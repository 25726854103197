<template>
    <div>

        <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage"/>

        <!-- begin page-header -->
        <h1 class="page-header">Config Profile Snapshot </h1>
        <!-- end page-header -->


        <!-- begin create/edit form -->
        <v-container fluid>

            <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage" :height="height" :width="width"></loading>

            <panel noButton="true" :title='titleName'>
                <form ref="configProfileForm" >
                    <vue-json-pretty :data="configProfileDetails" showLength v-bind:showDoubleQuotes="false" 
                            v-bind:highlightMouseoverNode="true" v-bind:showSelectController="true" />

                    <v-row align="center" justify="space-around">
                        <v-btn color="success" @click="goBack">Go Back</v-btn>
                    </v-row>

                </form>
            </panel>
        </v-container>
        <!-- end create/edit form -->

        <!-- begin apiStatus line -->
        <p class="help is-danger text-red" v-show="apiStatus" v-text="apiStatus"></p>
        <!-- end apiStatus line -->

    </div>
</template>
<script>

import Loading from 'vue-loading-overlay';
import ApiService from '../../services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from './Utils.vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
    components: {
        Loading,
        Breadcrumb,
        VueJsonPretty,
    },
    data(){
        return {
            currOrgId: null,
            deviceId: null,
            configProfileId:"",
            configProfileVersion:"",
            configProfileName:"",
            configProfileDetails:{},

            isLoading: false,
            fullPage: true,

            titleName:'View',

            height:35,
            width:35,
            userdetails:JSON.parse(localStorage.getItem('user-details')),
            tag: '',
            parent_pages:[ {name:'Home'},
                           {name:'Deployed Profile Snapshots'/*, route:'/snapshots/device_deployed_profile_snapshots'*/},
                           {name:"View", active:true} ],
            apiStatus: null,
        };
    },

    mixins: [ Utils ],

    methods: {

        goBack(){
            this.$router.go(-1);
        },
        getDeployedConfigProfile(){
            let query_params = {};

            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            query_params.configProfileId            = this.configProfileId;
            if ( this.configProfileVersion )
                query_params.configProfileVersion   = this.configProfileVersion;
            query_params.inclConfig                 = true

            ApiService.get((this.deployable == "true" ? "/api/v1/device/deviceDeployableConfig/config/" : "/api/v1/deviceDeployedConfig/device/") +
                                    this.deviceId, (err, result) => {
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //console.log("getDeployedConfigProfile " + JSON.stringify(result.data));
                    //console.log("getDeployedConfigProfile " + JSON.stringify(result.data.data[0]));
                    if ( result.data.data[0] )
                    {
                        var config = result.data.data[0].deployConfig.config;
                        /*
                        delete config.kafkaStats;
                        delete config.kafkaMcp;
                        console.log("getDeployedConfigProfile " + JSON.stringify(config));
                        */
                        // Remove appIds
                        if ( config.apps ) config.apps.forEach((a) => { if(a.appId) delete a.appId } );
                        this.configProfileDetails = config;
                        //console.log("getDeployedConfigProfile " + JSON.stringify(config));
                    }
                }
            }, query_params)
        },

        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.goBack();
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();

        this.deviceId               = this.$route.params.deviceId;
        this.configProfileId        = this.$route.params.configProfileId;
        this.configProfileName      = this.$route.params.configProfileName;
        this.configProfileVersion   = parseInt(this.$route.params.configProfileVersion);
        this.deployable             = this.$route.params.deployable;

        this.titleName              = decodeURIComponent(this.configProfileName) + " - v" + this.configProfileVersion
        this.getDeployedConfigProfile()
    }
}

</script>



