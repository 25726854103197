<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="5" class="tile-box">
      <v-card-title class="page-title">
        <v-icon>mdi-apps</v-icon> Applications
        <v-btn rounded outlined color="primary" absolute right :class="isShow" v-on:click="createApplication()">
          <v-icon>mdi-plus</v-icon> New
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
          {{ apiStatus }}
        </v-alert>
        <TableAdvancedFilter ref="tableAdvancedFilter" :enabledFieldsIn="tableAdvancedFilter.enabledFilterFields"
          v-on:freeFormSearchChanged="processFreeFormSearchChange"
          v-on:advancedFilterChanged="processAdvancedFilterChange" />

      </v-card-text>
      <v-container fluid class="pt-0">
        <v-data-table :loading="isLoading" :headers="application_table_vuetify_columns" :items="application_table_rows"
            multi-sort item-key="appId" :options.sync="pagingOptions" >
          <!-- :server-items-length="utilsTotalEntriesAllPages">
               :footer-props="{ 'items-per-page-options': [6, 10, 15, 25, 100] }" server side paging not supported for Applications -->
          <template v-slot:item.appName="{ item }">
            <p>
              <v-img class="float-left" v-if="item.iconImg" :src="item.iconImg" height="24" width="24" />
              <v-img class="float-left" v-else-if="item.icon"
                :src="require('../../../public/assets/img/moda/' + item.icon)" height="24" width="24" />
              <span class="float-left ml-4">{{ item.appName }} 
                  <v-chip v-if="utilsIsSystem(item)" x-small>SYS</v-chip>
              </span>
            </p>
          </template>

          <template v-slot:item.appType="{ item }">
            {{ utilsGetArrayElementByFieldValue(applicationParams.appTypes, "value", item.appType).displayName }}
          </template>

          <template v-slot:item.subType_protoPorts="{ item }">
            <span v-if="item.protocol">
              {{ utilsGetArrayElementByFieldValue(applicationParams.protocols, "value", item.protocol).displayName }}
              <span
                v-if="utilsGetArrayElementByFieldValue(applicationParams.protocols, 'value', item.protocol).isPort">
                &nbsp; {{ item.portRange.start }} &nbsp; - &nbsp;
                {{ item.portRange.end }}
              </span>
            </span>
            <span v-else-if="item.appType == UtilsModaConstants.AppType.SITE_MONITORING && item.subType">
              {{ utilsGetArrayElementByFieldValue(applicationParams.subTypesSite, "value", item.subType).displayName }}
            </span>
            <span v-else-if="item.appType == UtilsModaConstants.AppType.DEVICE_MONITORING && item.subType">
              {{ utilsGetArrayElementByFieldValue(applicationParams.subTypesDevice, "value", item.subType).displayName
              }}
            </span>
          </template>

          <template v-slot:item.domains="{ item }">
            <span v-for="domain in item.domains" :key="domain">
              {{ domain }} <br />
            </span>
          </template>

          <template v-slot:item.params="{ item }">
            <span v-for="paramName in Object.keys(item.params)" :key="paramName">
              {{ item.params[paramName] }}
            </span>
          </template>

          <template v-slot:item.profilesUsing="{ item }">
            <span v-for="prof in item.profilesUsing" :key="prof.analyticsConfigProfileId">
              <a href="javascript:;" v-on:click="navigateToAnalyticsConfigProfile(prof.analyticsConfigProfileId)">
                {{ prof.analyticsConfigProfileName }},
              </a>
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <nobr>
              <v-icon @click="showMoreParams(item)" title="More" class="mr-2" color="teal">
                mdi-information-outline
              </v-icon>
              <v-icon @click="viewApplicationDetails(item.appId)" class="mr-2" color="primary" title="View">
                mdi-eye
              </v-icon>
              <v-icon @click="duplicateApplication(item.appId)" class="mr-2" title="Clone" color="cyan">
                mdi-content-copy
              </v-icon>
              <v-icon :class="utilsGetDisplayClass(item)" @click="editApplicationDetails(item.appId, 0)" class="mr-2" color="warning" title="Edit">
                mdi-pencil
              </v-icon>
              <v-icon :class="utilsGetDisplayClass(item)" @click="deleteApplicationDetails(item.appId, item.appName)" title="Delete" color="red">
                mdi-delete
              </v-icon>
            </nobr>
          </template>

          <template v-slot:item.statuses="{ item }">

            <!-- key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully -->
            <v-tooltip top :key="statusesKey1">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" :color="utilsGetSeverityColor(item.highestSeverity).colorName">
                  {{ utilsFaultStatusDisplayObject(item.faultStatus).icon }}
                </v-icon>
              </template>

              <span v-if="item.highestSeverity">{{ item.highestSeverity }}</span>
              <span v-else>{{ utilsFaultStatusDisplayObject(item.faultStatus).text }}</span>
            </v-tooltip>
            <span v-if="item.incCount" :key="statusesKey2">
              (
              <a href="javascript:;" title="# Incidents" v-on:click="navigateToIncidents(item)">
                {{ item.incCount }}
              </a>
              )
            </span>

          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <!-- !!! Some of the params and functions used here are defined in AdsTreeTableUtils.vue mixin !!!!  -->
    <!-- !!! When async functionality implemented for this table, callRows, callChildren, callTempRows routines need to be
                 brought back to this file !!!!! -->
    <!--
      <v-text-field v-model="filterValueSearch" hide-details prepend-icon="fas fa-search" single-line @input="utilsFilterChanged" />
-->

    <!-- begin More Params dialog -->
    <v-dialog v-model="open_more_params_dialog" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row class="pt-6">
            <v-col cols="12" md="4"> Created Date: </v-col>
            <v-col cols="12" md="8">
              {{ more_params_row.createdDateString }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4"> Used by: </v-col>
            <v-col cols="12" md="8">
              <span v-for="prof in more_params_row.profilesUsing" :key="prof.analyticsConfigProfileId">
                <a href="javascript:;" v-on:click="navigateToAnalyticsConfigProfile(prof.analyticsConfigProfileId)">
                  {{ prof.analyticsConfigProfileName }},
                </a>
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="open_more_params_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end More Params dialog -->

    <DeleteDialog v-if="deleteDialog" :currentObjectName="currName" :isBackupBeforeDelete="false"
      @DeleteDialogCallback="deleteObjectChildEvent">
    </DeleteDialog>
  </div>
</template>



<script>
import ApiService from "@/services/api.service";
import auth from "@/services/auth.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import AdsTreeTableUtils from "@/pages/moda/AdsTreeTableUtils.vue";
import Utils from "@/pages/moda/Utils.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TableAdvancedFilter from '@/components/templates/TableAdvancedFilter'
//import ModaConst from '../../services/ModaConstants.js';

export default {
  components: {
    Breadcrumb,
    DeleteDialog,
    TableAdvancedFilter,
  },
  mixins: [AdsTreeTableUtils, Utils],
  data() {
    var application_table_vuetify_columns = [
      {
        text: "Name",
        value: "appName",
        sortable: true,
        filterable: true,
        width: 250
      },
      {
        text: "Type",
        value: "appType",
        sortable: true,
        filterable: true,
      },
      {
        text: "Subtype / Proto & Ports",
        value: "subType_protoPorts",
        sortable: false,
        filterable: true,
      },
      {
        text: "Parameters",
        value: "params",
        sortable: false,
        filterable: true,
      },
      {
        text: "Domains",
        value: "domains",
        sortable: true,
        filterable: true,
      },
      /*
            {
              text: "Used by",
              value: "profilesUsing",
              sortable: true,
              filterable: true,
            },
            {
              text: "Creation Time",
              value: "createdDateString",
              sortable: true,
              filterable: true,
            },
      */
      {
        text: "Status (#Incidents)",
        value: "statuses",
        sortable: true,
        filterable: true,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false
      },
    ];

    return {
      currOrgId: null,

      application_table_vuetify_columns,
      application_table_rows: [],
      applicationParams: {
        appTypes: [],
        protocols: [],
        params: {},
      },

      isLoading: false,

      userdetails: {},
      isShow: "display-none",
      parent_pages: [{ name: "Home" }, { name: "Applications", active: true }],
      apiStatus: null,
      deleteDialog: false,
      currId: null,
      currName: null,
      statusesKey1: 0,
      statusesKey2: 1,
      more_params_row: {},
      open_more_params_dialog: false,

      pagingOptions: sessionStorage.getItem(`_pagination${this.$route.path}`) ? JSON.parse(sessionStorage.getItem(`_pagination${this.$route.path}`)) : this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
      //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable

      //Note: Values of app(List) and component(List) are passed in a tuple of (idList & nameList)
      //      Map() or Set() can not be used as 'id' or 'name' could be empty
      // During a cross launch to this page from other pages, the passed in values are set in xLaunch
      xLaunch: {
        org: {
          orgIdList: [],         // ["orgobj.1001"] 
          orgNameList: []        // ["Org 1"]
        },
        site: {
          siteIdList: [],         // ["sitobj.1001"] 
          siteNameList: []        // ["Austin"]
        },
        app: {
          appIdList: [],         // ["appobj.1001"] 
          appNameList: []        // ["Teams"]
        },
      },
      tableAdvancedFilter: {
        enabledFilterFields: [], //this.utilsAdvancedFilterFields template doesn't work directly using mixin's data. Init it in mounted()
      },
      defaultAdvSearchCriteria: [
        /*
                {"name":"highestSeverity", // default Major and above states
                    "value": [
                        ModaConst.FaultService.Severity.CRITICAL,
                        ModaConst.FaultService.Severity.SEVERE,
                        ModaConst.FaultService.Severity.MAJOR,
                    ]
                },
        */
      ],
    };
  },
  watch: {
    pagingOptions: {
      handler() {
        sessionStorage.setItem(`_pagination${this.$route.path}`, JSON.stringify(this.pagingOptions));
        this.getApplicationList();
      },
      deep: false,
      immediate: false,
    },
  },
  methods: {
    navigateToIncidents(row) {
      //console.log("navigateToIncidents appRowSelected ", JSON.stringify(row));
      var appIds = [], appNames = [], states = []//, advSearchString = "";
      appIds = [row.appId]
      appNames = [row.appName]
      states = this.utilsOpenStates;
      //advSearchString = "Open incs. in " + row.appName;

      var query = [];
      if (appIds.length && appNames.length) {
        query.push({
          name: "component", value: {
            componentIdType: this.UtilsModaConstants.FaultService.ComponentIdType.APP,
            componentIdList: appIds,
            componentNameList: appNames
          }
        });
      }

      /*~~~ OLD ~~~
      if (appIds.length) {
          query.push({ name: "appId", value: appIds });
          query.push({ name: "componentId", value: appIds });
      }
      if (appNames.length) {
          query.push({ name: "appName", value: appNames });
          query.push({ name: "componentName", value: appNames });
      }
      ~~~~~~~~~~~~~*/

      query.push({ name: "state", value: states });
      console.log("navigateToIncidents query ", JSON.stringify(query));
      query.length ? this.$router.push({ name: "INCIDENTS", query: query }) : this.$router.push({ name: "INCIDENTS" });
    },

    // table filter functionality doesn't work unless it's rendering from native fields
    // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
    buildFilterableColumns(objs) {
      objs.forEach((obj) => {
        obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
        obj.profilesUsing = [];
        if (obj._children) this.buildFilterableColumns(obj._children);
      });
    },
    showMoreParams(row) {
      //console.log("showMoreParams " + JSON.stringify(row))
      this.more_params_row = row;
      this.open_more_params_dialog = true;
    },
    duplicateApplication(appId) {
      // const origApp = this._application_table_rows.find(r => r.appId === appId);
      // console.log('appId', origApp);

      this.$router.push({ name: "APPLICATIONS_EDIT", params: { appId: appId, readonly: 'duplicate' } })
    },
    getApplicationParams() {
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      ApiService.get("/api/v1/applicationParams", (err, result) => {
        if (err) {
          this.apiStatus = result.data.message ? result.data.message : result.data;
          this.utilsCheckLogout(this.apiStatus)
        } else {
          this.apiStatus = null;
          this.applicationParams = result.data.data[0];
          //console.log("getApplicationParams " + JSON.stringify(this.applicationParams));
          //this.getApplicationList(); // called by pagingOptions watcher
        }
      },
        query_params
      );
    },
    getApplicationList() {
      this.isLoading = true

      let query_params = {}, body = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      //if (this.filterValueSearch) query_params.searchString = "any:*:" + this.filterValueSearch;
      query_params.pageNum = this.pagingOptions.page;
      query_params.pageSize = this.pagingOptions.itemsPerPage;
      /*
            var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, {
              subType_protoPorts: "protocol",
            });
            if (sortCriteria) query_params.sortCriteria = sortCriteria;
      */
      var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, {subType_protoPorts: "subType", statuses:"highestSeverityNum"});
      if (sortCriteria) query_params.sortCriteria = sortCriteria;

      if (this.freeFormSearchStr) query_params.searchString = "any:*:" + this.freeFormSearchStr
      if (this.advSearchCriteria) {
        //console.log("getApplicationList advSearchCriteria ", JSON.stringify(this.query))
        var advSearchCriteria = [];
        Object.keys(this.advSearchCriteria).forEach((k) =>
          advSearchCriteria.push(this.advSearchCriteria[k])
        );
        if (advSearchCriteria.length) body.advSearchCriteria = advSearchCriteria;
        //console.log("getDevicesList body ", JSON.stringify(body))
      }

      ApiService.post("/api/v1/application/page3", body, (err, result) => {
        this.isLoading = false;
        //console.log("getApplicationList " + JSON.stringify(result.data));
        if (err) {
          this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
          this.utilsCheckLogout(this.apiStatus)
        } else {
          this.apiStatus = null;

          this._application_table_rows = result.data.data.docs;
          this.utilsTotalEntriesAllPages = result.data.data.totalEntries;

          this.buildFilterableColumns(this._application_table_rows);
          //console.log("getApplicationList " + JSON.stringify(this._application_table_rows, null, 2));
          this.getProfilesUsingList();
        }
      },
        query_params
      );
    },
    getProfilesUsingList() {
      //this.isLoading=true

      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      var body = {};
      body.appIds = this._application_table_rows.map((a) => a.appId);

      ApiService.post("/api/v1/analyticsConfigProfile/withapp", body, (err, result) => {
        this.isLoading = false;
        //   console.log("getProfilesUsingList " + JSON.stringify(result.data));
        if (err) {
          this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
          this.utilsCheckLogout(this.apiStatus)
        } else {
          this.apiStatus = null;
          var profiles = result.data.data;
          // push profiles using each app into app.profilesUsing array
          this._application_table_rows.forEach(function (app) {
            profiles.forEach(function (prof) {
              if (prof.applications.findIndex((papp) => papp.appId == app.appId) >= 0) {
                app.profilesUsing.push({
                  analyticsConfigProfileId: prof.analyticsConfigProfileId,
                  analyticsConfigProfileName: prof.analyticsConfigProfileName,
                });
              }
            });
          });
        }
        this.application_table_rows = this._application_table_rows;
      },
        query_params
      );
    },
    editApplicationDetails(id, readonly) {
      this.$router.push({ name: "APPLICATIONS_EDIT", params: { appId: id, readonly: readonly } })
    },
    viewApplicationDetails(id) {
      auth.crossLaunchAppsAdvSearch = this.advSearchCriteria // save this state to come back to same search state
      this.$router.push({ name: "APPLICATIONS_VIEW", params: { appId: id } })
    },

    deleteApplicationDetails(Id, name) {
      this.deleteDialog = true;
      this.currId = Id;
      this.currName = name;
    },

    deleteObjectChildEvent(cancelFlag) {
      this.deleteDialog = false;
      if (cancelFlag == true) return;
      //   console.log("**" + isBackupDel);
      // if(confirm("Do you really want to delete - '" + name + "' ?"))
      {
        this.isLoading = true;

        let query_params = {};
        if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
        query_params.deleteMode = this.UtilsModaConstants.DeleteMode.FORCE;

        ApiService.delete("/api/v1/application/" + this.currId,
          (err, result) => {
            //console.log("**"+JSON.stringify(result))
            this.isLoading = false;
            if (err) {
              this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
              this.utilsCheckLogout(this.apiStatus)
            } else {
              this.apiStatus = null;
              this.getApplicationList();
            }
          },
          query_params
        );
      }
    },
    createApplication() {
      this.$router.push({ name: "APPLICATIONS_CREATE" })
    },
    navigateToAnalyticsConfigProfile(id) {
      var readonly = 1;
      this.$router.push({ name: "ANAL_CONFIG_PROFILES_EDIT", params: { analyticsConfigProfileId: id, readonly: readonly } })
    },
    get_auth_details() {
      let title = this.$route.meta.title;
      this.isShow = auth.AuthService.get_usr_auth_details(title);
    },
    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      this.clearPaginationCache();
      this.getApplicationList();
    },
    clearPaginationCache(){
      sessionStorage.removeItem(`_pagination${this.$route.path}`);
      this.pagingOptions.page = 1;
    },
    processFreeFormSearchChange(freeFormSearchStr) {
      this.freeFormSearchStr = freeFormSearchStr
      auth.globalAppsFreeFormSearchStr = freeFormSearchStr
      console.log("Applications processFreeFormSearchChange " + this.freeFormSearchStr)
      this.getApplicationList();
    },
    processAdvancedFilterChange(advancedFilter) {
      //console.log("Table filter selected " + JSON.stringify(advancedFilter))

      this.pagingOptions.page = 1;        // Reset page to 1st page, for AdvSearch APPLY

      // append to advanced search passed in, if any
      this.advSearchCriteria = []
      //this.advSearchString = ""

      // Only Applications.vue has orgName filter as backend returns by default global apps
      if (advancedFilter.orgNameFilter) {
        if (advancedFilter.orgNameFilter == null || advancedFilter.orgNameFilter == [] ||
          !this.utilsIsSameList(advancedFilter.orgNameFilter, this.xLaunch.org.orgNameList)) {
          //advancedFilter.orgNameFilter != this.xLaunch.org.orgNameList)
          // orgNameList is empty or cross-launched orgNameList is modified. 
          // Let the backend calculate the Ids, as needed
          this.xLaunch.org.orgIdList = null;
        }
        this.advSearchCriteria.push({
          "name": "org",
          "value": { orgIdList: this.xLaunch.org.orgIdList, orgNameList: advancedFilter.orgNameFilter }
        })
        //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.orgNameFilter)
      }

      if (advancedFilter.siteNameFilter) {
        if (advancedFilter.siteNameFilter == null || advancedFilter.siteNameFilter == [] ||
          !this.utilsIsSameList(advancedFilter.siteNameFilter, this.xLaunch.site.siteNameList)) {
          //advancedFilter.siteNameFilter != this.xLaunch.site.siteNameList)
          // siteNameList is empty or cross-launched siteNameList is modified. 
          // Let the backend calculate the Ids, as needed
          this.xLaunch.site.siteIdList = null;
        }
        this.advSearchCriteria.push({
          "name": "site",
          "value": { siteIdList: this.xLaunch.site.siteIdList, siteNameList: advancedFilter.siteNameFilter }
        })
        //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.siteNameFilter)
      }

      if (advancedFilter.appNameFilter) {
        if (advancedFilter.appNameFilter == null || advancedFilter.appNameFilter == [] ||
          !this.utilsIsSameList(advancedFilter.appNameFilter, this.xLaunch.app.appNameList)) {
          //advancedFilter.appNameFilter != this.xLaunch.app.appNameList)
          // appNameList is empty or cross-launched appNameList is modified. 
          // Let the backend calculate the Ids, as needed
          this.xLaunch.app.appIdList = null;
        }
        this.advSearchCriteria.push({
          "name": "application",
          "value": { appIdList: this.xLaunch.app.appIdList, appNameList: advancedFilter.appNameFilter }
        })
        //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.appNameFilter)
      }

      if (advancedFilter.highestSeverityFilter) {
        this.advSearchCriteria.push({ "name": "highestSeverity", "value": advancedFilter.highestSeverityFilter })
        //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.highestSeverityFilter)
      }
      if (advancedFilter.appTypeFilter) {
        this.advSearchCriteria.push({ "name": "appType", "value": advancedFilter.appTypeFilter })
        //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.appTypeFilter)
      }
      if (advancedFilter.appSubTypeFilter) {
        this.advSearchCriteria.push({ "name": "subType", "value": advancedFilter.appSubTypeFilter })
        //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.appSubTypeFilter)
      }
      if (advancedFilter.appProtocolFilter) {
        this.advSearchCriteria.push({ "name": "protocol", "value": advancedFilter.appProtocolFilter })
        //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.appProtocolFilter)
      }
      if (advancedFilter.appDomainsFilter) {
        this.advSearchCriteria.push({ "name": "domains", "value": advancedFilter.appDomainsFilter })
        //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.appDomainsFilter)
      }

      auth.globalAppsAdvSearch = this.advSearchCriteria;
      //auth.globalAppsAdvSearchString = this.advSearchString;
      this.clearPaginationCache();
      this.getApplicationList();
    },
    init() {
      if (this.advSearchCriteria) {
        //console.log("Applications.vue init this.advSearchCriteria " + JSON.stringify(this.advSearchCriteria))
        var tmp

        // Only Applications.vue has orgName filter as backend returns by default global apps
        tmp = this.advSearchCriteria.find(e => e.name == "org")
        if (tmp) {
          this.tableAdvancedFilter.orgNamesFilterIn = tmp.value.orgNameList;
          // orgNames are only to pass to this component, orgIds are sent to backend
          // send names to backend
          // this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "orgName")
          this.xLaunch.org = tmp.value;
        } else { // set default
          this.tableAdvancedFilter.orgNamesFilterIn = [];
          this.xLaunch.org = { orgIdList: [], orgNameList: [] };
        }

        tmp = this.advSearchCriteria.find(e => e.name == "site")
        if (tmp) {
          this.tableAdvancedFilter.siteNamesFilterIn = tmp.value.siteNameList;
          // siteNames are only to pass to this component, orgIds are sent to backend
          // send names to backend
          // this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "siteName")
          this.xLaunch.site = tmp.value;
        } else { // set default
          this.tableAdvancedFilter.siteNamesFilterIn = [];
          this.xLaunch.site = { siteIdList: [], siteNameList: [] };
        }

        tmp = this.advSearchCriteria.find(e => e.name == "application")
        if (tmp) {
          this.tableAdvancedFilter.appNamesFilterIn = tmp.value.appNameList;
          // appNames are only to pass to this component, orgIds are sent to backend
          // send names to backend
          // this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "appName")
          this.xLaunch.app = tmp.value;
        } else { // set default
          this.tableAdvancedFilter.appNamesFilterIn = [];
          this.xLaunch.app = { appIdList: [], appNameList: [] };
        }

        tmp = this.advSearchCriteria.find(e => e.name == "highestSeverity")
        if (tmp) this.tableAdvancedFilter.highestSeveritiesFilterIn = tmp.value

        tmp = this.advSearchCriteria.find(e => e.name == "appType")
        if (tmp) this.tableAdvancedFilter.appTypesFilterIn = tmp.value

        tmp = this.advSearchCriteria.find(e => e.name == "subType")
        if (tmp) this.tableAdvancedFilter.appSubTypesFilterIn = tmp.value

        tmp = this.advSearchCriteria.find(e => e.name == "protocol")
        if (tmp) this.tableAdvancedFilter.appProtocolFilterIn = tmp.value

        tmp = this.advSearchCriteria.find(e => e.name == "domains")
        if (tmp) this.tableAdvancedFilter.appDomainsFilterIn = tmp.value

      }

      this.$refs.tableAdvancedFilter.setAdvancedFilters(this.tableAdvancedFilter, this.freeFormSearchStr)

      //console.log("Application init orgNamesFilter " + this.tableAdvancedFilter.orgNamesFilterIn + 
      //", ApplicationstatusFilter " + this.tableAdvancedFilter.ApplicationstatusFilterIn)
    },

  },
  mounted() {
    if (this.$route.params && this.$route.params.targetOrgId) {
      this.$refs.breadcrumb.navigateToOrg(this.$route.params.targetOrgId)
      this.currOrgId = this.$route.params.targetOrgId
    } else
      this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();

    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.FREEFORM_SEARCH)
    // Only Applications.vue has orgName filter as backend returns by default global apps
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.ORG_NAME)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.SITE_NAME)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.APP_NAME)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.HIGHEST_SEVERITY)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.APP_TYPE)
    //this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.APP_SUB_TYPE) // not supporting subtype filter. These are internal
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.APP_PROTOCOL)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.APP_DOMAINS)

    if (this.$route.query && Array.isArray(this.$route.query)) {
      // query passed in from cross Launch
      this.advSearchCriteria = this.$route.query
      //auth.globalAppsAdvSearch = this.advSearchCriteria - //don't save cross launch filter as global.
                                                            // We should be able to see complete list by clicking on left panel
    } else if (auth.crossLaunchAppsAdvSearch)
      // else, take the one from crossLaunch state - the one we set when we navigated away from this page
      this.advSearchCriteria = auth.crossLaunchAppsAdvSearch
    else if (auth.globalAppsAdvSearch)
      // else, take the one from global state
      this.advSearchCriteria = auth.globalAppsAdvSearch
    else // else, default
      this.advSearchCriteria = this.defaultAdvSearchCriteria;

    if (auth.globalAppsFreeFormSearchStr)
      this.freeFormSearchStr = auth.globalAppsFreeFormSearchStr

    this.init()

    this.get_auth_details();
    this.utilsDataRefreshFunc = this.getApplicationList;
    this.getApplicationParams();
  },
};
</script>
