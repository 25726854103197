<template>
    <div>

        <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage"/>

        <!-- begin page-header -->
        <h1 class="page-header">Network Analytics</h1>
        <!-- end page-header -->

        <loading :active.sync="isLoading" 
        :can-cancel="onCancel" 
        :is-full-page="fullPage" :height="height" :width="width"></loading>

        <div >
<!-- Use this src when modadash is running on command line to directly go to grafana container. Note: authentication/authorization DOES NOT work.
            <iframe ref="dashboard2" src="http://localhost:3333/moda-grafana/moda-grafana/d/BWIvrI7Gz/network-analytics" width="100%" height="810" frameborder="0" style="border:0" allowfullscreen></iframe>
-->

<!-- Use this src when modadash is running as docker to proxy through modaserver container. Authentication/Authorization works.
            <iframe ref="dashboard2" src="/moda-grafana/d/BWIvrI7Gz/network-analytics" width="100%" height="810" frameborder="0" style="border:0" allowfullscreen></iframe>
-->

<!-- Use this src when modadash is running on command line to proxy through modaserver which is also running on command line. Authentication/Authorization works.
            <iframe ref="dashboard2" src="http://localhost:3000/moda-grafana/d/BWIvrI7Gz/network-analytics" width="100%" height="810" frameborder="0" style="border:0" allowfullscreen></iframe>
-->

<!-- This is the dynamic way to load either one of the above using JS code below.
-->
            <iframe id="dashboard3" width="100%" height="810" frameborder="0" style="border:0" allowfullscreen></iframe>

        </div >

    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import auth from '../../services/auth.service'
import {globalAPIUrlStore} from '../../main.js'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from './Utils'

import ModaConst from '../../services/ModaConstants.js';

export default {
    components: {
        Loading,
        Breadcrumb,
    },
    data(){
        return {
            currOrgId: null,
            isLoading: false,
            fullPage: true,
            onCancel:true,
            height:35,
            width:35,
            isShow:'display-none',
            parent_pages:[ {name:'Home'}, {name:'Network Analytics', active:true} ],
            pageName : ModaConst.Socket.Page.NETWORK_ANALYTICS,
            isPageActivated : false,
            isPageVisible : true,
        }
    },
    mixins: [Utils],
    methods: {

        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },

        setIFrameSrc(){

            // synchronous code isn't working coz iframe is not yet created when this is called.
            // iframe.write() isn't working coz grafana requires .src to be set
            setTimeout(function() {

                var dburl = "/d/BWIvrI7Gz/network-analytics"

                var iframe = document.getElementById('dashboard3');

                //console.log("Dasboard2.vue set iframe src " + iframe + " src " + globalAPIUrlStore.GRAFANA_SERVICE_URL + dburl);
                iframe.src = globalAPIUrlStore.GRAFANA_SERVICE_URL + dburl
            }, 100);
        },

        setOrgCookie(){
            // This does not overwrite entire document.cookie string. It just adds/updates specified key=value only
            // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
            // Note: document.cookie is an accessor property with native setter and getter functions, and consequently is not a data property with a value: what you write is not the same as what you read, everything is always mediated by the JavaScript interpreter.
            document.cookie = "MODA_targetOrgId="+this.currOrgId;
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.setOrgCookie();
            this.setIFrameSrc();
        },

        handleVisibility () {
            if (document.visibilityState === 'hidden') {
                this.isPageVisible = false;
                this.socketMessage = "Client's Page - Dashboard Networks - is hidden";
            } else {
                this.isPageVisible = true;
                this.socketMessage = "Client's page - Dashboard Networks - is visible " + this.isPageActivated;
                if ( this.isPageActivated ) this.setIFrameSrc(); // refresh alarms list when page becomes visible
            }
            console.log (this.socketMessage);
        }
    },

    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.get_auth_details();
        console.log("Network Analytics in mounted")
        // if keep-alive is enabled at router level, comment out below two lines and uncomment in activated/deactivated
        this.setOrgCookie();
        this.setIFrameSrc();
        document.addEventListener('visibilitychange', this.handleVisibility, false);
    },
    activated: function() {
        this.isPageActivated = true;
        console.log("Network Analytics in activated")
        //socketService.sendMessage(this.pageName, "in hook: " + this.pageName + " activated");
        //this.setOrgCookie();
        //this.setIFrameSrc(); // refresh alarms list when page becomes visible
    },
    deactivated: function() {
        this.isPageActivated = false;
        console.log("Network Analytics in deactivated")
        //socketService.sendMessage(this.pageName, "in hook: " + this.pageName + " deactivated");
    },
}
</script>
