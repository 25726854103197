import axios from 'axios'
import {globalAPIUrlStore} from '../main.js'
var auth_token = ""
var headers = {} 

const UserApiService = {
    get(url,callback,query_params={}) {
        // we are using cookie now. authorization header not needed
        auth_token = localStorage.getItem('MODA_Authorization')
        if(auth_token){
            headers = {"MODA_Authorization" :auth_token}
        }
        axios.get(globalAPIUrlStore.API_SERVICE_URL+url,{headers,params:query_params, withCredentials:true}).then(result  => {
            callback(null, result) 
        }).catch(error => {
            //console.log(error)
            callback(error, error.response)
        })
    },
    post(url, postObj,callback, query_params={}) {
        // we are using cookie now. authorization header not needed
        auth_token = localStorage.getItem('MODA_Authorization')
        if(auth_token){
            headers = {"MODA_Authorization" :auth_token}
        }
        axios.post(globalAPIUrlStore.API_SERVICE_URL+url,postObj,{headers, params:query_params, withCredentials:true}).then(result  => {
            callback(null, result) 
        }).catch(error => {
            //console.log(error)
            callback(error, error.response)
        })
    },

    put(url, postObj,callback, query_params={}) {
        // we are using cookie now. authorization header not needed
        auth_token = localStorage.getItem('MODA_Authorization')
        if(auth_token){
            headers = {"MODA_Authorization" :auth_token}
        }
        axios.put(globalAPIUrlStore.API_SERVICE_URL+url,postObj,{headers, params:query_params, withCredentials:true}).then(result  => {
            callback(null, result) 
        }).catch(error => {
            //console.log(error)
            callback(error, error.response)
        })
    },

    delete(url,callback, query_params={}) {
        // we are using cookie now. authorization header not needed
        auth_token = localStorage.getItem('MODA_Authorization')
        if(auth_token){
            headers = {"MODA_Authorization" :auth_token}
        }
        axios.delete(globalAPIUrlStore.API_SERVICE_URL+url,{headers, params:query_params, withCredentials:true}).then(result  => {
            callback(null, result) 
        }).catch(error => {
            //console.log(error)
            callback(error, error.response)
        })
    },

    login(url, postObj,callback) {
        axios.post(globalAPIUrlStore.API_SERVICE_URL+url,postObj).then(result  => {
            callback(null, result) 
        }).catch(error => {
            //console.log(error)
            callback(error, error.response)
        })
    },

}

export default UserApiService
