<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-arrow-up-thick</v-icon> MODA Agent SWUpdate Job {{ titleName }}
			</v-card-title>
			<v-card-text class="pb-0">
				<v-container fluid class="pl-5 pr-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<form ref="deviceswupdatejobform">
						<v-row v-if="isLoading">
							<v-col cols="12">
								<v-progress-linear indeterminate color="primary"></v-progress-linear>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols=12 md=6>
								<v-text-field dense v-model="deviceswupdatejobdetails.jobName" :error-messages="nameErrors"
									@input="$v.deviceswupdatejobdetails.jobName.$touch()"
									@blur="$v.deviceswupdatejobdetails.jobName.$touch()" class="moda-required" label="SW Update Job Name"
									:disabled="readonly" />
							</v-col>
							<v-col cols=12 md=6>
								<v-text-field dense v-model="deviceswupdatejobdetails.description" label="Description"
									:disabled="readonly">
								</v-text-field>
							</v-col>
						</v-row>

						<!-- 
                    <v-row>
                        <v-col cols=12 md=6>
                            <v-autocomplete v-model="deviceModelId" :items="models_list" prepend-icon="mdi-filter-variant"
                                          item-text="deviceModelName" item-value="deviceModelId" :error-messages="deviceModelIdListErrors"
                                          auto-select-first @input="$v.deviceModelId.$touch()"
                                          @blur="$v.deviceModelId.$touch()"
                                          class="moda-required" label="Model" outlined  :disabled="readonly" />
                        </v-col>
                        <v-col cols=12 md=6>
                            <v-autocomplete v-model="swVersionSelected" :items="swVersionList" prepend-icon="mdi-filter-variant"
                                          item-text="deviceSoftwareImageName" item-value="deviceSoftwareImageId" :error-messages="swVersionListError"
                                          auto-select-first @input="$v.swVersionSelected.$touch()"
                                          @blur="$v.swVersionSelected.$touch()"
                                          class="moda-required" label="SwVersion" outlined  :disabled="readonly" />
                        </v-col>
                    </v-row>
           -->

						<v-row>
							<v-col cols=12 md=12>
								<div class="search-wrapper">
									<v-text-field v-model="device_search" label="Search table" prepend-inner-icon="mdi-magnify" />
								</div>
								<v-data-table v-model="selected_devices" :headers="device_table_columns" :search="device_search"
									:items="all_devices" item-key="deviceId" show-select :error-messages="devicesErrors"
									@input="$v.selected_devices.$touch()" @blur="$v.selected_devices.$touch()" class="moda-required"
									:disabled="readonly" @toggle-select-all="selectAllToggle">

									<template v-slot:header.data-table-select="{ props, on }">
										<v-simple-checkbox @input="on.input($event)" :value="props.value"
											:indeterminate="props.indeterminate" :disabled="readonly"></v-simple-checkbox>
									</template>

									<template v-slot:item.data-table-select="{ item, isSelected, select }">
										<v-simple-checkbox v-if="isDeviceRegistered(item)" :value="isSelected" @input="select($event)"
											:disabled="readonly"></v-simple-checkbox>
									</template>

									<template v-slot:item.deviceName="{ item }">
										<a class="link-text" v-on:click="navigateToDevice(item.deviceId)"> {{item.deviceName}} </a>
									</template>
									<template v-slot:item.siteName="{ item }">
										<a class="link-text" v-on:click="navigateToSite(item.siteId)"> {{item.siteName}} </a>
									</template>
									<template v-slot:item.configProfiles="{ item }">
										<v-select v-model="item.selSWImageId" :items="item.configProfiles" :disabled="readonly"
											item-text="deviceSoftwareImageName" item-value="deviceSoftwareImageId"></v-select>
										<!-- begin create/edit form 
                       :hint="`${item.selSWImageId.deviceSoftwareImageName}, ${item.selSWImageId.deviceSoftwareImageId}`"
                                    <a href="javascript:;" v-for="cProf in item.configProfiles" :key="cProf.type"
                                            v-on:click="navigateToSWUpdateProfile(cProf.type, cProf.value.configProfileId)">
                                                {{cProf.value.configProfileName}} - v{{cProf.value.configProfileVersion}}<br></a>
        -->
									</template>
									<template v-slot:item.statuses="{ item }">
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon v-bind="attrs" v-on="on"
													:color="utilsOperStatusDisplayObject(item.operStatus).colorName">
													{{utilsOperStatusDisplayObject(item.operStatus).icon}}
												</v-icon>
											</template>
											<span>{{utilsOperStatusDisplayObject(item.operStatus).text}}</span>
										</v-tooltip>
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon v-bind="attrs" v-on="on"
													:color="utilsLiveStatusDisplayObject(item.liveStatus).colorName">
													{{utilsLiveStatusDisplayObject(item.liveStatus).icon}}
												</v-icon>
											</template>
											<span>{{utilsLiveStatusDisplayObject(item.liveStatus).text}}</span>
										</v-tooltip>
									</template>
								</v-data-table>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols=12 md=3>
								<strong>Schedule Options</strong>
								<v-radio-group dense v-model="deviceswupdatejobdetails.jobScheduleType" class="moda-required"
									:error-messages="jobScheduleTypeErrors"
									@change="$v.deviceswupdatejobdetails.jobScheduleType.$touch()">
									<v-row>
										<v-col cols=12 md=6>
											<v-radio key="Now" label="Now" :value="UtilsModaConstants.ModaScheduleType.IMMEDIATE"
												:disabled="readonly" />
										</v-col>
										<v-col cols=12 md=6>
											<v-radio key="Future" label="Future" :value="UtilsModaConstants.ModaScheduleType.SCHEDULED"
												:disabled="readonly" />
										</v-col>
									</v-row>
								</v-radio-group>
								<v-menu v-if="deviceswupdatejobdetails.jobScheduleType == UtilsModaConstants.ModaScheduleType.SCHEDULED"
									v-model="scheduledAtMenu" :close-on-content-click="false" :nudge-right="40"
									transition="scale-transition" offset-y min-width="290px" :disabled="readonly">

									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="scheduledAt" label="Schedule at" prepend-inner-icon="mdi-calendar" readonly
											v-bind="attrs" v-on="on" class="moda-required" :error-messages="scheduledAtErrors"
											@input="$v.scheduledAt.$touch()" @blur="$v.scheduledAt.$touch()" />
									</template>
									<!--  time-picker has a bug. When menu pops up 2nd time onwards, it gets stuck in minutes.
                                        Needs to forcefully set selectingHour = true -->
									<v-date-picker v-model="scheduledAtDate" :min="nowDate"
										@change="$refs.timepicker && ($refs.timepicker.selectingHour = true)" no-title scrollable />

									<v-time-picker ref="timepicker" v-model="scheduledAtTime" :min="nowTime" format="24hr"
										@change="scheduledAtMenu = false; $refs.timepicker && ($refs.timepicker.selectingHour = true)"
										no-title scrollable />
								</v-menu>
							</v-col>
						</v-row>
					</form>
				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="!readonly && !isEdit" @click="submit"
					:disabled="this.$v.$invalid">
					Create</v-btn>
				<v-btn rounded outlined color="success" v-if="!readonly && isEdit" @click="submit" :disabled="this.$v.$invalid">
					Update</v-btn>
				<v-btn rounded outlined color="error" v-if="!readonly" @click="cancel">Cancel</v-btn>
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>

import ApiService from '@/services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'

export default {
    components: {
        Breadcrumb,
    },
    data(){
        return {

            allowMultipleTargetSelection: false, // set to true to allow multiple selections for target

            currOrgId: null,
            targetOrgId: null,

            isLoading: false,
            fullPage: true,
            titleName:'Create',

            deviceSWUpdateJobId:"",
            isEdit:false,
            readonly:true,

            device_search : '',
            device_table_columns : [
                {
                    text: 'Device',
                    align: 'start',
                    sortable: true,
                    value: 'deviceName',
                },
                {
                    text: 'Model',
                    align: 'start',
                    sortable: true,
                    value: 'deviceModel.deviceModelName',
                },
                {
                    text: 'Unique ID',
                    align: 'start',
                    sortable: true,
                    value: 'macAddress',
                },
                {
                    text: 'Serial #',
                    align: 'start',
                    sortable: true,
                    value: 'serialNumber',
                },
                {
                    text: 'Site',
                    align: 'start',
                    sortable: true,
                    value: 'siteName',
                },
                {
                    text: 'Sw Version',
                    align: 'start',
                    sortable: true,
                    value: 'deviceSwVersion',
                },
                {
                    text: 'New Sw Version',
                    align: 'start',
                    sortable: true,
                    value: 'configProfiles',
                },
                {
                    text: 'Statuses',
                    align: 'start',
                    sortable: true,
                    value: 'statuses',
                },
            ],
            all_devices:[],
            num_eligible_devices: 0,
            selected_devices:[],

            models_list:[],
            swVersionList:[ { "orgId": null, "deviceSoftwareImageId": "devswi.10002", "deviceModelId": "devmod.1002",
            "deviceSoftwareImageName": "AgentSoftware-Ubuntu", "deviceSoftwareImageVersion": 1, "deviceSoftwareImageDate": 1617143484546,
            "deviceSoftwareImageFilename": "devmod.1002_moda_root.tgz", "description": "Device Software Image for Ubuntu OS",
            "entryType": "System" } ],
            deviceModelToSwVersionMap : new Map(),

            height:35,
            width:35,
            userdetails:JSON.parse(localStorage.getItem('user-details')),
            deviceswupdatejobdetails:{
                jobId :'',
                jobName :'',
                description :'',
                deviceIds: [],
                jobFrequency :'',
                jobScheduleType :'',
                scheduledAt :'',
            },
            scheduledAtMenu: false,
            scheduledAt:'',
            scheduledAtDate: this.utilsFormatDateTime2().split(' ')[0],
            scheduledAtTime: this.utilsFormatDateTime2().split(' ')[1],
            nowDate: this.utilsFormatDateTime2().split(' ')[0],
            nowTime: this.utilsFormatDateTime2().split(' ')[1],

            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Profiles & Jobs"},
                           {name:'Device SW Update Jobs', route: { name: "DEVICE_SWUPDATE_JOBS" }},
                           {name:"Create/Edit", active:true} ],
            apiStatus: null,
        };
    },

    watch: {
        scheduledAtDate : function(val) {
            this.scheduledAt = val + " " + this.scheduledAtTime;
            //console.log("this.scheduledAt " + this.scheduledAt);
            if ( val != this.nowDate ) delete this.nowTime
            else this.nowTime = this.utilsFormatDateTime2().split(' ')[1]
        },
        scheduledAtTime : function(val) {
            this.scheduledAt = this.scheduledAtDate + " " + val;
            //console.log("this.scheduledAt " + this.scheduledAt);
        },
    },

    mixins: [validationMixin,
            Utils ],

    validations: {
        deviceswupdatejobdetails : {
            jobName: { required, minLength: minLength(3) },
            jobScheduleType: { required },
        },
        selected_devices: { required },
        scheduledAt: { required: requiredIf( function() { return this.deviceswupdatejobdetails.jobScheduleType ==
                                                                 this.UtilsModaConstants.ModaScheduleType.SCHEDULED;}), },
        // swVersionSelected: { required },
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.deviceswupdatejobdetails.jobName.$dirty) return errors
            !this.$v.deviceswupdatejobdetails.jobName.required && errors.push('Name is required.')
            !this.$v.deviceswupdatejobdetails.jobName.minLength && errors.push('Name must be atleast 3 characters long')
            return errors
        },
        devicesErrors () {
            const errors = []
            if (!this.$v.selected_devices.$dirty) return errors
            !this.$v.selected_devices.required && errors.push('Device selection required.')
            return errors
        },
        jobScheduleTypeErrors () {
            const errors = []
            if (!this.$v.deviceswupdatejobdetails.jobScheduleType.$dirty) return errors
            !this.$v.deviceswupdatejobdetails.jobScheduleType.required && errors.push('Schedule type required.')
            return errors
        },
        scheduledAtErrors () {
            const errors = []
            if (!this.$v.scheduledAt.$dirty) return errors
            !this.$v.scheduledAt.required && errors.push('Scheduled time required.')
            return errors
        },
        deviceModelIdListErrors() {
            const errors = []
            return errors
        },
        swVersionListError() {
            const errors = []
            return errors;
        },
    },

    methods: {

        isDeviceRegistered (device) {
            return (device.operStatus == this.UtilsModaConstants.DeviceOperStatus.REGISTERED) 
        },
        selectAllToggle(props) {
            console.log("selectAllToggle called" + props);
            if(this.selected_devices.length != this.num_eligible_devices ) {
                this.selected_devices = [];
                const self = this;
                props.items.forEach(item => {
                    if(this.isDeviceRegistered(item)) {
                        self.selected_devices.push(item);
                    }
                });
            } else this.selected_devices = [];
        },
        submit () {
            this.$v.$touch()

            let selectedStr = "";
            this.deviceswupdatejobdetails.jobType = this.UtilsModaConstants.ModaJobType.DEVICE_SW_UPDATE_JOB
            this.deviceswupdatejobdetails.jobName = this.deviceswupdatejobdetails.jobName.trim();
            this.deviceswupdatejobdetails.jobData = this.selected_devices.map((d) => {
                if ( d )  {
                    selectedStr += JSON.stringify(d.selSWImageId);
                    return { deviceId: d.deviceId,  deviceSoftwareImageId: d.selSWImageId };
                } else return null;
            })
            console.log("submit*****" + selectedStr);

            this.deviceswupdatejobdetails.jobScheduleInfo = {}
            if (this.deviceswupdatejobdetails.jobScheduleType == this.UtilsModaConstants.ModaScheduleType.SCHEDULED){
                this.deviceswupdatejobdetails.jobScheduleInfo.SCHEDULED = {}
                this.deviceswupdatejobdetails.jobScheduleInfo.SCHEDULED.runAt = new Date(this.scheduledAt).getTime();
            } else if (this.deviceswupdatejobdetails.jobScheduleType == this.UtilsModaConstants.ModaScheduleType.IMMEDIATE)
                this.deviceswupdatejobdetails.jobScheduleInfo.offset = 400 // 400 ms offset to current time

            this.isLoading=true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            if ( this.isEdit ){
                //ApiService.put("/jobsvc/api/v1/deviceSWUpdateJob/"+this.deviceSWUpdateJobId,this.deviceswupdatejobdetails, (err, result) =>
                ApiService.put("/jobsvc/api/v1/jobs/"+this.deviceSWUpdateJobId,this.deviceswupdatejobdetails, (err, result) =>
                {
                    //console.log("*update*"+JSON.stringify(result))
                    this.isLoading=false;
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateDeviceSWUpdateJob()
                    }
                }, query_params, "JOBS_SERVICE_URL")
            } else {// create
                //console.log("create*****"+JSON.stringify(this.deviceswupdatejobdetails))
                //ApiService.post("/jobsvc/api/v1/deviceSWUpdateJob",this.deviceswupdatejobdetails, (err, result) =>
                ApiService.post("/jobsvc/api/v1/jobs",this.deviceswupdatejobdetails, (err, result) =>
                {
                    //console.log("*create*"+JSON.stringify(err) + " " + JSON.stringify(result))
                    this.isLoading=false
                    if ( err ) {
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        console.log("*create error:*"+JSON.stringify(err) + " " + JSON.stringify(result))
                        this.utilsCheckLogout(this.apiStatus);
                    }
                    else {
                        this.apiStatus = null;
                        this.cancelCreateDeviceSWUpdateJob()
                    }
                }, query_params, "JOBS_SERVICE_URL")
            }

        },
        cancel () {
            this.$v.$reset()
            this.cancelCreateDeviceSWUpdateJob()
        },
        navigateToDevice(id){
            var readonly = 1
            this.$router.push({name : "MODA_AGENTS_EDIT", params: { id: id, readonly: readonly } } )
        },
        navigateToSite(id){
            var readonly = 1
            this.$router.push({ name: "SITES_EDIT", params: { siteId: id, readonly: readonly } })
        },
        navigateToSWUpdateProfile(type, id){
            var readonly = 1
            if ( type == this.UtilsModaConstants.SWUpdateProfileType.ANALYTICS )
                this.$router.push( { name : "ANAL_CONFIG_PROFILES_EDIT", params : { analyticsConfigProfileId: id, readonly: readonly}})
            else if ( type == this.UtilsModaConstants.SWUpdateProfileType.SYSTEM )
                this.$router.push( { name : "SYS_CONFIG_PROFILES_EDIT", params : { systemConfigProfileId: id, readonly: readonly}})
        },
        cancelCreateDeviceSWUpdateJob(){
            this.$router.go(-1);
        },

        getModelsList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            //this.isLoading=true;
            ApiService.get("/api/v1/deviceModel", (err, result) => {
                this.isLoading=false;
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //console.log("getModelsList " + JSON.stringify(result));
                    this.models_list = result.data.data;
                    this.deviceModelId = this.models_list[0].deviceModelId;
                    //console.log("getModelsList models_list " + JSON.stringify(this.models_list));
                    this.models_list.forEach(item => {
                        this.deviceModelToSwVersionMap.set(item.deviceModelId, []);
                    });
                    this.getModelToSwVersionList();
                }
            }, query_params)
        },
        getModelToSwVersionList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            var body = {
                    //deviceModelIds : [],
                    deviceModelIds : Array.from(this.deviceModelToSwVersionMap.keys()),
            };

            //this.isLoading=true;
            //body.deviceModelIds.unshift(this.deviceModelId);
            //body.deviceModelIds.unshift(Array.from(this.deviceModelToSwVersionMap.keys()));
            console.log("swVersionList: modelIds " + body.deviceModelIds);
            ApiService.post("/api/v1/softwareImage/devicemodel", body, (err, result) => {
                this.isLoading=false;
                if ( err )  {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    console.log("swVersionList: error " + err);
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = result.result;
                    this.swVersionList = result.data.data;
                    this.swVersionList.forEach(item => {
                        let abc = this.deviceModelToSwVersionMap.get(item.deviceModelId);
                        abc.unshift(item);
                    });
                    console.log("swVersionList: " + JSON.stringify(result.data));
                    //this.swVersionSelected = this.swVersionList[0].deviceSoftwareImageId;
                    this._all_devices.forEach(dt => {
                        dt.configProfiles = this.deviceModelToSwVersionMap.get(dt.deviceModelId);
                        if((dt.configProfiles) && (dt.configProfiles.length > 0))
                            dt.selSWImageId = dt.configProfiles[0].deviceSoftwareImageId; 
                        else
                            dt.selSWImageId = null;
                        console.log("pre selected *****:" + dt.selSWImageId);
                    });
                }
            }, query_params)
        },



        getDeviceSWUpdateJobDetails(){
            this.isLoading = true;
            let query_params = {};
            if ( this.targetOrgId ) query_params.targetOrgId = this.targetOrgId;
            else if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/jobsvc/api/v1/jobs/"+this.deviceSWUpdateJobId, (err, result) => {
                this.isLoading = false;
                //console.log("deviceswupdatejob " + JSON.stringify(result))
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.deviceswupdatejobdetails = result.data.data[0];
                    this.selected_devices = this.deviceswupdatejobdetails.devices;
                    var dateStr = this.utilsFormatDateTime2(this.deviceswupdatejobdetails.jobScheduledAt);
                    var dateStrs = dateStr.split(' ');
                    this.scheduledAtDate = dateStrs[0];
                    this.scheduledAtTime = dateStrs[1];

                    //console.log("dateStrs " + JSON.stringify(dateStrs) + " dateStr " + dateStr);
                    this.titleName = (this.readonly ? "View - " : "Edit - ") +this.deviceswupdatejobdetails.jobName
                }
            }, query_params, "JOBS_SERVICE_URL")
        },

        getSWUpdateImages(eligible_devices){
            eligible_devices
/* TBD
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            var deviceIds = eligible_devices.map(x => x.deviceId);

            ApiService.post("/api/v1/deviceSWUpdateMap/devices/configs", { deviceIds: deviceIds }, (err, result) => {
                //console.log(JSON.stringify(result))
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    var devices = result.data.data;

                    //console.log("getSWUpdateImages devices cfgProfiles " + JSON.stringify(devices));

                    // hook retrieved cfgProfiles array to corresponding device in all_devices
                    devices.forEach ( x => {
                            if ( x.configProfiles.length ) {
                                var dev = this._all_devices.find(y => x.deviceId == y.deviceId);
                                if ( dev ) dev.configProfiles = x.configProfiles;
                            }
                        }
                    )

                    this.all_devices = this._all_devices;
                    delete this._all_devices;

                    //console.log("getSWUpdateImages all_devices " + JSON.stringify(this.all_devices));

                    if(this.deviceSWUpdateJobId != "" && this.deviceSWUpdateJobId !=undefined)
                        this.getDeviceSWUpdateJobDetails();
                }
            }, query_params, "API_SERVICE_URL")
*/
            this.all_devices = this._all_devices;
            delete this._all_devices;
            if(this.deviceSWUpdateJobId != "" && this.deviceSWUpdateJobId !=undefined)
                this.getDeviceSWUpdateJobDetails();
        },

        getDevicesLiveStatus(){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            var body = { }
            body.deviceIds = this._all_devices.map(d => d.deviceId);

            ApiService.post("/svcStatus/api/v1/status/device", body, (err, result) => {
                //console.log(JSON.stringify(result))
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //console.log("getDevicesLiveStatus " + JSON.stringify(result.data.data));
                    var liveStatuses = result.data.data.devicesStatusList
                    // merge liveStatuses into all_devices
                    this._all_devices.forEach(dt => {
                        var d = liveStatuses.find(ld => ld.deviceId == dt.deviceId);
                        if ( d ) dt.liveStatus = d.deviceLiveStatus
                    })


                    //var eligible_devices = this._all_devices.filter(x => this.isDeviceRegistered(x));
                    var eligible_devices = this._all_devices;
                    this.num_eligible_devices = eligible_devices.length;

                    if ( this.num_eligible_devices ){
                        this.getSWUpdateImages(eligible_devices);
                    } else if(this.deviceSWUpdateJobId != "" && this.deviceSWUpdateJobId !=undefined){
                        this.all_devices =  this._all_devices;
                        this.getDeviceSWUpdateJobDetails();
                    }
                }
            }, query_params, "STATUS_SERVICE_URL")
        },
        getDevicesList(){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/device", (err, result) => {
                //console.log(JSON.stringify(result))
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this._all_devices = result.data.data;
                    //console.log("getDevicesList " + JSON.stringify(this._all_devices))
                    this._all_devices.forEach(dt => {
                        dt.configProfiles = this.deviceModelToSwVersionMap.get(dt.deviceModelId);
                        if((dt.configProfiles) && (dt.configProfiles.length > 0))
                            dt.selSWImageId = dt.configProfiles[0].deviceSoftwareImageId; 
                        else
                            dt.selSWImageId = null;
                        //console.log("pre selected *****:" + dt.selSWImageId);
                    })
                    this.getDevicesLiveStatus();
                }
            }, query_params, "API_SERVICE_URL")
        },


        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            if(this.deviceSWUpdateJobId != "" && this.deviceSWUpdateJobId !=undefined){
                // Edit can't be done when org is changed, so g back
                this.cancelCreateDeviceSWUpdateJob();
            }else
                this.getDevicesList()
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.targetOrgId = this.$route.params.targetOrgId;
        this.deviceSWUpdateJobId = this.$route.params.deviceSWUpdateJobId;
        this.readonly = (this.$route.params.readonly == "1");
        this.getModelsList();
        this.getDevicesList();
        if(this.deviceSWUpdateJobId != "" && this.deviceSWUpdateJobId !=undefined){
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
        }
    }
}

</script>



