<script>

export default {
     // add here all the methods, props… to share
    data() {
/*
        let ads_rows = [
            {
                _id: 'tiger',
                name: 'Tiger Nixon',
                function: 'System Architect',
                city: 'Edinburgh',
                id: '5421',
                since: '2011/04/25',
                budget: '$320,800',
                _hasChildren: true,
            },
            {
                name: 'Lael Greer',
                function: 'Systems Administrator',
                city: 'London',
                id: '6733',
                since: '2009/02/27',
                budget: '$103,500',
                _showChildren: true,
                _children: [
                    {
                        name: 'Garrett Winters',
                        function: 'Accountant',
                        city: 'Tokyo',
                        id: '8422',
                        since: '2011/07/25',
                        budget: '$170,750',
                    },
                ],
            },
        ];

        ads_rows.length = 5;

        let ads_columns = [
            {
                property: 'id',
                title: 'ID#',
                direction: null,
                filterable: true,
            },
            {
                property: 'name',
                title: 'Name',
                direction: null,
                filterable: true,
            },
            {
                 property: 'function',
                title: 'Function',
                direction: null,
                filterable: true,
                groupable: true,
            },
            {
                property: 'city',
                title: 'City',
                direction: null,
                filterable: true,
            },
            {
                property: 'since',
                title: 'Since',
                direction: null,
                filterable: true,
            },
            {
                property: 'budget',
                title: 'Budget',
                direction: null,
                filterable: true,
            },
        ];
        let classes = {
            group: {
                'vue-ads-font-bold': true,
                'vue-ads-border-b': true,
                'vue-ads-italic': true,
            },
            '0/all': {
                'vue-ads-py-3': true,
                'vue-ads-px-2': true,
            },
            'even/': {
                'vue-ads-bg-blue-lighter': true,
            },
            'odd/': {
                'vue-ads-bg-blue-lightest': true,
            },
            '0/': {
                'vue-ads-bg-blue-lighter': false,
                'vue-ads-bg-blue-dark': true,
                'vue-ads-text-white': true,
                'vue-ads-font-bold': true,
            },
            '1_/': {
                'hover:vue-ads-bg-red-lighter': true,
            },
            '1_/0': {
                'leftAlign': true
            }
        };
*/
        return {

            //ads_rows,
            //ads_columns,
            //classes,

            // ads-table search and paging options
            filterValueAds: '',
            startPage: 1,
            endPage: 10,

            totalPages:1,
            currPage: 0, // AdsTable paging is 0 based
            pageSize: 10,
            itemsPerPage: 10, // !!! reqquired by pages still using Ads table. Do not remove !!!!
        };
    },
    methods: {

        // ads-table search and paging options
        rangeChanged (start, end) {
            //console.log("rangeChanged start " + start + " end " + end);
            this.startPage = start;
            this.endPage = end;
        },
        pageChanged (page) {
            //console.log("pageChanged " + JSON.stringify(page));
            this.currPage = page;
        },
        handlePageSizeChange(size) {
            console.log("handlePageSizeChange " + JSON.stringify(size));
            this.pageSize = size;
            this.currPage = 0;
        },
        filterChanged (filter) {
            // vue-ads is not handling \ correctly. It crashes. So, replace with double backslash here before sending into it
            filter = filter.replace(/\\/g, "\\\\");
            //console.log("filterChanged " + JSON.stringify(filter));
            //console.log("regex " + JSON.stringify(new RegExp(filter, 'i')));
            this.filterValueAds = filter;
        },

        sleep (ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async callRows (indexesToLoad) {
            await this.sleep(1000);
            console.log("callRows indexesToLoad " + JSON.stringify(indexesToLoad));
            return indexesToLoad.map(index => {
                index
                return {
                    name: 'Call Rows',
                    function: 'Developer',
                    city: 'San Francisco',
                    id: '8196',
                    since: '2010/07/14',
                    budget: '$86,500',
                };
            });
        },

        async callChildren (parent) {
            parent
            console.log("callChildren parent " + JSON.stringify(parent));
            await this.sleep(1000);
            return [
                {
                    name: 'Call child',
                    function: 'Developer',
                    city: 'San Francisco',
                    id: '8196',
                    since: '2010/07/14',
                    budget: '$86,500',
                },
            ];
        },

        async callTempRows (filter, columns, start, end) {
            console.log("callTempRows filter " + JSON.stringify(filter) + " columns " + columns + " start " + start + " end " + end);
            filter, columns, start, end
            await this.sleep(1000);
            return {
                rows: [
                    {
                        name: 'Temp CEO ' + filter,
                        function: 'CEO',
                        city: 'San Francisco',
                        id: '8196',
                        since: '2010/07/14',
                        budget: '$86,500',
                        _meta: {visibleChildren: []} /* !!! without this, vue-ads-tree-table crashes !!! */
                    },
                    {
                        name: 'Temp CTO ' + filter,
                        function: 'CTO',
                        city: 'San Francisco',
                        id: '8196',
                        since: '2010/07/14',
                        budget: '$86,500',
                        _meta: {visibleChildren: []} /* !!! without this, vue-ads-tree-table crashes !!! */
                    },
                ],
                total: 2,
            };
        },

   }
}

</script>
