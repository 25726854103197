<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />

    <v-card :elevation="5" class="tile-box">
      <v-card-title class="page-title">
        <v-icon>mdi-router-wireless</v-icon> Devices
        <v-btn rounded outlined color="primary" absolute right :class="isShow" v-on:click="createMonitoredDevice()">
          <v-icon>mdi-plus</v-icon> New
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
          {{ apiStatus }}
        </v-alert>
        <TableAdvancedFilter ref="tableAdvancedFilter" :enabledFieldsIn="tableAdvancedFilter.enabledFilterFields"
          v-on:freeFormSearchChanged="processFreeFormSearchChange"
          v-on:advancedFilterChanged="processAdvancedFilterChange" />
      </v-card-text>
      <v-container fluid class="pt-0">
        <v-data-table :loading="isLoading" :headers="monitoredDeviceTableColumnsVueTable"
          :items="monitored_device_table_rows" :options.sync="pagingOptions"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }"
          :server-items-length="utilsTotalEntriesAllPages" item-key="monDevId" no-data-text="No devices" multi-sort>
          <template v-slot:item.monDevName="{ item }">
            {{ item.monDevName }}
          </template>
          <template v-slot:item.monDevVendorModel="{ item }">
            <v-icon v-if="item.imageInfo && item.imageInfo.icons && item.imageInfo.icons.mdi"
              :icon="item.imageInfo.icons.fa" >{{ item.imageInfo.icons.mdi }}</v-icon>
            {{ item.monDevVendorModel }}
          </template>

          <template v-slot:item.siteName="{ item }">
            <a href="javascript:;" v-on:click="navigateToSite(item.siteId)">
              {{ item.siteName }}
            </a>
          </template>

          <template v-slot:item.statuses="{ item }">

            <!-- for the agent device itself, liveStatus will be null - nmap detects it, but ARP won't detect it 
                if it's explicitly not online/offline, it's shown as Unknown -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" :class="utilsLiveStatusDisplayObject(item.liveStatus).class"
                  :color="utilsLiveStatusDisplayObject(item.liveStatus).colorName">
                  {{ utilsLiveStatusDisplayObject(item.liveStatus).icon }}
                </v-icon>
              </template>
              <span>{{ utilsLiveStatusDisplayObject(item.liveStatus).text }}</span>
            </v-tooltip>

            <!-- key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully -->
            <v-tooltip top :key="statusesKey1">
              <!-- display icon (faulty/good) with highestSeverity color -->
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"
                  :color="utilsGetSeverityColor(item.highestSeverity).colorName">
                  {{ utilsFaultStatusDisplayObject(item.faultStatus).icon }}
                </v-icon>
              </template>

              <span v-if="item.highestSeverity">{{ item.highestSeverity }}</span>
              <span v-else>{{ utilsFaultStatusDisplayObject(item.faultStatus).text }}</span>

            </v-tooltip>

            <span v-if="item.incCount" :key="statusesKey2">
              (
              <a href="javascript:;" title="# Incidents" v-on:click="navigateToIncidents(item)">
                {{ item.incCount }}
              </a>
              )
            </span>

          </template>

          <template v-slot:item.actions="{ item }">
            <nobr>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" class="mr-2" v-on="on" color="teal">mdi-information-outline</v-icon>
                </template>
                <span>Importance: {{ item.monDevImportance }} <br> </span>
                <span>Uptime guess: {{ item.monDevUptimeStr }} <br> </span>
                <span>Last updated: {{ item.modifiedDateString }} <br> </span>
                <span v-if="item.assignedTemplatesStr">{{ item.assignedTemplatesStr }} <br> </span>
                <span v-if="item.serviceNames">{{ item.serviceNames }}</span>
              </v-tooltip>
              <v-icon @click="viewMonitoredDeviceDetails(item.monDevId)" class="mr-2" color="primary" title="View">
                mdi-eye
              </v-icon>
              <v-icon :class="utilsGetDisplayClass(item)" @click="editMonitoredDeviceDetails(item.monDevId, 0)" class="mr-2" color="warning" title="Edit">
                mdi-pencil
              </v-icon>
              <v-icon :class="utilsGetDisplayClass(item)" @click="showDialogToDelete(item.monDevId, item.monDevName)" title="Delete" color="red">
                mdi-delete
              </v-icon>
            </nobr>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <!-- !!! Some of the params and functions used here are defined in AdsTreeTableUtils.vue mixin !!!!  -->
    <!-- !!! When async functionality implemented for this table, callRows, callChildren, callTempRows routines need to be
                 brought back to this file !!!!! -->
    <DeleteDialog v-if="open_delete_dialog" :currentObjectName="currentMonDevName" :isBackupBeforeDelete="false"
      @DeleteDialogCallback="deleteMonitoredDeviceDetailsChildEvent" :delMessage="deleteMessage"> </DeleteDialog>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import auth from "@/services/auth.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import AdsTreeTableUtils from "@/pages/moda/AdsTreeTableUtils.vue";
import Utils from "@/pages/moda/Utils.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TableAdvancedFilter from '@/components/templates/TableAdvancedFilter'
import ModaConst from "@/services/ModaConstants.js";


export default {
  components: {
    Breadcrumb,
    DeleteDialog,
    TableAdvancedFilter,
  },
  mixins: [AdsTreeTableUtils, Utils],
  data() {
    var monitoredDeviceTableColumnsVueTable = [
      {
        text: "Name",
        value: "monDevName",
        sortable: true,
        filterable: true,
      },
      {
        text: "MAC Vendor & Model",
        value: "monDevVendorModel",
        sortable: true,
        filterable: true,
      },
/*
      {
        text: "Model",
        value: "monDevModel",
        sortable: true,
        filterable: true,
      },
*/
      {
        text: "MAC",
        value: "monDevMACAddress",
        sortable: true,
        filterable: true,
      },
      {
        text: "IPAddr",
        value: "monDevIpAddress",
        sortable: true,
        filterable: true,
      },
      {
        text: "Site",
        value: "siteName",
        sortable: true,
        filterable: true,
      },
/*
      {
        text: "Templates",
        value: "assignedTemplatesStr",
        sortable: true,
        filterable: true,
      },
      {
        text: "Zbx",
        value: "zbxMonitored",
        sortable: true,
        filterable: true,
      },
      {
        text: "Services",
        value: "serviceNames",
        sortable: true,
        filterable: true,
      },
      {
        text: "Importance",
        value: "monDevImportance",
        sortable: true,
        filterable: true,
      },
      {
        text: "Uptime",
        value: "monDevUptimeStr",
        sortable: true,
        filterable: true,
      },
*/
      {
        text: "Liveness / Fault Status (#Incidents)",
        value: "statuses",
        sortable: true,
        filterable: true,
      },
/*
      {
        text: "Highest Sev",
        value: "highestSeverity",
      },
      {
        text: "Last updated",
        value: "modifiedDateString",
      },
*/
      {
        text: "Actions",
        value: "actions",
        sortable: false
      },
    ];
    return {
      currOrgId: null,
      monitoredDeviceTableColumnsVueTable,
      sites_list: [],
      //   monitored_device_table_columns,
      monitored_device_table_rows: [],
      isLoading: false,
      userdetails: {},
      isShow: "display-none",
      parent_pages: [
      { name: "Home" },
      { name: "Devices", active: true },
      ],
      apiStatus: null,
      open_delete_dialog: false,
      currentMonDevId: null,
      currentMonDevName: "",
      confirmToDelete: null,
      isHardDelete: null,
      more_params_row: {},
      statusesKey1: 0, // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
      statusesKey2: 1, // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
      open_more_params_dialog: false,
      //options: { page: 1, itemsPerPage: 10 },
      pagingOptions: sessionStorage.getItem(`_pagination${this.$route.path}`) ? JSON.parse(sessionStorage.getItem(`_pagination${this.$route.path}`)) : this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
      //   totalEntriesAllPages: []


      //Note: Values of site(List) and component(List) are passed in a tuple of (idList & nameList)
      //      Map() or Set() can not be used as 'id' or 'name' could be empty
      // During a cross launch to this page from other pages, the passed in values are set in xLaunch
      xLaunch : {
        site: {
            siteIdList: [],         // ["sitobj.1001"] 
            siteNameList: []        // ["Austin"]
        },
      },
      tableAdvancedFilter : {
        enabledFilterFields : [], //this.utilsAdvancedFilterFields template doesn't work directly using mixin's data. Init it in mounted()
      },
      defaultAdvSearchCriteria: [
/*
        {"name":"highestSeverity", // default Major and above states
            "value": [
                ModaConst.FaultService.Severity.CRITICAL,
                ModaConst.FaultService.Severity.SEVERE,
                ModaConst.FaultService.Severity.MAJOR,
            ]
        },
*/
      ],
    };
  },
  computed: {
    deleteMessage() {
      return "Do you really want to delete: " + this.currentMonDevName + " ?";
    },
  },
  watch: {
      pagingOptions: {
        handler () {
            //console.log("incident watch pagingOptions " + JSON.stringify(this.pagingOptions));
            sessionStorage.setItem(`_pagination${this.$route.path}`, JSON.stringify(this.pagingOptions));
            if ( ! this.isLoading2 ) this.getMonitoredDevicesList();          
        },
        deep: false,
        immediate: false,
      },
  },
  methods: {
/* NOT USED. Backend returns aggregated list already
    updateMonitoredDeviceStatus ( monDevCfgList, monDevStsList ){
        monDevCfgList.forEach ( function (monDevCfg) {
            let monDevSts = monDevStsList.find( (monDevSts) => monDevSts.monDevId == monDevCfg.monDevId )
            if ( monDevSts ) {
                monDevCfg.faultStatus      = monDevSts.faultStatus
                monDevCfg.highestSeverity  = monDevSts.highestSeverity
                monDevCfg.incCount         = monDevSts.incCount
            }
        }, this)
    },
    getMonitoredDevicesStatusList() {
      let query_params = {}, body={};
      if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

      this.isLoading=true

      query_params.summaryDataType = this.UtilsModaConstants.FaultService.Summary.DataType.MONITORED_DEVICES,
      query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.LISTS

      body = this.monitored_device_table_rows.map( (s) => s.monDevId )

      //console.log("getMonitoredDevicesStatusList body " + JSON.stringify(body))

      ApiService.post( "/svcSummary/api/v1/summary", body, (err, result) => {   //### svcFault
          this.isLoading = false;
          if (err) {
            this.apiStatus = "Failed to fetch Devices' fault status"
            //this.utilsCheckLogout(this.apiStatus);
          }else {
            this.apiStatus = null;
            //console.log("**** getMonitoredDevicesStatusList monDevs result = " + JSON.stringify(result.data.data, null, 2))
            this.updateMonitoredDeviceStatus(this.monitored_device_table_rows, result.data.data)
            //console.log("**** getMonitoredDevicesStatusList monDevs = " + JSON.stringify(this.monitored_device_table_rows, null, 2))
            this.statusesKey1 +=1 ; // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
            this.statusesKey2 +=1 ; // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
          }
        }, query_params, "SUMMARY_SERVICE_URL" //### "FAULT_SERVICE_URL"
      );
    },
*/
    getMonitoredDevicesList() {
      //   this.isLoading = true;

      let query_params = {}, body = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      query_params.pageNum = this.pagingOptions.page;
      query_params.pageSize= this.pagingOptions.itemsPerPage;

      var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, { monDevVendorModel: "monDevVendor", statuses:"highestSeverityNum"});
      query_params.sortCriteria = sortCriteria || "highestSeverityNum:desc";

      //if (this.filterValueSearch) query_params.searchString = "any:*:" + this.filterValueSearch;
      if ( this.freeFormSearchStr ) query_params.searchString= "any:*:" + this.freeFormSearchStr

      if ( this.advSearchCriteria ) {
        //console.log("getMonitoredDevicesList advSearchCriteria ", JSON.stringify(this.advSearchCriteria))
        var advSearchCriteria = []
        Object.keys(this.advSearchCriteria).forEach( (k) => advSearchCriteria.push(this.advSearchCriteria[k]) )
        if ( advSearchCriteria.length ) body.advSearchCriteria = advSearchCriteria
        //console.log("getMonitoredDevicesList body ", JSON.stringify(body))
      }

      //###ApiService.post( "/svcInventory/api/v1/monitoredDevice/all/page2?shorten=true", body, (err, result) => {
      ApiService.post( "/svcSummary/api/v1/list/mondev?shorten=true", body, (err, result) => {
          this.isLoading = false;
          if (err){
            this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
            this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            // this.totalPages = result.data.data.totalPages;
            // this.totalEntriesAllPages =
            this.utilsTotalEntriesAllPages = result.data.data.totalEntries;
            this.monitored_device_table_rows = result.data.data.docs;
            //console.log("mondev list ", result.data.data.docs);
            this.buildFilterableColumns(this.monitored_device_table_rows);
            this.isLoading = false;
            //this.getMonitoredDevicesStatusList(); // Backend already returns aggregated list
          }
        },
        query_params, "SUMMARY_SERVICE_URL"
        //### query_params, "INVENTORY_SERVICE_URL"
      );
    },
    buildFilterableColumns(objs) {
      objs.forEach((obj) => {
        obj.monDevVendorModel = (obj.monDevVendor ? obj.monDevVendor + " / " : "") + obj.monDevModel;
        obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
        obj.modifiedDateString = this.utilsFormatDateTime(obj.modifiedDate);
        //obj.zbxMonitored = (obj.zbxMonitored === true ? "\u2714" : "\u2718");
        obj.monDevUptimeStr = this.utilsFormatSeconds2Str(obj.monDevUptime); 
        obj.adminStatus = (obj.adminStatus === 'Enabled' ? "\u2714" : "\u2718");

        obj.assignedTemplatesStr = obj.assignedTemplateNames && obj.assignedTemplateNames != "" ? "Templates : " + obj.assignedTemplateNames : null 
        obj.serviceNames = obj.services.map((s) => s.service + "/" + s.port)
        obj.serviceNames = obj.serviceNames.join(", ");
        obj.serviceNames = obj.serviceNames && obj.serviceNames != "" ? "Services : " + obj.serviceNames : null;
        if ( obj.reflectedFaultStatus == ModaConst.FaultService.FaultStatus.UNKNOWN ){
            obj.faultStatus = ModaConst.FaultService.FaultStatus.UNKNOWN
            obj.liveStatus = ModaConst.FaultService.FaultStatus.UNKNOWN
            obj.highestSeverity = ModaConst.FaultService.FaultStatus.UNKNOWN
        }
      });
    },
    createMonitoredDevice() {
      this.$router.push({name : "MONITORED_DEVICES_CREATE"} )
    },
    editMonitoredDeviceDetails(id, readonly) {
      this.$router.push({name : "MONITORED_DEVICES_EDIT", params: { id: id, readonly: readonly } } )
    },
    viewMonitoredDeviceDetails(id) {
      auth.crossLaunchMonDevsAdvSearch = this.advSearchCriteria // save this state to come back to same search state
      this.$router.push({name : "MONITORED_DEVICES_VIEW", params: { id: id } } )
    },
    navigateToIncidents(row) {
        //console.log("navigateToIncidents monDevRowSelected ", JSON.stringify(row));
        var monDevIds = [], monDevNames=[], states = []//, advSearchString = "";
        monDevIds = [row.monDevId]
        monDevNames = [row.monDevName]
        states = this.utilsOpenStates;
        //advSearchString = "Open incs. in " + row.monDevName;

        var query = [];
        if (monDevIds.length && monDevNames.length) {
            //OLD: query.push({ name: "monDevId", value: monDevIds });
            //query.push({ name: "monDev", value: {monDevIdList: monDevIds, monDevNameList: monDevNames} });
            query.push({ name: "component", value: {
                        componentIdType: this.UtilsModaConstants.FaultService.ComponentIdType.MONITORED_DEVICE,
                        componentIdList: monDevIds, componentNameList: monDevNames} });
            //query.push({ name: "componentId", value: monDevIds });
        }
        /*~~~ OLD ~~~
        if (monDevNames.length) {
            query.push({ name: "monDevName", value: monDevNames });
            //query.push({ name: "componentName", value: monDevNames });
        }
        ~~~~~~~~~~~~~~*/

        query.push({ name: "state", value: states });
        console.log("navigateToIncidents query ", JSON.stringify(query));
        query.length ? this.$router.push({ name: "INCIDENTS", query: query }) : this.$router.push({ name: "INCIDENTS" });
    },
    navigateToSite(id) {
      var readonly = 1;
      this.$router.push({ name: "SITES_EDIT", params: { siteId: id, readonly: readonly } })
    },

    deleteMonitoredDeviceDetailsChildEvent(cancelFlag, isBackupDel) {
      console.log(
        "** Delete Site:" +
          this.currentMonDevId +
          " " +
          this.currentMonDevName +
          " " +
          isBackupDel
      );
      this.open_delete_dialog = false;
      this.isLoading = false;
      if (cancelFlag == true) return;

      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      query_params.deleteMode = this.UtilsModaConstants.DeleteMode.FORCE;

      ApiService.delete(
        "/svcInventory/api/v1/monitoredDevice/" + this.currentMonDevId,
        (err, result) => {
          console.log("result", result);
          this.isLoading = false;
          if (err){
           this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
           this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            this.getMonitoredDevicesList();
          }
        },
        query_params, "INVENTORY_SERVICE_URL"
      );
    },

    showDialogToDelete(id, name) {
      this.currentMonDevId = id;
      this.currentMonDevName = name;
      this.open_delete_dialog = true;
      this.isHardDelete = false;
      console.log("delete dialog called " + this.open_delete_dialog);
    },

    get_auth_details() {
      let title = this.$route.meta.title;
      //console.log('---'+title)
      this.isShow = auth.AuthService.get_usr_auth_details(title);
    },

    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      this.clearPaginationCache();
      this.getMonitoredDevicesList();
    },
    clearPaginationCache(){
      sessionStorage.removeItem(`_pagination${this.$route.path}`);
      this.pagingOptions.page = 1;
    },

    processFreeFormSearchChange(freeFormSearchStr){
        this.freeFormSearchStr = freeFormSearchStr
        auth.globalMonDevsFreeFormSearchStr = freeFormSearchStr
        console.log("MonitoredDevices processFreeFormSearchChange " + this.freeFormSearchStr)
        this.clearPaginationCache();
        this.getMonitoredDevicesList();
    },
    processAdvancedFilterChange(advancedFilter){
        //console.log("Table filter selected " + JSON.stringify(advancedFilter))

        this.pagingOptions.page = 1;        // Reset page to 1st page, for AdvSearch APPLY

        // append to advanced search passed in, if any
        this.advSearchCriteria = []
        //this.advSearchString = ""

/* orgName filter removed
        if ( advancedFilter.orgNameFilter ){
            this.advSearchCriteria.push({"name":"orgName", "value": advancedFilter.orgNameFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.orgNameFilter)
        }
*/
        if ( advancedFilter.siteNameFilter ){
            if (advancedFilter.siteNameFilter == null || advancedFilter.siteNameFilter == [] ||
                !this.utilsIsSameList(advancedFilter.siteNameFilter, this.xLaunch.site.siteNameList)) {
                //advancedFilter.siteNameFilter != this.xLaunch.site.siteNameList)
                    // siteNameList is empty or cross-launched siteNameList is modified. 
                    // Let the backend calculate the Ids, as needed
                    this.xLaunch.site.siteIdList = null;
            }
            this.advSearchCriteria.push({"name":"site",
                                         "value": {siteIdList: this.xLaunch.site.siteIdList, siteNameList: advancedFilter.siteNameFilter}})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.siteNameFilter)
        }

        if ( advancedFilter.highestSeverityFilter ){
            this.advSearchCriteria.push({"name":"highestSeverity", "value": advancedFilter.highestSeverityFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.highestSeverityFilter)
        }
        if ( advancedFilter.devNameFilter ){
            this.advSearchCriteria.push({"name":"monDev",
                                         "value": {monDevIdList: null, monDevNameList: advancedFilter.devNameFilter}})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devNameFilter)
        }
        if ( advancedFilter.devLiveStatusFilter ){
            this.advSearchCriteria.push({"name":"liveStatus", "value": advancedFilter.devLiveStatusFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devLiveStatusFilter)
        }
        if ( advancedFilter.devMACAddressFilter ){
            this.advSearchCriteria.push({"name":"monDevMACAddress", "value": advancedFilter.devMACAddressFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devMACAddressFilter)
        }
        if ( advancedFilter.devIpAddressFilter ){
            this.advSearchCriteria.push({"name":"monDevIpAddress", "value": advancedFilter.devIpAddressFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devIpAddressFilter)
        }
        if ( advancedFilter.devModelFilter ){
            this.advSearchCriteria.push({"name":"monDevModel", "value": advancedFilter.devModelFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devModelFilter)
        }
        if ( advancedFilter.devVendorFilter ){
            this.advSearchCriteria.push({"name":"monDevVendor", "value": advancedFilter.devVendorFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devVendorFilter)
        }
        if ( advancedFilter.devOSFilter ){
            this.advSearchCriteria.push({"name":"monDevOS", "value": advancedFilter.devOSFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devOSFilter)
        }


        auth.globalMonDevsAdvSearch       = this.advSearchCriteria;
        //auth.globalMonDevsAdvSearchString = this.advSearchString;
        this.clearPaginationCache();
        this.getMonitoredDevicesList();
    },
    init(){
        if ( this.advSearchCriteria ){
            //console.log("MonitoredDevices.vue init this.advSearchCriteria " + JSON.stringify(this.advSearchCriteria))
            var tmp

            tmp = this.advSearchCriteria.find(e => e.name == "site")
            if ( tmp ) {
                this.tableAdvancedFilter.siteNamesFilterIn  = tmp.value.siteNameList;
                // siteNames are only to pass to this component, orgIds are sent to backend
                // send names to backend
                // this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "siteName")
                this.xLaunch.site = tmp.value;
            } else { // set default
                this.tableAdvancedFilter.siteNamesFilterIn = [];
                this.xLaunch.site = {siteIdList: [], siteNameList: []};
            }

            tmp = this.advSearchCriteria.find(e => e.name == "highestSeverity")
            if ( tmp ) this.tableAdvancedFilter.highestSeveritiesFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "monDev")
            if ( tmp ) this.tableAdvancedFilter.devNameFilterIn  = tmp.value.monDevNameList

            tmp = this.advSearchCriteria.find(e => e.name == "liveStatus")
            if ( tmp ) this.tableAdvancedFilter.devLiveStatusFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "monDevMACAddress")
            if ( tmp ) this.tableAdvancedFilter.devMACAddressFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "monDevIpAddress")
            if ( tmp ) this.tableAdvancedFilter.devIpAddressFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "monDevModel")
            if ( tmp ) this.tableAdvancedFilter.devModelFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "monDevVendor")
            if ( tmp ) this.tableAdvancedFilter.devVendorFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "monDevOS")
            if ( tmp ) this.tableAdvancedFilter.devOSFilterIn  = tmp.value
        }

        this.$refs.tableAdvancedFilter.setAdvancedFilters(this.tableAdvancedFilter, this.freeFormSearchStr)

        //console.log("MonitoredDevice init orgNamesFilter " + this.tableAdvancedFilter.orgNamesFilterIn + 
                            //", MonitoredDevicestatusFilter " + this.tableAdvancedFilter.MonitoredDevicestatusFilterIn)
    },

  },
  mounted() {

    if ( this.$route.params && this.$route.params.targetOrgId ){
        this.$refs.breadcrumb.navigateToOrg(this.$route.params.targetOrgId)
        this.currOrgId = this.$route.params.targetOrgId
    } else
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();

    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.FREEFORM_SEARCH)
    //this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.ORG_NAME) // orgName removed
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.SITE_NAME)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.HIGHEST_SEVERITY)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_NAME)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_LIVE_STATUS)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_MAC_ADDRESS)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_IP_ADDRESS)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_MODEL)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_VENDOR)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_OS)

    if ( this.$route.query && Array.isArray(this.$route.query) ) {
         // query passed in from cross Launch
         this.advSearchCriteria = this.$route.query
         auth.globalMonDevsAdvSearch = this.advSearchCriteria
    } else if (auth.crossLaunchMonDevsAdvSearch)
      // else, take the one from crossLaunch state - the one we set when we navigated away from this page
      this.advSearchCriteria = auth.crossLaunchMonDevsAdvSearch
    else if ( auth.globalMonDevsAdvSearch )
         // else, take the one from global state
         this.advSearchCriteria = auth.globalMonDevsAdvSearch
    else // else, default
         this.advSearchCriteria = this.defaultAdvSearchCriteria;
/*
    if ( this.$route.params && this.$route.params.advSearchString )
         this.advSearchString = this.$route.params.advSearchString
    else if ( auth.globalMonDevsAdvSearchString )
         this.advSearchString = auth.globalMonDevisAdvSearchString
*/

    if ( auth.globalMonDevsFreeFormSearchStr )
        this.freeFormSearchStr = auth.globalMonDevsFreeFormSearchStr

    this.init()

    this.get_auth_details();
    //this.getMonitoredDevicesList(); -- called in watch
    //### NOT NEEDED: this.getSitesList();
    this.utilsDataRefreshFunc = this.getMonitoredDevicesList;
  },
};
</script>
