<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import zoomPlugin from 'chartjs-plugin-zoom';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineController, 
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js' 

ChartJS.register(Title, Tooltip, Legend, BarElement, LineController, LineElement, CategoryScale, LinearScale, zoomPlugin)

export default {
  name: 'MixedChart',
  components: {
    Bar
  },
  props: {
    chartData: {
      type: Object,
      required: true,
      default: () => { return { data: [], label: "" }}
    },
    chartOptions: {
      type: Object,
      required: true,
      default:  () => { return {}}
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  }
}
</script>
