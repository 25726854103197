<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />

		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-circle-multiple</v-icon> MODA Agent Software Images
				<v-btn rounded outlined color="primary" absolute right :class="isShow" v-on:click="createAdminTaskSwImages()">
					<v-icon>mdi-plus</v-icon> New
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<div class="search-wrapper">
					<v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify"
						@input="utilsFilterChanged" />
				</div>
			</v-card-text>
			<v-container fluid class="pt-0">
				<v-data-table :loading="isLoading" :headers="AdminTaskSwImages_table_columns" :items="swVersionList" multi-sort
					:search="filterValueSearch" :sort-by.sync="AdminTaskSwImages_table_sortby" :sort-desc.sync="AdminTaskSwImages_table_sortdesc">

					<template v-slot:item.jobId="{ item }">
						<span> {{ item.jobId.split('_')[0].split("jobinf.")[1] }} </span>
					</template>

					<template v-slot:item.devices="{ item }">
						<span v-for="device in item.devices" :key="device.deviceId">
							<a class="link-text" :title="device.deviceName" v-on:click="navigateToDevice(device.deviceId)">
								{{ device.deviceName }} </a>
							<br />
						</span>
					</template>

					<template v-slot:item.scheduledDateString="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" small
									:class="utilsJobScheduleTypeDisplayObject(item.jobScheduleType).class">
									{{ utilsJobScheduleTypeDisplayObject(item.jobScheduleType).icon }}
								</v-icon>
							</template>
							<span>{{ utilsJobScheduleTypeDisplayObject(item.jobScheduleType).text }}</span>
						</v-tooltip>
						{{ item.scheduledDateString }}
					</template>

					<template v-slot:item.jobStatus="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" small :class="utilsJobStatusDisplayObject(item.jobStatus).class">
									{{ utilsJobStatusDisplayObject(item.jobStatus).icon }}
								</v-icon>
							</template>
							<span>{{ utilsJobStatusDisplayObject(item.jobStatus).text }}</span>
						</v-tooltip>
					</template>

					<template v-slot:item.createdBy="{ item }">
						<a class="link-text" :title="item.createdByEmail" v-on:click="navigateToUser(item.createdBy)">
							{{ item.createdByEmail }}
						</a>
					</template>

					<template v-slot:item.actions="{ item }">
							<v-icon @click="viewAdminTaskSwImagesDetails(item.deviceSoftwareImageId)" class="mr-2" color="primary"
								title="View">mdi-eye</v-icon>
<!-- :class="utilsGetDisplayClass(item)" isn't working for these icons for some reason -->
							<v-icon :class="isShow"
                                @click="editAdminTaskSwImagesDetails(item.deviceSoftwareImageId, 0)" class="mr-2"
								color="warning" title="Edit">mdi-pencil</v-icon>
							<v-icon :class="isShow"
                                @click="deleteAdminTaskSwImagesDetails(item.deviceSoftwareImageId, item.deviceSoftwareImageName)"
								color="error" title="Delete">mdi-delete</v-icon>
<!--
                            <span :class="utilsGetDisplayClass(props.row)">
									<v-icon small @click="editAdminTaskSwImagesDetails(props.row.jobId, 0)"
											class="mr-2 record-edit" title="Edit"> fas fa-pencil-alt </v-icon>
									<v-icon v-if="isCancellable(props.row)" small 
											@click="cancelAdminTaskSwImagesDetails(props.row.deviceSoftwareImageId, props.row.deviceSoftwareImageName)"
											class="mr-2 record-edit" title="Cancel"> fas fa-times </v-icon>
							</span>
-->
					</template>

				</v-data-table>
			</v-container>
		</v-card>
		<DeleteDialog v-if="deleteDialog" :currentObjectName="currName" @DeleteDialogCallback='deleteObjectChildEvent'>
		</DeleteDialog>
	</div>
</template>

<script>

import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import AdsTreeTableUtils from '@/pages/moda/AdsTreeTableUtils.vue'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'

export default {
    components: {
        Breadcrumb,
        DeleteDialog,
    },
    mixins: [
        AdsTreeTableUtils,
        Utils,
    ],

/***
  {
    "orgId": null,
    "deviceSoftwareImageId": "devswi.10005",
    "deviceModelId": "devmod.1005",
    "deviceSoftwareImageName": "AccellexSoftware-XPB-800",
    "deviceSoftwareImageVersion": 1,
    "deviceSoftwareImageDate": 1617143484546,
    "deviceSoftwareImageFilename": "devmod.1005_moda_root.tgz",
    "description": "Device Software Image for Axcellex XPB-800",
    "entryType": "System"
  }
****/

    data(){
        var AdminTaskSwImages_table_columns= [
           {
               text: 'Name',
               value: 'deviceSoftwareImageName',
               direction: true,
               filterable: true,
           },
/*
           {
			   text: 'Model Id',
			   value: 'deviceModelId',
               direction: true,
               filterable: true,
           },
*/
           {
               text: 'Model',
               value: 'deviceModelName',
               direction: true,
               filterable: true,
           },
           {
						text: 'Version',
						value: 'deviceSoftwareImageVersion',
               direction: true,
               filterable: true,
           },
           {
						text: 'File Name',
						value: 'deviceSoftwareImageFilename',
               direction: true,
               filterable: true,
           },
           {
						text: 'Release Date',
						value: 'deviceSoftwareImageDateString',
               direction: true,
               filterable: true,
           },
           {
						text: 'description',
						value: 'description',
               filterable: true,
           },
           {
						text: 'Actions',
						value: 'actions',
           },

        ];


        return {
            currOrgId: null,

            models_list:[],
            swVersionList:[],
            deviceModelToSwVersionMap: new Map(),
            // Jobs data
            AdminTaskSwImages_table_columns,
            AdminTaskSwImages_table_sortby: 'deviceSoftwareImageDateString',
            AdminTaskSwImages_table_sortdesc: true,

            // Job Runs data
            currPageJobRuns: 0,
            startPageJobRuns: 0,
            endPageJobRuns: 10,
            itemsPerPageJobRuns: 10,
            filterValueJobRuns : '',

            isLoading: false,

            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, {name: "Admin Tasks"},
                           {name:'MODA Agent Software', active:true} ],
            apiStatus: null,

            deleteDialog:false,
            currId:null,
            currName:null,

        };
    },

    methods: {
        getAdminTaskSwImagesList() {
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            this.isLoading=true;
            ApiService.get("/api/v1/deviceModel", (err, result) => {
                this.isLoading=false;
                if ( err ) {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout();
                } else {
                    this.apiStatus = null;
                    //console.log("getModelsList " + JSON.stringify(result));
                    this.models_list = result.data.data;
                    this.deviceModelId = this.models_list[0].deviceModelId;
                    //console.log("getModelsList models_list " + JSON.stringify(this.models_list));
                    this.models_list.forEach(item => {
                        this.deviceModelToSwVersionMap.set(item.deviceModelId, []);
                    });
                    this.getModelToSwVersionList();
                }
            }, query_params)
        },
        getModelToSwVersionList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            var body = {
                    deviceModelIds : Array.from(this.deviceModelToSwVersionMap.keys()),
            };

            //this.isLoading=true;
            //console.log("swVersionList: modelIds " + body.deviceModelIds);
            ApiService.post("/api/v1/softwareImage/devicemodel", body, (err, result) => {
                this.isLoading=false;
                if ( err )  {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    console.log("swVersionList: error " + err);
                } else {
                    this.apiStatus = result.result;
                    this.swVersionList = result.data.data;
                    this.swVersionList.forEach(item => {
                        let abc = this.deviceModelToSwVersionMap.get(item.deviceModelId);
                        abc.unshift(item);
                        item.deviceSoftwareImageDateString = this.utilsFormatDateTime2UTC(item.deviceSoftwareImageDate).substring(0,10);


                    });
                    //console.log("swVersionList: " + JSON.stringify(result.data));
                }
            }, query_params)
        },

        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
        buildFilterableColumns(objs){
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                obj.scheduledDateString = this.utilsFormatDateTime(obj.scheduledAt);
                //if (obj.deployedProfiles) // this same routine is used for bot jobs and job runs. deployedProfiles only is in jobrun
                    //obj.deployedProfiles.forEach((prof) => {
                        //prof.configProfileVersionString = this.utilsFormatDateTime(prof.configProfileVersion*1000);
                    //}, this)
                if ( obj._children ) this.buildFilterableColumns(obj._children);
            })
        },
        viewAdminTaskSwImagesDetails(id){
            this.$router.push( { name: "SW_IMAGES_VIEW", params: { id: id}})
        },

        editAdminTaskSwImagesDetails(id, readonly){
            this.$router.push( { name: "SW_IMAGES_EDIT", params: { id: id, readonly: readonly}})
        },
        cancelAdminTaskSwImagesDetails(Id, name){
            if(confirm("Do you really want to cancel - '" + name + "' ?")) {
                this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

                ApiService.put("/api/v1/softwareImage/cancel/"+Id, {}, (err, result) => {
                    //console.log("**"+JSON.stringify(result))
                    this.isLoading=false
                    if ( err ) this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    else {
                        this.apiStatus = null;
                        this.getAdminTaskSwImagesList();
                    }
                }, query_params)
            }
        },
        deleteAdminTaskSwImagesDetails(Id, name){
            this.deleteDialog = true;
            this.currId = Id;
            this.currName = name;
        },
        deleteObjectChildEvent(cancelFlag, isBackupDel) 
        {
            console.log("** delete " + isBackupDel);
            this.deleteDialog = false;
            if(cancelFlag == true) return;

            this.isLoading =true;

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

            ApiService.delete("/api/v1/softwareImage/"+this.currId, (err, result) => {
                    this.isLoading=false
                    if (err) this.apiStatus = 
              (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    else {
                        this.apiStatus = null;
                        this.getAdminTaskSwImagesList();
                    }
                }, query_params );
        },
        createAdminTaskSwImages() {
            this.$router.push( { name: "SW_IMAGES_CREATE"})
        },
        get_auth_details() {
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.getAdminTaskSwImagesList();
        },
        rangeChangedJobRuns (start, end) {
            this.startPageJobRuns = start;
            this.endPageJobRuns = end;
        },
        pageChangedJobRuns (page) {
            this.currPageJobRuns = page;
        },

    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.get_auth_details();
        this.getAdminTaskSwImagesList();
    }
}
</script>
