<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-chart-timeline-variant</v-icon> Analytics Profile {{ titleName }}

			</v-card-title>
			<v-card-text class="pb-0">
				<v-container fluid class="pl-5 pr-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<form ref="analyticsConfigProfileform" class="form-view">
						<v-row v-if="isLoading">
							<v-col cols="12">
								<v-progress-linear indeterminate color="primary"></v-progress-linear>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12" sm="6">
									<strong>Config Profile Name</strong>
									<p>{{ analyticsConfigProfileDetails.analyticsConfigProfileName ||  "--" }}</p>
							</v-col>
							<v-col cols="12" sm="6">
									<strong>Description</strong>
									<p>{{ analyticsConfigProfileDetails.description ||  "--" }}</p>
							</v-col>
							<v-col cols="12">
								<v-autocomplete v-model="selected_applications" :items="all_applications" item-key="appId"
									item-text="appName" chips clearable multiple prepend-inner-icon="mdi-apps"
									:return-object="true" label="Applications"
									:disabled="readonly">

									<template v-slot:item="{ item, on, attrs }">
										<v-list-item v-bind="attrs" v-on="on">
											<template v-slot:default="{ active }">
												<v-list-item-action>
													<v-checkbox :input-value="active"></v-checkbox>
												</v-list-item-action>

												<v-list-item-content>
													<span v-on:click="navigateToApplication(item.appId)">
														<a class="link-text"> {{ item.appName }} </a>
													</span>
												</v-list-item-content>
											</template>
										</v-list-item>
									</template>
									<template v-slot:selection="{ item, select, selected }">
										<v-chip :input-value="selected" :close="!readonly" @click="select" color="primary" dark
											@click:close="remove_application(item)">
											<span v-on:click="navigateToApplication(item.appId)">
												<a class="link-text"> {{ item.appName }} </a>
											</span>
										</v-chip>
									</template>

								</v-autocomplete>
							</v-col>
							<v-col cols="12" sm="4">
									<strong>Syslog Listen Port</strong>
									<p>{{ analyticsConfigProfileDetails.syslog.listenPort ||  "--" }}</p>
							</v-col>
							<v-col cols="12" sm="4">
									<strong>Protocol</strong>
									<p>{{ analyticsConfigProfileDetails.syslog.protocol ||  "--" }}</p>
							</v-col>
							<v-col cols="12" sm="4">
									<strong>Syslog Receive</strong>
									<p>{{ utilStatus1 ||  "--" }}</p>
							</v-col>
						</v-row>
					</form>
				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>

import ApiService from '@/services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            currOrgId: null,
            targetOrgId: null,
            applicationsSelectedArray:['all'],
            isLoading: false,
            fullPage: true,
            onCancel:true,
            titleName:'Create',
            analyticsConfigProfileId:"",
            isEdit:false,
            readonly:true,

            search:null,
            syslog_protocols:["UDP"],
            all_applications:[],
            all_orgs_flat:[],
            selected_applications: [],
            analyticsConfigProfileDetails: {
                        analyticsConfigProfileId :'',
                        analyticsConfigProfileName : '',
                        applications : [],
                        syslog: {
                            //receive : this.UtilsModaConstants.AdminStatus.ENABLED, // uses computed prop instead
                            protocol : "UDP",
                            listenPort : 10541,
                        }
                    },

            height:35,
            width:35,
            userdetails:JSON.parse(localStorage.getItem('user-details')),
            tag: '',
            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Profiles & Jobs"},
                           {name:'Analytics Config Profiles',
                            route: { name: "ANAL_CONFIG_PROFILES"} },
                           {name:"View", active:true} ],
            apiStatus: null,
        };
    },

    mixins: [ Utils ],
    methods: {
        cancel () {
            this.cancelCreateAnalyticsConfigProfile()
        },
        navigateToApplication(id){
            var readonly = 1
            const r = this.$router.resolve({name: "APPLICATIONS_EDIT", params: { appId: id, readonly: readonly} } )
						window.open(r.href, '_blank');
        },
        cancelCreateAnalyticsConfigProfile(){
            this.$router.go(-1);
        },
        getanalyticsConfigProfileDetails(){
						this.isLoading=true
            let query_params = {};
            if ( this.targetOrgId ) query_params.targetOrgId = this.targetOrgId;
            else if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/analyticsConfigProfile/"+this.analyticsConfigProfileId, (err, result) => {
								this.isLoading=false;
                if ( err ) {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.analyticsConfigProfileDetails = result.data.data[0];
                    this.selected_applications = this.analyticsConfigProfileDetails.applications.map(app => {
                        return {appId:app.appId, appName:app.appName}
                    });
                    this.utilStatus1 = this.analyticsConfigProfileDetails.syslog.receive
                    this.titleName = (this.readonly ? "View - " : "Edit - ") +this.analyticsConfigProfileDetails.analyticsConfigProfileName
                }
            }, query_params)
        },

        getApplicationsList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            this.all_applications = [];
            ApiService.get("/api/v1/application", (err, result) => {
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {

                    this.apiStatus = null;
                    this.all_applications = result.data.data;
                    if(this.analyticsConfigProfileId != "" && this.analyticsConfigProfileId !=undefined)
                        this.getanalyticsConfigProfileDetails();
                }
            }, query_params)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            if(this.analyticsConfigProfileId != "" && this.analyticsConfigProfileId !=undefined){
                this.cancelCreateAnalyticsConfigProfile();
            }else
                this.getApplicationsList()
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.targetOrgId = this.$route.params.targetOrgId;
        this.analyticsConfigProfileId = this.$route.params.analyticsConfigProfileId;
        this.readonly = true;
        this.getApplicationsList()
        if(this.analyticsConfigProfileId != "" && this.analyticsConfigProfileId !=undefined){
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
        }
    }
}

</script>



