<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-text-box</v-icon> Policies
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<div class="search-wrapper">
					<v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify" />
				</div>
			</v-card-text>
			<v-container fluid class="pt-0">
				<v-data-table :loading="isLoading" :headers="users_policies_table_columns" :items="users_policies_table_rows"
					:search="filterValueSearch" :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }"
					item-key="monTemplateId">

					<template v-slot:item.policyName="{ item }">
						<span> {{item.policyName}} <sup v-if="utilsIsSystem(item)"> <small>(Sys) </small></sup> </span>
					</template>

					<template v-slot:item.actions="{ item }">
						<v-row>
							<v-icon @click="editUserPolicyDetails(item.policyId, 1)" class="mr-2" color="primary" title="View">
								mdi-eye </v-icon>
							<span :class="utilsGetDisplayClass(item)">
								<v-icon @click="editUserPolicyDetails(item.policyId, 0)" class="mr-2" color="warning" title="Edit">
									mdi-pencil</v-icon>
								<v-icon @click="deleteUserPolicyDetails(item.policyId,
								item.policyName)" color="error" title="Delete">mdi-delete</v-icon>
							</span>
						</v-row>
					</template>
				</v-data-table>
			</v-container>
		</v-card>

		<DeleteDialog v-if="deleteDialog" :currentObjectName="currName" @DeleteDialogCallback='deleteObjectChildEvent'>
		</DeleteDialog>
	</div>
</template>

<script>
import UserApiService from '@/services/user-api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import AdsTreeTableUtils from '@/pages/moda/AdsTreeTableUtils.vue'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'

export default {
    components: {
        Breadcrumb,
        DeleteDialog,
    },
    mixins: [
        AdsTreeTableUtils,
        Utils,
    ],
    data(){
        var users_policies_table_columns= [
					{
						text: 'Name',
						value: 'policyName',
						direction: true,
						filterable: true,
					},
					{
						text: 'Features',
						value: 'features',
						direction: true,
						filterable: true,
					},
					{
						text: 'Permissions',
						value: 'permissions',
						direction: true,
						filterable: true,
					},
					{
						text: 'Creation Time',
						value: 'createdDateString',
						direction: true,
						filterable: true,
					},
					{
						text: 'Actions',
						value: 'actions',
						width: 120
					},
        ]
        return {
            currOrgId: null,

            users_policies_table_columns,
            users_policies_table_rows: [],

            isLoading: false,

            userdetails:{},
            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Access Control"},
                           {name:'Policies', active:true} ],
            apiStatus: null,
			deleteDialog:false,
			currId:null,
			currName:null,

        };
    },
    methods: {
        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
        buildFilterableColumns(objs){
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                if ( obj._children ) this.buildFilterableColumns(obj._children);
            })
        },
        getUsersPolicyList(){
            this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            UserApiService.get("/usersvc/api/v1/policy", (err, result) => {
                this.isLoading=false
                //console.log("getUsersPolicyList " + JSON.stringify(result.data));
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.users_policies_table_rows=result.data.data
                    this.buildFilterableColumns(this.users_policies_table_rows);
                }

            }, query_params)
        },
        editUserPolicyDetails(id, readonly){
            this.$router.push( { name : "USER_POLICIES_EDIT", params: { id: id, readonly: readonly }})
        },
        deleteUserPolicyDetails(Id, name){
			this.deleteDialog = true;
            this.currId = Id;
            this.currName = name;
		},
		deleteObjectChildEvent(cancelFlag, isBackupDel) 
		{
				console.log("** delete " + isBackupDel);
				this.deleteDialog = false;
				if(cancelFlag == true) return;


            // if(confirm("Do you really want to delete - '" + name + "' ?"))
				{
                this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

                UserApiService.delete("/usersvc/api/v1/policy/"+this.currId, (err, result) => {
                    //console.log("**"+JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getUsersPolicyList();
                    }
                }, query_params)
            }
        },
        createUserPolicy(){
            this.$router.push( { name : "USER_POLICIES_CREATE" } )
        },
        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.getUsersPolicyList();
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.get_auth_details();
        this.getUsersPolicyList();
    }
}
</script>
