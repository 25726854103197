<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-filter-settings</v-icon> Rule Details
			</v-card-title>
			<v-card-text class="pb-0">
				<v-container fluid class="pl-5 pr-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<form ref="ruleform">
						<v-row v-if="isLoading">
              <v-col cols="12">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </v-col>
            </v-row>
						<v-row>
							<v-col cols="12" sm="6">
								<v-text-field dense v-model="ruleDetails.ruleName" :error-messages="ruleNameErrors"
									@input="$v.ruleDetails.ruleName.$touch()" @blur="$v.ruleDetails.ruleName.$touch()"
									class="moda-required" label="Name" :disabled="readonly" />
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field dense v-model="ruleDetails.description" label="Description" :disabled="readonly" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<vue-query-builder :rules="rules" :maxDepth="3" v-model="ruleDetails.ruleDefinitionFE.ruleConditions"
								:disabled="readonly" />
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12">
								<v-autocomplete label="Action" outlined v-model="ruleDetails.ruleDefinitionFE.ruleAction"
							:items="ruleActions" item-text="label" item-value="id" class="moda-required" dense :disabled="readonly"
							:return-object="false" :error-messages="ruleActionErrors"
							@input="$v.ruleDetails.ruleDefinitionFE.ruleAction.$touch()"
							@blur="$v.ruleDetails.ruleDefinitionFE.ruleAction.$touch()" />
							</v-col>
						</v-row>

						
					</form>
				</v-container>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="!readonly" @click="saveRuleChanges">Save</v-btn>
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancelRuleChanges">Go Back</v-btn>
				<v-btn rounded outlined color="error" v-else @click="cancelRuleChanges">Cancel</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>

import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'
import VueQueryBuilder from 'vue-query-builder';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'EventsRulesCrud',
    components: {
        Breadcrumb,
        VueQueryBuilder,
    },
    mixins: [
        validationMixin,
        Utils,
    ],
    validations: {
        ruleDetails : {
            ruleName : { required },
            ruleDefinitionFE : {
                ruleAction : { required },
            },
        },
    },
    computed: {
        ruleNameErrors () {
            const errors = []
            if (!this.$v.ruleDetails.ruleName.$dirty) return errors
            !this.$v.ruleDetails.ruleName.required && errors.push('Rule name is required.')
            return errors
        },
        ruleActionErrors () {
            const errors = []
            if (!this.$v.ruleDetails.ruleDefinitionFE.ruleAction.$dirty) return errors
            !this.$v.ruleDetails.ruleDefinitionFE.ruleAction.required && errors.push('Rule action is required.')
            return errors
        },
    },
    data(){
        return {
            readonly:true,
            currOrgId: null,
            rule_Id: null,
            isLoading: false,
            userdetails:{},
            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, {name: "Configuration"},
                           {name:'Event Rules', route: { name: "EVENT_RULES" } }, {name:"Create/Edit", active:true} ],
            apiStatus: null,

            ruleDetails: {
                ruleName:null,
                description:null,
                ruleDefinitionFE : {
                    ruleConditions:null,
                    ruleAction:null,
                },
                ruleDefinition : {
                    conditions:null,
                    event:null,
                },
            },

            // Rules data
            rules: [
                // new format
                {
                    type: "select",
                    id: "$.categoryType",
                    label: "Alert category",
                    operators: ['equal', 'not-equal' ],
                    choices: [
                        {label: "ML Log Analysis",          value: "MLAnomalyLogAnalysis"},
                        {label: "ML Telemetry Analysis",    value: "MLAnomalyTelemetry"},
                        {label: "Device discovery",         value: "DeviceAvailability"},
                        {label: "Device problem",           value: "DeviceProblem"},
                        {label: "User added",               value: "Customer"},
                    ]
                },
                {
                    type: "text",
                    id: "$.siteId",
                    label: "Alert site ID",
                    operators: ['equal', 'not-equal' ],
                },
                {
                    type: "select",
                    id: "$.objType",
                    label: "Alert source type",
                    operators: ['equal', 'not-equal' ],
                    choices: [
                        {label: "Site monitoring",  value: "SiteMonitoring" },
                        {label: "App monitoring",   value: "ApplicationMonitoring" },
                        {label: "Device monitoring",value: "DeviceMonitoring" },
                    ]
                },

                // old format
                {
                    type: "select",
                    id: "categoryType",
                    label: "Alarm category",
                    operators: ['equal', 'not-equal' ],
                    choices: [
                        {label: "ML Log Analysis",          value: "MLAnomalyLogAnalysis"},
                        {label: "ML Telemetry Analysis",    value: "MLAnomalyTelemetry"},
                        {label: "Device discovery",         value: "DeviceAvailability"},
                        {label: "Device problem",           value: "DeviceProblem"},
                        {label: "User added",               value: "Customer"},
                    ]
                },
                {
                    type: "select",
                    id: "severity",
                    label: "Alarm severity",
                    operators: ['equal','less-than','less-than-equal','greater-than','greater-than-equal'],
                    choices: [
                        {label: "Critical", value: "Critical"},
                        {label: "Severe",   value: "Severe"},
                        {label: "Major",    value: "Major"},
                        {label: "Minor",    value: "Minor"},
                        {label: "Info",     value: "Info"},
                        {label: "Unknown",  value: "NotSet"},
                    ]
                },
                {
                    type: "select",
                    id: "priority",
                    label: "Alarm priority",
                    operators: ['equal','less-than','less-than-equal','greater-than','greater-than-equal'],
                    choices: [
                        {label: "High",     value: "High"},
                        {label: "Medium",   value: "Medium"},
                        {label: "Low",      value: "Low"},
                    ]
                },
            ],
            ruleActions: [
                {id: "Log",     label: "Log"},
                {id: "LogDrop", label: "Log and drop"},
                {id: "Drop",    label: "Drop"},
                {id: "DropAlert",label: "Drop the alert"},
                {id: "Attach",  label: "Attach"},
            ],
        };
    },
    watch: {
    },
    methods: {
/* Backend format
{ "_id": {    "$oid": "628714113b610fdd67e348e5"  },  "orgId": "orgobj.1000_1653019579",  "ruleId": "rule.1002",
  "ruleName": "Alarm Correlation Rule - 201", "description": "Alarm correlation to attach an Alarm to an Incident",
  "ruleType": "Correlation",
  "ruleDefinition": { "id": "rule.1002", "name": "Alarm Correlation Rule - 201",
    conditions": {
        "all": [
            {
                "fact": "categoryType",
                "operator": "equal",
                "value": "MLAnomalyLogAnalysis"
            },
            {
                "any": [
                    {
                        "fact": "severity",
                        "operator": "equal",
                        "value": "Critical"
                    },
                    {
                        "fact": "priority",
                        "operator": "equal",
                        "value": "High"
                    }
                ]
            }
        ]
    },
    "mdi-calendar": { "type": "Correlation", "params": { "action": "Attach", "incidentId": "inc.20000009" } }
  },
  "facts": null, "adminStatus": "Enabled", "entryType": "System",
  "createdDate": {    "$numberLong": "1653019665025"  },  "modifiedDate": {    "$numberLong": "-1"  }}
*/
/* UI Format
ruleDefinition changed  {
  "logicalOperator": "all",
  "children": [
    {
      "type": "query-builder-rule",
      "query": {
        "rule": "categoryType",
        "operator": "equal",
        "operand": "Alarm category",
        "value": "MLAnomalyLogAnalysis"
      }
    },
    {
      "type": "query-builder-rule",
      "query": {
        "rule": "severity",
        "operator": "equal=",
        "operand": "Alarm severity",
        "value": "Critical"
      }
    },
    {
      "type": "query-builder-rule",
      "query": {
        "rule": "priority",
        "operator": "less-than-equal",
        "operand": "Alarm priority",
        "value": "High"
      }
    },
    {
      "type": "query-builder-group",
      "query": {
        "logicalOperator": "any",
        "children": [
          {
            "type": "query-builder-rule",
            "query": {
              "rule": "categoryType",
              "operator": "not-equal",
              "operand": "Alarm category",
              "value": "MLAnomalyLogAnalysis"
            }
          },
          {
            "type": "query-builder-rule",
            "query": {
              "rule": "severity",
              "operator": "equal",
              "operand": "Alarm severity",
              "value": "Minor"
            }
          },
          {
            "type": "query-builder-rule",
            "query": {
              "rule": "priority",
              "operator": "greater-than",
              "operand": "Alarm priority",
              "value": "Medium"
            }
          }
        ]
      }
    }
  ]
}
EventsRules-crud.vue?79af:152 ruleAction changed  "Log"
*/
        conditionsFE2BE(query){
            if ( query.type == "query-builder-group" )
                return this.conditionsFE2BE(query.query)
            // else query.
            var beFormat = {}
            beFormat[query.logicalOperator] = query.children.map( function(child) {
                if ( child.type == "query-builder-group" )
                    return this.conditionsFE2BE(child.query)
                // else "query-builder-rule"
                var query = child.query;
                return { "path": query.rule, "operator": query.operator,
                         "operand" : query.operand, "value": query.value  }
            }, this)
            //console.log("conditionsFE2BE ", JSON.stringify(beFormat, null, 2));
            return beFormat;
        },
        actionsFE2BE(action){
            var event = {}
            if (action.includes("Drop"))
                event.type = this.UtilsModaConstants.FaultService.RuleType.REDUCTION_FILTER
            else
                event.type = this.UtilsModaConstants.FaultService.RuleType.CORRELATION
            event.params = {"action": action}
            return event;
        },
        conditionsBE2FE(conditions){
            if ( !conditions ) return conditions
            var op = Object.keys(conditions)[0]
            var feFormat = {logicalOperator: op}
            feFormat.children = conditions[op].map( function(child) {
                var keys = Object.keys(child);
                if ( keys.length === 1 ) // group
                    return { "type": "query-builder-group", "query" : this.conditionsBE2FE(child) }
                return { type : "query-builder-rule",
                         query : {"rule": child.path ? child.path : child.fact, //path is new format,fact is old
                                  "operator": child.operator,
                                  "operand": child.operand, "value": child.value }}
            }, this)

            return feFormat;
        },
        actionsBE2FE(event){
            var action = event.params.action
            return action;
        },
        submit () {
            //this.$v.$touch()
            this.isLoading=true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            var ourRuleConditions = this.ruleDetails.ruleDefinitionFE.ruleConditions
            var ourRuleAction = this.ruleDetails.ruleDefinitionFE.ruleAction
            var theirRuleDefinition = this.ruleDetails.ruleDefinition = {}
            if ( ourRuleConditions ){
                theirRuleDefinition.conditions = this.conditionsFE2BE(ourRuleConditions)
                theirRuleDefinition.name = this.ruleDetails.ruleName
            }
            if ( ourRuleAction ){
                theirRuleDefinition.event = this.actionsFE2BE(ourRuleAction)
            }
            console.log("EventsRules-crud.vue submit, sending ", JSON.stringify(this.ruleDetails, null, 2))

            if (this.isEdit) {
                ApiService.put("/svcFault/api/v1/rule/" + this.ruleDetails.ruleId, this.ruleDetails , 
                    (err, result) => {
                    this.isLoading=false;
                    if (err){
                        this.apiStatus =
                            (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                    }
                }, query_params, "FAULT_SERVICE_URL");
            } else {// create
                ApiService.post("/svcFault/api/v1/rule/", this.ruleDetails, (err, result) => {
                    this.isLoading=false;
                    if (err){
                        this.apiStatus =
                        (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                    }
                }, query_params, "FAULT_SERVICE_URL");
            }
        },

        getEventRuleDetails(ruleId)  {
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            query_params.shorten = "true"

            ApiService.get("/svcFault/api/v1/rule/"+ruleId, (err, result) => {
                this.isLoading=false;
                if ( err ){
                    this.apiStatus =
                        (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.ruleDetails = result.data.data[0]
                    var ourRuleDefinition = this.ruleDetails.ruleDefinitionFE = {}
                    ourRuleDefinition.ruleConditions = null
                    ourRuleDefinition.ruleAction = null

                    var theirRuleDefinition = this.ruleDetails.ruleDefinition
                    if ( theirRuleDefinition ){
                        if ( theirRuleDefinition.conditions )
                            ourRuleDefinition.ruleConditions = this.conditionsBE2FE(theirRuleDefinition.conditions)
                        if ( theirRuleDefinition.event )
                            ourRuleDefinition.ruleAction = this.actionsBE2FE(theirRuleDefinition.event)
                    }
                    delete this.ruleDetails.ruleDefinition

                    console.log("getEventRuleDetails: " + JSON.stringify(this.ruleDetails));
                    //console.log("getAlarmEventStatusList " + JSON.stringify(result.data.data));
                }

            }, query_params, "FAULT_SERVICE_URL");

        },

        cancelRuleChanges() {
            //console.log("cancelRuleChanges called");
            this.$router.go(-1)
        },
        saveRuleChanges() {
            //console.log("saveRuleChanges called");
            this.submit ();
            this.cancelRuleChanges();
        },

        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
            this.isShow = true
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            if(this.rule_id){
                // Edit can't be done when org is changed, so g back
                this.cancelRuleChanges();
            }else{
                //
            }
        },

    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.rule_id = this.$route.params.id; 
        this.readonly = (this.$route.params.readonly == "1");
        this.isEdit = false;
        this.get_auth_details();
        if(this.rule_id != "" && this.rule_id !=undefined){
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
            this.getEventRuleDetails(this.rule_id);
        }
    }
}
</script>
