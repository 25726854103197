<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-lan</v-icon> Network Map
			</v-card-title>
				
			<v-card-text>
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<v-container fluid>
					<v-row>
						<v-col>
							<p class="my-0">Map Options:</p>
							<v-checkbox :disabled="isLoading" @change="getNetworkMapData()" class="float-left mr-4 mt-0"
								v-model="inOpts.vlanGrouping" :label="`VLAN Grouping`"></v-checkbox>
							<v-checkbox :disabled="isLoading" @change="getNetworkMapData()" class="float-left mr-4 mt-0"
								v-model="inOpts.hierarchicalLayout" :label="`Show Hierarchical Layout`"></v-checkbox>
						</v-col>
							
						<v-col>
							<!-- findNode box -->
							<v-autocomplete v-model="findInput"
								:items=nodeNamesList()
								:label="`Name to find`"
								persistent-hint
								prepend-inner-icon="mdi-tag"
								clearable
							>
								<template v-slot:append-outer v-if="findInput">
									<v-icon 
										@click=onFindSelectedNode()
										v-text="`mdi-magnify`">
									</v-icon>
								</template>
							</v-autocomplete>
						</v-col>
					</v-row>

					<v-row v-if="isLoading">
						<v-col cols="12" class="text-center pt-10 mt-10" style="height:200px">
							<v-progress-circular :size="80" color="primary" indeterminate></v-progress-circular>
						</v-col>
					</v-row>
					<v-row v-else>
						<v-col>
							<Network ref="network" :nodes="dataX.nodes" :edges="dataX.edges" :options="mapOptions"
								:events="['doubleClick', 'zoom', 'click', 'oncontext']"
								@double-click="onDoubleClick" @zoom="onZoom" @click="onClick" @oncontext="onRightClick">
							</Network>
						</v-col>
					</v-row>
				</v-container>
				<v-bottom-sheet style="position: absolute;z-index: 5;right: 0;"
					v-model="sheet" hide-overlay persistent
				>

					<v-sheet>
						<v-btn
							text
							color="red"
							@click="sheet = !sheet"
							style="position:absolute;right: 0;z-index: 9;"
						>
							close
						</v-btn>
					<v-card>
								<v-container>
									<v-row dense>
										<v-col cols="6">											
												<v-avatar class="ma-3" size="80" tile dense>
													<v-img class="ma-3" :max-width="80" contain
														v-if="devicedetails.imageInfo && devicedetails.imageInfo.images && devicedetails.imageInfo.images.main"
															:src='"/assets/img/devices/" + devicedetails.imageInfo.images.main'>
													</v-img>
												</v-avatar>
												<v-chip class="ma-2" color="primary" text-color="white">
													Importance
													<v-avatar right class="darken-4 blue">
														{{ devicedetails.monDevImportance }}
													</v-avatar>
												</v-chip>
											<!-- <v-card-title> {{ devicedetails.monDevName || 'Unnamed Device' }}</v-card-title> -->
											
										</v-col>
										<v-col cols="6">
											<v-chip class="ma-2" color="primary" text-color="white">
												<a class="link-text" v-on:click="crossLaunchMonDevPage()">
													{{ devicedetails.monDevName || 'Unnamed Device' }} 
												</a>
											</v-chip>
											<v-card-subtitle class="py-0">{{ devicedetails.description }}</v-card-subtitle>
											<v-icon small>mdi-map-marker</v-icon>{{ devicedetails.siteId }}
										</v-col>

										<v-col>
											<strong>Mac</strong>
											<p>{{ devicedetails.monDevMACAddress }}</p>
										</v-col>
										<v-col>
											<strong>IP Address</strong>
											<p>{{ devicedetails.monDevIpAddress }}</p>
										</v-col>
										<v-col>
											<strong>Type</strong>
											<p>{{ devicedetails.monDevType }}</p>
										</v-col>
										<v-col>
											<strong>Vendor</strong>
											<p>{{ devicedetails.monDevVendor }}</p>
										</v-col>
										<v-col>
											<strong>Model</strong>
											<p>{{ devicedetails.monDevModel }}</p>
										</v-col>
										<v-col>
											<strong>OS</strong>
											<p>{{ devicedetails.monDevOS || "Unknown"}}</p>
										</v-col>
									</v-row>
									<v-row dense>
										<v-col>
											<strong>First Seen</strong>
											<p>{{ devicedetails.firstSeenStr }}</p>
										</v-col>
										<v-col>
											<strong>Last Seen</strong>
											<p>{{ devicedetails.lastSeenStr || "-" }}</p>
										</v-col>
										<v-col>
											<strong>Uptime</strong>
											<p>{{ devicedetails.upTimeStr || "-" }}</p>
										</v-col>
										<v-col>
											<strong>SNMP Enabled</strong>
											<p>{{ devicedetails.snmp.enabled }}</p>
										</v-col>
										<v-col>
											<strong>Transient</strong>
											<p>{{ devicedetails.isTransient }}</p>
										</v-col>
										<v-col>
											<strong>Device Location</strong>
											<p>{{ devicedetails.monDevLocation || "-" }}</p>
										</v-col>
									</v-row>
								</v-container>
							</v-card>
					</v-sheet>
				</v-bottom-sheet>

			</v-card-text>
		</v-card>
		<vue-context ref="rightClickMenu">
			<v-btn class="text-capitalize text-decoration-underline" color="primary" small @click="onContextMenuClick()">Goto {{makeCrossLaunchText(contextObj)}} page</v-btn>
		</vue-context>
	</div>
</template>

<script>
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils'
import { Network } from "vue-vis-network";
import { DataSet } from "vue-vis-network";

import InventoryService from "@/services/inventory.service";
import VueContext from 'vue-context';
import ModaConst from "@/services/ModaConstants.js";

const INITIAL_MAX_NODES = 30;
const SUBTREE_MAX_NODES = 3;
const FLASH_IMAGE = "/assets/img/network/flash.png";

export default {
	name: "netMap",
	components: {
		Breadcrumb,
		Network,
		VueContext
	},
	mixins: [
		Utils,
	],
	data() {
		return {
			currOrgId: null,
			contextObj: null,
			isLoading: false,
			sheet: false,
			mapData : null,			// {nodes: [], edges: []},
			mapOptions : null,
			// dataX = Effective data (calculated in refreshData())
			//TODO: Use DataView and populate this using the DataPipe ?
			dataX : {
				nodes: new DataSet(), 
				edges: new DataSet()
			},
			balanceNodes : INITIAL_MAX_NODES,
			currView: {
				scale: 1.0, 
				selectedNodeId: null,
				pointer: {DOM: {x:0.0, y:0.0}, canvas: {x:0.0, y:0.0}}, 
				center: {x:0.0, y:0.0},
				viewPos: {x:0.0, y:0.0}			// Need this ?
			},
			rootNode : {nodeId: null, position: null},

			apiStatus: null,
			isShow: 'display-none',
			parent_pages: [{ name: 'Home' }, { name: 'Network Map', active: true }],
			inOpts: {          //TODO: Check this 'input options' Usage
				vlanGrouping: true,
				hierarchicalLayout: true
			},
			findInput : null,

			selectedNodeType : "none",		// "org" / "site" / "vlan" / "mondev" / ...
			selectedNode : null,
			devicedetails: {		// selected MonitoredDevice details
				monDevModel: '',
				monDevName: null,
				monDevVendor: '',
				siteId: null,
				description: null,
				monDevMACAddress: null,
				assignedTemplates: [],
				serviceNames: "",
				adminStatus: "Enabled",
				monDevImportance: 0,
				
				snmp: {enabled: false},
				firstSeenStr: '',
				lastSeenStr: '',
				upTimeStr: '',
			},
		};
	},

	methods: {

		get_auth_details() {
			let title = this.$route.meta.title;
			this.isShow = auth.AuthService.get_usr_auth_details(title)
		},

		//--- Get networkMap data from backend ------------------------------
		getNetworkMapData() {
			//console.log("getNetworkMapData: inOpts = " + JSON.stringify(this.inOpts));

			let query_params = {};
			query_params.targetOrgId = this.currOrgId;

			let body = {};
			body.groupMonDevsByVlan = this.inOpts.vlanGrouping ? true : false;
			body.hierarchicalLayout = this.inOpts.hierarchicalLayout ? true : false;
			body.searchCriteria = {};

			this.isLoading = true;

			InventoryService.getNetworkMap(body, query_params).then((result) => {
				this.isLoading = false;
				if (result.data) {
					//DEBUG: console.log("getNetworkMapData: data = " + JSON.stringify(result.data));
					this.apiStatus = null;
					this.mapData = result.data.data;
					this.updateMapOptions();

					this.massageData();
					//DEBUG: console.log("this.mapData (massageData)= " + JSON.stringify(this.mapData));

					this.reduceNetwork();

					this.refreshData();
				}

			}).catch((err) => {
				this.isLoading = false;
				this.apiStatus = err.response.data || "Request failed";
				this.utilsCheckLogout(this.apiStatus);
			});
		},
		
		//--- get details about the selected MonitoredDevice --------------------------------------------
		getMonitoredDeviceDetails(orgId, monDevId) {
			let query_params = { targetOrgId: orgId };
			this.isLoading = true;
			
			//DEBUG:
			console.log("getMonitoredDeviceDetails: orgId: " + orgId + ", monDevId = " + monDevId);
			InventoryService.getDeviceDetails(monDevId, query_params).then((result) => {
				this.isLoading = false;
				this.sheet = true;
				if (result.data.data[0]) {
					this.apiStatus = null;
					this.devicedetails = result.data.data[0];
					//DEBUG: 
					console.log("devicedetails = " + JSON.stringify(this.devicedetails, null, 2));
					this.adminStatus = this.devicedetails.adminStatus;
					if (this.devicedetails.services) {
						this.devicedetails.serviceNames = this.devicedetails.services.map((s) => s.service + "/" + s.port);
					}
					
					this.devicedetails.firstSeenStr = this.utilsFormatDateTime(this.devicedetails.monDevFirstSeen);
					this.devicedetails.lastSeenStr = this.utilsFormatDateTime(this.devicedetails.monDevLastSeen);
					this.devicedetails.upTimeStr = this.utilsFormatSeconds2Str(this.devicedetails.monDevUptime);

					this.titleName = "View - " + this.devicedetails.monDevName;
					//### this.getMonitoredDevicesStatusList();		// Don't get Status & Templates. For this, user can cross launch to detailed device info page
					//### this.getTemplates();
				}
			}).catch((err) => {
				this.isLoading = false;
				this.apiStatus = err ? err.response.data.message ? err.response.data.message : err.response.data : "Request failed";
				this.utilsCheckLogout(this.apiStatus);
			});
		},

		processBreadcrumbMessage(selectedOrgId) {
			this.currOrgId = selectedOrgId;
			this.getNetworkMapData();
		},

		destroyNetwork() {
			this.$refs.network.destroy();
		},
		
		//=== for findSelected node ===
		nodeNamesList() {
			return (this.mapData && this.mapData.nodes) ?
				this.mapData.nodes.map((node) => { return {text: node.labelOrig, value: node.id} }) : [];
		},

		//--- Recursively open up (invisible) parent(s) ----------------------
		openUp(node) {
			console.log("openUp: node = " + JSON.stringify(node));
			if (node.isVisible == false) {
				node.isVisible = true;		// make the current node visible

				let [pIdx, pNode] = this.findNode(node.parentNodeId);	// find parent node
				this.openNode(pNode.id, pIdx, false, null);				// open up parent (don't refresh yet)
				if (pNode.isVisible == false) { 
					this.openUp(pNode);		// if parent is not visible, open up the parent also
				}
			}
		},

		//--- toggle the properties of a node (incl image & border color to give flashing effect) ----------
		blinkNode(node, n, xProp) {
			let self = this;
			if (n == 0) {
				// restore to old state
				this.dataX.nodes.update({id: node.id, image: xProp.image, borderWidth: xProp.borderWidth, color: xProp.color});
				return;
			} else {
				// toogle selected node image, borderWidth and color
				//Note: using borderWidth prop to determine the current state
				let newBorderWidth = xProp.borderWidth;
				let newImage = xProp.image;
				let newColor = xProp.color;

				if (this.dataX.nodes.get(node.id).borderWidth == xProp.borderWidth) {
					newBorderWidth = (xProp.borderWidth)*2;
					newImage = FLASH_IMAGE;
					newColor = {border: "red"};
				}
				//DEBUG: console.log("newImage : " + newImage + ", newBorderWidth = " + newBorderWidth);
				this.dataX.nodes.update({id: node.id, image: newImage, borderWidth: newBorderWidth, color: newColor});

				// Wait for sometime and call blinkNode again
				setTimeout(function() {
					self.blinkNode(node, n-1, xProp);
				}, 300);
			}
		},

		//--- flash a given node --------------------------------------------------
		flashNode(node) {
			console.log("flashNode: node = " + JSON.stringify(node));
			let origProp = {image: node.image, borderWidth: node.borderWidth, color: node.color};

			// 6 = blink node for 3 times (on-off)
			this.blinkNode(node, 6, origProp);
		},

		//--- highlights the selected node ----------------------------------------
		onFindSelectedNode() {
			console.log("onFindSelectedNode: findInput = " + this.findInput);

			if (this.findInput) {
				// highlight the selected node
				let [idx, node] = this.findNode(this.findInput);

				if (idx >= 0) {
					if (node.isVisible == false) {
						//DEBUG: console.log("node = " + JSON.stringify(node));

						// selected node is invisible; open up the nodes (ie make them visible), recursively
						this.openUp(node);

						this.refreshData();	// Refresh the whole tree (instead of adding all the opened nodes to dataX)
					}

					// highlight (flash) the selected node
					this.flashNode(node);

				} else {
					console.log("findSelectedNode - Not found ! (unreachable code)");
				}
			}
		},


		//--- update mapOptions ------------------
		updateMapOptions() {
			console.log("updateMapOptions:");

			this.mapOptions.physics.enabled = !this.inOpts.hierarchicalLayout;

			if (this.inOpts.hierarchicalLayout) {
				this.mapOptions.layout = {
					hierarchical: {
						enabled: true,
						levelSeparation: 150,
						nodeSpacing: 100,
						treeSpacing: 200,
						blockShifting: true,
						edgeMinimization: true,
						parentCentralization: false,
						direction: 'UD',        // UD, DU, LR, RL
						sortMethod: 'directed'  // hubsize, directed
					},
				}
			} else {
				this.mapOptions.layout = { improvedLayout: true };
			}
		},

		//--- find the index of nodeId in the nodes array (returns -1, if not found) --------------
		findNode(nodeId) {
			let idx = this.mapData.nodes.findIndex( (n) => n.id == nodeId);
			let node = null;
			if ( idx >= 0 ) {
				node = this.mapData.nodes[idx]
			}

			return [idx, node];
		},

		//--- set the visibility of the children - recursively ---
		setVisibility(visibility, deep, chNodeIdxList) {
			//console.log("setVisibility: visibility = " + visibility + ", deep = " + deep + " chnode = " + chNodeIdxList );
				
			let numChildren = chNodeIdxList.length;
			for (let k=0; k<numChildren; k++) {
				let chNodeIdx = chNodeIdxList[k];
				let chNode = this.mapData.nodes[chNodeIdx]
				chNode.isVisible = visibility;
				chNode.state = ModaConst.InventoryService.NetworkMap.NodeState.CLOSE;		
				// children state is always ModaConst.InventoryService.NetworkMap.NodeState.CLOSE

				if (deep) {
					if (chNode.childNodeIdxList.length > 0) {
						// Set the font for the non-leaf hidden children also, so that they
						// show us properly when the parent is opened up						
						if (visibility == false) {
							chNode.font = ModaConst.InventoryService.NetworkMap.NodeStateFont.CLOSE;
						}

						this.setVisibility(visibility, deep, chNode.childNodeIdxList);
					}
				}
			}
				
			//TODO: Hide/Unhide all the edges to those child nodes as well. Not needed ???
		},

		//--- unhide all the child nodes and change the children count color to normal ----
		openNode(nodeId, nodeIdx, doRefresh, pos) {
			//console.log("openNode: [" + nodeIdx + "]=>" + nodeId);
			//DEBUG: console.log("this.mapData.nodes = " + JSON.stringify(this.mapData.nodes));
			let node = this.mapData.nodes[nodeIdx]

			node.state = ModaConst.InventoryService.NetworkMap.NodeState.OPEN;
			node.font = ModaConst.InventoryService.NetworkMap.NodeStateFont.OPEN;
			this.setVisibility(true, false, node.childNodeIdxList);
			if (doRefresh) {
				this.refreshData();
				this.updateView(node, pos)
			}
		},
			
		//--- hide all the children of this node and show the children count in bold-blue color
		closeNode(nodeId, nodeIdx, doRefresh, pos) {
			//console.log("closeNode: [" + nodeIdx + "]=>" + nodeId);
			let node = this.mapData.nodes[nodeIdx]

			if (node.childNodeIdxList.length > 0) {
				node.state = ModaConst.InventoryService.NetworkMap.NodeState.CLOSE;
				node.font = ModaConst.InventoryService.NetworkMap.NodeStateFont.CLOSE;
				this.setVisibility(false, true, node.childNodeIdxList);
				if (doRefresh) { 
					this.refreshData();
					this.updateView(node, pos)
				}
			} else {
				console.log("INFO: Leaf node. Nothing to do");
			}
		},
			
		//--- toggleNode - ie Open or Close node --------------------------------------------- 
		toggleNode(nodeId, pos) {
			//console.log("toggleNode: nodeId = " + nodeId);

			let [idx, node] = this.findNode(nodeId);
			//DEBUG: console.log("findNode: [id=" + nodeId + "] = [idx = " + idx + ", node = " + JSON.stringify(node) + "]");

			if (idx >= 0) {
				let currNodeState = node.state;
				if (currNodeState == ModaConst.InventoryService.NetworkMap.NodeState.OPEN) {
					this.closeNode(nodeId, idx, true, pos);
				} else if (currNodeState == ModaConst.InventoryService.NetworkMap.NodeState.CLOSE) {
					this.openNode(nodeId, idx, true, pos);
				} else {
					console.log("ERROR: 'unknown' node state. Can't act");
				}
			} else {
				console.log("ERROR: Unable to get node index. Can't act");
			}
		},

		//--- close some sub-trees to reduce the overall tree ---------------------
		closeSubTrees(nodeId) {
			if (this.balanceNodes > 0) {
				//DEBUG: console.log("closeSubTrees: nodeId = " + nodeId);

				// network still has more nodes
				let [idx, node] = this.findNode(nodeId);
				let chIdList = node.childNodeIdList;
				let chIdxList = node.childNodeIdxList;
				let numGC = 0;
				for (let i=0; i<chIdxList.length; i++) {
					let gcIdx = chIdxList[i];
					let gcNode = this.mapData.nodes[gcIdx]
					numGC = gcNode.childNodeIdxList.length;
					if (numGC > SUBTREE_MAX_NODES) {
						this.closeNode(gcNode.id, gcIdx, false, null);	// close, but don't refresh yet
						this.balanceNodes -= numGC; 
					}

					if (this.balanceNodes > 0) {
						// network still has more nodes (call closeSubTrees on children)
						this.closeSubTrees(gcNode.id);
					}
				}
			}
		},

		//~~~~~~~~~~ Attach event handlers ~~~~~~~~ 
		
		//--- doubleClick : For now, 'doubleClick' is same as single 'click'
		onDoubleClick(params) {
			//console.log("onDoubleClick: ");
			//DEBUG: console.log("onDoubleClick:params = " + JSON.stringify(params));
			
			this.onClick(params)
		},

		//--- if the node is non-leaf, then toggle (Open or Close) the node 
		onClick(params) {
			//console.log("onClick: " + JSON.stringify(params));
			if (params.nodes.length == 1) {
				//DEBUG: console.log("node - " + params.nodes[0] + " at pos: " + network.getPositions(params.nodes[0]));

				// Note current view params (scale/pos)
				let network = this.$refs.network;
				this.currView.scale = network.getScale();
				this.currView.selectedNodeId = params.nodes[0];
				this.currView.pointer = params.pointer;
				this.currView.center = params.event.center;
				this.currView.viewPos = network.getViewPosition();
				//DEBUG: console.log("currView = " + JSON.stringify(this.currView));

				this.toggleNode(params.nodes[0], params.pointer.canvas);

				if (params.nodes[0].startsWith("mondev.")) {
					this.selectedNodeType = "mondev";
					this.selectedNode = this.mapData.nodes.find( (n) => n.id == params.nodes[0]);
					//DEBUG: console.log("### selectedNode = " + JSON.stringify(selectedNode));

					this.getMonitoredDeviceDetails(this.selectedNode.orgId, this.selectedNode.id);
				} else {
					this.selectedNodeType = "none";		//TODO: determine the proper one, if processing that type
					this.selectedNode = null;
				}
			}
		},

		//--- on right-click on a node, show a context menu for cross-launch to the corresponding object page
		onRightClick(params) {
			let network = this.$refs.network;
			let nodeClicked = network.getNodeAt(params.pointer.DOM);
			let node = this.mapData.nodes.find( (n) => n.id == nodeClicked);
			params.event.preventDefault();
			//console.log("OnRightClick: " + JSON.stringify(params));
			//console.log("OnRightClick: nodeClicked " + JSON.stringify(node));
			if (nodeClicked && node) {
				this.contextObj = node;
				this.$refs.rightClickMenu.open(params.event);
			}
		},

		//--- cross launch to the MonDev details page ------------------------------------------------------
		crossLaunchMonDevPage() {
			if (this.selectedNode != null) {
				this.$router.push({name: "MONITORED_DEVICES_VIEW", params : { id: this.selectedNode.id, targetOrgId: this.selectedNode.orgId, readonly: true } } );
			}
		},

		//--- cross-launch to the corresponding object page
		onContextMenuClick() {
			//handle go to click here
			let node = this.contextObj;
			let readonly = true;
			//console.log("OnContextMenuClick: nodeClicked " + JSON.stringify(this.contextObj));
			// TBD: take these category values from ModaConst
			if ( node.category == ModaConst.InventoryService.NetworkMap.NodeCategory.PARENT_ORG ||
				node.category == ModaConst.InventoryService.NetworkMap.NodeCategory.CURRENT_ORG ||
				node.category == ModaConst.InventoryService.NetworkMap.NodeCategory.ORG ) {
				this.$router.push({name: "ORGS_VIEW", params : { id: node.id, readonly: readonly } } );
			} else if ( node.category == ModaConst.InventoryService.NetworkMap.NodeCategory.SITE) {
				this.$router.push({name: "SITES_VIEW", params : { siteId: node.id, targetOrgId: node.orgId, readonly: readonly } } );
			} else if ( node.category == ModaConst.InventoryService.NetworkMap.NodeCategory.AGENT_DEVICE) {
				this.$router.push({name: "MODA_AGENTS_VIEW", params : { id: node.id, targetOrgId: node.orgId, readonly: readonly } } );
			} else if ( node.category == ModaConst.InventoryService.NetworkMap.NodeCategory.VLAN ) {
				// for now, VLAN also navigates to Agent. There is no object called VLAN
				this.$router.push({name: "MODA_AGENTS_VIEW", params : { id: node.parentNodeId, targetOrgId: node.orgId, readonly: readonly } } );
			} else if ( node.category == ModaConst.InventoryService.NetworkMap.NodeCategory.MONITORED_DEVICE ) {
				this.$router.push({name: "MONITORED_DEVICES_VIEW", params : { id: node.id, targetOrgId: node.orgId, readonly: readonly } } );
			}
		},

		//--- make display string for cross-launch
		makeCrossLaunchText(ctx) {
			var dispStr = "";
			if (ctx) {
				if (ctx.category == ModaConst.InventoryService.NetworkMap.NodeCategory.PARENT_ORG ||
					ctx.category == ModaConst.InventoryService.NetworkMap.NodeCategory.CURRENT_ORG ||
					ctx.category == ModaConst.InventoryService.NetworkMap.NodeCategory.ORG) {
					dispStr += "Organization: ";
				} else if (ctx.category == ModaConst.InventoryService.NetworkMap.NodeCategory.SITE) {
					dispStr += "Site: ";
				} else if (ctx.category == ModaConst.InventoryService.NetworkMap.NodeCategory.AGENT_DEVICE ||
					ctx.category == ModaConst.InventoryService.NetworkMap.NodeCategory.VLAN) {
					dispStr += "MODA Agent: ";
				} else if (ctx.category == ModaConst.InventoryService.NetworkMap.NodeCategory.MONITORED_DEVICE) {
					dispStr += "Device: ";
				}
				let eolIndex = ctx.label.indexOf("\n");
				dispStr += (eolIndex > 0) ? ctx.label.substring(0, eolIndex) : ctx.label;
			} else {
				dispStr = "<cross-launch not-available>";
			}

			return dispStr;
		},

		//--- note the scale (zoom level)
		onZoom() {
			let network = this.$refs.network;
			this.currView.scale = network.getScale();
			//console.log("onZoom: currView = " + JSON.stringify(this.currView));
			//console.log("onZoom: currView.scale = " + this.currView.scale);
		},

		//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


		//--- update view - position / scale(zoom) -------------------------------
		updateView(node, pos) {
			//console.log("updateView: ");
			let network = this.$refs.network;

			//For testing
			let option = 3;

			if (option == 1) {
				// Option-1) Whole map will reposition itself. So, move the affected node to its old position to appear as though it didn't move
				//network.moveNode(nodeId, pos.x, pos.y);
				network.moveNode(node.id, this.currView.pointer.canvas.x, this.currView.pointer.canvas.y);

			} else if (option == 2) {
				// Option-2) Move the 'Root' node to the same position as the initial
				if (this.rootNode.nodeId === null) {		// init root node details, if needed
					this.rootNode.nodeId = this.mapData.nodes[0].id;
					this.rootNode.position = network.getPositions(this.mapData.nodes[0].id)[this.mapData.nodes[0].id];
				}
				//console.log("rootNode = " + JSON.stringify(this.rootNode));
				//console.log("this.currView = " + JSON.stringify(this.currView));

				network.moveNode(this.rootNode.nodeId, this.rootNode.position.x, this.rootNode.position.y);
				
			} else if (option == 3) {
				// Option-3: Move canvas

				//TODO: Also pan the view ? Not-working :(
				var selNodeNewPos = network.getPositions(node.id)[node.id];
				var offsetForSelectedNode = {
					x: (this.currView.pointer.canvas.x - selNodeNewPos.x) * this.currView.scale,
					y: (this.currView.pointer.canvas.y - selNodeNewPos.y) * this.currView.scale
				}
				//console.log("SelectedNode offset " + JSON.stringify(offsetForSelectedNode));
				network.moveTo({
					//scale: this.currView.scale, 
					//position: newCanvasCenter, 
					offset: offsetForSelectedNode,
					animation: false
				});

			} else {
				// Do nothing

			}
		},

		//--- regenerate dataX for display - ie include only visible nodes/edges ------------------
		refreshData() {
			//DEBUG: console.log("refreshData: ");
			// Reset dataX lists
			this.dataX.nodes.clear(); 
			this.dataX.edges.clear();
			
			// Set new data
			this.dataX.nodes.add ( this.mapData.nodes.filter( (n) => n.isVisible == true ) );
			this.dataX.edges.add ( this.mapData.edges.filter( (e) => e.isVisible == true ) );
			//DEBUG: console.log("this.dataX.nodes = " + JSON.stringify(this.dataX.nodes));
		},

		htmlTitle(html) {
			const container = document.createElement("div");
			container.innerHTML = html;
			return container;
		},

		//--- update 'title' ---------------------------------
		massageData() {
			this.mapData.totalNodes = this.mapData.nodes.length

			this.mapData.nodes.forEach ( (node, i) => {
				//DEBUG: console.log("massaging node: [" + i + "] = " + JSON.stringify(node));

				/* Testing only - comment out below for production 
				node.highestSeverityNum = (i%5)+1;
				node.highestSeverity = ModaConst.FaultService.SeverityMapNumber[node.highestSeverityNum].enum
				if ( node.highestSeverityNum >= 5 ){
					node.faultStatus    = ModaConst.FaultService.FaultStatus.GOOD
					node.incCount       = 0;
				}else{
					node.faultStatus    = ModaConst.FaultService.FaultStatus.FAULTY
					node.incCount       = i;
				}
				Testing only - comment out above for production */

				let title = `<span>${node.category}:${node.labelOrig}<br/>
								   Children:${node.childNodeIdxList.length}<br/>
								   Status: ${node.faultStatus}<br/>`
				if ( node.faultStatus == ModaConst.FaultService.FaultStatus.FAULTY )
					title += `Severity: ${node.highestSeverity}<br/>
							  Incidents: ${node.incCount}<br/>
							  `
				title += `</span>`
				node.title = this.htmlTitle(title)

				node.shapeProperties = {useBorderWithImage: true}
				node.borderWidth = 6
				node.color = {background: "white"}
				node.color.border = this.utilsGetSeverityColor(node.highestSeverity).colorHex
			}, this)

			this.mapData.edges.forEach ( (e) => {
				e.color = {color: '#4578bf', hover: '#4578bf', inherit: false}
			})
		},

		/*~~~ OBSOLETE: Main part is moved to backend. 
		~~~~~ 'title' which depends on frontend code is left here ~~~
		//--- add state, parent, children, isVisibility to data --------------------
		massageDataOLD() {
			console.log("massageDataOLD: ");
			//console.log("massageData: nodes " + JSON.stringify(this.mapData.nodes));
			//console.log("massageData: edges " + JSON.stringify(this.mapData.edges));
	
			//TODO: Move this data enrichment to backend
			//1) Add 'state' & 'isVisible' to all the nodes
			let numNodes = this.mapData.nodes.length;
			this.mapData.totalNodes = numNodes;
		
			for (let i=0; i<numNodes; i++) {
				let node = this.mapData.nodes[i];
				node.title = this.htmlTitle(`<span>${node.category}:${node.label}<br/>Status: ${node.status}</span>`);
				node.state = ModaConst.InventoryService.NetworkMap.NodeState.OPEN;
				node.isVisible = true;
	
				node.parentNodeId = null;
				node.childNodeIdList = [];
				node.childNodeIdxList = [];
			}

			//2) Add 'isVisible' to all the edges
			var numEdges = this.mapData.edges.length;
			this.mapData.totalEdges = numEdges;
			let pIdx = -1;	// Parent node index
			let cIdx = -1; 	// Child node index
			let pNode, cNode
			for (let j=0; j<numEdges; j++) {
				let edge = this.mapData.edges[j]
				edge.isVisible = true;
					
				[pIdx, pNode] = this.findNode(edge.from);
				[cIdx, cNode] = this.findNode(edge.to);

				if (pIdx != -1 && cIdx != -1) {
					cNode.parentNodeId = pNode.id;
					cNode.parentNodeIdx = pIdx;	//This is not needed
						
					pNode.childNodeIdList.push(cNode.id);
					pNode.childNodeIdxList.push(cIdx);
						
					//DEBUG: console.log("this.mapData.nodes[" + pIdx + "] = " + JSON.stringify(pNode));
					//DEBUG: console.log("this.mapData.nodes[" + cIdx + "] = " + JSON.stringify(cNode));
				} else {
					console.log("ERROR: Unable to find parent/child node index: " + pIdx + "/" + cIdx);
				}
			}

			//3) Update the labels (if the children counts > 0)
			var numCh = 0;
			this.mapData.nodes.forEach ( function(node, n) {
				numCh = node.childNodeIdxList.length;
				if (numCh > 0) {
					node.label += "\n<b>(" + numCh + ")</b>";
				}
			});
		},
		~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

		//--- Close some nodes, if the total number of open nodes are too many
		//Note: Better to do this in frontend, as it could be used/called in some other conditions also
		//	without the need to make a backend call to get the data.
		reduceNetwork() {
			console.log("reduceNetwork: ");
			if (this.mapData.nodes.length > INITIAL_MAX_NODES) {
				console.log("Network size " + this.mapData.nodes.length + " is too large. Reducing");
				this.balanceNodes = INITIAL_MAX_NODES;
				this.closeSubTrees(this.mapData.nodes[0].id);		// nodeId of Root
			}
		},

		//--- initialize map options -----------------------------------------
		initMapOptions() {
			console.log("initMapOptions: ");

			this.mapOptions = {
				autoResize: false,				// default: true
				
				interaction: { 
					hover: true, 
					navigationButtons: false, 	// true = To show Pan/Zoom buttons
					keyboard: true },
				manipulation: { enabled: false }, // true = Enable EDIT button for add/remove nodes/links
				height: "90%",

				nodes: { 
					font: { 
						face: "verdana", 
						align: "center", 
						size: 12, 
						multi: "html", bold: { color: "gray" }, ital: {color: "gray"} }, 
					borderWidth: 2, 
					borderWidthSelected: 10,
					shadow: true 
				},

				edges: { width: 2, shadow: true },

				physics: { 
					enabled: false, 		// true = adjusts the map layout when one node is moved (elastic action)
					stabilization: false 
				},

				groups: {},

				layout: { improvedLayout: true }			// Updated later, based on selected option
			};

			// additional properties (in comments are example)
			this.mapOptions.groups[ModaConst.InventoryService.NetworkMap.NodeCategory.PARENT_ORG]          = { size: 24 /*, color: { background: "red", border: "white" }, shape: "diamond"*/};
			this.mapOptions.groups[ModaConst.InventoryService.NetworkMap.NodeCategory.CURRENT_ORG]         = { size: 24 /*, label: "Current Org", shape: "dot", color: "cyan"*/};
			this.mapOptions.groups[ModaConst.InventoryService.NetworkMap.NodeCategory.ORG]                 = { size: 24 /*, color: "rgb(0,255,140)"*/};
			this.mapOptions.groups[ModaConst.InventoryService.NetworkMap.NodeCategory.SITE]                = { size: 24 /*, color: "rgb(0,255,140)"*/};
			this.mapOptions.groups[ModaConst.InventoryService.NetworkMap.NodeCategory.AGENT_DEVICE]        = { size: 20 /*, color: { border: "white" }*/};
			this.mapOptions.groups[ModaConst.InventoryService.NetworkMap.NodeCategory.VLAN]                = { size: 20 /*, color: { border: "white" }*/};
			this.mapOptions.groups[ModaConst.InventoryService.NetworkMap.NodeCategory.MONITORED_DEVICE]    = { size: 16 /*, color: { border: "white" }*/};

			//---icons: NodeCategory.X (testing of FontAwesome icons). "\uf0c0" == "" ---
			//this.mapOptions.groups["icons"] = { shape: "icon", icon: { face: "FontAwesome", code: "\uf0c0", size: 50, color: "orange"} };
		}
	},

	mounted() {
		this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
		this.get_auth_details();
		this.initMapOptions();
		this.getNetworkMapData();
	}
}

</script>
