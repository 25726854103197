//import DashboardAnalytics from '../pages/moda/Dashboard-Analytics.vue'
//import DashboardSummaryAnalytics from '../pages/moda/Dashboard-Summary-Analytics.vue'
import DashboardSummaryStatus from '@/components/dashboard/SummaryStatus.vue'
import DashboardDeviceStatus from '@/components/dashboard/DashboardDeviceStatus.vue'
import DashboardNetworkMap from '@/components/dashboard/NetworkMap.vue'
import DashboardNetworkAnalytics from '@/components/dashboard/NetworkAnalytics.vue'
import DashboardNetworkAnalyticsGrafana from '../pages/moda/Dashboard-Network-Analytics-Grafana.vue'
import DashboardDeviceAnalytics from '@/components/dashboard/DeviceAnalytics.vue'
//import DashboardPerformanceSummary from '@/components/dashboard/PerformanceSummary.vue'

import EventsAlarms from '@/components/events/EventsAlarms.vue'
import EventsAlarmsCrud from '@/components/events/EventsAlarmsCrud.vue'

import EventsIncidents from '@/components/events/Incidents.vue'
import EventsIncidentsCrud from '@/components/events/IncidentsCrud.vue'
import InvestigationRoom from '@/components/events/InvestigationRoom.vue'

import EventsRules from '@/components/events/EventsRules.vue'
import EventsRulesCrud from '@/components/events/EventsRulesCrud.vue'
import EventRuleView from '@/components/events/EventRuleView.vue'

import IncidentCorrelationRules from '@/components/events/IncidentCorrelationRules.vue'
import IncidentCorrelationRulesCrud from '@/components/events/IncidentCorrelationRulesCrud.vue'
import IncidentCorrelationRulesView from '@/components/events/IncidentCorrelationRulesView.vue'

import Organization from '@/components/organizations/Organization.vue'
import Organizationcrud from '@/components/organizations/OrganizationCrud.vue'
import OrganizationView from '@/components/organizations/OrganizationView.vue'
//import OrganizationStatus from '../pages/moda/Organization-status.vue'
/* Not supported yet
import OrgsGroups from '../pages/moda/Orgs-Groups.vue'
import OrgsGroupsCrud from '../pages/moda/Orgs-Groups-crud.vue'
*/
//import DeviceLicenses from '../pages/moda/Devices-Licenses.vue'
//import DeviceLicensescrud from '../pages/moda/Devices--Licenses-crud.vue'

import Devices from '@/components/device/Devices.vue'
import DevicesView from '@/components/device/DevicesView.vue'
import Devicescrud from '@/components/device/DevicesCrud.vue'
//import DevicesStatus from '../pages/moda/Devices-status.vue'
import MonitoredDevices from '@/components/device/MonitoredDevices.vue'
import MonitoredDevicesCrud from '@/components/device/MonitoredDevicesCrud.vue'
import MonitoredDevicesView from '@/components/device/MonitoredDevicesView.vue'
import MonitoredDevicesStatus from '../pages/moda/MonitoredDevices-status.vue'
import MonitoringTemplates from '@/views/MonitoringTemplates.vue'
import AnalyticsConfigProfiles from '@/components/config-profiles/AnalyticsProfiles.vue'
import AnalyticsConfigProfilesView from '@/components/config-profiles/AnalyticsProfilesView.vue'
import AnalyticsConfigProfilesCrud from '@/components/config-profiles/AnalyticsProfilesCrud.vue'
import SystemConfigProfiles from '@/components/config-profiles/SystemProfiles.vue'
import SystemConfigProfilesCrud from '@/components/config-profiles/SystemProfilesCrud.vue'
import DeviceConfigMap from '@/components/config-profiles/DeviceConfigMap.vue'
import DeviceConfigMapView from '@/components/config-profiles/DeviceConfigMapView.vue'
import DeviceConfigMapCrud from '@/components/config-profiles/DeviceConfigMapCrud.vue'
import DeviceConfigJobs from '@/components/jobs/DeviceConfigJobs.vue'
import DeviceConfigJobsCrud from '@/components/jobs/DeviceConfigJobsCrud.vue'
import DeviceSWUpdateJobs from '@/components/jobs/SWUpdateJobs.vue'
import DeviceSWUpdateJobsCrud from '@/components/jobs/SWUpdateJobsCrud.vue'
import Applications from '@/components/applications/Applications.vue'
import ApplicationsView from '@/components/applications/ApplicationsView.vue'
import ApplicationsCrud from '@/components/applications/ApplicationsCrud.vue'
//import ApplicationsStatus from '../pages/moda/Applications-status.vue'
import Sites from '@/components/sites/Sites.vue'
import Sitescrud from '@/components/sites/SitesCrud.vue'
import SitesView from '@/components/sites/SitesView.vue'
//import SitesStatus from '../pages/moda/Sites-status.vue'
//import DashboardRawData from '../pages/moda/Dashboard-Rawdetails.vue'
//import NMAPDetails from '../pages/moda/NMAP-Details.vue'
import Login from '@/views/login.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import Users from '@/components/users/Users.vue'
import UsersView from '@/components/users/UsersView.vue'
import Userscrud from '@/components/users/UsersCrud.vue'
/* Not supported yet
import SitesGroups from '../pages/moda/Sites-Groups.vue'
import SitesGroupsCrud from '../pages/moda/Sites-Groups-crud.vue'
*/
import Userspolicies from '@/components/users/Policies.vue'
import Userspoliciescrud from '@/components/users/PoliciesCrud.vue'

import AdminDeviceModels   from '@/components/device/DeviceModels.vue'

import AdminTaskSwImages   from '@/components/admin-tasks/softwareImages.vue'
import AdminTaskSwImagesView from '@/components/admin-tasks/softwareImagesView.vue'
import AdminTaskSwImagesCrud from '@/components/admin-tasks/softwareImagesCrud.vue'

import AdminTaskAllJobs   from '@/components/jobs/AllJobs.vue'

import AdminSystemSettings   from '@/components/admin-tasks/systemSettings.vue'

import ConnectWiseProfile   from '@/components/config-profiles/ConnectWiseProfile.vue'

import DeviceDeployedProfileSnapshot from '../pages/moda/Device-Deployed-Profile-Snapshot.vue'

import Pagenotfound from '@/views/errors/404.vue'
import PagenotAuth from '@/views/errors/403.vue'


const routes = [

    /* Dashboards */

  { name: "DASHBOARD_SUMMARY_STATUS", path: '/dashboards/summary-status', component: DashboardSummaryStatus,
                meta: {requiresAuth: true,title :'dashboard-management'}},
  { name: "DASHBOARD_MONDEV_STATUS", path: '/dashboards/monitored-device-status', component: DashboardDeviceStatus,
                meta: {requiresAuth: true,title :'dashboard-management'}},
  { name: "DASHBOARD_NETWORK_MAP", path: '/dashboards/network-map', component: DashboardNetworkMap,
                meta: {requiresAuth: true,title :'dashboard-management'}},
  //{ name: "DASHBOARD_PERF_SUMMARY_STATUS", path: '/dashboards/performance-summary', component: DashboardPerformanceSummary,
  //              meta: {requiresAuth: true,title :'dashboard-management'}},

  { name: "DASHBOARD_NETWORK_ANALYTICS", path: '/dashboards/network-analytics', component: DashboardNetworkAnalytics,
                meta: {requiresAuth: true,title :'dashboard-management'}},
  { name: "DASHBOARD_NETWORK_ANALYTICS_GRAFANA", path: '/dashboards/network-analytics-grafana', component: DashboardNetworkAnalyticsGrafana,
                meta: {requiresAuth: true,title :'dashboard-management'}},
  { name: "DASHBOARD_DEVICE_ANALYTICS", path: '/dashboards/device-analytics', component: DashboardDeviceAnalytics,
                meta: {requiresAuth: true,title :'dashboard-management'}},


  { name:"ORGS", path: '/organizations', component: Organization ,
            meta: {requiresAuth: true,title :'organization-management'}},
  {
    name: "ORGS_VIEW", path: '/organizations/view/:id', component: OrganizationView,
    meta: { requiresAuth: true, pageAuth: true, title: 'organization-management' }
  },
  { name:"ORGS_CREATE", path: '/organizations/create', component: Organizationcrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'organization-management'}},
  { name:"ORGS_EDIT", path: '/organizations/edit/:id/:readonly', component: Organizationcrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'organization-management'}},

  { name:"SITES", path: '/sites', component: Sites ,
            meta: {requiresAuth: true,title :'site-management'}},
  {
    name: "SITES_VIEW", path: '/sites/view/:siteId', component: SitesView,
    meta: { requiresAuth: true, pageAuth: true, title: 'site-management' }
  },
  { name:"SITES_CREATE", path: '/sites/create', component: Sitescrud,
            meta: {requiresAuth: true,pageAuth:true,title :'site-management'}},
  { name:"SITES_EDIT_TARGETORG", path: '/sites/edit/:siteId/:readonly/:targetOrgId', component: Sitescrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'site-management'}},
  { name:"SITES_EDIT", path: '/sites/edit/:siteId/:readonly', component: Sitescrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'site-management'}},

  { name:"APPLICATIONS", path: '/applications', component: Applications ,
            meta: {requiresAuth: true,title :'app-management'}},
  { name:"APPLICATIONS_VIEW", path: '/applications/view/:appId', component: ApplicationsView ,
            meta: {requiresAuth: true,title :'app-management'}},
  { name:"APPLICATIONS_CREATE", path: '/applications/create', component: ApplicationsCrud ,
            meta: {requiresAuth: true,title :'app-management'}},
  { name:"APPLICATIONS_EDIT", path: '/applications/edit/:appId/:readonly', component: ApplicationsCrud ,
            meta: {requiresAuth: true,title :'app-management'}},

  { name:"MONITORED_DEVICES", path: '/devices', component: MonitoredDevices ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name:"MONITORED_DEVICES_CREATE", path: '/devices/create', component: MonitoredDevicesCrud, 
            meta: {requiresAuth: true, pageAuth:true, title :'device-management'}},
  { name:"MONITORED_DEVICES_EDIT", path: '/devices/edit/:id/:readonly', component: MonitoredDevicesCrud, 
            meta: {requiresAuth: true, pageAuth:true, title :'device-management'}},
  { name:"MONITORED_DEVICES_VIEW", path: '/devices/view/:id', component: MonitoredDevicesView, 
            meta: {requiresAuth: true, pageAuth:true, title :'device-management'}},

  { name:"INCIDENTS", path: '/incidents', component: EventsIncidents,
            meta: {requiresAuth: true, title :'incident-management'}},
  { name:"INCIDENTS_EDIT", path: '/incidents/edit/:id/:readonly',  component: EventsIncidentsCrud,
            meta: {requiresAuth: true, title :'incident-management'}},
  { name:"INCIDENT_INVESTIGATE", path: '/incidents/edit/investigate', component: InvestigationRoom,
            meta: {requiresAuth: true, title :'incident-management'}},


    /* Configuration */

/* Not supported yet

  { name:"MODA_AGENT_LICENSES", path: '/config/devicesLicenses', component: DeviceLicenses ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name:"MODA_AGENT_LICENSES_CREATE", path: '/config/deviceLicenses/create', component: DevicesLicensescrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'device-management'}},
  { name:"MODA_AGENT_LICENSES_EDIT", path: '/config/deviceLicenses/edit/:id/:readonly', component: DevicesLicensescrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'device-management'}},

  { name: "ORG_GROUPS", path: '/config/orgGroups', component: OrgsGroups ,
            meta: {requiresAuth: true,title :'organization-management'}},
  { name: "ORG_GROUPS_CREATE", path: '/config/orgGroups/create', component: OrgsGroupsCrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'organization-management'}},
  { name: "ORG_GROUPS_EDIT_TARGETORG", path: '/config/orgGroups/edit/:orgGroupId/:readonly/:targetOrgId', component: OrgsGroupsCrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'organization-management'}},
  { name: "ORG_GROUPS_EDIT", path: '/config/orgGroups/edit/:orgGroupId/:readonly', component: OrgsGroupsCrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'organization-management'}},
*/

  { name:"EVENT_RULES", path: '/config/filtering-rules', component: EventsRules,
            meta: {requiresAuth: true, title :'Filter Rules'}},
  { name:"EVENT_RULE_VIEW", path: '/config/filtering-rules/view/:id/:readonly',  component: EventRuleView,
            meta: {requiresAuth: true, title :'Rule Details View'}},
  { name:"EVENT_RULES_EDIT", path: '/config/filtering-rules/edit/:id/:readonly',  component: EventsRulesCrud,
            meta: {requiresAuth: true, title :'Rule Details Edit'}},
  { name:"EVENT_RULES_CREATE", path: '/config/filtering-rules/create',  component: EventsRulesCrud,
            meta: {requiresAuth: true, title :'Rule create'}},

  { name:"INCIDENT_CORRELATION_RULES", path: '/config/correlation-rules', component: IncidentCorrelationRules,
            meta: {requiresAuth: true, title :'Correlation Rules'}},
  { name:"INCIDENT_CORRELATION_RULES_CREATE", path: '/config/correlation-rules/create',  component: IncidentCorrelationRulesCrud,
            meta: {requiresAuth: true, title :'Correlation Rule create'}},
  { name:"INCIDENT_CORRELATION_RULES_EDIT", path: '/config/correlation-rules/edit',  component: IncidentCorrelationRulesCrud,
            meta: {requiresAuth: true, title :'Correlation Rule Edit'}},
  { name:"INCIDENT_CORRELATION_RULES_VIEW", path: '/config/correlation-rules/view',  component: IncidentCorrelationRulesView,
            meta: {requiresAuth: true, title :'Correlation Rule View'}},

  { name:"MODA_AGENTS", path: '/config/moda-agents', component: Devices ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name:"MODA_AGENTS_VIEW", path: '/config/moda-agents/view/:id', component: DevicesView ,
            meta: {requiresAuth: true,pageAuth:true,title :'device-management'}},
  { name:"MODA_AGENTS_CREATE", path: '/config/moda-agents/create', component: Devicescrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'device-management'}},
  { name:"MODA_AGENTS_EDIT", path: '/config/moda-agents/edit/:id/:readonly', component: Devicescrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'device-management'}},

  { name: "MON_TEMPLATES", path: '/config/profiles-jobs/monitoring-templates', component: MonitoringTemplates ,
            meta: {requiresAuth: true,title :'device-management'}},

  { name: "ANAL_CONFIG_PROFILES", path: '/config/profiles-jobs/analytics-profiles', component: AnalyticsConfigProfiles ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "ANAL_CONFIG_PROFILES_VIEW", path: '/config/profiles-jobs/analytics-profiles/view/:analyticsConfigProfileId',
                                                component: AnalyticsConfigProfilesView ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "ANAL_CONFIG_PROFILES_CREATE", path: '/config/profiles-jobs/analytics-profiles/create', component: AnalyticsConfigProfilesCrud ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "ANAL_CONFIG_PROFILES_EDIT", path: '/config/profiles-jobs/analytics-profiles/edit/:analyticsConfigProfileId/:readonly',
            component: AnalyticsConfigProfilesCrud , meta: {requiresAuth: true,title :'device-management'}},

  { name: "SYS_CONFIG_PROFILES", path: '/config/profiles-jobs/system-profiles', component: SystemConfigProfiles ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "SYS_CONFIG_PROFILES_CREATE", path: '/config/profiles-jobs/system-profiles/create', component: SystemConfigProfilesCrud ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "SYS_CONFIG_PROFILES_EDIT", path: '/config/profiles-jobs/system-profiles/edit/:systemConfigProfileId/:readonly',
            component: SystemConfigProfilesCrud , meta: {requiresAuth: true,title :'device-management'}},

  { name: "DEVICE_CONFIG_MAP", path: '/config/profiles-jobs/device-config-map', component: DeviceConfigMap ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "DEVICE_CONFIG_MAP_VIEW", path: '/config/profiles-jobs/device-config-map/view/:deviceConfigMapId/',
            component: DeviceConfigMapView , meta: {requiresAuth: true,title :'device-management'}},
  { name: "DEVICE_CONFIG_MAP_FILTER", path: '/config/profiles-jobs/device-config-map/filter/:configProfileName', component: DeviceConfigMap ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "DEVICE_CONFIG_MAP_CREATE", path: '/config/profiles-jobs/device-config-map/create', component: DeviceConfigMapCrud ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "DEVICE_CONFIG_MAP_EDIT", path: '/config/profiles-jobs/device-config-map/edit/:deviceConfigMapId/:readonly',
            component: DeviceConfigMapCrud , meta: {requiresAuth: true,title :'device-management'}},

  { name: "DEVICE_CONFIG_JOBS", path: '/config/profiles-jobs/device-config-jobs', component: DeviceConfigJobs ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "DEVICE_CONFIG_JOBS_CREATE", path: '/config/profiles-jobs/device-config-jobs/create', component: DeviceConfigJobsCrud ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "DEVICE_CONFIG_JOBS_EDIT", path: '/config/profiles-jobs/device-config-jobs/edit/:deviceConfigJobId/:readonly',
            component: DeviceConfigJobsCrud , meta: {requiresAuth: true,title :'device-management'}},

  { name: "DEVICE_SWUPDATE_JOBS", path: '/config/profiles-jobs/device-sw-update-jobs', component: DeviceSWUpdateJobs ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "DEVICE_SWUPDATE_JOBS_CREATE", path: '/config/profiles-jobs/device-sw-update-jobs/create', component: DeviceSWUpdateJobsCrud ,
            meta: {requiresAuth: true,title :'device-management'}},
  { name: "DEVICE_SWUPDATE_JOBS_EDIT", path: '/config/profiles-jobs/device-sw-update-jobs/edit/:deviceSWUpdateJobId/:readonly',
            component: DeviceSWUpdateJobsCrud , meta: {requiresAuth: true,title :'device-management'}},

  { name: "CONNECT_WISE_PROFILE", path: '/config/profiles-jobs/cw-profile', component: ConnectWiseProfile, 
            meta: {requiresAuth: true, title :'ConnectWise Profile'}},

/* Not supported yet
  { name: "SITE_GROUPS", path: '/config/sitesGroups', component: SitesGroups ,
            meta: {requiresAuth: true,title :'site-management'}},
  { name: "SITE_GROUPS_CREATE", path: '/config/sitesGroups/create', component: SitesGroupsCrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'site-management'}},
  { name: "SITE_GROUPS_EDIT_TARGETORG", path: '/config/sitesGroups/edit/:siteGroupId/:readonly/:targetOrgId', component: SitesGroupsCrud ,
            meta:{requiresAuth: true,pageAuth:true,title :'site-management'}},
  { name: "SITE_GROUPS_EDIT", path: '/config/sitesGroups/edit/:siteGroupId/:readonly', component: SitesGroupsCrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'site-management'}},
*/

  { name: "USERS", path: '/config/access-control/users', component: Users ,
            meta: {requiresAuth: true,title :'user-management'}},
  { name: "USERS_VIEW", path: '/config/access-control/users/view/:id', component: UsersView ,
            meta: {requiresAuth: true,pageAuth:true,title :'user-management'}},
  { name: "USERS_CREATE", path: '/config/access-control/users/create', component: Userscrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'user-management'}},
  { name: "USERS_EDIT", path: '/config/access-control/users/edit/:id/:readonly', component: Userscrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'user-management'}},

  { name: "LOGIN", path: '/login', component: Login },
  { name: "FORGOT_PASSWORD", path: '/forgot_password', component: ForgotPassword},
  { name: "PASSWORD_RESET", path: '/password_reset', component: PasswordReset},
  { name: "ROOT", path: '/', component: Login ,meta: {requiresAuth: true,pageAuth:true}},

  { name: "USER_POLICIES", path: '/config/access-control/policies', component: Userspolicies,
            meta: {requiresAuth: true,title :'policy-management'}},
  { name: "USER_POLICIES_CREATE", path: '/config/access-control/policies/create', component: Userspoliciescrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'policy-management'}},
  { name: "USER_POLICIES_EDIT", path: '/config/access-control/policies/edit/:id/:readonly', component: Userspoliciescrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'policy-management'}},

  { name: "DEVICE_DEPLOYED_SNAPSHOTS", path: '/config/snapshots/deviceDeployedProfileSnapshots/:deviceId/:configProfileId/:configProfileName',
            component: DeviceDeployedProfileSnapshot , meta: {requiresAuth: true,title :'device-management'}},
  { name: "DEVICE_DEPLOYED_SNAPSHOTS_VERSION",
            path: '/config/snapshots/deviceDeployedProfileSnapshots/:deviceId/:configProfileId/:configProfileName/:configProfileVersion/:deployable',
            component: DeviceDeployedProfileSnapshot , meta: {requiresAuth: true,title :'device-management'}},

  { name: "DEVICE_MODELS", path: '/config/admin-tasks/device-models', component: AdminDeviceModels, 
            meta: {requiresAuth: true,title :'device-SwImages'}},

  { name: "SW_IMAGES", path: '/config/admin-tasks/software-images', component: AdminTaskSwImages, 
            meta: {requiresAuth: true,title :'device-SwImages'}},
  { name: "SW_IMAGES_VIEW", path: '/config/admin-tasks/software-images/view/:id', component: AdminTaskSwImagesView ,
            meta: {requiresAuth: true,pageAuth:true,title :'device-SwImages'}},
  { name: "SW_IMAGES_CREATE", path: '/config/admin-tasks/software-images/create', component: AdminTaskSwImagesCrud ,
            meta: {requiresAuth: true,pageAuth:true,title :'device-SwImages'}},
  { name: "SW_IMAGES_EDIT", path: '/config/admin-tasks/software-images/edit/:id/:readonly', component: AdminTaskSwImagesCrud, 
            meta: {requiresAuth: true,pageAuth:true,title :'device-SwImages'}},

  { name: "ALL_JOBS", path: '/config/admin-tasks/all-jobs', component: AdminTaskAllJobs, 
            meta: {requiresAuth: true,title :'device-SwImages'}},

  { name: "SYSTEM_SETTINGS", path: '/config/admin-tasks/system-settings', component: AdminSystemSettings, 
            meta: {requiresAuth: true,title :'device-SwImages'}},

  { name:"ALARMS", path: '/config/admin-tasks/events', component: EventsAlarms, meta: {requiresAuth: true, title :'Events'}},
  { name:"ALARMS_EDIT", path: '/config/admin-tasks/events/edit/:id/:readonly',  component: EventsAlarmsCrud,
            meta: {requiresAuth: true, title :'Events Details View/Edit'}},

  { path: '/page-not-found', component: Pagenotfound },
  { path: '/page-not-auth', component: PagenotAuth },
  { path: '*', redirect: '/page-not-found' }
]
export default routes;
