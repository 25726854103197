<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />

		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-circle-multiple</v-icon>MODA Agent Software Image {{ titleName }}
			</v-card-title>
			<v-card-text class="pb-0">
				<!-- begin create/edit form -->
				<v-container fluid class="pl-5 pr-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<form ref="devSwImageform" class="form-view">

						<v-row dense>
							<v-col cols="12" sm="4">
									<strong>Name</strong>
									<p>{{ devSwImageDetails.deviceSoftwareImageName ||  "--" }}</p>
							</v-col>
							<v-col cols=12 sm=4>
								<strong>Description</strong>
								<p>{{ devSwImageDetails.description ||  "--" }}</p>
							</v-col>
							<v-col cols=12 sm=4>
								<strong>Model</strong>
								<v-autocomplete dense v-model="devSwImageDetails.deviceModelId" :items="models_list" item-text="deviceModelName"
									item-value="deviceModelId" auto-select-first
									class="moda-required" label="" :disabled="readonly" />
							</v-col>
							<v-col cols=12 sm=4>
								<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="0" transition="scale-transition"
									offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="releaseDate" label="Rel Date" prepend-icon="mdi-calendar" readonly
											:disabled="readonly" v-bind="attrs" v-on="on" />
									</template>
									<v-date-picker v-model="releaseDate" @input="menu2 = false"></v-date-picker>
								</v-menu>
							</v-col>

							<v-col cols=12 sm=4>
								<strong>Version</strong>
								<p>{{ devSwImageDetails.deviceSoftwareImageVersion ||  "--" }}</p>
							</v-col>
							<v-col cols=12 sm=4>
								<strong>MD5 hash</strong>
								<p>{{ devSwImageDetails.deviceSoftwareImageMd5Hash ||  "--" }}</p>
							</v-col>
						</v-row>
					</form>
				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>

	</div>
</template>

<script>
import ApiService from '@/services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'

export default {
	components: {
		Breadcrumb
	},
	data() {
		return {

			menu2: false,
			releaseDate: new Date().toISOString().substr(0, 10),
			currOrgId: null,
			targetOrgId: null,

			isLoading: false,
			fullPage: true,
			titleName: 'Create',

			isEdit: false,
			readonly: true,

			device_search: '',
			swVersionSelected: '',
			models_list: [],
			swVersionList: [{
				"orgId": null, "deviceSoftwareImageId": "devswi.10002", "deviceModelId": "devmod.1002",
				"deviceSoftwareImageName": "AgentSoftware-Ubuntu", "deviceSoftwareImageVersion": 1, "deviceSoftwareImageDate": 1617143484546,
				"deviceSoftwareImageFilename": "devmod.1002_moda_root.tgz", "description": "Device Software Image for Ubuntu OS",
				"entryType": "System"
			}],
			deviceModelToSwVersionMap: new Map(),

			height: 35,
			width: 35,
			userdetails: JSON.parse(localStorage.getItem('user-details')),
			fileSelected: {}, // this is not a string. It's javascript File object. Can't serialize it
			fileUploadNeeded: false,
			devSwImageDetails: {
				orgId: null,
				deviceSoftwareImageId: null,
				deviceModelId: "",
				deviceSoftwareImageName: "",
				deviceSoftwareImageVersion: 1,
				deviceSoftwareImageDate: 1617143484546,
				deviceSoftwareImageFilename: "",
				deviceSoftwareImageMd5Hash: "",
				description: "",
				entryType: "System",
			},
			parent_pages: [{ name: 'Home' }, { name: "Admin Tasks" },
			{ name: 'MODA Agent SW Images', route: { name: "SW_IMAGES" } },
			{ name: "View", active: true }],
			apiStatus: null,
		};
	},
	mixins: [Utils],
	methods: {

		cancel() {
			this.cancelCreatedevSwImages()
		},
		cancelCreatedevSwImages() {
			this.$router.go(-1);
		},
		getModelsList() {
			let query_params = {};
			if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

			//this.isLoading=true;
			ApiService.get("/api/v1/deviceModel", (err, result) => {
				this.isLoading = false;
				if (err) {
					this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
					this.utilsCheckLogout(this.apiStatus);
				} else {
					this.apiStatus = null;
					this.models_list = result.data.data;
					this.deviceModelId = this.models_list[0].deviceModelId;
					this.models_list.forEach(item => {
						this.deviceModelToSwVersionMap.set(item.deviceModelId, []);
					});
					//this.getModelToSwVersionList();
				}
			}, query_params)
		},
		getModelToSwVersionList() {
			let query_params = {};
			if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

			var body = {
				//deviceModelIds : [],
				deviceModelIds: Array.from(this.deviceModelToSwVersionMap.keys()),
			};

			//this.isLoading=true;
			//body.deviceModelIds.unshift(this.deviceModelId);
			//body.deviceModelIds.unshift(Array.from(this.deviceModelToSwVersionMap.keys()));
			console.log("getModelToSwVersionList swVersionList: modelIds " + body.deviceModelIds);
			ApiService.post("/api/v1/softwareImage/devicemodel", body, (err, result) => {
				this.isLoading = false;
				if (err) {
					this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
					console.log("getModelToSwVersionList swVersionList: error " + err);
					this.utilsCheckLogout(this.apiStatus);
				} else {
					this.apiStatus = result.result;
					this.swVersionList = result.data.data;
					this.swVersionList.forEach(item => {
						let abc = this.deviceModelToSwVersionMap.get(item.deviceModelId);
						abc.unshift(item);
					});
				}
			}, query_params)
		},
		getCurrSwImageDetails() {
			// if ( ! this.isEdit ) return;
			//this.isLoading=true
			let query_params = {};
			if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
			ApiService.get("/api/v1/softwareImage/" + this.devSwImageDetails.deviceSoftwareImageId, (err, result) => {
				//console.log(JSON.stringify(result))
				this.isLoading = false
				if (err) {
					this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
				} else if (result.data.data[0]) {
					// this.devSwImageDetails.deviceSoftwareImageId 
					this.devSwImageDetails = result.data.data[0];
					console.log("getCurrSwImageDetails " + JSON.stringify(this.devSwImageDetails));
					this.releaseDate = this.utilsFormatDateTime2UTC(this.devSwImageDetails.deviceSoftwareImageDate).substring(0, 10);
				}
			}, query_params)
		},
		processBreadcrumbMessage(selectedOrgId) {
			this.currOrgId = selectedOrgId;
			if ((this.devSwImageDetails.deviceSoftwareImageId != "") &&
				(this.devSwImageDetails.deviceSoftwareImageId != undefined)) {
				// Edit can't be done when org is changed, so g back
				this.cancelCreatedevSwImages();
			} else
				this.getModelsList()
		},
	},
	mounted() {
		this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
		this.targetOrgId = this.$route.params.targetOrgId;
		this.devSwImageDetails.deviceSoftwareImageId = this.$route.params.id; // deviceSoftwareImageId;
		this.readonly = true;
		this.getModelsList();
		if ((this.devSwImageDetails.deviceSoftwareImageId) && (this.devSwImageDetails.deviceSoftwareImageId != "")) {
			this.isEdit = true;
			this.titleName = this.readonly ? "View" : "Edit"
			this.getCurrSwImageDetails();
		}
	}
}

</script>
