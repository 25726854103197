import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min, max, min_value, max_value, email } from 'vee-validate/dist/rules';
import { Regex } from '@/utils/RegEx';
import { ipAddress } from "vuelidate/lib/validators"

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('email', {
  ...email,
  message: 'Invalid email address'
});

extend('emails', {
  validate(value) {
    let valid = true;
    let emails = value.split(",").map((el) => el.trim());
    emails.forEach(email => {
      if(!Regex.email.test(email)) valid = false;
    });
    return valid;
  },
  message: 'Invalid email address'
});

extend('min', {
  ...min,
  message: 'Atleast {length} characters required'
});
extend('max', {
  ...max,
  message: 'Max {length} characters allowed'
});
extend('min_value', {
  ...min_value,
  message: 'Min value must be {min}'
});
extend('max_value', {
  ...max_value,
  message: 'Max value must be {max}'
});

extend('alphanumeric', {
  validate(value) { return Regex.alphanumeric.test(value); },
  message: 'Alphanumeric only'
});

/*
extend('IPv4', {
  validate(value) { return Regex.IPv4.test(value); },
  message: 'Invalid IPv4 Address'
});
*/
extend('IPv4', {
  validate(value) { return ipAddress(value); },
  message: 'Invalid IPv4 Address2'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
