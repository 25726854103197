const Menu = [
  {
    path: '/dashboards', icon: 'mdi-view-dashboard', title: 'Dashboards',
    children: [
      { path: '/dashboards/summary-status', icon: 'mdi-chart-donut', title: 'Status Summary' },
      { path: '/dashboards/monitored-device-status', icon: 'mdi-list-status', title: 'Device Status' },
      { path: '/dashboards/network-map', icon: 'mdi-lan', title: 'Network Map' },
      // TBD: uncomment performancesummary when it's fully ready
      //{ path: '/dashboards/performance-summary', icon: 'mdi-trending-down', title: 'Performance Summary' },

      { path: '/dashboards/network-analytics', icon: 'mdi-poll', title: 'Network Analytics' },
      //{ path: '/dashboards/networkAnalyticsGrafana', icon: 'mdi-poll', title: 'Network Analytics Grafana' },
      { path: '/dashboards/device-analytics', icon: 'mdi-router-wireless-settings', title: 'MODA Agent Status' },
    ]
  },
  { path: '/organizations', icon: 'mdi-home-city', title: 'Organizations' },
  { path: '/sites', icon: 'mdi-map-marker-multiple', title: 'Sites' },
  { path: '/applications', icon: 'mdi-apps', title: 'Applications', },
  { path: '/devices', icon: 'mdi-router-wireless', title: 'Devices' },
  { path: '/incidents', icon: 'mdi-ticket-confirmation', title: 'Incidents' },

  {
    path: '/config', icon: 'mdi-cog', title: 'Configuration',
    children: [
      { path: '/config/moda-agents', icon: 'mdi-router-wireless', title: 'MODA Agents' },
      // TBD: uncomment org and site Groups when it's fully tested
      //{ path: '/orgsGroups', icon: 'fa fa-object-group',title: 'Org-Groups' },
      //{ path: '/sitesGroups', icon: 'fa fa-object-group',title: 'Site-Groups' },
      {
        path: '/profiles-jobs', icon: 'mdi-clipboard-text-multiple', title: 'Profiles & Jobs',
        children: [
          {
            path: '/config/profiles-jobs/monitoring-templates', icon: 'mdi-clipboard-pulse',
            title: 'Monitoring Templates'
          },
          {
            path: '/config/profiles-jobs/analytics-profiles', icon: 'mdi-chart-timeline-variant',
            title: 'Analytics Profiles'
          },
          {
            path: '/config/profiles-jobs/system-profiles', icon: 'mdi-window-shutter-cog',
            title: 'System Profiles'
          },
          {
            path: '/config/profiles-jobs/device-config-map', icon: 'mdi-chart-donut',
            title: 'Profile Usage'
          },
          {
            path: '/config/profiles-jobs/device-config-jobs', icon: 'mdi-list-box-outline',
            title: 'Config Jobs'
          },
          {
            path: '/config/profiles-jobs/device-sw-update-jobs', icon: 'mdi-arrow-up-thick',
            title: 'SW Update Jobs'
          },
          {
            path: '/config/profiles-jobs/cw-profile', icon: 'mdi-google-circles-group',
            title: 'ConnectWise Profile'
          },
        ]
      },
      { path: '/config/filtering-rules', icon: 'mdi-filter-settings',title: 'Filtering Rules' },
      { path: '/config/correlation-rules', icon: 'mdi-transit-connection-variant',title: 'Correlation Rules' },
      {
        path: '/config/access-control', icon: 'mdi-account-lock', title: 'Access Control',
        children: [
          { path: '/config/access-control/users', icon: 'mdi-account', title: 'Users' },
          { path: '/config/access-control/policies', icon: 'mdi-text-box', title: 'Policies' },
        ]
      },
      /* This child is pushed conditionally below
            { path: '/config/admin-tasks', icon: 'fa fa-lock', title: 'Admin Tasks',
              children: [
                { path: '/config/admin-tasks/events', icon: 'mdi-bell',title: 'Events' },
                { path: '/config/admin-tasks/device-models', icon: 'fa fa-list-ol',title: 'Device Models' },
                { path: '/config/admin-tasks/software-images', icon: 'mdi-circle-multiple', title: 'MODA Agent Software' },
                { path: '/config/admin-tasks/all-jobs', icon: 'fa fa-list-ol',title: 'All Jobs' },
                { path: '/config/admin-tasks/system-settings', icon: 'mdi-application-cog-outline', title: 'System Settings' },
              ]
            },
      */
    ]
  },

]

let udObj = localStorage.getItem("user-details");
if (udObj) {
    try {
  let userDetails = JSON.parse(udObj);
  //DEBUG: console.log("sidebarMenu user-details " + typeof(userDetails) + " Value " + JSON.stringify(userDetails))
  if (userDetails.email.includes("@modatechnologies.com")) {
    Menu.push(
      {
        path: '/config/admin-tasks', icon: 'mdi-view-list', title: 'Admin Tasks',
        children: [
          { path: '/config/admin-tasks/events', icon: 'mdi-bell',title: 'Events' },
          { path: '/config/admin-tasks/device-models', icon: 'mdi-table-cog', title: 'Device Models' },
          { path: '/config/admin-tasks/software-images', icon: 'mdi-circle-multiple', title: 'MODA Agent Software' },
          { path: '/config/admin-tasks/all-jobs', icon: 'mdi-calendar-clock', title: 'All Jobs' },
          { path: '/config/admin-tasks/system-settings', icon: 'mdi-application-cog-outline', title: 'System Settings' },
        ]
      }
    )
  }
    } catch {
        console.log("sidebarMenu user-details " + typeof(udObj) + " Value " + JSON.stringify(udObj))
    }
} else {
  console.error("userDetails object is NULL");
}

export default Menu;
