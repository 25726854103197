<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="2" class="tile-box">
      <v-card-title class="page-title">
        <v-icon>mdi-apps</v-icon> {{ titleName }} Application
        <v-btn v-if="isEdit" :key="statusesKey1" class="status-button" :color="utilsGetSeverityColor(applicationDetails.highestSeverity).colorName" rounded outlined right absolute>
          Status: <v-icon>{{ utilsFaultStatusDisplayObject(applicationDetails.faultStatus).icon }}</v-icon><strong>{{ applicationDetails.highestSeverity ?
            applicationDetails.highestSeverity :utilsFaultStatusDisplayObject(applicationDetails.faultStatus).text }} </strong>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container fluid class="pl-5 pr-5">
          <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
            {{ apiStatus }}
          </v-alert>
          <v-row v-if="isEdit">
            <v-col cols="3" :key="statusesKey2">
              <v-alert text class="link-alert" prominent type="warning" icon="mdi-ticket-confirmation"
                @click="navigateToIncidents(applicationDetails)">
                <p class="mb-0">Incidents</p>
                <h3>
                  {{ applicationDetails.incCount || 0 }}
                </h3>
              </v-alert>
            </v-col>
          </v-row>
          <form ref="applicationform">
            <v-row v-if="isLoading">
              <v-col cols="12">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field dense v-model="applicationDetails.appName" :error-messages="nameErrors"
                  @input="$v.applicationDetails.appName.$touch()" @blur="$v.applicationDetails.appName.$touch()"
                  class="moda-required" label="Application Name" :disabled="readonly" />
              </v-col>
              <v-col cols="12" :md="applicationDetails.iconImg || applicationDetails.icon ? 3 : 4">
                <v-file-input dense v-model="iconFileSelected" label="Icon file" accept="image/svg,image/png"
                  :hint='"SVG/PNG files < " + MAX_ICON_FILE_SIZE_STR' persistent-hint @change="onIconFileChange"
                  :error-messages="iconFileErrors" @input="$v.iconFileSelected.$touch()"
                  @blur="$v.iconFileSelected.$touch()" :disabled="readonly"></v-file-input>
              </v-col>
              <v-col cols="12" v-if="applicationDetails.iconImg || applicationDetails.icon" md="1">
                <!-- user created apps have 'iconImg' field and image resides in mongo -->
                <v-img v-if="applicationDetails.iconImg" :src="applicationDetails.iconImg" height="30" width="30"
                  contain />
                <!-- system apps have 'icon' file name as file resides on file system -->
                <v-img v-else-if="applicationDetails.icon"
                  :src="require('../../../public/assets/img/moda/' + applicationDetails.icon)" height="30" width="30"
                  contain />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field dense v-model="applicationDetails.description" label="Description" :disabled="readonly">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-autocomplete v-model="applicationDetails.appType" :items="allParams.appTypes" item-value="value"
                  dense item-text="displayName" prepend-inner-icon="mdi-filter-multiple-outline"
                  :error-messages="typeErrors" auto-select-first @input="$v.applicationDetails.appType.$touch()"
                  @blur="$v.applicationDetails.appType.$touch()" class="moda-required" label="Type"
                  :disabled="readonly" />
              </v-col>
              <!-- Either protocol or subType is shown based on appType, not both -->
              <v-col cols="12" md="3">
                <v-autocomplete v-if="protocol_field_show" v-model="applicationDetails.protocol" dense
                  :items="allParams.protocols" item-value="value" item-text="displayName"
                  prepend-inner-icon="mdi-filter-multiple-outline" :error-messages="protoErrors" auto-select-first
                  @input="$v.applicationDetails.protocol.$touch()" @blur="$v.applicationDetails.protocol.$touch()"
                  class="moda-required" label="Protocol" :disabled="readonly" />
                <v-autocomplete v-if="subtype_field_show" v-model="applicationDetails.subType" dense
                  :items="getSubTypes()" item-value="value" item-text="displayName"
                  prepend-inner-icon="mdi-filter-multiple-outline" :error-messages="subTypeErrors" auto-select-first
                  @input="$v.applicationDetails.subType.$touch()" @blur="$v.applicationDetails.subType.$touch()"
                  class="moda-required" label="Subtype" :disabled="readonly" />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-if="port_fields_show" v-model.number="applicationDetails.portRange.start" dense
                  label="Port Start" type="number" v-on:keypress="utilsIsNumber" class="moda-required"
                  :error-messages="portStartErrors" @input="$v.applicationDetails.portRange.start.$touch()"
                  @blur="$v.applicationDetails.portRange.start.$touch()" :disabled="readonly">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-if="port_fields_show" v-model.number="applicationDetails.portRange.end" label="Port End"
                  dense type="number" v-on:keypress="utilsIsNumber" class="moda-required"
                  :error-messages="portEndErrors" @input="$v.applicationDetails.portRange.end.$touch()"
                  @blur="$v.applicationDetails.portRange.end.$touch()" :disabled="readonly">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="domain_field_show">
              <v-col cols="12">
                <v-textarea dense v-model="applicationDetails.domains" :error-messages="domainErrors"
                  @input="$v.applicationDetails.domains.$touch()" @blur="$v.applicationDetails.domains.$touch()"
                  class="moda-required" label="Domains" :disabled="readonly" auto-grow rows="1" row-height="15" />
              </v-col>
            </v-row>
            <v-row>
              <!-- TBD type == Enum, Boolean -->
              <template v-for="paramName in Object.keys(allParams.params)">
                <v-col cols="12" :md="3"
                  v-if="isShowParam(paramName) && allParams.params[paramName].type == 'Integer' || allParams.params[paramName].type == 'String'"
                  :key="paramName">
                  <v-text-field dense v-if="isShowParam(paramName) && allParams.params[paramName].type == 'Integer'"
                    :label="allParams.params[paramName].displayName"
                    v-model.number="applicationDetails.params[paramName]" type="number" :hint="getRange(paramName)"
                    :min="allParams.params[paramName].range[0]" :max="allParams.params[paramName].range[1]"
                    v-on:keypress="utilsIsNumber" :disabled="readonly" :error-messages="paramErrors(paramName)"
                    @input="modified_param = paramName; $v.applicationDetails.params[paramName].$touch();"
                    @blur="$v.applicationDetails.params[paramName].$touch()">
                  </v-text-field>
                  <v-text-field dense v-if="isShowParam(paramName) && allParams.params[paramName].type == 'String'"
                    :label="allParams.params[paramName].displayName" v-model="applicationDetails.params[paramName]"
                    :disabled="readonly">
                  </v-text-field>
                </v-col>
              </template>
            </v-row>
          </form>
        </v-container>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn rounded outlined color="success" v-if="!readonly && !isEdit" @click="submit"
          :disabled="this.$v.$invalid">Create</v-btn>
        <v-btn rounded outlined color="success" v-if="!readonly && isEdit" @click="submit" :disabled="this.$v.$invalid">
          {{ duplicate ? "Duplicate" : "Update" }}
        </v-btn>
        <v-btn rounded outlined color="error" v-if="!readonly" @click="cancel">Cancel</v-btn>
        <v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
      </v-card-actions>
    </v-card>
    <!-- user created apps have 'iconImg' field and image resides in mongo -->
    <!-- system apps have 'icon' file name as file resides on file system -->
  </div>
</template>


<script>
import { validationMixin } from "vuelidate";
import {
  between,
  minLength,
  required,
  requiredIf,
} from "vuelidate/lib/validators";
import ApiService from "@/services/api.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import Utils from "@/pages/moda/Utils.vue";


const MAX_ICON_FILE_SIZE_BYTES = 200000
const MAX_ICON_FILE_SIZE_STR = "200kB"

const isIconFileValid = (file) => !file || file.size === undefined || file.size < MAX_ICON_FILE_SIZE_BYTES

export default {
  components: {
    //Loading,
    Breadcrumb,
  },
  data() {
    return {
      MAX_ICON_FILE_SIZE_STR: MAX_ICON_FILE_SIZE_STR,
      currOrgId: null,
      isLoading: false,
      titleName: "Create",
      application_id: "",
      isEdit: false,
      readonly: true,
      duplicate: false,

      fullPage: true,
      onCancel: true,

      height: 35,
      width: 35,

      userdetails: JSON.parse(localStorage.getItem("user-details")),
      allParams: {
        // All params and their applicability to various types of applications
        appTypes: [],
        protocols: [],
        params: {},
      },
      applicationDetails: {
        applicationId: "",
        appName: "",
        appType: "",
        iconImg: "",
        protocol: "",
        domains: [],
        portRange: {},
        params: {},

        description: "",
      },
      iconFileSelected : {}, // file object, not a string. Can't serialize
      //thisApplicationParamNames:[], // this particular application (being edited) params
      protocol_field_show: false,
      subtype_field_show: false,
      port_fields_show: false,
      domain_field_show: false,
      modified_param: "", // save the currently modified param name here. vuetify $each doesn't give the index. This is the only way
      parent_pages: [
        { name: "Home" },
        { name: "Applications", route: { name: "APPLICATIONS" } },
        { name: "Create/Edit", active: true },
      ],
      apiStatus: null,
      statusesKey1:0, // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
      statusesKey2:1, // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
    };
  },
  watch: {
    applicationDetails: {
      handler(val) {
        var appType = this.utilsGetArrayElementByFieldValue( this.allParams.appTypes, "value", val.appType);
        this.protocol_field_show = appType ? appType.isProtocol : false;
        this.subtype_field_show = appType ? appType.isSubtype : false;

        var proto = this.utilsGetArrayElementByFieldValue( this.allParams.protocols, "value", val.protocol);
        this.port_fields_show = this.protocol_field_show && proto ? proto.isPort : false;
        this.domain_field_show = this.protocol_field_show && proto ? proto.isDomain : false;
      },
      deep: true,
    },
  },

  mixins: [validationMixin, Utils],
  validations: {
    iconFileSelected: { isIconFileValid },
    applicationDetails: {
      appName: { required, minLength: minLength(3) },
      appType: { required },
      protocol: {
        required: requiredIf(function () { return this.protocol_field_show; }), 
      },
      subType: {
        required: requiredIf(function () { return this.subtype_field_show; }),
      },
      portRange: {
        start: {
          required: requiredIf(function () { return this.port_fields_show; }), 
          between: between(1, 65535),
        },
        end: {
          required: requiredIf(function () { return this.port_fields_show; }),
          between: between(1, 65535),
        },
      },
      domains: {
        required: requiredIf(function () { return this.domain_field_show; }),
      },
      params: {
        /* $each operator has sever deficiency. It doesn't supply index aka param name in our case.
                   It supplies only top level object aka params in our case. So, we have no way to know which
                   parameter it's being called for. So, only way is to list each parameter here separately instead of using $each

                   !!!!!!!! So, anytime new params added in backend, we have to add it here :(   !!!!!!!!
                */
        sitePilotMeasurementInterval: {
          customBetween() {
            return this.isAppParamValid("sitePilotMeasurementInterval");
          },
        },
        siteActualMeasurementInterval: {
          customBetween() {
            return this.isAppParamValid("siteActualMeasurementInterval");
          },
        },
        deviceMeasurementInterval: {
          customBetween() {
            return this.isAppParamValid("deviceMeasurementInterval");
          },
        },
        pathMeasurementInterval: {
          customBetween() {
            return this.isAppParamValid("pathMeasurementInterval");
          },
        },
        //tCPRetransPktsRatioThresh:  { customBetween() { return this.isAppParamValid("tCPRetransPktsRatioThresh") } },
        //tCPWinSizeAvgThresh:        { customBetween() { return this.isAppParamValid("tCPWinSizeAvgThresh") } },
        //uDPFlowLatencyStdDevThresh: { customBetween() { return this.isAppParamValid("uDPFlowLatencyStdDevThresh") } },
        //uDPFlowAvgLatencyThresh:    { customBetween() { return this.isAppParamValid("uDPFlowAvgLatencyThresh") } },
        //pathLatencyStdDevThresh:    { customBetween() { return this.isAppParamValid("pathLatencyStdDevThresh") } },
        //pathLatencyThresh:          { customBetween() { return this.isAppParamValid("pathLatencyThresh") } },
        //pathLossThresh:             { customBetween() { return this.isAppParamValid("pathLossThresh") } },
        //uplinkThresh:               { customBetween() { return this.isAppParamValid("uplinkThresh") } },
        //downlinkThresh:             { customBetween() { return this.isAppParamValid("downlinkThresh") } },
        //rspsReqsRatioThresh:        { customBetween() { return this.isAppParamValid("rspsReqsRatioThresh") } },
        //errRspsReqsRatioThresh:     { customBetween() { return this.isAppParamValid("errRspsReqsRatioThresh") } },

        // string params have no validations
        //wifi24SSID:                 { customBetween() { return this.isAppParamValid("wifi24SSID") } },
        //wifi24Password:             { customBetween() { return this.isAppParamValid("wifi24Password") } },
        //wifi5SSID:                  { customBetween() { return this.isAppParamValid("wifi5SSID") } },
        //wifi5Password:              { customBetween() { return this.isAppParamValid("wifi5Password") } },
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.applicationDetails.appName.$dirty) return errors;
      !this.$v.applicationDetails.appName.minLength && errors.push("Name must be atleast 3 characters long");
      !this.$v.applicationDetails.appName.required && errors.push("Name is required.");
      return errors;
    },
    iconFileErrors() {
      const errors = [];
      if (!this.$v.iconFileSelected.$dirty) return errors;
      !this.$v.iconFileSelected.isIconFileValid && errors.push("Icon file must be < " + MAX_ICON_FILE_SIZE_STR);
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.applicationDetails.appType.$dirty) return errors;
      !this.$v.applicationDetails.appType.required &&
        errors.push("Type is required.");
      return errors;
    },
    protoErrors() {
      const errors = [];
      if (!this.$v.applicationDetails.protocol.$dirty) return errors;
      !this.$v.applicationDetails.protocol.required &&
        errors.push("Protocol is required.");
      return errors;
    },
    subTypeErrors() {
      const errors = [];
      if (!this.$v.applicationDetails.subType.$dirty) return errors;
      !this.$v.applicationDetails.subType.required &&
        errors.push("Subtype is required.");
      return errors;
    },
    domainErrors() {
      const errors = [];
      if (!this.$v.applicationDetails.domains.$dirty) return errors;
      !this.$v.applicationDetails.domains.required &&
        errors.push("Domain is required.");
      return errors;
    },
    portStartErrors() {
      const errors = [];
      if (!this.$v.applicationDetails.portRange.start.$dirty) return errors;
      !this.$v.applicationDetails.portRange.start.required && errors.push("Port start is required.");
      !this.$v.applicationDetails.portRange.start.between && errors.push("Port start must be 1 - 65535.");

      return errors;
    },
    portEndErrors() {
      const errors = [];
      if (!this.$v.applicationDetails.portRange.end.$dirty) return errors;
      !this.$v.applicationDetails.portRange.end.required && errors.push("Port end is required.");
      !this.$v.applicationDetails.portRange.end.between && errors.push("Port end must be 1 - 65535.");

      if ( this.applicationDetails.portRange.start && this.applicationDetails.portRange.end &&
           this.applicationDetails.portRange.end <= this.applicationDetails.portRange.start)
        errors.push("Port end must be > port start!");

      return errors;
    },
  },

  methods: {
    onIconFileChange(fileObject) {
        // fileObject is not a string. It's javascript File object. Can't serialize it
        if ( fileObject ) {
            const reader = new FileReader();
            reader.readAsDataURL(fileObject);
            reader.onload = () => {
                this.applicationDetails.iconImg = reader.result;
                //console.log("onIconFileChange " + this.applicationDetails.iconImg)
            }
        } else {
            this.applicationDetails.iconImg = {} // if you just delete this field, any existing iconImg won't be overwritten. So, set to empty
        }
    },

    paramErrors(paramName) {
      // computed params can't take arguments
      const errors = [];
      //console.log("paramErrors " + paramName + " " + JSON.stringify(this.$v.applicationDetails.params));
      if ( !this.$v.applicationDetails.params[paramName] || !this.$v.applicationDetails.params[paramName].$dirty)
        return errors;
      !this.$v.applicationDetails.params[paramName].customBetween && errors.push("Out of range");
      return errors;
    },

    navigateToIncidents(row) {
        //console.log("navigateToIncidents appRowSelected ", JSON.stringify(row));
        var appIds = [], appNames=[], states = []//, advSearchString = "";
        appIds = [row.appId]
        appNames = [row.appName]
        states = this.utilsOpenStates;
        //advSearchString = "Open incs. in " + row.appName;

        var query = [];
        if (appIds.length && appNames.length) {
          query.push({name: "component", value: {
                  componentIdType: this.UtilsModaConstants.FaultService.ComponentIdType.APP,
                  componentIdList: appIds,
                  componentNameList: appNames
                }
          });
        }

        /*~~~ OLD ~~~
        if (appIds.length) {
            query.push({ name: "appId", value: appIds });
            query.push({ name: "componentId", value: appIds });
        }
        if (appNames.length) {
            query.push({ name: "appName", value: appNames });
            query.push({ name: "componentName", value: appNames });
        }
        ~~~~~~~~~~~~~*/

        query.push({ name: "state", value: states });
        console.log("navigateToIncidents query ", JSON.stringify(query));
        query.length ? this.$router.push({ name: "INCIDENTS", query: query }) : this.$router.push({ name: "INCIDENTS" });
    },


    getSubTypes() {
      var subTypes =
        this.applicationDetails.appType == this.UtilsModaConstants.AppType.SITE_MONITORING ? this.allParams.subTypesSite
                                                : this.allParams.subTypesDevice;
      //console.log("getSubTypes " + this.applicationDetails.appType + " subTypes " + JSON.stringify(subTypes));
      return subTypes;
    },

    isAppParamValid(paramName) {
      var param = this.allParams.params[paramName];
      var range = param.range;
      var value = this.applicationDetails.params[paramName];

      if (!param.type != "Integer") return true; // this routine is called for Integers only anyways

      //console.log("appParamValidate " + paramName + " " + JSON.stringify(range));
      return value && value != ""
        ? value >= range[0] && value <= range[1]
        : true;
    },

    getRange(allParamName) {
      var allParams = this.allParams.params;
      return ( allParams[allParamName].range[0] + "-" + allParams[allParamName].range[1]);
    },

    isShowParam(allParamName) {
      var allParams = this.allParams.params;
      //var thisAppParams = this.applicationDetails.params;
      var thisAppType = this.applicationDetails.appType;
      var thisAppProto = this.applicationDetails.protocol;
      var thisAppSubtype = this.applicationDetails.subType;

      //   console.log(
      //     "isShowParam " +
      //       allParamName +
      //       " " +
      //       JSON.stringify(allParams[allParamName].range)
      //   );
      return (
        allParams &&
        // if edit, does this app have the allParamName ? - whether app has params are not, display all eligible params
        //&& ( ! this.isEdit || (thisAppParams && thisAppParams[allParamName]))
        // does this app's appType match param appTypes ?
        allParams[allParamName].appTypes &&
        allParams[allParamName].appTypes.includes(thisAppType) &&
        // does this app's protocol match param protols ?
        (!thisAppProto ||
          !allParams[allParamName].protocols ||
          !allParams[allParamName].protocols.length ||
          allParams[allParamName].protocols.includes(thisAppProto)) &&
        // does this app's subType match param subTypes ?
        (!thisAppSubtype ||
          !allParams[allParamName].subTypes ||
          !allParams[allParamName].subTypes.length ||
          allParams[allParamName].subTypes.includes(thisAppSubtype))
      );
    },

    submit() {
      this.$v.$touch();

      this.applicationDetails.appName = this.applicationDetails.appName.trim();
      if (typeof this.applicationDetails.domains == "string")
        if ( this.applicationDetails.domains )
            this.applicationDetails.domains = this.applicationDetails.domains.trim().split(/[\s,\n;]+/);

      if (!this.protocol_field_show) delete this.applicationDetails.protocol;
      if (!this.subtype_field_show) delete this.applicationDetails.subType;
      if (!this.port_fields_show) delete this.applicationDetails.portRange;
      if (!this.domain_field_show) delete this.applicationDetails.domains;

      Object.keys(this.allParams.params).forEach((paramName) => {
        if ( !this.isShowParam(paramName) || !this.applicationDetails.params[paramName])
          delete this.applicationDetails.params[paramName];
        else if (this.allParams.params[paramName].type == "String") {
          this.applicationDetails.params[paramName] =
            this.applicationDetails.params[paramName].trim();
        }
      });

      //console.log("Application submit ***** "+JSON.stringify(this.applicationDetails).length)

      this.isLoading = true;
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      if (this.duplicate) this.isEdit = !this.duplicate;

      if (this.isEdit) {
        ApiService.put( "/api/v1/application/" + this.application_id, this.applicationDetails, (err, result) => {
            //console.log("*update*"+JSON.stringify(result))
            this.isLoading = false;
            if (err){
              this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
              this.utilsCheckLogout(this.apiStatus);
            } else {
              this.apiStatus = null;
              this.cancelCreateApplication();
            }
          },
          query_params
        );
      } else {
        // create
        ApiService.post( "/api/v1/application", this.applicationDetails, (err, result) => {
            // console.log("*create*"+JSON.stringify(err) + " " + JSON.stringify(result));
            this.isLoading = false;
            if (err) {
              this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
              this.utilsCheckLogout(this.apiStatus);
            } else {
              this.apiStatus = null;
              this.cancelCreateApplication();
            }
          },
          query_params
        );
      }
    },
    cancel() {
      this.$v.$reset();

      this.cancelCreateApplication();
    },

    cancelCreateApplication() {
      this.$router.go(-1);
    },
    getApplicationParams() {
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      this.isLoading = true;

      ApiService.get( "/api/v1/applicationParams", (err, result) => {
          this.isLoading = false;
          if (err){
            this.apiStatus = result.data.message ? result.data.message : result.data;
            this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            this.allParams = result.data.data[0];
            //console.log("getApplicationParams " + JSON.stringify(this.allParams));
            if (this.isEdit) this.getApplicationDetails();
          }
        },
        query_params
      );
    },

    getApplicationDetails() {
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      ApiService.get( "/api/v1/application/" + this.application_id, (err, result) => {
          if (err){
            this.apiStatus = result.data.message ? result.data.message : result.data; this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            this.applicationDetails = result.data.data[0];
            if ( !this.applicationDetails.portRange ) this.applicationDetails.portRange = {}// template is throwing error if this isn't present

            //this.thisApplicationParamNames = Object.keys(this.applicationDetails.params);
             //console.log( "getApplicationDetails " + JSON.stringify(this.applicationDetails) );
            this.duplicate && this.markAsDuplicate();
            this.titleName = (this.readonly ? "View - " : "Edit - ") + this.applicationDetails.appName;
          }
        },
        query_params
      );
    },
    markAsDuplicate() {
      this.applicationDetails.description += ` (duplicated from ${this.applicationDetails.appName})`;
      this.applicationDetails.appName += " copy";
      this.applicationDetails.entryType = "User";

      delete this.applicationDetails.appId;
      delete this.applicationDetails.createdDate;
      delete this.applicationDetails.adminStatus;

      this.application_id = "";
    },
    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      if (this.isEdit) {
        // Edit can't be done when org is changed, so g back
        this.cancelCreateApplication();
      } else this.getApplicationParams();
    },
  },
  mounted() {
    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    this.application_id = this.$route.params.appId;
    this.duplicate = this.$route.params.readonly === "duplicate";
    this.readonly = this.$route.params.readonly == "1";
    //this.getFeatures();
    this.getApplicationParams();
    if (this.application_id != "" && this.application_id != undefined) {
      this.isEdit = true;
      this.titleName = this.readonly ? "View" : "Edit";
      //this.getApplicationDetails();
    }
  },
};
</script>


