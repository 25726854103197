<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-text-box</v-icon> Policy {{ titleName }}
			</v-card-title>
			<v-card-text class="pb-0">
				<v-container fluid class="pl-5 pr-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<form ref="policyform" :class="readonly ? 'form-view' : ''">
						<v-row v-if="isLoading">
							<v-col cols="12">
								<v-progress-linear indeterminate color="primary"></v-progress-linear>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols=12 md=6>
								<v-text-field :dense="readonly" v-model="policydetails.policyName" :error-messages="nameErrors"
									@input="$v.policydetails.policyName.$touch()" @blur="$v.policydetails.policyName.$touch()"
									:class="!readonly ? 'moda-required' : ''" label="Policy Name" :disabled="readonly" />
							</v-col>
							<v-col cols=12 md=6>
								<v-text-field :dense="readonly" v-model="policydetails.description" label="Description" :disabled="readonly">
								</v-text-field>
							</v-col>
							<v-col cols=12>
								<v-autocomplete dense v-model="selected_features" :items="all_features" chips clearable multiple
									:error-messages="featureErrors" :return-object="true"
									@input="$v.selected_features.$touch()" @blur="$v.selected_features.$touch()" :class="!readonly ? 'moda-required' : ''"
									label="Features" :disabled="readonly">
									<template v-slot:selection="{ item, select, selected }">
										<v-chip :input-value="selected" :close="!readonly" dark color="primary" @click="select"
											@click:close="readonly || remove_feature(item)">
											<strong>{{ item.text }}</strong>
										</v-chip>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols=12>
								<v-autocomplete dense v-model="selected_permissions" :items="all_permissions" chips clearable multiple
									:error-messages="permissionErrors" :return-object="true"
									@input="$v.selected_permissions.$touch()" @blur="$v.selected_permissions.$touch()"
									:class="!readonly ? 'moda-required' : ''" label="Permissions" :disabled="readonly">
									<template v-slot:selection="{ item, select, selected }">
										<v-chip :input-value="selected" :close="!readonly" dark color="primary" @click="select"
											@click:close="readonly || remove_permission(item)">
											<strong>{{ item.text }}</strong>
										</v-chip>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
					</form>
				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="!readonly && !isEdit" @click="submit"
					:disabled="this.$v.$invalid">Create</v-btn>
				<v-btn rounded outlined color="success" v-if="!readonly && isEdit" @click="submit"
					:disabled="this.$v.$invalid">
					Update</v-btn>
				<v-btn rounded outlined color="error" v-if="!readonly" @click="cancel">Cancel</v-btn>
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>

import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import UserApiService from '@/services/user-api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils'

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            currOrgId: null,
            isLoading: false,
            fullPage: true,
            onCancel:true,
            titleName:'Create',
            policy_id:"",
            isEdit:false,
            readonly:true,
            height:35,
            width:35,

            selected_features:[],
            all_features:[{text:'all'}],
            selected_permissions:[],
            all_permissions:[{text:'read-only'},{text:'read-write'}],

            userdetails:JSON.parse(localStorage.getItem('user-details')),
            policydetails:{
                policyId :'',
                policyName : '',
                description : '',
                features : [],
                permissions:[],
            },
            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Access Control"},
                           {name:'Policies', route: { name: "USER_POLICIES"}},
                           {name:"Create/Edit", active:true} ],
            apiStatus: null,
        };
    },

    mixins: [validationMixin, Utils],
    validations: {
        policydetails : {
            policyName: { required, minLength: minLength(3) },
        },
        selected_features : { required },
        selected_permissions : { required },
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.policydetails.policyName.$dirty) return errors
            !this.$v.policydetails.policyName.minLength && errors.push('Name must be atleast 3 characters long')
            !this.$v.policydetails.policyName.required && errors.push('Name is required.')
            return errors
        },
        featureErrors () {
            const errors = []
            if (!this.$v.selected_features.$dirty) return errors
            !this.$v.selected_features.required && errors.push('Atleast one feature is required.')
            return errors
        },
        permissionErrors () {
            const errors = []
            if (!this.$v.selected_permissions.$dirty) return errors
            !this.$v.selected_permissions.required && errors.push('Atleast one permission is required.')
            return errors
        },
    },

    methods: {


        submit () {
            this.$v.$touch()

            this.policydetails.policyName = this.policydetails.policyName.trim();

            this.policydetails.features = this.selected_features.map(a => {
                return a.text
            });
            this.policydetails.permissions = this.selected_permissions.map(a => {
                return a.text
            });
            //console.log("*****"+JSON.stringify(this.policydetails))

            this.isLoading=true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            if ( this.isEdit ){
                UserApiService.put("/usersvc/api/v1/policy/"+this.policy_id,this.policydetails, (err, result) => {
                    //console.log("*update*"+JSON.stringify(result))
                    this.isLoading=false;
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreatePolicy()
                    }
                }, query_params)
            }else {// create
                UserApiService.post("/usersvc/api/v1/policy",this.policydetails, (err, result) => {
                    //console.log("*create*"+JSON.stringify(err) + " " + JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreatePolicy()
                    }
                }, query_params)
            }
        },
        cancel () {
            this.$v.$reset()

            this.cancelCreatePolicy()
        },
        remove_feature (feature) {
            //console.log("perm " + JSON.stringify(feature) + " arr " + JSON.stringify(this.selected_features));
            this.selected_features.splice(this.selected_features.findIndex(x => x.text === feature.text), 1)
            this.selected_features = [...this.selected_features]
        },
        remove_permission (permission) {
            //console.log("perm " + JSON.stringify(permission) + " arr " + JSON.stringify(this.selected_permissions));
            this.selected_permissions.splice(this.selected_permissions.findIndex(x => x.text === permission.text), 1)
            this.selected_permissions = [...this.selected_permissions]
        },


        cancelCreatePolicy(){
            this.$router.go(-1);
        },
        getFeatures(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            UserApiService.get("/usersvc/api/v1/features", (err, result) => {
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    var features = result.data.data;
                    //console.log("getFeatures " + JSON.stringify(features));
                    features.forEach((name) => {
                        this.all_features.push({text:name});
                    })
                }
            }, query_params)
        },
        getPolicyDetails(){
						this.isLoading = true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            UserApiService.get("/usersvc/api/v1/policy/"+this.policy_id, (err, result) => {
							this.isLoading = false;
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.policydetails = result.data.data[0];
                    this.selected_features=this.policydetails.features.map(name => {return {text:name}});
                    this.selected_permissions=this.policydetails.permissions.map(name => {return {text:name}});

                    //console.log("feat " + JSON.stringify(this.selected_features) + " perm " + JSON.stringify(this.selected_permissions));

                    this.titleName = (this.readonly ? "View - " : "Edit - ") +this.policydetails.policyName
                }
            }, query_params)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            if(this.policy_id != "" && this.policy_id !=undefined){
                // Edit can't be done when org is changed, so g back
                this.cancelCreatePolicy();
            }
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.policy_id = this.$route.params.id;
        this.readonly = (this.$route.params.readonly == "1");
        this.getFeatures();
        if(this.policy_id != "" && this.policy_id !=undefined){
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
            this.getPolicyDetails();
        }
    }
}

</script>


