import { render, staticRenderFns } from "./IncidentCorrelationRulesCrud.vue?vue&type=template&id=c2c25a56&"
import script from "./IncidentCorrelationRulesCrud.vue?vue&type=script&lang=js&"
export * from "./IncidentCorrelationRulesCrud.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports