// ============================================================================
// Configuration settings
// ============================================================================

var config = {};


// Main server
// ---------------------------------------------------

/*
 !!! process.env variables are specified in .env.production or .env.development
 !!!
*/

config.server = {};
config.server.api_url = process.env.VUE_APP_API_SERVER_URL || 'http://localhost:3000';
config.server.grafana_url= process.env.VUE_APP_GRAFANA_URL || 'http://localhost:3000/moda-grafana';
config.server.mcp_svc_url= process.env.VUE_APP_API_MCP_URL || 'http://localhost:3500';
config.server.status_api_url= process.env.VUE_APP_API_STATUS_URL || 'http://localhost:3600';
// production variables is defined as empty
config.server.status_ws_url= process.env.VUE_APP_WS_STATUS_URL != undefined ? process.env.VUE_APP_WS_STATUS_URL : 'http://localhost:3600';
config.server.jobs_svc_url= process.env.VUE_APP_API_JOBS_URL || 'http://localhost:3610';
config.server.fault_svc_api_url= process.env.VUE_APP_API_FAULTS_URL || 'http://localhost:3620';
config.server.fault_svc_ws_url= process.env.VUE_APP_WS_FAULTS_URL != undefined ? process.env.VUE_APP_WS_FAULTS_URL : 'http://localhost:3622';
config.server.inventory_svc_api_url= process.env.VUE_APP_API_INVENTORY_URL || 'http://localhost:3630';
config.server.summary_svc_api_url= process.env.VUE_APP_API_SUMMARY_URL || 'http://localhost:3640';
config.server.summary_svc_ws_url= process.env.VUE_APP_WS_SUMMARY_URL != undefined ? process.env.VUE_APP_WS_SUMMARY_URL : 'http://localhost:3642';
config.server.stats_api_url= process.env.VUE_APP_API_STATS_URL || 'http://localhost:3650';
config.server.extsys_api_url= process.env.VUE_APP_API_EXTSYS_URL || 'http://localhost:3660';

config.server.google_map_api_url= process.env.VUE_APP_GOOGLE_MAP_API_URL  || 'https://maps.googleapis.com/maps/api/geocode/json?';
config.server.google_map_api_key= process.env.VUE_APP_GOOGLE_MAP_API_KEY || 'AIzaSyCp7kVUFphxTZ63zC2HLS_oVqQqmO3Ek7g'; // restricted to maps only
/*
config.server.aws_s3_rawlogs_bucket= process.env.VUE_APP_AWS_S3_RAWLOGS_BUCKET || 'moda-ramesh1-rawlogs-dev';
config.server.aws_s3_key_id= process.env.VUE_APP_AWS_S3_KEY_ID || 'AKIAWJEN6SUZJ4FSM77G'; // restricted to s3 rawlogs dir only
config.server.aws_s3_key_secret= process.env.VUE_APP_AWS_S3_KEY_SECRET || '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'; // restricted to s3 rawlogs dir only
config.server.aws_s3_region= process.env.VUE_APP_AWS_S3_REGION || 'us-east-1'; // restricted to s3 rawlogs dir only
*/

//Microsoft login app
config.server.microsoft_client_id= process.env.VUE_APP_MICROSOFT_CLIENT_ID || '6475dc65-fb1e-4641-a555-c8a72783283b';

export default config;

// ============================================================================
