// eslint-disable-next-line no-unused-vars
var globalBreadcrumb = [];
var globalOrgsList = [];
var globalIncidentsAdvSearch;
var globalIncidentsAdvSearchString;
var globalAlarmsAdvSearch;
var globalAlarmsAdvSearchString;

const AuthService = { 

    get_usrdetails() {
        var user = JSON.parse(localStorage.getItem('user-details'))
        return user;
    },

    // default permission is to allow read-write unless explicitly disabled by read-only permission
    get_usr_auth_details(title) {
        let write_access= 'display-block'
        let user_details = JSON.parse(localStorage.getItem('user-details'))
        //console.log("get_usr_auth_details " + title + " " + JSON.stringify(user_details));
        if(user_details.pbac){
            title
            let pbac = user_details.pbac

            pbac.forEach(polObj => {
                let features = polObj.policy.features;
                features.forEach(feature => {

                    if ( feature == "all" || feature == title){
                        let permissions = polObj.policy.permissions;
                        permissions.forEach(permission => {

/*  TBD : apply to specific site(s)/group(s) - waiting for full RBAC support
                            if ( title == "site-management" ) {
                                if(permission == 'read-only') write_access ='display-none';
                            } else
*/
                            {
                                if(permission == 'read-only') write_access ='display-none';
                            }

                        });
                    }
                });
            });
        }

        return write_access
    },

    // default permission is to allow read-write unless explicitly disabled by read-only permission
    get_site_auth_details(title, siteId) {
        let write_access= 'display-block'
        siteId
        let user_details = JSON.parse(localStorage.getItem('user-details'))
        if(user_details.pbac){
/*
            let pbac = user_details.pbac;
            write_access= 'display-block' 
            for(let i=0; i< pbac.length;i++) {
                if (pbac[0].policyDetails.features[0] == title){
                    if(pbac[0].siteId ==siteId){
                        if(pbac[0].policyDetails.permissions[0] && pbac[0].policyDetails.permissions[0] == 'read-write'){
                        write_access ='display-block';
                        } else {
                            write_access ='display-none'; 
                        }
                    } else {
                        //console.log(write_access+'******3***'+siteId);
                    }
                }
            }
*/
        }
        console.log(title + '*****'+write_access)
        return write_access
    },
}
let auth = {AuthService:AuthService, globalBreadcrumb:globalBreadcrumb, globalOrgsList:globalOrgsList,
            globalIncidentsAdvSearch        : globalIncidentsAdvSearch,
            globalIncidentsAdvSearchString  : globalIncidentsAdvSearchString,
            globalAlarmsAdvSearch           : globalAlarmsAdvSearch,
            globalAlarmsAdvSearchString     : globalAlarmsAdvSearchString,
           }
export default auth
