<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-calendar-clock</v-icon> All Jobs
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
			</v-card-text>
			<v-container fluid class="pt-0">
				<div class="search-wrapper">
					<v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify"
						@input="utilsFilterChanged" />
				</div>
				<v-data-table :loading="isLoading" :headers="allJobs_table_vuetify_columns" :items="allJobs_table_rows"
					:options.sync="pagingOptions" :server-items-length="utilsTotalEntriesAllPages" show-select
					v-model="jobsSelected1" :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }" item-key="jobId"
					multi-sort no-data-text="No jobs found">

					<template v-slot:item.jobId="{ item }">
						<span> {{ item.jobId.split('_')[0].split("jobinf.")[1] }} </span>
					</template>

					<template v-slot:item.scheduledDateString="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" small
									:class="utilsJobScheduleTypeDisplayObject(item.jobScheduleType).class">
									{{ utilsJobScheduleTypeDisplayObject(item.jobScheduleType).icon }}
								</v-icon>
							</template>
							<span>{{ utilsJobScheduleTypeDisplayObject(item.jobScheduleType).text }}</span>
						</v-tooltip>
						{{ item.scheduledDateString }}
					</template>

					<template v-slot:item.jobStatus="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" :class="utilsJobStateDisplayObject(item.jobState).class"
									:color="utilsJobStateDisplayObject(item.jobState).color">
									{{ utilsJobStateDisplayObject(item.jobState).icon }}
								</v-icon>
							</template>
							<span>{{ utilsJobStateDisplayObject(item.jobState).text }}/
								{{ utilsJobStatusDisplayObject(item.jobStatus).text }}</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" :class="utilsJobStatusDisplayObject(item.jobStatus).class"
									:color="utilsJobStatusDisplayObject(item.jobStatus).color">
									{{ utilsJobStatusDisplayObject(item.jobStatus).icon }}
								</v-icon>
							</template>
							<span>{{ utilsJobStateDisplayObject(item.jobState).text }}/
								{{ utilsJobStatusDisplayObject(item.jobStatus).text }}</span>
						</v-tooltip>
					</template>

					<template v-slot:item.actions="{ item }">
						<nobr>
                        <v-icon @click="editAllJobsDetails(item, 1)" class="mr-2" color="primary" title="View">mdi-eye</v-icon>
						<v-icon @click="showMoreJobParams(item)" title="More" class="mr-2" color="teal">
								mdi-information-outline
						</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)" v-if="isCancellable(item)"
								@click="cancelAllJobsDetails(item.jobId, item.jobName)" class="mr-2" color="warning" title="Cancel">
								mdi-cancel</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)" @click="deleteAllJobsDetails(item.jobId, item.jobName)"
								color="error" title="Delete">mdi-delete</v-icon>
						</nobr>
					</template>
				</v-data-table>

				<div class="search-wrapper">
					<v-text-field v-model="filterValueJobRuns" label="Search table" prepend-inner-icon="mdi-magnify"
						@input="filterChangedJobRuns" />
				</div>
				<v-data-table :headers="allJobRuns_table_vuetify_columns" :items="allJobRuns_table_rows"
					:options.sync="jobRunsPagingOptions" :server-items-length="jobRunsTotalEntriesAllPages"
					:footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }" item-key="jobRunId" multi-sort
					:no-data-text="allJobs_table_rows.length ? (jobsSelected.length ? 'No job runs found for selected jobs' : 'Select one or more rows in above table') : 'No job runs found'">

					<template v-slot:item.jobId="{ item }">
						<span> {{ item.jobId.split('_')[0].split("jobinf.")[1] }} </span>
					</template>

					<template v-slot:item.jobRunId="{ item }">
						<span> {{ item.jobRunId.split('_')[0].split("jobrun.")[1] }} </span>
					</template>

					<template v-slot:item.jobRunStateStatus="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" :class="utilsJobRunStateDisplayObject(item.jobRunState).class"
									:color="utilsJobRunStateDisplayObject(item.jobRunState).color">
									{{ utilsJobRunStateDisplayObject(item.jobRunState).icon }}
								</v-icon>
							</template>
							<span>{{ utilsJobRunStateDisplayObject(item.jobRunState).text }}/
								{{ utilsJobRunStatusDisplayObject(item.jobRunStatus).text }}</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" :class="utilsJobRunStatusDisplayObject(item.jobRunStatus).class"
									:color="utilsJobRunStatusDisplayObject(item.jobRunStatus).color">
									{{ utilsJobRunStatusDisplayObject(item.jobRunStatus).icon }}
								</v-icon>
							</template>
							<span>{{ utilsJobRunStateDisplayObject(item.jobRunState).text }}/
								{{ utilsJobRunStatusDisplayObject(item.jobRunStatus).text }}</span>
						</v-tooltip>
					</template>

					<template v-slot:item.actions="{ item }">
						<v-icon @click="showMoreJobRunParams(item)" title="More" class="mr-2" color="teal">
							mdi-information-outline
						</v-icon>
					</template>

				</v-data-table>
			</v-container>
		</v-card>

		<!-- begin More Params dialogs -->
		<v-dialog v-model="open_more_job_params_dialog" max-width="500px">
			<v-card>
				<v-card-text>
					<v-row class="pt-6">
						<v-col cols="12" md="4"> Created By: </v-col>
						<v-col cols="12" md="8">
							{{ more_job_params_row.createdByEmail }}
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="4"> Job Name: </v-col>
						<v-col cols="12" md="8">
							{{ more_job_params_row.jobName }}
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="4"> Job Data: </v-col>
						<v-col cols="12" md="8">
							{{ more_job_params_row.jobData }}
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="4"> Job Message: </v-col>
						<v-col cols="12" md="8">
							{{ more_job_params_row.jobMessage }}
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" text @click="open_more_job_params_dialog = false">
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="open_more_job_run_params_dialog" max-width="500px">
			<v-card>
				<v-card-text>
					<v-row>
						<v-col cols="12" md="4"> Job Input Data: </v-col>
						<v-col cols="12" md="8">
							{{ more_job_run_params_row.jobInputData }}
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="4"> Job Result Data: </v-col>
						<v-col cols="12" md="8">
							{{ more_job_run_params_row.jobResultData }}
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" text @click="open_more_job_run_params_dialog = false">
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- end More Params dialogs -->

		<DeleteDialog v-if="deleteDialog" :currentObjectName="currName" @DeleteDialogCallback='deleteObjectChildEvent'>
		</DeleteDialog>

	</div>
</template>

<script>

import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import ModaConst from '@/services/ModaConstants';

export default {
    components: {
        Breadcrumb,
        DeleteDialog,
    },
    mixins: [
        Utils
    ],
    data(){
        var allJobs_table_vuetify_columns= [
           {
               text: 'Job Id',
               value: 'jobId',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Job Type',
               value: 'jobType',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Schedule At',
               value: 'scheduledDateString',
               sortable: true,
               filterable: true,
           },
           {
               text: 'State/Status',
               value: 'jobStatus',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Creation Time',
               value: 'createdDateString',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Actions',
               value: 'actions',
           },
/*
           {
               text: 'Created By',
               value: 'createdBy',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Job Name',
               value: 'jobName',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Job Data',
               value: 'jobData',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Job Message',
               value: 'jobMessage',
               sortable: true,
               filterable: true,
           },
*/
        ];

        var allJobRuns_table_vuetify_columns= [
           {
               text: 'Job Id',
               value: 'jobId',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Job Run Id',
               value: 'jobRunId',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Run at',
               value: 'jobRunAtDateString',
               sortable: true,
               filterable: true,
           },
           {
               text: 'State/Status',
               value: 'jobRunStateStatus',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Creation Time',
               value: 'createdDateString',
               sortable: true,
               filterable: true,
           },
/*
           {
               text: 'Job Input Data',
               value: 'jobInputData',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Job Result Data',
               value: 'jobResultData',
               sortable: true,
               filterable: true,
           },
*/
					{
						text: 'Actions',
						value: 'actions',
						width: 120
					},
        ];



        return {
            currOrgId: null,

            // Jobs data
            allJobs_table_vuetify_columns,
            allJobs_table_rows: [],
            jobsSelected1: [],
            jobsSelected: [],
            more_job_params_row: {},
            open_more_job_params_dialog: false,

            // Job Runs data
            allJobRuns_table_vuetify_columns,
            allJobRuns_table_rows: [],
            currPageJobRuns: 0,
            startPageJobRuns: 0,
            endPageJobRuns: 10,
            itemsPerPageJobRuns: 10,
            filterValueJobRuns : '',
            utilsAwaitingJobRunsSearchTimer: null,
            more_job_run_params_row: {},
            open_more_job_run_params_dialog: false,

            isLoading: false,

            userdetails:{},

            isShow: 'display-none',
            parent_pages:[ {name:"Home"}, {name: "Admin Tasks"}, {name:"All Jobs", active:true} ],
            apiStatus: null,

            pagingOptions:this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
            jobRunsPagingOptions:this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
            //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable
            jobRunsTotalEntriesAllPages:0,

            deleteDialog:false,
            currId:null,
            currName:null,

        };
    },
    watch: {
      pagingOptions: {
        handler () {
            this.getAllJobsList();
        },
        deep: false,
        immediate: false,
      },
      jobRunsPagingOptions: {
        handler () {
            this.getAllJobRunsList();
        },
        deep: false,
        immediate: false,
      },
      jobsSelected1: {
        handler () {
            this.jobInfoSelected(this.jobsSelected1);
        },
        deep: false,
        immediate: false,
      },
    },
    methods: {

        // Jobs

        isCancellable(row){
            // can't cancel IMMEDIATE or already EXECUTED jobs
            return row.jobScheduleType != this.UtilsModaConstants.ModaScheduleType.IMMEDIATE &&
                   row.jobStatus == this.UtilsModaConstants.JobStatus.PENDING;
        },

        showMoreJobParams(row) {
            //console.log("showMoreParams " + JSON.stringify(row))
            this.more_job_params_row = row;
            this.open_more_job_params_dialog = true;
        },

        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
        buildFilterableColumns(objs){
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                if(obj.jobScheduleType == 'SCHEDULED')  {
                    //console.log("buildFilterableColumns " + JSON.stringify(obj.jobScheduleInfo.SCHEDULED.runAt));
                    if(obj.jobScheduleInfo)
                    obj.scheduledDateString = this.utilsFormatDateTime(obj.jobScheduleInfo.SCHEDULED.runAt);
                }
                else if(obj.jobScheduleType == 'IMMEDIATE')  {
                    //console.log("buildFilterableColumns " + JSON.stringify(obj.jobScheduleInfo.IMMEDIATE.runAt));
                    if((obj.jobScheduleInfo) && (obj.jobScheduleInfo.IMMEDIATE))
                        obj.scheduledDateString = this.utilsFormatDateTime(obj.jobScheduleInfo.IMMEDIATE.runAt);
                }
                else if(obj.jobScheduleType == 'PERIODIC')  {
                    //console.log("buildFilterableColumns " + JSON.stringify(obj.jobScheduleInfo.PERIODIC));
                    if((obj.jobScheduleInfo) && (obj.jobScheduleInfo.PERIODIC))
                        obj.scheduledDateString = obj.jobScheduleInfo.PERIODIC.periodMins + " mins." +
                                " Start " + this.utilsFormatDateTime(obj.jobScheduleInfo.PERIODIC.startTime)+
                                ". End " + this.utilsFormatDateTime(obj.jobScheduleInfo.PERIODIC.endTime)
                }
                if ( obj.jobData ) obj.jobData = JSON.stringify(obj.jobData)
                if ( obj.jobScheduleInfo ) obj.jobScheduleInfo = JSON.stringify(obj.jobScheduleInfo)
                if ( obj.jobInputData ) obj.jobInputData = JSON.stringify(obj.jobInputData)
                if ( obj.jobResultData ) obj.jobResultData = JSON.stringify(obj.jobResultData)
                if ( obj._children ) this.buildFilterableColumns(obj._children);
            })
            //console.log("buildFilterableColumns" + JSON.stringify(objs));
        },
        navigateToUser(id){
            var readonly = 1
            this.$router.push( { name: "USERS_EDIT", params : { id: id, readonly: readonly }})
        },
        getAllJobsList(){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            if ( this.filterValueSearch ) query_params.searchString= "any:*:" + this.filterValueSearch
            //query_params.jobType = this.UtilsModaConstants.ModaJobType.DEVICE_CONFIG_JOB;
            query_params.pageNum = this.pagingOptions.page;
            query_params.pageSize = this.pagingOptions.itemsPerPage;
            var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, {scheduledDateString:'jobScheduleInfo.SCHEDULED.runAt'} )
            if ( sortCriteria ) query_params.sortCriteria = sortCriteria

            // ApiService.get("/mcpsvc/api/v1/allJob", (err, result) => 
            ApiService.get("/jobsvc/api/v1/jobs/page",
                (err, result) => {
                this.isLoading=false
                //console.log("getAllJobsList " + JSON.stringify(result.data));
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.allJobs_table_rows=result.data.data.docs
                    this.utilsTotalEntriesAllPages=result.data.data.totalEntries
                    //this.allJobRuns_table_rows=[];
/*
                    if(this.allJobs_table_rows.length > 0) {
                        if(!this.allJobs_table_rows[0].jobId) {
                            this.allJobs_table_rows.shift(); // TODO: temp fix for bad entry in db
                        }
                    }
*/
                    this.buildFilterableColumns(this.allJobs_table_rows);
                    //console.log("getAllJobsList " + JSON.stringify(this.allJobs_table_rows));
                    //console.log("getAllJobsList " + JSON.stringify(this.utilsTotalEntriesAllPages));
                }

            }, query_params, "JOBS_SERVICE_URL")
        },
        editAllJobsDetails(job, readonly){
            console.log(job.jobType);
            if ( job.jobType == ModaConst.ModaJobType.DEVICE_CONFIG_JOB )
                this.$router.push( { name: "DEVICE_CONFIG_JOBS_EDIT", params : { deviceConfigJobId: job.id, readonly : readonly }})
            else if ( job.jobType == ModaConst.ModaJobType.DEVICE_SW_UPDATE_JOB )
                this.$router.push( { name: "DEVICE_SWUPDATE_JOBS_EDIT", params : { deviceSWUpdateJobId: job.id, readonly: readonly}})
        },
        cancelAllJobsDetails(Id, name){
            if(confirm("Do you really want to cancel - '" + name + "' ?")) {
                this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

                //ApiService.put("/mcpsvc/api/v1/allJob/cancel/"+Id, 
                ApiService.put("/jobsvc/api/v1/jobs/cancel/"+Id,
                    {}, (err, result) => {
                    //console.log("**"+JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getAllJobsList();
                    }
                }, query_params, "JOBS_SERVICE_URL")
            }
        },
        deleteAllJobsDetails(Id, name){
            this.deleteDialog = true;
            this.currId = Id;
            this.currName = name;
        },
        deleteObjectChildEvent(cancelFlag, isBackupDel) 
        {
            console.log("** delete " + isBackupDel);
            this.deleteDialog = false;
            if(cancelFlag == true) return;

            //if(confirm("Do you really want to delete - '" + name + "' ?"))
                {
                this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
                query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

                //ApiService.delete("/mcpsvc/api/v1/allJob/"+this.currId, 
                ApiService.delete("/jobsvc/api/v1/jobs/"+this.currId, 
                    (err, result) => {
                    //console.log("**"+JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getAllJobsList();
                        this.getAllJobRunsList(); // selected job may have been deleted, so refresh job runs as well
                    }
                }, query_params, "JOBS_SERVICE_URL")
            }
        },
        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.utilsDataRefreshFunc = this.getAllJobsList;
            //this.getAllJobsList();
            this.getAllJobsList();
        },



        // Job Runs


        showMoreJobRunParams(row) {
            //console.log("showMoreParams " + JSON.stringify(row))
            this.more_job_run_params_row = row;
            this.open_more_job_run_params_dialog = true;
        },

        jobInfoSelected(rows){
            //console.log("Jobs selected " + JSON.stringify(rows));

            this.jobsSelected = rows.map((row) => {
                return row.jobId;
            })
            if ( this.jobsSelected.length ) this.getAllJobRunsList()
            else this.allJobRuns_table_rows = [];

        }, 

        getAllJobRunsList()  {
            if ( ! this.jobsSelected.length )  {
                this.jobRunsTotalEntriesAllPages=0
                this.allJobRuns_table_rows=[];
                return;
            }

            this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            if ( this.filterValueJobRuns ) query_params.searchString= "any:*:" + this.filterValueJobRuns
            query_params.pageNum = this.jobRunsPagingOptions.page;
            query_params.pageSize = this.jobRunsPagingOptions.itemsPerPage;
            var sortCriteria = this.utilsMakeSortCriteria(this.jobRunsPagingOptions, {deployedProfiles:'deployedProfiles.cpList', 
                                                                                      jobRunAtDateString: 'jobRunAt'} )
            if ( sortCriteria ) query_params.sortCriteria = sortCriteria
            var body = {
                jobIds : this.jobsSelected,
            };

            //ApiService.post("/mcpsvc/api/v1/allJob/detail", 
            ApiService.post("/jobsvc/api/v1/jobruns/job/page",
                body, (err, result) => {
                this.isLoading=false
                //console.log("getAllJobRunsList " + JSON.stringify(result.data));
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    var temp = result.data.data.docs
                    this.jobRunsTotalEntriesAllPages=result.data.data.totalEntries
                    //this.buildFilterableColumns(this.allJobRuns_table_rows);
                    temp.forEach((obj) => {
                        obj.jobRunAtDateString = this.utilsFormatDateTime(obj.jobRunAt);
                        obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                    }, this);
                    //console.log("getAllJobRunsList " + JSON.stringify(temp));
                    this.allJobRuns_table_rows=temp;
                }

            }, query_params, "JOBS_SERVICE_URL")
        },
        rangeChangedJobRuns (start, end) {
            //console.log("rangeChanged 1 start " + start + " end " + end);
            this.startPageJobRuns = start;
            this.endPageJobRuns = end;
        },
        pageChangedJobRuns (page) {
            //console.log("pageChanged 1 " + JSON.stringify(page));
            this.currPageJobRuns = page;
        },

        filterChangedJobRuns () {
            //console.log("filterChangedJobRuns 1 ", this.filterValueJobRuns);
            if (this.utilsAwaitingJobRunsSearchTimer) {
                clearTimeout(this.utilsAwaitingJobRunsSearchTimer);
                this.utilsAwaitingJobRunsSearchTimer = null;
            }
            this.utilsAwaitingJobRunsSearchTimer = setTimeout(() => {
                    //console.log("filterChangedJobRuns 2 ", this.filterValueJobRuns);
                    this.utilsAwaitingJobRunsSearchTimer = false;
                    if ( this.utilsJobRunDataRefreshFunc ) this.utilsJobRunDataRefreshFunc();
                }, 500); // 1 sec delay
        },

        async callRowsJobRuns (indexesToLoad) {
            indexesToLoad
        },

        async callChildrenJobRuns (parent) {
            parent
        },

        async callTempRowsJobRuns (filter, columns, start, end) {
            filter, columns, start, end
        },

    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.get_auth_details();
        this.utilsDataRefreshFunc = this.getAllJobsList;
        this.utilsJobRunDataRefreshFunc = this.getAllJobRunsList;
        //this.getAllJobsList();
        //this.getAllJobsStatusList();
    }
}
</script>
