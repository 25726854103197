<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-chart-timeline-variant</v-icon> Analytics Profiles
				<v-btn rounded outlined color="primary" absolute right :class="isShow"
					v-on:click="createAnalyticsConfigProfile()">
					<v-icon>mdi-plus</v-icon> New
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<div class="search-wrapper">
					<v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify" />
				</div>
			</v-card-text>
			<v-container fluid class="pt-0">
				<v-data-table :search="filterValueSearch" :loading="isLoading" :headers="analyticsConfigProfile_table_columns"
					:items="analyticsConfigProfile_table_rows" :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }"
					item-key="monTemplateId">
					<template v-slot:item.analyticsConfigProfileName="{ item }">
						<nobr>
							<span class="float-left">{{ item.analyticsConfigProfileName }}
								<v-chip v-if="utilsIsSystem(item)" x-small>
									SYS
								</v-chip>
							</span>
						</nobr>
					</template>

					<template v-slot:item.applications="{ item }">
						<span v-for="app in item.applications" :key="app.appId">
							<a class="link-text mr-2" :title="app.appName" v-on:click="navigateToApplication(app.appId)"> {{app.appName}}
							</a>
						</span>
					</template>

					<template v-slot:item.numDeviceConfigMaps="{ item }">
						<a v-if="item.numDeviceConfigMaps" href="javascript:;" :title="item.numDeviceConfigMaps"
							v-on:click="navigateToDeviceConfigMaps(item.analyticsConfigProfileName)">
							{{item.numDeviceConfigMaps}} </a>
					</template>

					<template v-slot:item.syslog="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" x-large
									:color="utilsSyslogStatusDisplayObject(item.syslog.receive).colorName">
									{{utilsSyslogStatusDisplayObject(item.syslog.receive).icon}}
								</v-icon>
							</template>
							<span>{{utilsSyslogStatusDisplayObject(item.syslog.receive).text}}</span>
						</v-tooltip>
						<nobr>Port : {{ item.syslog.listenPort }}</nobr>
					</template>

					<template v-slot:item.actions="{ item }">
						<v-row>
							<v-icon @click="viewAnalyticsConfigProfileDetails(item.analyticsConfigProfileId)"
								class="mr-2" title="View" color="primary">
								mdi-eye
							</v-icon>
							<v-icon v-if="item.orgId == currOrgId" :class="utilsGetDisplayClass(item)"
                                @click="editAnalyticsConfigProfileDetails(item.analyticsConfigProfileId, 0)"
                                color="warning" class="mr-2" title="Edit">mdi-pencil</v-icon>
							<v-icon :class="utilsGetDisplayClass(item)" v-if="item.numDeviceConfigMaps == 0"
								@click="deleteAnalyticsConfigProfileDetails(item.analyticsConfigProfileId, item.analyticsConfigProfileName)"
								class="record-delete" title="Delete" color="red">mdi-delete</v-icon>
						</v-row>
					</template>
				</v-data-table>
			</v-container>
		</v-card>

		<DeleteDialog v-if="deleteDialog" :currentObjectName="analyticsConfigProfileName" :isBackupBeforeDelete="true"
			@DeleteDialogCallback='deleteDeviceDetailsChildEvent'>
		</DeleteDialog>


	</div>
</template>

<script>

import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import AdsTreeTableUtils from '@/pages/moda/AdsTreeTableUtils.vue'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'

export default {
    components: {
        Breadcrumb,
		DeleteDialog,
    },
    mixins: [
        AdsTreeTableUtils,
        Utils,
    ],
    data(){
        var analyticsConfigProfile_table_columns_1= [
           {
               title: 'Name',
               property: 'analyticsConfigProfileName',
               direction: true,
               filterable: true,
           },
           {
               title: 'Belongs To',
               property: 'orgName',
               direction: true,
               filterable: true,
           },
           {
               title: 'Applications',
               property: 'applications',
               direction: true,
               filterable: true,
           },
           {
               title: 'Syslog',
               property: 'syslog',
               direction: true,
               filterable: true,
           },
           {
               title: 'Used by',
               property: 'numDeviceConfigMaps',
               direction: true,
               filterable: true,
           },
           {
               title: 'Current version',
               property: 'analyticsConfigProfileVersion',
               direction: true,
               filterable: true,
           },
           {
               title: 'Last changed',
               property: 'modifiedDateString',
               direction: true,
               filterable: true,
           },
           {
               title: 'Actions',
               property: 'actions',
           },
        ]
				var analyticsConfigProfile_table_columns= [
           {
               text: 'Name',
               value: 'analyticsConfigProfileName',
               direction: true,
               filterable: true,
           },
           {
						text: 'Belongs To',
						value: 'orgName',
               direction: true,
               filterable: true,
           },
           {
						text: 'Applications',
						value: 'applications',
               direction: true,
               filterable: true,
           },
           {
						text: 'Syslog',
						value: 'syslog',
               direction: true,
               filterable: true,
           },
           {
						text: 'Used by',
						value: 'numDeviceConfigMaps',
               direction: true,
               filterable: true,
           },
           {
						text: 'Current version',
						value: 'analyticsConfigProfileVersion',
               direction: true,
               filterable: true,
           },
           {
						text: 'Last changed',
						value: 'modifiedDateString',
               direction: true,
               filterable: true,
           },
           {
						text: 'Actions',
						value: 'actions',
           },
        ]
        return {
            currOrgId: null,

            analyticsConfigProfile_table_columns,
            analyticsConfigProfile_table_rows: [],

            isLoading: false,

            userdetails:{},
            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Profiles & Jobs"},
                           {name:'AnalyticsConfigProfiles', active:true} ],
            apiStatus: null,
			deleteDialog:false,
            analyticsConfigProfileId:null,
			analyticsConfigProfileName:null,
        };
    },
    methods: {
        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
        buildFilterableColumns(objs){
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                obj.modifiedDateString = this.utilsFormatDateTime(obj.modifiedDate);
                if ( obj._children ) this.buildFilterableColumns(obj._children);
            })
        },
        navigateToApplication(id){
            var readonly = 1
            this.$router.push({name: "APPLICATIONS_EDIT", params: { appId: id, readonly: readonly} } )
        },
        navigateToDeviceConfigMaps(name){
            this.$router.push( { name: "DEVICE_CONFIG_MAP_FILTER", params : { configProfileName: encodeURIComponent(name)}})
        },
        getAnalyticsConfigProfileList(){
            this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/analyticsConfigProfile", (err, result) => {
                this.isLoading=false
                //console.log("getAnalyticsConfigProfileList " + JSON.stringify(result.data));
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.analyticsConfigProfile_table_rows=result.data.data
                    this.buildFilterableColumns(this.analyticsConfigProfile_table_rows);
                }

            }, query_params)
        },
        viewAnalyticsConfigProfileDetails(id){
            this.$router.push( { name: "ANAL_CONFIG_PROFILES_VIEW", params : { analyticsConfigProfileId: id } })
        },
        editAnalyticsConfigProfileDetails(id, readonly){
            this.$router.push( { name: "ANAL_CONFIG_PROFILES_EDIT", params : { analyticsConfigProfileId: id, readonly: readonly } })
        },

        deleteAnalyticsConfigProfileDetails(Id, name){
			this.deleteDialog = true;
            this.analyticsConfigProfileId=Id;
			this.analyticsConfigProfileName=name;
		},

		deleteDeviceDetailsChildEvent(cancelFlag, isBackupDel) {
				this.deleteDialog = false;
				if(cancelFlag == true) return;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
                query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

				if(isBackupDel == true)
					query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE

                ApiService.delete("/api/v1/analyticsConfigProfile/"+this.analyticsConfigProfileId, (err, result) => {
                    //console.log("**"+JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getAnalyticsConfigProfileList();
                    }
                }, query_params)
        },

        createAnalyticsConfigProfile(){
            this.$router.push( { name: "ANAL_CONFIG_PROFILES_CREATE" } )
        },
        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.getAnalyticsConfigProfileList();
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.get_auth_details();
        this.getAnalyticsConfigProfileList();
    }
}
</script>
