// !!!!!
// !!!!!
// !!!!! EVERYTIME THIS FILE IS CHANGED, COPY IT OVER TO frontend/src/services      !!!!!!!!!!!!!!!!!!!
// !!!!! AND CHANGE LAST LINE export dedault ModaConst (ECMAScript)                 !!!!!!!!!!!!!!!!!!!
// !!!!!
//
// modaConstants.js - All constants and enums used in Moda Server
//====================================================================

var ModaConst = {};


//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~ api path prefixes of various containers           ~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

ModaConst.apiserver_api_prefix  = "/api/v1"
ModaConst.mcpserver_api_prefix  = "/mcpsvc/api/v1"
ModaConst.APIPrefix_SvcStatus   = "/svcStatus/api/v1"
ModaConst.APIPrefix_SvcSummary  = "/svcSummary/api/v1"
ModaConst.APIPrefix_SvcInventory= "/svcInventory/api/v1"
ModaConst.APIPrefix_SvcFault    = "/svcFault/api/v1"
ModaConst.APIPrefix_SvcML       = "/svcML/api/v1"
//ModaConst.APIPrefix_SvcJob      = "/svcJob/api/v1"    //TODO: Use instead of ModaConst.jobserver_api_prefix
ModaConst.jobserver_api_prefix  = "/jobsvc/api/v1"
ModaConst.APIPrefix_SvcStats    = "/svcStats/api/v1"
ModaConst.APIPrefix_SvcExtSysInteg  = "/svcExtSys/api/v1"

ModaConst.Service = {
    API             : "svcAPI",
    MCP             : "svcMCP",
    STATUS          : "svcStatus",
    SUMMARY         : "svcSummary",
    FAULT           : "svcFault",
    ML              : "svcML",
    JOB_SCHEDULER   : "svcJob",
    STATS           : "svcStats",
    EXT_SYS_INTEGRATION  : "svcExtSysIntegration"
};

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~ Well known fields to be shared by various modules ~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

ModaConst.kafka = {
    // !!!!! below removeStackPrefix() looks for dev_, test_, prod_ to skip <stack>_<dev env>_ !!!! 
    // !!!! Make sure these topic names don't use those patterns !!!!
    msg_names: [
        //"firewall", not ready yet. ES purge fails if index not present
        "speedtest",
        "mtr",
        "nmap",
        "ndpi_flows",
        "ndpi_dns",
        //"ndpi_devs", Not used anymore
        "wifi",
        "device_self_singleton",
        "device_self_disks",
        "mdev_logs",
    ],
    // !!!!! below removeStackPrefix() looks for dev_, test_, prod_ to skip <stack>_<dev env>_ !!!! 
    // !!!! Make sure these topic names don't use those patterns !!!!
    device2enricher_topic_suffix:    "_data_collection",
    enricher2flattener_topic_suffix:"_data_enriched",
    flattener2elastic_topic_suffix: "_data_flattened",
}
ModaConst.kafka.removeStackPrefix = function (topic) {
    // skip "<stackId>_<deploymentEnv>_"
    var pos = -1
    if ( (pos = topic.indexOf("_prod_")) >= 0 )
        topic = topic.substring(pos + 6)
    else if ( (pos = topic.indexOf("_test_")) >= 0 )
        topic = topic.substring(pos + 6)
    else if ( (pos = topic.indexOf("_dev_")) >= 0 )  // !!!! be careful it doesn't conflict with  device prefixes dev !!!!
        topic = topic.substring(pos + 5)
    return topic
}

ModaConst.KAFKA_CA_CERT = "../common/certs/kafka/moda_self100.crt"

ModaConst.ERROR_ID = "error.1001"

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~ ElaticSearch related ~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
ModaConst.ElasticSearch = {};
ModaConst.ElasticSearch.FindNET = {};

ModaConst.ElasticSearch.FindNET.APIPrefix_SvcFindNET = "/svcFindNET/api/v1"

ModaConst.ElasticSearch.FindNET.indexNET = "moda_network_element_type";


//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~ Enums ~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Use this in apiHelper (makeResponse etc)
ModaConst.Result = {
    SUCCESS         : "Success", 
    FAIL            : "Fail", 
    PARTIAL_SUCCESS : "PartialSuccess",
    SUCCESS_NUM         : 1, 
    FAIL_NUM            : 0, 
    PARTIAL_SUCCESS_NUM : 2
};


// Use when creating or updating an entity
ModaConst.Action = { 
    CREATE  : 0, 
    UPDATE  : 1 
};
ModaConst.Action.isValid = function (value) {
    return (value == ModaConst.Action.CREATE ||
            value == ModaConst.Action.UPDATE) ? true : false;
}


// Mongo DB collection names
ModaConst.MongoCollection = { 
    CUSTOM_SEQUENCE             : "customSequence",

    ORGS                        : "orgs", 
    ORG_GROUPS                  : "orgGroups",

    SITES                       : "sites",
    SITE_GROUPS                 : "siteGroups",

    DEVICE_MODELS               : "deviceModels",
    DEVICES                     : "devices",

    APPLICATIONS                : "applications",
    APPLICATION_PARAMS          : "applicationParams",

    ANALYTICS_CONFIG_PROFILES   : "analyticsConfigProfiles",
    SYSTEM_CONFIG_PROFILES      : "systemConfigProfiles",
    VPN_CONFIG_PROFILES         : "vpnConfigProfiles",
    //IDS_CONFIG_PROFILES         : "idsConfigProfiles",
    //FIREWALL_CONFIG_PROFILES    : "firewallConfigProfiles",
    ML_CONFIG_TEMPLATES          : "mlConfigTemplates",

    EFFECTIVE_CONFIGS           : "effectiveConfigs",

    DEVICE_CONFIG_MAP           : "deviceConfigMap",
    DEVICE_DEPLOYED_CONFIGS     : "deviceDeployedConfigs",

    DEVICE_LIVE_STATUS          : "deviceLiveStatus",

    DEVICE_SOFTWARE_IMAGES      : "deviceSoftwareImages",

    USERS                       : "users",
    POLICIES                    : "policies",
    PBAC                        : "pbac",

    SYSTEM_SETTINGS             : "systemSettings",
    MODA_DB_SCHEMA_INFO         : "modaDbSchemaInfo",

    AGENDA_JOBS                 : "modaAgendaJobs",
    MODA_JOB_INFO               : "modaJobInfo",
    MODA_JOB_RUN_STATUS         : "modaJobRunStatus",

    MCP_REQUESTS                : "mcpRequests"
};

// Fault service specific MongoDB Collections
ModaConst.FaultService = {};
ModaConst.FaultService.MongoCollection = {
    ALARMS                              : "alarms",
    INCIDENTS                           : "incidents",
    INCIDENT_HISTORY                    : "incidentHistory",
    RULES                               : "rules",
    RULE_RUNS                           : "ruleRuns",
    CORRELATION_RULES                   : "correlationRules"

};

// Inventory service specific MongoDB Collections
ModaConst.InventoryService = {};
ModaConst.InventoryService.MongoCollection = {
    MONITORING_TEMPLATES        : "monitoringTemplates",
    MONITORED_DEVICES           : "monitoredDevices"
};

// Summary service specific MongoDB Collections
ModaConst.SummaryService = {};
ModaConst.SummaryService.MongoCollection = {
    SUMMARY                         : "summary",

    APPLICATION_STATUS              : "applicationStatus",

    ORGS_FAULT_STATUS               : "orgsFaultStatus",
    SITES_FAULT_STATUS              : "sitesFaultStatus",
    MONITORED_DEVICES_FAULT_STATUS  : "monitoredDevicesFaultStatus",
    APPLICATIONS_FAULT_STATUS       : "applicationsFaultStatus",
    AGENT_DEVICES_FAULT_STATUS      : "agentDevicesFaultStatus"
};


// ExtSysIntegration service specific MongoDB Collections
ModaConst.ExtSysIntegrtionService = {};
ModaConst.ExtSysIntegrtionService.MongoCollection = {
    EXT_SYS_SYNC_STATUS             : "extSysSyncStatus",
    EXT_SYS_CONNECTWISE_PROFILE     : "extSysConnectWiseProfile",
    //EXT_SYS_SYNC_OBJECTS          : "extSysSyncObjects",
    EXT_SYS_ORGS                    : "extSysOrgs",
    EXT_SYS_SITES                   : "extSysSites",
    EXT_SYS_TICKETS                 : "extSysTickets",
    EXT_SYS_MESSAGES                : "extSysMessages", // table to de-dup messages received from external systems
};

ModaConst.ExtSysIntegrtionService.ExtSys = {
    CONNECT_WISE                    : "ConnectWise",
    SERVICE_NOW                     : "ServiceNow",
};

// Sets of Moda DB Schema
ModaConst.DbSchema = {
    COMMON      : "common", 
    INVENTORY   : "inventory", 
    /*[[JOB_SCHEDULER: "jobscheduler",]]*/ 
    FAULT       : "fault",
    SUMMARY     : "summary",
    STATS       : "stats",
    EXT_SYS_INTEGRATION : "extSysIntegration"
};


// !!!!!
// !!!!!
// !!!!! EVERYTIME THIS FILE IS CHANGED, COPY IT OVER TO frontend/src/services      !!!!!!!!!!!!!!!!!!!
// !!!!! AND CHANGE LAST LINE export dedault ModaConst (ECMAScript)                 !!!!!!!!!!!!!!!!!!!
// !!!!!

//--- DeleteMode: A flag to determine the PreReq & Dependent objects that will be deleted or 
//  Actually delete the Object and Dependent objects
// CHECK    = Checks object deletion pre-req and also lists the objets that will be deleted.
// REGULAR  = Delete - Hard or Soft (Obsolete/Not used now)
// FORCE    = Actually (Hard) delete the entries
//ModaConst.DeleteMode = {CHECK: "check", REGULAR: "regular", FORCE: "force"};        // Default = check
ModaConst.DeleteMode = {
    CHECK   : "check",      // default
    FORCE   : "force"
};

// Org search
ModaConst.OrgSearchDepth = {
    SHALLOW : 1, 
    DEEP    : 2
};
ModaConst.OrgSearchDepth.defaultValue = function () { return ModaConst.OrgSearchDepth.SHALLOW };

// Use in all entries (for entryType). SYSTEM entries can not be deleted
ModaConst.EntryType = { 
    SYSTEM  : "System", 
    USER    : "User" 
};
ModaConst.EntryType.defaultValue = function () { return ModaConst.EntryType.USER };
ModaConst.EntryType.isValid = function (value) {
    return (value == ModaConst.EntryType.SYSTEM ||
            value == ModaConst.EntryType.USER) ? true : false;
}


// Use in all entries (Old name = status)
ModaConst.AdminStatus = { 
    ENABLED : "Enabled", 
    DISABLED: "Disabled" 
};
ModaConst.AdminStatus.defaultValue = function () { return ModaConst.AdminStatus.ENABLED };
ModaConst.AdminStatus.isValid = function (value) {
    return (value == ModaConst.AdminStatus.ENABLED ||
            value == ModaConst.AdminStatus.DISABLED) ? true : false;
}


// Use in Device : PhysicalBox or a Virtual Machine
ModaConst.DeviceType = { 
    BARE_METAL  : "Bare Metal", 
    VM          : "VM" 
};
ModaConst.DeviceType.defaultValue = function () { return ModaConst.DeviceType.VM };
ModaConst.DeviceType.isValid = function (value) {
    return (value == ModaConst.DeviceType.BARE_METAL ||
            value == ModaConst.DeviceType.VM
        ) ? true : false;
}


// Use in DeviceModelType 
ModaConst.DeviceModelType = { 
    ANALYTICS_AGENT : "AnalyticsAgent", 
    NETWORK_DEVICE  : "NetworkDevice", 
    ROUTER          : "Router", 
    SWITCH          : "Switch", 
    SERVER          : "Server" 
};
ModaConst.DeviceModelType.defaultValue = function () { return ModaConst.DeviceModelType.ANALYTICS_AGENT };
ModaConst.DeviceModelType.isValid = function (value) {
    return (value == ModaConst.DeviceModelType.ANALYTICS_AGENT ||
            value == ModaConst.DeviceModelType.NETWORK_DEVICE ||
            value == ModaConst.DeviceModelType.ROUTER ||
            value == ModaConst.DeviceModelType.SWITCH ||
            value == ModaConst.DeviceModelType.SERVER) ? true : false;
}

ModaConst.MonitoredDevice = {};
ModaConst.MonitoredDevice.ProcessingAction = {
    ADDED           : "add",
    UPDATED         : "upd",
    UPDATED_STATUS  : "updsts",
};

//--- MonitoredDevice related ----------------------------------
ModaConst.InventoryService.MonitoredDevice = {};
ModaConst.InventoryService.MonitoredDevice.Type = {
    MODA_AGENT          : "ModaAgent",
    GATEWAY_ROUTER      : "GatewayRouter",
    NETWORK_DEVICE      : "NetworkDevice",
    LINUX_SERVER        : "LinuxServer",
    WINDOWS_SERVER      : "WindowsServer",
    WINDOWS_LAPTOP      : "WindowsLaptop",
    APPLE_LAPTOP        : "AppleLaptop",
    APPLE_MOBILE        : "AppleMobile",
    APPLE_TABLET        : "AppleTablet",
    ANDROID_MOBILE      : "AndroidMobile",
    ANDROID_TABLET      : "AndroidTablet",
    SMART_TV            : "SmartTV",
    SMART_HOME_DEVICE   : "SmartHomeDevice",
    MEDIA_STREAMING_DEVICE: "MediaStreamingDevice",
    POS_TERMINAL        : "PointOfSaleTerminal",
    GENERIC_DEVICE      : "GenericDevice"
};

ModaConst.InventoryService.MonitoredDevice.Model = {
    UNKNOWN : "Unknown"      // Default
}; 

ModaConst.InventoryService.MonitoredDevice.OS = {
    UBUNTU      : "Ubuntu",
    LINUX       : "Linux",
    WINDOWS     : "Windows",
    AAPL        : "iOS",
    CSCO        : "IOS",
    JNPR        : "JunOS",
    ANDROID     : "Android",
    OTHER       : "Other"
};

//TODO: Enhance this in the future, when Template import feature is supported
ModaConst.InventoryService.MonitoringTemplateType = {
    MODA_LOG_ANALYSYS   : "MT_LogAnalysis",     // Moda Template
    MODA_TELEMETRY      : "MT_Telemetry",       // Moda Template
    MODA_POLLING        : "MT_Polling",         // Moda Template

    EXT_SNMP            : "MT_ExtSNMP",         // Zabbix Template
    EXT_WMI             : "MT_ExtWMI",
    OTHER               : "MT_Other"
};

ModaConst.InventoryService.ZabbixMonitoringType = {
    PROXY       : "Proxy",
    MODA_AGENT  : "Agent"
};


//--- SNMP Setting constants ---------------------------------------------------
ModaConst.InventoryService.SNMP = {};
ModaConst.InventoryService.SNMP.ConnectionType = {
    DNS : 0,
    IP  : 1
};

ModaConst.InventoryService.SNMP.Version = {
    SNMPv1  : 1,
    SNMPv2  : 2,
    SNMPv3  : 3
};

//--- SNMPv3 related ---
ModaConst.InventoryService.SNMP.SecurityLevel = {
    NO_AUTH_NO_PRIVACY  : 0,    // default
    AUTH_ONLY           : 1,
    AUTH_AND_PRIVACY    : 2
};

// For SecurityLevel == AUTH_ONLY or AUTH_AND_PRIVACY 
ModaConst.InventoryService.SNMP.AuthProtocol = {
    MD5     : 0,        // default
    SHA1    : 1,
    SHA224  : 2,
    SHA256  : 3,
    SHA384  : 4, 
    SHA512  : 5
};

// For SecurityLevel == AUTH_AND_PRIVACY 
ModaConst.InventoryService.SNMP.PrivProtocol = {
    DES     : 0,        //default
    AES128  : 1,
    AES192  : 2,
    AES256  : 3,
    AES192C : 4,
    AES256C : 5
};

ModaConst.InventoryService.NetworkMap = {};
ModaConst.InventoryService.NetworkMap.NodeCategory = {
    PARENT_ORG      : "POrg",
    CURRENT_ORG     : "COrg",
    ORG             : "Org",
    SITE            : "Site",
    AGENT_DEVICE    : "Agt",
    VLAN            : "VLAN",
    MONITORED_DEVICE : "Dev"
};
ModaConst.InventoryService.NetworkMap.NodeState = {
	OPEN : 'open',
	CLOSE : 'close'
};

ModaConst.InventoryService.NetworkMap.NodeStateFont = {
	OPEN : { multi: "html", size: 12, bold: { color: 'gray' }, ital: {color: 'gray'}, boldital: {color: "darkgray"} },	
	CLOSE : { multi: "html", size: 12, bold: { color: "brown", size:16 }, ital: {color: 'cyan'} }	// bold = "#0077aa"
};


//----------------------------------------------------------------------------


// !!!!!
// !!!!!
// !!!!! EVERYTIME THIS FILE IS CHANGED, COPY IT OVER TO frontend/src/services      !!!!!!!!!!!!!!!!!!!
// !!!!! AND CHANGE LAST LINE export dedault ModaConst (ECMAScript)                 !!!!!!!!!!!!!!!!!!!
// !!!!!


// Use in DeviceModel
ModaConst.DeviceOS = { 
    OPEN_WRT    : "OpenWRT", 
    OTHER       : "Other", 
    CISCO_IOS   : "CiscoIOS", 
    UBUNTU      : "Ubuntu" 
};
ModaConst.DeviceOS.defaultValue = function () { return ModaConst.DeviceOS.OPEN_WRT };
ModaConst.DeviceOS.isValid = function (value) {
    return (value == ModaConst.DeviceOS.OPEN_WRT ||
            value == ModaConst.DeviceOS.OTHER ||
            value == ModaConst.DeviceOS.CISCO_IOS ||
            value == ModaConst.DeviceOS.UBUNTU) ? true : false;
}


// Use in Applications - strings should match those pre-populated in md-mongo/mongo-index.js
ModaConst.AppType = { 
    APP_MONITORING      : "ApplicationMonitoring", 
    SITE_MONITORING     : "SiteMonitoring", 
    DEVICE_MONITORING   : "DeviceMonitoring" 
};
ModaConst.AppType.defaultValue = function () { return ModaConst.AppType.APP_MONITORING };
ModaConst.AppType.isValid = function (value) {
    return (value == ModaConst.AppType.APP_MONITORING ||
            value == ModaConst.AppType.SITE_MONITORING ||
            value == ModaConst.AppType.DEVICE_MONITORING) ? true : false;
}

// Use in Applications - strings should match those pre-populated in md-mongo/mongo-index.js
ModaConst.AppSubType = { 
    SPEEDTEST   : "speedtest", 
    NDPI        : "ndpi", 
    NMAP        : "nmap", 
    WIFI        : "wifi", 
    DHCP        : "dhcp", 
    DNS         : "dns", 
    SELF        : "self" 
};
ModaConst.AppSubType.defaultValue = function () { return ModaConst.AppSubType.SELF };
ModaConst.AppSubType.isValid = function (value) {
    return (value == ModaConst.AppSubType.SPEEDTEST ||
            value == ModaConst.AppSubType.NDPI ||
            value == ModaConst.AppSubType.NMAP ||
            value == ModaConst.AppSubType.WIFI ||
            value == ModaConst.AppSubType.DHCP ||
            value == ModaConst.AppSubType.DNS ||
            value == ModaConst.AppSubType.SELF) ? true : false;
}

// Use in Applications - strings should match those pre-populated in md-mongo/mongo-index.js
ModaConst.AppProtocol = { 
    TCP_UDP : "tcp_udp", 
    TCP     : "tcp", 
    UDP     : "udp", 
    ICMP    : "icmp" 
};
ModaConst.AppProtocol.defaultValue = function () { return ModaConst.AppSubType.SELF };
ModaConst.AppProtocol.isValid = function (value) {
    return (value == ModaConst.AppSubType.TCP_UDP ||
            value == ModaConst.AppSubType.TCP ||
            value == ModaConst.AppSubType.UDP ||
            value == ModaConst.AppSubType.ICMP) ? true : false;
}



// Use in User
ModaConst.UserType = { 
    OPERATOR            : "Operator", 
    ADMIN               : "Administrator", 
    SUPPORT_ENGINEER    : "SupportEngineer", 
    EXECUTIVE           : "Executive" 
};
ModaConst.UserType.defaultValue = function () { return ModaConst.UserType.OPERATOR };
ModaConst.UserType.isValid = function (value) {
    return (value == ModaConst.UserType.OPERATOR ||
            value == ModaConst.UserType.ADMIN ||
            value == ModaConst.UserType.SUPPORT_ENGINEER ||
            value == ModaConst.UserType.EXECUTIVE) ? true : false;
}


// Use in User
ModaConst.AuthProvider = {
    LOCAL       : "Local",
    MICROSOFT   : "Microsoft",     // MicrosoftEntra (== Office365 / AzureAD)
};
ModaConst.AuthProvider.defaultValue = function () { return ModaConst.AuthProvider.LOCAL };
ModaConst.AuthProvider.isValid = function (value) {
    return (value == ModaConst.AuthProvider.LOCAL ||
            value == ModaConst.AuthProvider.MICROSOFT) ? true : false;
}

// Use in Device: 
//  PREPROVISIONED  = Initial state of Device object on creation 
//  PROVISIONED     = a valid license is attached & Device is ready for Registration
//TODO: Combine PreProv & Prov as we no longer license devices
//  REGISTERED      = Device is in Registered
ModaConst.DeviceOperStatus = { 
    PREPROVISIONED  : "PreProvisioned", 
    PROVISIONED     : "Provisioned", 
    REGISTERED      : "Registered" 
};
ModaConst.DeviceOperStatus.defaultValue = function () { return ModaConst.DeviceOperStatus.PROVISIONED };
ModaConst.DeviceOperStatus.isValid = function (value) {
    return (value == ModaConst.DeviceOperStatus.PREPROVISIONED ||
            value == ModaConst.DeviceOperStatus.PROVISIONED ||    
            value == ModaConst.DeviceOperStatus.REGISTERED) ? true : false;
}


// Use in DeviceLiveStatus
ModaConst.DeviceLiveStatus = { 
    ONLINE  : "Online", 
    OFFLINE : "Offline", 
    FAULTY  : "Faulty", 
    UNKNOWN : "Unknown" 
};
ModaConst.DeviceLiveStatus.defaultValue = function () { return ModaConst.DeviceLiveStatus.UNKNOWN };
ModaConst.DeviceLiveStatus.isValid = function (value) {
    return (value == ModaConst.DeviceLiveStatus.ONLINE ||
            value == ModaConst.DeviceLiveStatus.OFFLINE ||
            value == ModaConst.DeviceLiveStatus.FAULTY ||
            value == ModaConst.DeviceLiveStatus.UNKNOWN) ? true : false;
}

// Use in DeviceLiveStatus  //TODO: Change to enum/code/color/icon
ModaConst.DeviceLiveStatusSummary = { 
    HEALTHY     : "green", 
    UNHEALTHY   : "orange", 
    CRITICAL    : "red", 
    UNKNOWN     : "gray" 
};
ModaConst.DeviceLiveStatusSummary.defaultValue = function () { return ModaConst.DeviceLiveStatusSummary.UNKNOWN };
ModaConst.DeviceLiveStatusSummary.isValid = function (value) {
    return (value == ModaConst.DeviceLiveStatusSummary.HEALTHY ||
            value == ModaConst.DeviceLiveStatusSummary.UNHEALTHY ||
            value == ModaConst.DeviceLiveStatusSummary.CRITICAL ||
            value == ModaConst.DeviceLiveStatusSummary.UNKNOWN) ? true : false;
}

// !!!!!
// !!!!!
// !!!!! EVERYTIME THIS FILE IS CHANGED, COPY IT OVER TO frontend/src/services      !!!!!!!!!!!!!!!!!!!
// !!!!! AND CHANGE LAST LINE export dedault ModaConst (ECMAScript)                 !!!!!!!!!!!!!!!!!!!
// !!!!!


// Use in Site (HeadOffice / BranchOffice)
ModaConst.SiteType = { 
    HQ      : "Headquarter", 
    BO      : "BranchOffice", 
    OTHER   : "Other" 
};
ModaConst.SiteType.defaultValue = function () { return ModaConst.SiteType.BO };
ModaConst.SiteType.isValid = function (value) {
    return (value == ModaConst.SiteType.HQ ||
            value == ModaConst.SiteType.BO ||
            value == ModaConst.SiteType.OTHER) ? true : false;
}
ModaConst.SiteName = {}
ModaConst.SiteName.defaultValue = function (name, siteType) {return  name + "-" + siteType}

// Use in ConfigProfile
ModaConst.ConfigProfileType = { 
    ANALYTICS   : "Analytics", 
    SYSTEM      : "System", 
    VPN         : "VPN"
    // IDS_IPS  : "IDS_IPS", 
    // FW       : "FireWall",
}; 
//N/A: ModaConst.ConfigProfileType.defaultValue = function () { return ModaConst.ConfigProfileType.ANALYTICS };
ModaConst.ConfigProfileType.isValid = function (value) {
    return (value == ModaConst.ConfigProfileType.ANALYTICS ||
            value == ModaConst.ConfigProfileType.SYSTEM ||
            value == ModaConst.ConfigProfileType.VPN) ? true : false;
}


// Use in DeviceSoftwareImage
ModaConst.SoftwareImageType = { 
    DEVICE  : "Device", 
    AGENT   : "Agent" 
};
ModaConst.SoftwareImageType.defaultValue = function () { return ModaConst.SoftwareImageType.DEVICE };
ModaConst.SoftwareImageType.isValid = function (value) {
    return (value == ModaConst.SoftwareImageType.DEVICE ||
            value == ModaConst.SoftwareImageType.AGENT) ? true : false;
}


// Use in AnalyticsConfigProfile
ModaConst.ConfigOperStatus = { 
    FREE        : "Free", 
    ASSIGNED    : "Assigned" 
};
ModaConst.ConfigOperStatus.defaultValue = function () { return ModaConst.ConfigOperStatus.FREE };
ModaConst.ConfigOperStatus.isValid = function (value) {
    return (value == ModaConst.ConfigOperStatus.FREE ||
            value == ModaConst.ConfigOperStatus.ASSIGNED) ? true : false;
}

// !!!!!
// !!!!!
// !!!!! EVERYTIME THIS FILE IS CHANGED, COPY IT OVER TO frontend/src/services      !!!!!!!!!!!!!!!!!!!
// !!!!! AND CHANGE LAST LINE export dedault ModaConst (ECMAScript)                 !!!!!!!!!!!!!!!!!!!
// !!!!!


// Use in ConfigMap. NOTE: For now, allow only "DEVICE" type
ModaConst.MapTargetType = { 
    DEVICE      : "Device" , 
    SITE        : "Site", 
    SITE_GROUP  : "SiteGroup", 
    ORG         : "Organization", 
    ORG_GROUP   : "OrganizationGroup" 
};
ModaConst.MapTargetType.defaultValue = function () { return ModaConst.MapTargetType.DEVICE };
ModaConst.MapTargetType.isValid = function (value) {
    return (value == ModaConst.MapTargetType.DEVICE ||
            value == ModaConst.MapTargetType.SITE || 
            value == ModaConst.MapTargetType.SITE_GROUP || 
            value == ModaConst.MapTargetType.ORG || 
            value == ModaConst.MapTargetType.ORG_GROUP) ? true : false;
}

// Use in KafkaConnector
ModaConst.KafkaCluster = { 
    STATS   : "Stats", 
    COMM    : "Comm", 
    MCP     : "MCP" 
};
ModaConst.KafkaCluster.defaultValue = function () { return ModaConst.KafkaCluster.COMM };
ModaConst.KafkaCluster.isValid = function (value) {
    return (value == ModaConst.KafkaCluster.STATS ||
            value == ModaConst.KafkaCluster.COMM ||
            value == ModaConst.KafkaCluster.MCP) ? true : false;
}

// Use in inter service communication (ISC)/notification 
//Option 1: Generic event approach irrespective of entity
ModaConst.ISCTopic = {
    ORG_NOTIFICATION            : "org_notification", 
    DEVICE_NOTIFICATION         : "device_notification", 
    USER_NOTIFICATION           : "user_notification", 
    CONFIGURATION_NOTIFICATION  : "configuration_notification", 
    JOB_NOTIFICATION            : "job_notification"
};

ModaConst.ISCEvent = {
    CREATED     : "Created", 
    UPDATED     : "Updated", 
    DELETED     : "Deleted", 
    CANCELLED   : "Cancelled", 
    ATTACHED    : "Attached", 
    UNATTACHED  : "Un-Attached"
};
// SubEvent[] can be used along with any Event 'Updated' to specify the what field is updated
ModaConst.ISCSubEvent = {
    NAME_UPDATED    : "Name_Updated", 
    Email_Updated   : "Email_Updated"
};

ModaConst.ISCEntity = {
    ORG         : "Org", 
    DEVICE      : "Device", 
    PROFILE     : "Profile", 
    USER        : "User", 
    JOB         : "Job"
};
// SubEntity[] can be used along with Entity For ex If Entity is "Profile", subEntity could be ['Analytics','System'] for Event "ATTACHED"
ModaConst.ISCSubEntity = {
    ORGGRP      : "Org_Grp", 
    Site        : "Site", 
    ANALYTICS   : "Analytics", 
    SYSTEM      : "SYSTEM", 
    CONFIG      : "CONFIG", 
    ML          : "ML" 
};
        

//Option 2 entity/event specific approach
// ModaConst.ISCDeviceEvent = {DEVICE_PROVISIONED: "DEVICE_PROVISIONED", DEVICE_UPDATED: "DEVICE_UPDATED", DEVICE_DELETED:"DEVICE_DELETED",
//                             DEVICE_REGISTERED:"DEVICE_REGISTERED", LICENSE_EXPIRED:"LICENSE_EXPIRED"}

// ModaConst.ISCUserEvent = {USER_CREATED: "USER_CREATED", USER_UPDATED: "USER_UPDATED", USER_DELETED:"USER_DELETED"}  
// ModaConst.ISCConfigEvent = {PROFILE_CREATED: "PROFILE_CREATED", PROFILE_UPDATED: "PROFILE_UPDATED", PROFILE_DELETED:"PROFILE_DELETED", PROFILE_ATTACHED:"PROFILE_ATTACHED"}    
// ModaConst.ISCConfigEvent = {PROFILE_CREATED: "PROFILE_CREATED", PROFILE_UPDATED: "PROFILE_UPDATED", PROFILE_DELETED:"PROFILE_DELETED", PROFILE_ATTACHED:"PROFILE_ATTACHED"}



// Use in mcp server

ModaConst.MCPReqType = { 
    SETCONFIGREQ    : "SetConfigRequest", 
    GETCONFIGREQ    : "GetConfigRequest", 
    RESETCONFIGREQ  : "ResetConfigRequest", 
    BOOTNOTIFICATION: "BootNotification"
};
ModaConst.MCPRespType = { 
    SETCONFIGRESP   : "SetConfigResponse", 
    GETCONFIGRESP   : "GetConfigResponse", 
    RESETCONFIGRESP :"ResetConfigResponse"
};
//ModaConst.ModaJobRunStatus = { POSTEDTOAGT: "PostToDeviceSuccess", POSTTOAGTFAILED: "PostToDeviceFailed", AGTACKEDSUCCUSS: "DeviceAcknowledgedSuccess", AGTACKEDFAIL: "DeviceAcknowledgedFailed" };
//OLD: ModaConst.ModaJobRunStatus = { RUNNING: "Running", SUCCESS: "Success", FAILED: "Failed"};


ModaConst.ModaJobType = { 
    DEVICE_LIVESTATUS_JOB           : "DeviceLiveStatusJob",
    DEVICE_CONFIG_JOB               : "DeviceConfigJob",
    DEVICE_SW_UPDATE_JOB            : "DeviceSWUpdateJob",
    TELEMETRY_ML_TRAINING_JOB       : "TelemetryMLTrainingJob", 
    TELEMETRY_ML_PREDICTION_JOB     : "TelemetryMLPredictionJob",
    LOG_ANALYSIS_ML_TRAINING_JOB    : "LogAnalysisMLTrainingJob", 
    LOG_ANALYSIS_ML_PREDICTION_JOB  : "LogAnalysisMLPredictionJob",
    BACKUP_AND_PURGE_JOB            : "BackupPurgeJob"
};

ModaConst.ModaJobType.isValid = function (value) {
    return (value == ModaConst.ModaJobType.DEVICE_CONFIG_JOB ||
            value == ModaConst.ModaJobType.DEVICE_LIVESTATUS_JOB ||
            value == ModaConst.ModaJobType.DEVICE_SW_UPDATE_JOB ||
            value == ModaConst.ModaJobType.TELEMETRY_ML_TRAINING_JOB ||
            value == ModaConst.ModaJobType.TELEMETRY_ML_PREDICTION_JOB ||
            value == ModaConst.ModaJobType.LOG_ANALYSIS_ML_TRAINING_JOB ||
            value == ModaConst.ModaJobType.LOG_ANALYSIS_ML_PREDICTION_JOB || 
            value == ModaConst.ModaJobType.BACKUP_AND_PURGE_JOB
        ) ? true : false;
}

ModaConst.BackupDataType = {
    NONE    : "none",       // No backup (ie only purge)
    ALL     : "all",        // All data (ie all collections)
    FAULT   : "fault"       // Only fault data: incidents/alarms/incidentHistory
}


ModaConst.ModaJobType.isMLJobType = function (value) {
    return (value == ModaConst.ModaJobType.TELEMETRY_ML_TRAINING_JOB ||
            value == ModaConst.ModaJobType.TELEMETRY_ML_PREDICTION_JOB ||
            value == ModaConst.ModaJobType.LOG_ANALYSIS_ML_TRAINING_JOB ||
            value == ModaConst.ModaJobType.LOG_ANALYSIS_ML_PREDICTION_JOB) ? true : false;
}


//@@@ OBSOLETE: ModaConst.DeviceConfigRunStatus = {INPROGRESS:"In-Progress", PENDING: "Pending", SKIPPED: "Skipped", SUCCESS: "Success", FAILED:"Failed", NONE: "None"};

//OLD: ModaConst.ModaJobStatus = { PENDING: "Pending", EXECUTED: "Executed", FAILED:"Failed", CANCELLED: "Cancelled" };
//CHECK: DUPLICATE?: ModaConst.ModaJobRunStatus = { RUNNING: "Running", SUCCESS: "Success", FAILED: "Failed"};

ModaConst.ModaScheduleType = { 
    IMMEDIATE   : "IMMEDIATE", 
    SCHEDULED   : "SCHEDULED", 
    PERIODIC    : "PERIODIC", 
    CRON        : "CRON" 
}
ModaConst.ModaJobOffsetDefault = 2000;

//@@@ OBSOLETE: ModaConst.MCPAction = {SCHEDULE: 0, GET: 1, CANCEL: 2, DELETE: 3};


// used in jobscheduler to update site/app anomalyStatus
ModaConst.ModaAnomalyStatus = { 
    NORMAL      : "NORMAL", 
    ABNORMAL    : "ABNORMAL" 
}       //OBSOLETED. Use ML.AnomalyStatus instead

/*~~~ ### Obsolete ### ~~~
ModaConst.ML = {};

ModaConst.ML.Category = {
    TELEMETRY       : "Telemetry",
    LOG_ANALYSIS    : "LogAnalysis"
};

// Obsoleted by ModaConst.FaultService.MLAnomalyStatus
ModaConst.ML.AnomalyStatus = { 
    CLEAR   : "Clear", 
    ANOMALY : "Anomaly",
    UNKNOWN : "Unknown" };
ModaConst.ML.AnomalyStatus.defaultValue = function () { return ModaConst.ML.AnomalyStatus.CLEAR };
ModaConst.ML.AnomalyStatus.isValid = function (value) {
    return (value == ModaConst.ML.AnomalyStatus.CLEAR ||
            value == ModaConst.ML.AnomalyStatus.ANOMALY ||
            value == ModaConst.ML.AnomalyStatus.UNKNOWN) ? true : false;
}

ModaConst.ML.LogAnomalyType = {
    SEQUENCE    : "Sequence",
    PARAM       : "Param"
}

ModaConst.ML.ConfigTemplateCustom = "Custom";
~~~~~~~~~~~~~~~~~~*/
ModaConst.FaultService.MLAnomalyStatus = { 
    CLEAR   : "Clear", 
    ANOMALY : "Anomaly",
    UNKNOWN : "Unknown" };
ModaConst.FaultService.MLAnomalyStatus.defaultValue = function () { return ModaConst.FaultService.MLAnomalyStatus.CLEAR };
ModaConst.FaultService.MLAnomalyStatus.isValid = function (value) {
    return (value == ModaConst.FaultService.MLAnomalyStatus.CLEAR ||
            value == ModaConst.FaultService.MLAnomalyStatus.ANOMALY ||
            value == ModaConst.FaultService.MLAnomalyStatus.UNKNOWN) ? true : false;
}


// JobType to Task (URL) mapping !!!! Can we build this from config ??? !!!!
ModaConst.TaskUrls = {};
ModaConst.TaskUrls.init = function (config) {
    ModaConst.TaskUrls[ModaConst.ModaJobType.DEVICE_LIVESTATUS_JOB] =
        { taskUrl: "http://" + config.liveStatusServer.host + ":" + config.liveStatusServer.port + ModaConst.APIPrefix_SvcStatus + "/task/status/check" }

    ModaConst.TaskUrls[ModaConst.ModaJobType.BACKUP_AND_PURGE_JOB] =
        { taskUrl: "http://" + config.apiserver.host + ":" + config.apiserver.port + ModaConst.apiserver_api_prefix + "/dataManagement/task/bakPurge" }

    ModaConst.TaskUrls[ModaConst.ModaJobType.DEVICE_CONFIG_JOB] =
        { taskUrl: "http://" + config.apiserver.host + ":" + config.apiserver.port + ModaConst.apiserver_api_prefix + "/task/config/deploy" }

    ModaConst.TaskUrls[ModaConst.ModaJobType.DEVICE_SW_UPDATE_JOB] =
        { taskUrl: "http://" + config.apiserver.host + ":" + config.apiserver.port + ModaConst.apiserver_api_prefix + "/task/software/install" }

    ModaConst.TaskUrls[ModaConst.ModaJobType.TELEMETRY_ML_TRAINING_JOB] =
        { taskUrl: "http://" + config.telemetryTrainer.host + ":" + config.telemetryTrainer.port + ModaConst.APIPrefix_SvcML + "/task/telemetry/ml/training" }
    ModaConst.TaskUrls[ModaConst.ModaJobType.TELEMETRY_ML_PREDICTION_JOB] =
        { taskUrl: "http://" + config.telemetryPredictor.host + ":" + config.telemetryPredictor.port + ModaConst.APIPrefix_SvcML + "/task/telemetry/ml/prediction" }

    ModaConst.TaskUrls[ModaConst.ModaJobType.LOG_ANALYSIS_ML_TRAINING_JOB] =
        { taskUrl: "http://" + config.ML.logAnalysisTrainer.host + ":" + config.ML.logAnalysisTrainer.port + ModaConst.APIPrefix_SvcML + "/task/loganalysis/ml/training" }
    ModaConst.TaskUrls[ModaConst.ModaJobType.LOG_ANALYSIS_ML_PREDICTION_JOB] =
        { taskUrl: "http://" + config.ML.logAnalysisPredictor.host + ":" + config.ML.logAnalysisPredictor.port + ModaConst.APIPrefix_SvcML + "/task/loganalysis/ml/prediction" }
}


// !!!!!
// !!!!!
// !!!!! EVERYTIME THIS FILE IS CHANGED, COPY IT OVER TO frontend/src/services      !!!!!!!!!!!!!!!!!!!
// !!!!! AND CHANGE LAST LINE export dedault ModaConst (ECMAScript)                 !!!!!!!!!!!!!!!!!!!
// !!!!!


//~~~ Use in MODA Inter-Service-Communication. Refer to the design note for details ~~~~~~~~~~~~~~~

//--- For Event communication ---
ModaConst.MISC = {};
ModaConst.MISC.EventTopic = {
    API_SVC_EVENT : "misc_apisvc_event",        // API Server 
    MCP_SVC_EVENT : "misc_mcpsvc_event",        // MCP Server
    ML_SVC_EVENT  : "misc_mlsvc_event",         // ML Processing (Training/Prediction) Server
    EXT_SVC_EVENT : "misc_extsvc_event",
    ANA_SVC_EVENT : "misc_anasvc_event"         // Analytics (micro-service)
};

//--- For Master data updates ---
ModaConst.MISC.UpdateTopic = {
    ORG_UPDATE      : "misc_org_update",        // Org, OrgGroup, Site, SiteGroup updates
    DEVICE_UPDATE   : "misc_device_update",     // (Agent) Device related updates
    MONITORED_DEVICE_UPDATE   : "misc_monitored_device_update",     // Bulk addition/updation on NMAP discovery
    CONFIG_UPDATE   : "misc_config_update",     // Config Profile & Deployed Config, SoftwareImage? related updates
    USER_UPDATE     : "misc_user_update",       // User, PBAC related updates
    JOB_UPDATE      : "misc_job_update",        //CHECK: Need this ?
    FAULT_UPDATE    : "misc_fault_update",      // Fault, Incident
    OTHER_UPDATE    : "misc_other_update"       // All other updates
};

//--- Info Notifications ---
ModaConst.MISC.NotificationTopic = {
    FAULT_NOTIFICATION  : "misc_fault_notification",
    REPORT_NOTIFICATION : "misc_report_notification",
    AUDIT_NOTIFICATION  : "misc_audit_notification"
};

ModaConst.MISC.TopicNames = [
    ModaConst.MISC.EventTopic.API_SVC_EVENT,                // "misc_apisvc_event",
    ModaConst.MISC.EventTopic.MCP_SVC_EVENT,                // "misc_mcpsvc_event",
    ModaConst.MISC.EventTopic.ML_SVC_EVENT,                // "misc_mlpsvc_event",
    ModaConst.MISC.EventTopic.ANA_SVC_EVENT,                // "misc_anasvc_event",

    ModaConst.MISC.UpdateTopic.ORG_UPDATE,                  // "misc_org_update",
    ModaConst.MISC.UpdateTopic.DEVICE_UPDATE,               // "misc_device_update",
    ModaConst.MISC.UpdateTopic.CONFIG_UPDATE,               // "misc_config_update",
    ModaConst.MISC.UpdateTopic.USER_UPDATE,                 // "misc_user_update",
    ModaConst.MISC.UpdateTopic.JOB_UPDATE,                  // "misc_job_update",
    ModaConst.MISC.UpdateTopic.FAULT_UPDATE,                // "misc_fault_update",

    ModaConst.MISC.UpdateTopic.OTHER_UPDATE,                // "misc_other_update",

    ModaConst.MISC.NotificationTopic.FAULT_NOTIFICATION,    // "misc_fault_notification",
    ModaConst.MISC.NotificationTopic.REPORT_NOTIFICATION,   // "misc_report_notification",
    ModaConst.MISC.NotificationTopic.AUDIT_NOTIFICATION     // "misc_audit_notification"
];

ModaConst.MISC.Service = { 
    API_SERVICE         : "api", 
    MCP_SERVICE         : "mcp", 
    USER_SERVICE        : "usr", 
    LIVE_STATUS_SERVICE : "sta",
    ML_SERVICE          : "mlx",
    JOB_SCHEDULER		: "job",
    FAULT_SERVICE       : "flt",
    SUMMARY_SERVICE     : "sum",
    INVENTORY_SERVICE   : "inv",
    EXT_SERVICE         : "ext",
    OTHER_SERVICE       : "oth" };

ModaConst.MISC.ObjectType = { 
    ORG                 : "org", 
    ORG_GROUP           : "ogrp", 
    SITE                : "sit", 
    SITE_GROUP          : "sgrp", 
    DEVICE              : "dev",            // AgentDevice
    MONITORED_DEVICE    : "mdev",
    NETWORK_ELEMENT     : "nelm",
    USER                : "usr",
    ALARM               : "alm",
    INCIDENT            : "inc",
    RULE                : "rul",
    CONFIG_PROFILE      : "cfg",
    // TBD: these 4 are not objectTypes. Revisit
    EXT_NOTIF           : "ext",
    GENERIC_MCP         : "mcp",
    GENERIC_ACTION      : "act",
    GENERIC_NOTIF       : "not" };

ModaConst.MISC.UpdateType = { 
    ADD                     : "add",    // [CREATE]
    DELETE                  : "del",    // [DELETE]
    BULK_DELETE             : "bulk_del",// [BULK_DELETE]
    MODIFY                  : "mod",    // [UPDATE]
    COMMAND                 : "cmd",    // [COMMAND]
    OTHER                   : "oth" };  //CHECK: Not really used ?

// ActionType (== EventType) This is applicable when ObjectType == GENERIC_MCP || GENERIC_ACTION
//TODO: Add more Action types in future
ModaConst.MISC.ActionType = {
    REGISTER_DEVICE_AGENT   : "regdev",
    UNREGISTER_DEVICE_AGENT : "unregi",         //CHECK: Need this ?
    DEPLOY_CONFIG           : "depcfg",
    GET_CONFIG              : "getcfg",
    INSTALL_SOFTWARE        : "instsw",
    REBOOT_DEVICE_AGENT     : "reboot",
    RESET_DEVICE_AGENT      : "resetx",
    ML_TRAIN                : "mltrai",
    ML_PREDICT              : "mlpred",
    UNDETERMINED            : "undetr",
    DISCOVER_NE             : "discov",
    RSSH_START              : "rsshstart",
    RSSH_STOP               : "rsshstop",
    OTHER                   : "otherx" };       //CHECK: Not really used ?

//TODO: Add more Notification types in future
// This is applicable when ObjectType == GENERIC_NOTIF
ModaConst.MISC.NotificationType = {
    FAULT_NOTIF             : "faultx",
    DISCOVERY               : "discovery",
    //BACKUP_NOTIF            : "backup",
    DATA_PURGE_NOTIF        : "dpurge",
    JOB_PURGE_NOTIF         : "jpurge",
    //AUDIT_LOG_NOTIF         : "audlog",
    //REPORT_GENERATION_NOTIF : "rptgen",
    STATUS_NOTIF            : "status",
    OTHER                   : "otherx" };    //CHECK: Not really used ?
    

ModaConst.MISC.DataType = { 
    STRING              : "str", 
    NUMBER              : "num", 
    ISO_DATE            : "idt", 
    EPOCH_DATE          : "edt", 
    OBJECT              : "obj" };


//=== JobState & JobStatus ======================================================
//Note: 'State' is different from 'Status'
//  -> State    : Current State of the Job or JobRun
//  -> Status   : Result of (all) the Job executions or individual JobRuns
// Refer: to JobScheduler design note (.pptx) for detailed description
//===============================================================================
ModaConst.JobState = {
    CREATED             : "Created",		// [START]  Job is created but, it is not started executing (even one Run)
    IN_PROGRESS         : "InProgress",		// [WIP]    Job is Running (atleast 1 Run for the multiple runs)
    ENDED               : "Ended",		    // [END]    One or all Job runs are completed
    CANCELLED           : "Cancelled"       // [END]    Job is Ended manually (without normally 'Ended')
};
//Note: there is no direct 'Success' or 'Failure' for Jobs - it is sort of applicable for JobRuns.
// JobStatus for a Job is calculated by the Status of all the JobRuns corresponding to the Job
// JobStatus will be UNKNOWN intially and updated to SUCCESS/PART_SUCCESS/FAIL when JobState is in [END] state
ModaConst.JobStatus = {
    UNKNOWN             : "Unknown",        // [START]  Initial status
    SUCCESS             : "Success",		// [END]    All Job runs are 'Success'ful
    PARTIAL_SUCCESS     : "PartialSuccess",	// [END]    Atleast one of the Job Runs is 'Success'ful
    FAIL                : "Fail"		    // [END]    All Job runs 'Fail'ed
};

ModaConst.JobRunState = {
    TRIGGERED           : "Triggered",		// [START]  At JobScheduler
    INITIATED           : "Initiated",		// [WIP]    At Task (TaskUrl is called)
    RUNNING             : "Running",		// [WIP]    At Job is in Execution (Ex: by DeviceAgent/APIServer/ML-Train/ML-Predict/...)
    COMPLETED           : "Completed",		// [END]    JobRun is completed
    NO_RESPONSE         : "NoResponse",		// [END]    No response for a long time / Stuck / Hung - Timedout
    ABORTED             : "Aborted"		    // [END]    Aborted at initial stages
};

ModaConst.JobRunStatus = {
    UNKNOWN             : "Unknown",        // [START]  Initial JobRun status
    SUCCESS             : "Success",		// [END]    JobRun is 'Success'ful
    FAIL                : "Fail"		    // [END]    JobRun 'Fail'ed
};
//===============================================================================


//--- for use in MCP messages ---
ModaConst.MCP = {};

ModaConst.MCP.ORG_TOPIC_PREFIX = 'mcp_s2a_';
ModaConst.MCP.A2S_TOPIC = "mcp_a2s_all";

ModaConst.MCP.Service = {
    MCP_SERVICE         : "mcp", 
    DEVICE_AGENT        : "agt" };

ModaConst.MCP.ObjectType = {
    MCP                 : "mcp"};
    
ModaConst.MCP.MessageType = {
    COMMAND             : "cmd",
    NOTIFICATION        : "ntf"};

ModaConst.MCP.MessageSubType = {
    REQUEST             : "req",
    RESPONSE            : "rsp"};

ModaConst.MCP.Command = { 
    SET_CONFIG          : "SetConfig", 
    GET_CONFIG          : "GetConfig", 
    INSTALL_SOFTWARE    : "InstallSoftware",
    REBOOT_DEVICE       : "RebootDevice",
    RESET_DEVICE        : "ResetDevice",
    RSSH_START          : "RsshStart",
    RSSH_STOP           : "RsshStop",
};

ModaConst.MCP.Command2Action = function(cmd) {
    if (cmd === ModaConst.MCP.Command.SET_CONFIG) return ModaConst.MISC.ActionType.DEPLOY_CONFIG;
    if (cmd === ModaConst.MCP.Command.GET_CONFIG) return ModaConst.MISC.ActionType.GET_CONFIG;
    if (cmd === ModaConst.MCP.Command.INSTALL_SOFTWARE) return ModaConst.MISC.ActionType.INSTALL_SOFTWARE;
    if (cmd === ModaConst.MCP.Command.REBOOT_DEVICE) return ModaConst.MISC.ActionType.REBOOT_DEVICE_AGENT;
    if (cmd === ModaConst.MCP.Command.RESET_DEVICE) return ModaConst.MISC.ActionType.RESET_DEVICE_AGENT;

    return ModaConst.MISC.ActionType.UNDETERMINED;
}

ModaConst.MCP.Notification = { 
    BOOT_NOTIFICATION   : "BootNotif"//, 
    //HEARTBEAT_NOTIFICATION : "HeartbeatNotif",    // Moved to REST Call
    //SYSTEM_INFO_NOTIFICATION :   "SysInfoNotif",  // Merged with HeartbeatNotif
    //UNREGISTER : "UnregisterNotification",        // Moved to REST Call
};

// Use in MCPRequest
ModaConst.McpRequestStatus = { 
    POSTED      : "posted", 
    DELIVERED   : "delivered", 
    RESPONDED   : "responded", 
    COMPLETED   : "completed" 
};
ModaConst.McpRequestStatus.defaultValue = function () { return ModaConst.McpRequestStatus.POSTED };
ModaConst.McpRequestStatus.isValid = function (value) {
    return (value == ModaConst.McpRequestStatus.POSTED ||
            value == ModaConst.McpRequestStatus.DELIVERED ||
            value == ModaConst.McpRequestStatus.RESPONDED ||
            value == ModaConst.McpRequestStatus.COMPLETED) ? true : false;
}


//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~ Fault management related (Alarms & Incidents/Tickets)
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

//--- Fault - common ---
ModaConst.FaultService.ComponentIdType = {      // Source of Alarm/Incident 
    DEVICE_AGENT    : "citDevAgt", 
    MONITORED_DEVICE: "citMonDev",
    SITE            : "citSite",
    APP             : "citApp",
    MULTI           : "citMulti",               // Applicable to Incidents only
    OTHER           : "citOther" };
ModaConst.FaultService.ComponentIdType.defaultValue = function () { return ModaConst.FaultService.ComponentIdType.OTHER };
ModaConst.FaultService.ComponentIdType.isValid = function (value) {
    return (value == ModaConst.FaultService.ComponentIdType.DEVICE_AGENT ||
            value == ModaConst.FaultService.ComponentIdType.MONITORED_DEVICE ||
            value == ModaConst.FaultService.ComponentIdType.SITE ||
            value == ModaConst.FaultService.ComponentIdType.APP ||
            value == ModaConst.FaultService.ComponentIdType.MULTI ||
            value == ModaConst.FaultService.ComponentIdType.OTHER) ? true : false;
}


//--- Alarms ---
ModaConst.Alarm = {};

ModaConst.Alarm.Source = { 
    DEVICE_AGENT    : "DevAgt", 
    MODA_CMS        : "CMS",            // Incl CMS.ML component that generates ML Anamoly faults
    EXT_MON_SYS     : "ExtMonSys",      // External Monitoring System (Zabbix)
    OTHER           : "Other" };
ModaConst.Alarm.Source.defaultValue = function () { return ModaConst.Alarm.Source.OTHER };
ModaConst.Alarm.Source.isValid = function (value) {
    return (value == ModaConst.Alarm.Source.DEVICE_AGENT ||
            value == ModaConst.Alarm.Source.MODA_CMS ||
            value == ModaConst.Alarm.Source.EXT_MON_SYS ||
            value == ModaConst.Alarm.Source.OTHER) ? true : false;
}


ModaConst.Alarm.Ext = {};
ModaConst.Alarm.Ext.Zabbix = {};
ModaConst.Alarm.Ext.Zabbix.Source = {
    TRIGGER             : "TRIGGER",
    DISCOVERY_HOST      : "DISCOVERY_HOST",
    DISCOVERY_SERVICE   : "DISCOVERY_SERVICE"
}

/*~~~ ### Obsolete ### ~~~
ModaConst.Alarm.Category = { 
    ML_ANOMALY          : "MLAnomaly", 
    THRESHOLD_CROSSING  : "ThresholdCross",
    CUSTOMER            : "Customer", 
    SYSTEM              : "System", 
    DEVICE_STATUS       : "DeviceStatus",
    DEVICE_PROBLEM      : "DeviceProblem",
    OTHER               : "Other" };
ModaConst.Alarm.Category.defaultValue = function () { return ModaConst.Alarm.Category.OTHER };
ModaConst.Alarm.Category.isValid = function (value) {
    return (value == ModaConst.Alarm.Category.ML_ANOMALY ||
            value == ModaConst.Alarm.Category.THRESHOLD_CROSSING ||
            value == ModaConst.Alarm.Category.CUSTOMER ||
            value == ModaConst.Alarm.Category.SYSTEM ||
            value == ModaConst.Alarm.Category.DEVICE_STATUS ||
            value == ModaConst.Alarm.Category.DEVICE_PROBLEM ||
            value == ModaConst.Alarm.Category.OTHER) ? true : false;
}
~~~~~~~~~~~~~~~~~~~~*/

//Old: ModaConst.Alarm.CategoryType = {
ModaConst.FaultService.CategoryType = {
    ML_ANOMALY_TELEMETRY    : "MLAnomalyTelemetry",     // Alarms based on Moda ML module 
    ML_ANOMALY_LOG_ANALYSIS : "MLAnomalyLogAnalysis",   // Alarms based on Moda ML module
    DEVICE_AVAILABILITY     : "DeviceAvailability",     // Alarms based on Zabbix DISCOVERY
    DEVICE_PROBLEM          : "DeviceProblem",          // Alarms based on Zabbix TRIGGER
    //THRESHOLD_CROSSING    : "ThresholdCross",         // For OutOfDiskSPace type of Alerts from ModaDevAgent
    CUSTOMER                : "Customer",               // Alarm manually added by Admin
    SYSTEM                  : "System",                 // CMS System generated Alarm
    EXTERNAL_SYS            : "ExternalSystem",         // External System
    OTHER                   : "Other"                   // All other types
}

//Old: ModaConst.Alarm.CategorySubType = {

ModaConst.FaultService.CategorySubType = {};
ModaConst.FaultService.CategorySubType.Telemetry = {
    ML_TELEMETRY_ANOMALY        : "TelemetryAnomaly"
};

ModaConst.FaultService.CategorySubType.LogAnalysis = {
    ML_LOG_ANALYSIS_SEQUENCE    : "Sequence",
    ML_LOG_ANALYSIS_PARAMS      : "Params"
};

ModaConst.FaultService.CategorySubType.DeviceAvailability = {
    ICMP_PING                   : "ICMPPing"
};

ModaConst.FaultService.CategorySubType.DeviceProblem = {
    ICMP_RESPONSE_TIME          : "ICMPResponseTime",
    HIGH_CPU_USAGE              : "HighCPUUsage",
    HIGH_DISK_USAGE             : "RunningOutOfDisk",
    HIGH_MEMORY_USAGE           : "HighMemoryUsage",

    HIGH_TEMPERATURE            : "HighTemperature",
    VOLTAGE_OUT_OF_RANGE        : "VoltageOutOfRange",
    POWER_SUPPLY_FAILURE        : "PowerSupplyFailure",
    FAN_FAILURE                 : "FanFailure",

    GENERIC                     : "Generic"
};

//ModaConst.FaultService.CategorySubType.ThresholdCross = {
//  OUT_OF_RESOURCE             : "OutOfResource"
//};

ModaConst.FaultService.CategorySubType.Manual = {
    MANUAL                      : "Manual"
};

ModaConst.FaultService.CategorySubType.Internal = {
    INTERNAL                    : "Internal"
};

ModaConst.FaultService.CategorySubType.ExternalSystem = {
    CONNECTWISE                 : "ConnectWise"
};

ModaConst.FaultService.CategorySubType.Other = {
    OTHER                       : "Other"
};


//--- Severity (applicable to both Alarms and Incidents) ---
//~~~ ModaConst.Alarm.Severity = {...}  // *** Obsolete *** by ModaConst.FaultService.Severity

ModaConst.FaultService.Severity = {
    CRITICAL    : "Critical",
    SEVERE      : "Severe",
    MAJOR       : "Major",
    MINOR       : "Minor",
    INFO        : "Info",
    NOT_SET     : "NotSet"
}

ModaConst.FaultService.Severity.defaultValue = function () { return ModaConst.FaultService.Severity.INFO };
ModaConst.FaultService.Severity.isValid = function (value) {
    return (value == ModaConst.FaultService.Severity.CRITICAL ||
            value == ModaConst.FaultService.Severity.SEVERE ||
            value == ModaConst.FaultService.Severity.MAJOR ||
            value == ModaConst.FaultService.Severity.MINOR ||
            value == ModaConst.FaultService.Severity.INFO || 
            value == ModaConst.FaultService.Severity.NOT_SET) ? true : false;
}

//--- SeverityMapping : Enum to other ---
ModaConst.FaultService.SeverityMapEnum = {
    "Critical"  : {code: "S1", number: 1, color: "Red"},
    "Severe"    : {code: "S2", number: 2, color: "LightRed"},
    "Major"     : {code: "S3", number: 3, color: "Orange"},
    "Minor"     : {code: "S4", number: 4, color: "Yellow"},
    "Info"      : {code: "S5", number: 5, color: "LightBlue"},
    "NotSet"    : {code: "S6", number: 6, color: "Gray"}
}

ModaConst.FaultService.SeverityMapCode = {
    "S1"        : {enum: "Critical",    number: 1, color: "Red"},
    "S2"        : {enum: "Severe",      number: 2, color: "LightRed"},
    "S3"        : {enum: "Major",       number: 3, color: "Orange"},
    "S4"        : {enum: "Minor",       number: 4, color: "Yellow"},
    "S5"        : {enum: "Info",        number: 5, color: "LightBlue"},
    "S6"        : {enum: "NotSet",      number: 6, color: "Gray"}
}

// Our own severity levels - reverse order to Zabbix
ModaConst.FaultService.SeverityMapNumber = {
    1        : {enum: "Critical",    code: "S1", color: "Red"},
    2        : {enum: "Severe",      code: "S2", color: "LightRed"},
    3        : {enum: "Major",       code: "S3", color: "Orange"},
    4        : {enum: "Minor",       code: "S4", color: "Yellow"},
    5        : {enum: "Info",        code: "S5", color: "LightBlue"},
    6        : {enum: "NotSet",      code: "S6", color: "Gray"}
}

//--- Update the severity based on the device weightage
// Input sNum is ModaConst.FaultService.ZabbixSeverityMapNum and output mSev is ModaConst.FaultService.SeverityMapNumber
ModaConst.FaultService.severityConversion = function(sNum, devImp) {
    if (devImp == null || devImp == "") {
        // This problem could occur for older MonDev entries. 
        // Match the default devImp value with that in the 'config.js'
        console.log("ERROR: devImp is undefined. Assuming default (5)");
        devImp = 5;
    } 

	//DEBUG: console.log("sevConv: sNum = " + sNum + "(" + ModaConst.FaultService.SeverityMapNum[sNum].enum + "), devImp = " + devImp);
	let mSev = Math.round(sNum*(devImp/10.0));
	
    //DEBUG: mSevEnum = ModaConst.FaultService.SeverityMapNumber[mSev].enum;
    //DEBUG: console.log("\t\t\t\tmSev(MODA) = " + mSev + ", mSev.enum = " + mSevEnum);

	return mSev;
}

ModaConst.FaultService.devImpSevCutOff = function(devImp) {
    let dropSev = 4;    // == config.faultServer.alertDropThresholdSeverity.other
    
    let mappedSev = ModaConst.FaultService.severityConversion(dropSev, devImp);

    return mappedSev ? ModaConst.FaultService.SeverityMapNumber[mappedSev].enum : "All";
}

//--- Zabbix Severity Mapping 
// Ref: https://www.zabbix.com/documentation/current/en/manual/config/triggers/severity
ModaConst.FaultService.ZabbixSeverity = {
    DISASTER        : "Disaster",       // 5-Red        (Disaster. Financial losses, etc)
    HIGH            : "High",           // 4-LightRed   (Something important has happened)
    AVERAGE         : "Average",        // 3-Orange     (Average problem)
    WARNING         : "Warning",        // 2-Yellow     (Be warned)
    INFORMATIONAL   : "Infoformation",  // 1-LightBlue  (For information purposes)
    NOT_CLASSIFIED  : "Not classified"  // 0-Gray       (Unknown severity)
}

// Zabbix's severity levels - reverse order to ours
ModaConst.FaultService.ZabbixSeverityMapNum = {
    5 : {enum: "Disaster"},
    4 : {enum: "High"},
    3 : {enum: "Average"},
    2 : {enum: "Warning"},
    1 : {enum: "Information"},
    0 : {enum: "Not classified"}
}

/*~~~ Severity Zabbix - *** Obsolete *** - Use the deviceImportance weighted conversion method ~~~
ModaConst.FaultService.severityConversionZabbix2Moda = function(zsev, devImp) {
    if (zsev == 5 || zsev == ModaConst.FaultService.ZabbixSeverity.DISASTER) { return ModaConst.FaultService.Severity.CRITICAL;
    } else if (zsev == 4 || zsev == ModaConst.FaultService.ZabbixSeverity.HIGH) { return ModaConst.FaultService.Severity.SEVERE;
    } else if (zsev == 3 || zsev == ModaConst.FaultService.ZabbixSeverity.AVERAGE) { return ModaConst.FaultService.Severity.MAJOR;
    } else if (zsev == 2 || zsev == ModaConst.FaultService.ZabbixSeverity.WARNING) { return ModaConst.FaultService.Severity.MINOR;
    } else if (zsev == 1 || zsev == ModaConst.FaultService.ZabbixSeverity.INFORMATIONAL) { return ModaConst.FaultService.Severity.INFO;
    } else if (zsev == 0 || zsev == ModaConst.FaultService.ZabbixSeverity.NOT_CLASSIFIED) { return ModaConst.FaultService.Severity.NOT_SET;
    } else { console.log("ERROR: Invalid ext-severity : " + zsev); return ModaConst.FaultService.Severity.NOT_SET; }
}
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
ModaConst.FaultService.severityConversionZabbix2Moda = function(zsNum, devImp) {
    if (!devImp) {
        // This problem could occur for older MonDev entries. 
        // Match the default devImp value with that in the 'config.js'
        console.log("ERROR: devImp is undefined. Assuming default (5)");
        devImp = 5;
    } 

	//DEBUG: console.log("sev-Zabbix-to-Moda: zsNum = " + zsNum + "(" + ModaConst.FaultService.ZabbixSeverityMapNum[zsNum].enum + "), devImp = " + devImp);
	let mSev = Math.round(6 - Math.round(zsNum*(devImp/7.0)));
    if (mSev < 1) mSev = 1;     // Cap mSev at 1
	
    //DEBUG: mSevEnum = ModaConst.FaultService.SeverityMapNumber[mSev].enum;
    //DEBUG: console.log("\t\t\t\tmSev = " + mSev + ", mSev.enum = " + mSevEnum);

	return mSev;
}



//--- Priority (Usually/For now) applicable to Incidents only --- 
ModaConst.FaultService.Priority = {
    HIGH    : "High",
    MEDIUM  : "Medium",
    LOW     : "Low"
};
ModaConst.FaultService.PriorityEnum = {
    "High"      : {enum: "High",    code: "P1", number: 1},
    "Medium"    : {enum: "Medium",  code: "P2", number: 2},
    "Low"       : {enum: "Low",     code: "P3", number: 3}
};

ModaConst.FaultService.Priority.defaultValue = function () { return ModaConst.FaultService.Priority.LOW };
ModaConst.FaultService.Priority.isValid = function (value) {
    return (value == ModaConst.FaultService.Priority.HIGH ||
            value == ModaConst.FaultService.Priority.MEDIUM ||
            value == ModaConst.FaultService.Priority.LOW) ? true : false;
}



//--- SeverityMapping : Enum to other ---
ModaConst.FaultService.SeverityMapEnum = {
    "Critical"  : {code: "S1", number: 1, color: "Red"},
    "Severe"    : {code: "S2", number: 2, color: "LightRed"},
    "Major"     : {code: "S3", number: 3, color: "Orange"},
    "Minor"     : {code: "S4", number: 4, color: "Yellow"},
    "Info"      : {code: "S5", number: 5, color: "LightBlue"},
    "NotSet"    : {code: "S6", number: 6, color: "Gray"}
}



// - Aggregated fault status of objects - orgs, sites, devs, apps
ModaConst.FaultService.FaultStatus = {
    FAULTY      : ModaConst.DeviceLiveStatus.FAULTY,
    GOOD        : "Good",
    UNKNOWN     : ModaConst.DeviceLiveStatus.UNKNOWN,
}
ModaConst.FaultService.FaultStatus.defaultValue = function () { return ModaConst.FaultService.FaultStatus.GOOD };
ModaConst.FaultService.FaultStatus.isValid = function (value) {
    return (value == ModaConst.FaultService.FaultStatus.FAULTY ||
            value == ModaConst.FaultService.FaultStatus.GOOD ||
            value == ModaConst.FaultService.FaultStatus.UNKNOWN ) ? true : false;
}



//=== Alarm related ===

ModaConst.Alarm.Type = { 
    RAISE   : "Raise", 
    CLEAR   : "Clear", 
    UNKNOWN : "Unknown" };
ModaConst.Alarm.Type.defaultValue = function () { return ModaConst.Alarm.Type.UNKNOWN };
ModaConst.Alarm.Type.isValid = function (value) {
    return (value == ModaConst.Alarm.Type.RAISE ||
            value == ModaConst.Alarm.Type.CLEAR ||
            value == ModaConst.Alarm.Type.UNKNOWN) ? true : false;
}


// Do we need this ???
ModaConst.Alarm.Status = { 
    NEW             : "New", 
    ACKNOWLEDGED    : "Ack" // ie no need to consider it for further processing ?
};
ModaConst.Alarm.Status.defaultValue = function () { return ModaConst.Alarm.Status.NEW };
ModaConst.Alarm.Status.isValid = function (value) {
    return (value == ModaConst.Alarm.Status.NEW || 
            value == ModaConst.Alarm.Status.ACKNOWLEDGED ) ? true : false;
}




//=== Incident related ===
ModaConst.Incident = {};

//~~~ ModaConst.Incident.Severity = { // *** Obsolete *** by ModaConst.FaultService.Severity ~~~

//TODO: Obsolete this (ModaConst.Incident.Category) and use ModaConst.FaultService.CategoryType
/*~~~
ModaConst.Incident.Category = { 
    ML_ANOMALY          : "iMLAnomaly",
    THRESHOLD_CROSSING  : "iThresholdCross", 
    CUSTOMER            : "iCustomer", 
    SYSTEM              : "iSystem", 
    DEVICE_STATUS       : "iDeviceStatus",
    DEVICE_PROBLEM      : "iDeviceProblem",
    OTHER               : "iOther" };
ModaConst.Incident.Category.defaultValue = function () { return ModaConst.Incident.Category.OTHER };
ModaConst.Incident.Category.isValid = function (value) {
    return (value == ModaConst.Incident.Category.ML_ANOMALY ||
            value == ModaConst.Incident.Category.THRESHOLD_CROSSING ||
            value == ModaConst.Incident.Category.CUSTOMER ||
            value == ModaConst.Incident.Category.SYSTEM ||
            value == ModaConst.Incident.Category.DEVICE_STATUS ||
            value == ModaConst.Incident.Category.DEVICE_PROBLEM ||
            value == ModaConst.Incident.Category.OTHER) ? true : false;
}
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

ModaConst.Incident.State = { 
    NEW             : "New",                // [PENDING] state
    OPEN            : "Open",               // [PENDING]
    IN_PROGRESS     : "InProgress",         // [PENDING]
    HOLD            : "Hold",               // [PENDING]
    REOPEN          : "Reopen",             // [PENDING]
    RESOLVED        : "Resolved",           // [DONE]
    UN_REPRODUCEABLE: "UnReproduceable",    // [DONE]
    CLOSED          : "Closed",             // [CLOSED]
    DUPLICATE       : "Duplicate",          // [CLOSED]
    JUNK            : "Junk" };             // [CLOSED]
ModaConst.Incident.State.defaultValue = function () { return ModaConst.Incident.State.NEW };
ModaConst.Incident.State.isValid = function (value) {
    return (value == ModaConst.Incident.State.NEW ||
            value == ModaConst.Incident.State.OPEN ||
            value == ModaConst.Incident.State.IN_PROGRESS ||
            value == ModaConst.Incident.State.HOLD ||
            value == ModaConst.Incident.State.REOPEN ||
            value == ModaConst.Incident.State.RESOLVED ||
            value == ModaConst.Incident.State.UN_REPRODUCEABLE ||
            value == ModaConst.Incident.State.CLOSED ||
            value == ModaConst.Incident.State.DUPLICATE ||
            value == ModaConst.Incident.State.JUNK) ? true : false;
}
ModaConst.Incident.State.getOpenStates = function() { 
    return [
        ModaConst.Incident.State.NEW,
        ModaConst.Incident.State.OPEN,
        ModaConst.Incident.State.IN_PROGRESS,
        ModaConst.Incident.State.HOLD,
        ModaConst.Incident.State.REOPEN
    ];
};
ModaConst.Incident.State.getClosedStates = function() { 
    return [
        ModaConst.Incident.State.RESOLVED,
        ModaConst.Incident.State.UN_REPRODUCEABLE,
        ModaConst.Incident.State.CLOSED,
        ModaConst.Incident.State.DUPLICATE,
        ModaConst.Incident.State.JUNK
    ];
};

ModaConst.Incident.State.isOpenState = function(state) {
    if (state == ModaConst.Incident.State.NEW || 
        state == ModaConst.Incident.State.OPEN ||
        state == ModaConst.Incident.State.IN_PROGRESS ||
        state == ModaConst.Incident.State.HOLD ||
        state == ModaConst.Incident.State.REOPEN) {
        return true;
    } else {
        return false;
    }
}

ModaConst.Incident.State.isClosedState = function(state) {
    if (state == ModaConst.Incident.State.RESOLVED ||
        state == ModaConst.Incident.State.UN_REPRODUCEABLE ||
        state == ModaConst.Incident.State.CLOSED ||
        state == ModaConst.Incident.State.DUPLICATE ||
        state == ModaConst.Incident.State.JUNK) {
        return true;
    } else {
        return false;
    }
}


ModaConst.Incident.State.isType = function (state) {
    if (state == ModaConst.Incident.State.RESOLVED || 
        state == ModaConst.Incident.State.UN_REPRODUCEABLE) {
        return "DONE";
    } else if (state == ModaConst.Incident.State.CLOSED || 
        state == ModaConst.Incident.State.DUPLICATE || 
        state == ModaConst.Incident.State.JUNK) {
        return "CLOSED";
    } else {
        return "PENDING";
    }
}


//--- Rules related -------
ModaConst.FaultService.RuleType = {
    FILTER      : "Filter",
    CORRELATE   : "Correlate",
    NOTIFY      : "Notify" };
ModaConst.FaultService.RuleType.isValid = function (value) {
    return (value == ModaConst.FaultService.RuleType.FILTER ||
            value == ModaConst.FaultService.RuleType.CORRELATE ||
            value == ModaConst.FaultService.RuleType.NOTIFY) ? true : false;
}

ModaConst.FaultService.RuleAction = {
    DROP_ALERT          : "Drop Event",
    AUTO_CLOSE_INCIDENT : "Auto-Close Incident",
    ATTACH_ALARM        : "Attach Alarm",
    DUPLICATE_INCIDENT  : "Duplicate Incident",
    MERGE_INCIDENT      : "Merge Incident",             // Same as Duplicate Incident ?
    NOTIFY_EMAIL        : "Notify by Email",
    NOTIFY_SMS          : "Notify by SMS",
    NEXT                : "Next"            // Proceed to next step
};
ModaConst.FaultService.RuleAction.isValid = function (value) {
    return (value == ModaConst.FaultService.RuleAction.DROP_ALERT ||
            value == ModaConst.FaultService.RuleAction.AUTO_CLOSE_INCIDENT ||
            value == ModaConst.FaultService.RuleAction.ATTACH_ALARM ||
            value == ModaConst.FaultService.RuleAction.DUPLICATE_INCIDENT || 
            value == ModaConst.FaultService.RuleAction.MERGE_INCIDENT || 
            value == ModaConst.FaultService.RuleAction.NOTIFY_EMAIL || 
            value == ModaConst.FaultService.RuleAction.NOTIFY_SMS || 
            value == ModaConst.FaultService.RuleAction.NEXT) ? true : false;
}


//--- (Dashboard Overview) Summary related -------
ModaConst.FaultService.Summary = {};
ModaConst.FaultService.Summary.DataType = {
    ORGS                : "Organizations",
    SITES               : "Sites",
    INCIDENTS           : "Incidents",
    APPLICATIONS        : "Applications",
    AGENT_DEVICES       : "AgentDevices",
    MONITORED_DEVICES   : "MonitoredDevices"
};

ModaConst.FaultService.Summary.DataType.isValid = function (value) {
    return (value == ModaConst.FaultService.Summary.DataType.ORGS ||
            value == ModaConst.FaultService.Summary.DataType.SITES ||
            value == ModaConst.FaultService.Summary.DataType.INCIDENTS ||
            value == ModaConst.FaultService.Summary.DataType.APPLICATIONS ||
            value == ModaConst.FaultService.Summary.DataType.AGENT_DEVICES ||
            value == ModaConst.FaultService.Summary.DataType.MONITORED_DEVICES) ? true : false;
}

ModaConst.FaultService.Summary.Type = {
    COUNTS              : "Counts",
    LISTS               : "Lists",
};

ModaConst.FaultService.Summary.Type.isValid = function (value) {
    return (value == ModaConst.FaultService.Summary.Type.COUNTS ||
            value == ModaConst.FaultService.Summary.Type.LISTS) ? true : false;
}

ModaConst.FaultService.DefaultIcon = {
    APPLICATIONS : "cloud-app.png"
};


// string values in CW enums must be substrings of respective names defined in CW Setup Tables
ModaConst.FaultService.ConnectWiseState = {
    NEW             : "New",
    ASSIGNED        : "Assigned",
    IN_PROGRESS     : "In Progress",
    CLOSED          : "Closed",
}
ModaConst.FaultService.ConnectWisePriority = {
    P1              : "Priority 1",
    P2              : "Priority 2",
    P3              : "Priority 3",
    P4              : "Priority 4",
}
ModaConst.FaultService.ConnectWiseSeverity = {
    LOW             : "Low",
    MEDIUM          : "Medium",
    HIGH            : "High",
}
ModaConst.FaultService.ConnectWiseImpact = {
    LOW             : "Low",
    MEDIUM          : "Medium",
    HIGH            : "High",
}

// Mappings from To/From CW
ModaConst.FaultService.ToConnectWiseState = {}
ModaConst.FaultService.ToConnectWiseState[ModaConst.Incident.State.NEW]             = ModaConst.FaultService.ConnectWiseState.NEW
ModaConst.FaultService.ToConnectWiseState[ModaConst.Incident.State.OPEN]            = ModaConst.FaultService.ConnectWiseState.ASSIGNED
ModaConst.FaultService.ToConnectWiseState[ModaConst.Incident.State.HOLD]            = ModaConst.FaultService.ConnectWiseState.ASSIGNED
ModaConst.FaultService.ToConnectWiseState[ModaConst.Incident.State.REOPEN]          = ModaConst.FaultService.ConnectWiseState.ASSIGNED
ModaConst.FaultService.ToConnectWiseState[ModaConst.Incident.State.IN_PROGRESS]     = ModaConst.FaultService.ConnectWiseState.IN_PROGRESS
ModaConst.FaultService.ToConnectWiseState[ModaConst.Incident.State.RESOLVED]        = ModaConst.FaultService.ConnectWiseState.CLOSED
ModaConst.FaultService.ToConnectWiseState[ModaConst.Incident.State.UN_REPRODUCIBLE] = ModaConst.FaultService.ConnectWiseState.CLOSED
ModaConst.FaultService.ToConnectWiseState[ModaConst.Incident.State.CLOSED]          = ModaConst.FaultService.ConnectWiseState.CLOSED
ModaConst.FaultService.ToConnectWiseState[ModaConst.Incident.State.DUPLICATE]       = ModaConst.FaultService.ConnectWiseState.CLOSED
ModaConst.FaultService.ToConnectWiseState[ModaConst.Incident.State.JUNK]            = ModaConst.FaultService.ConnectWiseState.CLOSED

ModaConst.FaultService.FromConnectWiseState = {}
ModaConst.FaultService.FromConnectWiseState[ModaConst.FaultService.ConnectWiseState.NEW]            = ModaConst.Incident.State.NEW
ModaConst.FaultService.FromConnectWiseState[ModaConst.FaultService.ConnectWiseState.ASSIGNED]       = ModaConst.Incident.State.OPEN
ModaConst.FaultService.FromConnectWiseState[ModaConst.FaultService.ConnectWiseState.IN_PROGRESS]    = ModaConst.Incident.State.IN_PROGRESS
ModaConst.FaultService.FromConnectWiseState[ModaConst.FaultService.ConnectWiseState.CLOSED]         = ModaConst.Incident.State.CLOSED

ModaConst.FaultService.ToConnectWiseSeverity = {}
ModaConst.FaultService.ToConnectWiseSeverity[ModaConst.FaultService.Severity.CRITICAL]  = ModaConst.FaultService.ConnectWiseSeverity.HIGH
ModaConst.FaultService.ToConnectWiseSeverity[ModaConst.FaultService.Severity.SEVERE]    = ModaConst.FaultService.ConnectWiseSeverity.MEDIUM
ModaConst.FaultService.ToConnectWiseSeverity[ModaConst.FaultService.Severity.MAJOR]     = ModaConst.FaultService.ConnectWiseSeverity.MEDIUM
ModaConst.FaultService.ToConnectWiseSeverity[ModaConst.FaultService.Severity.MINOR]     = ModaConst.FaultService.ConnectWiseSeverity.LOW
ModaConst.FaultService.ToConnectWiseSeverity[ModaConst.FaultService.Severity.INFO]      = ModaConst.FaultService.ConnectWiseSeverity.LOW
ModaConst.FaultService.ToConnectWiseSeverity[ModaConst.FaultService.Severity.NOT_SET]   = ModaConst.FaultService.ConnectWiseSeverity.LOW

ModaConst.FaultService.FromConnectWiseSeverity = {}
ModaConst.FaultService.FromConnectWiseSeverity[ModaConst.FaultService.ConnectWiseSeverity.LOW] = ModaConst.FaultService.Severity.MINOR
ModaConst.FaultService.FromConnectWiseSeverity[ModaConst.FaultService.ConnectWiseSeverity.MEDIUM] = ModaConst.FaultService.Severity.MAJOR
ModaConst.FaultService.FromConnectWiseSeverity[ModaConst.FaultService.ConnectWiseSeverity.HIGH] = ModaConst.FaultService.Severity.CRITICAL

ModaConst.FaultService.ToConnectWisePriority = {}
ModaConst.FaultService.ToConnectWisePriority[ModaConst.FaultService.Priority.LOW]       = ModaConst.FaultService.ConnectWisePriority.P3
ModaConst.FaultService.ToConnectWisePriority[ModaConst.FaultService.Priority.MEDIUM]    = ModaConst.FaultService.ConnectWisePriority.P2
ModaConst.FaultService.ToConnectWisePriority[ModaConst.FaultService.Priority.HIGH]      = ModaConst.FaultService.ConnectWisePriority.P1

ModaConst.FaultService.FromConnectWisePriority = {}
ModaConst.FaultService.FromConnectWisePriority[ModaConst.FaultService.ConnectWisePriority.P1]   = ModaConst.FaultService.Priority.HIGH
ModaConst.FaultService.FromConnectWisePriority[ModaConst.FaultService.ConnectWisePriority.P2]   = ModaConst.FaultService.Priority.MEDIUM
ModaConst.FaultService.FromConnectWisePriority[ModaConst.FaultService.ConnectWisePriority.P3]   = ModaConst.FaultService.Priority.LOW
ModaConst.FaultService.FromConnectWisePriority[ModaConst.FaultService.ConnectWisePriority.P4]   = ModaConst.FaultService.Priority.LOW

ModaConst.FaultService.ToConnectWiseImpact = {}
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.CRITICAL]  = {}
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.SEVERE]    = {}
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.MAJOR]     = {}
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.MINOR]     = {}
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.INFO]      = {}
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.NOT_SET]   = {}
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.CRITICAL][ModaConst.FaultService.Priority.LOW] = ModaConst.FaultService.ConnectWiseImpact.MEDIUM
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.CRITICAL][ModaConst.FaultService.Priority.MEDIUM] = ModaConst.FaultService.ConnectWiseImpact.MEDIUM
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.CRITICAL][ModaConst.FaultService.Priority.HIGH] = ModaConst.FaultService.ConnectWiseImpact.HIGH

ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.SEVERE][ModaConst.FaultService.Priority.LOW] = ModaConst.FaultService.ConnectWiseImpact.LOW
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.SEVERE][ModaConst.FaultService.Priority.MEDIUM] = ModaConst.FaultService.ConnectWiseImpact.MEDIUM
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.SEVERE][ModaConst.FaultService.Priority.HIGH] = ModaConst.FaultService.ConnectWiseImpact.MEDIUM

ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.MAJOR][ModaConst.FaultService.Priority.LOW] = ModaConst.FaultService.ConnectWiseImpact.LOW
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.MAJOR][ModaConst.FaultService.Priority.MEDIUM] = ModaConst.FaultService.ConnectWiseImpact.LOW
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.MAJOR][ModaConst.FaultService.Priority.HIGH] = ModaConst.FaultService.ConnectWiseImpact.MEDIUM

ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.MINOR][ModaConst.FaultService.Priority.LOW] = ModaConst.FaultService.ConnectWiseImpact.LOW
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.MINOR][ModaConst.FaultService.Priority.MEDIUM] = ModaConst.FaultService.ConnectWiseImpact.LOW
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.MINOR][ModaConst.FaultService.Priority.HIGH] = ModaConst.FaultService.ConnectWiseImpact.LOW

ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.INFO][ModaConst.FaultService.Priority.LOW] = ModaConst.FaultService.ConnectWiseImpact.LOW
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.INFO][ModaConst.FaultService.Priority.MEDIUM] = ModaConst.FaultService.ConnectWiseImpact.LOW
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.INFO][ModaConst.FaultService.Priority.HIGH] = ModaConst.FaultService.ConnectWiseImpact.LOW

ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.NOT_SET][ModaConst.FaultService.Priority.LOW] = ModaConst.FaultService.ConnectWiseImpact.LOW
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.NOT_SET][ModaConst.FaultService.Priority.MEDIUM] = ModaConst.FaultService.ConnectWiseImpact.LOW
ModaConst.FaultService.ToConnectWiseImpact[ModaConst.FaultService.Severity.NOT_SET][ModaConst.FaultService.Priority.HIGH] = ModaConst.FaultService.ConnectWiseImpact.LOW






//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~ RoutePolicies 
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
ModaConst.RoutePolicy = {};

// Features
ModaConst.RoutePolicy.Feature = { 
    USER            : "user",
    POLICY          : "policy", 
    ORGS            : "organizations",
    ORG_GROUPS      : "orggroups",
    SITES           : "sites",
    SITE_GROUPS     : "sitegroups",
    DEVICES         : "devices",
    MONITORING_TEMPLATES: "monitoringTemplates",
    MONITORED_DEVICES: "monitoredDevices",
    DASHBOARD       : "dashboard",
    APPLICATION     : "application",
    JOBS            : "jobs",
    ALARMS          : "alarms",
    INCIDENTS       : "incidents",
    RULES           : "rules",
    CORRELATION_RULES: "correlationRules",
    SWIMAGE         : "swImage",
    SYSTEM          : "system"
};
ModaConst.RoutePolicy.Feature.isValid = function (value) {
    return (value == ModaConst.RoutePolicy.Feature.USER ||
            value == ModaConst.RoutePolicy.Feature.POLICY ||
            value == ModaConst.RoutePolicy.Feature.ORGS ||
            value == ModaConst.RoutePolicy.Feature.ORG_GROUPS ||
            value == ModaConst.RoutePolicy.Feature.SITES ||
            value == ModaConst.RoutePolicy.Feature.SITE_GROUPS||
            value == ModaConst.RoutePolicy.Feature.DEVICES ||
            value == ModaConst.RoutePolicy.Feature.MONITORING_TEMPLATES ||
            value == ModaConst.RoutePolicy.Feature.MONITORED_DEVICES ||
            value == ModaConst.RoutePolicy.Feature.DASHBOARD ||
            value == ModaConst.RoutePolicy.Feature.APPLICATION ||
            value == ModaConst.RoutePolicy.Feature.JOBS ||
            value == ModaConst.RoutePolicy.Feature.ALARMS ||
            value == ModaConst.RoutePolicy.Feature.INCIDENTS ||
            value == ModaConst.RoutePolicy.Feature.RULES ||
            value == ModaConst.RoutePolicy.Feature.CORRELATION_RULES ||
            value == ModaConst.RoutePolicy.Feature.SWIMAGE ||
            value == ModaConst.RoutePolicy.Feature.SYSTEM
            ) ? true : false;
}

// Actions
ModaConst.RoutePolicy.Action = { 
    FETCH           : "fetch",
    CREATE          : "create", 
    UPDATE          : "update",
    DELETE          : "delete"
};
ModaConst.RoutePolicy.Action.isValid = function (value) {
    return (value == ModaConst.RoutePolicy.Action.FETCH ||
            value == ModaConst.RoutePolicy.Action.CREATE ||
            value == ModaConst.RoutePolicy.Action.UPDATE ||
            value == ModaConst.RoutePolicy.Action.DELETE) ? true : false;
}


//--- Fields of Each MongoDB collection that will be used in the generic search ---
ModaConst.SearchFields = {};
ModaConst.SearchFields[ModaConst.MongoCollection.ORGS] = [
    "orgName", 
    //xGUI: "description",      //xGUI: Not displayed on the GUI as of now 
    //xGUI: "address.city", 
    //xGUI: "address.state", 
    //xGUI: "address.country",
    "contactName", 
    "email",
    "phone",
    //"parentOrgName",  //TBD: how to search on cross referenced params displayed in UI
];
ModaConst.SearchFields[ModaConst.MongoCollection.SITES] = [
    "siteName", 
    //xGUI: "description", 
    "address.city", 
    "address.state", 
    "address.country"
];
ModaConst.SearchFields[ModaConst.MongoCollection.DEVICES] = [
    "deviceName",
    "macAddress",
    "serialNumber",
    //xGUI: "description",
    //"deviceModelName",  //TBD: how to search on cross referenced params displayed in UI
    //"siteName",  //TBD: how to search on cross referenced params displayed in UI
    //"depolyedConfigList", //TBD: how to search on cross referenced params displayed in UI
];
ModaConst.SearchFields[ModaConst.MongoCollection.APPLICATIONS] = [
    "appName",
    "appType",
    "subType",
    //xGUI: "description",
    "params",
    //"domains", //TBD: how to search on cross referenced params displayed in UI
    //"usedByProfilesList", //TBD: how to search on cross referenced params displayed in UI
];
ModaConst.SearchFields[ModaConst.MongoCollection.MODA_JOB_INFO] = [
    "jobId",
    "jobName",
    //xGUI: "description",
    //"deviceName", // for config job cross reference
    "jobState",
    "jobStatus",
];
ModaConst.SearchFields[ModaConst.MongoCollection.MODA_JOB_RUN_STATUS] = [
    "jobId",
    "jobRunId",
    //"jobName", // cross reference
    //xGUI: "description",
    //"deviceName", // for config job cross reference
    "jobRunStatus"
];
ModaConst.SearchFields[ModaConst.FaultService.MongoCollection.ALARMS] = [
    "alarmId",
    "incidentId",
    //xGUI: "description",    // Not displayed on the UI as of now
    "shortDescription",
    //"category",
    "categoryType",
    "categorySubType",
    "severity", 
    "status", 
    "type" 
];
ModaConst.SearchFields[ModaConst.FaultService.MongoCollection.INCIDENTS] = [
    "incidentId",
    "headline", 
    //xGUI: "description",
    //"category",
    //"componentName", 
    "severity", 
    "state",
    "priority"
    // , "externalIncidentId",
];
ModaConst.SearchFields[ModaConst.InventoryService.MongoCollection.MONITORING_TEMPLATES] = [
    "monTemplateName",
    "description"
];

ModaConst.SearchFields[ModaConst.InventoryService.MongoCollection.MONITORED_DEVICES] = [
    "monDevName",
    "monDevMACAddress",
    "monDevIpAddress",
    "monDevVendor",
    "monDevModel"
];


//--- Sockets related constants
ModaConst.Socket = {};
ModaConst.Socket.Room = {
    /*--- Currently the whole DashboardSummary is updated at once ---
    SUMMARY_INCIDENTS           : "room_summ_inc",
    SUMMARY_SITES               : "room_summ_sit",
    SUMMARY_AGENT_DEVICES       : "room_summ_dev",
    SUMMARY_MONITORED_DEVICES   : "room_summ_mondev",
    ----------------------------------------------------------------*/
    SUMMARY                     : "room_summ",
    LIST_ALARMS                 : "room_list_alm",
    LIST_INCIDENTS              : "room_list_inc",
    AGENT_DEVICES               : "room_agtdev"             // Used by Page.SUMMARY(AgentDevice) & Page.AGENT_DEVICES
};

ModaConst.Socket.Event = {
    INCIDENT        : "event_incident",         // Uses Room.LIST_INCIDENTS
    ALARM           : "event_alarm",            // Uses Room.LIST_ALARMS
    SUMMARY         : "event_summary",          // Uses Room.SUMMARY. For Incidents/Sites/Apps
    DEVICE_STATUS   : "event_agtdev_status",    // Uses Room.SUMMARY
    //APPLICATION     : "event_application"       // Uses Room.SUMMARY. Use Event.SUMMARY for now
};

ModaConst.Socket.Page = {
    NETWORK_ANALYTICS   : "network_analytics",
    DEVICE_ANALYTICS    : "device_analytics",
    SUMMARY             : "summary_page",
    ALARMS              : "alarms_page",
    INCIDENTS           : "incidents_page",
    AGENT_DEVICES       : "agtdev_page"
};


//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~ constants 
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

ModaConst.PredefinedUser = {};
ModaConst.PredefinedUser.Id = {};   // Match wih Id's pre-defined in the DB schema file - mongo-index.js
ModaConst.PredefinedUser.Id.ADMIN    = "userid.1001"
ModaConst.PredefinedUser.Id.SERVICE  = "userid.1002"
ModaConst.PredefinedUser.Id.SYSTEM   = "userid.1003"
ModaConst.PredefinedUser.Id.DEMO     = "userid.1004"

ModaConst.MacAddress = {};
ModaConst.MacAddress.DEFAULT = "FF:FF:FF:FF:FF:FF";        //CHECK: Use some other smaller string ?

// Dates are defined in epoch millisec 
ModaConst.Date = {};
ModaConst.Date.UNDEFINED = -1;
ModaConst.Date.UNLIMITED = 1900000000000;       // epoch millisec of "Mar 17, 2030"
ModaConst.Date.EOL_DATE = new Date(2038, 1);
ModaConst.Date.START_TIME = 1577836800000;      // 2020/01/01

ModaConst.Value = {};
ModaConst.Value.UNKNOWN = -1;

// Flag to indicate to use Auto/Default value
ModaConst.AUTO_GENERATE = "__auto__";
ModaConst.USE_DEFAULT = "__default__";


ModaConst.DefaultCompanyName = "MODA";

ModaConst.FROM_EMAIL =  'no-reply@modatechnologies.com';

ModaConst.SENDER_SMS_ID = 'ModaTech'; 


// Minimum length of text strings for generic search. 
// Show error/warning in the GUI, if the entered text is shorter than this.
ModaConst.SEARCH_TEXT_MIN_LENGTH = 3;

// WiFi
ModaConst.wifi= {}
ModaConst.wifi.band24 = {}
ModaConst.wifi.band24.channels = ['Auto', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
ModaConst.wifi.band24.channelWidths = [20, 40]

ModaConst.wifi.band5 = {}
ModaConst.wifi.band5.channels = [ 'Auto', 36,40,44,48,52,56,60,64,100,104,108,112,116,120, 124,128,132,136,140,144,149,153,157,161,165 ]
ModaConst.wifi.band5.channelWidths = [20, 40, 80, 160]


// Debug log max length
ModaConst.MAX_DEBUG_LOG_LENGTH = 512
ModaConst.toTrimString = function(obj) { return (JSON.stringify(obj).substr(0, ModaConst.MAX_DEBUG_LOG_LENGTH) + " .......... "); }
ModaConst.toTrimStringLen = function(obj, len) { return (JSON.stringify(obj).substr(0, len) + " ..... "); }

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


Object.freeze(ModaConst);

//module.exports = ModaConst; // Node.js requires this line (CommonJS standard)
export default ModaConst; // UI requires this line (ECMAScript standard)


// !!!!!
// !!!!!
// !!!!! EVERYTIME THIS FILE IS CHANGED, COPY IT OVER TO frontend/src/services      !!!!!!!!!!!!!!!!!!!
// !!!!! AND CHANGE LAST LINE export dedault ModaConst (ECMAScript)                 !!!!!!!!!!!!!!!!!!!
// !!!!!
