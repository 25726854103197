<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-router-wireless</v-icon>MODA Agent {{ titleName }}
				<v-badge title="Liveness" v-if="isEdit"
					bordered
					:key="statusKey1"
					:color="utilsLiveStatusDisplayObject(devicedetails.deviceLiveStatus).colorName" offset-x="-10" offset-y="10"
					:content="utilsLiveStatusDisplayObject(devicedetails.deviceLiveStatus).text">
				</v-badge>
				
				<v-btn v-if="isEdit" class="status-button" :color="utilsOperStatusDisplayObject(devicedetails.operStatus).colorName"
					rounded outlined right absolute>
					Status: <v-icon>{{ utilsOperStatusDisplayObject(devicedetails.operStatus).icon }}</v-icon><strong>{{
						utilsOperStatusDisplayObject(devicedetails.operStatus).text }} </strong>
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<!-- begin create/edit form -->
				<v-container fluid class="pl-5 pr-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<form ref="deviceform">
						<v-row v-if="isLoading">
							<v-col cols="12">
								<v-progress-linear indeterminate color="primary"></v-progress-linear>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="2">
								<v-switch v-model="utilStatus1" :true-value="UtilsModaConstants.AdminStatus.ENABLED"
									:false-value="UtilsModaConstants.AdminStatus.DISABLED"
									:label="utilsStatusLabelString(null, utilStatus1)" outlined inset :disabled="readonly"></v-switch>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field v-model="devicedetails.deviceName" :error-messages="nameErrors"
									@input="$v.devicedetails.deviceName.$touch()" @blur="$v.devicedetails.deviceName.$touch()"
									class="moda-required" label="MODA Agent Name" :disabled="readonly" />
							</v-col>
							<v-col cols="12" md="6">
								<v-text-field v-model="devicedetails.description" label="Description" :disabled="readonly">
								</v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field dense v-model="devicedetails.macAddress" :error-messages="macAddressErrors"
									@input="$v.devicedetails.macAddress.$touch()" @blur="$v.devicedetails.macAddress.$touch()"
									class="moda-required" label="MAC Address(Unique ID)" :disabled="readonly" />
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field dense v-model="devicedetails.serialNumber" label="Serial Number" :disabled="readonly" clearable>
								</v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-autocomplete dense v-model="devicedetails.siteId" :items="sites_list" item-text="siteName"
									item-value="siteId" :return-object="false" :error-messages="siteErrors"
									@input="$v.devicedetails.siteId.$touch()" @blur="$v.devicedetails.siteId.$touch()"
									class="moda-required" label="Site" :disabled="readonly" />
							</v-col>
							<v-col cols="12" md="4">
								<v-autocomplete dense v-model="devicedetails.deviceModelId" :items="models_list" item-text="deviceModelName"
									item-value="deviceModelId" :error-messages="modelIdErrors" auto-select-first
									@input="$v.devicedetails.deviceModelId.$touch()" @blur="$v.devicedetails.deviceModelId.$touch()"
									class="moda-required" label="Model" :disabled="readonly" />
							</v-col>
							<v-col cols="12" md="8">
								<v-text-field dense v-model="devicedetails.ipScanRange" label="IP Scan Range"
									hint="e.g. 192.168.0.1-200,192.168.1.0/24,192.168.2.24" persistent-hint :disabled="readonly"
									:error-messages="ipScanRangeErrors" clearable @input="$v.devicedetails.ipScanRange.$touch()"
									@blur="$v.devicedetails.ipScanRange.$touch()"> </v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12" md="4">
								<v-text-field dense v-model="devicedetails.wmi.userName" label="WMI User Name" :disabled="readonly" clearable/>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field dense v-model="devicedetails.wmi.password" label="WMI User Password" :disabled="readonly" clearable
											:append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
											:type="password_show ? 'text' : 'password'" @click:append="password_show = !password_show" />
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field dense v-model="devicedetails.wmi.workgroup" label="WMI Devices' Workgroup" :disabled="readonly" clearable/>
							</v-col>
						</v-row>

						<!--
<v-row>
    <v-col cols=12 md=6>
        <v-text-field v-model="devicedetails.version" label="MODA Agent Version" :disabled="readonly" > </v-text-field>
    </v-col>
</v-row>
-->
					</form>

				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="!readonly && !isEdit" class="mr-4" @click="submit"
								:disabled="this.$v.$invalid">Create</v-btn>
				<v-btn rounded outlined color="success" v-if="!readonly && isEdit" class="mr-4" @click="submit"
					:disabled="this.$v.$invalid">Update</v-btn>
				<v-btn rounded outlined color="error" v-if="!readonly" @click="cancel">Cancel</v-btn>
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import ApiService from '@/services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import Utils from '@/pages/moda/Utils'
//import ModaConstants from '../../services/ModaConstants.js'

// Custom Vuelidate validator - must be outside vue block
// IP Scan range format is comma separate list of something like below
//  192.168.0.1-244     : IP range
//  192.157.45.25       : single IP
//  192.153.66.42/24    : CIDR range
const isValidIpScanRange = function(value) {

    if (!this.devicedetails.ipScanRange ) return true // ipScanRange is optional

    let isValid = 0
    let regexes = [
        // single IP/IP range: 3 groups with dot, one group without dot, optional group with dash prefix
        '((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?){1}(-(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){0,1}$',
        // single IP/CIDR range: 3 groups with dot, one group without dot, optional netmask
        '((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?){1}(\\/(\\d|[0-2]\\d|3[0-2]))?$',
    ]
    let ips = value.split(",")
    ips.forEach( function(ip) {
        regexes.every( function(regexStr) {
            const regex = new RegExp(regexStr);
            //console.log("isValidIpScanRange regex " + regex + ", IP " + ip + ", isValid " + regex.test(ip))
            if(regex.test(ip)) {
                isValid++;
                return false; // stop the loop
            }
            return true; // continue loop
        }, ip, isValid)
    }, isValid)
    //console.log("isValidIpScanRange isValid " + isValid + ", ips.length " + ips.length )
    return isValid == ips.length
}

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            currOrgId: null,
            isLoading: false,
            fullPage: true,
            onCancel:true,
            height:35,
            width:35,
            device_id:'',
            titleName:'Create',
            isEdit:false,
            readonly:true,

            statusKey1: 0, // key attribute for those elements that depend on liveStatus data so they can be re-rendered forcefully

            sites_list:[],
            models_list:[],
            //types_list:['Bare Metal','VM'],

            userdetails:JSON.parse(localStorage.getItem('user-details')),
            parent_pages:[ {name:'Home'}, {name: "Configuration"},
                           {name:'MODA Agents', route: { name: "MODA_AGENTS" }},
                           {name:"Create/Edit", active:true} ],
            devicedetails:{
                deviceId :'',
                deviceName : '',
                deviceModelId : '',
                //version : '',
                serialNumber : '',
                ipScanRange : '',
                macAddress : '',
                description : '',
                orgId : '',
                siteId : '',
                wmi : {
                    userName : '',
                    password : '',
                    workgroup : '',
                },
            },
            password_show: false,
            apiStatus: null,
        };
    },

    mixins: [validationMixin, Utils],
    validations: {
        devicedetails : {
            deviceName: { required, minLength: minLength(3) },
            macAddress: { required, minLength: minLength(17) },
            ipScanRange: { isValidIpScanRange },
            siteId : { required },
            deviceModelId : { required },
        },
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.devicedetails.deviceName.$dirty) return errors
            !this.$v.devicedetails.deviceName.minLength && errors.push('Name must be atleast 3 characters long')
            !this.$v.devicedetails.deviceName.required && errors.push('Name is required.')
            return errors
        },
        macAddressErrors () {
            const errors = []
            if (!this.$v.devicedetails.macAddress.$dirty) return errors
            !this.$v.devicedetails.macAddress.minLength && errors.push('Unique ID must be atleast 17 characters long')
            !this.$v.devicedetails.macAddress.required && errors.push('ID is required.')
            return errors
        },
        ipScanRangeErrors () {
            let errors = []
            //console.log('ipScanRangeErrors "' + this.devicedetails.ipScanRange + '" fsaafa ');
            // ipScanRange is optional. Hence checking !this.devicedetails.ipScanRange
            if (!this.$v.devicedetails.ipScanRange.$dirty || !this.devicedetails.ipScanRange ) return errors
            !this.$v.devicedetails.ipScanRange.isValidIpScanRange && errors.push('Invalid IP range')
            return errors
        },
        siteErrors () {
            const errors = []
            if (!this.$v.devicedetails.siteId.$dirty) return errors
            !this.$v.devicedetails.siteId.required && errors.push('Site is required.')
            return errors
        },
        modelIdErrors () {
            const errors = []
            if (!this.$v.devicedetails.deviceModelId.$dirty) return errors
            !this.$v.devicedetails.deviceModelId.required && errors.push('Model is required.')
            return errors
        },
    },

    methods: {


        submit () {
            this.$v.$touch()

            this.isLoading=true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            this.devicedetails.deviceName = this.devicedetails.deviceName.trim();
            this.devicedetails.macAddress = this.devicedetails.macAddress.trim();
            if ( this.devicedetails.serialNumber ) this.devicedetails.serialNumber = this.devicedetails.serialNumber.trim();
            if ( this.devicedetails.ipScanRange ) this.devicedetails.ipScanRange = this.devicedetails.ipScanRange.trim();
            this.devicedetails.adminStatus = this.utilStatus1;

            //console.log("MODA Agent submit "+JSON.stringify(this.devicedetails))

            if ( this.isEdit ){
                ApiService.put("/api/v1/device/"+this.device_id,this.devicedetails, (err, result) => {
                    //console.log("*update*"+JSON.stringify(result))
                    this.isLoading=false;
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateDevice()
                    }
                }, query_params)
            }else {// create
                ApiService.post("/api/v1/device",this.devicedetails, (err, result) => {
                    //console.log("*create*"+JSON.stringify(err) + " " + JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateDevice()
                    }
                }, query_params)
            }
        },
        cancel () {
            this.$v.$reset()

            this.cancelCreateDevice()
        },


        cancelCreateDevice(){
            this.$router.go(-1)
        },

        getSitesList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            //this.isLoading=true;
            ApiService.get("/api/v1/site", (err, result) => {
                this.isLoading=false;
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.sites_list = result.data.data.objs;
                    //console.log("this.sites_list " + JSON.stringify(this.sites_list));
                    this.getModelsList();
                }
            }, query_params)
        },
        getModelsList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            //this.isLoading=true;
            ApiService.get("/api/v1/deviceModel", (err, result) => {
                this.isLoading=false;
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //console.log("getModelsList " + JSON.stringify(result));
                    this.models_list = result.data.data;
                    //console.log("getModelsList models_list " + JSON.stringify(this.models_list));
                    if(this.device_id != "" && this.device_id !=undefined)
                        this.getDeviceDetails();
                }
            }, query_params)
        },

        getDevicesLiveStatus() {
          //this.isLoading=true

          let query_params = {};
          if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

          var body = {};
          body.deviceIds = [ this.devicedetails.deviceId ]

          ApiService.post( "/svcStatus/api/v1/status/device", body, (err, result) => {
              //console.log(JSON.stringify(result))
              this.isLoading = false;
              if (err || result.data.data.devicesStatusList.length == 0){
                //this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Failed to fetch online status";
                this.apiStatus = "Failed to fetch devices' online status";
                this.utilsCheckLogout(this.apiStatus);
              } else {
                this.apiStatus = null;
                //console.log("getDevicesLiveStatus " + JSON.stringify(result.data.data));
                var liveStatuses = result.data.data.devicesStatusList;
                // merge liveStatuses into device_table_rows
                this.devicedetails.deviceLiveStatus = liveStatuses[0].deviceLiveStatus
                //console.log("getDevicesLiveStatus " + JSON.stringify(this.devicedetails, null, 2));

                this.statusKey1 += 1; // increment key attribute of those elements that depend on liveStatus data so they re-render
              }
            },
            query_params, "STATUS_SERVICE_URL");
        },

        getDeviceDetails(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            this.isLoading=true;
            ApiService.get("/api/v1/device/"+this.device_id, (err, result) => {
                //console.log(result);
                this.isLoading=false;
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else if ( result.data.data[0] ){
                    this.apiStatus = null;
                    this.devicedetails = result.data.data[0];
                    if ( ! this.devicedetails.wmi ) this.devicedetails.wmi = {}
                    //console.log("getDeviceDetails " + JSON.stringify(this.devicedetails));
                    this.utilStatus1 = this.devicedetails.adminStatus;
                    this.titleName = (this.readonly ? "View - " : "Edit - ") +this.devicedetails.deviceName
                    this.getDevicesLiveStatus();
                }
            }, query_params)
        },
        processBreadcrumbMessage(selectedOrgId) {
            this.currOrgId = selectedOrgId;
            if (this.device_id != "" && this.device_id !=undefined) {
                // Edit can't be done when org is changed, so g back
                this.cancelCreateDevice();
            } else {
                this.getSitesList(); 
            }
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.device_id = this.$route.params.id;
        this.readonly = (this.$route.params.readonly == "1");
        this.getSitesList();    // includes getModelsList();
        if(this.device_id != "" && this.device_id !=undefined){
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
        }
    }
}

</script>


