<template>
	<div class="content-wrapper">

		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-chart-donut</v-icon> Profile Usage {{ titleName }}
			</v-card-title>
			<v-card-text class="pb-0">
				<v-container fluid class="px-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<form ref="deviceConfigMapform" class="form-view">
						<v-row v-if="isLoading">
							<v-col cols="12">
								<v-progress-linear indeterminate color="primary"></v-progress-linear>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12" sm="6">
								<strong>Target</strong>
								<p>{{ deviceConfigMapDetails.targetType || "--" }}</p>
							</v-col>
							<v-col cols=12 sm="6">

							<v-autocomplete dense v-model="selected_targets" :chips="allowMultipleTargetSelection"
								:multiple="allowMultipleTargetSelection" :clearable="allowMultipleTargetSelection" :items="all_targets"
								item-text="targetName" item-value="targetId" :return-object="true" :error-messages="targetErrors"
								@input="$v.selected_targets.$touch()" @blur="$v.selected_targets.$touch()"
								:label="deviceConfigMapDetails.targetType" :disabled="readonly">

								<template v-if="allowMultipleTargetSelection" v-slot:item="{ item, on, attrs }">
									<v-list-item v-bind="attrs" v-on="on">
										<template v-slot:default="{ active }">
											<v-list-item-action>
												<v-checkbox :input-value="active"></v-checkbox>
											</v-list-item-action>
											<v-list-item-content>
												<v-list-item-title>
													<span v-on:click="navigateToAssignedTo(deviceConfigMapDetails.targetType, item.targetId)">
														<a href="javascript:;"> {{ item.targetName }} </a>
													</span>
												</v-list-item-title>
											</v-list-item-content>
										</template>
									</v-list-item>
								</template>
								<template v-if="allowMultipleTargetSelection" v-slot:selection="{ item, select, selected }">
									<v-chip :input-value="selected" close>
										<span v-on:click="navigateToAssignedTo(deviceConfigMapDetails.targetType, item.targetId)">
											<a href="javascript:;"> {{ item.targetName }} </a>
										</span>
									</v-chip>
								</template>
							</v-autocomplete>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols=12>
								<v-row>
									<v-col cols=12><strong>Config Profile</strong></v-col>
								</v-row>
								<v-row dense>
									<v-col cols=12 md=4>
										<v-autocomplete v-model="deviceConfigMapDetails.configProfileType" :items="all_profile_types"
											:error-messages="configProfileTypeErrors"
											@input="$v.deviceConfigMapDetails.configProfileType.$touch()"
											@blur="$v.deviceConfigMapDetails.configProfileType.$touch()" label="Profile Type"
											:disabled="readonly" />
									</v-col>
									<v-col cols=12 md=8>
										<v-autocomplete v-model="deviceConfigMapDetails.configProfileId" :items="all_config_profiles"
											:item-text="configProfileNameField" :item-value="configProfileIdField" :return-object="false"
											:error-messages="configProfileErrors" @input="$v.deviceConfigMapDetails.configProfileId.$touch()"
											@blur="$v.deviceConfigMapDetails.configProfileId.$touch()"
											no-data-text="No matching device model profiles found" label="Profile" :disabled="readonly" />
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12" sm="3">
										<strong>Created on</strong>
										<p>{{ selected_config_profile.createdDateString || "--" }}</p>
									</v-col>
									<v-col cols="12" sm="3">
										<strong>Last Modified</strong>
										<p>{{ selected_config_profile.modifiedDateString || "--" }}</p>
									</v-col>
									<v-col cols="12" sm="3"
										v-if='deviceConfigMapDetails.configProfileType == UtilsModaConstants.ConfigProfileType.ANALYTICS && selected_config_profile.syslog'>
										<strong>Syslog</strong>
										<p>{{ utilsSyslogStatusDisplayObject(selected_config_profile.syslog.receive).text }}</p>
									</v-col>
									<v-col cols="12" sm="3"
										v-if='deviceConfigMapDetails.configProfileType == UtilsModaConstants.ConfigProfileType.ANALYTICS && selected_config_profile.syslog'>
										<strong>Port</strong>
										<p>{{ selected_config_profile.syslog.listenPort || "--" }}</p>
									</v-col>
									<v-col cols="12"
										v-if='deviceConfigMapDetails.configProfileType == UtilsModaConstants.ConfigProfileType.ANALYTICS && selected_config_profile.syslog'>
										<v-autocomplete v-model="selected_config_profile.all_applications"
												:items="selected_config_profile.all_applications" item-value="appId" item-text="appName" chips
												multiple label="Applications" disabled prepend-inner-icon="mdi-apps">
												<template v-slot:selection="{ item, select, selected }">
													<v-chip :input-value="selected" color="primary" dark>
														<span v-on:click="navigateToApplication(item.appId)">
															<a class="link-text"> {{ item.appName }} </a>
														</span>
													</v-chip>
												</template>
											</v-autocomplete>
									</v-col>
								</v-row>
								<v-row dense>

									<v-col cols="12" sm="6"
										v-if='deviceConfigMapDetails.configProfileType == UtilsModaConstants.ConfigProfileType.SYSTEM &&
											selected_config_profile.profileDetails && selected_config_profile.profileDetails.statsReportingIntervalSecs'>
										<strong>Reporting interval</strong>
										<p>{{ selected_config_profile.profileDetails.statsReportingIntervalSecs || "--" }}</p>
									</v-col>

									<v-col cols="12" sm="6"
										v-if='deviceConfigMapDetails.configProfileType == UtilsModaConstants.ConfigProfileType.SYSTEM &&
											selected_config_profile.profileDetails && selected_config_profile.profileDetails.lte && selected_config_profile.profileDetails.lte.lte0'>
										<strong>LTE0 :</strong>
										<p>{{ utilsSyslogStatusDisplayObject(selected_config_profile.profileDetails.lte.lte0.adminStatus).text || "--" }} - 
											{{ selected_config_profile.profileDetails.lte.lte0.apn + ", " +
													selected_config_profile.profileDetails.lte.lte0.preferredSIMSlot }}
										</p>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</form>
				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>

import ApiService from '@/services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
//import { required, minLength } from 'vuelidate/lib/validators'
import Utils from '@/pages/moda/Utils.vue'

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            allowMultipleTargetSelection: false, // set to true to allow multiple selections for target
            currOrgId: null,
            currOrgName: null,
            targetOrgId: null,
            applicationsSelectedArray:['all'],
            isLoading: false,
            fullPage: true,
            onCancel:true,
            titleName:'Create',
            deviceConfigMapId:"",
            isEdit:false,
            readonly:true,

            configProfileNameField: "",
            configProfileIdField: "",

            all_config_profiles:[],
            all_analytics_config_profiles:[],
            all_system_config_profiles:[],


        /* 1. all_targets and selected_targets represent what is shown in UI widget.
           2. all_sites, all_devices, all_orgs, selected_sites, selected_devices, selected_orgs act as their backend data and memory to remember
              previous selection if user is just flipping between target type radio button.
           3. Whenever user clicks on target type radio button, variables in #1 are simply pointed to corresponding list in #2.
           4. On page load, retrieve AnalyticsConfigProfiles, DeviceConfigMap. Initialize selected_devices or selected_orgs or selected_sites
              to DeviceConfigMap assignedTo based on DeviceConfigMap targetType

           5. And based on DeviceConfigMap targetType, fetch either DevicesList or OrgsList, SitesList and populate
              corresponding all_devices or all_orgs or all_sites.
           6. When user flips between target type radio buttons, if corresponding lists are not fetched, fetch just only that list, and initialize
              selected list to first in the all list. This happens only first time.
           7. Once a particular list is fetched, next time onwards, it's just pointing all_targets and selected_targets to appropriate list.
              No need to re-fetch.
        */

            all_devices_fetched: false,
            all_orgs_fetched: false,
            all_sites_fetched: false,
            all_devices:[],
            all_orgs:[],
            all_sites:[],
            selected_devices:[],
            selected_orgs:[],
            selected_sites:[],
            all_targets:[],
            selected_targets:[],

            height:35,
            width:35,
            userdetails:JSON.parse(localStorage.getItem('user-details')),
            deviceConfigMapDetails:{
                deviceConfigMapId :'',
                configProfileType :'',
                configProfileId :'',
                assignedTo :{},
                targetType :'',
                createdBy :'',
                description :'',
            },

            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Profiles & Jobs"},
                           {name:'Profile Usage', route: { name: "DEVICE_CONFIG_MAP" }},
                           {name:"View", active:true} ],
            apiStatus: null,
        };
    },

    watch: {
        deviceConfigMapDetails : {
            handler (val) {
                //console.log("watch deviceConfigMapDetails" + JSON.stringify(this.deviceConfigMapDetails));
                if ( val.configProfileType ){

                    if ( val.configProfileType == this.UtilsModaConstants.ConfigProfileType.ANALYTICS ) {

                        this.configProfileNameField = "analyticsConfigProfileName"
                        this.configProfileIdField = "analyticsConfigProfileId"
                        this.all_config_profiles = this.all_analytics_config_profiles;

                    }else if ( val.configProfileType == this.UtilsModaConstants.ConfigProfileType.SYSTEM ) {

                        this.configProfileNameField = "systemConfigProfileName"
                        this.configProfileIdField = "systemConfigProfileId"
                        this.all_config_profiles = this.filterSystemProfiles()
                    }
                }
                // check targetTypeChanged()
                this.setSelectedProfile(val.configProfileId);
                //console.log("watch selected_config_profile" + JSON.stringify(this.selected_config_profile));
            },
            deep: true,
        },
        /* See logic in data section */
        selected_targets : {
            handler () {
                // save selected_targets back to corresponding backend data for later use
                if ( this.deviceConfigMapDetails.targetType == "Device"){
                    this.selected_devices = this.selected_targets;
                    if ( this.deviceConfigMapDetails.configProfileType == this.UtilsModaConstants.ConfigProfileType.SYSTEM )
                        this.all_config_profiles = this.filterSystemProfiles();
                } else if ( this.deviceConfigMapDetails.targetType == "Organization"){
                    this.selected_orgs = this.selected_targets;
                    if ( this.deviceConfigMapDetails.configProfileType == this.UtilsModaConstants.ConfigProfileType.SYSTEM )
                        this.all_config_profiles = this.all_system_config_profiles;
                } else if ( this.deviceConfigMapDetails.targetType == "Site"){
                    this.selected_sites = this.selected_targets;
                    if ( this.deviceConfigMapDetails.configProfileType == this.UtilsModaConstants.ConfigProfileType.SYSTEM )
                        this.all_config_profiles = this.all_system_config_profiles;
                }
                this.setSelectedProfile(this.deviceConfigMapDetails.configProfileId);
            },
            deep: true,
        },
    },

    mixins: [validationMixin,
            Utils ],

    validations: {
        deviceConfigMapDetails : {
            configProfileType: { required },
            //deviceConfigMapName: { required, minLength: minLength(3) },
            configProfileId: { required },
        },
        selected_targets: { required },
    },
    computed: {
        configProfileTypeErrors () {
            //console.log("computed configProfileTypeErrors" + JSON.stringify(this.$v.deviceConfigMapDetails));
            const errors = []
            if (!this.$v.deviceConfigMapDetails.configProfileType.$dirty) return errors
            !this.$v.deviceConfigMapDetails.configProfileType.required && errors.push('Profile type is required.')
            return errors
        },
        configProfileErrors () {
            const errors = []
            if (!this.$v.deviceConfigMapDetails.configProfileId.$dirty) return errors
            !this.$v.deviceConfigMapDetails.configProfileId.required && errors.push('Profile is required.')
            return errors
        },
        targetErrors () {
            const errors = []
            if (!this.$v.selected_targets.$dirty) return errors
            !this.$v.selected_targets.required && errors.push('Target is required.')
            return errors
        },

        // must be computed. because we are using this.UtilsModaConstants...
        selected_config_profile: function () {
            return {
                all_applications:[],
                syslog: { receive:this.UtilsModaConstants.AdminStatus.ENABLED, listenPort:541},
                createdDateString :'',
                modifiedDateString :'',
            }
        },
        all_profile_types : function() {
            return [ this.UtilsModaConstants.ConfigProfileType.ANALYTICS, this.UtilsModaConstants.ConfigProfileType.SYSTEM ];
        },
    },

    methods: {
        cancel () {
            this.$v.$reset()
            this.cancelCreateDeviceConfigMap()
        },
        navigateToApplication(id){
            var readonly = 1
            const r = this.$router.resolve({name: "APPLICATIONS_EDIT", params: { appId: id, readonly: readonly} } );
            window.open(r.href, '_blank');
        },
        navigateToAssignedTo(type, id){
            var readonly = 1
            if ( type == "Device")
                this.$router.push({name : "MODA_AGENTS_EDIT", params: { id: id, readonly: readonly } } )
            else if ( type == "Site")
                this.$router.push({ name: "SITES_EDIT", params: { siteId: id, readonly: readonly } })
            else if ( type == "Organization")
                this.$router.push({name:"ORGS_EDIT", params : {id: id, readonly: readonly}})
            else if ( type == "OrganizationGroup")
                this.$router.push('/config/orgsGroups/edit/'+id+ "/" + readonly)
        },
        cancelCreateDeviceConfigMap(){
            this.$router.go(-1);
        },

        setSelectedProfile(configProfileId){
                var cfgProfile
                if ( configProfileId ){
                    cfgProfile = this.utilsGetArrayElementByFieldValue(this.all_config_profiles,
                                                        this.configProfileIdField, configProfileId);
                    if ( cfgProfile ) {
                        this.selected_config_profile.all_applications = cfgProfile.applications;
                        this.selected_config_profile.syslog = cfgProfile.syslog;
                        this.selected_config_profile.profileDetails = cfgProfile.profileDetails;
                        this.selected_config_profile.createdDateString = this.utilsFormatDateTime(cfgProfile.createdDate);
                        this.selected_config_profile.modifiedDateString = this.utilsFormatDateTime(cfgProfile.modifiedDate);
                    } else
                        this.deviceConfigMapDetails.configProfileId = ""
                }
                if ( ! cfgProfile && this.all_config_profiles[0]) // either configProfileId not set or not in the correct array type
                    this.deviceConfigMapDetails.configProfileId = this.all_config_profiles[0][this.configProfileIdField];
        },
        filterSystemProfiles(){

            //console.log("filterSystemProfiles selected_devices " + JSON.stringify(this.selected_devices))
            //console.log("filterSystemProfiles all_devices " + JSON.stringify(this.all_devices))

            // when editing an existing config map, this.selected_devices will not have deviceModelId. It will only have targetId & targetName
            // so find intersection of all_devices and selected_devices, then find deviceModelIds from that intersected array
            var intersected = Array.isArray(this.selected_devices) ?
                                    this.all_devices.filter(ad => this.selected_devices.findIndex(sd => sd.targetId == ad.targetId) != -1 ) :
                                    this.all_devices.filter(ad => this.selected_devices.targetId == ad.targetId)
            var selected_models = intersected.map(sd => {return sd.deviceModelId});
            //console.log("filterSystemProfiles intersected " + JSON.stringify(intersected));
            //console.log("filterSystemProfiles selected_models " + JSON.stringify(selected_models))
            return this.all_system_config_profiles.filter( function(profile) {
                //console.log("filterSystemProfiles " + this.deviceConfigMapDetails.targetType + " " + profile.deviceModelId );

                if ( this.deviceConfigMapDetails.targetType != "Device" ) return true; // show all profiles if Org or Site is selected
                // if target is Device, profile's deviceModelId must match one of the selected models
                if ( selected_models.includes(profile.deviceModelId) ) return true;
                return false;
            }, this) // array filter() routine requires thisArg
        },
        /* See logic in data section */
        targetTypeChanged(val){
            // retrieve list if first time. Nex time onwards, just point UI lists to corresponding data lists
            if ( val == 'Device' ){
                if ( ! this.all_devices_fetched ) this.getDevicesList(1);
                else {
                    this.selected_targets = this.selected_devices;
                    this.all_targets = this.all_devices;
                }
                //console.log("targetTypeChanged " + JSON.stringify(val) + " " + JSON.stringify(this.selected_devices));
            } else if ( val == 'Organization' ) {
                if ( ! this.all_orgs_fetched ) this.getOrgsList(1);
                else {
                    this.selected_targets = this.selected_orgs;
                    this.all_targets = this.all_orgs;
                }
                //console.log("targetTypeChanged " + JSON.stringify(val) + " " + JSON.stringify(this.selected_orgs));
            } else if ( val == 'Site' ) {
                if ( ! this.all_sites_fetched )this.getSitesList(1);
                else {
                    this.selected_targets = this.selected_sites;
                    this.all_targets = this.all_sites;
                }
                //console.log("targetTypeChanged " + JSON.stringify(val) + " " + JSON.stringify(this.selected_sites));
            }
        },


        addSuffixes(type, profiles){
            profiles.forEach((p) => {
                // suffix orgName to profile display name if it's not logged in user's org Name so that
                // downstream users will know to which org profile belongs to
                // Suffix device Model also.
                var field;
                if ( type == this.UtilsModaConstants.ConfigProfileType.ANALYTICS ) field = "analyticsConfigProfileName"
                else if ( type == this.UtilsModaConstants.ConfigProfileType.SYSTEM ) field = "systemConfigProfileName"
                else return
                if ( p.orgName && p.orgName != this.userdetails.orgInfo.orgName )
                    p[field] += " (" + p.orgName +  (p.deviceModel ? (" - " + p.deviceModel.deviceModelName) : "") + ")";
                else if (p.deviceModel)
                    p[field] += " (" + p.deviceModel.deviceModelName + ")";
            });
        },
        getAnalyticsConfigProfileList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            this.isLoading=true
            this.all_applications = [];
            ApiService.get("/api/v1/analyticsConfigProfile", (err, result) => {
                //this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {

                    this.apiStatus = null;
                    this.all_analytics_config_profiles = result.data.data;
                    this.addSuffixes(this.UtilsModaConstants.ConfigProfileType.ANALYTICS, this.all_analytics_config_profiles);

                    if(this.deviceConfigMapId != "" && this.deviceConfigMapId !=undefined)
                        ;
                    else if (this.all_analytics_config_profiles.length){
                        // init combo boxs to analytics
                        this.deviceConfigMapDetails.configProfileType = this.UtilsModaConstants.ConfigProfileType.ANALYTICS
                        this.all_config_profiles = this.all_analytics_config_profiles
                        this.deviceConfigMapDetails.configProfileId = this.all_config_profiles[0].analyticsConfigProfileId;
                    }

                    this.getSystemConfigProfileList();
                }
            }, query_params)
        },

        getSystemConfigProfileList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/systemConfigProfile", (err, result) => {
                //this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {

                    //console.log("getSystemConfigProfileList " + JSON.stringify(result.data.data));

                    this.apiStatus = null;
                    this.all_system_config_profiles = result.data.data;
                    this.addSuffixes(this.UtilsModaConstants.ConfigProfileType.SYSTEM, this.all_system_config_profiles);

                    if(this.deviceConfigMapId != "" && this.deviceConfigMapId !=undefined)
                        this.getDeviceConfigMapDetails();
                    else{
                        this.deviceConfigMapDetails.targetType = "Device"
                        this.getDevicesList(1);
                    }
                }
            }, query_params)
        },

        getDeviceConfigMapDetails(){
            let query_params = {};
            if ( this.targetOrgId ) query_params.targetOrgId = this.targetOrgId;
            else if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/deviceConfigMap/"+this.deviceConfigMapId, (err, result) => {
                //console.log("deviceConfigMap " + JSON.stringify(result))
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.deviceConfigMapDetails = result.data.data[0];

                    if ( this.deviceConfigMapDetails.configProfileType == this.UtilsModaConstants.ConfigProfileType.ANALYTICS)
                        this.all_config_profiles = this.all_analytics_config_profiles
                    else if ( this.deviceConfigMapDetails.configProfileType == this.UtilsModaConstants.ConfigProfileType.SYSTEM)
                        this.all_config_profiles = this.filterSystemProfiles();

                    this.selected_targets = this.allowMultipleTargetSelection ?  this.deviceConfigMapDetails.assignedTo :
                                                                                this.deviceConfigMapDetails.assignedTo[0];

                    //console.log ( "deviceConfigMap " + JSON.stringify(this.selected_targets));

                    /* See logic in data section */
                    // based on target type, save selected list to corresponding backend data
                    if ( this.deviceConfigMapDetails.targetType == 'Device'){
                        this.selected_devices =  this.selected_targets;
                        this.getDevicesList(0)
                    } else if ( this.deviceConfigMapDetails.targetType == 'Organization'){
                        this.selected_orgs =  this.selected_targets;
                        this.getOrgsList(0)
                    } else if ( this.deviceConfigMapDetails.targetType == 'Site'){
                        this.selected_sites =  this.selected_targets;
                        this.getSitesList(0)
                    }

                    //console.log(JSON.stringify(this.selected_applications))
                    //this.titleName = (this.readonly ? "View - " : "Edit - ") +this.deviceConfigMapDetails.deviceConfigMapName
                }
            }, query_params)
        },

        getOrgsList(initSelected){

            this.all_orgs = [ { targetId: this.currOrgId, targetName: this.currOrgName } ]
            this.all_targets = this.all_orgs;
            if ( initSelected )
                this.selected_targets = this.selected_orgs = this.allowMultipleTargetSelection ? [this.all_orgs[0]] : this.all_orgs[0];
            this.all_orgs_fetched = true;

/* We can not assign to any downstream org from higher levels. If we do, those downstream org's users won't see this entry when they login.
   So, we'll have to go down to that org level and create mapping there.

            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/org/children/min/" + this.currOrgId + "/0", (err, result) => {
                //console.log(JSON.stringify(result))
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    var orgs_list = result.data.data;
                    this.all_orgs = orgs_list.map(a => {
                        return { targetId: a.orgId, targetName: a.orgName }
                    });

                    // See logic in data section

                    // if called in response to UI click (not coz of page load), select first one in the list

                    this.all_targets = this.all_orgs;
                    if ( initSelected )
                        this.selected_targets = this.selected_orgs = this.allowMultipleTargetSelection ? [this.all_orgs[0]] : this.all_orgs[0];
                    this.all_orgs_fetched = true;
                }
            }, query_params)
*/
        },

        getSitesList(initSelected){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/site", (err, result) => {
                //console.log(JSON.stringify(result))
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    var sites_list = result.data.data.objs;
                    this.all_sites = sites_list.map(a => {
                        return { targetId: a.siteId, targetName: a.siteName }
                    });

                    /* See logic in data section */

                    // if called in response to UI click (not coz of page load), select first one in the list

                    this.all_targets = this.all_sites;
                    if ( initSelected )
                        this.selected_targets = this.selected_sites = this.allowMultipleTargetSelection ? [this.all_sites[0]] : this.all_sites[0];
                    this.all_sites_fetched = true;
                }
            }, query_params)
        },

        getDevicesList(initSelected){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/device", (err, result) => {
                //console.log(JSON.stringify(result))
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    var devices_list = result.data.data;
                    this.all_devices = devices_list.map(a => {
                        return { targetId: a.deviceId, targetName: a.deviceName + " (" + a.deviceModel.deviceModelName + ")",
                                 deviceModelId: a.deviceModelId }
                    });

                    /* See logic in data section */

                    // if called in response to UI click (not coz of page load), select first one in the list

                    this.all_targets = this.all_devices;
                    if ( initSelected )
                        this.selected_targets = this.selected_devices = this.allowMultipleTargetSelection ? [this.all_devices[0]] : this.all_devices[0];
                    this.all_devices_fetched = true;

                    if ( this.deviceConfigMapDetails.configProfileType == this.UtilsModaConstants.ConfigProfileType.SYSTEM)
                        this.all_config_profiles = this.filterSystemProfiles(); // edit existing config map requires all_devices to filterSystemProfiles()
                }
            }, query_params)
        },


        processBreadcrumbMessage(selectedOrgId, selectedOrgName){
            this.currOrgId = selectedOrgId;
            this.currOrgName = selectedOrgName;
            if(this.deviceConfigMapId != "" && this.deviceConfigMapId !=undefined){
                // Edit can't be done when org is changed, so g back
                this.cancelCreateDeviceConfigMap();
            }else{
                this.all_devices_fetched = false;
                this.all_orgs_fetched = false;
                this.all_sites_fetched = false;
                this.getAnalyticsConfigProfileList()
            }
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.currOrgName = this.$refs.breadcrumb.getLastBreadcrumbOrgName();
        this.targetOrgId = this.$route.params.targetOrgId;
        this.deviceConfigMapId = this.$route.params.deviceConfigMapId;
        this.readonly = true;
        this.getAnalyticsConfigProfileList()
        if(this.deviceConfigMapId != "" && this.deviceConfigMapId !=undefined){
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
        }
    }
}

</script>
