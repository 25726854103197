<template>
	<v-footer padless>
		<v-col class="text-center pa-1" cols="12">
			<p class="caption mb-0 pr-5 pb-2 text-right">{{ new Date().getFullYear() }} @ <strong>Moda Technologies</strong></p>
		</v-col>
	</v-footer>
</template>

<script>
export default {
	name: 'Footer',
	data() {
		return {
		}
	}
}
</script>
