<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-window-shutter-cog</v-icon> {{ titleName }} System Profile
			</v-card-title>
			<v-card-text class="pb-0">
				<!-- begin create/edit form -->
				<v-container fluid class="px-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<form ref="systemConfigProfileform" :class="readonly ? 'form-view' : ''">
						<v-row v-if="isLoading">
							<v-col cols="12">
								<v-progress-linear indeterminate color="primary"></v-progress-linear>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="3">
								<v-text-field v-model="systemConfigProfile.systemConfigProfileName" :error-messages="nameErrors"
									@input="$v.systemConfigProfile.systemConfigProfileName.$touch()"
									@blur="$v.systemConfigProfile.systemConfigProfileName.$touch()" class="moda-required"
									label="Config Profile Name" :disabled="readonly" />
							</v-col>
							<v-col cols="12" md="4">
								<v-autocomplete v-model="systemConfigProfile.deviceModelId" :items="all_models_list"
									item-text="deviceModelName" item-value="deviceModelId" :error-messages="modelIdErrors"
									auto-select-first @input="$v.systemConfigProfile.deviceModelId.$touch()"
									@blur="$v.systemConfigProfile.deviceModelId.$touch()" @change="deviceModelIdChanged"
									class="moda-required" label="Device Model" :disabled="readonly" />
							</v-col>
							<v-col cols="12" md="5">
								<v-text-field v-model="systemConfigProfile.description" label="Description" :disabled="readonly">
								</v-text-field>
							</v-col>
						</v-row>

						<v-tabs v-model="settings_tab" background-color="primary" class="mt-2" dark slider-size="3">
							<v-tab><v-icon left>mdi-wrench</v-icon>System </v-tab>
							<v-tab v-if="systemConfigProfile.profileDetails.ltes"><v-icon left>mdi-signal</v-icon>LTE</v-tab>
							<v-tab v-if="systemConfigProfile.profileDetails.wifi"><v-icon left>mdi-wifi</v-icon>WiFi</v-tab>
							<v-tab v-if="systemConfigProfile.profileDetails.lans"><v-icon left>mdi-lan</v-icon>LANs</v-tab>
							<v-tab v-if="systemConfigProfile.profileDetails.wans"><v-icon left>mdi-wan</v-icon>WAN</v-tab>
						</v-tabs>

						<v-tabs-items v-model="settings_tab">
							<v-tab-item>
								<v-card class="pa-3">
									<v-row>
										<v-col cols="12" md="2">
											<p class="text-overline pt-4">System Settings</p>
										</v-col>
										<v-col cols="12" md="3">
											<v-text-field v-model.number="systemConfigProfile.profileDetails.statsReportingIntervalSecs"
												label="Statistics reporting interval" type="number" hint="30 - 3600" class="moda-required"
												min=30 max=3600 v-on:keypress="utilsIsNumber" :disabled="readonly"
												:error-messages="reportingIntervalErrors"
												@input="$v.systemConfigProfile.profileDetails.statsReportingIntervalSecs.$touch()"
												@blur="$v.systemConfigProfile.profileDetails.statsReportingIntervalSecs.$touch()">
											</v-text-field>
										</v-col>
									</v-row>
								</v-card>
							</v-tab-item>

							<v-tab-item v-if="systemConfigProfile.profileDetails.ltes">
								<v-card class="pa-3">
									<v-row v-for="lte in selected_model_ltes_params" :key="lte.value">
										<v-col cols="12" md="1">
											<p class="text-overline pt-4"> {{ lte.text }} </p>
										</v-col>
										<v-col cols="12" md="2">
											<v-switch v-model="systemConfigProfile.profileDetails.ltes[lte.value].adminStatus"
												:true-value="UtilsModaConstants.AdminStatus.ENABLED"
												:false-value="UtilsModaConstants.AdminStatus.DISABLED" inset
												:label="utilsStatusLabelString(null, systemConfigProfile.profileDetails.ltes[lte.value].adminStatus)"
												:disabled="readonly"></v-switch>
										</v-col>
										<v-col cols="12" md="6">
											<v-text-field v-model="systemConfigProfile.profileDetails.ltes[lte.value].APN" label="APN"
												:disabled="readonly" />
										</v-col>
										<v-col cols="12" md="3">
											<v-autocomplete v-model="systemConfigProfile.profileDetails.ltes[lte.value].preferredSIMSlot"
												:items="selected_model_all_sims" prepend-inner-icon="mdi-sim" label="Preferred SIM Slot"
												:disabled="readonly" />
										</v-col>
									</v-row>
								</v-card>
							</v-tab-item>

							<v-tab-item v-if="systemConfigProfile.profileDetails.wifi">
								<v-card class="pa-3" :elevation="0">
									<v-row v-for="radio in selected_model_radios_params" :key="radio.value">
										<v-col cols="12" md="2">
											<p class="text-overline pt-4"> {{ radio.text }} </p>
										</v-col>
										<v-col cols="12" md="2">
											<v-switch v-model="systemConfigProfile.profileDetails.wifi.radios[radio.value].adminStatus"
												:true-value="UtilsModaConstants.AdminStatus.ENABLED"
												:false-value="UtilsModaConstants.AdminStatus.DISABLED" inset
												:label="utilsStatusLabelString(null, systemConfigProfile.profileDetails.wifi.radios[radio.value].adminStatus)"
												:disabled="readonly"></v-switch>
										</v-col>
										<v-col cols="12" md="4">
											<v-autocomplete v-model="systemConfigProfile.profileDetails.wifi.radios[radio.value].channel"
												:items="UtilsModaConstants.wifi[radio.band].channels" prepend-icon="mdi-filter-variant"
												label="Channel" :disabled="readonly" />
										</v-col>
										<v-col cols="12" md="4">
											<v-autocomplete v-model="systemConfigProfile.profileDetails.wifi.radios[radio.value].channelWidth"
												:items="UtilsModaConstants.wifi[radio.band].channelWidths" prepend-icon="mdi-filter-variant"
												label="Channel Width" :disabled="readonly" />
										</v-col>
									</v-row>
								</v-card>
								<v-divider></v-divider>
								<v-card class="pa-3" :elevation="0">
									<v-row v-for="wlan in selected_model_wlans_params" :key="wlan.value">
										<v-col cols="12" md="2">
											<p class="text-overline pt-4"> {{ wlan.text }} </p>
										</v-col>
										<v-col cols=12 md=2>
											<v-switch v-model="systemConfigProfile.profileDetails.wifi.wlans[wlan.value].adminStatus"
												:true-value="UtilsModaConstants.AdminStatus.ENABLED"
												:false-value="UtilsModaConstants.AdminStatus.DISABLED" inset
												:label="utilsStatusLabelString(null, systemConfigProfile.profileDetails.wifi.wlans[wlan.value].adminStatus)"
												:disabled="readonly"></v-switch>
										</v-col>
										<v-col cols=12 md=2>
											<v-autocomplete v-model="systemConfigProfile.profileDetails.wifi.wlans[wlan.value].security"
												:items="security_modes" label="Security" :disabled="readonly" />
										</v-col>
										<v-col cols=12 md=3>
											<v-text-field v-model="systemConfigProfile.profileDetails.wifi.wlans[wlan.value].SSID"
												label="SSID" :disabled="readonly" />
										</v-col>
										<v-col cols=12 md=3>
											<v-text-field v-model="systemConfigProfile.profileDetails.wifi.wlans[wlan.value].password"
												:append-icon="wlan.passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
												:type="wlan.passwordShow ? 'text' : 'password'"
												@click:append="wlan.passwordShow = !wlan.passwordShow"
												@input="$v.systemConfigProfile.profileDetails.wifi.wlans[wlan.value].password.$touch()"
												@blur="$v.systemConfigProfile.profileDetails.wifi.wlans[wlan.value].password.$touch()"
												:error-messages="passwordErrors(wlan.value)" label="Password" :disabled="readonly"
												:class="isWLANEnabled(wlan.value) ? 'moda-required' : ''" />
										</v-col>
									</v-row>
								</v-card>
							</v-tab-item>

							<v-tab-item v-if="systemConfigProfile.profileDetails.lans">
								<v-card>
									<v-row class="ma-2" v-for="lan in selected_model_lans_params" :key="lan.value">
										<v-col cols="12" md="2">
											<p class="text-overline pt-4"> {{ lan.text }} </p>
										</v-col>
										<v-col cols="12" md="2">
											<v-switch v-model="systemConfigProfile.profileDetails.lans[lan.value].adminStatus"
												:true-value="UtilsModaConstants.AdminStatus.ENABLED"
												:false-value="UtilsModaConstants.AdminStatus.DISABLED" inset
												:label="utilsStatusLabelString(null, systemConfigProfile.profileDetails.lans[lan.value].adminStatus)"
												:disabled="readonly"></v-switch>
										</v-col>
										<v-col cols="12" md="2">
											<v-text-field v-model="systemConfigProfile.profileDetails.lans[lan.value].ipAddress"
												:error-messages="ipAddressErrors('lans', lan.value, 'ipAddress')"
												@input="$v.systemConfigProfile.profileDetails.lans[lan.value].ipAddress.$touch()"
												@blur="$v.systemConfigProfile.profileDetails.lans[lan.value].ipAddress.$touch()"
												label="IP Address" hint="123.234.134.114" persistent-hint :disabled="readonly"
												:class="isLANEnabled(lan.value) ? 'moda-required' : ''" />
										</v-col>
										<v-col cols="12" md="2">
											<v-text-field v-model="systemConfigProfile.profileDetails.lans[lan.value].netMask"
												:error-messages="netMaskErrors('lans', lan.value, 'netMask')"
												@input="$v.systemConfigProfile.profileDetails.lans[lan.value].netMask.$touch()"
												@blur="$v.systemConfigProfile.profileDetails.lans[lan.value].netMask.$touch()" label="Netmask"
												hint="255.255.255.0" persistent-hint :disabled="readonly"
												:class="isLANEnabled(lan.value) ? 'moda-required' : ''" />
										</v-col>
										<v-col cols="12" md="4">
											<v-text-field v-model="systemConfigProfile.profileDetails.lans[lan.value].displayName"
												label="Name" :disabled="readonly" />
										</v-col>
										<v-col cols="12" md="6">
											<p class="text-subtitle-2 mb-0">Ethernet Ports</p>
											<span v-for="(port) in selected_model_ethernet_ports" :key="port.value" class="float-left mr-4">
												<v-checkbox  v-model="systemConfigProfile.profileDetails.lans[lan.value].ethernetPorts"
													:disabled="readonly" :label="port.text" :value="port.value"
													@click="ethernetPortClicked(lan.value, port)"></v-checkbox>
											</span>
										</v-col>
										<v-col cols="12" md="6">
											<p class="text-subtitle-2 mb-0"> WLANs</p>
											<span v-for="(wlan) in selected_model_wlans_params" :key="wlan.value" class="float-left mr-4">
												<v-checkbox v-if="systemConfigProfile.profileDetails.wifi.wlans[wlan.value].SSID != ''"
													v-model="systemConfigProfile.profileDetails.lans[lan.value].wlans"
													:label="systemConfigProfile.profileDetails.wifi.wlans[wlan.value].SSID"
													:disabled="readonly" :value="wlan.value"
													@click="wlanClicked(lan.value, wlan)"></v-checkbox>
											</span>
										</v-col>
									</v-row>
								</v-card>
							</v-tab-item>

							<v-tab-item v-if="systemConfigProfile.profileDetails.wans">
								<v-card class="pa-3" :elevation="0" v-for="wan in selected_model_wans_params" :key="wan.value">
										<v-row v-for="version in wan" :key="version.value">
											<v-col cols=12 md=1>
												<p class="text-overline pt-4"> {{ version.text }} </p>
											</v-col>
											<v-col cols="12" md="2">
												<v-switch v-model="systemConfigProfile.profileDetails.wans[version.value].adminStatus"
													:true-value="UtilsModaConstants.AdminStatus.ENABLED"
													:false-value="UtilsModaConstants.AdminStatus.DISABLED" inset
													:label="utilsStatusLabelString(null, systemConfigProfile.profileDetails.wans[version.value].adminStatus)"
													:disabled="readonly"></v-switch>
											</v-col>
											<v-col cols=12 md=3>
												<v-autocomplete v-model="systemConfigProfile.profileDetails.wans[version.value].ipMode"
													:items="version.version == 'v4' ? ipv4_modes : ipv6_modes" icon="mdi-filter-variant"
													label="IP Mode" :disabled="readonly" />
											</v-col>
											<v-col cols=12 md=3>
												<v-text-field v-model="systemConfigProfile.profileDetails.wans[version.value].ipAddress"
													:error-messages="ipAddressErrors('wans', version.value, 'ipAddress', version.version)"
													@input="$v.systemConfigProfile.profileDetails.wans[version.value].ipAddress.$touch()"
													@blur="$v.systemConfigProfile.profileDetails.wans[version.value].ipAddress.$touch()"
													label="IP Address" :hint="version.addrHint" persistent-hint
													:disabled="readonly || !isWANStatic(version.value)"
													:class="isWANStatic(version.value) ? 'moda-required' : ''" />
											</v-col>
											<v-col cols=12 md=3>
												<v-text-field v-model="systemConfigProfile.profileDetails.wans[version.value].netMask"
													:error-messages="netMaskErrors('wans', version.value, 'netMask', version.version)"
													@input="$v.systemConfigProfile.profileDetails.wans[version.value].netMask.$touch()"
													@blur="$v.systemConfigProfile.profileDetails.wans[version.value].netMask.$touch()"
													label="Netmask" :hint="version.maskHint" persistent-hint
													:disabled="readonly || !isWANStatic(version.value)"
													:class="isWANStatic(version.value) ? 'moda-required' : ''" />
											</v-col>
											<v-col cols=12 md=6>
												<v-text-field dense v-model="systemConfigProfile.profileDetails.wans[version.value].defaultGateway"
													:error-messages="ipAddressErrors('wans', version.value, 'defaultGateway', version.version)"
													@input="$v.systemConfigProfile.profileDetails.wans[version.value].defaultGateway.$touch()"
													@blur="$v.systemConfigProfile.profileDetails.wans[version.value].defaultGateway.$touch()"
													label="Default Gateway" :hint="version.addrHint" persistent-hint
													:disabled="readonly || !isWANStatic(version.value)"
													:class="isWANStatic(version.value) ? 'moda-required' : ''" />
											</v-col>
											<v-col cols=12 md=6>
												<v-textarea dense v-model="systemConfigProfile.profileDetails.wans[version.value].dnsServers"
													auto-grow rows="1" row-height="15"
													:error-messages="dnsServersErrors('wans', version.value, 'dnsServers', version.version)"
													@input="$v.systemConfigProfile.profileDetails.wans[version.value].dnsServers.$touch()"
													@blur="$v.systemConfigProfile.profileDetails.wans[version.value].dnsServers.$touch()"
													label="DNS Servers" :hint="version.addrHint + ', ' + version.addrHint" persistent-hint
													:disabled="readonly || !isWANStatic(version.value)"
													:class="isWANStatic(version.value) ? 'moda-required' : ''" />
											</v-col>
										</v-row>
								</v-card>
							</v-tab-item>
						</v-tabs-items>
					</form>
				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="!readonly && !isEdit" @click="submit"
					:disabled="this.$v.$invalid">Create
				</v-btn>
				<v-btn rounded outlined color="success" v-if="!readonly && isEdit" @click="submit" :disabled="this.$v.$invalid">
					Update
				</v-btn>
				<v-btn rounded outlined color="error" v-if="!readonly" @click="cancel">Cancel</v-btn>
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>
import ApiService from '@/services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, ipAddress } from 'vuelidate/lib/validators'

export default {

    components: {
        Breadcrumb
    },
    data(){
        return {
            currOrgId: null,
            targetOrgId: null,
            isLoading: false,
            fullPage: true,
            onCancel:true,
            titleName:'Create',
            systemConfigProfileId:"",
            isEdit:false,
            readonly:true,

            settings_tab:null,
            all_models_list:[],
            selected_model_hw_caps: {},

            security_modes : ["WPA1WPA2", "WPA1"],
            ipv4_modes : [{text:"DHCP", value:"dhcp"}, {text:"Static", value:"static"},],
            ipv6_modes : [{text:"DHCPv6", value:"dhcpv6"}, {text:"Static", value:"static"},],

            // MAX HW capabilities
            // !!! NOTE : to add new models, just add extra array elements if it supports more than these max numbers !!!
            // !!!  These arrays are appropriately sliced based on HW capabilities received from backend from deviceModel API
            max_sims:[1, 2],
            max_ltes_params : [ { text: "LTE 1", value:"lte0", },],
            radio_24ghz_params : { text: "2.4Ghz Radio", value:"radio24", band: "band24", },
            radio_5ghz_params :  { text: "5Ghz Radio", value:"radio5", band: "band5", },
            max_wlans_params : [ { text: "WLAN 1", value:"wlan0", passwordShow: false,}, // default, single WLAN
                                 { text: "WLAN 2", value:"wlan1", passwordShow: false,},
                                 { text: "WLAN 3", value:"wlan2", passwordShow: false,},
                                 { text: "WLAN 4", value:"wlan3", passwordShow: false,},
                               ],
            max_lans_params : [ { text: "LAN 1", value:"lan0", }, // default, single LAN
                                { text: "LAN 2", value:"lan1", },
                                { text: "LAN 3", value:"lan2", },
                                { text: "LAN 4", value:"lan3", },
                                { text: "LAN 5", value:"lan4", },
                                { text: "LAN 6", value:"lan5", },
                                { text: "LAN 7", value:"lan6", },
                                { text: "LAN 8", value:"lan7", },
                               ],
            // this array is directly returned by deviceModel.properties.hwCapabilities since text changes based on labels on the box
            //max_ethernet_ports : [{text:"1", value:0}, {text:"2", value:1}, {text:"3", value:2}, {text:"4", value:3}, {text:"5", value:4},
                                  //{text:"6", value:5}, {text:"7", value:6}, {text:"8", value:7}, {text:"LAN-2.5G", value:250},],

            // each WAN port has two versions
            max_wans_params : [ [ { text: "IPv4", value:"wan0v4", version: "v4",
                                    addrHint: "123.234.134.114", maskHint: "255.255.255.0"},
                                  { text: "IPv6", value:"wan0v6", version: "v6",
                                    addrHint: "2001:db8:0:ff00::8329", maskHint: "2001:db8::/32"},
                                ],
                                [ { text: "IPv4", value:"wan1v4", version: "v4",
                                    addrHint: "123.234.134.114", maskHint: "255.255.255.0"},
                                    { text: "IPv6", value:"wan1v6", version: "v6",
                                    addrHint: "2001:db8:0:ff00::8329", maskHint: "2001:db8::/32"},
                                ],
                              ],

            // Current selected model's HW capabilities = slice of MAX HW capabilities
            // template uses these arrays to render DOM
            selected_model_all_sims:[],
            selected_model_ltes_params : [],
            selected_model_radios_params : [],
            selected_model_ethernet_ports : [],
            selected_model_wlans_params : [],
            selected_model_lans_params : [],
            selected_model_wans_params : [],


            systemConfigProfile: {
                systemConfigProfileId :'',
                systemConfigProfileName : '',
                profileDetails: {
                    statsReportingIntervalSecs: 600,
                    ltes : {
                        lte0 : {
                            adminStatus : '',
                            APN : "",
                            preferredSIMSlot: 1,
                        },
                        lte1 : {
                            adminStatus : '',
                            APN : "",
                            preferredSIMSlot: 1,
                        },
                    },
                    wifi : {
                        radios: {
                            radio24 : {
                                adminStatus : '',
                                channel: 'Auto',
                                channelWidth: 20,
                                transmitPower: 'Auto',
                            },
                            radio5 : {
                                adminStatus : '',
                                channel: 'Auto',
                                channelWidth: 20,
                                transmitPower: 'Auto',
                            },
                        },
                        wlans: {
                            wlan0 : {
                                adminStatus : '',
                                SSID: 'Default SSID',
                                password: 'Default Password',
                                security: 'WPA1WPA2',
                            },
                            wlan1 : {
                                adminStatus : '',
                                SSID: '',
                                password: '',
                                security: 'WPA1WPA2',
                            },
                            wlan2 : {
                                adminStatus : '',
                                SSID: '',
                                password: '',
                                security: 'WPA1WPA2',
                            },
                            wlan3 : {
                                adminStatus : '',
                                SSID: '',
                                password: '',
                                security: 'WPA1WPA2',
                            },
                        },
                    },
                    lans: {
                        lan0 : {
                            adminStatus : '',
                            displayName: "Default LAN",
                            ipAddress: '192.168.123.1',
                            netMask: '255.255.255.0',
                            ethernetPorts: [0, 1, 2, 3, 4, 5, 6, 7, 250], // default all ports and wlans in single LAN
                            wlans: ["wlan0", "wlan1", "wlan2", "wlan3"],
                        },
                        lan1 : {
                            adminStatus : '',
                            displayName: '',
                            ipAddress: '',
                            netMask: '',
                            ethernetPorts: [],
                            wlans: [],
                        },
                        lan2 : {
                            adminStatus : '',
                            displayName: '',
                            ipAddress: '',
                            netMask: '',
                            ethernetPorts: [],
                            wlans: [],
                        },
                        lan3 : {
                            adminStatus : '',
                            displayName: '',
                            ipAddress: '',
                            netMask: '',
                            ethernetPorts: [],
                            wlans: [],
                        },
                        lan4 : {
                            adminStatus : '',
                            displayName: '',
                            ipAddress: '',
                            netMask: '',
                            ethernetPorts: [],
                            wlans: [],
                        },
                        lan5 : {
                            adminStatus : '',
                            displayName: '',
                            ipAddress: '',
                            netMask: '',
                            ethernetPorts: [],
                            wlans: [],
                        },
                        lan6 : {
                            adminStatus : '',
                            displayName: '',
                            ipAddress: '',
                            netMask: '',
                            ethernetPorts: [],
                            wlans: [],
                        },
                        lan7 : {
                            adminStatus : '',
                            displayName: '',
                            ipAddress: '',
                            netMask: '',
                            ethernetPorts: [],
                            wlans: [],
                        },
                    },
                    wans: {
                        wan0v4 : {
                            adminStatus : '',
                            ipMode: 'dhcp',
                            ipAddress: '',
                            netMask: '',
                            defaultGateway: '',
                            dnsServers:[],
                        },
                        wan0v6 : {
                            adminStatus : '',
                            ipMode: 'dhcpv6',
                            ipAddress: '',
                            netMask: '',
                            defaultGateway: '',
                            dnsServers:[],
                        },
                        wan1v4 : {
                            adminStatus : '',
                            ipMode: 'dhcp',
                            ipAddress: '',
                            netMask: '',
                            defaultGateway: '',
                            dnsServers:[],
                        },
                        wan1v6 : {
                            adminStatus : '',
                            ipMode: 'dhcpv6',
                            ipAddress: '',
                            netMask: '',
                            defaultGateway: '',
                            dnsServers:[],
                        },
                    },
                },
            },

            height:35,
            width:35,
            userdetails:JSON.parse(localStorage.getItem('user-details')),
            tag: '',
            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Profiles & Jobs"},
                           {name:'System Config Profiles', route: { name: "SYS_CONFIG_PROFILES"} },
                           {name:"Create/Edit", active:true} ],
            apiStatus: null,
        };
    },

    mixins: [validationMixin,
            Utils ],

    validations: {

        systemConfigProfile : {
            systemConfigProfileName: { required, minLength: minLength(3) },
            deviceModelId: { required },
            profileDetails : {
                statsReportingIntervalSecs:        { required, customBetween() { return this.isParamValid("statsReportingIntervalSecs") } },
                wifi : {
                    wlans: {
                        wlan0 : {
                            password: { required: requiredIf( function() { return this.isWLANEnabled("wlan0") } ),
                                        minLength: minLength(8),
                                      },
                        },
                        wlan1 : {
                            password: { required: requiredIf( function() { return this.isWLANEnabled("wlan1") } ),
                                        minLength: minLength(8),
                                      },
                        },
                        wlan2 : {
                            password: { required: requiredIf( function() { return this.isWLANEnabled("wlan2") } ),
                                        minLength: minLength(8),
                                      },
                        },
                        wlan3 : {
                            password: { required: requiredIf( function() { return this.isWLANEnabled("wlan3") } ),
                                        minLength: minLength(8),
                                      },
                        },
                    },
                },
                lans: {
                    lan0 : {
                        ipAddress: { ipAddress, required: requiredIf( function() { return this.isLANEnabled("lan0") }) },
                        netMask: { ipAddress, required: requiredIf( function() { return this.isLANEnabled("lan0") }) },
                    },
                    lan1 : {
                        ipAddress: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan1") }) },
                        netMask: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan1") }) },
                    },
                    lan2 : {
                        ipAddress: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan2") }) },
                        netMask: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan2") }) },
                    },
                    lan3 : {
                        ipAddress: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan3") }) },
                        netMask: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan3") }) },
                    },
                    lan4 : {
                        ipAddress: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan4") }) },
                        netMask: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan4") }) },
                    },
                    lan5 : {
                        ipAddress: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan5") }) },
                        netMask: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan5") }) },
                    },
                    lan6 : {
                        ipAddress: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan6") }) },
                        netMask: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan6") }) },
                    },
                    lan7 : {
                        ipAddress: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan7") }) },
                        netMask: { ipAddress, required: requiredIf( function() {return this.isLANEnabled("lan7") }) },
                    },
                },
                wans: {
                    wan0v4 : {
                        ipAddress: { ipAddress, required: requiredIf( function() {return this.isWANStatic("wan0v4") }) },
                        netMask: { ipAddress, required: requiredIf( function() {return this.isWANStatic("wan0v4") }) },
                        defaultGateway: { ipAddress, required: requiredIf( function() {return this.isWANStatic("wan0v4") }) },
                        dnsServers: { customIpAddressList(value) { return this.utilsValidateIPAddressList("v4", value) },
                                            required: requiredIf( function() {return this.isWANStatic("wan0v4") }) },
                    },
                    wan0v6 : {
                        ipAddress: { customIpv6Address(value) { return this.utilsValidateIPv6Address(value) },
                                     required: requiredIf( function() {return this.isWANStatic("wan0v6") }) },
                        netMask: { customIpv6Netmask(value) { return this.utilsValidateIPv6Netmask(value) },
                                   required: requiredIf( function() {return this.isWANStatic("wan0v6") }) },
                        defaultGateway: { customIpv6Address(value) { return this.utilsValidateIPv6Address(value) },
                                          required: requiredIf( function() {return this.isWANStatic("wan0v6") }) },
                        dnsServers: { customIpAddressList(value) { return this.utilsValidateIPAddressList("v6", value) },
                                            required: requiredIf( function() {return this.isWANStatic("wan0v6") }) },
                    },
                    wan1v4 : {
                        ipAddress: { ipAddress, required: requiredIf( function() {return this.isWANStatic("wan1v4") }) },
                        netMask: { ipAddress, required: requiredIf( function() {return this.isWANStatic("wan1v4") }) },
                        defaultGateway: { ipAddress, required: requiredIf( function() {return this.isWANStatic("wan1v4") }) },
                        dnsServers: { customIpAddressList(value) { return this.utilsValidateIPAddressList("v4", value) },
                                            required: requiredIf( function() {return this.isWANStatic("wan1v4") }) },
                    },
                    wan1v6 : {
                        ipAddress: { customIpv6Address(value) { return this.utilsValidateIPv6Address(value) },
                                     required: requiredIf( function() {return this.isWANStatic("wan1v6") }) },
                        netMask: { customIpv6Netmask(value) { return this.utilsValidateIPv6Netmask(value) },
                                   required: requiredIf( function() {return this.isWANStatic("wan1v6") }) },
                        defaultGateway: { customIpv6Address(value) { return this.utilsValidateIPv6Address(value) },
                                          required: requiredIf( function() {return this.isWANStatic("wan1v6") }) },
                        dnsServers: { customIpAddressList(value) { return this.utilsValidateIPAddressList("v6", value) },
                                            required: requiredIf( function() {return this.isWANStatic("wan1v6") }) },
                    },
                },
            }
        },
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.systemConfigProfile.systemConfigProfileName.$dirty) return errors
            !this.$v.systemConfigProfile.systemConfigProfileName.required && errors.push('Name is required.')
            !this.$v.systemConfigProfile.systemConfigProfileName.minLength && errors.push('Name must be atleast 3 characters long')
            return errors
        },
        reportingIntervalErrors () {
            const errors = []
            if (!this.$v.systemConfigProfile.profileDetails.statsReportingIntervalSecs.dirty) return errors
            !this.$v.systemConfigProfile.profileDetails.statsReportingIntervalSecs.required && errors.push('Reporting interval required')
            !this.$v.systemConfigProfile.profileDetails.statsReportingIntervalSecs.customBetween && errors.push('Out of range')
            return errors
        },
        modelIdErrors () {
            const errors = []
            if (!this.$v.systemConfigProfile.deviceModelId.$dirty) return errors
            !this.$v.systemConfigProfile.deviceModelId.required && errors.push('Model is required.')
            return errors
        },

/* This watch is not working if deviceModelId is initialized upon load
        // just for watching purpose. We don't need to watch entire profile, just deviceModelId needed
        deviceModelIdWatch() {
            return this.systemConfigProfile.deviceModelId;
        },
*/
    },

    watch: {
/* This watch is not working if deviceModelId is initialized upon load
        // watching computed property. We don't need to watch entire profile, just deviceModelId needed
        deviceModelIdWatch : {
            handler : function(val) {
                console.log("deviceModelId changed to " + val);
                this.deviceModelIdChanged(val);
            },
        },
*/
    },


    methods: {

        // computed variables can't have arguments
        passwordErrors (wlan) {
            const errors = []
            if (!this.$v.systemConfigProfile.profileDetails.wifi.wlans[wlan].password.$dirty) return errors
            !this.$v.systemConfigProfile.profileDetails.wifi.wlans[wlan].password.required && errors.push('Password is required.')
            !this.$v.systemConfigProfile.profileDetails.wifi.wlans[wlan].password.minLength && errors.push('Password must be atleast 8 characters long')
            return errors
        },
        // computed variables can't have arguments
        ipAddressErrors (lans_wans, lan_wan, value, version="v4") {
            const errors = []
            if (!this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].$dirty) return errors
            !this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].required && errors.push('IP Address required')
            if ( version == "v4")
                !this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].ipAddress && errors.push('Invalid IP Address')
            else if ( version == "v6")
                !this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].customIpv6Address && errors.push('Invalid IP Address')
            return errors
        },
        // computed variables can't have arguments
        netMaskErrors (lans_wans, lan_wan, value, version="v4") {
            const errors = []
            if (!this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].$dirty) return errors
            !this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].required && errors.push('Netmask required')
            if ( version == "v4")
                !this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].ipAddress && errors.push('Invalid Netmask')
            else if ( version == "v6")
                !this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].customIpv6Netmask && errors.push('Invalid Netmask')
            return errors
        },
        // computed variables can't have arguments
        dnsServersErrors (lans_wans, lan_wan, value, version="v4") {
            const errors = []
            //console.log("dnsServersErrors " + value + " " + JSON.stringify(this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value]));
            if (!this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].$dirty) return errors
            !this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].required && errors.push('DNS Servers required')
            if ( version == "v4")
                !this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].customIpAddressList && errors.push('Invalid DNS Servers')
            else if ( version == "v6")
                !this.$v.systemConfigProfile.profileDetails[lans_wans][lan_wan][value].customIpAddressList && errors.push('Invalid DNS Servers')
            return errors
        },
        isLANEnabled(field){
            return this.systemConfigProfile.profileDetails.lans[field] &&
                   this.systemConfigProfile.profileDetails.lans[field].adminStatus != this.UtilsModaConstants.AdminStatus.DISABLED 
        },
        isWLANEnabled(field){
            return this.systemConfigProfile.profileDetails.wifi.wlans[field] &&
                   this.systemConfigProfile.profileDetails.wifi.wlans[field].SSID != ""
        },
        isWANStatic(field){
            return this.systemConfigProfile.profileDetails.wans[field] &&
                   this.systemConfigProfile.profileDetails.wans[field].ipMode == "static"
        },
        ethernetPortClicked(lan, port){
            var lans = this.systemConfigProfile.profileDetails.lans;
            var lan_ep = lans[lan].ethernetPorts;
            if ( lan_ep.findIndex(value => value == port.value) >= 0 ) {
                // checked. Remove this value from other LANs' ethernetPorts. One port can exist in only one LAN
                //console.log("ethernetPortClicked " + lan + " port " + JSON.stringify(port) + " checked, value " + JSON.stringify(lan_ep));
                Object.keys(lans).forEach( function(key) {
                    //console.log("key " + key + " " + JSON.stringify(lans[key]));
                    if ( key != lan ){
                        lans[key].ethernetPorts = lans[key].ethernetPorts.filter(l => l != port.value);
                    }
                })
            }else{
                // unchecked
                //console.log("ethernetPortClicked " + lan + " port " + JSON.stringify(port) + " unchecked, value " + JSON.stringify(lan_ep));
            }
        },
        wlanClicked(lan, wlan){
            var lans = this.systemConfigProfile.profileDetails.lans;
            var wlans = lans[lan].wlans;
            if ( wlans.findIndex(value => value == wlan.value) >= 0 ) {
                // checked. Remove this value from other LANs' wlans. One wlan can exist in only one LAN
                //console.log("wlanClicked " + lan + " wlan " + JSON.stringify(wlan) + " checked, value " + JSON.stringify(wlans));
                Object.keys(lans).forEach( function(key) {
                    //console.log("key " + key + " " + JSON.stringify(lans[key]));
                    if ( key != lan ){
                        lans[key].wlans = lans[key].wlans.filter(l => l != wlan.value);
                    }
                })
            }else{
                // unchecked
                //console.log("wlanClicked " + lan + " wlan " + JSON.stringify(wlan) + " unchecked, value " + JSON.stringify(wlans));
            }
        },

        isParamValid(paramName){
            //console.log("pramValidate " + paramName + " " + JSON.stringify(value));
            if ( paramName == "statsReportingIntervalSecs" ){
                var value = this.systemConfigProfile.profileDetails.statsReportingIntervalSecs;
                return value ? (value >= 30 &&  value <= 3600) : false;
            }
            return true;
        },


        deviceModelIdChanged(val) {
            //console.log("deviceModelIdChanged changed to " + val);
            var selected = this.all_models_list.find(model => model.deviceModelId == val);
            if ( selected ) {
                selected = selected.properties.find(property => property.name == "hwCapabilities");
                if ( selected ) {
                    this.selected_model_hw_caps = selected = selected.value
                    //console.log("It's HW caps " + JSON.stringify(this.selected_model_hw_caps));

                    // populate HW caps arrays based on the deviceModel from the backend
                    this.selected_model_ltes_params         = this.max_ltes_params.slice(0, selected.numLTEs);
                    this.selected_model_all_sims            = this.max_sims.slice(0, selected.numSIMs)
                    this.selected_model_radios_params       = [];
                    for(let i=0; i < selected.numWiFi24GHz; i++) this.selected_model_radios_params.push(this.radio_24ghz_params);
                    for(let i=0; i < selected.numWiFi5GHz; i++) this.selected_model_radios_params.push(this.radio_5ghz_params);
                    this.selected_model_wlans_params        = this.max_wlans_params.slice(0, selected.numWLANs);
                    this.selected_model_lans_params         = this.max_lans_params.slice(0, selected.numLANs);
                    this.selected_model_ethernet_ports      = [].concat(selected.ethernetLAN1GPorts, selected.ethernetLAN25GPorts);
                    this.selected_model_wans_params         = this.max_wans_params.slice(0,
                                                                    selected.ethernetWAN1GPorts.length + selected.ethernetWAN25GPorts.length);

                }
            }
        },

        submit () {
            this.$v.$touch()

            var opd = this.systemConfigProfile.profileDetails;

            //console.log("submit orig*****"+JSON.stringify(this.systemConfigProfile))

            this.systemConfigProfile.systemConfigProfileName = this.systemConfigProfile.systemConfigProfileName.trim()

            // shallow copy first i.e. toplevel params only.
            // Let's not disturb systemConfigProfile as we may still stay on this page if submit fails
            var sd = JSON.parse(JSON.stringify(this.systemConfigProfile));
            var pd = sd.profileDetails;

            // copy everything from this.systemConfigProfile.profileDetails upto the size of respective HW caps arrays
            // and adminStatus from respective HW caps arrays

            pd.ltes = {};
            this.selected_model_ltes_params.forEach( function(lte) {
                pd.ltes[lte.value]              = JSON.parse(JSON.stringify(opd.ltes[lte.value])) // deep copy
                if ( opd.ltes[lte.value].APN )
                    pd.ltes[lte.value].APN      = opd.ltes[lte.value].APN.trim()
            }, this)

            var radios = {};
            this.selected_model_radios_params.forEach( function(radio) {
                radios[radio.value]             = JSON.parse(JSON.stringify(opd.wifi.radios[radio.value])) // deep copy
            }, this)

            var wlans = {};
            this.selected_model_wlans_params.forEach( function(wlan) {
                wlans[wlan.value]               = JSON.parse(JSON.stringify(opd.wifi.wlans[wlan.value])) // deep copy
                if ( opd.wifi.wlans[wlan.value].SSID )
                    wlans[wlan.value].SSID      = opd.wifi.wlans[wlan.value].SSID.trim()
                if ( opd.wifi.wlans[wlan.value].password )
                    wlans[wlan.value].password  = opd.wifi.wlans[wlan.value].password.trim()
            }, this)
            pd.wifi = {};
            pd.wifi.radios = radios;
            pd.wifi.wlans = wlans;

            pd.lans = {};
            this.selected_model_lans_params.forEach( function(lan) {
                pd.lans[lan.value]              = JSON.parse(JSON.stringify(opd.lans[lan.value])) // deep copy
                if ( opd.lans[lan.value].displayName )
                    pd.lans[lan.value].displayName  = opd.lans[lan.value].displayName.trim()
                if ( opd.lans[lan.value].ipAddress )
                    pd.lans[lan.value].ipAddress    = opd.lans[lan.value].ipAddress.trim()
                if ( opd.lans[lan.value].netMask )
                    pd.lans[lan.value].netMask      = opd.lans[lan.value].netMask.trim()

                // send only the ports that are in selected_model_ethernet_ports
                pd.lans[lan.value].ethernetPorts= opd.lans[lan.value].ethernetPorts.filter(function(p){
                                                        return this.selected_model_ethernet_ports.findIndex(ep => ep.value == p) != -1;
                                                    }, this);
                // send only the wlans that are in selected_model_wlans_params
                pd.lans[lan.value].wlans        = opd.lans[lan.value].wlans.filter( function(w){
                                                        return this.selected_model_wlans_params.findIndex(wp => wp.value == w) != -1;
                                                    }, this);
            }, this)

            pd.wans = {};
            this.selected_model_wans_params.forEach( function(wan) {
                wan.forEach( function(version) {
                    pd.wans[version.value]                  = JSON.parse(JSON.stringify(opd.wans[version.value])) // deep copy
                    if ( opd.wans[version.value].ipAddress )
                        pd.wans[version.value].ipAddress        = opd.wans[version.value].ipAddress.trim()
                    if ( opd.wans[version.value].netMask )
                        pd.wans[version.value].netMask          = opd.wans[version.value].netMask.trim()
                    if ( opd.wans[version.value].defaultGateway )
                        pd.wans[version.value].defaultGateway   = opd.wans[version.value].defaultGateway.trim()

                    // arrayize comma separated dnsServers string
                    if ( typeof opd.wans[version.value].dnsServers == 'string' ){
                        pd.wans[version.value].dnsServers   = []
                        if ( opd.wans[version.value].dnsServers )
                            opd.wans[version.value].dnsServers.trim().split(/[\s,\n;]+/).forEach( tok => pd.wans[version.value].dnsServers.push(tok.trim()) )
                    }
                })
            }, this)

            //console.log("submit new 2*****"+JSON.stringify(sd))

            this.isLoading=true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            if ( this.isEdit ){
                ApiService.put("/api/v1/systemConfigProfile/"+this.systemConfigProfileId, sd, (err, result) => {
                    //console.log("*update*"+JSON.stringify(result))
                    this.isLoading=false;
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateSystemConfigProfile()
                    }
                }, query_params)
            }else {// create
                ApiService.post("/api/v1/systemConfigProfile", sd, (err, result) => {
                    //console.log("*create*"+JSON.stringify(err) + " " + JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateSystemConfigProfile()
                    }
                }, query_params)
            }
        },
        cancel () {
            this.$v.$reset()
            this.cancelCreateSystemConfigProfile()
        },

        cancelCreateSystemConfigProfile(){
            this.$router.go(-1);
        },
        getModelsList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            //this.isLoading=true;
            ApiService.get("/api/v1/deviceModel", (err, result) => {
                this.isLoading=false;
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //console.log("getModelsList " + JSON.stringify(result));
                    var all_models_list = result.data.data;

                    // filter in those models that support System Config profiles
                    this.all_models_list = all_models_list.filter((model) =>
                                                model.configProfileTypesSupported.includes(this.UtilsModaConstants.ConfigProfileType.SYSTEM) );

                    //console.log("getModelsList all_models_list " + JSON.stringify(this.all_models_list));
                    if(this.systemConfigProfileId != "" && this.systemConfigProfileId !=undefined)
                        this.getsystemConfigProfile();
                    else if ( this.all_models_list.length ) { // else, set model selected to first one
                        this.systemConfigProfile.deviceModelId = this.all_models_list[0].deviceModelId;
                        //console.log("Initial model id set to " + this.systemConfigProfile.deviceModelId);
                        this.deviceModelIdChanged(this.systemConfigProfile.deviceModelId) // watch doesn't work first time
                    }
                }
            }, query_params)
        },
        getsystemConfigProfile(){
            let query_params = {};
            if ( this.targetOrgId ) query_params.targetOrgId = this.targetOrgId;
            else if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/systemConfigProfile/"+this.systemConfigProfileId, (err, result) => {
                //console.log("systemConfigProfile " + JSON.stringify(result))
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.systemConfigProfile = result.data.data[0];

                    delete this.systemConfigProfile.deviceModel; // not needed of this in this form

                    this.deviceModelIdChanged(this.systemConfigProfile.deviceModelId)

                    //console.log(JSON.stringify(this.selected_applications))
                    this.titleName = (this.readonly ? "View - " : "Edit - ") +this.systemConfigProfile.systemConfigProfileName
                }
            }, query_params)
        },
        // adminStatuses need to be initialized in the code. Can't be done in data() {...} as we are using this.UtilsModaConstants
        initSystemConfigProfile(){
            var pd = this.systemConfigProfile.profileDetails;
            Object.keys(pd.ltes).forEach( function(key) {
                pd.ltes[key].adminStatus = this.UtilsModaConstants.AdminStatus.ENABLED; // by default, all LTEs enabled
            }, this)

            Object.keys(pd.wifi.radios).forEach( function(key) {
                pd.wifi.radios[key].adminStatus = this.UtilsModaConstants.AdminStatus.ENABLED; // by default, all radios enabled
            }, this)

            Object.keys(pd.wifi.wlans).forEach( function(key) {
                pd.wifi.wlans[key].adminStatus = this.UtilsModaConstants.AdminStatus.DISABLED;
            }, this)
            pd.wifi.wlans[this.max_wlans_params[0].value].adminStatus = this.UtilsModaConstants.AdminStatus.ENABLED; // by default, LAN0 is Enabled

            Object.keys(pd.lans).forEach( function(key) {
                pd.lans[key].adminStatus = this.UtilsModaConstants.AdminStatus.DISABLED;
            }, this)
            pd.lans[this.max_lans_params[0].value].adminStatus = this.UtilsModaConstants.AdminStatus.ENABLED; // by default, LAN0 is Enabled

            Object.keys(pd.wans).forEach( function(key) {
                pd.wans[key].adminStatus = pd.wans[key].ipMode == 'dhcp'  ? // by default, IPv4 Enabled on WANs, v6 disabled
                                            this.UtilsModaConstants.AdminStatus.ENABLED : this.UtilsModaConstants.AdminStatus.DISABLED;
            }, this)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            if(this.systemConfigProfileId != "" && this.systemConfigProfileId !=undefined){
                // Edit can't be done when org is changed, so g back
                this.cancelCreateSystemConfigProfile();
            }
            // Models don't change from org to org so no need to getModelsList
            //else this.getApplicationsList()
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.targetOrgId = this.$route.params.targetOrgId;
        this.systemConfigProfileId = this.$route.params.systemConfigProfileId;
        this.readonly = (this.$route.params.readonly == "1");
        if(this.systemConfigProfileId != "" && this.systemConfigProfileId !=undefined){
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
        }else
            this.initSystemConfigProfile();
        this.getModelsList();
    }
}

</script>



