<template>
	<div class="content-wrapper text-center">
		<div class="error-content">
			<h1 class="py-4 x-large-heading">403</h1>
			<h2>We couldn't access it...</h2>
			<p>The page you're looking for access forbidden.</p>
			<div>
				<v-btn @click="goToDahboard()" color="primary" x-large class="my-6" outlined rounded>Go To Dashboard</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	created() {
	},
	beforeRouteLeave (to, from, next) {
		next();
	},
	methods: {
		goToDahboard(){
			this.$router.push({name: "DASHBOARD_SUMMARY_STATUS"});
		}
	}
}
</script>
