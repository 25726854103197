<template>
  <v-container class="login-wrapper mt-5 pt-10">
    <div class="login-cover-placeholder">
      <div class="area">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
    <div class="mx-auto logo mt-6 mb-5">
      <div class="moda-logo"></div> MODA Technologies
    </div>
    <v-card elevation="10" class="mx-auto login-box" max-width="400">
      <v-card-title>
        <div class="mx-auto">Password Reset</div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-alert v-if="errMsg" :border="'top'" :color="errColor" dark>
              {{ errMsg }}
            </v-alert>
            <form v-on:submit="submitForm" method="POST" class="margin-bottom-0">
              <v-text-field v-model="email" outlined label="Email Address" prepend-inner-icon="mdi-account"
                @input="errMsg = null" />
              <v-text-field prepend-inner-icon="mdi-key-variant" v-model="password" label="Password"
                :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'" :type="password_show ? 'text' : 'password'"
                @click:append="password_show = !password_show" outlined @input="errMsg = null" />
              <v-btn block rounded type="submit" class="float-right mb-2" color="primary" :loading="isLoading"
                    :disabled="isResetPasswordDisabled()" large>
                Reset Password 
              </v-btn>
              <a v-if="resetFailed" href="#" @click="resetAgain">Reset again ?</a>
            </form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import ModaConst from "@/services/ModaConstants.js"
import UserApiService from "@/services/user-api.service";
import auth from "@/services/auth.service";
import {globalAPIUrlStore} from '../main.js'

export default {
  components: {
  },
  async mounted() {
      console.log("this.$route.query ", this.$route.query)
      this.email = this.$route.query.email
      this.resetToken = this.$route.query.resetToken
  },
  data() {
    return {
      email: "",
      password: "",
      password_show: false,
      isLoading: false,
      errMsg: null,
      errColor: "red lighten-2",
      resetFailed: false,
    };
  },
  computed: {
  },
  methods: {

    isResetPasswordDisabled(){
        return !this.email || !this.password
    },

    resetAgain() {
        let route = {name: "FORGOT_PASSWORD"}
        if ( this.email ) route.query = { email: this.email }
        this.$router.push(route)
    },

    submitForm: function (e) {

        e.preventDefault();

        var login_details = {
            email: this.email,
            password: this.password,
            resetToken: this.resetToken,
        };

        this.isLoading = true;
        UserApiService.put("/usersvc/api/v1/users/password/reset", login_details, (err, result) => {
            this.isLoading = false;
            if (err) {
                if (result && result.data && result.data.message && result.data.message.message)
                    this.errMsg = result.data.message.message
                else
                    this.errMsg = "Password reset failed"
                this.errColor = "red lighten-2"
                this.resetFailed = true
            } else {
                this.resetFailed = false
                this.errColor = "green lighten-2"
                this.errMsg = "Password reset successful, you will be redirected to login page shortly"
                setTimeout( ()=>{ this.$router.push({name: "LOGIN"}) }, 3000 )
            }
        });
    },
  },
};
</script>
