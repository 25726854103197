<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-home-city</v-icon>Organizations {{ titleName }}
				<v-btn v-if="isEdit" class="status-button" :color="utilsGetSeverityColor(orgdetails.highestSeverity).colorName"
					rounded outlined right absolute>
					Status: <v-icon>{{ utilsFaultStatusDisplayObject(orgdetails.faultStatus).icon }}</v-icon><strong>{{
						orgdetails.highestSeverity ?
						orgdetails.highestSeverity : utilsFaultStatusDisplayObject(orgdetails.faultStatus).text }} </strong>
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<!-- begin create/edit form -->
				<v-container fluid class="pl-5 pr-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<v-row v-if="isEdit" class="md-content-stretch" :key="statusKey1">
						<v-col cols="12" sm="6" md="auto">
							<v-alert text class="link-alert" prominent color="lime darken-2" icon="mdi-router-wireless"
								@click="navigateToSites(orgdetails)">
								<p class="mb-0">Sites</p>
								<h3>
									{{ orgdetails.siteCount || 0 }}
								</h3>
							</v-alert>
						</v-col>
						<v-col cols="12" sm="6" md="auto">
							<v-alert text class="link-alert" prominent type="warning" icon="mdi-ticket-confirmation"
								@click="navigateToIncidents(orgdetails)">
								<p class="mb-0">Incidents</p>
								<h3>
									{{ orgdetails.incCount || 0 }}
								</h3>
							</v-alert>
						</v-col>
						<v-col cols="12" sm="6" md="auto">
							<v-alert text class="link-alert" prominent type="info" icon="mdi-apps"
								@click="navigateToApplications(orgdetails)">
								<p class="mb-0">Applications</p>
								<h3>
									{{ orgdetails.applicationCount || 0 }}
								</h3>
							</v-alert>
						</v-col>
						<v-col cols="12" sm="6" md="auto">
							<v-alert text class="link-alert" prominent color="deep-purple" icon="mdi-router-wireless"
								@click="navigateToMonitoredDevices(orgdetails)">
								<p class="mb-0">Devices</p>
								<h3 :key="countsKey1">
									{{ orgdetails.monDevCount || 0 }}
								</h3>
							</v-alert>
						</v-col>
					</v-row>

					<v-row v-if="isLoading">
						<v-col cols="12">
							<v-progress-linear indeterminate color="primary"></v-progress-linear>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" sm="3">
							<strong>Status</strong>
							<p>{{ utilStatus1 || "--" }}</p>
						</v-col>
						<v-col cols="12" sm="3">
							<strong>Organization Name</strong>
							<p>{{ orgdetails.orgName || "--" }}</p>
						</v-col>
						<v-col cols="12" sm="3">
							<strong>Description</strong>
							<p>{{ orgdetails.description || "--" }}</p>
						</v-col>
						<v-col cols="12" sm="3">
							<strong>Contact Name</strong>
							<p>{{ orgdetails.contactName || "--" }}</p>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" sm="3">
							<strong>Contact Email</strong>
							<p>{{ orgdetails.email || "--" }}</p>
						</v-col>
						<v-col cols="12" sm="3">
							<strong>Phone</strong>
							<p>{{ orgdetails.phone || "--" }}</p>
						</v-col>
						<v-col cols="12" sm="3">
							<strong>Address Line 1</strong>
							<p>{{ orgdetails.address.line1 || "--" }}</p>
						</v-col>
						<v-col cols="12" sm="3">
							<strong>Address Line 2</strong>
							<p>{{ orgdetails.address.line2 || "--" }}</p>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" sm="3">
							<strong>City</strong>
							<p>{{ orgdetails.address.city || "--" }}</p>
						</v-col>
						<v-col cols="12" sm="3">
							<strong>State</strong>
							<p>{{ orgdetails.address.state || "--" }}</p>
						</v-col>
						<v-col cols="12" sm="3">
							<strong>Zipcode</strong>
							<p>{{ orgdetails.address.zipcode || "--" }}</p>
						</v-col>
						<v-col cols="12" sm="3">
							<strong>Country</strong>
							<p>{{ orgdetails.address.country || "--"  }}</p>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" sm="3">
							<strong>Billing Code 1</strong>
							<p>{{ orgdetails.billingCode0 || "--" }}</p>
						</v-col>
						<v-col cols="12" sm="3">
							<strong>Billing Code 2</strong>
							<p>{{ orgdetails.billingCode1 || "--" }}</p>
						</v-col>
						<v-col cols="12" sm="3">
							<strong>Billing Code 3</strong>
							<p>{{ orgdetails.billingCode2 || "--" }}</p>
						</v-col>
					</v-row>
				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import ApiService from '@/services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils'

export default {
	components: {
		Breadcrumb
	},
	data() {
		return {
			currOrgId: null,
			isLoading: false,
			fullPage: true,
			onCancel: true,
			height: 35,
			width: 35,
			types_list: [{ id: 'sp', value: 'SP' }, { id: 'msp', value: 'MSP' }],
			beingEditedOrgId: '',
			titleName: 'View',
			readonly: true,
			isEdit: false,
			statusKey1: 0, // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
			countsKey1: 1000, // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully

			userdetails: JSON.parse(localStorage.getItem('user-details')),
			orgdetails: {
				orgId: '',
				orgName: '',
				description: '',
				type: '',
				address: {
					line1: '',
					line2: '',
					city: '',
					state: '',
					country: '',
					zipcode: '',
				},
				contactName: '',
				email: '',
				phone: '',
				billingCode0: '',
				billingCode1: '',
				billingCode2: '',
				icon: '',
			},
			parent_pages: [{ name: 'Home' }, { name: 'Organizations', route: { name: 'ORGS' } },
			{ name: 'View', active: true }],
			apiStatus: null,
		};
	},

	mixins: [Utils],
	methods: {

		navigateToSites(row) {
			this.$router.push({ name: "SITES", query: [], params: { targetOrgId: row.orgId }, })
		},
		navigateToApplications(row) {
			this.$router.push({ name: "APPLICATIONS", query: [], params: { targetOrgId: row.orgId }, })
		},
		navigateToMonitoredDevices(row) {
			this.$router.push({ name: "MONITORED_DEVICES", query: [], params: { targetOrgId: row.orgId }, })
		},
		navigateToIncidents(row) {
			var states = this.utilsOpenStates;
			var query = [];
			query.push({ name: "state", value: states });
			console.log("navigateToIncidents query ", JSON.stringify(query));
			query.length ? this.$router.push({ name: "INCIDENTS", query: query, params: { targetOrgId: row.orgId }, }) :
				this.$router.push({ name: "INCIDENTS", params: { targetOrgId: row.orgId } });
		},
		cancel() {
			this.$router.go(-1);
		},
		updateOrgStatus(orgStsList) {
			let orgSts = orgStsList[0]
			this.orgdetails.highestSeverity = orgSts.highestSeverity
			this.orgdetails.incCount = orgSts.incCount
			this.orgdetails.faultStatus = orgSts.faultStatus
		},
		getMonitoredDevicesCount() {
			let query_params = {}, body = {};
			query_params.targetOrgId = this.beingEditedOrgId;

			this.isLoading = true
			body.orgIds = [this.beingEditedOrgId]

			ApiService.post("/svcInventory/api/v1/monitoredDevice/counts", body, (err, result) => {
				this.isLoading = false;
				if (err) {
					this.apiStatus = "Failed to fetch devices counts"
					//this.utilsCheckLogout(this.apiStatus);
				} else {
					this.apiStatus = null;
					this.orgdetails.monDevCount = result.data.data.length ? result.data.data[0].monDevCount : 0
					this.countsKey1 += 1; // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
				}
			}, query_params, "INVENTORY_SERVICE_URL"
			);
		},
		getOrganizationsStatusList() {
			let query_params = {}, body = {};
			query_params.targetOrgId = this.beingEditedOrgId;

			this.isLoading = true

			query_params.summaryDataType = this.UtilsModaConstants.FaultService.Summary.DataType.ORGS,
				query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.LISTS

			body = [this.beingEditedOrgId]

			ApiService.post("/svcSummary/api/v1/summary", body, (err, result) => {       //### svcFault
				this.isLoading = false;
				if (err) {
					this.apiStatus = "Failed to fetch orgs' fault status"
					//this.utilsCheckLogout(this.apiStatus);
				} else {
					this.apiStatus = null;
					this.updateOrgStatus(result.data.data)
					this.statusKey1 += 1; // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
					this.getMonitoredDevicesCount()
				}
			}, query_params, "SUMMARY_SERVICE_URL"   //###"FAULT_SERVICE_URL"
			);
		},
		getOrgDetails() {

			let query_params = {};
			query_params.targetOrgId = this.beingEditedOrgId;

			ApiService.get("/api/v1/org/" + this.beingEditedOrgId, (err, result) => {
				this.isLoading = false
				if (err) {
					this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
					this.utilsCheckLogout(this.apiStatus);
				} else if (result.data.data[0]) {
					this.apiStatus = null;
					this.orgdetails = result.data.data[0];
                    if ( this.orgdetails.billingCode ){
						this.orgdetails.billingCode0 = this.orgdetails.billingCode[0];
						this.orgdetails.billingCode1 = this.orgdetails.billingCode[1];
						this.orgdetails.billingCode2 = this.orgdetails.billingCode[2];
                    }
					this.utilStatus1 = this.orgdetails.adminStatus;
					this.titleName = "View - " + this.orgdetails.orgName
					this.getOrganizationsStatusList()
				}
			}, query_params)
		},
		processBreadcrumbMessage(selectedOrgId) {
			this.currOrgId = selectedOrgId;
			if (this.beingEditedOrgId != "" && this.beingEditedOrgId != undefined) {
				this.getOrgDetails();
			}
		},
	},
	mounted() {
    if ( this.$route.params && this.$route.params.targetOrgId ){
        this.$refs.breadcrumb.navigateToOrg(this.$route.params.targetOrgId)
        this.currOrgId = this.$route.params.targetOrgId
    } else
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
		this.beingEditedOrgId = this.$route.params.id;
		this.readonly = (this.$route.params.readonly == "1");
		if (this.beingEditedOrgId != "" && this.beingEditedOrgId != undefined) {
			this.isEdit = true;
			this.titleName = "View";
			this.getOrgDetails();
		}
	}
}

</script>


