<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="2" class="tile-box">
      <v-card-title class="page-title"><v-icon>mdi-map-marker-multiple</v-icon> {{ titleName }} Site
      </v-card-title>
      <v-card-text class="pb-0">
        <!-- begin create/edit form -->
        <v-container fluid class="pl-5 pr-5">
          <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
            {{ apiStatus }}
          </v-alert>
          <v-row :key="statusKey1">
            <v-col cols="3">
              <v-alert
                text
                class="link-alert"
                prominent
                type="warning"
                icon="mdi-ticket-confirmation"
                @click="navigateToIncidents(sitedetails)"
              > 
                <p class="mb-0">Incidents</p>
                <h3>
                  {{ sitedetails.incCount || 0 }}
                </h3>
              </v-alert>
            </v-col>
            <v-col cols="3">
              <v-alert
                text
                class="link-alert"
                prominent
                type="info"
                icon="mdi-apps"
                @click="navigateToApplications(sitedetails)"
              > 
                <p class="mb-0">Applications</p>
                <h3>
                  {{ sitedetails.applicationCount || 0 }}
                </h3>
              </v-alert>
            </v-col>
            <v-col cols="3">
              <v-alert
                text
                class="link-alert"
                prominent
                color="deep-purple"
                icon="mdi-router-wireless"
                @click="navigateToMonitoredDevices(sitedetails)"
              > 
                <p class="mb-0">Devices</p>
                <h3 :key="countsKey1">
                  {{ sitedetails.monDevCount || 0 }}
                </h3>
              </v-alert>
            </v-col>
          </v-row>
          <form ref="siteform" class="form-view">
            <v-row v-if="isLoading">
              <v-col cols="12">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="3">
                <strong>Site Name</strong>
                <p>{{ sitedetails.siteName || "--" }}</p>
              </v-col>
              <v-col cols="12" sm="3">
                <strong>Description</strong>
                <p>{{ sitedetails.description || "--" }}</p>
              </v-col>

              <v-col cols="12" sm="3">
                <strong>Region</strong>
                <p>{{ sitedetails.region || "--" }}</p>
              </v-col>

              <v-col cols="12" sm="3">
                <strong>Address Line 1</strong>
                <p>{{ sitedetails.address.line1 || "--" }}</p>
              </v-col>
              <v-col cols="12" sm="3">
                <strong>Address Line 2</strong>
                <p>{{ sitedetails.address.line2 || "--" }}</p>
              </v-col>
              <v-col cols="12" sm="3">
                <strong>City</strong>
                <p>{{ sitedetails.address.city || "--" }}</p>
              </v-col>
              <v-col cols="12" sm="3">
                <strong>Zipcode</strong>
                <p>{{ sitedetails.address.zipcode || "--" }}</p>
              </v-col>
              <v-col cols="12" sm="3">
                <strong>State</strong>
                <p>{{ sitedetails.address.state || "--" }}</p>
              </v-col>
              <v-col cols="12" sm="3">
                <strong>Country</strong>
                <p>{{ sitedetails.address.country || "--" }}</p>
              </v-col>
              <v-col cols="12" sm="3">
                <strong>Billing Code 1</strong>
                <p>{{ sitedetails.billingCode0 || "--" }}</p>
              </v-col>
              <v-col cols="12" sm="3">
                <strong>Billing Code 2</strong>
                <p>{{ sitedetails.billingCode1 || "--" }}</p>
              </v-col>
              <v-col cols="12" sm="3">
                <strong>Billing Code 3</strong>
                <p>{{ sitedetails.billingCode2 || "--" }}</p>
              </v-col>
            </v-row>

            <!-- Advanced settings -->

            <v-expansion-panels hover flat class="mt-2" :value="0">
              <v-expansion-panel>
                <v-expansion-panel-header color="#fbfbfb" expand-icon="mdi-chevron-down"> Advanced </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <!-- Telemetry settings -->

                  <template>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-switch dense v-model="sitedetails.mlSettings.telemetry.enabled"
                          :true-value="true" :false-value="false" label="Telemetry" inset :disabled="readonly">
                        </v-switch>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-switch dense v-model="sitedetails.mlSettings.telemetry.mlEnabled"
                          v-if="sitedetails.mlSettings.telemetry.enabled" :true-value="true" :false-value="false"
                          label="Telemetry-AI/ML (Disable,Update,Enable,Update to train immdly)" inset :disabled="readonly"></v-switch>
                      </v-col>
                      <!-- Telemetry config (if ML enabled ) -->
                      <v-col cols="12" md="4">
                        <v-autocomplete value="sitedetails.mlConfigParams.mlConfigTemplateName"
                          v-model="sitedetails.mlConfigParams.mlConfigTemplateId"
                          v-if="sitedetails.mlSettings.telemetry.mlEnabled" label="Internet Connectivity"
                          :items="mlConfigTemplates" item-text="mlConfigTemplateName" item-value="mlConfigTemplateId"
                          :disabled="readonly" @change="mlConfigTemplateChangeHandler" return-object />
                      </v-col>
                    </v-row>
                  </template>

                  <!-- Telemetry config (if ML enabled ) -->

                  <template  v-if="sitedetails.mlSettings.telemetry.mlEnabled">
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field dense v-model.number="sitedetails.mlSettings.telemetry.bucketIntervalMins" label="Bucket Interval (Minutes)" inset
                          :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Bucket Interval (Minutes)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field dense v-model.number="sitedetails.mlSettings.telemetry.trainingPeriodDays" label="Incr Training Period (Days)" inset
                          :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Training Period (Days)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field dense  v-model.number="sitedetails.mlSettings.telemetry.predictionBuckets" label="Prediction Buckets" inset
                          :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Prediction Buckets</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.downloadBandwidthMbps"
                          label="Download Speed (Mbps)" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Download Speed (Mbps)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.downloadLowerThresholdPct"
                          label="Download Speed Lower Threshold  (%)" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Download Speed Lower Threshold (%)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.downloadRangeMultiplier"
                          label="Download Speed Range Multiplier" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Download Speed Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.uploadBandwidthMbps"
                          label="Upload Speed (Mbps)" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Upload Speed (Mbps)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.uploadLowerThresholdPct"
                          label="Upload Speed Lower Threshold (%)" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Upload Speed Lower Threshold (%)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.uploadRangeMultiplier"
                          label="Upload Speed Range Multiplier" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Upload Speed Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.loss.upperThresholdPct"
                          label="Loss Upper Threshold (%)" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Loss Upper Threshold (%)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.loss.lossRangeMultiplier"
                          label="Loss Range Multiplier" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Loss Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.upperThresholdMilliSec"
                          label="Latency Upper Threshold (ms)" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Latency Upper Threshold (ms)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.latencyRangeMultiplier"
                          label="Latency Range Multiplier" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Latency Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.stdDevUpperThresholdMilliSec"
                          label="Latency Standard Dev Upper Threshold (ms)" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Latency Standard Dev Upper Threshold (ms)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.stdDevRangeMultiplier"
                          label="Latency Standard Dev Range Multiplier" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Latency Standard Dev Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.flowsUpperThresholdMilliSec"
                          label="Flows Latency Upper Threshold (ms)" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Flows Latency Upper Threshold (ms)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.flowsLatencyRangeMultiplier"
                          label="Flows Latency Range Multiplier" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Flows Latency Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.protocols.rspsReqsRatioLowerThreshold"
                          v-if="sitedetails.mlSettings.telemetry.mlEnabled"
                          label="Protocol Rsps/Reqs Ratio Lower Threshold" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Protocol Rsps/Reqs Ratio Lower Threshold</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.protocols.rspsReqsRatioRangeMultiplier"
                          v-if="sitedetails.mlSettings.telemetry.mlEnabled"
                          label="Protocol Rsps/Reqs Ratio Range Multiplier" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Protocol Rsps/Reqs Ratio Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.protocols.errRspsTotal"
                          v-if="sitedetails.mlSettings.telemetry.mlEnabled"
                          label="Protocol Total Error Rsps Upper Threshold" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Protocol Total Error Rsps Upper Threshold</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.protocols.errRspsTotalRangeMultiplier"
                          v-if="sitedetails.mlSettings.telemetry.mlEnabled"
                          label="Protocol Total Error Rsps Range Multiplier" inset :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Protocol Total Error Rsps Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                    </v-row>

                  </template>

                  <!-- Loganalysis settings -->

                  <template>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-switch dense v-model="sitedetails.mlSettings.logAnalysis.enabled"
                          :true-value="true" :false-value="false" label="LogAnalysis" inset :disabled="readonly">
                        </v-switch>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-switch dense v-model="sitedetails.mlSettings.logAnalysis.mlEnabled"
                          v-if="sitedetails.mlSettings.logAnalysis.enabled" :true-value="true" :false-value="false"
                          label="LogAnalysis-AI/ML" inset :disabled="readonly"></v-switch>
                      </v-col>
                    </v-row>
                  </template>

                  <!-- Loganalysis config (if ML enabled ) -->

                  <template v-if="sitedetails.mlSettings.logAnalysis.mlEnabled">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field dense label="Prediction Period (Minutes)" inset
                          v-model.number="sitedetails.mlSettings.logAnalysis.predictionPeriodMins"
                          :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Prediction Period (Minutes)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field dense label="Full Training Period (Days)" inset
                          v-model.number="sitedetails.mlSettings.logAnalysis.trainingPeriodDays"
                          :disabled="readonly" type="number">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Training Period (Days)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </form>
        </v-container>
        <!-- end create/edit form -->
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>

      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import Utils from "@/pages/moda/Utils";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      panelModel: ['advanced'],
      currOrgId: null,
      targetOrgId: null,
      isLoading: false,
      fullPage: true,
      onCancel: true,
      titleName: "View",
      siteId: "",
      isEdit: false,
      readonly: true,
      height: 35,
      width: 35,
      mlConfigTemplates: [],
      selectedTemplate: null,
      userdetails: JSON.parse(localStorage.getItem("user-details")),
      statusKey1: 0, // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
      countsKey1: 1000, // key attribute of elements that depend on counts data so they can be re-rendered forcefully

      // Since we are using these default values at few places throughout the code,
      // it's better to keep one master copy here and use (copy) them wherever needed so it's easy to change to
      // different values in future
      mlTelemetrySettingsDefaults: {
        enabled: true,
        mlEnabled: false,
        bucketIntervalMins: 1, // good to be same as application measurement intervals
        trainingPeriodDays: 1, // Trainig dataprep is incremental. So, only use last full day's data to incrementally
                               // update data and retrain the already existing model
        predictionBuckets: 10, // speedtest actual measurement is 4hrs(240 mins), flows interval is 10mins default.
                               // Both are optional and are not used in ML computations if not available
                               // Keep this atleast 10 because sometimes last bucket which is Anomaly may have empty paramList
                               // in which case, ML code takes paramList from previous Anomaly bucket(s)
      },
      mlLogAnalysisSettingsDefaults: {
        enabled: false,
        mlEnabled: false,
        trainingPeriodDays: 7, // Trainig is incremental but dataprep is not. So use full 7 days
        predictionPeriodMins: 10,
      },

      mlConfigParamsDefaults: {
        mlConfigTemplateId: "Custom",
        mlConfigTemplateName: "Custom",
        speedtest: {
          downloadBandwidthMbps: 100,
          downloadLowerThresholdPct: 10,
          downloadRangeMultiplier: 7,

          uploadBandwidthMbps: 20,
          uploadLowerThresholdPct: 10,
          uploadRangeMultiplier: 7,
        },
        latency: {
          upperThresholdMilliSec: 1000,
          latencyRangeMultiplier: 5,
          stdDevUpperThresholdMilliSec: 1000,
          stdDevRangeMultiplier: 6,
          flowsUpperThresholdMilliSec: 1000,
          flowsLatencyRangeMultiplier: 7,
        },
        loss: {
          upperThresholdPct: 75,
          lossRangeMultiplier: 5,
        },
        protocols: {
          rspsReqsRatioLowerThreshold: 0,
          rspsReqsRatioRangeMultiplier: 10,
          errRspsTotal: 150,
          errRspsTotalRangeMultiplier: 10,
        },
      },

      sitedetails: {
        siteId: "",
        siteName: "",
        location: {
          lat: "",
          long: "",
        },
        region: "",
        description: "",
        orgId: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          state: "",
          country: "",
          zipcode: "",
        },
        billingCode0: "",
        billingCode1: "",
        billingCode2: "",

        mlConfigParams: {}, // initialized to Detaults in mounted()

        // initialized to Defaults in mounted(0. For now, it just needs enabled flags as it's used in v-if everywhere in template
        mlSettings: {
          telemetry: {
            enabled: false,
          },
          logAnalysis: {
            enabled: false,
          },
        },
        /*
        mlConfigParams: {
          mlConfigTemplateName: "Custom",
          mlConfigTemplateId: "Custom",
          speedtest: {
            downloadBandwidthMbps: 100,
            downloadLowerThresholdPct: 10,
            downloadRangeMultiplier: 7,

            uploadBandwidthMbps: 20,
            uploadLowerThresholdPct: 10,
            uploadRangeMultiplier: 7,
          },
          latency: {
            upperThresholdMilliSec: 1000,
            latencyRangeMultiplier: 5,
            stdDevUpperThresholdMilliSec: 200,
            stdDevRangeMultiplier: 6,
            flowsUpperThresholdMilliSec: 1000,
            flowsLatencyRangeMultiplier: 7,
          },
          loss: {
            upperThresholdPct: 50,
            lossRangeMultiplier: 5,
          },
          protocols: {
            rspsReqsRatioLowerThreshold: 0.8,
            rspsReqsRatioRangeMultiplier: 10,
            errRspsTotal: 50,
            errRspsTotalRangeMultiplier: 10,
          },
        },
*/
      },
      parent_pages: [
        { name: "Home" },
        { name: "Sites", route: { name: "SITES" } },
        { name: "View", active: true },
      ],
      apiStatus: null,
    };
  },
  mixins: [Utils],
  computed: {
    telemetryEnabledHelper() {
      return this.sitedetails.mlSettings.telemetry.enabled;
    },
    logAnalysisEnabledHelper() {
      return this.sitedetails.mlSettings.logAnalysis.enabled;
    },
  }, // computed
  watch: {
    telemetryEnabledHelper() {
      if (!this.sitedetails.mlSettings.telemetry.enabled) {
        this.sitedetails.mlConfigParams = JSON.parse(
          JSON.stringify(this.mlConfigParamsDefaults)
        );
        this.sitedetails.mlSettings.telemetry = JSON.parse(
          JSON.stringify(this.mlTelemetrySettingsDefaults)
        );
      }
    },
    logAnalysisEnabledHelper() {
      if (!this.sitedetails.mlSettings.logAnalysis.enabled) {
        this.sitedetails.mlSettings.logAnalysis = JSON.parse(
          JSON.stringify(this.mlLogAnalysisSettingsDefaults)
        );
      }
    }
  },
  methods: {
    navigateToApplications(row) {
      console.log("navigateToApplications sitedetails ", JSON.stringify(row.siteId));
      // empty query is needed. Else, that component will use default query
      let query = [{ name: "site", value: { siteNameList: [row.siteName] } }]
      this.$router.push({ name: "APPLICATIONS", query: query })
    },
    navigateToMonitoredDevices(row) {
      console.log("navigateToMonitoredDevices sitedetails ", JSON.stringify(row.siteId));
      // empty query is needed. Else, that component will use default query
      let query = [{ name: "site", value: { siteNameList: [row.siteName] } }]
      this.$router.push({ name: "MONITORED_DEVICES", query: query })
    },
    navigateToIncidents(row) {
      var siteIds = [], siteNames = [], states = [];
      var query = [];

      states = this.utilsOpenStates;
      siteIds = Array.isArray(row) ? row.map((r) => r.siteId) : [row.siteId]
      siteNames = Array.isArray(row) ? row.map((r) => r.siteName) : [row.siteName]

      if (siteIds.length && siteNames.length)
        query.push({ name: "site", value: { siteIdList: siteIds, siteNameList: siteNames } });
      query.push({ name: "state", value: states });
      query.length ? this.$router.push({ name: "INCIDENTS", query: query }) : this.$router.push({ name: "INCIDENTS" });
    },
    cancel() {
      this.$router.go(-1);
    },
    cancelCreateSite() {
      this.$router.go(-1);
    },
    updateSiteStatus(siteStsList) {
      let siteSts = siteStsList[0]
      this.sitedetails.highestSeverity = siteSts.highestSeverity
      this.sitedetails.incCount = siteSts.incCount
      this.sitedetails.faultStatus = siteSts.faultStatus
    },
    getMonitoredDevicesCount() {
      let query_params = {}, body = {};
      query_params.targetOrgId = this.beingEditedOrgId;

      this.isLoading = true

      body.orgIds = [this.currOrgId]
      body.siteIds = [this.sitedetails.siteId]

      //console.log("getOrganizationsStatusList body " + JSON.stringify(body))

      ApiService.post("/svcInventory/api/v1/monitoredDevice/counts", body, (err, result) => {
        this.isLoading = false;
        if (err) {
          this.apiStatus = "Failed to fetch devices counts"
          //this.utilsCheckLogout(this.apiStatus);
        } else {
          this.apiStatus = null;
          //console.log("**** getMonitoredDevicesCount result = " + JSON.stringify(result, null, 2))
          // Output format
          //[{"orgId":"orgobj.1000","sites":[{"siteId":"sitobj.2000078_1fd8ba97-748f-4e00-ad71-05d6a246d73f","monDevCount":4},{"siteId":"sitobj.2000046_81222a6d-fe4f-472f-909c-19e63c8eb2ed","monDevCount":84}],"monDevCount":88},{"orgId":"orgobj.2001_68e21c61-014b-4bcb-9119-31607cd0bf3e","sites":[{"siteId":"sitobj.2000111_0feb497d-3bbe-4885-9a99-bc9ba2374ca7","monDevCount":1}],"monDevCount":1}]

          if (result.data.data[0] && result.data.data[0].sites[0])
            this.sitedetails.monDevCount = result.data.data[0].sites[0].monDevCount
          else this.sitedetails.monDevCount = 0
          this.countsKey1 += 1; // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
          //console.log("**** getMonitoredDevicesCount sitedetails = " + JSON.stringify(this.sitedetails, null, 2))
        }
      }, query_params, "INVENTORY_SERVICE_URL"
      );
    },

    getSitesStatusList() {
      let query_params = {}, body = {};
      if (this.targetOrgId) query_params.targetOrgId = this.targetOrgId;
      else if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      this.isLoading = true

      query_params.summaryDataType = this.UtilsModaConstants.FaultService.Summary.DataType.SITES,
        query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.LISTS

      body = [this.sitedetails.siteId]

      //console.log("getSitesStatusList body " + JSON.stringify(body))

      ApiService.post("/svcSummary/api/v1/summary", body, (err, result) => {    //### svcFault
        this.isLoading = false;
        if (err) {
          this.apiStatus = "Failed to fetch sites' fault status"
          //this.utilsCheckLogout(this.apiStatus);
        } else {
          this.apiStatus = null;
          //console.log("**** getSitesStatusList sites result = " + JSON.stringify(result, null, 2))
          this.updateSiteStatus(result.data.data)
          //console.log("**** getSitesStatusList sites = " + JSON.stringify(this.sitedetails, null, 2))
          this.statusKey1 += 1; // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
        }
      }, query_params, "SUMMARY_SERVICE_URL" //### "FAULT_SERVICE_URL"
      );
    },
    getSiteDetails() {
      let query_params = {};
      if (this.targetOrgId) query_params.targetOrgId = this.targetOrgId;
      else if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      ApiService.get("/api/v1/site/" + this.siteId, (err, result) => {
        //console.log("getSiteDetails " + JSON.stringify(result))
        if (err) {
          this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed")
          this.utilsCheckLogout(this.apiStatus);
        } else {
          this.apiStatus = null;
          this.sitedetails = result.data.data.objs[0];
          //console.log("getSiteDetails " + JSON.stringify(this.sitedetails))
          if (!this.sitedetails.mlSettings)
            // old site. Populate empty objs so UI wont fart
            this.sitedetails.mlSettings = { telemetry: {}, logAnalysis: {} };
          this.titleName = "View - " + this.sitedetails.siteName;
          this.getSitesStatusList();
          this.getMonitoredDevicesCount()
        }
      },
        query_params
      );
    },
    getMlConfigTemplates() {
      let query_params = {};
      if (this.targetOrgId) query_params.targetOrgId = this.targetOrgId;
      else if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      ApiService.get("/api/v1/mlConfigTemplate/", (err, result) => {
        if (err) {
          this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed")
          this.utilsCheckLogout(this.apiStatus);
        } else {
          // this.apiStatus = null;
          this.mlConfigTemplates = result.data.data.map(
            (t) => t
            /*  No need to remap..just a few more extra fields. This way, if we add more fields, we don't to come here and adjust
              {
            return {
              mlConfigTemplateId: String(t.mlConfigTemplateId),
              mlConfigTemplateName: String(t.mlConfigTemplateName),
              mlConfigParams: {
                latency: { ...t.mlConfigParams.latency },
                speedtest: { ...t.mlConfigParams.speedtest },
                loss: { ...t.mlConfigParams.loss },
                protocols: { ...t.mlConfigParams.protocols },
              },
            };
          }
*/
          );
          this.mlConfigTemplates.push(JSON.parse(JSON.stringify(this.mlConfigParamsDefaults))
            /*
          {
            mlConfigTemplateName: "Custom",
            mlConfigTemplateId: "Custom",
            mlConfigParams: {
              speedtest: {
                downloadBandwidthMbps: 400,
                downloadLowerThresholdPct: 10,
                downloadRangeMultiplier: 7,

                uploadBandwidthMbps: 30,
                uploadLowerThresholdPct: 10,
                uploadRangeMultiplier: 7,
              },
              latency: {
                upperThresholdMilliSec: 1000,
                latencyRangeMultiplier: 5,
                stdDevUpperThresholdMilliSec: 200,
                stdDevRangeMultiplier: 6,
                flowsUpperThresholdMilliSec: 1000,
                flowsLatencyRangeMultiplier: 7,
              },
              loss: {
                upperThresholdPct: 50,
                lossRangeMultiplier: 5,
              },
              protocols: {
                rspsReqsRatioLowerThreshold: 0.8,
                rspsReqsRatioRangeMultiplier: 10,
                errRspsTotal: 0,
                errRspsTotalRangeMultiplier: 0,
              },
            },
          }
*/
          );
          // console.log("mlConfigTemplates", this.mlConfigTemplates);
        }
      },
        query_params
      );
    },
    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      if (this.siteId != "" && this.siteId != undefined) {
        // Edit can't be done when org is changed, so g back
        this.cancelCreateSite();
      }
    },

    mlConfigTemplateChangeHandler(e) {
      let t = JSON.parse(JSON.stringify(e));
      //console.log("mlConfigTemplateChangeHandler event")
      //console.log(JSON.stringify(e))
      this.sitedetails.mlConfigParams = t.mlConfigParams
        ? { ...t.mlConfigParams, mlConfigTemplateId: String(t.mlConfigTemplateId), mlConfigTemplateName: String(t.mlConfigTemplateName), }
        : t; // Custom obj doesn't have mlConfigParams nested. It's not something we fetched from backend.
      // Hence for Custom , take the top level objects

      /*
      console.log("mlConfigTemplateChangeHandler mlConfigParams") 
      console.log(JSON.stringify(this.sitedetails.mlConfigParams))
      this.selectedTemplate = {
        ...t.mlConfigParams,
        mlConfigTemplateId: String(t.mlConfigTemplateId),
        mlConfigTemplateName: String(t.mlConfigTemplateName),
      };
      console.log(JSON.stringify(this.selectedTemplate))
*/
    },

    setMlConfigAsCustom() {
      //this.sitedetails.mlConfigParams.mlConfigTemplateName = "Custom"; // Name will be automatically
      this.sitedetails.mlConfigParams.mlConfigTemplateId = "Custom";
    },
  },
  mounted() {
    this.getMlConfigTemplates();
    this.sitedetails.mlConfigParams = JSON.parse(JSON.stringify(this.mlConfigParamsDefaults));
    this.sitedetails.mlSettings.telemetry = JSON.parse(JSON.stringify(this.mlTelemetrySettingsDefaults));
    this.sitedetails.mlSettings.logAnalysis = JSON.parse(JSON.stringify(this.mlLogAnalysisSettingsDefaults));
    if ( this.$route.params && this.$route.params.targetOrgId ){
        this.$refs.breadcrumb.navigateToOrg(this.$route.params.targetOrgId)
        this.currOrgId = this.$route.params.targetOrgId
    } else
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    this.targetOrgId = this.$route.params.targetOrgId;
    this.siteId = this.$route.params.siteId;
    this.readonly = true;
    if (this.siteId != "" && this.siteId != undefined) {
      this.titleName = "View";
      this.getSiteDetails();
    }
  },
};
</script>
