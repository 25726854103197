<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-list-box-outline</v-icon> Device Config Job {{ titleName }}
			</v-card-title>
			<v-card-text class="pb-0">
				<v-container fluid class="pl-5 pr-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<form ref="deviceconfigjobform" :class="readonly ? 'form-view' : ''">
						<v-row v-if="isLoading">
							<v-col cols="12">
								<v-progress-linear indeterminate color="primary"></v-progress-linear>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols=12 md=6>
								<v-text-field dense v-model="deviceconfigjobdetails.jobName" :error-messages="nameErrors"
									@input="$v.deviceconfigjobdetails.jobName.$touch()" @blur="$v.deviceconfigjobdetails.jobName.$touch()"
									class="moda-required" label="Config Job Name" :disabled="readonly" />
							</v-col>
							<v-col cols=12 md=6>
								<v-text-field dense v-model="deviceconfigjobdetails.description" label="Description" :disabled="readonly">
								</v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols=12>
								<div class="search-wrapper">
									<v-text-field v-model="device_search"  prepend-inner-icon="mdi-magnify" label="Search">
								</v-text-field>
								</div>
								
								<v-data-table v-model="selected_devices" :headers="device_table_columns" :search="device_search"
									:items="all_devices" item-key="deviceId" show-select :error-messages="devicesErrors"
									@input="$v.selected_devices.$touch()" @blur="$v.selected_devices.$touch()" class="moda-required"
									:disabled="readonly" @toggle-select-all="selectAllToggle">

									<template v-slot:header.data-table-select="{ props, on }">
										<v-simple-checkbox @input="on.input($event)" :value="props.value"
											:indeterminate="props.indeterminate" :disabled="readonly"></v-simple-checkbox>
									</template>

									<template v-slot:item.data-table-select="{ item, isSelected, select }">
										<v-simple-checkbox v-if="isDeviceRegistered(item)" :value="isSelected" @input="select($event)"
											:disabled="readonly"></v-simple-checkbox>
									</template>

									<template v-slot:item.deviceName="{ item }">
										<a class="link-text" v-on:click="navigateToDevice(item.deviceId)"> {{item.deviceName}} </a>
									</template>
									<template v-slot:item.siteName="{ item }">
										<a class="link-text" v-on:click="navigateToSite(item.siteId)"> {{item.siteName}} </a>
									</template>
									<template v-slot:item.configProfiles="{ item }">
										<a class="link-text" v-for="cProf in item.configProfiles" :key="cProf.type"
											v-on:click="navigateToConfigProfile(cProf.type, cProf.value.configProfileId)">
											{{cProf.value.configProfileName}} - v{{cProf.value.configProfileVersion}}<br></a>
									</template>
									<template v-slot:item.statuses="{ item }">
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon v-bind="attrs" v-on="on"
													:color="utilsOperStatusDisplayObject(item.operStatus).colorName">
													{{utilsOperStatusDisplayObject(item.operStatus).icon}}
												</v-icon>
											</template>
											<span>{{utilsOperStatusDisplayObject(item.operStatus).text}}</span>
										</v-tooltip>
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon v-bind="attrs" v-on="on"
													:color="utilsLiveStatusDisplayObject(item.liveStatus).colorName">
													{{utilsLiveStatusDisplayObject(item.liveStatus).icon}}
												</v-icon>
											</template>
											<span>{{utilsLiveStatusDisplayObject(item.liveStatus).text}}</span>
										</v-tooltip>
									</template>
									<!--
											<template v-slot:item.operStatus="{ item }">
													<v-tooltip top>
															<template v-slot:activator="{ on, attrs }">
																	<v-icon v-bind="attrs" v-on="on" small :class="utilsOperStatusDisplayObject(item.operStatus).class">
																			{{utilsOperStatusDisplayObject(item.operStatus).icon}}
																	</v-icon>
															</template>
															<span>{{utilsOperStatusDisplayObject(item.operStatus).text}}</span>
													</v-tooltip>
											</template>
											<template v-slot:item.liveStatus="{ item }">
													<v-tooltip top>
															<template v-slot:activator="{ on, attrs }">
																	<v-icon v-bind="attrs" v-on="on" small :class="utilsLiveStatusDisplayObject(item.liveStatus).class">
																			{{utilsLiveStatusDisplayObject(item.liveStatus).icon}}
																	</v-icon>
															</template>
															<span>{{utilsLiveStatusDisplayObject(item.liveStatus).text}}</span>
													</v-tooltip>
											</template>
-->
								</v-data-table>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols=12 md=3>
								<strong>Schedule Options</strong>
								<v-radio-group v-model="deviceconfigjobdetails.jobScheduleType" class="moda-required"
									:error-messages="jobScheduleTypeErrors" @change="$v.deviceconfigjobdetails.jobScheduleType.$touch()">
									<v-row>
										<v-col cols=12 md=6>
											<v-radio key="Now" label="Now" :value="UtilsModaConstants.ModaScheduleType.IMMEDIATE"
												:disabled="readonly" />
										</v-col>
										<v-col cols=12 md=6>
											<v-radio key="Future" label="Future" :value="UtilsModaConstants.ModaScheduleType.SCHEDULED"
												:disabled="readonly" />
										</v-col>
									</v-row>
								</v-radio-group>

								<v-menu v-if="deviceconfigjobdetails.jobScheduleType == UtilsModaConstants.ModaScheduleType.SCHEDULED"
									v-model="scheduledAtMenu" :close-on-content-click="false" :nudge-right="40"
									transition="scale-transition" offset-y min-width="290px" :disabled="readonly">

									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="scheduledAt" label="Schedule at" prepend-icon="mdi-calendar" readonly
											v-bind="attrs" v-on="on" class="moda-required" :error-messages="scheduledAtErrors"
											@input="$v.scheduledAt.$touch()" @blur="$v.scheduledAt.$touch()" />
									</template>

									<!--  time-picker has a bug. When menu pops up 2nd time onwards, it gets stuck in minutes.
															Needs to forcefully set selectingHour = true -->

									<v-date-picker v-model="scheduledAtDate" :min="nowDate"
										@change="$refs.timepicker && ($refs.timepicker.selectingHour = true)" no-title scrollable />

									<v-time-picker ref="timepicker" v-model="scheduledAtTime" :min="nowTime" format="24hr"
										@change="scheduledAtMenu = false;$refs.timepicker && ($refs.timepicker.selectingHour = true)"
										no-title scrollable />
								</v-menu>
							</v-col>
						</v-row>
					</form>
				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="!readonly && !isEdit" @click="submit" :disabled="this.$v.$invalid">
					Create</v-btn>
				<v-btn rounded outlined color="success" v-if="!readonly && isEdit" @click="submit" :disabled="this.$v.$invalid">
					Update</v-btn>
				<v-btn rounded outlined color="error" v-if="!readonly" @click="cancel">Cancel</v-btn>
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>

	</div>
</template>
<script>

import ApiService from '@/services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {

            allowMultipleTargetSelection: false, // set to true to allow multiple selections for target

            currOrgId: null,
            targetOrgId: null,

            isLoading: false,
            fullPage: true,
            titleName:'Create',

            deviceConfigJobId:"",
            isEdit:false,
            readonly:true,

            device_search : '',
            device_table_columns : [
                {
                    text: 'Device',
                    align: 'start',
                    sortable: true,
                    value: 'deviceName',
                },
                {
                    text: 'Model',
                    align: 'start',
                    sortable: true,
                    value: 'deviceModel.deviceModelName',
                },
                {
                    text: 'Unique ID',
                    align: 'start',
                    sortable: true,
                    value: 'macAddress',
                },
                {
                    text: 'Serial #',
                    align: 'start',
                    sortable: true,
                    value: 'serialNumber',
                },
                {
                    text: 'Site',
                    align: 'start',
                    sortable: true,
                    value: 'siteName',
                },
                {
                    text: 'Profiles to be deployed',
                    align: 'start',
                    sortable: true,
                    value: 'configProfiles',
                },
                {
                    text: 'Statuses',
                    align: 'start',
                    sortable: true,
                    value: 'statuses',
                },
/*
                {
                    text: 'Regn Status',
                    align: 'start',
                    sortable: true,
                    value: 'operStatus',
                },
                {
                    text: 'Liveness',
                    align: 'start',
                    sortable: true,
                    value: 'liveStatus',
                },
*/
            ],
            all_devices:[],
            num_eligible_devices: 0,
            selected_devices:[],

            height:35,
            width:35,
            userdetails:JSON.parse(localStorage.getItem('user-details')),
            deviceconfigjobdetails:{
                jobType: '', // this.UtilsModaConstants.ModaJobType.DEVICECONFIGJOB,
                jobId :'',
                jobName :'',
                description :'',
                deviceIds: [],
                jobFrequency :'',
                jobScheduleType :'',
                //scheduledAt :'',
                jobScheduleInfo: { "offset":7000, "SCHEDULED" : { "runAt" : 1619382341523 } },
                jobData:[],
            },
            scheduledAtMenu: false,
            scheduledAt:'',
            scheduledAtDate: this.utilsFormatDateTime2().split(' ')[0],
            scheduledAtTime: this.utilsFormatDateTime2().split(' ')[1],
            nowDate: this.utilsFormatDateTime2().split(' ')[0],
            nowTime: this.utilsFormatDateTime2().split(' ')[1],

            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Profiles & Jobs"},
                           {name:'Device Config Jobs', route: { name: "DEVICE_CONFIG_JOBS" }},
                           {name:"Create/Edit", active:true} ],
            apiStatus: null,
        };
    },

    watch: {
        scheduledAtDate : function(val) {
            this.scheduledAt = val + " " + this.scheduledAtTime;
            //console.log("this.scheduledAt " + this.scheduledAt);
            if ( val != this.nowDate ) delete this.nowTime
            else this.nowTime = this.utilsFormatDateTime2().split(' ')[1]
        },
        scheduledAtTime : function(val) {
            this.scheduledAt = this.scheduledAtDate + " " + val;
            //console.log("this.scheduledAt " + this.scheduledAt);
        },
    },

    mixins: [validationMixin,
            Utils ],

    validations: {
        deviceconfigjobdetails : {
            jobName: { required, minLength: minLength(3) },
            jobScheduleType: { required },
        },
        selected_devices: { required },
        scheduledAt: { required: requiredIf( function() { return this.deviceconfigjobdetails.jobScheduleType ==
                                                                 this.UtilsModaConstants.ModaScheduleType.SCHEDULED;}), },
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.deviceconfigjobdetails.jobName.$dirty) return errors
            !this.$v.deviceconfigjobdetails.jobName.required && errors.push('Name is required.')
            !this.$v.deviceconfigjobdetails.jobName.minLength && errors.push('Name must be atleast 3 characters long')
            return errors
        },
        devicesErrors () {
            const errors = []
            if (!this.$v.selected_devices.$dirty) return errors
            !this.$v.selected_devices.required && errors.push('Device selection required.')
            return errors
        },
        jobScheduleTypeErrors () {
            const errors = []
            if (!this.$v.deviceconfigjobdetails.jobScheduleType.$dirty) return errors
            !this.$v.deviceconfigjobdetails.jobScheduleType.required && errors.push('Schedule type required.')
            return errors
        },
        scheduledAtErrors () {
            const errors = []
            if (!this.$v.scheduledAt.$dirty) return errors
            !this.$v.scheduledAt.required && errors.push('Scheduled time required.')
            return errors
        },
    },

    methods: {

        isDeviceRegistered (device) {
            return device.operStatus == this.UtilsModaConstants.DeviceOperStatus.REGISTERED
        },
        selectAllToggle(props) {
            if(this.selected_devices.length != this.num_eligible_devices ) {
                this.selected_devices = [];
                const self = this;
                props.items.forEach(item => {
                    if(this.isDeviceRegistered(item)) {
                        self.selected_devices.push(item);
                    }
                });
            } else this.selected_devices = [];
        },
        submit () {
            this.$v.$touch()

            this.deviceconfigjobdetails.jobType = this.UtilsModaConstants.ModaJobType.DEVICECONFIGJOB;
            this.deviceconfigjobdetails.jobName = this.deviceconfigjobdetails.jobName.trim();
            // this.deviceconfigjobdetails.devcfgs
            this.deviceconfigjobdetails.jobData = this.selected_devices.map((d) => {
                if ( d )  {
                    d.configProfiles = d.configProfiles.filter(p => p.value);
                    var configProfiles = d.configProfiles.map(p => { return {configProfileType: p.type, configProfileId: p.value.configProfileId,
                                                                              configProfileVersion: p.value.configProfileVersion} } );
                    return {deviceId: d.deviceId,  cpList:configProfiles };
                } else return null;
            })

            this.deviceconfigjobdetails.scheduledAt = 
                (this.deviceconfigjobdetails.jobScheduleType == this.UtilsModaConstants.ModaScheduleType.SCHEDULED)
                                                     ? new Date(this.scheduledAt).getTime() : null;
            this.deviceconfigjobdetails.jobScheduleInfo.SCHEDULED.runAt = new Date(this.scheduledAt).getTime() ;
            //console.log("submit*****"+JSON.stringify(this.deviceconfigjobdetails))

            this.isLoading=true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            this.deviceconfigjobdetails.jobType = this.UtilsModaConstants.ModaJobType.DEVICECONFIGJOB;
            this.deviceconfigjobdetails.jobType = "DeviceConfigJob" ;

            if ( this.isEdit ){
                ApiService.put( 
                    //"/mcpsvc/api/v1/deviceConfigJob/"+this.deviceConfigJobId,
                    "/jobsvc/api/v1/jobs/"+this.deviceConfigJobId,
                    this.deviceconfigjobdetails, (err, result) => {
                    //console.log("*update*"+JSON.stringify(result))
                    this.isLoading=false;
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateDeviceConfigJob()
                    }
                }, query_params, "JOBS_SERVICE_URL")
            }else {// create
                console.log("create*****"+JSON.stringify(this.deviceconfigjobdetails))
                console.log("create*****"+JSON.stringify(this.deviceconfigjobdetails.jobType))
                ApiService.post(
                    //"/mcpsvc/api/v1/deviceConfigJob",
                    "/jobsvc/api/v1/jobs",
                    this.deviceconfigjobdetails, (err, result) => {
                    //console.log("*create*"+JSON.stringify(err) + " " + JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateDeviceConfigJob()
                    }
                }, query_params, "JOBS_SERVICE_URL")
            }

        },
        cancel () {
            this.$v.$reset()
            this.cancelCreateDeviceConfigJob()
        },
        navigateToDevice(id){
            var readonly = 1
            this.$router.push({name : "MODA_AGENTS_VIEW", params: { id: id } } )
        },
        navigateToSite(id){
            var readonly = 1
            this.$router.push({ name: "SITES_VIEW", params: { siteId: id } })
        },
        navigateToConfigProfile(type, id){
            var readonly = 1
            if ( type == this.UtilsModaConstants.ConfigProfileType.ANALYTICS )
                this.$router.push( { name : "ANAL_CONFIG_PROFILES_VIEW", params : { analyticsConfigProfileId: id, readonly: readonly}})
            else if ( type == this.UtilsModaConstants.ConfigProfileType.SYSTEM )
                this.$router.push( { name : "SYS_CONFIG_PROFILES_EDIT", params : { systemConfigProfileId: id, readonly: readonly}})
        },
        cancelCreateDeviceConfigJob(){
            this.$router.go(-1);
        },



        getDeviceConfigJobDetails(){
            let query_params = {};
            if ( this.targetOrgId ) query_params.targetOrgId = this.targetOrgId;
            else if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            //ApiService.get("/mcpsvc/api/v1/deviceConfigJob/"+this.deviceConfigJobId, 
            ApiService.get("/jobsvc/api/v1/jobs/"+this.deviceConfigJobId, 
                (err, result) => {
                //console.log("deviceconfigjob " + JSON.stringify(result))
                if ( err ) { 
                    console.log("deviceconfigjob err: " + this.deviceConfigJobId + 
                                                    " " + JSON.stringify(err))
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.deviceconfigjobdetails = result.data.data[0];
                    this.selected_devices = this.deviceconfigjobdetails.devices;
                    //var dateStr = this.utilsFormatDateTime2(this.deviceconfigjobdetails.scheduledAt);
                    var dateStr = this.utilsFormatDateTime2(this.deviceconfigjobdetails.jobScheduleInfo.SCHEDULED.runAt);
                    this.scheduledAt = dateStr ; 
                    var dateStrs = dateStr.split(' ');
                    this.scheduledAtDate = dateStrs[0];
                    this.scheduledAtTime = dateStrs[1];

                    //console.log("dateStrs " + JSON.stringify(dateStrs) + " dateStr " + dateStr);
                    this.titleName = (this.readonly ? "View - " : "Edit - ") +this.deviceconfigjobdetails.jobName
                }
            }, query_params, "JOBS_SERVICE_URL")
        },

        getConfigProfiles(eligible_devices){
            this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            var deviceIds = eligible_devices.map(x => x.deviceId);

            ApiService.post("/api/v1/deviceConfigMap/devices/configs", { deviceIds: deviceIds }, (err, result) => {
                //console.log(JSON.stringify(result))
                this.isLoading=false
                if ( err ) {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    var devices = result.data.data;

                    //console.log("getConfigProfiles devices cfgProfiles " + JSON.stringify(devices));

                    // hook retrieved cfgProfiles array to corresponding device in all_devices
                    devices.forEach ( x => {
                            if ( x.configProfiles.length ) {
                                var dev = this._all_devices.find(y => x.deviceId == y.deviceId);
                                if ( dev ) dev.configProfiles = x.configProfiles;
                            }
                        }
                    )

                    this.all_devices = this._all_devices;
                    delete this._all_devices;

                    //console.log("getConfigProfiles all_devices " + JSON.stringify(this.all_devices));

                    if(this.deviceConfigJobId != "" && this.deviceConfigJobId !=undefined)
                        this.getDeviceConfigJobDetails();
                }
            }, query_params, "API_SERVICE_URL")
        },

        getDevicesLiveStatus(){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            var body = { }
            body.deviceIds = this._all_devices.map(d => d.deviceId);

            ApiService.post("/svcStatus/api/v1/status/device", body, (err, result) => {
                //console.log(JSON.stringify(result))
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //console.log("getDevicesLiveStatus " + JSON.stringify(result.data.data));
                    var liveStatuses = result.data.data.devicesStatusList
                    // merge liveStatuses into all_devices
                    this._all_devices.forEach(dt => {
                        var d = liveStatuses.find(ld => ld.deviceId == dt.deviceId);
                        if ( d ) dt.liveStatus = d.deviceLiveStatus
                    })


                    var eligible_devices = this._all_devices.filter(x => this.isDeviceRegistered(x));
                    this.num_eligible_devices = eligible_devices.length;

                    if ( this.num_eligible_devices ){
                        this.getConfigProfiles(eligible_devices);
                    } else if(this.deviceConfigJobId != "" && this.deviceConfigJobId !=undefined){
                        this.all_devices =  this._all_devices;
                        this.getDeviceConfigJobDetails();
                    }
                }
            }, query_params, "STATUS_SERVICE_URL")
        },
        getDevicesList(){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/device", (err, result) => {
                //console.log(JSON.stringify(result))
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this._all_devices = result.data.data;
                    //console.log("getDevicesList " + JSON.stringify(this._all_devices))
                    this.getDevicesLiveStatus();
                }
            }, query_params, "API_SERVICE_URL")
        },


        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            if(this.deviceConfigJobId != "" && this.deviceConfigJobId !=undefined){
                // Edit can't be done when org is changed, so g back
                this.cancelCreateDeviceConfigJob();
            }else
                this.getDevicesList()
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.targetOrgId = this.$route.params.targetOrgId;
        this.deviceConfigJobId = this.$route.params.deviceConfigJobId;
        this.readonly = (this.$route.params.readonly == "1");
        this.getDevicesList()
        if(this.deviceConfigJobId != "" && this.deviceConfigJobId !=undefined){
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
        }
    }
}

</script>



