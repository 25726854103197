<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="2" class="tile-box">
      <v-card-title class="page-title"><v-icon>mdi-map-marker-multiple</v-icon> {{ titleName }} Site
        <v-btn v-if="isEdit" class="status-button" :color="utilsGetSeverityColor(sitedetails.highestSeverity).colorName" rounded outlined right absolute>
          Status: <v-icon>{{ utilsFaultStatusDisplayObject(sitedetails.faultStatus).icon }}</v-icon><strong>{{ sitedetails.highestSeverity ?
            sitedetails.highestSeverity :utilsFaultStatusDisplayObject(sitedetails.faultStatus).text }} </strong>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <!-- begin create/edit form -->
        <v-container fluid class="pl-5 pr-5">
          <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
            {{ apiStatus }}
          </v-alert>
          <v-row v-if="isEdit" :key="statusKey1">
            <v-col cols="3">
              <v-alert
                text
                class="link-alert"
                prominent
                type="warning"
                icon="mdi-ticket-confirmation"
                @click="navigateToIncidents(sitedetails)"
              > 
                <p class="mb-0">Incidents</p>
                <h3>
                  {{ sitedetails.incCount || 0 }}
                </h3>
              </v-alert>
            </v-col>
            <v-col cols="3">
              <v-alert
                text
                class="link-alert"
                prominent
                type="info"
                icon="mdi-apps"
                @click="navigateToApplications(sitedetails)"
              > 
                <p class="mb-0">Applications</p>
                <h3>
                  {{ sitedetails.applicationCount || 0 }}
                </h3>
              </v-alert>
            </v-col>
            <v-col cols="3">
              <v-alert
                text
                class="link-alert"
                prominent
                color="deep-purple"
                icon="mdi-router-wireless"
                @click="navigateToMonitoredDevices(sitedetails)"
              > 
                <p class="mb-0">Devices</p>
                <h3 :key="countsKey1">
                  {{ sitedetails.monDevCount || 0 }}
                </h3>
              </v-alert>
            </v-col>
          </v-row>
          <form ref="siteform">
            <v-row v-if="isLoading">
              <v-col cols="12">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" sm="12">
                <v-text-field class="moda-required" dense v-model="sitedetails.siteName" :error-messages="nameErrors"
                  @input="$v.sitedetails.siteName.$touch()" @blur="$v.sitedetails.siteName.$touch()" label="Site Name"
                  :disabled="readonly" />
              </v-col>
              <v-col cols="12" md="4" sm="12">
                <v-text-field dense v-model="sitedetails.description" label="Description" :disabled="readonly">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="12">
                <v-text-field dense v-model="sitedetails.region" label="Region" :disabled="readonly"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field dense v-model="sitedetails.address.line1" label="Address Line 1" :disabled="readonly">
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field dense v-model="sitedetails.address.line2" label="Address Line 2" :disabled="readonly">
                </v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field dense class="moda-required" v-model="sitedetails.address.city"
                  :error-messages="addressCityErrors" label="City" @input="$v.sitedetails.address.city.$touch()"
                  @blur="$v.sitedetails.address.city.$touch()" :disabled="readonly" />
              </v-col>
              <v-col cols=12 md=2>
                <v-text-field dense v-model="sitedetails.address.zipcode" label="Zipcode" :disabled="readonly"></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field dense v-model="sitedetails.address.state" label="State" :disabled="readonly"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field dense v-model="sitedetails.address.country" label="Country" :disabled="readonly"></v-text-field>
              </v-col>

              <v-col cols="12" md="2" sm="12">
                <v-text-field dense v-model="sitedetails.billingCode0" label="Billing Code 1"
                  :disabled="readonly"></v-text-field>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <v-text-field dense v-model="sitedetails.billingCode1" label="Billing Code 2"
                  :disabled="readonly"></v-text-field>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <v-text-field dense v-model="sitedetails.billingCode2" label="Billing Code 3"
                  :disabled="readonly"></v-text-field>
              </v-col>
            </v-row>

            <!-- Advanced settings -->

            <v-expansion-panels hover flat class="mt-2">
              <v-expansion-panel>
                <v-expansion-panel-header color="#fbfbfb" expand-icon="mdi-chevron-down"> Advanced </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <!-- Telemetry settings -->

                  <template>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-switch dense v-model="sitedetails.mlSettings.telemetry.enabled"
                          :true-value="true" :false-value="false" label="Telemetry" inset :disabled="readonly">
                        </v-switch>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-switch dense v-model="sitedetails.mlSettings.telemetry.mlEnabled"
                          v-if="sitedetails.mlSettings.telemetry.enabled" :true-value="true" :false-value="false"
                          label="Telemetry-AI/ML (Disable,Update,Enable,Update to train immdly)" inset :disabled="readonly"></v-switch>
                      </v-col>
                      <!-- Telemetry config (if ML enabled ) -->
                      <v-col cols="12" md="4">
                        <v-autocomplete dense value="sitedetails.mlConfigParams.mlConfigTemplateName"
                          v-model="sitedetails.mlConfigParams.mlConfigTemplateId"
                          v-if="sitedetails.mlSettings.telemetry.mlEnabled" label="Internet Connectivity"
                          :items="mlConfigTemplates" item-text="mlConfigTemplateName" item-value="mlConfigTemplateId"
                          :disabled="readonly" @change="mlConfigTemplateChangeHandler" return-object />
                      </v-col>
                    </v-row>
                  </template>

                  <!-- Telemetry config (if ML enabled ) -->

                  <template  v-if="sitedetails.mlSettings.telemetry.mlEnabled">
                    <v-row>
                      <v-col cols="12" md="2">
                        <v-text-field dense v-model.number="sitedetails.mlSettings.telemetry.bucketIntervalMins" label="Bucket Interval (Minutes)" inset
                          :disabled="readonly" type="number" :error-messages="telemetryBucketIntervalMinsErrors"
                          @input="$v.sitedetails.mlSettings.telemetry.bucketIntervalMins.$touch()"
                          @blur="$v.sitedetails.mlSettings.telemetry.bucketIntervalMins.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Bucket Interval (Minutes)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field dense v-model.number="sitedetails.mlSettings.telemetry.trainingPeriodDays" label="Incr Training Period (Days)" inset
                          :disabled="readonly" type="number" :error-messages="telemetryTrainingPeriodErrors"
                          @input="$v.sitedetails.mlSettings.telemetry.trainingPeriodDays.$touch()"
                          @blur="$v.sitedetails.mlSettings.telemetry.trainingPeriodDays.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Training Period (Days)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field dense  v-model.number="sitedetails.mlSettings.telemetry.predictionBuckets" label="Prediction Buckets" inset
                          :disabled="readonly" type="number" :error-messages="telemetryMlPredictionBucketsErrors"
                          @input="$v.sitedetails.mlSettings.telemetry.predictionBuckets.$touch()"
                          @blur="$v.sitedetails.mlSettings.telemetry.predictionBuckets.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Prediction Buckets</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.downloadBandwidthMbps"
                          label="Download Speed (Mbps)" inset :disabled="readonly" type="number"
                          :error-messages="downloadBandwidthMbpsErrors"
                          @input="$v.sitedetails.mlConfigParams.speedtest.downloadBandwidthMbps.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.speedtest.downloadBandwidthMbps.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Download Speed (Mbps)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.downloadLowerThresholdPct"
                          label="Download Speed Lower Threshold  (%)" inset :disabled="readonly" type="number"
                          :error-messages="downloadLowerThresholdPctErrors"
                          @input="$v.sitedetails.mlConfigParams.speedtest.downloadLowerThresholdPct.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.speedtest.downloadLowerThresholdPct.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Download Speed Lower Threshold (%)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.downloadRangeMultiplier"
                          label="Download Speed Range Multiplier" inset :disabled="readonly" type="number"
                          :error-messages="downloadRangeMultiplierErrors"
                          @input="$v.sitedetails.mlConfigParams.speedtest.downloadRangeMultiplier.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.speedtest.downloadRangeMultiplier.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Download Speed Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.uploadBandwidthMbps"
                          label="Upload Speed (Mbps)" inset :disabled="readonly" type="number"
                          :error-messages="uploadBandwidthMbpsErrors"
                          @input="$v.sitedetails.mlConfigParams.speedtest.uploadBandwidthMbps.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.speedtest.uploadBandwidthMbps.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Upload Speed (Mbps)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.uploadLowerThresholdPct"
                          label="Upload Speed Lower Threshold (%)" inset :disabled="readonly" type="number"
                          :error-messages="uploadLowerThresholdPctErrors"
                          @input="$v.sitedetails.mlConfigParams.speedtest.uploadLowerThresholdPct.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.speedtest.uploadLowerThresholdPct.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Upload Speed Lower Threshold (%)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.speedtest.uploadRangeMultiplier"
                          label="Upload Speed Range Multiplier" inset :disabled="readonly" type="number"
                          :error-messages="uploadRangeMultiplierErrors"
                          @input="$v.sitedetails.mlConfigParams.speedtest.uploadRangeMultiplier.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.speedtest.uploadRangeMultiplier.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Upload Speed Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.loss.upperThresholdPct"
                          label="Loss Upper Threshold (%)" inset :disabled="readonly" type="number"
                          :error-messages="lossUpperThresholdPctErrors"
                          @input="$v.sitedetails.mlConfigParams.loss.upperThresholdPct.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.loss.upperThresholdPct.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Loss Upper Threshold (%)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.loss.lossRangeMultiplier"
                          label="Loss Range Multiplier" inset :disabled="readonly" type="number"
                          :error-messages="lossRangeMultiplierErrors"
                          @input="$v.sitedetails.mlConfigParams.loss.lossRangeMultiplier.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.loss.lossRangeMultiplier.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Loss Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.upperThresholdMilliSec"
                          label="Latency Upper Threshold (ms)" inset :disabled="readonly" type="number"
                          :error-messages="latencyUpperThresholdMilliSecErrors"
                          @input="$v.sitedetails.mlConfigParams.latency.upperThresholdMilliSec.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.latency.upperThresholdMilliSec.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Latency Upper Threshold (ms)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.latencyRangeMultiplier"
                          label="Latency Range Multiplier" inset :disabled="readonly" type="number"
                          :error-messages="latencyRangeMultiplierErrors"
                          @input="$v.sitedetails.mlConfigParams.latency.latencyRangeMultiplier.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.latency.latencyRangeMultiplier.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Latency Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.stdDevUpperThresholdMilliSec"
                          label="Latency Standard Dev Upper Threshold (ms)" inset :disabled="readonly" type="number"
                          :error-messages="latencyStdDevUpperThresholdMilliSecErrors"
                          @input="$v.sitedetails.mlConfigParams.latency.stdDevUpperThresholdMilliSec.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.latency.stdDevUpperThresholdMilliSec.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Latency Standard Dev Upper Threshold (ms)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.stdDevRangeMultiplier"
                          label="Latency Standard Dev Range Multiplier" inset :disabled="readonly" type="number"
                          :error-messages="latencyStdDevRangeMultiplierErrors"
                          @input="$v.sitedetails.mlConfigParams.latency.stdDevRangeMultiplier.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.latency.stdDevRangeMultiplier.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Latency Standard Dev Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.flowsUpperThresholdMilliSec"
                          label="Flows Latency Upper Threshold (ms)" inset :disabled="readonly" type="number"
                          :error-messages="latencyFlowsUpperThresholdMilliSecErrors"
                          @input="$v.sitedetails.mlConfigParams.latency.flowsUpperThresholdMilliSec.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.latency.flowsUpperThresholdMilliSec.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Flows Latency Upper Threshold (ms)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.latency.flowsLatencyRangeMultiplier"
                          label="Flows Latency Range Multiplier" inset :disabled="readonly" type="number"
                          :error-messages="latencyFlowsRangeMultiplierErrors"
                          @input="$v.sitedetails.mlConfigParams.latency.flowsLatencyRangeMultiplier.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.latency.flowsLatencyRangeMultiplier.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Flows Latency Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.protocols.rspsReqsRatioLowerThreshold"
                          v-if="sitedetails.mlSettings.telemetry.mlEnabled"
                          label="Protocol Rsps/Reqs Ratio Lower Threshold" inset :disabled="readonly" type="number"
                          :error-messages="protocolsRspsReqsRatioErrors"
                          @input="$v.sitedetails.mlConfigParams.protocols.rspsReqsRatioLowerThreshold.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.protocols.rspsReqsRatioLowerThreshold.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Protocol Rsps/Reqs Ratio Lower Threshold</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.protocols.rspsReqsRatioRangeMultiplier"
                          v-if="sitedetails.mlSettings.telemetry.mlEnabled"
                          label="Protocol Rsps/Reqs Ratio Range Multiplier" inset :disabled="readonly" type="number"
                          :error-messages="protocolsRspsReqsRatioRangeMultiplierErrors"
                          @input="$v.sitedetails.mlConfigParams.protocols.rspsReqsRatioRangeMultiplier.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.protocols.rspsReqsRatioRangeMultiplier.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Protocol Rsps/Reqs Ratio Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.protocols.errRspsTotal"
                          v-if="sitedetails.mlSettings.telemetry.mlEnabled"
                          label="Protocol Total Error Rsps Upper Threshold" inset :disabled="readonly" type="number"
                          :error-messages="errRspsTotalErrors"
                          @input="$v.sitedetails.mlConfigParams.protocols.errRspsTotal.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.protocols.errRspsTotal.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Protocol Total Error Rsps Upper Threshold</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-text-field dense
                          v-model.number="sitedetails.mlConfigParams.protocols.errRspsTotalRangeMultiplier"
                          v-if="sitedetails.mlSettings.telemetry.mlEnabled"
                          label="Protocol Total Error Rsps Range Multiplier" inset :disabled="readonly" type="number"
                          :error-messages="errRspsTotalRangeMultiplierErrors"
                          @input="$v.sitedetails.mlConfigParams.protocols.errRspsTotalRangeMultiplier.$touch()"
                          @blur="$v.sitedetails.mlConfigParams.protocols.errRspsTotalRangeMultiplier.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Protocol Total Error Rsps Range Multiplier</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                    </v-row>

                  </template>

                  <!-- Loganalysis settings -->

                  <template>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-switch dense v-model="sitedetails.mlSettings.logAnalysis.enabled"
                          :true-value="true" :false-value="false" label="LogAnalysis" inset :disabled="readonly">
                        </v-switch>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-switch dense v-model="sitedetails.mlSettings.logAnalysis.mlEnabled"
                          v-if="sitedetails.mlSettings.logAnalysis.enabled" :true-value="true" :false-value="false"
                          label="LogAnalysis-AI/ML" inset :disabled="readonly"></v-switch>
                      </v-col>
                    </v-row>
                  </template>

                  <!-- Loganalysis config (if ML enabled ) -->

                  <template v-if="sitedetails.mlSettings.logAnalysis.mlEnabled">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field dense label="Prediction Period (Minutes)" inset
                          v-model.number="sitedetails.mlSettings.logAnalysis.predictionPeriodMins"
                          :disabled="readonly" type="number" :error-messages="logAnalysisPredictionPeriodMinsErrors"
                          @input="$v.sitedetails.mlSettings.logAnalysis.predictionPeriodMins.$touch()"
                          @blur="$v.sitedetails.mlSettings.logAnalysis.predictionPeriodMins.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Prediction Period (Minutes)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field dense label="Full Training Period (Days)" inset
                          v-model.number="sitedetails.mlSettings.logAnalysis.trainingPeriodDays"
                          :disabled="readonly" type="number" :error-messages="logAnalysisTrainingPeriodErrors"
                          @input="$v.sitedetails.mlSettings.logAnalysis.trainingPeriodDays.$touch()"
                          @blur="$v.sitedetails.mlSettings.logAnalysis.trainingPeriodDays.$touch()">
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                mdi-information
                              </v-icon>
                            </template>
                            <span>Training Period (Days)</span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </form>
        </v-container>
        <!-- end create/edit form -->
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn rounded outlined color="success" v-if="!readonly && !isEdit" @click="submit" :disabled="this.$v.$invalid">
          Create
        </v-btn>
        <v-btn rounded outlined color="success" v-if="!readonly && isEdit" @click="submit" :disabled="this.$v.$invalid">
          Update
        </v-btn>
        <v-btn rounded outlined color="error" v-if="!readonly" @click="cancel">Cancel</v-btn>
        <v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>

      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  numeric,
  between,
  integer,
  requiredIf,
} from "vuelidate/lib/validators";
import Utils from "@/pages/moda/Utils";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      currOrgId: null,
      targetOrgId: null,
      isLoading: false,
      fullPage: true,
      onCancel: true,
      titleName: "Create",
      siteId: "",
      isEdit: false,
      readonly: true,
      height: 35,
      width: 35,
      mlConfigTemplates: [],
      selectedTemplate: null,
      userdetails: JSON.parse(localStorage.getItem("user-details")),
      statusKey1: 0, // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
      countsKey1: 1000, // key attribute of elements that depend on counts data so they can be re-rendered forcefully

      // Since we are using these default values at few places throughout the code,
      // it's better to keep one master copy here and use (copy) them wherever needed so it's easy to change to
      // different values in future
      mlTelemetrySettingsDefaults: {
        enabled: true,
        mlEnabled: false,
        bucketIntervalMins: 1, // good to be same as application measurement intervals
        trainingPeriodDays: 1, // Trainig dataprep is incremental. So, only use last full day's data to incrementally
                               // update data and retrain the already existing model
        predictionBuckets: 10, // speedtest actual measurement is 4hrs(240 mins), flows interval is 10mins default.
                               // Both are optional and are not used in ML computations if not available
                               // Keep this atleast 10 because sometimes last bucket which is Anomaly may have empty paramList
                               // in which case, ML code takes paramList from previous Anomaly bucket(s)
      },
      mlLogAnalysisSettingsDefaults: {
        enabled: false,
        mlEnabled: false,
        trainingPeriodDays: 7, // Trainig is incremental but dataprep is not. So use full 7 days
        predictionPeriodMins: 10,
      },

      mlConfigParamsDefaults: {
        mlConfigTemplateId: "Custom",
        mlConfigTemplateName: "Custom",
        speedtest: {
          downloadBandwidthMbps: 100,
          downloadLowerThresholdPct: 10,
          downloadRangeMultiplier: 7,

          uploadBandwidthMbps: 20,
          uploadLowerThresholdPct: 10,
          uploadRangeMultiplier: 7,
        },
        latency: {
          upperThresholdMilliSec: 1000,
          latencyRangeMultiplier: 5,
          stdDevUpperThresholdMilliSec: 1000,
          stdDevRangeMultiplier: 6,
          flowsUpperThresholdMilliSec: 1000,
          flowsLatencyRangeMultiplier: 7,
        },
        loss: {
          upperThresholdPct: 75,
          lossRangeMultiplier: 5,
        },
        protocols: {
          rspsReqsRatioLowerThreshold: 0,
          rspsReqsRatioRangeMultiplier: 10,
          errRspsTotal: 150,
          errRspsTotalRangeMultiplier: 10,
        },
      },

      sitedetails: {
        siteId: "",
        siteName: "",
        location: {
          lat: "",
          long: "",
        },
        region: "",
        description: "",
        orgId: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          state: "",
          country: "",
          zipcode: "",
        },
        billingCode0: "",
        billingCode1: "",
        billingCode2: "",

        mlConfigParams: {}, // initialized to Detaults in mounted()

        // initialized to Defaults in mounted(0. For now, it just needs enabled flags as it's used in v-if everywhere in template
        mlSettings: {
          telemetry: {
            enabled: false,
          },
          logAnalysis: {
            enabled: false,
          },
        },
        /*
        mlConfigParams: {
          mlConfigTemplateName: "Custom",
          mlConfigTemplateId: "Custom",
          speedtest: {
            downloadBandwidthMbps: 100,
            downloadLowerThresholdPct: 10,
            downloadRangeMultiplier: 7,

            uploadBandwidthMbps: 20,
            uploadLowerThresholdPct: 10,
            uploadRangeMultiplier: 7,
          },
          latency: {
            upperThresholdMilliSec: 1000,
            latencyRangeMultiplier: 5,
            stdDevUpperThresholdMilliSec: 200,
            stdDevRangeMultiplier: 6,
            flowsUpperThresholdMilliSec: 1000,
            flowsLatencyRangeMultiplier: 7,
          },
          loss: {
            upperThresholdPct: 50,
            lossRangeMultiplier: 5,
          },
          protocols: {
            rspsReqsRatioLowerThreshold: 0.8,
            rspsReqsRatioRangeMultiplier: 10,
            errRspsTotal: 50,
            errRspsTotalRangeMultiplier: 10,
          },
        },
*/
      },
      parent_pages: [
        { name: "Home" },
        { name: "Sites", route: { name: "SITES" } },
        { name: "Create/Edit", active: true },
      ],
      apiStatus: null,
    };
  },
  mixins: [validationMixin, Utils],
  validations: {
    sitedetails: {
      siteName: { required, minLength: minLength(3) },
      address: {
        city: { required },
      },
      mlSettings: {
        telemetry: {
          bucketIntervalMins: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 999999),
            numeric,
            integer,
          },
          trainingPeriodDays: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 999999),
            numeric,
            integer,
          },
          predictionBuckets: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 999999),
            numeric,
            integer,
          },
        },
        logAnalysis: {
          trainingPeriodDays: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.logAnalysis.mlEnabled;
            }),
            between: between(0, 999999),
            numeric,
            integer,
          },
          predictionPeriodMins: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.logAnalysis.mlEnabled;
            }),
            between: between(0, 999999),
            numeric,
            integer,
          },
        },
      },
      mlConfigParams: {
        speedtest: {
          downloadBandwidthMbps: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 999999),
            numeric,
            integer,
          },
          downloadLowerThresholdPct: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 100),
            numeric,
          },
          downloadRangeMultiplier: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            //between: between(0, 100),
            numeric,
            integer,
          },
          uploadBandwidthMbps: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 999999),
            numeric,
            integer,
          },
          uploadLowerThresholdPct: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 100),
            numeric,
          },
          uploadRangeMultiplier: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            //between: between(0, 100),
            numeric,
            integer,
          },
        },
        latency: {
          upperThresholdMilliSec: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 999999),
            numeric,
            integer,
          },
          latencyRangeMultiplier: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            //between: between(0, 999999),
            numeric,
            integer,
          },
          stdDevUpperThresholdMilliSec: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 999999),
            numeric,
            integer,
          },
          stdDevRangeMultiplier: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            //between: between(0, 999999),
            numeric,
            integer,
          },
          flowsUpperThresholdMilliSec: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 999999),
            numeric,
            integer,
          },
          flowsLatencyRangeMultiplier: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            //between: between(0, 999999),
            numeric,
            integer,
          },
        },
        loss: {
          upperThresholdPct: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 100),
            numeric,
          },
          lossRangeMultiplier: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            //between: between(0, 100),
            numeric,
            integer,
          },
        },
        protocols: {
          rspsReqsRatioLowerThreshold: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 1),
          },
          rspsReqsRatioRangeMultiplier: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            //between: between(0, 1),
            numeric,
            integer,
          },
          errRspsTotal: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            between: between(0, 999999),
            numeric,
            integer,
          },
          errRspsTotalRangeMultiplier: {
            required: requiredIf(function () {
              return this.sitedetails.mlSettings.telemetry.mlEnabled;
            }),
            //between: between(0, 999999),
            numeric,
            integer,
          },
        },
      },
    },
  },
  computed: {
    // mlConfigParamsDirtyHelper(){
    // //      let value =
    // //     this.$v.sitedetails.mlConfigParams.speedtest.downloadBandwidthMbps;
    // //   return (value && value.$dirty);

    // // If even one of the mlConfigParams value isn't equal to selectedTemplate value, returns false
    //     return (
    //         this.selectedTemplate &&
    //         this.$v.sitedetails.mlConfigParams.speedtest.downloadBandwidthMbps == this.selectedTemplate.speedtest.downloadBandwidthMbps &&
    //         this.$v.sitedetails.mlConfigParams.speedtest.downloadLowerThresholdPct == this.selectedTemplate.speedtest.downloadLowerThresholdPct &&
    //         this.$v.sitedetails.mlConfigParams.speedtest.downloadUpperThresholdPct == this.selectedTemplate.speedtest.downloadUpperThresholdPct &&
    //         this.$v.sitedetails.mlConfigParams.speedtest.downloadRangeMultiplier == this.selectedTemplate.speedtest.downloadRangeMultiplier &&

    //         this.$v.sitedetails.mlConfigParams.speedtest.uploadBandwidthMbps == this.selectedTemplate.speedtest.uploadBandwidthMbps &&
    //         this.$v.sitedetails.mlConfigParams.speedtest.uploadLowerThresholdPct == this.selectedTemplate.speedtest.uploadLowerThresholdPct &&
    //         this.$v.sitedetails.mlConfigParams.speedtest.uploadUpperThresholdPct == this.selectedTemplate.speedtest.uploadUpperThresholdPct &&
    //         this.$v.sitedetails.mlConfigParams.speedtest.uploadRangeMultiplier == this.selectedTemplate.speedtest.uploadRangeMultiplier &&

    //         this.$v.sitedetails.mlConfigParams.latency.lowerThresholdMilliSec == this.selectedTemplate.latency.lowerThresholdMilliSec &&
    //         this.$v.sitedetails.mlConfigParams.latency.upperThresholdMilliSec == this.selectedTemplate.latency.upperThresholdMilliSec &&
    //         this.$v.sitedetails.mlConfigParams.latency.latencyRangeMultiplier == this.selectedTemplate.latency.latencyRangeMultiplier &&

    //         this.$v.sitedetails.mlConfigParams.protoRatio.value == this.selectedTemplate.protoRatio.value
    //     )
    // },

    nameErrors() {
      const errors = [];
      if (!this.$v.sitedetails.siteName.$dirty) return errors;

      !this.$v.sitedetails.siteName.required && errors.push("Name is required.");

      !this.$v.sitedetails.siteName.minLength && errors.push("Name must be atleast 3 characters long");

      return errors;
    },
    addressCityErrors() {
      const errors = [];
      if (!this.$v.sitedetails.address.city.$dirty) return errors;

      !this.$v.sitedetails.address.city.required && errors.push("City is required.");

      return errors;
    },

    // mlSettings.telemetry
    telemetryBucketIntervalMinsErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlSettings.telemetry.bucketIntervalMins;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      !value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    telemetryTrainingPeriodErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlSettings.telemetry.trainingPeriodDays;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      !value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    telemetryMlPredictionBucketsErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlSettings.telemetry.predictionBuckets;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      !value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },

    // mlSettings.logAnalysis
    logAnalysisPredictionPeriodMinsErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlSettings.logAnalysis.predictionPeriodMins;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      !value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    logAnalysisTrainingPeriodErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlSettings.logAnalysis.trainingPeriodDays;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      !value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },

    // mlConfigParams
    downloadBandwidthMbpsErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.speedtest.downloadBandwidthMbps;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      !value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    downloadLowerThresholdPctErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.speedtest.downloadLowerThresholdPct;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.between && errors.push("Must be between 0 and 100!");

      !value.required && errors.push("This cannot be empty!");

      return errors;
    },
    downloadRangeMultiplierErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.speedtest.downloadRangeMultiplier;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      //!value.between && errors.push("Must be between 0 and 100!");

      !value.required && errors.push("This cannot be empty!");

      return errors;
    },
    uploadBandwidthMbpsErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.speedtest.uploadBandwidthMbps;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      !value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    uploadLowerThresholdPctErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.speedtest.uploadLowerThresholdPct;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.between && errors.push("Must be between 0 and 100!");

      !value.required && errors.push("This cannot be empty!");

      return errors;
    },
    uploadRangeMultiplierErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.speedtest.uploadRangeMultiplier;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      //!value.between && errors.push("Must be between 0 and 100!");

      !value.required && errors.push("This cannot be empty!");

      return errors;
    },
    latencyUpperThresholdMilliSecErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.latency.upperThresholdMilliSec;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      !value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    latencyRangeMultiplierErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.latency.liatencyRangeMultiplier;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      //!value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    latencyStdDevUpperThresholdMilliSecErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.latency.stdDevUpperThresholdMilliSec;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      !value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    latencyStdDevRangeMultiplierErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.latency.stdDevRangeMultiplier;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      //!value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    latencyFlowsUpperThresholdMilliSecErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.latency.flowsUpperThresholdMilliSec;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      !value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    latencyFlowsRangeMultiplierErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.latency.flowsLatencyRangeMultiplier;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.required && errors.push("This cannot be empty!");

      //!value.between && errors.push("Must not be negative!");

      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    lossUpperThresholdPctErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.loss.upperThresholdPct;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.between && errors.push("Must be between 0 and 100!");

      !value.required && errors.push("This cannot be empty!");

      return errors;
    },
    lossRangeMultiplierErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.loss.lossRangeMultiplier;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      //!value.between && errors.push("Must be between 0 and 100!");

      !value.required && errors.push("This cannot be empty!");
      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    protocolsRspsReqsRatioErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.protocols.rspsReqsRatioLowerThreshold;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.between && errors.push("Must be between 0 and 1!");

      !value.required && errors.push("This cannot be empty!");

      return errors;
    },
    protocolsRspsReqsRatioRangeMultiplierErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.protocols.rspsReqsRatioRangeMultiplier;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      //!value.between && errors.push("Must be between 0 and 1!");

      !value.required && errors.push("This cannot be empty!");
      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    errRspsTotalErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.protocols.errRspsTotal;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      !value.between && errors.push("Must be between 0 and 1!");

      !value.required && errors.push("This cannot be empty!");
      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    errRspsTotalRangeMultiplierErrors() {
      const errors = [];
      let value = this.$v.sitedetails.mlConfigParams.protocols.errRspsTotalRangeMultiplier;

      if (!value || !value.$dirty) return errors;

      if (value && value.$dirty) this.setMlConfigAsCustom();

      //!value.between && errors.push("Must be between 0 and 1!");

      !value.required && errors.push("This cannot be empty!");
      !value.integer && errors.push("Must be an integer!");

      return errors;
    },
    telemetryEnabledHelper() {
      return this.sitedetails.mlSettings.telemetry.enabled;
    },
    logAnalysisEnabledHelper() {
      return this.sitedetails.mlSettings.logAnalysis.enabled;
    },
  }, // computed
  watch: {
    telemetryEnabledHelper() {
      if (!this.sitedetails.mlSettings.telemetry.enabled) {
        this.sitedetails.mlConfigParams = JSON.parse(
          JSON.stringify(this.mlConfigParamsDefaults)
        );
        this.sitedetails.mlSettings.telemetry = JSON.parse(
          JSON.stringify(this.mlTelemetrySettingsDefaults)
        );
      }
    },
    logAnalysisEnabledHelper() {
      if (!this.sitedetails.mlSettings.logAnalysis.enabled) {
        this.sitedetails.mlSettings.logAnalysis = JSON.parse(
          JSON.stringify(this.mlLogAnalysisSettingsDefaults)
        );
      }
    },
    /*
    telemetryMLEnabledHelper() {
      if (!this.sitedetails.mlSettings.telemetry.mlEnabled) {
        this.sitedetails.mlSettings.telemetry = {
          enabled: false,
          mlEnabled: false,
          bucketIntervalMins: 20,
          trainingPeriodDays: 1, // Trainig is incremental. So, only user last full day's data to incrementally retrain the already existing model
          predictionBuckets: 2,
        };

        this.sitedetails.mlConfigParams = {
          mlConfigTemplateName: "Custom",
          mlConfigTemplateId: "Custom",
          speedtest: {
            downloadBandwidthMbps: 0,
            downloadLowerThresholdPct: 0,
            downloadRangeMultiplier: 0,

            uploadBandwidthMbps: 0,
            uploadLowerThresholdPct: 0,
            uploadRangeMultiplier: 0,
          },
          latency: {
            upperThresholdMilliSec: 0,
            latencyRangeMultiplier: 0,
            stdDevUpperThresholdMilliSec: 0,
            stdDevRangeMultiplier: 0,
            flowsUpperThresholdMilliSec: 0,
            flowsLatencyRangeMultiplier: 0,
          },
          loss: {
            upperThresholdPct: 0,
            lossRangeMultiplier: 0,
          },
          protocols: {
            rspsReqsRatioLowerThreshold: 0,
            rspsReqsRatioRangeMultiplier: 0,
            errRspsTotal: 0,
            errRspsTotalRangeMultiplier: 0,
          },
        };
      }
    },
    logAnalysisMLEnabledHelper() {
      if (!this.sitedetails.mlSettings.logAnalysis.mlEnabled) {
        this.sitedetails.mlSettings.logAnalysis = {
          enabled: false,
          mlEnabled: false,
          trainingPeriodDays: 1, // Trainig is incremental. So, only user last full day's data to incrementally retrain the already existing model
          predictionPeriodMins: 20,
        };
      }
    },
*/
    // mlConfigParamsDirtyHelper: {
    //         handler(hasChanged){
    //             if(!hasChanged){
    //             console.log("hasChanged!");
    //             this.setMlConfigAsCustom();
    //             }
    //         }
    //     }, deep: true
  },
  methods: {
    navigateToApplications(row) {
      console.log("navigateToApplications sitedetails ", JSON.stringify(row.siteId));
      // empty query is needed. Else, that component will use default query
      let query = [{ name: "site", value: { siteNameList: [row.siteName] } }]
      this.$router.push({ name: "APPLICATIONS", query: query })
    },
    navigateToMonitoredDevices(row) {
      console.log("navigateToMonitoredDevices sitedetails ", JSON.stringify(row.siteId));
      // empty query is needed. Else, that component will use default query
      let query = [{ name: "site", value: { siteNameList: [row.siteName] } }]
      this.$router.push({ name: "MONITORED_DEVICES", query: query })
    },
    navigateToIncidents(row) {
      var siteIds = [], siteNames = [], states = [];
      var query = [];

      states = this.utilsOpenStates;
      siteIds = Array.isArray(row) ? row.map((r) => r.siteId) : [row.siteId]
      siteNames = Array.isArray(row) ? row.map((r) => r.siteName) : [row.siteName]

      if (siteIds.length && siteNames.length)
        query.push({ name: "site", value: { siteIdList: siteIds, siteNameList: siteNames } });
      /*
            advSearchString = "Open incs. in " + siteNames;
            if (siteIds.length) query.push({ name: "siteId", value: siteIds });
            if (siteNames.length) query.push({ name: "siteName", value: siteNames });
              // TBD : componentId, componentName ?????
            if (states.length) query.push({ name: "state", value: states });
            console.log("navigateToIncidents query ", JSON.stringify(query));
            query.length
              ? this.$router.push({ name: "INCIDENTS", query: query, params: { advSearchString: advSearchString, targetOrgId: row.orgId }, })
              : this.$router.push({ name: "INCIDENTS" });
      */
      query.push({ name: "state", value: states });
      console.log("navigateToIncidents query ", JSON.stringify(query));
      query.length ? this.$router.push({ name: "INCIDENTS", query: query }) : this.$router.push({ name: "INCIDENTS" });
    },
    submit() {
      this.$v.$touch();
      //console.log("submit*****"+JSON.stringify(this.sitedetails))

      this.isLoading = true;
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      this.sitedetails.siteName = this.sitedetails.siteName.trim();
      if (this.sitedetails.address.line1)
        this.sitedetails.address.line1 = this.sitedetails.address.line1.trim();
      if (this.sitedetails.address.line2)
        this.sitedetails.address.line2 = this.sitedetails.address.line2.trim();

      this.sitedetails.address.city = this.sitedetails.address.city.trim();

      if (this.sitedetails.address.state)
        this.sitedetails.address.state = this.sitedetails.address.state.trim();
      if (this.sitedetails.address.country)
        this.sitedetails.address.country = this.sitedetails.address.country.trim();
      if (this.sitedetails.address.zipcode)
        this.sitedetails.address.zipcode = this.sitedetails.address.zipcode.trim();
      if (this.sitedetails.region)
        this.sitedetails.region = this.sitedetails.region.trim();

      this.sitedetails.billingCode = [];
      this.sitedetails.billingCode[0] = this.sitedetails.billingCode0 ? this.sitedetails.billingCode0.trim() : null;
      this.sitedetails.billingCode[1] = this.sitedetails.billingCode1 ? this.sitedetails.billingCode1.trim() : null;
      this.sitedetails.billingCode[2] = this.sitedetails.billingCode2 ? this.sitedetails.billingCode2.trim() : null;
      delete this.sitedetails.billingCode0;
      delete this.sitedetails.billingCode1;
      delete this.sitedetails.billingCode2;

      if (this.isEdit) {
        ApiService.put("/api/v1/site/" + this.siteId, this.sitedetails, (err, result) => {
          //console.log("*update*"+JSON.stringify(result))
          this.isLoading = false;
          if (err) {
            this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed")
            this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            this.cancelCreateSite();
          }
        },
          query_params
        );
      } else {
        // create
        ApiService.post("/api/v1/site", this.sitedetails, (err, result) => {
          //console.log("*create error "+JSON.stringify(err))
          //console.log("*create result " + JSON.stringify(result))
          this.isLoading = false;
          if (err) {
            this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed")
            this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            this.cancelCreateSite();
          }
        },
          query_params
        );
      }
    },
    cancel() {
      this.$v.$reset();
      this.cancelCreateSite();
    },
    cancelCreateSite() {
      this.$router.go(-1);
    },
    updateSiteStatus(siteStsList) {
      let siteSts = siteStsList[0]
      this.sitedetails.highestSeverity = siteSts.highestSeverity
      this.sitedetails.incCount = siteSts.incCount
      this.sitedetails.faultStatus = siteSts.faultStatus
    },
    getMonitoredDevicesCount() {
      let query_params = {}, body = {};
      query_params.targetOrgId = this.beingEditedOrgId;

      this.isLoading = true

      body.orgIds = [this.currOrgId]
      body.siteIds = [this.sitedetails.siteId]

      //console.log("getOrganizationsStatusList body " + JSON.stringify(body))

      ApiService.post("/svcInventory/api/v1/monitoredDevice/counts", body, (err, result) => {
        this.isLoading = false;
        if (err) {
          this.apiStatus = "Failed to fetch devices counts"
          //this.utilsCheckLogout(this.apiStatus);
        } else {
          this.apiStatus = null;
          //console.log("**** getMonitoredDevicesCount result = " + JSON.stringify(result, null, 2))
          // Output format
          //[{"orgId":"orgobj.1000","sites":[{"siteId":"sitobj.2000078_1fd8ba97-748f-4e00-ad71-05d6a246d73f","monDevCount":4},{"siteId":"sitobj.2000046_81222a6d-fe4f-472f-909c-19e63c8eb2ed","monDevCount":84}],"monDevCount":88},{"orgId":"orgobj.2001_68e21c61-014b-4bcb-9119-31607cd0bf3e","sites":[{"siteId":"sitobj.2000111_0feb497d-3bbe-4885-9a99-bc9ba2374ca7","monDevCount":1}],"monDevCount":1}]

          if (result.data.data[0] && result.data.data[0].sites[0])
            this.sitedetails.monDevCount = result.data.data[0].sites[0].monDevCount
          else this.sitedetails.monDevCount = 0
          this.countsKey1 += 1; // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
          //console.log("**** getMonitoredDevicesCount sitedetails = " + JSON.stringify(this.sitedetails, null, 2))
        }
      }, query_params, "INVENTORY_SERVICE_URL"
      );
    },

    getSitesStatusList() {
      let query_params = {}, body = {};
      if (this.targetOrgId) query_params.targetOrgId = this.targetOrgId;
      else if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      this.isLoading = true

      query_params.summaryDataType = this.UtilsModaConstants.FaultService.Summary.DataType.SITES,
        query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.LISTS

      body = [this.sitedetails.siteId]

      //console.log("getSitesStatusList body " + JSON.stringify(body))

      ApiService.post("/svcSummary/api/v1/summary", body, (err, result) => {    //### svcFault
        this.isLoading = false;
        if (err) {
          this.apiStatus = "Failed to fetch sites' fault status"
          //this.utilsCheckLogout(this.apiStatus);
        } else {
          this.apiStatus = null;
          //console.log("**** getSitesStatusList sites result = " + JSON.stringify(result, null, 2))
          this.updateSiteStatus(result.data.data)
          //console.log("**** getSitesStatusList sites = " + JSON.stringify(this.sitedetails, null, 2))
          this.statusKey1 += 1; // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
        }
      }, query_params, "SUMMARY_SERVICE_URL" //### "FAULT_SERVICE_URL"
      );
    },
    getSiteDetails() {
      let query_params = {};
      if (this.targetOrgId) query_params.targetOrgId = this.targetOrgId;
      else if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      ApiService.get("/api/v1/site/" + this.siteId, (err, result) => {
        //console.log("getSiteDetails " + JSON.stringify(result))
        if (err) {
          this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed")
          this.utilsCheckLogout(this.apiStatus);
        } else {
          this.apiStatus = null;
          this.sitedetails = result.data.data.objs[0];
          //console.log("getSiteDetails " + JSON.stringify(this.sitedetails))
          if (!this.sitedetails.mlSettings)
            // old site. Populate empty objs so UI wont fart
            this.sitedetails.mlSettings = { telemetry: {}, logAnalysis: {} };
          this.titleName = (this.readonly ? "View - " : "Edit - ") + this.sitedetails.siteName;
          this.getSitesStatusList();
          this.getMonitoredDevicesCount()
        }
      },
        query_params
      );
    },
    getMlConfigTemplates() {
      let query_params = {};
      if (this.targetOrgId) query_params.targetOrgId = this.targetOrgId;
      else if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      ApiService.get("/api/v1/mlConfigTemplate/", (err, result) => {
        if (err) {
          this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed")
          this.utilsCheckLogout(this.apiStatus);
        } else {
          // this.apiStatus = null;
          this.mlConfigTemplates = result.data.data.map(
            (t) => t
            /*  No need to remap..just a few more extra fields. This way, if we add more fields, we don't to come here and adjust
              {
            return {
              mlConfigTemplateId: String(t.mlConfigTemplateId),
              mlConfigTemplateName: String(t.mlConfigTemplateName),
              mlConfigParams: {
                latency: { ...t.mlConfigParams.latency },
                speedtest: { ...t.mlConfigParams.speedtest },
                loss: { ...t.mlConfigParams.loss },
                protocols: { ...t.mlConfigParams.protocols },
              },
            };
          }
*/
          );
          this.mlConfigTemplates.push(JSON.parse(JSON.stringify(this.mlConfigParamsDefaults))
            /*
          {
            mlConfigTemplateName: "Custom",
            mlConfigTemplateId: "Custom",
            mlConfigParams: {
              speedtest: {
                downloadBandwidthMbps: 400,
                downloadLowerThresholdPct: 10,
                downloadRangeMultiplier: 7,

                uploadBandwidthMbps: 30,
                uploadLowerThresholdPct: 10,
                uploadRangeMultiplier: 7,
              },
              latency: {
                upperThresholdMilliSec: 1000,
                latencyRangeMultiplier: 5,
                stdDevUpperThresholdMilliSec: 200,
                stdDevRangeMultiplier: 6,
                flowsUpperThresholdMilliSec: 1000,
                flowsLatencyRangeMultiplier: 7,
              },
              loss: {
                upperThresholdPct: 50,
                lossRangeMultiplier: 5,
              },
              protocols: {
                rspsReqsRatioLowerThreshold: 0.8,
                rspsReqsRatioRangeMultiplier: 10,
                errRspsTotal: 0,
                errRspsTotalRangeMultiplier: 0,
              },
            },
          }
*/
          );
          // console.log("mlConfigTemplates", this.mlConfigTemplates);
        }
      },
        query_params
      );
    },
    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      if (this.siteId != "" && this.siteId != undefined) {
        // Edit can't be done when org is changed, so g back
        this.cancelCreateSite();
      }
    },

    mlConfigTemplateChangeHandler(e) {
      let t = JSON.parse(JSON.stringify(e));
      //console.log("mlConfigTemplateChangeHandler event")
      //console.log(JSON.stringify(e))
      this.sitedetails.mlConfigParams = t.mlConfigParams
        ? { ...t.mlConfigParams, mlConfigTemplateId: String(t.mlConfigTemplateId), mlConfigTemplateName: String(t.mlConfigTemplateName), }
        : t; // Custom obj doesn't have mlConfigParams nested. It's not something we fetched from backend.
      // Hence for Custom , take the top level objects

      /*
      console.log("mlConfigTemplateChangeHandler mlConfigParams") 
      console.log(JSON.stringify(this.sitedetails.mlConfigParams))
      this.selectedTemplate = {
        ...t.mlConfigParams,
        mlConfigTemplateId: String(t.mlConfigTemplateId),
        mlConfigTemplateName: String(t.mlConfigTemplateName),
      };
      console.log(JSON.stringify(this.selectedTemplate))
*/
    },

    setMlConfigAsCustom() {
      //this.sitedetails.mlConfigParams.mlConfigTemplateName = "Custom"; // Name will be automatically
      this.sitedetails.mlConfigParams.mlConfigTemplateId = "Custom";
    },
  },
  mounted() {
    this.getMlConfigTemplates();
    this.sitedetails.mlConfigParams = JSON.parse(JSON.stringify(this.mlConfigParamsDefaults));
    this.sitedetails.mlSettings.telemetry = JSON.parse(JSON.stringify(this.mlTelemetrySettingsDefaults));
    this.sitedetails.mlSettings.logAnalysis = JSON.parse(JSON.stringify(this.mlLogAnalysisSettingsDefaults));
    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    this.targetOrgId = this.$route.params.targetOrgId;
    this.siteId = this.$route.params.siteId;
    this.readonly = this.$route.params.readonly == "1";
    if (this.siteId != "" && this.siteId != undefined) {
      this.isEdit = true;
      this.titleName = this.readonly ? "View" : "Edit";
      this.getSiteDetails();
    }
  },
};
</script>
