<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="5" class="tile-box">
      <v-card-title class="page-title">
        <v-icon>mdi-router-wireless</v-icon> MODA Agents
        <v-btn rounded outlined color="primary" absolute right :class="isShow" v-on:click="createDevice()">
          <v-icon>mdi-plus</v-icon> New
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
          {{ apiStatus }}
        </v-alert>
        <TableAdvancedFilter ref="tableAdvancedFilter" :enabledFieldsIn="tableAdvancedFilter.enabledFilterFields"
          v-on:freeFormSearchChanged="processFreeFormSearchChange"
          v-on:advancedFilterChanged="processAdvancedFilterChange" />
      </v-card-text>
      <v-container fluid class="pt-0">
        <v-data-table :loading="isLoading" :headers="deviceTableColumnsVueTable" :items="device_table_rows"
          :options.sync="pagingOptions" :server-items-length="utilsTotalEntriesAllPages" multi-sort
          no-data-text="No devices" :footer-props="{ 'items-per-page-options': [2, 5, 10, 15, 25, 100] }"
          item-key="deviceId">
          <template v-slot:item.siteName="{ item }">
            <a href="javascript:;" v-on:click="navigateToSite(item.siteId)">
              {{ item.siteName }}
            </a>
          </template>
          <template v-slot:item.statuses="{ item }">

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
<!-- admin icon is now reserved for toggle button action
                <v-icon v-bind="attrs" v-on="on" x-large
                  :color="utilsAdminStatusDisplayObject(item.adminStatus).colorName">
                  {{ utilsAdminStatusDisplayObject(item.adminStatus).icon }}
                </v-icon>
-->
                <span v-bind="attrs" v-on="on" x-large> {{ item.adminStatusDisplay }} </span>
              </template>
              <span>{{ utilsAdminStatusDisplayObject(item.adminStatus).text }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" :color="utilsOperStatusDisplayObject(item.operStatus).colorName">
                  {{ utilsOperStatusDisplayObject(item.operStatus).icon }}
                </v-icon>
              </template>
              <span>{{ utilsOperStatusDisplayObject(item.operStatus).text }}</span>
            </v-tooltip>

            <!-- key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully -->
            <v-tooltip top :key="liveStatusKey">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" :color="utilsLiveStatusDisplayObject(item.deviceLiveStatus).colorName">
                  {{ utilsLiveStatusDisplayObject(item.deviceLiveStatus).icon }}
                </v-icon>
              </template>
              <span>{{ utilsLiveStatusDisplayObject(item.deviceLiveStatus).text }}</span>
            </v-tooltip>

          </template>

          <template v-slot:item.deployedProfiles="{ item }">
            <span   v-for="prof in item.deployedConfigList" :key="prof.configProfileId">
              <a href="javascript:;" v-on:click="navigateToDeployedProfileSnapshot(item, prof)">
                {{ prof.configProfileName }} - v{{ prof.configProfileVersion }}<br />
              </a>
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-row>
              <v-icon @click="showMoreParams(item)" class="mr-2" title="More" color="teal">
                mdi-information-outline
              </v-icon>
              <v-icon @click="viewDeviceDetails(item.deviceId)" class="mr-2" color="primary" title="View">
                mdi-eye
              </v-icon>
              <v-icon
                v-if="utilsIsMODAUser() && item.deviceLiveStatus == UtilsModaConstants.DeviceLiveStatus.ONLINE && !item.isRsshRunning"
                :class="utilsGetDisplayClass(item)" @click="deviceRssh.deviceId = item.deviceId; open_rssh_dialog = true" color="black" class="mr-2"
                title="Start RSSH">
                mdi-console
              </v-icon>
              <v-icon v-if="utilsIsMODAUser() && item.isRsshRunning" small @click="startStopRssh(item.deviceId, false)"
                :class="utilsGetDisplayClass(item)" color="warning" title="Stop RSSH">
                mdi-close-octagon
              </v-icon>
              <v-icon :class="utilsGetDisplayClass(item)" @click="editDeviceDetails(item.deviceId, 0)" class="mr-2" color="warning" title="Edit">
                mdi-pencil
              </v-icon>
              <v-icon :class="utilsGetDisplayClass(item)" @click="showDialogToDelete(item.deviceId, item.deviceName)" color="red" class="mr-2"
                title="Delete">
                mdi-delete
              </v-icon>
            </v-row>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <v-dialog v-model="open_more_params_dialog" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row class="pt-6">
            <v-col cols="12" md="4"> Created Date: </v-col>
            <v-col cols="12" md="8">
              {{ more_params_row.createdDateString }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4"> IP Addrs: </v-col>
            <v-col cols="12" md="8" :key="liveStatusKey">
              {{ more_params_row.ipAddrs }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4"> IP Scan Range: </v-col>
            <v-col cols="12" md="8">
              {{ more_params_row.ipScanRange }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="open_more_params_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="open_rssh_dialog" eager max-width="400" @keydown.esc="open_rssh_dialog = false">
      <v-card>
        <v-card-title>Start RSSH</v-card-title>
        <v-card-text class="pb-0">
          <v-container class="px-0 mb-5" fluid>
            <form ref="deviceRsshform" class="pt-5">
              <v-row>
                <v-col cols=12>
                  <v-text-field v-model="deviceRssh.host" :error-messages="nameErrors" class="moda-required" dense
                    label="RSSH Server" @input="$v.deviceRssh.host.$touch()" @blur="$v.deviceRssh.host.$touch()" />
                </v-col>
                <v-col cols=12>
                  <v-text-field v-model.number="deviceRssh.rport" label="Port on RSSH Server" type="number" dense
                    v-on:keypress="utilsIsNumber" class="moda-required" :error-messages="portErrors"
                    @input="$v.deviceRssh.rport.$touch()" @blur="$v.deviceRssh.rport.$touch()" />
                </v-col>
                <v-col cols=12>
                  <v-text-field v-model="deviceRssh.user" :error-messages="userErrors" class="moda-required" dense
                    label="Username" @input="$v.deviceRssh.user.$touch()" @blur="$v.deviceRssh.user.$touch()" />
                </v-col>
                <v-col cols=12>
                  <v-text-field v-model="deviceRssh.password" dense
                    :append-icon="password_show ? 'fas fa-eye' : 'fas fa-eye-slash'"
                    :type="password_show ? 'text' : 'password'" @click:append="password_show = !password_show"
                    label="Password" :error-messages="passwordErrors" class="moda-required"
                    @input="$v.deviceRssh.password.$touch()" @blur="$v.deviceRssh.password.$touch()" />
                </v-col>
              </v-row>
            </form>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn rounded outlined color="success"
            @click="startStopRssh(deviceRssh.deviceId, true); open_rssh_dialog = false" :disabled="this.$v.$invalid">
            Start RSSH</v-btn>
          <v-btn rounded outlined color="error" @click="open_rssh_dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DeleteDialog v-if="open_delete_dialog" :currentObjectName="currentDeviceName" :isBackupBeforeDelete="false"
      @DeleteDialogCallback="deleteDeviceDetailsChildEvent" :delMessage="deleteMessage"> </DeleteDialog>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import auth from "@/services/auth.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import Utils from "@/pages/moda/Utils.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TableAdvancedFilter from '@/components/templates/TableAdvancedFilter'
import { validationMixin } from 'vuelidate'
import { required, minLength, between } from 'vuelidate/lib/validators'

//--- socket.io ---
import socketService from "@/services/socket.io.service";
import ModaConst from "@/services/ModaConstants.js";

export default {
  name: "AgentDevices",
  components: {
    Breadcrumb,
    DeleteDialog,
    TableAdvancedFilter,
  },
  mixins: [
    validationMixin,
    Utils,
  ],
  data() {
    var deviceTableColumnsVueTable = [
      {
        text: "Name",
        value: "deviceName",
        sortable: true,
        filterable: true,
      },
      {
        text: "Model",
        value: "deviceModelName",
        sortable: true,
        filterable: true,
      },
/*
           {
               text: 'Version',
               value: 'version',
               sortable: true,
               filterable: true,
           },
*/
      {
        text: "Serial #",
        value: "serialNumber",
        sortable: true,
        filterable: true,
      },
      {
        text: "MAC Address", // 'Unique ID',
        value: "macAddress",
        sortable: true,
        filterable: true,
      },
      {
        text: "Site",
        value: "siteName",
        sortable: true,
        filterable: true,
      },
/*
           {
               text: 'Admin',
               value: 'adminStatus',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Oper Status',
               value: 'operStatus',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Creation Time',
               value: 'createdDateString',
               sortable: true,
               filterable: true,
           },
*/
      {
        text: "Profile snapshots deployed",
        value: "deployedProfiles",
        sortable: false,
        filterable: true,
      },
      {
        text: "Statuses",
        value: "statuses",
        sortable: true,
        filterable: true,
      },
      {
        text: "",
        value: "moreParams",
        //sortable : true,
        //filterable: true,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false
      },
    ];

    return {
      currOrgId: null,
      deviceTableColumnsVueTable,
      device_table_rows: [],
      advSearchCriteria: null,
      //advSearchString: null,

      isLoading: false,
      userdetails: {},
      isShow: "display-none",
      parent_pages: [{ name: "Home" }, {name: "Configuration"}, { name: "MODA Agents", active: true }],
      apiStatus: null,
      open_delete_dialog: false,
      currentDeviceId: null,
      currentDeviceName: "",
      confirmToDelete: null,
      isHardDelete: null,
      more_params_row: {},
      open_more_params_dialog: false,
      //options:{page:1, itemsPerPage:10 },
      pagingOptions: sessionStorage.getItem(`_pagination${this.$route.path}`) ? JSON.parse(sessionStorage.getItem(`_pagination${this.$route.path}`)) : this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
      //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable

      liveStatusKey: 0, // key attribute for those elements that depend on liveStatus data so they can be re-rendered forcefully

      //---socket ---
      pageName: ModaConst.Socket.Page.AGENT_DEVICES,
      isConnected: false,
      isPageActivated: false,
      isPageVisible: true,
      socketMessage: "",
      eventData: null, // Data from a Socket event

      //Note: Values of site(List) and component(List) are passed in a tuple of (idList & nameList)
      //      Map() or Set() can not be used as 'id' or 'name' could be empty
      // During a cross launch to this page from other pages, the passed in values are set in xLaunch
      xLaunch : {
        site: {
            siteIdList: [],         // ["sitobj.1001"] 
            siteNameList: []        // ["Austin"]
        },
      },
      tableAdvancedFilter : {
        enabledFilterFields : [], //this.utilsAdvancedFilterFields template doesn't work directly using mixin's data. Init it in mounted()
      },
      defaultAdvSearchCriteria: [
/*  -- for now, there is no highestSeverity for Agents. Add back when Incidents are associated with Agent Devices
        {"name":"highestSeverity", // default Major and above states
            "value": [
                ModaConst.FaultService.Severity.CRITICAL,
                ModaConst.FaultService.Severity.SEVERE,
                ModaConst.FaultService.Severity.MAJOR,
            ]
        },
*/
      ],


      // RSSH related
      open_rssh_dialog: false,
      password_show: false,
      deviceRssh :{
        deviceId: "",
        host: "rssh.modatechnologies.com",
        rport: 10000,
        user: null,
        password: null,
      },
    };
  },

  //=== Hooks ================
  created: function () {
/* TBD Commenting out websockets for now until Kong issue is figured out
    let cSocket = socketService.setupSocketConnection(this.pageName, "STATUS_SERVICE_WS_URL", ModaConst.Service.STATUS);
    this.isConnected = true;
    this.socketMessage = "Connected to the LiveStatus";

    socketService.sendMessage(this.pageName, "in hook: created. Connected");

    cSocket.on("connect", () => {
      console.log("Connected to the Socket server. socket.id = " + cSocket.id);
      socketService.addSocketToActiveMap(this.pageName, cSocket);

      socketService.sendEvent(this.pageName, "page", {
        pageName: this.pageName,
        orgId: this.currOrgId,
      });

      this.attachSocketMessageHandlers(this, cSocket);
    });
*/
  },
  mounted: function () {
    console.log( "Events Devices mounted query type " + typeof this.$route.query + ", value " + JSON.stringify(this.$route.query));
    //console.log( "Events Devices mounted params " + JSON.stringify(this.$route.params));
    console.log( "Events Devices mounted global advSearc " + JSON.stringify(auth.globalDevicesAdvSearch));
    //console.log( "Events Devices mounted advSeahStr " + JSON.stringify(auth.globalDevicesAdvSearchString));

    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();

    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.FREEFORM_SEARCH)
    //this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.ORG_NAME) // orgName removed
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.SITE_NAME)
    // -- for now, there is no highestSeverity for Agents. Add back when Incidents are associated with Agent Devices
    //this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.HIGHEST_SEVERITY)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_NAME)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_LIVE_STATUS)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_MAC_ADDRESS)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_SERIAL_NUMBER)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.ADMIN_STATUS)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_OPER_STATUS)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.DEV_MODEL)

    if ( this.$route.query && Array.isArray(this.$route.query) ) {
         // query passed in from cross Launch
         this.advSearchCriteria = this.$route.query
         //auth.globalDevicesAdvSearch = this.advSearchCriteria - //don't save cross launch filter as global.
                                                                  // We should be able to see complete list by clicking on left panel
    } else if (auth.crossLaunchAppsAdvSearch)
      // else, take the one from crossLaunch state - the one we set when we navigated away from this page
      this.advSearchCriteria = auth.crossLaunchDevicesAdvSearch
    else if ( auth.globalDevicesAdvSearch )
         // else, take the one from global state
         this.advSearchCriteria = auth.globalDevicesAdvSearch
    else // else, default
         this.advSearchCriteria = this.defaultAdvSearchCriteria;

/*
    if ( this.$route.params && this.$route.params.advSearchString )
         this.advSearchString = this.$route.params.advSearchString
    else if ( auth.globalDevicesAdvSearchString )
         this.advSearchString = auth.globalDevicesAdvSearchString
*/

    //console.log( "Events Devices mounted 2 advSearc " + JSON.stringify(this.advSearchCriteria));
    //console.log( "Events Devices mounted 2 advSeahStr " + JSON.stringify(this.advSearchString));

    if ( auth.globalDevicesFreeFormSearchStr )
         this.freeFormSearchStr = auth.globalDevicesFreeFormSearchStr

    this.init()

    this.get_auth_details();
    //this.advSearchCriteria = this.$route.query;
    //if (this.$route.params && this.$route.params.advSearchString)
      //this.advSearchString = this.$route.params.advSearchString;
    this.utilsDataRefreshFunc = this.getDevicesList;

    //--- socket ---
    socketService.sendMessage(this.pageName, "in hook: mounted");
    document.addEventListener("visibilitychange", this.handleVisibility, false);
  },
  activated: function () {
    this.isPageActivated = true;
    console.log(this.pageName, "in hook: " + this.pageName + " activated");
    socketService.sendMessage( this.pageName, "in hook: " + this.pageName + " activated");
    this.getDevicesList(); // Refresh (Agent) Devices list when page is activated
  },
  deactivated: function () {
    this.isPageActivated = false;
    console.log(this.pageName, "in hook: " + this.pageName + " deactivated");
    socketService.sendMessage( this.pageName, "in hook: " + this.pageName + " deactivated");
  },
  destroyed: function () {
    socketService.sendMessage( this.pageName, "in hook: destroyed. Disconnecting socket");

    // Disconnect from the Socket server on 'destroyed' hook only
    socketService.disconnectSocket(this.pageName);
    this.isConnected = false;
  },
  //===========================

  validations: {
    deviceRssh : {
        host: { required, minLength: minLength(3) },
        rport: { required, between: between(1025, 65535) },
        user: { required, minLength: minLength(3) },
        password: { required, minLength: minLength(3) },
    },
  },

  computed: {
    deleteMessage() {
      return "Do you really want to delete: " + this.currentDeviceName + " ?";
    },
    nameErrors () {
        const errors = []
        if (!this.$v.deviceRssh.host.$dirty) return errors
        !this.$v.deviceRssh.host.minLength && errors.push('Host must be atleast 3 characters long')
        !this.$v.deviceRssh.host.required && errors.push('Host is required.')
        return errors
    },
    portErrors () {
        const errors = []
        if (!this.$v.deviceRssh.rport.$dirty) return errors
        !this.$v.deviceRssh.rport.required && errors.push('Port is required.')
        !this.$v.deviceRssh.rport.between && errors.push('Port must be 1025 - 65535')
        return errors
    },
    userErrors () {
        const errors = []
        if (!this.$v.deviceRssh.user.$dirty) return errors
        !this.$v.deviceRssh.user.minLength && errors.push('User must be atleast 3 characters long')
        !this.$v.deviceRssh.user.required && errors.push('User is required.')
        return errors
    },
    passwordErrors () {
        const errors = []
        if (!this.$v.deviceRssh.password.$dirty) return errors
        !this.$v.deviceRssh.password.minLength && errors.push('Password must be atleast 3 characters long')
        !this.$v.deviceRssh.password.required && errors.push('Password is required.')
        return errors
    },
  },
  watch: {
    pagingOptions: {
      handler() {
        //console.log("options watch " + JSON.stringify(this.pagingOptions))
        sessionStorage.setItem(`_pagination${this.$route.path}`, JSON.stringify(this.pagingOptions));
        this.getDevicesList();
      },
      deep: false,
      immediate: false,
    },
  },
  methods: {
    advancedSearchCleared() {
      this.advSearchCriteria = null;
      this.getDevicesList();
    },

    // table filter functionality doesn't work unless it's rendering from native fields
    // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
    buildFilterableColumns(objs) {
      objs.forEach((obj) => {
        obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
        obj.adminStatusDisplay = (obj.adminStatus == this.UtilsModaConstants.AdminStatus.ENABLED) ?"\u2714":"\u2718"
        //obj.deviceModelName = obj.deviceModel.deviceModelName; // nested docs don't work well
        obj.deployedConfigList.forEach((prof) => {
          prof.configProfileVersionString = this.utilsFormatDateTime( prof.configProfileVersion * 1000);
          prof.configDeployedDateString = this.utilsFormatDateTime( prof.configDeployedDate * 1000);
        }, this);
      });
    },
/* NOT USED
    getDevicesLiveStatus() {
      //this.isLoading=true

      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      var body = {};
      body.deviceIds = this.device_table_rows.map((d) => d.deviceId);

      ApiService.post( "/svcStatus/api/v1/status/device", body, (err, result) => {
          //console.log(JSON.stringify(result))
          this.isLoading = false;
          if (err){
            //this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Failed to fetch liveness status";
            this.apiStatus = "Failed to fetch devices' online status";
            this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            //console.log("getDevicesLiveStatus " + JSON.stringify(result.data.data));
            var liveStatuses = result.data.data.devicesStatusList;
            // merge liveStatuses into device_table_rows
            //console.log("getDevicesLiveStatus " + JSON.stringify(liveStatuses));
            this.device_table_rows.forEach((dt) => {
              var d = liveStatuses.find((ld) => ld.deviceId == dt.deviceId);
              if (d) { 
                dt.deviceLiveStatus = d.deviceLiveStatus;
                if ( d.ipAddrs ) dt.ipAddrs = d.ipAddrs.join();
              }
            });
            //console.log("getDevicesLiveStatus " + JSON.stringify(this.device_table_rows, null, 2));

            this.liveStatusKey += 1; // increment key attribute of those elements that depend on liveStatus data so they re-render
          }
        },
        query_params, "STATUS_SERVICE_URL");
    },
*/
    getDevicesList() {
      this.isLoading=true

      let query_params = {}, body = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      query_params.pageNum = this.pagingOptions.page;
      query_params.pageSize = this.pagingOptions.itemsPerPage;
      var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, {
        statuses: "deviceLiveStatus",
        //deviceModelName: "deviceModelId",
      });
      if (sortCriteria) query_params.sortCriteria = sortCriteria;
      //if (this.filterValueSearch) query_params.searchString = "any:*:" + this.filterValueSearch;

      if ( this.freeFormSearchStr ) query_params.searchString= "any:*:" + this.freeFormSearchStr
      if (this.advSearchCriteria) {
        //console.log("getDevicesList advSearchCriteria ", JSON.stringify(this.query))
        var advSearchCriteria = [];
        Object.keys(this.advSearchCriteria).forEach((k) =>
          advSearchCriteria.push(this.advSearchCriteria[k])
        );
        if (advSearchCriteria.length) body.advSearchCriteria = advSearchCriteria;
        //console.log("getDevicesList body ", JSON.stringify(body))
      }

      //ApiService.get("/api/v1/device?shorten=true",
      ApiService.post( "/api/v1/device/page2?shorten=true", body, (err, result) => {
          this.isLoading = false;
          if (err){
            this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
            this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            //console.log("getDevicesList " + JSON.stringify(result.data.data));
            this.utilsTotalEntriesAllPages = result.data.data.totalEntries;
            this.device_table_rows = result.data.data.docs;
            //console.log("getDevicesList " + JSON.stringify(this.device_table_rows, null, 2));

            this.buildFilterableColumns(this.device_table_rows);
            //this.getDevicesLiveStatus();
          }
        },
        query_params
      );
    },
    createDevice() {
      this.$router.push({name : "MODA_AGENTS_CREATE"} )
    },
    viewDeviceDetails(id) {
      auth.crossLaunchDevicesAdvSearch = this.advSearchCriteria // save this state to come back to same search state
      this.$router.push({name : "MODA_AGENTS_VIEW", params: { id: id } } )
    },
    editDeviceDetails(id, readonly) {
      this.$router.push({name : "MODA_AGENTS_EDIT", params: { id: id, readonly: readonly } } )
    },
    navigateToSite(id) {
      var readonly = 1;
      this.$router.push({ name: "SITES_EDIT", params: { siteId: id, readonly: readonly } })
    },
    navigateToDeployedProfileSnapshot(device, prof) {
      let deployable = false;

      this.$router.push( { name: "DEVICE_DEPLOYED_SNAPSHOTS_VERSION",
                            params : { deviceId: device.deviceId,
                                       configProfileId: prof.configProfileId,
                                       configProfileName: encodeURIComponent(prof.configProfileName),
                                       configProfileVersion: prof.configProfileVersion,
                                       deployable: deployable }})
    },

    startStopRssh(deviceId, start){
        //console.log("startStopRssh " + deviceId + ", " + start + " JSON " + JSON.stringify(this.deviceRssh))
        this.deviceRssh.deviceId = deviceId
        this.deviceRssh.actionType = start ? ModaConst.MISC.ActionType.RSSH_START : ModaConst.MISC.ActionType.RSSH_STOP

        this.isLoading=true;
        let query_params = {};
        if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

        ApiService.put("/api/v1/device/rssh/"+deviceId, this.deviceRssh, (err, result) => {
            this.isLoading=false;
            if ( err ){
                this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                this.utilsCheckLogout(this.apiStatus);
            } else {
                this.apiStatus = null;
                this.getDevicesList();
            }
        }, query_params)

    },

    deleteDeviceDetailsChildEvent(cancelFlag, isBackupDel) {
      console.log( "** Delete Device:" + this.currentDeviceId + " " + this.currentDeviceName + " " + isBackupDel);
      this.open_delete_dialog = false;
      this.isLoading = false;
      if (cancelFlag == true) return;

      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      query_params.deleteMode = this.UtilsModaConstants.DeleteMode.FORCE;

      ApiService.delete( "/api/v1/device/" + this.currentDeviceId, (err, result) => {
          //console.log("**"+JSON.stringify(result))
          this.isLoading = false;
          if (err){
            this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
            this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            this.getDevicesList();
          }
        },
        query_params
      );
    },

    showMoreParams(row) {
      //console.log("showMoreParams " + JSON.stringify(row))
      this.more_params_row = row;
      this.open_more_params_dialog = true;
    },
    showDialogToDelete(id, name) {
      this.currentDeviceId = id;
      this.currentDeviceName = name;
      this.open_delete_dialog = true;
      this.isHardDelete = false;
      console.log("delete dialog called " + this.open_delete_dialog);
    },

    get_auth_details() {
      let title = this.$route.meta.title;
      //console.log('---'+title)
      this.isShow = auth.AuthService.get_usr_auth_details(title);
    },

    processBreadcrumbMessage(selectedOrgId) {
      this.clearPaginationCache();
      this.currOrgId = selectedOrgId;
      this.getDevicesList();
    },
    clearPaginationCache(){
        sessionStorage.removeItem(`_pagination${this.$route.path}`);
        this.pagingOptions.page = 1;
    },

    //---socket ---
    attachSocketMessageHandlers(self, clientSocket) {
      console.log( "attachSocketMessageHandlers: clientSocket.id = " + clientSocket ? clientSocket.id : null);

      clientSocket.on(ModaConst.Socket.Event.DEVICE_STATUS, (data) => {
        console.log( "Got an DeviceStatus event from server: data = " + JSON.stringify(data));
        self.eventData = data;
        let relevantEvent = true;
        if (relevantEvent && self.isPageVisible) {
          //DEBUG: console.log("Socket: getting devices list ...");
          self.getDevicesList();
        }
      });

      clientSocket.on("message", (data) => {
        console.log("Got message from Server: data = " + JSON.stringify(data));
        self.socketMessage = data;
      });
    },

    handleVisibility() {
      if (document.visibilityState === "hidden") {
        this.isPageVisible = false;
        this.socketMessage = "Client's Devices Page is hidden";
      } else {
        this.isPageVisible = true;
        this.socketMessage =
          "Client's Devices page is visible " + this.isPageActivated;
        if (this.isPageActivated) this.getDevicesList(); // refresh devices (w/ status) list when page becomes visible
      }
      //DEBUG:
      socketService.sendMessage(this.pageName, this.socketMessage);
    },

    processFreeFormSearchChange(freeFormSearchStr){
        this.freeFormSearchStr = freeFormSearchStr
        auth.globalDevicesFreeFormSearchStr = freeFormSearchStr
        console.log("Devices processFreeFormSearchChange " + this.freeFormSearchStr)
        this.getDevicesList();
    },
    processAdvancedFilterChange(advancedFilter){
        //console.log("Table filter selected " + JSON.stringify(advancedFilter))

        this.pagingOptions.page = 1;        // Reset page to 1st page, for AdvSearch APPLY

        // append to advanced search passed in, if any
        this.advSearchCriteria = []
        //this.advSearchString = ""

/* orgName filter removed
        if ( advancedFilter.orgNameFilter ){
            this.advSearchCriteria.push({"name":"orgName", "value": advancedFilter.orgNameFilter})
            this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.orgNameFilter)
        }
*/
        if ( advancedFilter.siteNameFilter ){
            if (advancedFilter.siteNameFilter == null || advancedFilter.siteNameFilter == [] ||
                !this.utilsIsSameList(advancedFilter.siteNameFilter, this.xLaunch.site.siteNameList)) {
                //advancedFilter.siteNameFilter != this.xLaunch.site.siteNameList)
                    // siteNameList is empty or cross-launched siteNameList is modified. 
                    // Let the backend calculate the Ids, as needed
                    this.xLaunch.site.siteIdList = null;
            }
            this.advSearchCriteria.push({"name":"site",
                                         "value": {siteIdList: this.xLaunch.site.siteIdList, siteNameList: advancedFilter.siteNameFilter}})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.siteNameFilter)
        }

        if ( advancedFilter.highestSeverityFilter ){
            this.advSearchCriteria.push({"name":"highestSeverity", "value": advancedFilter.highestSeverityFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.highestSeverityFilter)
        }
        if ( advancedFilter.devNameFilter ){
            this.advSearchCriteria.push({"name":"device",
                                         "value": {deviceIdList: null, deviceNameList: advancedFilter.devNameFilter}})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devNameFilter)
        }
        if ( advancedFilter.devMACAddressFilter ){
            this.advSearchCriteria.push({"name":"macAddress", "value": advancedFilter.devMACAddressFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devMACAddressFilter)
        }
        if ( advancedFilter.devSerialNumberFilter ){
            this.advSearchCriteria.push({"name":"serialNumber", "value": advancedFilter.devSerialNumberFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devSerialNumberFilter)
        }
        if ( advancedFilter.adminStatusFilter ){
            this.advSearchCriteria.push({"name":"adminStatus", "value": advancedFilter.adminStatusFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.adminStatusFilter)
        }
        if ( advancedFilter.devOperStatusFilter ){
            this.advSearchCriteria.push({"name":"operStatus", "value": advancedFilter.devOperStatusFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devOperStatusFilter)
        }
        if ( advancedFilter.devLiveStatusFilter ){
            this.advSearchCriteria.push({"name":"liveStatus", "value": advancedFilter.devLiveStatusFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devLiveStatusFilter)
        }
        if ( advancedFilter.devModelFilter ){
            this.advSearchCriteria.push({"name":"deviceModel", "value": advancedFilter.devModelFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.devModelFilter)
        }

        auth.globalDevicesAdvSearch       = this.advSearchCriteria;
        //auth.globalDevicesAdvSearchString = this.advSearchString;
        this.clearPaginationCache();
        this.getDevicesList();
    },
    init(){
        if ( this.advSearchCriteria ){
            //console.log("Devices.vue init this.advSearchCriteria " + JSON.stringify(this.advSearchCriteria))
            var tmp

            tmp = this.advSearchCriteria.find(e => e.name == "site")
            if ( tmp ) {
                this.tableAdvancedFilter.siteNamesFilterIn  = tmp.value.siteNameList;
                // siteNames are only to pass to this component, orgIds are sent to backend
                // send names to backend
                // this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "siteName")
                this.xLaunch.site = tmp.value;
            } else { // set default
                this.tableAdvancedFilter.siteNamesFilterIn = [];
                this.xLaunch.site = {siteIdList: [], siteNameList: []};
            }

            tmp = this.advSearchCriteria.find(e => e.name == "highestSeverity")
            if ( tmp ) this.tableAdvancedFilter.highestSeveritiesFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "device")
            if ( tmp ) this.tableAdvancedFilter.devNameFilterIn  = tmp.value.deviceNameList

            tmp = this.advSearchCriteria.find(e => e.name == "macAddress")
            if ( tmp ) this.tableAdvancedFilter.devMACAddressFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "serialNumber")
            if ( tmp ) this.tableAdvancedFilter.devSerialNumberFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "adminStatus")
            if ( tmp ) this.tableAdvancedFilter.adminStatusFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "operStatus")
            if ( tmp ) this.tableAdvancedFilter.devOperStatusFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "liveStatus")
            if ( tmp ) this.tableAdvancedFilter.devLiveStatusFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "deviceModel")
            if ( tmp ) this.tableAdvancedFilter.devModelFilterIn  = tmp.value
        }

        this.$refs.tableAdvancedFilter.setAdvancedFilters(this.tableAdvancedFilter, this.freeFormSearchStr)
    },

  },
};
</script>
