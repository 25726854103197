<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-chart-donut</v-icon> Profile Usage
				<v-btn rounded outlined color="primary" absolute right :class="isShow" @click="createDeviceConfigMap()">
					<v-icon>mdi-plus</v-icon> New
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<div class="search-wrapper">
					<v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify"
						@input="filterChanged" />
				</div>
			</v-card-text>
			<v-container fluid class="pt-0">
				<v-data-table :headers="deviceConfigMap_table_columns" :search="filterValueSearch" :loading="isLoading"
					:items="deviceConfigMap_table_rows" item-key="deviceConfigMapId">
					<template v-slot:item.assignedTo="{ item }">
						<!-- assignedTo not array
						<a href="javascript:;" :title="props.row.assignedTo.targetName"
																v-on:click="navigateToAssignedTo(props.row.targetType, props.row.assignedTo.targetId)">
								{{props.row.assignedTo.targetName}}
						</a>-->
						<span v-for="target in item.assignedTo" :key="target.targetId">
							<a class="link-text" :title="target.targetName"
								v-on:click="navigateToAssignedTo(item.targetType, target.targetId)">
								{{target.targetName}}
							</a>
						</span>
					</template>

					<template v-slot:item.configProfile.configProfileName="{ item }">
						<a class="link-text" :title="item.configProfile.configProfileName"
							v-on:click="navigateToProfile(item.configProfileType, item.configProfile.configProfileId)">
							<span> {{item.configProfile.configProfileName}} <sup v-if="utilsIsSystem(item)"> <small>(Sys)
									</small></sup>
							</span>
						</a>
					</template>

					<template v-slot:item.profileParameters="{ item }">
						<div v-if="item.configProfileType == UtilsModaConstants.ConfigProfileType.ANALYTICS">
							<div v-if='item.configProfile.applications'>
								<label>Applications : </label>
								<span v-for="app in item.configProfile.applications" :key="app.appId">
									<a class="link-text mr-2" :title="app.appName" v-on:click="navigateToApplication(app.appId)">
										{{app.appName}}
									</a>
								</span>
							</div>

							<div v-if='item.configProfile.syslog'>
								<label>Syslog : </label>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on" x-large
											:color="utilsSyslogStatusDisplayObject(item.configProfile.syslog.receive).colorName">
											{{utilsSyslogStatusDisplayObject(item.configProfile.syslog.receive).icon}}
										</v-icon>
									</template>
									<span>{{utilsSyslogStatusDisplayObject(item.configProfile.syslog.receive).text}}</span>
								</v-tooltip>
								Port : {{ item.configProfile.syslog.listenPort }}
							</div>
						</div>
						<span v-else-if="item.configProfileType == UtilsModaConstants.ConfigProfileType.SYSTEM">
							<span v-if="item.configProfile.profileDetails.statsReportingIntervalSecs">
								Stats Reporting Interval: {{ item.configProfile.profileDetails.statsReportingIntervalSecs }} secs
								<br>
							</span>
							<span v-if="item.configProfile.profileDetails.lte">
								<span v-if="item.configProfile.profileDetails.lte.lte0">
									LTE0:
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-icon v-bind="attrs" v-on="on" x-large
												:color="utilsSyslogStatusDisplayObject(item.configProfile.profileDetails.lte.lte0.adminStatus).colorName">
												{{utilsSyslogStatusDisplayObject(item.configProfile.profileDetails.lte.lte0.adminStatus).icon}}
											</v-icon>
										</template>
										<span>{{utilsSyslogStatusDisplayObject(item.configProfile.profileDetails.lte.lte0.adminStatus).text}}</span>
									</v-tooltip>
									{{ item.configProfile.profileDetails.lte.lte0.apn + ", " +
									item.configProfile.profileDetails.lte.lte0.preferredSIMSlot }}
								</span>
							</span>
						</span>
					</template>

					<template v-slot:item.createdBy.email="{ item }">
						<a class="link-text" :title="item.createdBy.email" v-on:click="navigateToUser(item.createdBy.userId)">
							{{item.createdBy.email}}
						</a>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-icon @click="viewDeviceConfigMapDetails(item.deviceConfigMapId)" class="mr-2" color="primary"
							title="View">mdi-eye</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)" @click="editDeviceConfigMapDetails(item.deviceConfigMapId, 0)" class="mr-2" color="warning"
							title="Edit" > mdi-pencil</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)" @click="deleteDeviceConfigMapDetails(item.deviceConfigMapId, item.assignedTo)" color="error"
							title="Delete">mdi-delete</v-icon>
					</template>
				</v-data-table>
			</v-container>
		</v-card>

		<DeleteDialog v-if="deleteDialog" :currentObjectName="currentObjectName" :isBackupBeforeDelete="false"
			@DeleteDialogCallback='deleteDialogEventCallback' :delMessage="deleteMessage">
		</DeleteDialog>

	</div>
</template>

<script>

import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import AdsTreeTableUtils from '@/pages/moda/AdsTreeTableUtils.vue'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'

export default {
    components: {
        Breadcrumb,
        DeleteDialog,
    },
    mixins: [
        AdsTreeTableUtils,
        Utils,
    ],
    data(){
        var deviceConfigMap_table_columns= [
           {
               //title: 'Assigned To',
               //property: 'assignedTo',
               //direction: true,
               //filterable: true,

               text: 'Assigned To',
               align: 'start',
               sortable: true,
               value: 'assignedTo',

           },
           {
               //title: 'Profile Name',
               //property: 'configProfileName',
               //direction: true,
               //filterable: true,

               text: 'Profile Name',
               value: 'configProfile.configProfileName',
               sortable: true,
           },
           {
               //title: 'Profile Type',
               //property: 'configProfileType',
               //direction: true,
               //filterable: true,

               text: 'Profile Type',
               value: 'configProfileType',
               sortable: true,
           },
           {
               //title: 'Profile Belongs To',
               //property: 'configProfileOrgName',
               //direction: true,
               //filterable: true,

               text: 'Profile Belongs To',
               value: 'configProfileOrgName',
               sortable: true,
           },
           {
               //title: 'Profile Parameters',
               //property: 'profileParameters',
               //direction: true,
               //filterable: true,

               text: 'Profile Parameters',
               value: 'profileParameters',
               sortable: true,
           },
           {
               //title: 'Created By',
               //property: 'createdBy',
               //direction: true,
               //filterable: true,

               text: 'Created By',
               value: 'createdBy.email',
               sortable: true,
           },
           {
               //title: 'Creation Time',
               //property: 'createdDateString',
               //direction: true,
               //filterable: true,

               text: 'Creation Time',
               value: 'createdDateString',
               sortable: true,
           },
           {
               //title: 'Actions',
               //property: 'actions',

               text: 'Actions',
               value: 'actions',
								width: 120
           },
        ]
        return {
            currOrgId: null,
            filter: {},

            deviceConfigMap_table_columns,
            deviceConfigMap_table_rows: [],

            isLoading: false,

            userdetails:{},
            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Profiles & Jobs"},
                           {name:'Profile Usage', active:true} ],
            apiStatus: null,
			currentObjectId:null,
			currentObjectName:null,
			deleteMessage:null,
			deleteDialog:false,
        };
    },
    methods: {
        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
        buildFilterableColumns(objs){
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                //obj.configProfileName = obj.configProfile.configProfileName;
                obj.configProfileOrgName = obj.configProfile.orgName;
                if ( obj._children ) this.buildFilterableColumns(obj._children);
            })
        },
        navigateToProfile(type, id){
            var readonly = 1
            if ( type == this.UtilsModaConstants.ConfigProfileType.SYSTEM)
                this.$router.push( { name : "SYS_CONFIG_PROFILES_EDIT", params : { systemConfigProfileId: id, readonly: readonly}})
            else if ( type == this.UtilsModaConstants.ConfigProfileType.ANALYTICS)
                this.$router.push( { name : "ANAL_CONFIG_PROFILES_EDIT", params : { analyticsConfigProfileId: id, readonly: readonly}})
        },
        navigateToApplication(id){
            var readonly = 1
            this.$router.push({name: "APPLICATIONS_EDIT", params: { appId: id, readonly: readonly} } )
        },
        navigateToAssignedTo(type, id){
            var readonly = 1
            if ( type == "Device")
                this.$router.push({name : "MODA_AGENTS_EDIT", params: { id: id, readonly: readonly } } )
            else if ( type == "Site")
                this.$router.push({ name: "SITES_EDIT", params: { siteId: id, readonly: readonly } })
            else if ( type == "Organization")
                this.$router.push({name:"ORGS_EDIT", params : {id: id, readonly: readonly}})
            else if ( type == "OrganizationGroup")
                this.$router.push('/config/orgsGroups/edit/'+id+ "/" + readonly)
        },
        navigateToUser(id){
            var readonly = 1
            this.$router.push( { name: "USERS_EDIT", params : { id: id, readonly: readonly }})
        },
        getDeviceConfigMapList(){
            this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            //ApiService.post("/api/v1/deviceConfigMap/filtered", this.filter, (err, result) =>
            ApiService.get("/api/v1/deviceConfigMap", (err, result) =>
            {
                this.isLoading=false
                //console.log("getDeviceConfigMapList " + JSON.stringify(result.data));
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.deviceConfigMap_table_rows=result.data.data
                    this.buildFilterableColumns(this.deviceConfigMap_table_rows);
                }

            }, query_params)
        },
        viewDeviceConfigMapDetails(id, readonly){
            this.$router.push( { name: "DEVICE_CONFIG_MAP_VIEW", params : { deviceConfigMapId: id}})
        },
        editDeviceConfigMapDetails(id, readonly){
            this.$router.push( { name: "DEVICE_CONFIG_MAP_EDIT", params : { deviceConfigMapId: id, readonly: readonly}})
        },
        deleteDeviceConfigMapDetails(Id, assignedTo){
            var name = ''
            assignedTo.forEach((target) => name += target.targetName + " ")
            if ( ! name || name == '' ) name = "Do you want to really want to delete unassigned ?"
            else name = "Do you really want to delete '" + name + "' usage ?"
            this.deleteMessage = name ; // if(confirm(name))
            this.currentObjectId = Id;
			this.deleteDialog = true;
        },
        deleteDialogEventCallback(cancelFlag, isHard) 
		{
			console.log("** delete id "+this.currentObjectId + ' ' + isHard);
			this.deleteDialog = false;
			if(cancelFlag === true) {
					return;
			}

			let query_params = {};
			if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

            this.isLoading =true;
            query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

            ApiService.delete("/api/v1/deviceConfigMap/"+ this.currentObjectId, (err, result) => {
                    //console.log("**"+JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getDeviceConfigMapList();
                    }
            }, query_params)
        },
        createDeviceConfigMap(){
            this.$router.push( { name: "DEVICE_CONFIG_MAP_CREATE"})
        },
        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.getDeviceConfigMapList();
        },
    },
/*
    created(){
        this.utilsGetModaConstants();
    },
*/
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();

        //this.filter.configProfileName = this.$route.params.configProfileName; 
        if ( this.$route.params.configProfileName ) {
            this.filterValueSearch = decodeURIComponent(this.$route.params.configProfileName);
            this.filterChanged(this.filterValueSearch)
        }

        this.get_auth_details();
        this.getDeviceConfigMapList();
    }
}
</script>
