<template>
	<v-toolbar light class="p-0" :elevation="0">
		<div class="moda-logo"></div>
		<v-toolbar-title class="black--text">MODA Technologies</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-menu offset-y tile transition="slide-y-transition">
			<template v-slot:activator="{ attrs, on }">
				<v-btn class="ma-0" medium tile text v-bind="attrs" v-on="on">
					<v-avatar size="36">
						<v-icon>
							mdi-account-circle
						</v-icon>
					</v-avatar> {{ username }}
					<v-icon right>mdi-chevron-down</v-icon>
				</v-btn>
			</template>
			<v-list>
				<v-list-item link>
					<v-list-item-title @click="editCurrentUser()" v-text="editText + ' Profile'"></v-list-item-title>
				</v-list-item>
				<v-list-item link>
					<v-list-item-title v-text="'Logout'" @click="logOut()"></v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</v-toolbar>
</template>

<script>

import auth from '@/services/auth.service'

export default {
	name: 'Header',
	components: {
	},
	data() {
		return {
			username: "",
			userId: undefined,
			isShow: 'disply-none',
            editText: "Edit",
		}
	},
	methods: {
		editCurrentUser() {
            if ( this.isShow == 'display-none' )
                this.$router.push( { name: "USERS_VIEW", params : { id: this.userId }})
            else
                this.$router.push( { name: "USERS_EDIT", params : { id: this.userId, readonly: 0 }})
		},
		logOut() {
			localStorage.clear();
			sessionStorage.clear();
            this.$msal.saveCustomData("modastate", "LOGGEDOUT"); // call msal PLUGIN$
            //if ( this.$msal.isAuthenticated() ) this.$msal.signOut(); // logout MSAL also - not redirecting to logoutUrl, so commented out
			this.$router.push({ path: '/login' })
		}
	},
	created() {
		var userdetails = JSON.parse(localStorage.getItem('user-details'));
        this.isShow = auth.AuthService.get_usr_auth_details("Header")
        if ( this.isShow == 'display-none' ) this.editText = "View"
		this.username = userdetails ? (userdetails.firstName || userdetails.email.split("@")[0])+ ' ' + userdetails.lastName : ""
		this.userId = userdetails.userId
	}
}
</script>
