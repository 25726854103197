<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="5" class="tile-box">
      <v-card-title class="page-title">
        <v-icon>mdi-table-cog</v-icon> Device Models
      </v-card-title>
      <v-card-text class="pb-0">
        <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
          {{ apiStatus }}
        </v-alert>
        <div class="search-wrapper">
          <v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify"
            @input="utilsFilterChanged" />
        </div>
      </v-card-text>
      <v-container fluid class="pt-0">
        <v-data-table :loading="isLoading" :headers="deviceModels_table_vuetify_columns" :items="deviceModels"
          :options.sync="pagingOptions" :server-items-length="utilsTotalEntriesAllPages" multi-sort
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }" item-key="deviceModelId">
          <template v-slot:item.moreParams="{ item }">
            <v-icon small @click="showMoreParams(item)" title="More">
              fas fa-ellipsis-h
            </v-icon>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <!-- begin More Params dialogs -->
    <v-dialog v-model="open_more_params_dialog" max-width="1000px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4"> Properties: </v-col>
            <v-col cols="12" md="8">
              {{ more_params_row.properties }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="open_more_params_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import auth from "@/services/auth.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import Utils from "@/pages/moda/Utils.vue";

export default {
  components: {
    Breadcrumb,
  },
  mixins: [Utils],
  data() {
    var deviceModels_table_vuetify_columns = [
      {
        text: "ID",
        value: "deviceModelId",
        sortable: true,
        filterable: true,
      },
      {
        text: "Name",
        value: "deviceModelName",
        sortable: true,
        filterable: true,
      },
      {
        text: "Type",
        value: "deviceModelType",
        sortable: true,
        filterable: true,
      },
      {
        text: "OS",
        value: "deviceModelOS",
        sortable: true,
        filterable: true,
      },
      {
        text: "Profiles supp",
        value: "configProfileTypesSupported",
        sortable: true,
        filterable: true,
      },
      {
        text: "Manufacturer",
        value: "manufacturer",
        sortable: true,
        filterable: true,
      },
      {
        text: "Created",
        value: "createdDateString",
        sortable: true,
        filterable: true,
      },
      {
        text: 'More',
        value: 'moreParams',
      },
/*
      {
        text: "Properties",
        value: "properties",
        sortable: true,
        filterable: true,
      },
*/
    ];

    return {
      currOrgId: null,

      deviceModels_table_vuetify_columns,
      deviceModels: [],
      dirty: false,
      readonly: false,

      isLoading: false,

      userdetails: {},
      isShow: "display-none",
      parent_pages: [{ name: "Home" }, {name: "Admin Tasks"}, { name: "Device Models", active: true }],
      apiStatus: null,
      currId: null,
      currName: null,

      more_params_row: {},
      open_more_params_dialog: false,

      pagingOptions: sessionStorage.getItem(`_pagination${this.$route.path}`) ? JSON.parse(sessionStorage.getItem(`_pagination${this.$route.path}`)) : this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
      //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable
    };
  },
  watch: {
    pagingOptions: {
      handler() {
        sessionStorage.setItem(`_pagination${this.$route.path}`, JSON.stringify(this.pagingOptions));
        this.getDeviceModels();
      },
      deep: false,
      immediate: false,
    },
  },
  methods: {
    // table filter functionality doesn't work unless it's rendering from native fields
    // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
    buildFilterableColumns(objs) {
        objs.forEach( (obj) =>{
            if ( obj.createdDate ) obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
            if ( obj.properties ) obj.properties = JSON.stringify(obj.properties)
        })
    },

    showMoreParams(row) {
        //console.log("showMoreParams " + JSON.stringify(row))
        this.more_params_row = row;
        this.open_more_params_dialog = true;
    },

    getDeviceModels() {
      this.isLoading=true
      let query_params = {}
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      if (this.filterValueSearch)
        query_params.searchString = "any:*:" + this.filterValueSearch;
      
      var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, {} )
      if ( sortCriteria ) query_params.sortCriteria = sortCriteria

      ApiService.get( "/api/v1/deviceModel", (err, result) => {
          this.isLoading = false;
          if (err){
            this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
            this.utilsCheckLogout(this.apiStatus)
          }else {
            this.apiStatus = null;

            this.deviceModels = result.data.data;
            this.utilsTotalEntriesAllPages = this.deviceModels.length

            this.buildFilterableColumns(this.deviceModels);
            //console.log("getDeviceModels " + JSON.stringify(this.deviceModels));
          }
        },
        query_params
      );
    },

    get_auth_details() {
      let title = this.$route.meta.title;
      this.isShow = auth.AuthService.get_usr_auth_details(title);
    },
    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      this.clearPaginationCache();
      this.getDeviceModels();
    },
    clearPaginationCache(){
      sessionStorage.removeItem(`_pagination${this.$route.path}`);
      this.pagingOptions.page = 1;
    },
  },
  mounted() {
    this.readonly = false
    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    this.get_auth_details();
    this.utilsDataRefreshFunc = this.getDeviceModels;
    //this.getDeviceModels();
  },
};
</script>
