<template>
	<div>
		<v-container fluid class="breadcrumb-wrapper">
			<v-row>
				<v-col class="ma-0 pa-0">
					<v-icon small class="float-left org-icon">mdi-home-city</v-icon>
					<v-breadcrumbs class="float-left org-breadcrumbs pt-3" :items="breadcrumb_arr">
						<template v-slot:item="{ item, index }">
							<v-breadcrumbs-item href="javascript:;" @click="breadCrumbNavigation(index, item)">
								{{item.orgName}}
							</v-breadcrumbs-item>
						</template>
					</v-breadcrumbs>
					<v-btn icon class="float-left mt-2" small @click="init();open_dialog=true">
						<v-icon>mdi-fast-forward</v-icon>
					</v-btn>
					<v-btn icon title="Refresh" class="float-left mt-2" small @click="doRefresh()">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
					<v-breadcrumbs class="float-right pt-3" :items="parent_pages">
						<template v-slot:divider>
							<v-icon>mdi-chevron-right</v-icon>
						</template>
						<template v-slot:item="{ item }">
							<v-breadcrumbs-item v-if="item.route" href="javascript:;" v-on:click="goTo(item.route)">
								{{item.name}}
							</v-breadcrumbs-item>
							<v-breadcrumbs-item v-else>{{item.name}}</v-breadcrumbs-item>
						</template>
					</v-breadcrumbs>
				</v-col>
			</v-row>
		</v-container>
		<!-- end breadcrumb -->

		<!-- begin modal -->
		<v-dialog v-model="open_dialog" scrollable max-width="500px">
			<v-card>
				<v-card-title>
					Select Organization
				</v-card-title>
				<v-card-text class="pb-0">
					<div class="search-wrapper">
						<v-text-field v-model="org_search" label="Search" clearable
						prepend-inner-icon="mdi-magnify" clear-icon="mdi-close"></v-text-field>
					</div>
					<v-treeview class="mb-5" v-model="modal_selected_orgs" :items="all_orgs_hierarchy" :open=all_orgs_hierarchy
						item-key="orgId" item-text="orgName" item-children="_children" selection-type="independent" selectable
						return-object open-on-click :search="org_search" @input="modalOrgClicked">
					</v-treeview>
					<v-divider></v-divider>
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn outlined rounded color="success" @click="open_dialog = false;modalOrgSelected()">Select</v-btn>
					<v-btn outlined rounded color="error" @click="open_dialog = false"> Cancel </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- end modal -->
	</div>
</template>
<script>

import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Utils from "@/pages/moda/Utils.vue";
//import { ModelSelect } from 'vue-search-select'

export default {
    name : "Breadcrumb",
    props : {
        parent_pages: Array,
    },
    components: {
    },
    data(){
        return {
            all_orgs_hierarchy: [],
            breadcrumb_arr :[],
            prev_modal_selected_orgs: [],
            modal_selected_orgs: [],
            isLoading: false,
            loggedin_org:null,
            userdetails:null,
            open_dialog: false,
            org_search: null,
            apiStatus: null,
            modal_popup: [
                {
                    text: 'Modal Popup',
                    disabled: false,
                    href: ';',
                },
            ],
        };
    },
    mixins: [Utils],
    methods: {
        // called by other components to forcefully navigate to a specific org
        navigateToOrg (targetOrgId){
            this.currOrgId = targetOrgId
            this.getOrganizationsList(targetOrgId)
        },
        // called to popup modal
        modalOrgClicked(){
            let new_selection = this.modal_selected_orgs.filter(x => (this.prev_modal_selected_orgs.findIndex(y => x.orgId === y.orgId) == -1))

            if ( (this.prev_modal_selected_orgs.length == this.modal_selected_orgs.length) && new_selection.length == 0 )
                // both arrays exactly same. So, it's our own set below causing the loopback event. Ignore it
                return;

            //console.log("modalOrgClicked " +
                            //" prev_modal_selected_orgs " + JSON.stringify(this.prev_modal_selected_orgs) + 
                            //" ;  new modal_selected_orgs " + JSON.stringify(this.modal_selected_orgs) + 
                            //" ;  diff " + JSON.stringify(new_selection));

            this.modal_selected_orgs = new_selection;
            this.prev_modal_selected_orgs = this.modal_selected_orgs
        },
        // called by parent, or when an org selected in popup, or when a org clicked in breadcrumb
        getOrganizationsList(targetOrgId){
            let query_params = {};
            if ( targetOrgId ) query_params.targetOrgId = targetOrgId;
            //this.isLoading=true
            this.all_orgs_hierarchy = []
            //this.site_options = []

            ApiService.get("/api/v1/org", (err, result) => {
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    //console.log("Breadcrumb getOrganizationsList result " + JSON.stringify(result));
                    this.apiStatus = null;
                    // populate popup modal org list
                    this.all_orgs_hierarchy = result.data.data._children;
                    this.breadCrumbUpdate(result.data.data._ancestors, this.all_orgs_hierarchy[0]);
                    //console.log("Breadcrumb getOrganizationsList all_orgs_hierarchy " + JSON.stringify(this.all_orgs_hierarchy));
                }
            }, query_params)
        },
        // internal routine to update breadcrumb
        breadCrumbUpdate(orgs_list, currOrg){

            this.currOrgName = currOrg.orgName

            this.breadcrumb_arr = []
            let clickable = false;
            orgs_list.forEach((org) =>{
                // make all ancestors of logged in org not clickable and children clickable
                if ( org.orgId == this.loggedin_org.orgId ) clickable = true
                this.breadcrumb_arr.push({orgId:org.orgId,orgName:org.orgName,type:'org', clickable: clickable})
            }, this)
            this.breadcrumb_arr.push(currOrg);
            auth.globalBreadcrumb = this.breadcrumb_arr;
            auth.globalOrgsList = this.all_orgs_hierarchy;
            //console.log("breadCrumbUpdate " + JSON.stringify(this.breadcrumb_arr));
        },
        getLastBreadcrumbOrgId(){
            return this.breadcrumb_arr.length > 0 ? this.breadcrumb_arr[this.breadcrumb_arr.length-1].orgId : this.loggedin_org.orgId;
        },
        getLastBreadcrumbOrgName(){
            return this.breadcrumb_arr.length > 0 ? this.breadcrumb_arr[this.breadcrumb_arr.length-1].orgName : this.loggedin_org.orgName;
        },
        breadCrumbNavigation(index, item){
            //console.log("In breadCrumbNavigation " + index + " " + JSON.stringify(item));
            //if ( index < this.breadcrumb_arr.length - 1 ) {
                this.currOrgId = item.orgId
                this.currOrgName = item.orgName
                this.sendMessageToParent(item.orgId, item.orgName);
                this.getOrganizationsList(item.orgId);
            //}
        },
        modalOrgSelected(){
            if ( this.modal_selected_orgs[0] ) {
                this.currOrgId = this.modal_selected_orgs[0].orgId
                this.currOrgId = this.modal_selected_orgs[0].orgName
                this.sendMessageToParent(this.modal_selected_orgs[0].orgId, this.modal_selected_orgs[0].orgName);
                this.getOrganizationsList(this.modal_selected_orgs[0].orgId);
            }
        },
        sendMessageToParent(selectedOrgId, selectedOrgName){
            this.$emit('messageFromBreadcrumb', selectedOrgId, selectedOrgName);
        },
        goTo(route){
            this.$router.push(route);
        },
        doRefresh(){
            //console.log("Breadcrumb doRefresh")
            this.sendMessageToParent(this.currOrgId, this.currOrgName)
        },
        init() { // also called when modal repeatedly opened
            this.org_search = null
            this.modal_selected_orgs = [];
            if ( this.breadcrumb_arr.length > 0 ) {
                var curr = this.breadcrumb_arr[this.breadcrumb_arr.length-1];
                this.modal_selected_orgs.push({orgId:curr.orgId, orgName: curr.orgName});
            }
            this.prev_modal_selected_orgs = this.modal_selected_orgs;
        },
    },
    created() {
        this.userdetails =  auth.AuthService.get_usrdetails(); 
        this.loggedin_org = this.userdetails.orgInfo

        if(auth.globalOrgsList && auth.globalOrgsList.length > 0)
            this.all_orgs_hierarchy = auth.globalOrgsList;

        if(auth.globalBreadcrumb && auth.globalBreadcrumb.length > 0)
            this.breadcrumb_arr = auth.globalBreadcrumb;
        else
            this.getOrganizationsList(this.loggedin_org.orgId);

        this.init();
        this.currOrgId = this.getLastBreadcrumbOrgId()
        this.currOrgName = this.getLastBreadcrumbOrgName()
    }
}
</script>
