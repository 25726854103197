<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-account</v-icon> Users
				<v-btn rounded outlined color="primary" absolute right :class="isShow" @click="createUser()">
					<v-icon>mdi-plus</v-icon> New
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<div class="search-wrapper">
					<v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify" />
				</div>
			</v-card-text>
			<v-container fluid class="pt-0">
				<v-data-table :loading="isLoading" :headers="users_table_columns" :items="users_table_rows"
					:search="filterValueSearch" :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }"
					item-key="monTemplateId">

					<template v-slot:item.email="{ item }">
						<p>
							<span class="float-left ml-4">{{ item.email }}
								<v-chip v-if="utilsIsSystem(item)" x-small>SYS</v-chip>
							</span>
						</p>
					</template>

					<template v-slot:item.name="{ item }">
						<span v-if="item.lastName"> {{ item.lastName }}, &nbsp; </span>
						<span v-if="item.firstName"> {{ item.firstName }} </span>
					</template>

					<template v-slot:item.authProvider="{ item }">
						<span>{{ authProvider_list[item.authProvider] }}</span>
					</template>

					<template v-slot:item.permissions="{ item }">
						<span v-for="pbac in item.pbac" :key="pbac.pbacId">
							[
							<a class="link-text" v-on:click="navigateToPolicy(pbac.policy.policyId)">
								{{ pbac.policy.policyName }} </a>
							<span v-if="pbac.targetOrg.orgName"> --- </span>
							<a class="link-text" v-on:click="navigateToOrg(pbac.targetOrg.orgId)">
								{{ pbac.targetOrg.orgName }} </a>
							<span v-if="pbac.orgGroups.length"> --- </span>
							<span v-for="(orgGroup, index) in pbac.orgGroups" :key="orgGroup.orgGroupId">
								<span v-if="index"> , </span>
								<a class="link-text" v-on:click="navigateToOrgGroup(pbac.targetOrg.orgId, orgGroup.orgGroupId)">
									{{ orgGroup.orgGroupName }} </a>
							</span>
							<span v-if="pbac.siteGroups.length"> --- </span>
							<span v-for="(siteGroup, index) in pbac.siteGroups" :key="siteGroup.siteGroupId">
								<span v-if="index"> , </span>
								<a class="link-text" v-on:click="navigateToSiteGroup(pbac.targetOrg.orgId, siteGroup.siteGroupId)">
									{{ siteGroup.siteGroupName }} </a>
							</span>
							<span v-if="pbac.sites.length"> --- </span>
							<span v-for="(site, index) in pbac.sites" :key="site.siteId">
								<span v-if="index"> , </span>
								<a class="link-text" v-on:click="navigateToSite(pbac.targetOrg.orgId, site.siteId)">
									{{ site.siteName }}
								</a>
							</span>
							]
						</span>
					</template>

					<template v-slot:item.adminStatus="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" x-large
									:color="utilsAdminStatusDisplayObject(item.adminStatus).colorName">
									{{ utilsAdminStatusDisplayObject(item.adminStatus).icon }}
								</v-icon>
							</template>
							<span>{{ utilsAdminStatusDisplayObject(item.adminStatus).text }}</span>
						</v-tooltip>
					</template>

					<template :class="isShow" v-slot:item.actions="{ item }">
						<v-icon @click="viewUserDetails(item.userId)" class="mr-2" color="primary" title="View">mdi-eye</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)" @click="editUserDetails(item.userId, 0)" class="mr-2"
							color="warning" title="Edit">mdi-pencil</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)" @click="deleteUserDetails(item.userId, item.email)"
							color="error" title="Delete">mdi-delete</v-icon>
					</template>

				</v-data-table>
			</v-container>
		</v-card>

		<DeleteDialog v-if="deleteDialog" :currentObjectName="currName" @DeleteDialogCallback='deleteObjectChildEvent'>
		</DeleteDialog>
	</div>
</template>

<script>

import UserApiService from '@/services/user-api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import ModaConst from '../../services/ModaConstants.js'

export default {
    components: {
        Breadcrumb,
        DeleteDialog,
    },
    mixins: [
        Utils,
    ],
    data(){
        var users_table_columns= [
           {
               text: 'Email',
               value: 'email',
               direction: true,
               filterable: true,
           },
           {
						text: 'Name',
						value: 'name',
               direction: true,
               filterable: true,
           },
           {
						text: 'Phone',
						value: 'phone',
               direction: true,
               filterable: true,
           },
           {
						text: 'Permissions',
						value: 'permissions',
               direction: true,
               filterable: true,
           },
           {
               text: 'Authentication Provider',
               value: 'authProvider',
               direction: true,
               filterable: true,
           },
           {
						text: 'Enabled',
						value: 'adminStatus',
               direction: true,
               filterable: true,
           },
           {
						text: 'Creation Time',
						value: 'createdDateString',
               direction: true,
               filterable: true,
           },
           {
						text: 'Actions',
						value: 'actions',
           },
        ]
        let authProvider_list = {}
        authProvider_list[ModaConst.AuthProvider.LOCAL] = "Local"
        authProvider_list[ModaConst.AuthProvider.MICROSOFT] = "Microsoft"       // "MicrosoftEntra/Office365/AzureAD"
        return {
            currOrgId: null,

            users_table_columns,
            users_table_rows: [],

            isLoading: false,
            userdetails:{},
            authProvider_list,
            isShow:'display-none',
            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Access Control"},
                           {name:'Users', active:true} ],
            users_policies_list:[],
            apiStatus: null,
				deleteDialog:null,
				currId:null,
				currName:null,
        };
    },

    methods: {
        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
        buildFilterableColumns(objs){
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                if ( obj._children ) this.buildFilterableColumns(obj._children);
            })
        },
        getUsersPolicyList(){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            UserApiService.get("/usersvc/api/v1/policy", (err, result) => {
                //this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.users_policies_list = result.data.data
                    this.permissions_names = this.users_policies_list.map(a => {
                                            return {policyId:a.policyId,text: a.policyName };
                                         });
                    this.getUsersList();
                }
            }, query_params)
        },
        getUsersList(){
            this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            UserApiService.get("/usersvc/api/v1/users", (err, result) => {
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.users_table_rows=result.data.data
                    this.buildFilterableColumns(this.users_table_rows);
                }
            }, query_params)
        },
        viewUserDetails(id){
            this.$router.push( { name: "USERS_VIEW", params : { id: id }})
        },
        editUserDetails(id, readonly){
            this.$router.push( { name: "USERS_EDIT", params : { id: id, readonly: readonly }})
        },
        deleteUserDetails(Id, name) {
			this.deleteDialog = true;
			this.currId = Id;
			this.currName = name;
		},

		deleteObjectChildEvent(cancelFlag, isBackupDel) {
				this.deleteDialog = false;
				if(cancelFlag == true) return;
				console.log("** delete " + isBackupDel);

            // if(confirm("Do you really want to delete - '" + name + "' ?"))
			{
                this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
                query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

                UserApiService.delete("/usersvc/api/v1/users/"+this.currId, (err, result) => {
                    //console.log("**"+JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getUsersList();
                    }
                }, query_params)
            }
        },
        createUser(){
            this.$router.push( { name: "USERS_CREATE"})
        },
        navigateToPolicy(id){
            var readonly = 1
            this.$router.push( { name : "USER_POLICIES_EDIT", params: { id: id, readonly: readonly }})
        },
        navigateToOrg(id){
            var readonly = 1
            this.$router.push({name:"ORGS_EDIT", params : {id: id, readonly: readonly}})
        },
        navigateToOrgGroup(targetOrgId, id){
            var readonly = 1
            this.$router.push('/config/orgsGroups/edit/'+id+ "/" + readonly + "/" + targetOrgId)
        },
        navigateToSiteGroup(targetOrgId, id){
            var readonly = 1
            this.$router.push( { name : "SITE_GROUPS_EDIT_TARGETORG", params : { siteGroupId: id, redaonly: readonly, targetOrgId: targetOrgId } } )
        },
        navigateToSite(targetOrgId, id){
            var readonly = 1
            this.$router.push({ name: "SITES_EDIT_TARGETORG", params: { siteId: id, readonly: readonly, targetOrgId: targetOrgId } })
        },
        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.getUsersPolicyList();
        },
    },

    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.get_auth_details();
        this.getUsersPolicyList();
    }
}
</script>
