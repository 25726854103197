<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="5" class="tile-box">
      <v-card-title class="page-title">
        <v-icon>mdi-home-city</v-icon> Organizations
        <v-btn rounded outlined absolute right color="primary" :class="isShow" v-on:click="createOrganization()">
          <v-icon>mdi-plus</v-icon> New
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
          {{  apiStatus  }}
        </v-alert>
        <TableAdvancedFilter ref="tableAdvancedFilter" :enabledFieldsIn="tableAdvancedFilter.enabledFilterFields"
          v-on:freeFormSearchChanged="processFreeFormSearchChange"
          v-on:advancedFilterChanged="processAdvancedFilterChange" />
      </v-card-text>
      <v-container fluid class="pt-0">
        <v-row v-if="isLoading">
          <v-col cols="12">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-col>
        </v-row>
        <div class="v-data-table">
          <div class="v-data-table__wrapper">
            <vue-ads-table :columns="org_table_columns" :rows="org_table_rows" class="grid-tree-view mb-5">
              <template slot="sort-icon" slot-scope="props">
                <v-icon small>{{  props.direction === null ? '' : (props.direction ? 'mdi-arrow-up' : 'mdi-arrow-down') 
                  }}
                </v-icon>
              </template>
              <template slot="orgName" slot-scope="props">
                <a href="javascript:;" :title="props.row.orgName" v-on:click="navigateToOrg(props.row.orgId)">
                  {{  props.row.orgName  }}
                </a>
              </template>
              <template slot="parentOrgName" slot-scope="props">
                <a v-if="props.row.parentClickable" href="javascript:;" :title="props.row.parentOrgName"
                  v-on:click="navigateToOrg(props.row.parentOrgId)">
                  {{  props.row.parentOrgName  }}
                </a>
                <span v-else> {{ props.row.parentOrgName }} </span>
              </template>
              <template slot="counts" slot-scope="props">
                <a v-if="props.row.siteCount" href="javascript:;" title="Sites" v-on:click="navigateToSites(props.row)">
                  {{  props.row.siteCount  }}
                </a>
                <span v-else> {{  props.row.siteCount  }} </span>
                /
                <a v-if="props.row.applicationCount" href="javascript:;" title="Apps"
                  v-on:click="navigateToApplications(props.row)">
                  {{  props.row.applicationCount  }}
                </a>
                <span v-else> {{  props.row.applicationCount  }} </span>
                /
                <span :key="countsKey1">
                  <a v-if="props.row.monDevCount" href="javascript:;" title="Devs"
                    v-on:click="navigateToMonitoredDevices(props.row)">
                    {{  props.row.monDevCount  }}
                  </a>
                  <span v-else> {{  props.row.monDevCount  }} </span>
                </span>
              </template>
              <template slot="statuses" slot-scope="props">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" x-large
                      :color="utilsAdminStatusDisplayObject(props.row.adminStatus).colorName">
                      {{  utilsAdminStatusDisplayObject(props.row.adminStatus).icon  }}
                    </v-icon>
                  </template>
                  <span>{{  utilsAdminStatusDisplayObject(props.row.adminStatus).text  }}</span>
                </v-tooltip>
                <!-- key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully -->
                <v-tooltip top :key="statusesKey1">
                  <!-- display icon (faulty/good) with highestSeverity color -->
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"
                      :color="utilsGetSeverityColor(props.row.highestSeverity).colorName">
                      {{  utilsFaultStatusDisplayObject(props.row.faultStatus).icon  }}
                    </v-icon>
                  </template>
                  <span>{{  props.row.highestSeverity || utilsFaultStatusDisplayObject(props.row.faultStatus).text 
                    }}</span>
                </v-tooltip>
                <span v-if="props.row.incCount" :key="statusesKey2">
                  (<a href="javascript:;" title="# Incidents" v-on:click="navigateToIncidents(props.row)">{{
                     props.row.incCount  }}</a>)
                </span>
              </template>
              <template slot="actions" slot-scope="props">
                <nobr>
                  <v-icon @click="showMoreParams(props.row)" title="More" class="mr-2" color="teal">
                    mdi-information-outline
                  </v-icon>
                  <v-icon @click="viewOrgDetails(props.row.orgId)" class="mr-2" color="primary" title="View">mdi-eye
                  </v-icon>
                  <v-icon :class="utilsGetDisplayClass(props.row)"  @click="editOrgDetails(props.row.orgId, 0)" class="mr-2" color="warning" title="Edit">
                    mdi-pencil</v-icon>
                  <v-icon :class="utilsGetDisplayClass(props.row)"
                    @click="deleteOrgDetails(props.row.orgId, props.row.orgName)" color="red" title="Delete">mdi-delete
                  </v-icon>
                </nobr>
              </template>
            </vue-ads-table>
            <!-- <vue-ads-pagination :total-items="utilsTotalEntriesAllPages" :max-visible-pages="5" :page="currPage"
          :items-per-page="itemsPerPage" @page-change="pageChanged" /> -->
          </div>
        </div>
      </v-container>
    </v-card>
    <!-- !!! Some of the params and functions used here are defined in AdsTreeTableUtils.vue mixin !!!!  -->
    <!-- !!! When async functionality implemented for this table, callRows, callChildren, callTempRows routines need to be
                 brought back to this file !!!!! -->

    <!-- !!!! Doesn't work !!!!! Uses old v-data-table
        <VuetifyTreeDataTable dense :loading="isLoading" :headers="org_table_vuetify_columns" :items="org_table_rows" item-key="orgId"
            :pagination="pagingOptions" :totalItems="utilsTotalEntriesAllPages"
            :footer-props="{'items-per-page-options':[5, 10, 15, 25, 100]}"
            :selectAll="true" > </VuetifyTreeDataTable>
-->

    <!-- 
            rowsPerPageText="fsafafafa"
            :footer-props="{'items-per-page-options':[5, 10, 15, 25, 100], 'items-per-page-text':'fasfasfas'}" item-key="orgId">
            :options.sync="pagingOptions" :server-items-length="utilsTotalEntriesAllPages" multi-sort

            :start="startPage" :end="endPage" :call-rows="callRows" :call-children="callChildren" :call-temp-rows="callTempRows" : pagination in backend
            :filter="filterValueAds" changed to backend search
-->
    <!--
            !!!! table filter functionality doesn't work unless it's rendering from native fields as they are !!!!
            !!!! i.e. doesn't work if it's rendered using slots !!!!

            <template slot="createdDateString" slot-scope="props"> {{ utilsFormatDateTime(props.row.createdDate) }} </template>
            <template slot="billingCodesJoined" slot-scope="props">{{ formatBillingCodes(props.row) }}</template>
-->
    <!--        !!!! filter functionality works for below case as we are rendering native field as it is albeit w/ markup !!!!  -->


    <!-- @range-change="rangeChanged" -->

    <!-- begin More Params dialog -->
    <v-dialog v-model="open_more_params_dialog" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row class="pt-6">
            <v-col cols="12" md="4"> Created Date: </v-col>
            <v-col cols="12" md="8">
              {{  more_params_row.createdDateString  }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4"> Billing Codes : </v-col>
            <v-col cols="12" md="8">
              {{  more_params_row.billingCodesJoined  }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="open_more_params_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end More Params dialog -->

    <DeleteDialog v-if="deleteDialog" :currentObjectName="currentName" @DeleteDialogCallback="deleteObjectChildEvent">
    </DeleteDialog>

  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import auth from "@/services/auth.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import AdsTreeTableUtils from "@/pages/moda/AdsTreeTableUtils.vue";
import Utils from "@/pages/moda/Utils.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
//import VuetifyTreeDataTable from './v-tree-data-table/v-tree-data-table.vue'
import { VueAdsTable } from "vue-ads-table-tree";
//import VueAdsPagination from "vue-ads-pagination";
//import { VueAdsPageButton } from 'vue-ads-pagination'
import TableAdvancedFilter from '@/components/templates/TableAdvancedFilter'
//import ModaConst from '../../services/ModaConstants.js';

export default {
  components: {
    Breadcrumb,
    DeleteDialog,
    //VuetifyTreeDataTable,
    VueAdsTable,
    //VueAdsPagination,
    //VueAdsPageButton,
    TableAdvancedFilter,
  },
  mixins: [AdsTreeTableUtils, Utils],
  data() {
    /*
        var org_table_vuetify_columns= [
           {
               text: 'Name',
               value: 'orgName',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Parent Org',
               value: 'parentOrgName',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Contact Person',
               value: 'contactName',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Email',
               value: 'email',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Phone',
               value: 'phone',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Billing Code(s)',
               value: 'billingCodesJoined',
               sortable: true,
               filterable: true,

           },
           {
               text: 'Admin',
               value: 'adminStatus',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Creation Time',
               value: 'createdDateString',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Actions',
               value: 'actions',
           }
        ]
*/

    var org_table_columns = [
      {
        title: "Name",
        property: "orgName",
        direction: true,
        filterable: true
      },
      {
        title: "Parent Org",
        property: "parentOrgName",
        direction: null,
        filterable: true,
      },
      {
        title: "Contact Person",
        property: "contactName",
        direction: null,
        filterable: true,
      },
      {
        title: "Email",
        property: "email",
        direction: null,
        filterable: true,
      },
      {
        title: "Phone",
        property: "phone",
        direction: null,
        filterable: true,
      },
/*
      {
        title: "Billing Code(s)",
        property: "billingCodesJoined",
        direction: null,
        filterable: true,
      },
      {
        title: "Creation Time",
        property: "createdDateString",
        direction: null,
        filterable: true,
      },
*/
      {
        title: "Sites/Apps/Devices",
        property: "counts",
        direction: null,
        filterable: true,
      },
      {
        title: "Statuses (#Incidents)",
        property: "statuses",
        direction: null,
        filterable: true,
      },
      // {
      //   title: "",
      //   property: "more",
      //   //sortable : true,
      //   //filterable: true,
      // },
      {
        title: "Actions",
        property: "actions",
      },
    ];

    return {
      currOrgId: null,
      org_table_columns,
      //org_table_vuetify_columns,
      org_table_rows: [],
      isLoading: false,
      userdetails: JSON.parse(localStorage.getItem("user-details")),
      isShow: "display-none",
      parent_pages: [{ name: "Home" }, { name: "Organizations", active: true }],
      apiStatus: null,
      deleteDialog: false,
      statusesKey1: 0, // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
      statusesKey2: 1, // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
      countsKey1: 1000, // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
      more_params_row: {},
      open_more_params_dialog: false,
      currId: null,
      currentName: null,

      // passing this to tree-data-table component. passing from one component to another isn't working
      //pagingOptions:this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
      pagingOptions: { page: 1, itemsPerPage: 10 }, // Data-table paging is 1 based
      //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable

      //Note: Values of org(List) is passed in a tuple of (idList & nameList)
      //      Map() or Set() can not be used as 'id' or 'name' could be empty
      // During a cross launch to this page from other pages, the passed in values are set in xLaunch
      xLaunch : {
        org: {
            orgIdList: [],
            orgNameList: []
        },
      },
      tableAdvancedFilter : {
        enabledFilterFields : [], //this.utilsAdvancedFilterFields template doesn't work directly using mixin's data. Init it in mounted()
      },
      defaultAdvSearchCriteria: [
/*
        {"name":"highestSeverity", // default Major and above states
            "value": [
                ModaConst.FaultService.Severity.CRITICAL,
                ModaConst.FaultService.Severity.SEVERE,
                ModaConst.FaultService.Severity.MAJOR,
            ]
        },
*/
      ],

    };
  },
  watch: {
    currPage: {
      handler() {
        console.log("currPage changed ", this.currPage);
        this.getOrganizationsList();
      },
      deep: false,
      immediate: false,
    },
    pagingOptions: {
      handler() {
        this.getOrganizationsList();
      },
      deep: false,
      immediate: false,
    },
  },

  methods: {
    navigateToOrg(currOrgId) {
      this.currOrgId = currOrgId;
      this.$refs.breadcrumb.navigateToOrg(currOrgId)
      this.getOrganizationsList(currOrgId);
    },
    navigateToSites(row) {
      console.log("navigateToSites orgRowSelected ", JSON.stringify(row.orgId));
      // empty query is needed. Else, that component will use default query
      this.$router.push({ name: "SITES", query: [], params: { targetOrgId: row.orgId }, })
    },
    navigateToApplications(row) {
      console.log("navigateToApplications orgRowSelected ", JSON.stringify(row.orgId));
      let query = [ {name: "org", value : { orgNameList: [row.orgName] } } ]
      // empty query is needed. Else, that component will use default query
      this.$router.push({ name: "APPLICATIONS", query: query, params: { targetOrgId: row.orgId }, })
    },
    navigateToMonitoredDevices(row) {
      console.log("navigateToMonitoredDevices orgRowSelected ", JSON.stringify(row.orgId));
      // empty query is needed. Else, that component will use default query
      this.$router.push({ name: "MONITORED_DEVICES", query: [], params: { targetOrgId: row.orgId }, })
    },
    navigateToIncidents(row) {
      //console.log("navigateToIncidents orgRowSelected ", JSON.stringify(row));
      var query = [];
      var states = this.utilsOpenStates;

/*
      //advSearchString = "Open incs. in " + orgNames;
      var orgIds = [], orgNames=[], //, advSearchString = "";
      orgIds = Array.isArray(row) ? row.map ( (r) => r.orgId ) : [row.orgId]
      orgNames = Array.isArray(row) ? row.map ( (r) => r.orgName ) : [row.orgName]
      if (orgIds.length) query.push({ name: "orgId", value: orgIds });
      if (orgNames.length) query.push({ name: "orgName", value: orgNames });
*/
      query.push({ name: "state", value: states });
      console.log("navigateToIncidents query ", JSON.stringify(query));
      this.$router.push({ name: "INCIDENTS", query: query, params: { targetOrgId: row.orgId }, })
    },

    formatBillingCodes(rowObj) {
      return rowObj.billingCode ? rowObj.billingCode.join() : "";
    },
    showMoreParams(row) {
      //console.log("showMoreParams " + JSON.stringify(row))
      this.more_params_row = row;
      this.open_more_params_dialog = true;
    },
    // table filter functionality doesn't work unless it's rendering from native fields
    // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
    buildFilterableColumns(objs) {
      objs.forEach((obj) => {
        obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
        obj.billingCodesJoined = this.formatBillingCodes(obj);
        obj.parentClickable = ( obj.orgId == this.loggedin_org.orgId ) ? false : true
        if (obj._children) this.buildFilterableColumns(obj._children);
        obj.children = obj._children;
      });
    },
    createDummyOrgs(orgsOrig) {
      //console.log("orgsOrig 1 ", orgsOrig.length);
      var n = orgsOrig.length;
      var newOrgs = JSON.parse(JSON.stringify(orgsOrig));
      newOrgs.forEach((org, i) => {
        org.orgId = org.orgId + n + i;
      });
      //console.log("newOrgs ", newOrgs.length);
      orgsOrig = orgsOrig.concat(newOrgs);
      //console.log("orgsOrig 2 ", orgsOrig.length);
      return orgsOrig;
    },
    collectOrgIds ( orgIds, orgs ){
        orgIds = orgIds.concat(orgs.map ( (org) => org.orgId ))
        orgs.forEach ( function(org){
            if ( org._children ) orgIds = this.collectOrgIds(orgIds, org._children)
        }, this)
        return orgIds
    },
    updateOrgStatus ( orgCfgList, orgStsList ){
        orgCfgList.forEach ( function (orgCfg) {
            let orgSts = orgStsList.find( (orgSts) => orgSts.orgId == orgCfg.orgId )
            if ( orgSts ) {
                orgCfg.faultStatus      = orgSts.faultStatus
                orgCfg.highestSeverity  = orgSts.highestSeverity
                orgCfg.incCount         = orgSts.incCount
            }
            if ( orgCfg._children ) this.updateOrgStatus (orgCfg._children, orgStsList)
        }, this)
    },
    updateMonDevCounts ( orgCfgList, monDevCounts ){
        orgCfgList.forEach ( function (orgCfg) {
            let c = monDevCounts.find( (m) => m.orgId == orgCfg.orgId )
            orgCfg.monDevCount = c ? c.monDevCount : 0
            if ( orgCfg._children ) this.updateMonDevCounts (orgCfg._children, monDevCounts)
        }, this)
    },
    getMonitoredDevicesCount(currOrgId = null) {
      let query_params = {}, body={};
      var targetOrgId = currOrgId ? currOrgId : this.currOrgId;
      query_params.targetOrgId = targetOrgId;

      this.isLoading=true

      body.orgIds = this.collectOrgIds([], this.org_table_rows)

      //console.log("getOrganizationsStatusList body " + JSON.stringify(body, null, 2))

      ApiService.post( "/svcInventory/api/v1/monitoredDevice/counts", body, (err, result) => {
          this.isLoading = false;
          if (err) {
            this.apiStatus = "Failed to fetch devices counts"
            //this.utilsCheckLogout(this.apiStatus);
          }else {
            this.apiStatus = null;
            //console.log("**** getMonitoredDevicesCount result = " + JSON.stringify(result, null, 2))
            // Output format
            //[{"orgId":"orgobj.1000","sites":[{"siteId":"sitobj.2000078_1fd8ba97-748f-4e00-ad71-05d6a246d73f","monDevCount":4},{"siteId":"sitobj.2000046_81222a6d-fe4f-472f-909c-19e63c8eb2ed","monDevCount":84}],"monDevCount":88},{"orgId":"orgobj.2001_68e21c61-014b-4bcb-9119-31607cd0bf3e","sites":[{"siteId":"sitobj.2000111_0feb497d-3bbe-4885-9a99-bc9ba2374ca7","monDevCount":1}],"monDevCount":1}]

            this.updateMonDevCounts (this.org_table_rows, result.data.data)
            this.countsKey1 +=1 ; // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
            //console.log("**** getMonitoredDevicesCount orgs = " + JSON.stringify(this.org_table_rows, null, 2))
          }
        }, query_params, "INVENTORY_SERVICE_URL"
      );
    },

    getOrganizationsStatusList(currOrgId = null) {
      let query_params = {}, body={};
      var targetOrgId = currOrgId ? currOrgId : this.currOrgId;
      query_params.targetOrgId = targetOrgId;

      this.isLoading=true

      query_params.summaryDataType = this.UtilsModaConstants.FaultService.Summary.DataType.ORGS,
      query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.LISTS

      body = this.collectOrgIds([], this.org_table_rows)

      //console.log("getOrganizationsStatusList body " + JSON.stringify(body))

      ApiService.post( "/svcSummary/api/v1/summary", body, (err, result) => {   //### svcFault
          this.isLoading = false;
          if (err) {
            this.apiStatus = "Failed to fetch orgs' fault status"
            //this.utilsCheckLogout(this.apiStatus);
          }else {
            this.apiStatus = null;
            //console.log("**** getOrganizationsStatusList orgs result = " + JSON.stringify(result, null, 2))
            this.updateOrgStatus(this.org_table_rows, result.data.data)
            //console.log("**** getOrganizationsStatusList orgs = " + JSON.stringify(this.org_table_rows, null, 2))
            this.statusesKey1 +=1 ; // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
            this.statusesKey2 +=1 ; // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
          }
        }, query_params, "SUMMARY_SERVICE_URL"  //###"FAULT_SERVICE_URL"
      );
    },

    getOrganizationsList(currOrgId = null) {
      let query_params = {}, body = {};
      var targetOrgId = currOrgId ? currOrgId : this.currOrgId;
      //query_params.targetOrgId = targetOrgId;
      //if (this.filterValueSearch) query_params.searchString = "any:*:" + this.filterValueSearch;
      query_params.pageNum = this.currPage + 1; // Ads table uses 0 based page numbers, but backends expects 1 based
      query_params.pageSize = this.itemsPerPage;
      query_params.pageNum = this.pagingOptions.page;
      query_params.pageSize = this.pagingOptions.itemsPerPage;
      query_params.sortCriteria = "highestSeverityNum:desc"

      if ( this.freeFormSearchStr ) query_params.searchString= "any:*:" + this.freeFormSearchStr

      if ( this.advSearchCriteria ) {
        //console.log("getOrganizationsList advSearchCriteria ", JSON.stringify(this.advSearchCriteria))
        var advSearchCriteria = []
        Object.keys(this.advSearchCriteria).forEach( (k) => advSearchCriteria.push(this.advSearchCriteria[k]) )
        if ( advSearchCriteria.length ) body.advSearchCriteria = advSearchCriteria
        //console.log("getOrganizationsList body ", JSON.stringify(body))
      }


      //this.isLoading=true
      //this.site_options = []
      ApiService.post( "/api/v1/org/children/tree/" + targetOrgId + "/0", body, (err, result) => {
          this.isLoading = false;
          if (err) {
            this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
            this.utilsCheckLogout(this.apiStatus);
          } else {
            //console.log("getOrganizationsList result " + JSON.stringify(result));
            this.apiStatus = null;
            this.org_table_rows = result.data.data.docs;
            this.utilsTotalEntriesAllPages = result.data.data.totalEntries;
            this.buildFilterableColumns(this.org_table_rows);
            //console.log("getOrganizationsList orgs " + JSON.stringify(this.org_table_rows, null, 2));

            /* TBD comment out below 
                    this.org_table_rows = this.createDummyOrgs(this.org_table_rows)
                    this.org_table_rows = this.createDummyOrgs(this.org_table_rows)
                    this.org_table_rows = this.createDummyOrgs(this.org_table_rows)
                    this.org_table_rows = this.createDummyOrgs(this.org_table_rows)
                    if ( this.org_table_rows.length ) this.utilsTotalEntriesAllPages = this.org_table_rows.length 
                    TBD comment out above */
            this.getOrganizationsStatusList(currOrgId)
            this.getMonitoredDevicesCount(currOrgId)
          }
        },
        query_params
      );
    },
    editOrgDetails(Id, readonly) {
      this.$router.push({name: "ORGS_EDIT", params : { id: Id, readonly: readonly } } );
    },
    viewOrgDetails(Id) {
      this.$router.push({name: "ORGS_VIEW", params : { id: Id } } );
    },
    createOrganization() {
      this.$router.push({name: "ORGS_CREATE" } );
    },
    deleteOrgDetails(Id, Name) {
      if (Id == this.currOrgId) {
        // if we are deleting current Org
        if (confirm("Cant delete current org '" + Name + "'")) {
          return;
        }
        return;
      }
      this.deleteDialog = true;
      this.currId = Id;
      this.currentName = Name;
    },
    deleteObjectChildEvent(cancelFlag, isBackupDel) {
      console.log("** delete " + isBackupDel);
      this.deleteDialog = false;
      if (cancelFlag == true) return;

      //if(confirm("Do you really want to delete '"+Name + "' ?"))
      {
        this.isLoading = true;

        let query_params = {};
        if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
        query_params.deleteMode = this.UtilsModaConstants.DeleteMode.FORCE;

        ApiService.delete( "/api/v1/dataManagement/org/delete/" + this.currId, (err, result) => {
            //console.log("**"+JSON.stringify(result))
            this.isLoading = false;
            if (err){
              this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
              this.utilsCheckLogout(this.apiStatus);
            } else {
              this.apiStatus = null;
              this.getOrganizationsList();
            }
            // update breadcrumb if org is deleted
            this.$refs.breadcrumb.getOrganizationsList(this.currOrgId);
          },
          query_params
        );
      }
    },
    get_auth_details() {
      let title = this.$route.meta.title;
      this.isShow = auth.AuthService.get_usr_auth_details(title);
      this.userdetails =  auth.AuthService.get_usrdetails(); 
      this.loggedin_org = this.userdetails.orgInfo
      //console.log("Organizatons loggedin_org " + JSON.stringify(this.loggedin_org))
    },
    getDisplayClass(org) {
      return org.orgId == this.currOrgId ? "display-none" : "display-block";
    },
    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      this.getOrganizationsList();
    },
    expandAllRows() {
      document
        .querySelectorAll("i.vue-ads-pr-2.fa-plus-square")
        .forEach((e) => e.click());
    },

    processFreeFormSearchChange(freeFormSearchStr){
        this.freeFormSearchStr = freeFormSearchStr
        auth.globalOrgsFreeFormSearchStr = freeFormSearchStr
        console.log("Organizations processFreeFormSearchChange " + this.freeFormSearchStr)
        this.getOrganizationsList();
    },
    processAdvancedFilterChange(advancedFilter){
        //console.log("Table filter selected " + JSON.stringify(advancedFilter))

        this.pagingOptions.page = 1;        // Reset page to 1st page, for AdvSearch APPLY

        // append to advanced search passed in, if any
        this.advSearchCriteria = []
        //this.advSearchString = ""

        if ( advancedFilter.orgNameFilter ){
            if (advancedFilter.orgNameFilter == null || advancedFilter.orgNameFilter == [] ||
                !this.utilsIsSameList(advancedFilter.orgNameFilter, this.xLaunch.org.orgNameList)) {
                //advancedFilter.orgNameFilter != this.xLaunch.org.orgNameList)
                    // orgNameList is empty or cross-launched orgNameList is modified. 
                    // Let the backend calculate the Ids, as needed
                    this.xLaunch.org.orgIdList = null;
            }
            this.advSearchCriteria.push({"name":"org", "value": 
                    {orgIdList: this.xLaunch.org.orgIdList, 
                    orgNameList: advancedFilter.orgNameFilter}})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.orgNameFilter)
        }

        if ( advancedFilter.highestSeverityFilter ){
            this.advSearchCriteria.push({"name":"highestSeverity", "value": advancedFilter.highestSeverityFilter})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.highestSeverityFilter)
        }
        if ( advancedFilter.parentOrgNameFilter ){
            this.advSearchCriteria.push({"name":"parentOrg", "value": {orgIdList: null, orgNameList: advancedFilter.parentOrgNameFilter}})
            //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.parentOrgNameFilter)
        }

        auth.globalOrgsAdvSearch       = this.advSearchCriteria;
        //auth.globalOrgsAdvSearchString = this.advSearchString;
        this.getOrganizationsList();
    },
    init(){
        if ( this.advSearchCriteria ){
            //console.log("orgs.vue init this.advSearchCriteria " + JSON.stringify(this.advSearchCriteria))
            var tmp

            tmp = this.advSearchCriteria.find(e => e.name == "org")
            if ( tmp ) {
                this.tableAdvancedFilter.orgNamesFilterIn  = tmp.value.orgNameList;
                // orgNames are only to pass to this component, orgIds are sent to backend
                // send names to backend
                // this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "orgName")
                this.xLaunch.org = tmp.value;
            } else { // set default
                this.tableAdvancedFilter.orgNamesFilterIn = [];
                this.xLaunch.org = {orgIdList: [], orgNameList: []};
            }

            tmp = this.advSearchCriteria.find(e => e.name == "highestSeverity")
            if ( tmp ) this.tableAdvancedFilter.highestSeveritiesFilterIn  = tmp.value

            tmp = this.advSearchCriteria.find(e => e.name == "parentOrg")
            if ( tmp ) this.tableAdvancedFilter.parentOrgNamesFilterIn  = tmp.value.orgNameList;
        }

        this.$refs.tableAdvancedFilter.setAdvancedFilters(this.tableAdvancedFilter, this.freeFormSearchStr)
            //console.log("org init orgNamesFilter " + this.tableAdvancedFilter.orgNamesFilterIn + 
                            //", orgstatusFilter " + this.tableAdvancedFilter.orgstatusFilterIn)
    },

  },

  mounted() {
    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();

    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.FREEFORM_SEARCH)
    //this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.ORG_NAME) // orgName removed
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.ORG_NAME)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.HIGHEST_SEVERITY)
    this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.PARENT_ORG_NAME)

    if ( this.$route.query && Array.isArray(this.$route.query) ) {
         // query passed in from cross Launch
         this.advSearchCriteria = this.$route.query
         //auth.globalOrgsAdvSearch = this.advSearchCriteria - //don't save cross launch filter as global.
                                                               // We should be able to see complete list by clicking on left panel
    } else if ( auth.globalOrgsAdvSearch )
         // else, take the one from global state
         this.advSearchCriteria = auth.globalOrgsAdvSearch
    else // else, default
         this.advSearchCriteria = this.defaultAdvSearchCriteria;

/*
    if ( this.$route.params && this.$route.params.advSearchString )
         this.advSearchString = this.$route.params.advSearchString
    else if ( auth.globalOrgsAdvSearchString )
         this.advSearchString = auth.globalOrgsAdvSearchString
*/

    if ( auth.globalOrgsFreeFormSearchStr )
        this.freeFormSearchStr = auth.globalOrgsFreeFormSearchStr

    this.init()

    this.get_auth_details();
    this.utilsDataRefreshFunc = this.getOrganizationsList;
    this.getOrganizationsList();
  },
  updated() {
    this.expandAllRows();
    setTimeout(this.expandAllRows, 100);
    setTimeout(this.expandAllRows, 100);
  },
};
</script>

<style>
.leftAlign {
  text-align: left;
}
</style>
