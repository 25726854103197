<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-map-marker-multiple</v-icon> Sites
				<v-btn rounded outlined color="primary" absolute right :class="isShow" v-on:click="createSite()">
					<v-icon>mdi-plus</v-icon> New
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<TableAdvancedFilter ref="tableAdvancedFilter" :enabledFieldsIn="tableAdvancedFilter.enabledFilterFields"
					v-on:freeFormSearchChanged="processFreeFormSearchChange"
					v-on:advancedFilterChanged="processAdvancedFilterChange" />
			</v-card-text>

			<v-container fluid class="pt-0">
				<v-data-table :loading="isLoading" :headers="site_table_vuetify_columns" :items="site_table_rows"
					:options.sync="pagingOptions" :server-items-length="utilsTotalEntriesAllPages" multi-sort
					:footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }" item-key="siteId">

					<template v-slot:item.actions="{ item }">
						<nobr>
							<v-icon @click="showMoreParams(item)" title="More" class="mr-2" color="teal">
								mdi-information-outline
							</v-icon>
							<v-icon @click="viewSiteDetails(item.siteId)" class="mr-2" color="primary" title="View">
								mdi-eye</v-icon>
							<v-icon :class="utilsGetDisplayClass(item)" @click="editSiteDetails(item.siteId, 0)" class="mr-2" color="warning" title="Edit">mdi-pencil
							</v-icon>
							<v-icon :class="utilsGetDisplayClass(item)" @click="enableDeleteSiteDialog(item.siteId, item.siteName)" color="red" title="Delete">
								mdi-delete</v-icon>
						</nobr>
					</template>
					<template v-slot:item.counts="{ item }">
						<a v-if="item.applicationCount" href="javascript:;" title="Apps" v-on:click="navigateToApplications(item)">
							{{ item.applicationCount }}
						</a>
						<span v-else> {{ item.applicationCount }} </span>
						/
						<span :key="countsKey1">
							<a v-if="item.monDevCount" href="javascript:;" title="Devs" v-on:click="navigateToMonitoredDevices(item)">
								{{ item.monDevCount }}
							</a>
							<span v-else> {{ item.monDevCount }} </span>
						</span>
					</template>
					<template v-slot:item.statuses="{ item }">

						<!-- key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully -->
						<v-tooltip top :key="statusesKey1">

							<!-- display icon (faulty/good) with highestSeverity color -->
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" :color="utilsGetSeverityColor(item.highestSeverity).colorName">
									{{ utilsFaultStatusDisplayObject(item.faultStatus).icon }}
								</v-icon>
							</template>
							<span v-if="item.highestSeverity">{{ item.highestSeverity }}</span>
							<span v-else>{{ utilsFaultStatusDisplayObject(item.faultStatus).text }}</span>
						</v-tooltip>
						<span v-if="item.incCount" :key="statusesKey2">
							(
							<a href="javascript:;" title="# Incidents" v-on:click="navigateToIncidents(item)">
								{{ item.incCount }}
							</a>
							)
						</span>
					</template>
				</v-data-table>
			</v-container>
		</v-card>
		<!-- !!! Some of the params and functions used here are defined in AdsTreeTableUtils.vue mixin !!!!  -->
		<!-- !!! When async functionality implemented for this table, callRows, callChildren, callTempRows routines need to be
                 brought back to this file !!!!! -->

		<!-- begin More Params dialog -->
		<v-dialog v-model="open_more_params_dialog" max-width="500px">
			<v-card>
				<v-card-text>
					<v-row class="pt-6">
						<v-col cols="12" md="4"> Created Date: </v-col>
						<v-col cols="12" md="8">
							{{ more_params_row.createdDateString }}
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="4"> Billing Codes : </v-col>
						<v-col cols="12" md="8">
							{{ more_params_row.billingCodesJoined }}
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" text @click="open_more_params_dialog = false">
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- end More Params dialog -->

		<DeleteDialog v-if="deleteDialog" :isBackupBeforeDelete="false" @DeleteDialogCallback='deleteDialogEventCallback'
			:delMessage="deleteMessage" />

		<GeneralDialog v-if="generalDialog" @GeneralDialogCallback='generalDialogEventCallback'
			:generalDialogMessage="generalDialogMessage" />

	</div>
</template>

<script>
//import 'vue-loading-overlay/dist/vue-loading.css';
import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import AdsTreeTableUtils from '@/pages/moda/AdsTreeTableUtils.vue'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import GeneralDialog from '@/components/dialogs/GeneralDialog.vue'
import TableAdvancedFilter from '@/components/templates/TableAdvancedFilter'
//import ModaConst from '../../services/ModaConstants.js';


export default {
    components: {
        Breadcrumb,
        DeleteDialog,
        GeneralDialog,
        TableAdvancedFilter,
    },
    mixins: [
        AdsTreeTableUtils,
        Utils,
    ],
    data(){
        var site_table_vuetify_columns = [
           {
               text: 'Name',
               value: 'siteName',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Address',
               value: 'address',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Region',
               value: 'region',
               sortable: true,
               filterable: true,
           },
/*
           {
               text: 'Billing Code(s)',
               value: 'billingCodesJoined',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Creation Time',
               value: 'createdDateString',
               sortable: true,
               filterable: true,
           },
*/
           {
               text: 'Tele.ML',
               value: 'teleMLSettings',
               sortable: false,
               filterable: true,
           },
           {
               text: 'Log.ML',
               value: 'logMLSettings',
               sortable: false,
               filterable: true,
           },
           {
               text: "Apps / Devices",
               value: "counts",
               sortable: false,
               filterable: true,
           },
           {
               text: "Status (#Incidents)",
               value: "statuses",
               sortable: true,
               filterable: true,
           },
           {
               text: 'Actions',
               value: 'actions',
               sortable: false
           },
        ]
        return {
            currOrgId: null,
            site_table_vuetify_columns,
            site_table_rows: [],
            pagingOptions: sessionStorage.getItem(`_pagination${this.$route.path}`) ? JSON.parse(sessionStorage.getItem(`_pagination${this.$route.path}`)) : this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
            //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable
            isLoading: false,
            isShow:'display-none',
            //isCreate:false,
            parent_pages:[ {name:'Home'}, {name:'Sites', active:true} ],
            userdetails:null,
            apiStatus: null,
            currentDeviceId:null,
            statusesKey1: 0, // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
            statusesKey2: 1, // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
            countsKey1: 1000, // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
            more_params_row: {},
            open_more_params_dialog: false,

            deleteMessage:null,
            deleteDialog:false,

            generalDialogMessage: null,
            generalDialog: false,

            //Note: Values of site(List) and component(List) are passed in a tuple of (idList & nameList)
            //      Map() or Set() can not be used as 'id' or 'name' could be empty
            // During a cross launch to this page from other pages, the passed in values are set in xLaunch
            xLaunch : {
              site: {
                  siteIdList: [],         // ["sitobj.1001"] 
                  siteNameList: []        // ["Austin"]
              },
            },
            tableAdvancedFilter : {
              enabledFilterFields : [], //this.utilsAdvancedFilterFields template doesn't work directly using mixin's data. Init it in mounted()
            },
            defaultAdvSearchCriteria: [
/*
              {"name":"highestSeverity", // default Major and above states
                  "value": [
                      ModaConst.FaultService.Severity.CRITICAL,
                      ModaConst.FaultService.Severity.SEVERE,
                      ModaConst.FaultService.Severity.MAJOR,
                  ]
              },
*/
            ],

        };
    },
    watch: {
      pagingOptions: {
        handler () {
            sessionStorage.setItem(`_pagination${this.$route.path}`, JSON.stringify(this.pagingOptions));
            this.getSitesList();
        },
        deep: false,
        immediate: false,
      },

    },
    methods: {
        navigateToApplications(row) {
            console.log("navigateToApplications siteRowSelected ", JSON.stringify(row.siteId));
            var query = [ {name: "site", value: { siteNameList: [row.siteName] }} ]
            // empty query is needed. Else, that component will use default query
            this.$router.push({ name: "APPLICATIONS", query: query })
        },
        navigateToMonitoredDevices(row) {
            console.log("navigateToMonitoredDevices siteRowSelected ", JSON.stringify(row.siteId));
            var query = [ {name: "site", value: { siteNameList: [row.siteName] }} ]
            // empty query is needed. Else, that component will use default query
            this.$router.push({ name: "MONITORED_DEVICES", query: query })
        },
        navigateToIncidents(row) {
            //console.log("navigateToIncidents siteRowSelected ", JSON.stringify(row));
            var siteIds = [], siteNames=[], states = []//, advSearchString = "";
            siteIds = [row.siteId]
            siteNames = [row.siteName]
            states = this.utilsOpenStates;
            //advSearchString = "Open incs. in " + row.siteName;

            var query = [];
            if (siteIds.length && siteNames.length) {
                //OLD: query.push({ name: "siteId", value: siteIds });
                query.push({ name: "site", value: {siteIdList: siteIds, siteNameList: siteNames} });
                //query.push({ name: "componentId", value: siteIds });
            }
            /*~~~ OLD ~~~
            if (siteNames.length) {
                query.push({ name: "siteName", value: siteNames });
                //query.push({ name: "componentName", value: siteNames });
            }
            ~~~~~~~~~~~~~~*/

            query.push({ name: "state", value: states });
            console.log("navigateToIncidents query ", JSON.stringify(query));
            query.length ? this.$router.push({ name: "INCIDENTS", query: query }) : this.$router.push({ name: "INCIDENTS" });
        },

        formatBillingCodes(rowObj){
            return rowObj.billingCode.map(bc => bc && bc).join(' ');
        },
        formatAddress(addressObj){
            return Object.values(addressObj).map(v => v && v.trim()).join(' ');
        },
        showMoreParams(row) {
            //console.log("showMoreParams " + JSON.stringify(row))
            this.more_params_row = row;
            this.open_more_params_dialog = true;
        },
        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
        buildFilterableColumns(objs){
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                obj.billingCodesJoined = this.formatBillingCodes(obj);
                obj.address = this.formatAddress(obj.address);
                if ( obj._children ) this.buildFilterableColumns(obj._children);
                //Refer: https://fsymbols.com/signs/tick/
                obj.teleMLSettings = (obj.mlSettings.telemetry.enabled?"\u2714":"\u2718") + "." + (obj.mlSettings.telemetry.mlEnabled?"\u2714":"\u2718");
                obj.logMLSettings = (obj.mlSettings.logAnalysis.enabled?"\u2714":"\u2718") + "." + (obj.mlSettings.logAnalysis.mlEnabled?"\u2714":"\u2718");
            })
        },
        updateSiteStatus ( siteCfgList, siteStsList ){
            siteCfgList.forEach ( function (siteCfg) {
                let siteSts = siteStsList.find( (siteSts) => siteSts.siteId == siteCfg.siteId )
                if ( siteSts ) {
                    siteCfg.faultStatus      = siteSts.faultStatus
                    siteCfg.highestSeverity  = siteSts.highestSeverity
                    siteCfg.incCount         = siteSts.incCount
                }
            }, this)
        },
        updateMonDevCounts ( siteCfgList, monDevCounts ){
            siteCfgList.forEach ( function (siteCfg) {
                siteCfg.monDevCount = 0
                monDevCounts.forEach( function(m1) {
                    if ( m1.orgId == siteCfg.orgId ) {
                        m1.sites.forEach( function(m2) {
                            if ( m2.siteId == siteCfg.siteId ) siteCfg.monDevCount = m2.monDevCount
                        })
                    }
                })
                if ( siteCfg._children ) this.updateMonDevCounts (siteCfg._children, monDevCounts)
            }, this)
        },
        getMonitoredDevicesCount() {
          let query_params = {}, body={};
          query_params.targetOrgId = this.currOrgId;

          this.isLoading=true

          //body.orgIds = this.currOrgId
          body.siteIds = this.site_table_rows.map( (s) => s.siteId )

          //console.log("getMonitoredDevicesCount body " + JSON.stringify(body))

          ApiService.post( "/svcInventory/api/v1/monitoredDevice/counts", body, (err, result) => {
              this.isLoading = false;
              if (err) {
                this.apiStatus = "Failed to fetch devices counts"
                //this.utilsCheckLogout(this.apiStatus);
              }else {
                this.apiStatus = null;
                //console.log("**** getMonitoredDevicesCount result = " + JSON.stringify(result, null, 2))
                // Output format
                //[{"orgId":"orgobj.1000","sites":[{"siteId":"sitobj.2000078_1fd8ba97-748f-4e00-ad71-05d6a246d73f","monDevCount":4},{"siteId":"sitobj.2000046_81222a6d-fe4f-472f-909c-19e63c8eb2ed","monDevCount":84}],"monDevCount":88}]

                this.updateMonDevCounts (this.site_table_rows, result.data.data)
                this.countsKey1 +=1 ; // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
                //console.log("**** getMonitoredDevicesCount sites = " + JSON.stringify(this.site_table_rows, null, 2))
              }
            }, query_params, "INVENTORY_SERVICE_URL"
          );
        },

        getSitesStatusList() {
          let query_params = {}, body={};
          if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

          this.isLoading=true

          query_params.summaryDataType = this.UtilsModaConstants.FaultService.Summary.DataType.SITES,
          query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.LISTS

          body = this.site_table_rows.map( (s) => s.siteId )

          //console.log("getSitesStatusList body " + JSON.stringify(body))

          ApiService.post( "/svcSummary/api/v1/summary", body, (err, result) => {       //### svcFault
              this.isLoading = false;
              if (err) {
                this.apiStatus = "Failed to fetch sites' fault status"
                //this.utilsCheckLogout(this.apiStatus);
              }else {
                this.apiStatus = null;
                //console.log("**** getSitesStatusList sites result = " + JSON.stringify(result.data.data, null, 2))
                this.updateSiteStatus(this.site_table_rows, result.data.data)
                //console.log("**** getSitesStatusList sites = " + JSON.stringify(this.site_table_rows, null, 2))
                this.statusesKey1 +=1 ; // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
                this.statusesKey2 +=1 ; // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
                this.getMonitoredDevicesCount();
              }
            }, query_params, "SUMMARY_SERVICE_URL"   //###"FAULT_SERVICE_URL"
          );

        },
        getSitesList(){
            let query_params = {}, body = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            //if ( this.filterValueSearch ) query_params.searchString= "any:*:" + this.filterValueSearch
            query_params.pageNum = this.pagingOptions.page;
            query_params.pageSize = this.pagingOptions.itemsPerPage;
            var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, {address: "address.city", statuses: "highestSeverityNum"});
            if (sortCriteria) query_params.sortCriteria = sortCriteria;
            //this.isLoading=true

            if ( this.freeFormSearchStr ) query_params.searchString= "any:*:" + this.freeFormSearchStr

            if ( this.advSearchCriteria ) {
                //console.log("getSitesList advSearchCriteria ", JSON.stringify(this.advSearchCriteria))
                var advSearchCriteria = []
                Object.keys(this.advSearchCriteria).forEach( (k) => advSearchCriteria.push(this.advSearchCriteria[k]) )
                if ( advSearchCriteria.length ) body.advSearchCriteria = advSearchCriteria
                //console.log("getSitesList body ", JSON.stringify(body))
            }

            ApiService.post("/api/v1/site/page2", body, (err, result) => {
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //console.log("getSitesList ****"+JSON.stringify(result))
                    //this.isCreate = result.data.data.isCreate
                    this.site_table_rows=result.data.data
                    this.utilsTotalEntriesAllPages = this.site_table_rows.length
                    this.buildFilterableColumns(this.site_table_rows);
                    //console.log("getSitesList ****"+JSON.stringify(this.site_table_rows, null, 2))
                    this.getSitesStatusList()
                }
            }, query_params)
        },
        createSite(){
            this.$router.push({ name: "SITES_CREATE" })
        },
        editSiteDetails(id, readonly){
            this.$router.push({ name: "SITES_EDIT", params: { siteId: id, readonly: readonly } })
        },
        viewSiteDetails(id){
            auth.crossLaunchSitesAdvSearch = this.advSearchCriteria // save this state to come back to same search state
            this.$router.push({ name: "SITES_VIEW", params: { siteId: id } })
        },
        getLocationFormat(sites){
            return sites.location.lat+'-'+sites.location.long
        },

        enableDeleteSiteDialog(id, name) {
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            query_params.siteId = id;
            this.currentDeviceId = id;

            this.isLoading=true
            ApiService.get("/api/v1/device", (err, result) => {
                this.isLoading=false
                var deviceNames = null
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) :
                                        "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    var devices = result.data.data
                    deviceNames = devices.map(d => d.deviceName).join('\n<br/>')
                    //console.log("deleteSiteDetails ****"+JSON.stringify(deviceNames))
                }

                // allow delete only if no devices exists under this site
                if(deviceNames){
                    let message = "Delete following devices under this site first:"+"\n<br/>"+deviceNames;
                    this.generalDialogMessage = message;
                    this.generalDialog = true;
                }else{
                    let message = "Do you really want to delete " + name + "? <br/>" + '\r\n';
                    this.deleteMessage = message;
                    this.deleteDialog = true;
                }
            }, query_params);
        },

        deleteDialogEventCallback(cancelFlag, isHard) 
        {
            this.deleteDialog = false;
            if(cancelFlag === true) {
                    return;
            }
            console.log("** delete id "+this.currentDeviceId + ' ' + isHard);

            this.isLoading=true

            let query_params = {};
            if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
            query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

            ApiService.delete("/api/v1/site/"+this.currentDeviceId, (err, result) => {
                this.isLoading=false 
                if ( err ){
              this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
              this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //console.log("**"+JSON.stringify(result))
                    this.getSitesList();
                }
            }, query_params)
        },

        deleteSiteDetailsOld(id, name){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            query_params.siteId = id;
            this.isLoading=true

            ApiService.get("/api/v1/device", (err, result) => {
                this.isLoading=false
                var deviceNames = null 
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    var devices = result.data.data
                    deviceNames = devices.map(d => d.deviceName).join('\n')
                    //console.log("deleteSiteDetails ****"+JSON.stringify(deviceNames))
                }

                let message = "Do you really want to delete '" + name + "' ?"
                if ( deviceNames ) message += ".\nFollowing devices and their data will be automatically deleted:\n" + deviceNames;

                if(confirm(message)){
                    this.isLoading=true

                    let query_params = {};
                    if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
                    query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

                    ApiService.delete("/api/v1/site/"+id, (err, result) => {
                        this.isLoading=false 
                        if ( err ){
                            this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                            this.utilsCheckLogout(this.apiStatus);
                        } else {
                            this.apiStatus = null;
                            //console.log("**"+JSON.stringify(result))
                            this.getSitesList();
                        }
                    }, query_params)
                }

            }, query_params)
        },

        generalDialogEventCallback()
        {
            this.generalDialog = false;
        },

        get_auth_details(){
            let title = this.$route.meta.title;
            //console.log(title+'**000000*'+siteId)
            this.isShow = auth.AuthService.get_usr_auth_details(title)
            //console.log("get_auth_details " + this.isShow);
        },
/*
        get_site_auth_details(siteId){
            let title = this.$route.meta.title;
            return auth.AuthService.get_site_auth_details(title, siteId)
            let write_access; 
            let user_details = this.userdetails
            if(user_details.pbac){
                let pbac = user_details.pbac;
                write_access= 'display-block' 
                for(let i=0; i< pbac.length;i++) {
                    if (pbac[0].policyDetails.features[0] == title){
                        if(pbac[0].siteId ==siteId){
                            if(pbac[0].policyDetails.permissions[0] && pbac[0].policyDetails.permissions[0] == 'read-write'){
                                write_access ='display-block';
                            } else {
                                write_access ='display-none'; 
                            }
                        } else {
                            //console.log(write_access+'******3***'+siteId);
                        }
                    }
                }
            }
            return write_access;
        },
*/
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.clearPaginationCache();
            this.getSitesList();
        },
        clearPaginationCache(){
            sessionStorage.removeItem(`_pagination${this.$route.path}`);
            this.pagingOptions.page = 1;
        },
        processFreeFormSearchChange(freeFormSearchStr){
            this.freeFormSearchStr = freeFormSearchStr
            auth.globalSitesFreeFormSearchStr = freeFormSearchStr
            console.log("Sites processFreeFormSearchChange " + this.freeFormSearchStr)
            this.getSitesList();
        },
        processAdvancedFilterChange(advancedFilter){
            //console.log("Table filter selected " + JSON.stringify(advancedFilter))

            this.pagingOptions.page = 1;        // Reset page to 1st page, for AdvSearch APPLY

            // append to advanced search passed in, if any
            this.advSearchCriteria = []
            //this.advSearchString = ""

    /* orgName filter removed
            if ( advancedFilter.orgNameFilter ){
                this.advSearchCriteria.push({"name":"orgName", "value": advancedFilter.orgNameFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.orgNameFilter)
            }
    */
            if ( advancedFilter.siteNameFilter ){
                if (advancedFilter.siteNameFilter == null || advancedFilter.siteNameFilter == [] ||
                    !this.utilsIsSameList(advancedFilter.siteNameFilter, this.xLaunch.site.siteNameList)) {
                    //advancedFilter.siteNameFilter != this.xLaunch.site.siteNameList)
                        // siteNameList is empty or cross-launched siteNameList is modified. 
                        // Let the backend calculate the Ids, as needed
                        this.xLaunch.site.siteIdList = null;
                }
                this.advSearchCriteria.push({"name":"site",
                                             "value": {siteIdList: this.xLaunch.site.siteIdList, siteNameList: advancedFilter.siteNameFilter}})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.siteNameFilter)
            }

            if ( advancedFilter.highestSeverityFilter ){
                this.advSearchCriteria.push({"name":"highestSeverity", "value": advancedFilter.highestSeverityFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.highestSeverityFilter)
            }
            if ( advancedFilter.addressFilter ){
                this.advSearchCriteria.push({"name":"address", "value": advancedFilter.addressFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.addressFilter)
            }
            if ( advancedFilter.regionFilter ){
                this.advSearchCriteria.push({"name":"region", "value": advancedFilter.regionFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.regionFilter)
            }

            auth.globalSitesAdvSearch       = this.advSearchCriteria;
            //auth.globalSitesAdvSearchString = this.advSearchString;
            this.clearPaginationCache();
            this.getSitesList();
        },
        init(){
            if ( this.advSearchCriteria ){
                //console.log("Sites.vue init this.advSearchCriteria " + JSON.stringify(this.advSearchCriteria))
                var tmp

                tmp = this.advSearchCriteria.find(e => e.name == "site")
                if ( tmp ) {
                    this.tableAdvancedFilter.siteNamesFilterIn  = tmp.value.siteNameList;
                    // siteNames are only to pass to this component, orgIds are sent to backend
                    // send names to backend
                    // this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "siteName")
                    this.xLaunch.site = tmp.value;
                } else { // set default
                    this.tableAdvancedFilter.siteNamesFilterIn = [];
                    this.xLaunch.site = {siteIdList: [], siteNameList: []};
                }

                tmp = this.advSearchCriteria.find(e => e.name == "highestSeverity")
                if ( tmp ) this.tableAdvancedFilter.highestSeveritiesFilterIn  = tmp.value

                tmp = this.advSearchCriteria.find(e => e.name == "address")
                if ( tmp ) this.tableAdvancedFilter.addressFilterIn  = tmp.value

                tmp = this.advSearchCriteria.find(e => e.name == "region")
                if ( tmp ) this.tableAdvancedFilter.regionFilterIn  = tmp.value

            }

            this.$refs.tableAdvancedFilter.setAdvancedFilters(this.tableAdvancedFilter, this.freeFormSearchStr)
                //console.log("Site init orgNamesFilter " + this.tableAdvancedFilter.orgNamesFilterIn + 
                                //", SitestatusFilter " + this.tableAdvancedFilter.SitestatusFilterIn)
        },

    },
    mounted() {
        if ( this.$route.params && this.$route.params.targetOrgId ){
            this.$refs.breadcrumb.navigateToOrg(this.$route.params.targetOrgId)
            this.currOrgId = this.$route.params.targetOrgId
        } else
            this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();

        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.FREEFORM_SEARCH)
        //this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.ORG_NAME) // orgName removed
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.SITE_NAME)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.HIGHEST_SEVERITY)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.ADDRESS)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.REGION)

        if ( this.$route.query && Array.isArray(this.$route.query) ) {
             // query passed in from cross Launch
             this.advSearchCriteria = this.$route.query
             //auth.globalSitesAdvSearch = this.advSearchCriteria - //don't save cross launch filter as global.
                                                                    // We should be able to see complete list by clicking on left panel
        } else if (auth.crossLaunchSitesAdvSearch)
            // else, take the one from crossLaunch state - the one we set when we navigated away from this page
            this.advSearchCriteria = auth.crossLaunchSitesAdvSearch
        else if ( auth.globalSitesAdvSearch )
             // else, take the one from global state
             this.advSearchCriteria = auth.globalSitesAdvSearch
        else // else, default
             this.advSearchCriteria = this.defaultAdvSearchCriteria;
/*
        if ( this.$route.params && this.$route.params.advSearchString )
             this.advSearchString = this.$route.params.advSearchString
        else if ( auth.globalSitesAdvSearchString )
             this.advSearchString = auth.globalSitesAdvSearchString
*/

        if ( auth.globalSitesFreeFormSearchStr )
             this.freeFormSearchStr = auth.globalSitesFreeFormSearchStr

        this.init()

        this.userdetails =  auth.AuthService.get_usrdetails(); 
        this.utilsDataRefreshFunc = this.getSitesList;
        this.get_auth_details();
        //this.getSitesList(); // will be called on pagingOptions change watcher
    }
}
</script>
