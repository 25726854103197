<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
	
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-filter-settings</v-icon> Filtering Rules
<!-- Rule creation is now from Incidents page
				<v-btn rounded outlined color="primary" absolute right :class="isShow" @click="createEventRule()">
					<v-icon>mdi-plus</v-icon> New
				</v-btn>
-->
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<div class="search-wrapper">
					<v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify" />
				</div>
			</v-card-text>
			<v-container fluid class="pt-0">
				<v-data-table :loading="isLoading" :headers="eventRuleTableColumnsVueTable" multi-sort no-data-text="No rules"
					:items="eventRule_table_rows" :options.sync="pagingOptions"
					:footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }" item-key="ruleId">

<!-- no need for additional column. Part of actions already
                    <template v-slot:item.status="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" x-large
                                    :color="utilsAdminStatusDisplayObject(item.adminStatus).colorName">
                                    {{ utilsAdminStatusDisplayObject(item.adminStatus).icon }}
                                </v-icon>
                            </template>
                            <span>{{ utilsAdminStatusDisplayObject(item.adminStatus).text }}</span>
                        </v-tooltip>
                    </template>
-->

					<template v-slot:item.actions="{ item }">
                        <v-icon :class="utilsGetDisplayClass(item)" @click="setAdminStatus(item)" x-large
                                    :color="utilsAdminStatusDisplayObject(item.adminStatus).colorName"
							title="Enable">{{ utilsAdminStatusDisplayObject(item.adminStatus).icon }}</v-icon>

						<v-icon @click="viewEventRuleDetails(item.ruleId, 1)" color="primary" 
                            title="View" class="mr-2">mdi-eye </v-icon>
<!--
						<v-icon :class="utilsGetDisplayClass(item)" @click="editEventRuleDetails(item.ruleId, 0)" color="warning"
							title="Edit" class="mr-2">mdi-pencil</v-icon>
-->
						<v-icon :class="utilsGetDisplayClass(item)" @click="deleteEventRule(item.ruleId, item.ruleName)" color="error"
							title="Delete">mdi-delete</v-icon>
					</template>
	
				</v-data-table>
			</v-container>
		</v-card>
	
        <GeneralConfirmDialog v-if=setAdminStatusDialog :generalDialogMessage="adminStatusDialogMessage"
                    @GeneralConfirmDialogCallback='setAdminStatusChildEvent'>
		</GeneralConfirmDialog>

		<DeleteDialog v-if="deleteDialog" :currentObjectName="currName" @DeleteDialogCallback='deleteObjectChildEvent'>
		</DeleteDialog>
	
	</div>
</template>

<script>

import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import GeneralConfirmDialog from '@/components/dialogs/GeneralConfirmDialog.vue'

export default {
    name: 'EventsRules',
    components: {
        Breadcrumb,
        DeleteDialog,
        GeneralConfirmDialog,
    },
    mixins: [
        //AdsTreeTableUtils,
        Utils,
    ],
    data(){
        var eventRuleTableColumnsVueTable = [
           {
               text: 'Name',
               value: 'ruleName',
               sortable: true,
               filterable: true,
           },
           {
               text:'Conditions',
               value:'conditions',
               sortable: true,
               filterable: true,
           },
           {
               text:'Action',
               value:'action',
               sortable: true,
               filterable: true,
           },
           {
               text:'Created',
               value:'createdDateString',
               sortable: true,
               filterable: true,
           },
/* no need for additional column. Part of actions already
            {
                text: "Status",
                value: "status",
                sortable: true,
                filterable: true,
            },
*/
           {
               text:'Actions',
               value:'actions',
               sortable: false,
               filterable: false,
               width: 160
           },
        ];

        return {
            readonly:true,
            currOrgId: null,
            //advSearchCriteria: null,
            //advSearchString: null,

            // Rules data
            eventRuleTableColumnsVueTable,
            eventRule_table_rows: [],

            isLoading: false,
            userdetails:{},

            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, {name: "Configuration"},
                           {name:'Faults Rules', active:true} ],
            apiStatus: null,

            setAdminStatusDialog: false,
            adminStatusDialogMessage: "",
            deleteDialog:false,
            currId:null,
            currName:null,

            pagingOptions:this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
            //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable
        };
    },


    //=== Hooks (Refer to EventsAlarms.vue for usage of all hooks) ==============
    mounted() {
        //console.log("Events Rules mounted query " + JSON.stringify(this.$route.query))
        //console.log("Events Rules mounted params " + JSON.stringify(this.$route.params))
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.get_auth_details();
        this.utilsDataRefreshFunc = this.getRulesList;
    },

    watch: {
      pagingOptions: {
        handler () {
            //console.log("rule watch pagingOptions " + JSON.stringify(this.pagingOptions));
            this.getRulesList();
        },
        deep: false,
        immediate: false,
      },

    },
    methods: {
        handlePageChange(value) {
            this.currentPage = value;
        },

        isCancellable(){
            return true;
        },

        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. 
        // So expand/build fields here
        buildFilterableColumns(objs) {
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                if (obj.ruleDefStr) {
                    obj.conditions = obj.ruleDefStr.conditions || "<viewRule to update>"
                    obj.action = obj.ruleDefStr.action || "<viewRule to update>"
                } else if ( obj.ruleDefinition ) {
                    obj.conditions = JSON.stringify(obj.ruleDefinition.conditions)
                    obj.action = JSON.stringify(obj.ruleDefinition.event)
                    delete obj.ruleDefinition
                }
                if ( obj._children ) this.buildFilterableColumns(obj._children);
            })
        },

        getRulesList() {
            this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            query_params.pageNum = this.pagingOptions.page;
            query_params.pageSize= this.pagingOptions.itemsPerPage;

            ApiService.get("/svcFault/api/v1/rule?shorten=true",
                (err, result) => {
                this.isLoading=false;
                //console.log("getRulesList " + JSON.stringify(result, null, 2));
                if ( err ) {
                    this.apiStatus =
                        (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //console.log("getRulesList " + JSON.stringify(result.data.data));

                    // for paginated response 
                    //this.utilsTotalEntriesAllPages = result.data.data.totalEntries ;
                    //this.eventRule_table_rows=result.data.data.docs;

                    // for non-paginated response 
                    this.eventRule_table_rows = result.data.data;
                    this.buildFilterableColumns(this.eventRule_table_rows);
                    //console.log("getRulesList " + JSON.stringify(this.eventRule_table_rows, null, 2));
                }

            }, query_params, "FAULT_SERVICE_URL")
        },
        createEventRule() {
            this.$router.push( { name: "EVENT_RULES_CREATE" })
        },
        viewEventRuleDetails(id, readonly) {
            this.$router.push( { name: "EVENT_RULE_VIEW", params: { id: id, readonly: readonly } })
        },
        editEventRuleDetails(id, readonly) {
            this.$router.push( { name: "EVENT_RULES_EDIT", params: { id: id, readonly: readonly } })
        },

        setAdminStatus(item) {
            item.newAdminStatus = item.newAdminStatus || item.adminStatus; // initialize first time
            item.newAdminStatus = item.newAdminStatus == this.UtilsModaConstants.AdminStatus.ENABLED ? 
                                        this.UtilsModaConstants.AdminStatus.DISABLED : this.UtilsModaConstants.AdminStatus.ENABLED;
            this.editedItem = item;
            //console.log("Adminstatus changed to " + item.newAdminStatus)
            this.adminStatusDialogMessage = "Really change " + item.ruleName + " to '" + item.newAdminStatus + "'";
            this.setAdminStatusDialog = true;
        },

        setAdminStatusChildEvent(cancelFlag)
        {
            //console.log("** Set Rule's AdminStatus to Enable/Disable cancel : " + cancelFlag);
            this.setAdminStatusDialog = false;
            if (cancelFlag == true) { delete this.editedItem.newAdminStatus; delete this.editeditem; return }

            this.isLoading = true;

            let query_params = {};
            if ( this.currOrgId ) 
                query_params.targetOrgId = this.currOrgId;
            let body = {
                    //orgId: query_params.targetOrgId, 
                    //ruleId: this.editedItem.ruleId, 
                    adminStatus: this.editedItem.newAdminStatus
            };

            ApiService.post("/svcFault/api/v1/ruleEngine/rule/status/" + this.editedItem.ruleId, body, (err, result) => {
                this.isLoading = false;
                if ( err ) {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.getRulesList();
                }
            }, query_params, "FAULT_SERVICE_URL")
        },

        deleteEventRule(Id, name) {
            this.deleteDialog = true;
            this.currId = Id;
            this.currName = name;
        },

        deleteObjectChildEvent(cancelFlag, isBackupDel) 
        {
            console.log("** delete " + isBackupDel);
            this.deleteDialog = false;
            if(cancelFlag == true) return;

            //if(confirm("Do you really want to delete - '" + name + "' ?"))
            {
                //this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
                query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

                ApiService.delete("/svcFault/api/v1/rule/"+this.currId, 
                    (err, result) => {
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getRulesList();
                    }
                }, query_params, "FAULT_SERVICE_URL")
            }
        },

        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.getRulesList();
        },
    }

}
</script>
