<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-bell</v-icon> Events
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<TableAdvancedFilter ref="tableAdvancedFilter" :enabledFieldsIn="tableAdvancedFilter.enabledFilterFields"
					v-on:freeFormSearchChanged="processFreeFormSearchChange"
					v-on:advancedFilterChanged="processAdvancedFilterChange" />
			</v-card-text>
			<v-container fluid class="pt-0">
				<v-data-table :loading="isLoading" :headers="alarmEventTableColumnsVueTable" :items="alarmEvent_table_rows"
					multi-sort :options.sync="pagingOptions" :server-items-length="utilsTotalEntriesAllPages"
					:footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }" item-key="alarmId">
					<template v-slot:item.alarmIdCode="{ item }">
						<v-row>
							<nobr>
								<v-icon v-if="isAlarmRaise(item.type)" color="error" title="Raise">mdi-arrow-up</v-icon>
								<v-icon v-else color="success" title="Clear">mdi-arrow-down</v-icon>
								{{ item.alarmIdCode }}
							</nobr>
						</v-row>
					</template>
					<template v-slot:item.typeSubtype="{ item }">
						<TypeIcon :item="item" :UtilsModaConstants="UtilsModaConstants" />
						<SubtypeIcon :item="item" :UtilsModaConstants="UtilsModaConstants" />
					</template>

					<template v-slot:item.siteName="{ item }">
						<a class="link-text" v-on:click="navigateToSite(item.orgId, item.siteId)"> {{ item.siteName }} </a>
					</template>

					<template v-slot:item.source="{ item }">
						<nobr>
							<a class="link-text float-left" v-on:click="navigateToComponent(item)">
								<v-img class="float-left mr-1" v-if="item.sourceIcon"
									:src="require('../../../public/assets/img/moda/' + item.sourceIcon)" height="24" width="24" contain />
								{{ item.source }}
							</a>
						</nobr>
					</template>

					<template v-slot:item.incidentIdCode="{ item }">
						<v-row v-if="item.incidentId">
							<nobr>
								<v-icon :class="utilsNonSeverityColors['MiscBlue'].colorClass">mdi-ticket-confirmation</v-icon>
								<a class="link-text" v-on:click="navigateToIncident(item)"> {{ item.incidentIdCode }} </a>
							</nobr>
						</v-row>
					</template>

					<template v-slot:item.severityStatus="{ item }">
						<v-chip :dark="utilsSeverityColors[item.severity].darkMode"
							:color="utilsSeverityColors[item.severity].colorName" small> {{ item.severity }} </v-chip>
					</template>

					<template v-slot:item.actions="{ item }">
						<v-icon @click="editAlarmEventDetails(item.alarmId, 1)" class="mr-2" color="primary" title="Edit">mdi-eye
						</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)" @click="editAlarmEventDetails(item.alarmId, 0)" color="warning"
							class="mr-2" title="Edit">mdi-pencil</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)" color="error"
							@click="deleteAlarmEventDetails(item.alarmId, item.shortDescription)" title="Delete">mdi-delete</v-icon>
					</template>
				</v-data-table>
			</v-container>
		</v-card>

		<DeleteDialog v-if="deleteDialog" :currentObjectName="currName" :delMessage="deleteMessage"
			@DeleteDialogCallback='deleteObjectChildEvent'>
		</DeleteDialog>

	</div>
</template>

<script>

import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import TypeIcon from '@/components/templates/TypeIcon'
import SubtypeIcon from '@/components/templates/SubtypeIcon'
import TableAdvancedFilter from '@/components/templates/TableAdvancedFilter'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'

//--- socket.io ---
import socketService from '@/services/socket.io.service';
import ModaConst from '@/services/ModaConstants.js';

export default {
    name: 'Alarms',
    components: {
        Breadcrumb,
        DeleteDialog,
        TableAdvancedFilter,
        TypeIcon,
        SubtypeIcon
    },
    mixins: [
        Utils,
    ],

    data() {
        var alarmEventTableColumnsVueTable = [
           { 
               text: 'Id',
               value: 'alarmIdCode',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Type, Subtype',
               value: 'typeSubtype',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Site',
               value: 'siteName',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Source',
               value: 'source',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Incident',
               value: 'incidentIdCode',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Severity',
               value: 'severityStatus',
               sortable: true,
               filterable: true,
           },
/*
           {
               text: 'Status',
               value: 'status',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Short Description',
               value: 'shortDescription',
               sortable: true,
               filterable: true,
           },
*/
           {
               text: 'Details',
               value: 'description',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Creation Time',
               value: 'createdDateString',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Actions',
               value: 'actions',
								width: 120
           },
        ];

        return {
            readonly:true,
            currOrgId: null,

            alarmEventTableColumnsVueTable,
            // Alarms data
            alarmEvent_table_rows: [],

            advSearchCriteria: null,
            //advSearchString: null,
            defaultAdvSearchCriteria: [
                    {"name":"status", // default all NEW, ACK statuses
                      "value": [
                            ModaConst.Alarm.Status.NEW,
                            ModaConst.Alarm.Status.ACKNOWLEDGED,
                        ]
                    },
                    {"name":"severity", // default top 3 severities
                      "value": [
                            ModaConst.FaultService.Severity.CRITICAL,
                            ModaConst.FaultService.Severity.SEVERE,
                            ModaConst.FaultService.Severity.MAJOR,
                        ]
                    },
            ],
            tableAdvancedFilter : {
                enabledFilterFields : [], //this.utilsAdvancedFilterFields template doesn't work directly using mixin's data. Init it in mounted()
            },

            pagingOptions: sessionStorage.getItem(`_pagination${this.$route.path}`) ? JSON.parse(sessionStorage.getItem(`_pagination${this.$route.path}`)) : this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
            //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable

            userdetails:{},

            isLoading: false,
            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, { name: "Monitoring" }, {name:'Events', active:true} ],
            apiStatus: null,

            deleteMessage:null,
            deleteDialog:false,
            currId:null,
            currName:null,
            deleteDialogOper:true,
            linkDialog:true,

            //Note: Values of site(List) and component(List) are passed in a tuple of (idList & nameList)
            // During a cross launch to this page from other pages, the passed in values are set in xLaunch
            xLaunch : {
                site: {
                    siteIdList: [],         // ["sitobj.1001"] 
                    siteNameList: []        // ["Austin"]
                },
                component : {               // == "source"
                    componentIdType: ModaConst.FaultService.ComponentIdType.OTHER,  // default
                    componentIdList: [],    // ["appobj.1001"]
                    componentNameList: []   // ["Zoom"]
                }
            },

            //---socket ---
            pageName : ModaConst.Socket.Page.ALARMS,
            isConnected : false,
            isPageActivated : false,
            isPageVisible : true,
            socketMessage : "",
            eventData : null            // Data from a Socket event
        };
    },


    //=== Hooks ================
    //Note: Can't emit/sendEvent in beforeCreate, as the client is not connected to the server/socket yet
    //TRACE:beforeCreate: function() { console.log("in hook: beforeCreate"); },

    created: function() {
/* TBD Commenting out websockets for now until Kong issue is figured out !!!!!!!!!!!
        // Connect to the Socket server on 'created' hook only
        let cSocket = socketService.setupSocketConnection(this.pageName, "FAULT_SERVICE_WS_URL", this.UtilsModaConst.Service.FAULT);
        this.isConnected = true;
        this.socketMessage = "Connected to the FaultServer";

        socketService.sendMessage(this.pageName, "in hook: created. Connected");

        // Refer: https://socket.io/docs/v4/client-socket-instance/
        cSocket.on("connect", () => {
            console.log("Connected to the Socket server. socket.id = " + cSocket.id);
            socketService.addSocketToActiveMap(this.pageName, cSocket);

            socketService.sendEvent(this.pageName, "page", {pageName: this.pageName, orgId: this.currOrgId});

            this.attachSocketMessageHandlers(this, cSocket);
        });
!!!!! */
    },

    //TRACE:beforeMount: function() { socketService.sendMessage(this.pageName, "in hook: beforeMount"); },

    mounted: function() {
        // template doesn't work directly using mixin's data
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.FREEFORM_SEARCH)
        //this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.ORG_NAME) // orgName removed
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.SITE_NAME)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.EVENT_SOURCE_NAME)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.EVENT_TYPE)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.EVENT_SEVERITY)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.EVENT_STATUS)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.CREATED_AFTER)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.CREATED_BEFORE)

        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        //this.advSearchCriteria = this.$route.query && typeof(this.$route.query) == Array ?
                                    //this.$route.query : this.defaultAdvSearchCriteria;

        if ( this.$route.query && Array.isArray(this.$route.query) ) {
            // query passed in from cross launch
            this.advSearchCriteria = this.$route.query
            auth.globalIncidentsAdvSearch = this.advSearchCriteria
        }else if ( auth.globalAlarmsAdvSearch )
            // else, a global state if any
            this.advSearchCriteria = auth.globalAlarmsAdvSearch
        else // else default one
            this.advSearchCriteria = this.defaultAdvSearchCriteria;

/*
        if ( this.$route.params && this.$route.params.advSearchString )
            this.advSearchString = this.$route.params.advSearchString
        else if ( auth.globalAlarmsAdvSearchString )
            this.advSearchString = auth.globalAlarmsAdvSearchString
*/

        if ( auth.globalAlarmsFreeFormSearchStr )
             this.freeFormSearchStr = auth.globalAlarmsFreeFormSearchStr

        this.init()
        this.get_auth_details();
        this.utilsDataRefreshFunc = this.getAlarmsList;

        //--- socket ---
        socketService.sendMessage(this.pageName, "in hook: mounted");

        // Refer: https://codeurce.io/vue-snippet-detect-if-the-user-navigates-to-another-tab/
        document.addEventListener('visibilitychange', this.handleVisibility, false);
    },

    //TRACE: beforeUpdate: function() { socketService.sendMessage(this.pageName, "in hook: beforeUpdate"); },
    //TRACE: updated: function() { socketService.sendMessage(this.pageName, "in hook: updated"); },
    //TRACE:beforeDestroy: function() { socketService.sendMessage(this.pageName, "in hook: beforeDestroy"); },
    //TRACE:unmounted: function() { socketService.sendMessage(this.pageName, "in hook: unmounted"); },
    activated: function() {
        this.isPageActivated = true;
        console.log(this.pageName, "in hook: " + this.pageName + " activated " + "this.pagingOptions ", JSON.stringify(this.pagingOptions));
        socketService.sendMessage(this.pageName, "in hook: " + this.pageName + " activated");
        if ( ! this.isLoading2 ) this.getAlarmsList(); // refresh alarms list when page becomes visible
    },
    deactivated: function() {
        this.isPageActivated = false;
        console.log(this.pageName, "in hook: " + this.pageName + " deactivated");
        socketService.sendMessage(this.pageName, "in hook: " + this.pageName + " deactivated");
    },

    destroyed: function() {
        socketService.sendMessage(this.pageName, "in hook: destroyed. Disconnecting socket");
 
        // Disconnect from the Socket server on 'destroyed' hook only
        socketService.disconnectSocket(this.pageName);
        this.isConnected = false; 
    },
    //===========================

    watch: {

      pagingOptions: {
        handler () {
            // console.log("this.pagingOptions ", JSON.stringify(this.pagingOptions) + " activated " + this.isPageActivated);
            sessionStorage.setItem(`_pagination${this.$route.path}`, JSON.stringify(this.pagingOptions));
            if ( ! this.isLoading2 ) this.getAlarmsList(); // called in activated()
        },
        deep: false,
        immediate: false,
      },

    },

    methods: {
        handlePageChange(value) {
            this.currentPage = value;
        },

        isAlarmRaise(type) {
            return (type=="Raise");
        },
        isCancellable() {
            return true;
        },

        navigateToSite(targetOrgId, id){
            var readonly = 1
            this.$router.push({ name: "SITES_EDIT_TARGETORG", params: { siteId: id, readonly: readonly, targetOrgId: targetOrgId } })
        },
        navigateToComponent(item){
            var readonly = 1
            if ( item.componentIdType ==  this.UtilsModaConstants.FaultService.ComponentIdType.MONITORED_DEVICE)
                this.$router.push({ name: "MONITORED_DEVICES_EDIT", params: { id: item.componentId, readonly: readonly}})
            else if ( item.componentIdType ==  this.UtilsModaConstants.FaultService.ComponentIdType.DEVICE_AGENT)
                this.$router.push({ name: "MODA_AGENTS_EDIT", params: { id: item.componentId, readonly: readonly}})
            else if ( item.componentIdType ==  this.UtilsModaConstants.FaultService.ComponentIdType.SITE)
                this.$router.push({ name: "SITES_EDIT", params: { siteId: item.componentId, readonly: readonly} })
            else if ( item.componentIdType ==  this.UtilsModaConstants.FaultService.ComponentIdType.APP)
                this.$router.push({ name: "APPLICATIONS_EDIT", params: { appId: item.componentId, readonly: readonly} })
        },
        navigateToIncident(item){
            var readonly = 1
            this.$router.push({ name: "INCIDENTS_EDIT", params: { id: item.incidentId, readonly: readonly}})
        },

        buildFilterableColumns(objs){
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                if ( obj._children ) this.buildFilterableColumns(obj._children);

                obj.componentIdTypeIcon = obj.componentIdType=="citApp" ? "💻" : "🏢";
                obj.source = ""
                // If omponentName does not include IPAddr & it is available in monDevInfo, prefix the source with it
                if ( obj.monDevInfo && obj.monDevInfo.monDevIpAddress && !obj.componentName.startsWith('IP-')) {
                    obj.source = obj.source.concat(obj.monDevInfo.monDevIpAddress)
                    obj.source = obj.source.concat(" / " )
                }
                obj.source = obj.source.concat(obj.componentName); //CHECK: How to prefix with the obj.componentIcon ?
                obj.sourceIcon = obj.componentIcon;
            })
        },
        getAlarmsList(){
            this.isLoading=true
            this.isLoading2=true

            let query_params = {}, body={};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            query_params.pageNum = this.pagingOptions.page;
            query_params.pageSize = this.pagingOptions.itemsPerPage;
            if ( this.freeFormSearchStr ) query_params.searchString= "any:*:" + this.freeFormSearchStr

            var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, {componentName: 'componentId', severityStatus: "severityOriginal"} )
            if ( sortCriteria ) query_params.sortCriteria = sortCriteria
            if ( this.advSearchCriteria ) {
                //console.log("getAlarmsList advSearchCriteria ", JSON.stringify(this.query))
                var advSearchCriteria = []
                Object.keys(this.advSearchCriteria).forEach( (k) => advSearchCriteria.push(this.advSearchCriteria[k]) )
                if ( advSearchCriteria.length ) body.advSearchCriteria = advSearchCriteria
                //console.log("getAlarmsList body ", JSON.stringify(body))
            }

            //ApiService.get("/svcFault/api/v1/alarm?shorten=true",
            ApiService.post("/svcFault/api/v1/alarm/page2?shorten=true", body,
                (err, result) => {
                this.isLoading=false
                this.isLoading2=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //this.totalPages = result.data.data.totalPages ;
                    this.utilsTotalEntriesAllPages = result.data.data.totalEntries ;
                    //console.log("getAlarmsList " + result.data.data.totalEntries + " " +
                                    //result.data.data.totalPages + " " + result.data.data.currentPage + " " );
                    this.alarmEvent_table_rows=result.data.data.docs ;
                    this.buildFilterableColumns(this.alarmEvent_table_rows);
                    //console.log("getAlarmsList " + this.alarmEvent_table_rows[0].type);
                    //console.log("getAlarmsList " + JSON.stringify(this.alarmEvent_table_rows));
                }

            }, query_params, "FAULT_SERVICE_URL")
        },

        editAlarmEventDetails(id, readonly){
            this.$router.push( { name: "ALARMS_EDIT", params: { id: id, readonly: readonly}})
        },
        cancelAlarmEventDetails(Id, name){
            if(confirm("Do you really want to cancel - '" + name + "' ?")) {
                //this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

                //ApiService.put("/mcpsvc/api/v1/deviceConfigJob/cancel/"+Id, 
                ApiService.put("/svcFault/api/v1/jobs/cancel/"+Id,
                    {}, (err, result) => {
                    //console.log("**"+JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getAlarmsList();
                    }
                }, query_params, "JOBS_SERVICE_URL")
            }
        },
        deleteAlarmEventDetails(Id, name){
            this.deleteDialog = true;
            this.currId = Id;
            this.currName = name;
            this.deleteDialogOper = true;
        },
        deleteAlarmChildEvent(cancelFlag, isBackupDel) 
        {
            cancelFlag, isBackupDel
            //console.log("** delete " + isBackupDel + cancelFlag);
            //this.isLoading =true;

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

            ApiService.delete("/svcFault/api/v1/alarm/"+this.currId, 
                    (err, result) => {
                //console.log("**"+JSON.stringify(result))
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.getAlarmsList();
                }
            }, query_params, "FAULT_SERVICE_URL")
        },
        deleteObjectChildEvent(cancelFlag, isBackupDel) 
        {
            //console.log("** delete " + isBackupDel);
            this.deleteDialog = false;
            if(cancelFlag == true) return;

            if(this.deleteDialogOper == true)
                this.deleteAlarmChildEvent(cancelFlag, isBackupDel); 
            else{
                //console.log("** linkUnlink " + isBackupDel + " link " + this.linkDialog);
            }
        },
        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.clearPaginationCache();
            this.getAlarmsList();
        },
        clearPaginationCache(){
            sessionStorage.removeItem(`_pagination${this.$route.path}`);
            this.pagingOptions.page = 1;
        },

        // helper function to compare two lists. //TODO: Move this to common utils file
        isSameList(list1, list2) {
            //DEBUG: console.log("isSameList: list1 = [" + list1 + "], list2 = [" + list2 + "]");
            let res = false;
            if (list1 == list2) {       //TODO: Use token based comparision
                res = true;
            } 

            console.log("isSameList: res = " + res);
            return res;
        },
        processFreeFormSearchChange(freeFormSearchStr){
            this.freeFormSearchStr = freeFormSearchStr
            auth.globalAlarmsFreeFormSearchStr = freeFormSearchStr
            console.log("EventsAlarms processFreeFormSearchChange " + this.freeFormSearchStr)
            this.getAlarmsList();
        },
        processAdvancedFilterChange(advancedFilter){
            //console.log("Table filter selected " + JSON.stringify(advancedFilter))

            this.pagingOptions.page = 1;        // Reset page to 1st page, for AdvSearch APPLY

            // append to advanced search passed in, if any
            this.advSearchCriteria = []
            //this.advSearchString = ""

/* orgName filter removed
            if ( advancedFilter.orgNameFilter ){
                this.advSearchCriteria.push({"name":"orgName", "value": advancedFilter.orgNameFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.orgNameFilter)
            }
*/
            if ( advancedFilter.siteNameFilter ){
                if (advancedFilter.siteNameFilter == null || advancedFilter.siteNameFilter == [] ||
                    !this.isSameList(advancedFilter.siteNameFilter, this.xLaunch.site.siteNameList)) {
                    //advancedFilter.siteNameFilter != this.xLaunch.site.siteNameList)
                        // siteNameList is empty or cross-launched siteNameList is modified. 
                        // Let the backend calculate the Ids, as needed
                        this.xLaunch.site.siteIdList = null;
                }
                this.advSearchCriteria.push({"name":"site", "value": 
                        {siteIdList: this.xLaunch.site.siteIdList, 
                        siteNameList: advancedFilter.siteNameFilter}})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.siteNameFilter)
            }

            if ( advancedFilter.eventSourceNameFilter ){
                if (advancedFilter.componentNameFilter == null || advancedFilter.componentNameFilter == [] ||
                    !this.isSameList(advancedFilter.componentNameFilter, this.xLaunch.component.componentNameList)) {
                    //advancedFilter.componentNameFilter != this.xLaunch.component.componentNameList)
                        // componentNameList is empty or cross-launched componentNameList is modified. 
                        // Let the backend calculate the Ids, as needed
                        this.xLaunch.component.componentIdList = null;
                }
                this.advSearchCriteria.push({"name":"component", "value": 
                        {componentIdType: this.xLaunch.component.componentIdType, 
                        componentIdList: this.xLaunch.component.componentIdList, 
                        componentNameList: advancedFilter.eventSourceNameFilter}})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.eventSourceNameFilter)
            }

            if ( advancedFilter.eventTypeFilter ){
                this.advSearchCriteria.push({"name":"categoryType", "value": advancedFilter.eventTypeFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.eventTypeFilter)
            }
            if ( advancedFilter.eventSeverityFilter ){
                this.advSearchCriteria.push({"name":"eventSeverity", "value": advancedFilter.eventSeverityFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.eventSeverityFilter)
            }
            if ( advancedFilter.eventStatusFilter ){
                this.advSearchCriteria.push({"name":"eventStatus", "value": advancedFilter.eventStatusFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.eventStatusFilter)
            }
            if ( advancedFilter.createdAfterFilter && advancedFilter.createdBeforeFilter ){
                this.advSearchCriteria.push({"name":"createdDate",
                                  "value": [advancedFilter.createdAfterFilter, advancedFilter.createdBeforeFilter]})
                //this.advSearchString = this.utilsAppendString(this.advSearchString,
                       //"Between " + advancedFilter.createdAfterFilter + " & " + advancedFilter.createdBeforeFilter)
            } else if ( advancedFilter.createdAfterFilter ){
                this.advSearchCriteria.push({"name":"createdDate", "value": ">"+advancedFilter.createdAfterFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, "After " + advancedFilter.createdAfterFilter)
            }else if ( advancedFilter.createdBeforeFilter ){
                this.advSearchCriteria.push({"name":"createdDate",
                        "value": "<"+advancedFilter.createdBeforeFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, "Before " + advancedFilter.createdBeforeFilter)
            }
            auth.globalAlarmsAdvSearch       = this.advSearchCriteria;
            //auth.globalAlarmsAdvSearchString = this.advSearchString;
            this.clearPaginationCache();
            this.getAlarmsList();
        },

        //---socket ---
        attachSocketMessageHandlers(self, clientSocket) {
            console.log("attachSocketMessageHandlers: clientSocket.id = " + (clientSocket) ? clientSocket.id : null);

            clientSocket.on(this.UtilsModaConst.Socket.Event.ALARM, data => {
                console.log("Got an Alarm event from server: data = " + JSON.stringify(data));
                self.eventData = data;
                let relevantEvent = true;   //TODO: determine to refersh the alarms list or not based on the eventData - 
                                            // ie no need to refresh the page, if the current Alarm event's siteIdPrefix 
                                            // is not same as the current context (breadcrumb)  
                if (relevantEvent && self.isPageVisible) {
                    //DEBUG: console.log("Socket: getting alarms list ...");
                    self.getAlarmsList();
                }
            });

            clientSocket.on('message', (data) => {
                console.log("Got message from Server: data = " + JSON.stringify(data));
                self.socketMessage  = data;
            });
        },

        handleVisibility () {
            if (document.visibilityState === 'hidden') {
                this.isPageVisible = false;
                this.socketMessage = "Client's Alarms Page is hidden";
            } else {
                this.isPageVisible = true;
                this.socketMessage = "Client's Alarms page is visible " + this.isPageActivated;
                if ( this.isPageActivated ) 
                    this.getAlarmsList(); // refresh alarms list when page becomes visible
            }
            //DEBUG:
            socketService.sendMessage(this.pageName, this.socketMessage);
        },

        init(){
            if ( this.advSearchCriteria ){
                //console.log("EventsIncidents.vue init this.advSearchCriteria " + JSON.stringify(this.advSearchCriteria))
                var tmp
/* orgName filter removed
                tmp = this.advSearchCriteria.find(e => e.name == "orgNames")
                if ( tmp ) {
                    this.tableAdvancedFilter.orgNamesFilterIn  = tmp.value;
                    // orgNames are only to pass to this component, orgIds are sent to backend
                    this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "orgNames")
                }
*/
                tmp = this.advSearchCriteria.find(e => e.name == "site")
                if ( tmp ) {
                    this.tableAdvancedFilter.siteNamesFilterIn  = tmp.value.siteNameList;
                    // siteNames are only to pass to this component, orgIds are sent to backend
                    // send names to backend
                    // this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "siteName")
                    this.xLaunch.site = tmp.value;
                } else { // set default
                    this.tableAdvancedFilter.siteNamesFilterIn = [];
                    this.xLaunch.site = {siteIdList: [], siteNameList: []};
                }

                //Only MonDevs are needed ???
                tmp = this.advSearchCriteria.find(e => e.name == "component")
                if ( tmp ) {
                    this.tableAdvancedFilter.eventSourceNamesFilterIn = tmp.value.componentNameList;
                    this.xLaunch.component = tmp.value;
                } else { // set default
                    this.tableAdvancedFilter.eventSourceNamesFilterIn = [];
                    this.xLaunch.component = {componentIdType: this.UtilsModaConstants.FaultService.ComponentIdType.OTHER,
                                              componentIdList: [], componentNameList: []};
                }

                tmp = this.advSearchCriteria.find(e => e.name == "severity")
                if ( tmp ) this.tableAdvancedFilter.eventSeveritiesFilterIn  = tmp.value

                tmp = this.advSearchCriteria.find(e => e.name == "status")
                if ( tmp ) this.tableAdvancedFilter.eventStatusesFilterIn  = tmp.value
            }

            this.$refs.tableAdvancedFilter.setAdvancedFilters(this.tableAdvancedFilter, this.freeFormSearchStr)

            //console.log("EventIncidents init orgNamesFilter " + this.tableAdvancedFilter.orgNamesFilterIn + 
                                //", eventStatusFilter " + this.tableAdvancedFilter.eventStatusFilterIn)
        },
    }

}
</script>
