<template>
  <div :class="showlabels ? 'full-width' : ''">
    <div class="float-left">
      <v-tooltip top v-if="getInfo(item.categorySubType)">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" medium class="mr-2" color="primary">
            {{ getInfo(item.categorySubType).icon }}</v-icon>
        </template>
        <span>{{ getInfo(item.categorySubType).text }}</span>
      </v-tooltip>
    </div>
    <div v-if="showlabels" class="float-left">
      <span v-if="getInfo(item.categorySubType)">{{ getInfo(item.categorySubType).text }} </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SubtypeIcon',
  props: ['item', 'UtilsModaConstants', 'showlabels'],
  methods: {
    getInfo(e) {
      let info = null;
      switch (e) {
        case this.UtilsModaConstants.FaultService.CategorySubType.LogAnalysis.ML_LOG_ANALYSIS_SEQUENCE:
          info = { icon: "mdi-text-long", text: "Message sequence" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.LogAnalysis.ML_LOG_ANALYSIS_PARAMS:
          info = { icon: "mdi-order-numeric-descending", text: "Parameter sequence" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.DeviceAvailability.ICMP_PING:
          info = { icon: "mdi-close-circle-outline", text: "No ping response" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.Manual.MANUAL:
          info = { icon: "mdi-account", text: "Manual" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.Internal.INTERNAL:
          info = { icon: "mdi-cog", text: "Internal" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.ExternalSystem.CONNECTWISE:
          info = { icon: "mdi-cog", text: "ConnectWise" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.Other.OTHER:
          info = { icon: "mdi-circle-outline", text: "Other" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.DeviceProblem.ICMP_RESPONSE_TIME:
          info = { icon: "mdi-sort-clock-ascending", text: "ICMP reponse time" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.DeviceProblem.HIGH_CPU_USAGE:
          info = { icon: "mdi-cpu-32-bit", text: "High CPU usage" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.DeviceProblem.HIGH_DISK_USAGE:
          info = { icon: "mdi-harddisk", text: "High disk Usage" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.DeviceProblem.HIGH_MEMORY_USAGE:
          info = { icon: "mdi-memory", text: "High memory usage" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.DeviceProblem.HIGH_TEMPERATURE:
          info = { icon: "mdi-thermometer-lines", text: "High temperature" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.DeviceProblem.VOLTAGE_OUT_OF_RANGE:
          info = { icon: "mdi-battery-outline", text: "Voltage out of range" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.DeviceProblem.POWER_SUPPLY_FAILURE:
          info = { icon: "mdi-power", text: "Power supply failure" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.DeviceProblem.FAN_FAILURE:
          info = { icon: "mdi-fan", text: "Fan failure" };
          break;
        case this.UtilsModaConstants.FaultService.CategorySubType.DeviceProblem.GENERIC:
          info = { icon: "mdi-adjust", text: "Generic" };
          break;
        default:
          break;
      }
      return info;
    }
  }
}
</script>
