<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-arrow-up-thick</v-icon> MODA Agent Software Update Jobs
				<v-btn rounded outlined color="primary" absolute right :class="isShow" v-on:click="createDeviceSWUpdateJobs()">
					<v-icon>mdi-plus</v-icon> New
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
			</v-card-text>
			<v-container fluid class="pt-0">
				<div class="search-wrapper">
					<v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify"
						@input="utilsFilterChanged" />
				</div>
				<v-data-table :headers="deviceSWUpdateJobs_table_vuetify_columns" :items="deviceSWUpdateJobs_table_rows"
					:options.sync="pagingOptions" :server-items-length="utilsTotalEntriesAllPages" show-select
					v-model="jobsSelected1" :footer-props="{'items-per-page-options':[5, 10, 15, 25, 100]}" item-key="jobId"
					multi-sort no-data-text="No jobs found">

					<template v-slot:item.jobId="{ item }">
						<span> {{item.jobId.split('_')[0].split("jobinf.")[1]}} </span>
					</template>

					<template v-slot:item.devices="{ item }">
						<span v-for="device in item.devices" :key="device.deviceId">
							<a class="link-text" :title="device.deviceName" v-on:click="navigateToDevice(device.deviceId)">
								{{device.deviceName}} </a>
							<br />
						</span>
					</template>

					<template v-slot:item.scheduledDateString="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on"
									:color="utilsJobScheduleTypeDisplayObject(item.jobScheduleType).colorName">
									{{utilsJobScheduleTypeDisplayObject(item.jobScheduleType).icon}}
								</v-icon>
							</template>
							<span>{{utilsJobScheduleTypeDisplayObject(item.jobScheduleType).text}}</span>
						</v-tooltip>
						{{item.scheduledDateString}}
					</template>

					<template v-slot:item.jobStatus="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" :color="utilsJobStateDisplayObject(item.jobState).colorName">
									{{utilsJobStateDisplayObject(item.jobState).icon}}
								</v-icon>
							</template>
							<span>{{utilsJobStateDisplayObject(item.jobState).text}} /
								{{utilsJobStatusDisplayObject(item.jobStatus).text}}</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" :color="utilsJobStatusDisplayObject(item.jobStatus).colorName">
									{{utilsJobStatusDisplayObject(item.jobStatus).icon}}
								</v-icon>
							</template>
							<span>{{utilsJobStateDisplayObject(item.jobState).text}} /
								{{utilsJobStatusDisplayObject(item.jobStatus).text}}</span>
						</v-tooltip>
					</template>

					<template v-slot:item.createdBy="{ item }">
						<a class="link-text" :title="item.createdByEmail" v-on:click="navigateToUser(item.createdBy)">
							{{item.createdByEmail}}
						</a>
					</template>

					<template v-slot:item.actions="{ item }">
						<v-row>
							<v-icon @click="editDeviceSWUpdateJobsDetails(item.jobId, 1)" class="mr-2" color="primary" title="View">
								mdi-eye </v-icon>
							<span >
								<!-- <v-icon small @click="editDeviceSWUpdateJobsDetails(item.jobId, 0)" class="mr-2 record-edit" title="Edit"> fas fa-pencil-alt </v-icon>-->
								<v-icon v-if="isCancellable(item)" :class="utilsGetDisplayClass(item)"
									@click="cancelDeviceSWUpdateJobsDetails(item.jobId, item.jobName)" class="mr-2" color="warning"
									title="Cancel">mdi-cancel</v-icon>
								<v-icon :class="utilsGetDisplayClass(item)" @click="deleteDeviceSWUpdateJobsDetails(item.jobId, item.jobName)" color="red"
									title="Delete">mdi-delete</v-icon>
							</span>
						</v-row>
					</template>
				</v-data-table>
				<div class="search-wrapper">
					<v-text-field v-model="filterValueJobRuns" label="Search table" prepend-inner-icon="mdi-magnify"
						@input="filterChangedJobRuns" />
				</div>
				<v-data-table :headers="deviceSWUpdateJobRuns_table_vuetify_columns"
					:items="deviceSWUpdateJobRuns_table_rows" :options.sync="jobRunsPagingOptions"
					:server-items-length="jobRunsTotalEntriesAllPages"
					:footer-props="{'items-per-page-options':[5, 10, 15, 25, 100]}" item-key="jobRunId" multi-sort
					:no-data-text="deviceSWUpdateJobs_table_rows.length ? (jobsSelected.length ? 'No job runs found for selected jobs' : 'Select one or more rows in above table') : 'No job runs found'">

					<template v-slot:item.jobId="{ item }">
						<span> {{item.jobId.split('_')[0].split("jobinf.")[1]}} </span>
					</template>

					<template v-slot:item.jobRunId="{ item }">
						<span> {{item.jobRunId.split('_')[0].split("jobrun.")[1]}} </span>
					</template>

					<template v-slot:item.devices="{ item }">
						<span v-for="device in item.devices" :key="device.deviceId">
							<a class="link-text" :title="device.deviceName" v-on:click="navigateToDevice(device.deviceId)">
								{{device.deviceName}} </a>
							<br />
						</span>
					</template>

					<template v-slot:item.swImagesDeployed="{ item }">
						<span   v-for="swImg in item.deviceSoftwareImages" :key="swImg.deviceSoftwareImageId">
							<a class="link-text" v-on:click="navigateToSoftwareImage(swImg)">
								{{swImg.deviceSoftwareImageName}}<br> </a>
						</span>
					</template>

					<template v-slot:item.jobRunStatus="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" :color="utilsJobRunStatusDisplayObject(item.jobRunStatus).colorName"
									:class="utilsJobRunStatusDisplayObject(item.jobRunStatus).class">
									{{utilsJobRunStatusDisplayObject(item.jobRunStatus).icon}}
								</v-icon>
							</template>
							<span>{{utilsJobRunStatusDisplayObject(item.jobRunStatus).text}}</span>
						</v-tooltip>
					</template>

				</v-data-table>
			</v-container>
		</v-card>
		<DeleteDialog v-if="deleteDialog" :currentObjectName="currName" @DeleteDialogCallback='deleteObjectChildEvent'>
		</DeleteDialog>

	</div>
</template>

<script>
import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
//import AdsTreeTableUtils from './AdsTreeTableUtils.vue'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'

export default {
    components: {
        Breadcrumb,
        DeleteDialog,
    },
    mixins: [
        //AdsTreeTableUtils,
        Utils,
    ],
    data(){
        var deviceSWUpdateJobs_table_vuetify_columns= [
           {
               text: 'Job Id',
               value: 'jobId',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Job Name',
               value: 'jobName',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Device(s)',
               value: 'devices',
               sortable: true,
               filterable: true,
           },
/* - taking too much space
           {
               text: 'Created By',
               value: 'createdBy',
               sortable: true,
               filterable: true,
           },
*/
/* - Device config jobs are always onetime. No need to display this
           {
               text: 'Frequency',
               value: 'jobFrequency',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Schedule Type',
               value: 'jobScheduleType',
               sortable: true,
               filterable: true,
           },
*/
           {
               text: 'Scheduled Time',
               value: 'scheduledDateString',
               sortable: true,
               filterable: true,
           },
           {
               text: 'State/Status',
               value: 'jobStatus',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Creation Time',
               value: 'createdDateString',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Actions',
               value: 'actions',
           },
        ];

        var deviceSWUpdateJobRuns_table_vuetify_columns= [
           {
               text: 'Job Id',
               value: 'jobId',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Job Run Id',
               value: 'jobRunId',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Device(s)',
               value: 'devices',
               sortable: true,
               filterable: true,
           },
           {
               text: 'SW Images deployed',
               value: 'swImagesDeployed',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Run at',
               value: 'jobRunAtDateString',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Status',
               value: 'jobRunStatus',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Creation Time',
               value: 'createdDateString',
               sortable: true,
               filterable: true,
           },
        ];



        var deviceSWUpdateJobs_table_columns= [
           {
               title: 'Job Id',
               property: 'jobId',
               direction: true,
               filterable: true,
           },
           {
               title: 'Job Name',
               property: 'jobName',
               direction: true,
               filterable: true,
           },
           {
               title: 'Device(s)',
               property: 'devices',
               direction: true,
               filterable: true,
           },
/* - taking too much space
           {
               title: 'Created By',
               property: 'createdBy',
               direction: true,
               filterable: true,
           },
*/
/* - Device config jobs are always onetime. No need to display this
           {
               title: 'Frequency',
               property: 'jobFrequency',
               direction: true,
               filterable: true,
           },
           {
               title: 'Schedule Type',
               property: 'jobScheduleType',
               direction: true,
               filterable: true,
           },
*/
           {
               title: 'Scheduled Time',
               property: 'scheduledDateString',
               direction: true,
               filterable: true,
           },
           {
               title: 'State/us',
               property: 'jobStatus',
               direction: true,
               filterable: true,
           },
           {
               title: 'Creation Time',
               property: 'createdDateString',
               direction: true,
               filterable: true,
           },
           {
               title: 'Actions',
               property: 'actions',
           },
        ];

        var deviceSWUpdateJobRuns_table_columns= [
           {
               title: 'Job Id',
               property: 'jobId',
               direction: true,
               filterable: true,
           },
           {
               title: 'Job Run Id',
               property: 'jobRunId',
               direction: true,
               filterable: true,
           },
           {
               title: 'Device(s)',
               property: 'devices',
               direction: true,
               filterable: true,
           },
           {
               title: 'Profile snapshots deployed',
               property: 'deployedProfiles',
               direction: true,
               filterable: true,
           },
           {
               title: 'Run at',
               property: 'jobRunAtDateString',
               direction: true,
               filterable: true,
           },
           {
               title: 'Status',
               property: 'jobRunStatus',
               direction: true,
               filterable: true,
           },
           {
               title: 'Creation Time',
               property: 'createdDateString',
               direction: true,
               filterable: true,
           },
        ];

        return {
            currOrgId: null,

            // Jobs data
            deviceSWUpdateJobs_table_columns,
            deviceSWUpdateJobs_table_vuetify_columns,
            deviceSWUpdateJobs_table_rows: [],
            jobsSelected1: [],
            jobsSelected: [],

            // Job Runs data
            deviceSWUpdateJobRuns_table_columns,
            deviceSWUpdateJobRuns_table_vuetify_columns,
            deviceSWUpdateJobRuns_table_rows: [],
            currPageJobRuns: 0,
            startPageJobRuns: 0,
            endPageJobRuns: 10,
            itemsPerPageJobRuns: 10,
            filterValueJobRuns : '',
            utilsAwaitingJobRunsSearchTimer: null,

            isLoading: false,

            userdetails:{},

            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Profiles & Jobs"},
                           {name:'Device SW Update Jobs', active:true} ],
            apiStatus: null,

            pagingOptions:this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
            jobRunsPagingOptions:this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
            //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable
            jobRunsTotalEntriesAllPages:0,

			deleteDialog:false,
			currId:null,
			currName:null,

        };
    },
    watch: {
      pagingOptions: {
        handler () {
            this.getDeviceSWUpdateJobsList();
        },
        deep: false,
        immediate: false,
      },
      jobRunsPagingOptions: {
        handler () {
            this.getDeviceSWUpdateJobRunsList();
        },
        deep: false,
        immediate: false,
      },
      jobsSelected1: {
        handler () {
            this.jobInfoSelected(this.jobsSelected1);
        },
        deep: false,
        immediate: false,
      },
    },
    methods: {

        // Jobs

        isCancellable(row){
            // can't cancel IMMEDIATE or already EXECUTED jobs
            return row.jobScheduleType != this.UtilsModaConstants.ModaScheduleType.IMMEDIATE &&
                   row.jobStatus == this.UtilsModaConstants.JobStatus.PENDING;
        },
        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
        buildFilterableColumns(objs){
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                if(obj.jobScheduleType == 'SCHEDULED')  {
                    //console.log("buildFilterableColumns " + JSON.stringify(obj.jobScheduleInfo.SCHEDULED.runAt));
                    if(obj.jobScheduleInfo)
                    obj.scheduledDateString = this.utilsFormatDateTime(obj.jobScheduleInfo.SCHEDULED.runAt);
                } 
                else if(obj.jobScheduleType == 'IMMEDIATE')  {
                    //console.log("buildFilterableColumns " + JSON.stringify(obj.jobScheduleInfo.IMMEDIATE.runAt));
                    if((obj.jobScheduleInfo) && (obj.jobScheduleInfo.IMMEDIATE))
                        obj.scheduledDateString = this.utilsFormatDateTime(obj.jobScheduleInfo.IMMEDIATE.runAt);
                } 
                if ( obj._children ) this.buildFilterableColumns(obj._children);
            })
            //console.log("buildFilterableColumns" + JSON.stringify(objs));
        },
        navigateToDevice(id){
            var readonly = 1
            this.$router.push({name : "MODA_AGENTS_EDIT", params: { id: id, readonly: readonly } } )
        },
        navigateToSoftwareImage(swImg) {
            var readonly = 1
            this.$router.push( { name: "SW_IMAGES_EDIT", params: { id: swImg.deviceSoftwareImageId , readonly: readonly}})
        },
        navigateToUser(id){
            var readonly = 1
            this.$router.push( { name: "USERS_EDIT", params : { id: id, readonly: readonly }})
        },
        getDeviceSWUpdateJobsList(){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            if ( this.filterValueSearch ) query_params.searchString= "any:*:" + this.filterValueSearch
            query_params.jobType = this.UtilsModaConstants.ModaJobType.DEVICE_SW_UPDATE_JOB
            query_params.pageNum = this.pagingOptions.page;
            query_params.pageSize = this.pagingOptions.itemsPerPage;
            var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, {scheduledDateString:'jobScheduleInfo.SCHEDULED.runAt'} )
            if ( sortCriteria ) query_params.sortCriteria = sortCriteria

            // ApiService.get("/mcpsvc/api/v1/deviceSWUpdateJob", (err, result) => 
            ApiService.get("/jobsvc/api/v1/jobs/page",
                (err, result) => {
                this.isLoading=false
                //console.log("getDeviceSWUpdateJobsList " + JSON.stringify(result.data));
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.deviceSWUpdateJobs_table_rows=result.data.data.docs
                    this.utilsTotalEntriesAllPages=result.data.data.totalEntries
                    //this.deviceSWUpdateJobRuns_table_rows=[];
/*
                    if(this.deviceSWUpdateJobs_table_rows.length > 0) {
                        if(!this.deviceSWUpdateJobs_table_rows[0].jobId) {
                            this.deviceSWUpdateJobs_table_rows.shift(); // TODO: temp fix for bad entry in db
                        }
                    }
*/
                    this.buildFilterableColumns(this.deviceSWUpdateJobs_table_rows);
                    //console.log("getDeviceSWUpdateJobsList " + JSON.stringify(this.deviceSWUpdateJobs_table_rows));
                    //console.log("getDeviceSWUpdateJobsList " + JSON.stringify(this.utilsTotalEntriesAllPages));
                }

            }, query_params, "JOBS_SERVICE_URL")
        },

        editDeviceSWUpdateJobsDetails(id, readonly){
            this.$router.push( { name: "DEVICE_SWUPDATE_JOBS_EDIT", params : { deviceSWUpdateJobId: id, readonly: readonly}})
        },
        cancelDeviceSWUpdateJobsDetails(Id, name){
			if(confirm("Do you really want to cancel - '" + name + "' ?")) {
                this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

                //ApiService.put("/mcpsvc/api/v1/deviceSWUpdateJob/cancel/"+Id, 
                ApiService.put("/jobsvc/api/v1/jobs/cancel/"+Id,
                    {}, (err, result) => {
                    //console.log("**"+JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getDeviceSWUpdateJobsList();
                    }
                }, query_params, "JOBS_SERVICE_URL")
            }
        },
        deleteDeviceSWUpdateJobsDetails(Id, name){
			this.deleteDialog = true;
			this.currId = Id;
			this.currName = name;
		},
		deleteObjectChildEvent(cancelFlag, isBackupDel) 
		{
			console.log("** delete " + isBackupDel);
			this.deleteDialog = false;
			if(cancelFlag == true) return;

            //if(confirm("Do you really want to delete - '" + name + "' ?"))
				{
                this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
                query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

                //ApiService.delete("/mcpsvc/api/v1/deviceSWUpdateJob/"+this.currId, 
                ApiService.delete("/jobsvc/api/v1/jobs/"+this.currId, 
                    (err, result) => {
                    //console.log("**"+JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getDeviceSWUpdateJobsList();
                        this.getDeviceSWUpdateJobRunsList(); // selected job may have been deleted, so refresh job runs as well
                    }
                }, query_params, "JOBS_SERVICE_URL")
            }
        },
        createDeviceSWUpdateJobs(){
            this.$router.push( { name: "DEVICE_SWUPDATE_JOBS_CREATE"})
        },
        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.utilsDataRefreshFunc = this.getDeviceSWUpdateJobsList;
            //this.getDeviceSWUpdateJobsList();
            this.getDeviceSWUpdateJobsList();
        },



        // Job Runs


        jobInfoSelected(rows){
            //console.log("Jobs selected " + JSON.stringify(rows));

            this.jobsSelected = rows.map((row) => {
                return row.jobId;
            })
            if ( this.jobsSelected.length ) this.getDeviceSWUpdateJobRunsList()
            else this.deviceSWUpdateJobRuns_table_rows = [];

        }, 

        getDeviceSWUpdateJobRunsList()  {
            if ( ! this.jobsSelected.length ) {
                this.jobRunsTotalEntriesAllPages=0
                this.deviceSWUpdateJobRuns_table_rows=[];
                return
            }

            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            if ( this.filterValueJobRuns ) query_params.searchString= "any:*:" + this.filterValueJobRuns
            query_params.pageNum = this.jobRunsPagingOptions.page;
            query_params.pageSize = this.jobRunsPagingOptions.itemsPerPage;
            var sortCriteria = this.utilsMakeSortCriteria(this.jobRunsPagingOptions, {swImagesDeployed: 'deviceSoftwareImages',
                                                                                      jobRunAtDateString: 'jobRunAt'} )
            if ( sortCriteria ) query_params.sortCriteria = sortCriteria
            var body = {
                jobIds : this.jobsSelected,
            };

            //ApiService.post("/mcpsvc/api/v1/deviceSWUpdateJob/detail", 
            ApiService.post("/jobsvc/api/v1/jobruns/job/page",
                body, (err, result) => {
                this.isLoading=false
                //console.log("getDeviceSWUpdateJobRunsList " + JSON.stringify(result.data));
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    var temp = result.data.data.docs
                    this.jobRunsTotalEntriesAllPages=result.data.data.totalEntries
                    //this.buildFilterableColumns(this.deviceSWUpdateJobRuns_table_rows);
                    temp.forEach((obj) => {
                        obj.jobRunAtDateString = this.utilsFormatDateTime(obj.jobRunAt);
                        obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                        //obj.jobRunStatus= this.utilsFormatDateTime(obj.jobRunStatus);
                        if((obj.jobInputData.cpList) && (obj.jobInputData.cpList.length > 0)) {
                            obj.jobInputData.cpList.forEach((cp) => {
                                    cp.configProfileName = cp.configProfileId;
                            });
                            obj.deployedProfiles = obj.jobInputData;
                            //console.log("getDeviceSWUpdateJobRunsList " + JSON.stringify(obj.deployedProfiles));
                            this.getConfigProfileDisplayNames(obj); 
                        } else {
                            obj.deployedProfiles = [];
                        }
                    }, this);
                    //console.log("getDeviceSWUpdateJobRunsList " + JSON.stringify(temp));
                    this.deviceSWUpdateJobRuns_table_rows=temp;
                }

            }, query_params, "JOBS_SERVICE_URL")
        },
        filterChangedJobRuns () {
            //console.log("filterChangedJobRuns 1 ", this.filterValueJobRuns);
            if (this.utilsAwaitingJobRunsSearchTimer) {
                clearTimeout(this.utilsAwaitingJobRunsSearchTimer);
                this.utilsAwaitingJobRunsSearchTimer = null;
            }
            this.utilsAwaitingJobRunsSearchTimer = setTimeout(() => {
                    //console.log("filterChangedJobRuns 2 ", this.filterValueJobRuns);
                    this.utilsAwaitingJobRunsSearchTimer = false;
                    if ( this.utilsJobRunDataRefreshFunc ) this.utilsJobRunDataRefreshFunc();
                }, 500); // 1 sec delay
        },

    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.get_auth_details();
        this.utilsDataRefreshFunc = this.getDeviceSWUpdateJobsList;
        this.utilsJobRunDataRefreshFunc = this.getDeviceSWUpdateJobRunsList;
        //this.getDeviceSWUpdateJobsList();
        //this.getDeviceSWUpdateJobsStatusList();
    }
}
</script>
