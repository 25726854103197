<template>
	<div style="height:0;">
		<v-icon class="menu-toggle" button @click.stop="drawer = !drawer">{{ drawer ? 'mdi-menu-open' : 'mdi-menu' }}
		</v-icon>
		<v-navigation-drawer fixed app clipped v-model="drawer">
			<sidebar-nav-list :menu="menu" v-for="menu in menus" :key="menu.path">
			</sidebar-nav-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
import Menu from '@/config/Menu'
import SidebarNavList from '@/components/templates/SidebarNavList'

export default {
	name: 'Sidebar',
	components: {
		SidebarNavList
	},
	data() {
		return {
			drawer: true,
			menus: Menu || [],
		}
	},
	directives: {

	},
	methods: {

	}
}
</script>
