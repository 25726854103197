<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />

    <v-card :elevation="5" class="tile-box">

      <v-overlay v-model="isLoading">
            <v-progress-circular size=100 indeterminate color="primary"> </v-progress-circular>
      </v-overlay>

      <v-card-title class="page-title">
        <v-icon>mdi-google-circles-group</v-icon> {{cwProfile.extSysCWProfileName}}
      </v-card-title>
      <v-card-text class="pb-0">
        <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
          {{ apiStatus }}
        </v-alert>
        <v-form ref="cwProfileForm" class="px-5">

          <v-row dense>
            <v-col cols="5" md="5">
                <v-text-field v-model="cwProfile.extSysCWProfileName" label="Name" :disabled="readonly" @input="dirty=true" />
            </v-col>
            <v-col cols="1" md="1"></v-col>
            <v-col cols="5" md="5">
                <v-switch v-model="utilStatus1" :true-value="UtilsModaConstants.AdminStatus.ENABLED"
                          :false-value="UtilsModaConstants.AdminStatus.DISABLED"
                          :label="utilsStatusLabelString(null, utilStatus1)" outlined inset :disabled="readonly"
                          @change="dirty=true"></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <!-- cwa -->
            <v-col cols="5" md="5">
              <v-text-field v-model="cwProfile.cwa.clientId" label="Automate ClientID" :hint="cwProfileHints.cwa.clientId"
                                                :disabled="readonly" @input="dirty=true" />
              <v-text-field v-model="cwProfile.cwa.serverUrl" label="Automate ServerURL" :hint="cwProfileHints.cwa.serverUrl"
                                                :disabled="readonly" @input="dirty=true" />
              <v-text-field v-model="cwProfile.cwa.user" label="Automate User" :hint="cwProfileHints.cwa.user"
                                                :disabled="readonly" @input="dirty=true" />
              <!--
                @input="$v.cwProfile.cwa.password.$touch()" 
                                @blur="$v.cwProfile.cwa.password.$touch()"
              -->
              <v-text-field v-model="cwProfile.cwa.password" label="Automate Password" :hint="cwProfileHints.cwa.password"
                                                :disabled="readonly" @input="dirty=true" 
                :append-icon="cwaPasswordShow ? 'mdi-eye' : 'mdi-eye-off'" 
                :type="cwaPasswordShow ? 'text' : 'password'" 
                @click:append="cwaPasswordShow = !cwaPasswordShow" />
            </v-col>
            <!-- dummy column -->
            <v-col cols="1" md="1"></v-col>
            <!-- cwm -->
            <v-col cols="5" md="5">
              <v-text-field v-model="cwProfile.cwm.clientId" label="Manage ClientID" :hint="cwProfileHints.cwm.clientId"
                                                :disabled="readonly" @input="dirty=true" />
              <v-text-field v-model="cwProfile.cwm.companyId" label="Manage CompanyID" :hint="cwProfileHints.cwm.companyId"
                                                :disabled="readonly" @input="dirty=true" />
              <v-text-field v-model="cwProfile.cwm.companyUrl" label="Manage CompanyURL" :hint="cwProfileHints.cwm.companyUrl"
                                                :disabled="readonly" @input="dirty=true" />
              <v-text-field v-model="cwProfile.cwm.publicKey" label="Manage PublicKey" :hint="cwProfileHints.cwm.publicKey"
                                                :disabled="readonly" @input="dirty=true" />
              <v-text-field v-model="cwProfile.cwm.privateKey" label="Manage PrivateKey" :hint="cwProfileHints.cwm.privateKey"
                                                :disabled="readonly" @input="dirty=true"
                :append-icon="cwmPrivateKeyShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="cwmPrivateKeyShow ? 'text' : 'password'"
                @click:append="cwmPrivateKeyShow = !cwmPrivateKeyShow" />
              <v-text-field v-model="cwProfile.cwm.serviceBoard" label="Manage ServiceBoard" :hint="cwProfileHints.cwm.serviceBoard"
                                                :disabled="readonly" @input="dirty=true" />
              <v-text-field v-model="cwProfile.cwm.serviceTeam" label="Manage ServiceTeam" :hint="cwProfileHints.cwm.serviceTeam"
                                                :disabled="readonly" @input="dirty=true" />
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="justify-end">
        <span v-if="!readonly">
        <v-btn color="primary" rounded outlined @click="syncWithConnectWise" >Sync Now</v-btn>
            <v-btn v-if="isNewProfile()" color="success" rounded outlined @click="createCWProfile" :disabled="!dirty">Create</v-btn>
            <v-btn v-else color="success" rounded outlined @click="updateCWProfile" :disabled="!dirty">Update</v-btn>
            <v-btn v-if="!isNewProfile()" color="red" rounded outlined @click="deleteCWProfile">Delete</v-btn>
        </span>
      </v-card-actions>
    </v-card>

    <GeneralConfirmDialog v-if="syncDialog" @GeneralConfirmDialogCallback='syncDialogEventCallback' :generalDialogMessage="syncMessage" />
    <DeleteDialog v-if="deleteDialog" :isBackupBeforeDelete="false" @DeleteDialogCallback='deleteDialogEventCallback'
            :delMessage="deleteMessage" />
    <GeneralDialog v-if="generalDialog" @GeneralDialogCallback='generalDialogEventCallback' :generalDialogMessage="generalDialogMessage" />

  </div>
</template>

<script>
import ExtSysService from "@/services/extsys.service";
import auth from "@/services/auth.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import Utils from "@/pages/moda/Utils.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import GeneralDialog from "@/components/dialogs/GeneralDialog.vue";
import GeneralConfirmDialog from "@/components/dialogs/GeneralConfirmDialog.vue";


export default {
  components: {
    Breadcrumb,
    DeleteDialog,
    GeneralDialog,
    GeneralConfirmDialog,
  },
  mixins: [Utils],
  data() {
    return {
      currOrgId: null,

      cwProfile : {
        orgId : this.currOrgId,
        extSysCWProfileId: "<NEW>",
        extSysCWProfileName: "<NEW>",
        adminStatus: "Enabled",
        cwa: {
          clientId         : "",
          serverUrl        : "",
          user             : "",
          password         : ""
        },
        cwm: {
          clientId         : "",
          companyId        : "",
          companyUrl       : "",
          publicKey        : "",
          privateKey       : "",
          serviceBoard     : "",
          serviceTeam      : "",
        },
      },

      cwProfileHints : {
        cwa: {
          clientId         : "42adad-5353-35ab-0342-edabc24213141",
          serverUrl        : "your-automate-url.connectwise.com",
          user             : "admin",
          password         : "password"
        },
        cwm: {
          clientId         : "42adad-5353-35ab-0342-edabc24213141",
          companyId        : "yourcompany_f",
          companyUrl       : "your-manage-url.connectwise.com",
          publicKey        : "KLJKLFRQEREFGW",
          privateKey       : "HALFAKFEZZASAA",
          serviceBoard     : "Professional Services",
          serviceTeam      : "Service",
        }
      },

      cwaPasswordShow : false,
      cwmPrivateKeyShow : false,

      dirty: false,
      readonly: false,

      isLoading: false,

      userdetails: {},
      isShow: "display-none",
      parent_pages: [
        { name: "Home" }, 
        { name: "Admin Tasks"}, 
        { name: "ConnectWise Profile", active: true }],
      apiStatus: null,

      syncMessage:null,
      syncDialog:false,

      deleteMessage:null,
      deleteDialog:false,

      generalDialogMessage: null,
      generalDialog: false,
    };
  },
  watch: {},
  methods: {
    isNewProfile(){
        return this.cwProfile.extSysCWProfileId == "<NEW>"
    },

    //--- return default CW Profile -------------------------------------------
    defaultCWProfile() {
      var newCwProfile = {
        orgId : this.currOrgId,
        extSysCWProfileId: "<NEW>",
        extSysCWProfileName: "ConnectWise Profile - " + this.currOrgName,
        adminStatus: this.UtilsModaConstants.AdminStatus.ENABLED,
        cwa: {
          clientId         : "",
          serverUrl        : "",
          user             : "",
          password         : ""
        },
        cwm: {
          clientId         : "",
          companyId        : "",
          companyUrl       : "",
          publicKey        : "",
          privateKey       : "",
          serviceBoard     : "Professional Services",
          serviceTeam      : "Service",
        }
      };
      return newCwProfile;
    },

    syncWithConnectWise() {
        this.syncMessage = "Sync will take a long time. Do you really want to sync?"
        this.syncDialog = true;
    },

    syncDialogEventCallback(cancelFlag, isHard) {
        this.syncDialog = false;
        if(cancelFlag === true) return;

        this.isLoading = true;
        let query_params = {};
        if (this.currOrgId) { 
          query_params.targetOrgId = this.currOrgId;
        }

        ExtSysService.syncWithConnectWiseNow(query_params).then((result) => {
            this.isLoading = false;
            this.dirty = false;
            this.apiStatus = null;

            this.generalDialogMessage = "Synced with ConnectWise successfully"
            this.generalDialog = true;

        }).catch((err) => {
            console.log("\n### ERROR (syncDialogEventCallback) err = " + JSON.stringify(err));

            this.isLoading = false;
            let resp = err.response
            this.apiStatus = (resp && resp.data && resp.data.message && resp.data.message.message) ? resp.data.message.message : "Sync failed, try again";
            this.utilsCheckLogout(this.apiStatus);
        });
    },

    //--- Create the profile --------------------------------------------------
    createCWProfile() {
        this.isLoading = true;
        let query_params = {};
        if (this.currOrgId) { 
          query_params.targetOrgId = this.currOrgId;
        }

        this.cwProfile.adminStatus = this.utilStatus1;

        ExtSysService.createConnectWiseProfile(this.cwProfile, query_params).then((result) => {
            this.isLoading = false;
            this.dirty = false;
            this.apiStatus = null;

            if (result.data) {
                //DEBUG: console.log("\n~~~> data (create) = " + JSON.stringify(result.data));
                this.cwProfile = result.data.data;
            }

            this.generalDialogMessage = "ConnectWise Profile Created successfully"
            this.generalDialog = true;

        }).catch((err) => {
            console.log("\n### ERROR (createConnetWiseProfile) err = " + JSON.stringify(err));

            this.isLoading = false;
            let resp = err.response
            this.apiStatus = (resp && resp.data && resp.data.message && resp.data.message.message) ? resp.data.message.message : "Create failed, try again";
            this.utilsCheckLogout(this.apiStatus);
        });
    },

    updateCWProfile() {
        this.isLoading = true;
        let query_params = {};
        if (this.currOrgId) { 
          query_params.targetOrgId = this.currOrgId;
        }

        this.cwProfile.adminStatus = this.utilStatus1;
        //console.log("\n~~~> cwProfile (update) = " + JSON.stringify(this.cwProfile));

        ExtSysService.updateConnectWiseProfile(this.cwProfile.extSysCWProfileId, this.cwProfile, query_params).then((result) => {
            this.isLoading = false;
            this.dirty = false;
            this.apiStatus = null;

            if (result.data) {
              //DEBUG: console.log("\n~~~> data (update) = " + JSON.stringify(result.data));
            }

            this.generalDialogMessage = "ConnectWise Profile Updated successfully"
            this.generalDialog = true;

        }).catch((err) => {
            console.log("\n### ERROR (updateConnetWiseProfile) err = " + JSON.stringify(err));
            this.isLoading = false;

            let resp = err.response
            this.apiStatus = (resp && resp.data && resp.data.message && resp.data.message.message) ? resp.data.message.message : "Update failed, try again";
            this.utilsCheckLogout(this.apiStatus);
        });
    },

    deleteCWProfile() {
        this.deleteMessage = "Do you really want to delete CW profile ?"
        this.deleteDialog = true;
    },

    deleteDialogEventCallback(cancelFlag, isHard)
    {
        this.deleteDialog = false;
        if(cancelFlag === true) return;

        this.isLoading = true;
        let query_params = {};
        if (this.currOrgId) {
          query_params.targetOrgId = this.currOrgId;
        }
        query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE

        ExtSysService.deleteConnectWiseProfile(this.cwProfile.extSysCWProfileId, query_params).then((result) => {
            this.isLoading = false;
            this.dirty = false;
            this.apiStatus = null;

            if (result.data) {
              //DEBUG: console.log("\n~~~> data (delete) = " + JSON.stringify(result.data));
            }

            this.generalDialogMessage = "ConnectWise Profile Deleted successfully"
            this.generalDialog = true;
            this.currentOp = "DELETE";

        }).catch((err) => {
            console.log("\n### ERROR (deleteConnetWiseProfile) err = " + JSON.stringify(err));

            this.isLoading = false;
            let resp = err.response
            this.apiStatus = (resp && resp.data && resp.data.message && resp.data.message.message) ? resp.data.message.message : "Delete failed, try again";
            this.utilsCheckLogout(this.apiStatus);
        });
    },

    //--- get ConnectionProfile associated with the current (selected) Org --------------------
    getCWProfile() {
      this.isLoading = true;
      let query_params = {};
      if (this.currOrgId) {
        query_params.targetOrgId = this.currOrgId;
      }

      ExtSysService.getConnectWiseProfile({}, query_params).then((result) => {
        this.isLoading = false;

        if (result.data) {
          this.apiStatus = null;

          if (result.data.data.length > 0) {
            this.cwProfile = result.data.data[0];
            this.utilStatus1 = this.cwProfile.adminStatus;
          } else {
            this.cwProfile = this.defaultCWProfile();
            this.dirty = true;
          }
          //DEBUG: console.log("\n~~~> data (get) = " + JSON.stringify(this.cwProfile));
       }

       }).catch((err) => {
          console.log("\n### ERROR (getConnetWiseProfile) err = " + JSON.stringify(err));

          this.isLoading = false;
          this.apiStatus = err.response.data || "Request failed";
          this.utilsCheckLogout(this.apiStatus);
       });
    },

    generalDialogEventCallback() {
        this.generalDialog = false;
        if ( this.currentOp == "DELETE" ){
            this.$router.push({ name: "DASHBOARD_SUMMARY_STATUS"})
        }
    },

    get_auth_details() {
      let title = this.$route.meta.title;
      this.isShow = auth.AuthService.get_usr_auth_details(title);
    },
    processBreadcrumbMessage(selectedOrgId, selectedOrgName) {
      this.currOrgId = selectedOrgId;
      this.currOrgName = selectedOrgName
      this.getCWProfile();
    },
  },

  mounted() {
    this.readonly = false
    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    this.currOrgName = this.$refs.breadcrumb.getLastBreadcrumbOrgName();
    this.get_auth_details();
    this.utilsDataRefreshFunc = this.getCWProfile;
    this.getCWProfile();
  },
};
</script>
