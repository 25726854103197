<template>
	<div>
		<v-dialog v-model="dialog" eager max-width="500" @keydown.esc="cancelDialogToDelete" @click:outside="cancelDialogToDelete">
			<v-card class="confirmation-dialog">
				<v-card-title>
					<v-icon color="error">mdi-alert</v-icon> Confirm
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<strong>
						<p class="mt-5" v-html="deleteDisplayMsg"></p>
					</strong>
					<v-text-field label="Enter 'Confirm' delete" hide-details v-model="confirmTextEntered"
						v-if="confirmTextEnable" required>
						Enter Confirm
					</v-text-field>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="justify-end">
					<v-btn rounded outlined color="error" :disabled="!delBtnEnable && confirmTextEnable"
						@click="deleteObjectDetails_2">
						{{delButtonName2}}
					</v-btn>
					<v-btn rounded outlined v-if="isBackupBeforeDelete" color="error"
						:disabled="!delBtnEnable && confirmTextEnable" @click="deleteObjectDetails_1">
						{{delButtonName1}}
					</v-btn>
					<v-btn rounded outlined color="info" @click="cancelDialogToDelete">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'DeleteDialog',

	data() {
		return {
			confirmTextEntered: ''
		}
	},
	props: {
		isBackupBeforeDelete: {
			type: Boolean,
			required: false,
			default: false,
		},
		delMessage: {
			type: String,
			required: false,
			default: null,
		},
		currentObjectName: {
			type: String,
			required: false,
			default: null,
		},
		isHardDelete: {
			type: Boolean,
			required: false,
			default: false,
		},
		dialog: {
			type: Boolean,
			required: false,
			default: true,
		},
		confirmText: {
			type: String,
			required: false,
			default: '',
		},
		confirmTextEnable: {
			type: Boolean,
			required: false,
			default: true,
		},
		delBtnEnableInput: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	watch: {
		confirmText(value) {
			if (this.confirmText.toLowerCase() === 'confirm')
				this.delBtnEnable = true;
			else
				this.delBtnEnable = false;
		},
	},
	computed: {
		deleteDisplayMsg: function () {
			if (this.delMessage)
				return this.delMessage;
			else
				return 'Do you really want to delete: ' + this.currentObjectName + ' ?';
		},
		delButtonName1: function () {
			return 'Backup & Delete';
		},
		delButtonName2: function () {
			return 'Delete';
		},
		confirmText123: function () {
			return 'Confirm';
		},
		delBtnEnable: function () {
			return this.delBtnEnableInput || ((this.confirmTextEntered === 'Confirm') ? true : false);
		},
	},
	methods: {
		cancelDialogToDelete: function () {
			this.$emit('DeleteDialogCallback', true, false);
		},
		deleteObjectDetails: function (isBackUpDel) {
			this.$emit('DeleteDialogCallback', false, isBackUpDel);
			console.log("**" + 'Delete:' + isBackUpDel);
		},
		deleteObjectDetails_1: function () {
			this.deleteObjectDetails(true);
		},
		deleteObjectDetails_2: function () {
			this.deleteObjectDetails(false);
		}
	}
}
</script>
