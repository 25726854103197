<template>
  <v-container class="login-wrapper mt-5 pt-10">
    <div class="login-cover-placeholder">
      <div class="area">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
    <div class="mx-auto logo mt-6 mb-5">
      <div class="moda-logo"></div> MODA Technologies
    </div>
    <v-card elevation="10" class="mx-auto login-box" max-width="400">
      <v-card-title>
        <div class="mx-auto">Password Reset</div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-alert v-if="errMsg" :border="'top'" :color="errColor" dark>
              {{ errMsg }}
            </v-alert>
            <form v-on:submit="submitForm" method="POST" class="margin-bottom-0">
              <v-text-field v-model="email" v-show="!success" outlined label="Email Address"
                prepend-inner-icon="mdi-account" @input="errMsg = null" />
              <v-btn block rounded v-show="!success" type="submit" class="float-right" color="primary"
                :loading="isLoading" :disabled="!isValidEmail" large>
                Send Reset E-mail
              </v-btn>
            </form>
          </v-col>
          <v-col>
            <div class="text-center"><a @click="$router.push('/login')" class="py-5">Go back to login</a></div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import UserApiService from "@/services/user-api.service";
import { Regex } from '@/utils/RegEx';

export default {
  components: {
  },
  async mounted() {
    //console.log("this.$route.query ", this.$route.query)
    this.email = this.$route.query.email
  },
  data() {
    return {
      email: "",
      password: "",
      password_show: false,
      isLoading: false,
      errMsg: null,
      errColor: "red lighten-2",
      success: false
    };
  },
  computed: {
    isValidEmail() {
      return Regex.email.test(this.email);
    }
  },
  methods: {
    submitForm: function (e) {

      e.preventDefault();

      var login_details = {
        email: this.email,
      };

      this.isLoading = true;
      UserApiService.post("/usersvc/api/v1/users/password/forgot", login_details, (err, result) => {
        this.isLoading = false;
        if (err) {
          if (result && result.data && result.data.message && result.data.message.message)
            this.errMsg = result.data.message.message
          else
            this.errMsg = "Forgot password failed"
          this.errColor = "red lighten-2"
        } else {
          this.errMsg = "Check your email for password reset link"
          this.errColor = "green lighten-2"
          this.success = true;
        }
      });
    },
  },
};
</script>
