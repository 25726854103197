import axios from 'axios'
import {globalAPIUrlStore} from '../main.js'
let auth_token = "";  
var headers = {} 

var urlPrefix = /^https?:\/\//i
var ModaConstants = null;

const ApiService = {

/*
    // promise version
    ModaConstants : null,
    getModaConstants() {
        if ( this.ModaConstants ) return this.ModaConstants;
        var url = globalAPIUrlStore['API_SERVICE_URL'] + '/api/v1/modaConstants';
        // console.log('**'+JSON.stringify(query_params))
        console.log('getModaConstants '+ url );
        return axios.get(url,{withCredentials:true}).then(result  => {
            this.ModaConstants = result.data.data;
            return this.ModaConstants;
        }).catch(error => {
            console.log(error)
        })
    },
*/

    getModaConstants(callback) {
        if ( ModaConstants ) return callback(null, ModaConstants);
        var url = globalAPIUrlStore['API_SERVICE_URL'] + '/api/v1/modaConstants';
        //console.log('getModaConstants '+ url );
        axios.get(url,{withCredentials:true}).then(result  => {
            //console.log('getModaConstants '+ JSON.stringify(result) );
            ModaConstants = result.data.data;
            callback(null, ModaConstants);
        }).catch(error => {
            callback(error)
        })
    },

    get(url,callback,query_params={}, SERVICE="API_SERVICE_URL") {
        // we are using cookie now. Send MODA_Authorization header also, for running frontend detached
        auth_token = localStorage.getItem('MODA_Authorization')
        if(auth_token){
            headers = {"MODA_Authorization" :auth_token}
        }
        if ( ! urlPrefix.test(url) )
            url = globalAPIUrlStore[SERVICE] + url;

        // console.log('**'+JSON.stringify(query_params))
        //console.log('**'+url + " headers " + JSON.stringify(headers));
        axios.get(url,{headers,params:query_params, withCredentials:true}).then(result  => {
            callback(null, result) 
        }).catch(error => {
            //console.log(error)
            callback(error, error.response)
        })
    },
    post(url, postObj,callback, query_params={}, SERVICE="API_SERVICE_URL", headers={}) {
        // we are using cookie now. Authorization header for running frontend detached
        auth_token = localStorage.getItem('MODA_Authorization')
        if(auth_token){
            headers = {"MODA_Authorization" :auth_token}
        }
        if ( ! urlPrefix.test(url) )
            url = globalAPIUrlStore[SERVICE] + url;

        //console.log("api.service post. sending " + typeof(postObj) + " " + JSON.stringify(postObj))
        axios.post(url,postObj,{headers, params:query_params, withCredentials:true})
        //axios({method:'post', url: url, headers, params:query_params, data: postObj, withCredentials:true})
        .then(result  => {
            callback(null, result)
        })
        .catch(error => {
            //console.log(error)
            callback(error, error.response)
        })
    },

    put(url, postObj,callback, query_params={}, SERVICE="API_SERVICE_URL") {
        // we are using cookie now. Send MODA_Authorization header also, for running frontend detached
        auth_token = localStorage.getItem('MODA_Authorization')
        if(auth_token){
            headers = {"MODA_Authorization" :auth_token}
        }
        if ( ! urlPrefix.test(url) )
            url = globalAPIUrlStore[SERVICE] + url;

        axios.put(url,postObj,{headers, params:query_params, withCredentials:true}).then(result  => {
            callback(null, result) 
        }).catch(error => {
            //console.log(error)
            callback(error, error.response)
        })
    },

    delete(url,callback, query_params={}, SERVICE="API_SERVICE_URL") {
        // we are using cookie now. Send MODA_Authorization header also, for running frontend detached
        auth_token = localStorage.getItem('MODA_Authorization')
        if(auth_token){
            headers = {"MODA_Authorization" :auth_token}
        }
        if ( ! urlPrefix.test(url) )
            url = globalAPIUrlStore[SERVICE] + url;

        axios.delete(url,{headers, params:query_params, withCredentials:true}).then(result  => {
            callback(null, result) 
        }).catch(error => {
            //console.log(error)
            callback(error, error.response)
        })
    },
    getGoogleMapPositionsAPI(location,callback) {
        let token     = globalAPIUrlStore.googleMapAPIKey
        let url = globalAPIUrlStore.googleMapAPIUrl+'address='+location+'&key='+token
        axios.get(url).then(result  => {
            callback(result) 
        }).catch(error => {
            console.log(error)
        })
    },

}

export default ApiService
