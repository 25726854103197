import axios from 'axios'
import { globalAPIUrlStore } from '@/main'

const InventoryService = {

	getMetricsData(data) {
		let headers = this.getHeaders();
		return axios.post(`${globalAPIUrlStore['INVENTORY_SERVICE_URL']}/svcInventory/api/v1/monitoredDevice/metric/history`,
			data,
			{ headers, withCredentials: true })
	},
	getTemplateInfo(data) {
		let headers = this.getHeaders();
		return axios.post(`${globalAPIUrlStore['INVENTORY_SERVICE_URL']}/svcInventory/api/v1/monitoredDevice/templates/info`,
			data,
			{ headers, withCredentials: true })
	},
	getDeviceDetails(device_id, query_params) {
		let headers = this.getHeaders();
		return axios.get(`${globalAPIUrlStore['INVENTORY_SERVICE_URL']}/svcInventory/api/v1/monitoredDevice/${device_id}`,
			{ headers, withCredentials: true, params:query_params })
	},
	getMonitoringTemplates() {
		let headers = this.getHeaders();
		return axios.get(`${globalAPIUrlStore['INVENTORY_SERVICE_URL']}/svcInventory/api/v1/monitoringTemplate/`,
			{ headers, withCredentials: true, params:{} })
	},
	getNetworkMap(data, query_params) {
		const params = new URLSearchParams(query_params).toString();
		let headers = this.getHeaders();
		return axios.post(`${globalAPIUrlStore['INVENTORY_SERVICE_URL']}/svcInventory/api/v1/deviceManager/getNetworkMap?${params}`,
			data,
			{ headers, withCredentials: true })
	},
	getHeaders() {
		return localStorage.getItem('MODA_Authorization') ?
			{ "MODA_Authorization": localStorage.getItem('MODA_Authorization') } : {};
	}
}

export default InventoryService;
