import axios from 'axios'
import { globalAPIUrlStore } from '@/main'

const FaultService = {

	getRuleMap(rule_id, query_params) {
		let headers = this.getHeaders();
		const params = new URLSearchParams(query_params).toString();
		return axios.get(`${globalAPIUrlStore['FAULT_SERVICE_URL']}/svcFault/api/v1/rule/map/${rule_id}`,
			{ headers, withCredentials: true, params:query_params })
	},

	getInvestigationReport(query_params) {
		let headers = this.getHeaders();
		const params = new URLSearchParams(query_params).toString();
		return axios.get(`${globalAPIUrlStore['FAULT_SERVICE_URL']}/svcFault/api/v1/incident/inv/incidentInvReport`,
			{ headers, withCredentials: true, params: query_params })
	},

	getSimilarIncidentsInvestigationReport(query_params) {
		let headers = this.getHeaders();
		const params = new URLSearchParams(query_params).toString();
		return axios.get(`${globalAPIUrlStore['FAULT_SERVICE_URL']}/svcFault/api/v1/incident/inv/similarIncidentsInvReport`,
			{ headers, withCredentials: true, params: query_params })
	},


	getHeaders() {
		return localStorage.getItem('MODA_Authorization') ?
			{ "MODA_Authorization": localStorage.getItem('MODA_Authorization') } : {};
	}
}

export default FaultService;
