<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-poll</v-icon> Network Analytics
			</v-card-title>
			<v-card-text>
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<v-container fluid class="py-0">
					<v-row>
						<v-col>
							<v-select clearable v-model="siteId" dense label="Site" :items="sites" item-value="siteId"
								item-text="siteName"></v-select>
						</v-col>
						<v-col>
							<v-select clearable v-model="deviceId" dense label="Device" :items="devices" item-value="deviceId"
								item-text="deviceName"></v-select>
						</v-col>
						<v-col>
							<v-select clearable v-model="appId" dense label="Application" :items="applications" item-value="appId"
								item-text="appName"></v-select>
						</v-col>
						<v-col>
							<v-btn-toggle class="float-right" mandatory dense v-model="timeWindow" tile color="primary" group>
								<v-btn value="1 h">1h</v-btn>
								<v-btn value="6 h">6h</v-btn>
								<v-btn value="12 h">12h</v-btn>
								<v-btn value="1 d">1d</v-btn>
								<v-btn value="7 d">7d</v-btn>
								<v-btn value="30 d">30d</v-btn>
							</v-btn-toggle>
						</v-col>
						<v-col cols="2" :md="2">
							<v-btn rounded outlined block color="primary" @click="loadNetworkAnalytics()"> Submit </v-btn>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn class="mb-2 float-right" rounded outlined color="error"
								@click="resetZoom()"><v-icon>mdi-magnify-minus-outline</v-icon>Reset zoom</v-btn>
						</v-col>
					</v-row>
					<v-row v-if="ChartData.datasets.length > 2 && showChart">
						<v-col>
							<h3 class="text-center">Network Performance</h3>
							<div style="position: relative;">
								<div class="annotation-tooltip"></div>
								<MixedChart :chartData="ChartData" :chartOptions="ChartOptions" :chartId="'netPerf'"></MixedChart>
							</div>
						</v-col>
					</v-row>
					<v-row  v-if="AppChartData.datasets.length > 2 && showChart">
						<v-col>
							<h3 class="text-center py-5">Application Performance</h3>
							<div style="position: relative;">
							<div class="annotation-tooltip"></div>
								<MixedChart :chartData="AppChartData" :chartOptions="AppChartOptions" :chartId="'appPerf'"></MixedChart>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</div>
</template>

<style>
.annotation-tooltip {
	position: absolute;
	background: #000;
	color: #FFF;
	border-radius: 5px;
	padding: 2px 10px;
	font-size: 12px;
	opacity: 0.7;
	display: none;
}
</style>

<script>
import Breadcrumb from '@/components/templates/Breadcrumb';
import MixedChart from '@/components/chartjs/Mixed.vue'
import DashboardService from '@/services/dashboard.service'
import moment from 'moment';
import Utils from '@/pages/moda/Utils.vue'
import ModaConst from '../../services/ModaConstants.js';

export default {
	components: {
		Breadcrumb,
		MixedChart,
	},
	mixins: [Utils],
	data() {
        /* Offsets of incidents from the top of the charts */
		let severityOffsets = {}
        severityOffsets[ModaConst.FaultService.Severity.CRITICAL]= 10
        severityOffsets[ModaConst.FaultService.Severity.SEVERE]  = 20
        severityOffsets[ModaConst.FaultService.Severity.MAJOR]   = 30
        severityOffsets[ModaConst.FaultService.Severity.MINOR]   = 40
        severityOffsets[ModaConst.FaultService.Severity.INFO]    = 50
        severityOffsets[ModaConst.FaultService.Severity.NOT_SET] = 60
		return {
			currOrgId: null,
			apiStatus: null,
			showChart: false,
			sites: [],
			devices: [],
			applications: [],
			incidents: [],
			xLabels_: [],
			siteId: null,
			deviceId: null,
			appId: null,
			timeWindow: "7 d",
			parent_pages: [{ name: 'Home' }, { name: 'Network Analytics', active: true }],
			ChartOptions: {
				name: "chartA",
				animation: {
					onComplete: function ({ chart }) {
						window.netPerf = chart;
					}
				},
				events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
				spanGaps: true,
				interaction: {
					mode: 'index',
					axis: 'y'
				},
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								return `${label}: ${context.formattedValue} ${window['paramDict'].find((o)=> { return o.name == context.dataset.label }).units}`;
							}
						}
					},
					zoom: {
						pan: {
							enabled: true,
							mode: 'x',
							modifierKey: 'ctrl'
						},
						zoom: {
							drag: {
								enabled: true,
								threshold: 20
							},
							mode: 'x'
						}
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							font: {
								size: 12,
								family: '\'Open Sans\', sans-serif'
							}
						},
						onClick: function (e, legendItem) {
							var index = legendItem.datasetIndex;
							var ci = this.chart;
							var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
							let hiddenSet = ci.data.datasets.filter(function (o, i) {
								return ci.getDatasetMeta(i).hidden === true;
							});
							let numHidden = 0;
							ci.data.datasets.forEach(function (e, i) {
								var meta = ci.getDatasetMeta(i);
								if (i !== index && hiddenSet.length == 0) {
									if (!alreadyHidden) {
										meta.hidden = meta.hidden === null ? !meta.hidden : null;
									} else if (meta.hidden === null) {
										meta.hidden = null;
									}
								} else if (i === index) {
									meta.hidden = hiddenSet.length === 0 ? null : !meta.hidden;
								}
								if ( meta.hidden ) numHidden++
							});
                            // if everything is hidden, open up everything
                            if ( numHidden == ci.data.datasets.length ){
								ci.data.datasets.forEach(function (e, i) {
									var meta = ci.getDatasetMeta(i);
									meta.hidden = !meta.hidden;
								});
                            }
							ci.update();
						}
					},
					annotation: {
						annotations: {}
					}
				},
				scales: {
					x: {
						ticks: {
							font: { size: 10, family: '"Open Sans", sans-serif' },
							autoSkip: true,
							maxRotation: 0,
							minRotation: 0,
							color: "#000",
						}
					},
					y: {
						position: 'left',
						title: {
							font: { size: 15, family: '"Open Sans", sans-serif' },
							display: true,
							text: 'Network speed'
						},
						grid: {
							color: '#f7f7f7'
						},
						ticks: {
							font: { size: 10, family: '"Open Sans", sans-serif' },
							color: "#000",
							autoSkip: true,
							callback : function(value,index,values){
									window.ChartAnnotationYvalue = value;
									return `${value} ${window['paramDict'].find((o)=> { return o.name == "Downlink Speed" }).units}`;
							}
						}
					},
					y1: {
						position: 'right',
						title: {
							font: { size: 15, family: '"Open Sans", sans-serif' },
							display: true,
							text: 'Protocol counts'
						},
						grid: {
							color: '#f7f7f7'
						},
						ticks: {
							font: { size: 10, family: '"Open Sans", sans-serif' },
							color: "#000",
							autoSkip: true
						}
					}
				}
			},
			AppChartOptions: {
				animation: {
					onComplete: function ({ chart }) {
						window.appPerf = chart;
					}
				},
				interaction: {
					mode: 'index',
					axis: 'y'
				},
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					zoom: {
						pan: {
							enabled: true,
							mode: 'x',
							modifierKey: 'ctrl',
						},
						zoom: {
							drag: {
								enabled: true,
								threshold: 20
							},
							mode: 'x'
						},
					},
					legend: {
						display: true,
						onClick: function (e, legendItem) {
							var index = legendItem.datasetIndex;
							var ci = this.chart;
							var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
							let hiddenSet = ci.data.datasets.filter(function (o, i) {
								return ci.getDatasetMeta(i).hidden === true;
							});
							ci.data.datasets.forEach(function (e, i) {
								var meta = ci.getDatasetMeta(i);
								if (i !== index && hiddenSet.length == 0) {
									if (!alreadyHidden) {
										meta.hidden = meta.hidden === null ? !meta.hidden : null;
									} else if (meta.hidden === null) {
										meta.hidden = null;
									}
								} else if (i === index) {
									meta.hidden = hiddenSet.length === 0 ? null : !meta.hidden;
								}
							});
							ci.update();
						},
						position: 'bottom',
						labels: {
							font: {
								size: 12,
								family: '\'Open Sans\', sans-serif'
							}
						}
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								return `${label}: ${context.formattedValue} ${window['paramDict'].find((o)=> { return o.name == label.split(" ")[0] }).units}`;
							}
						}
					},
					annotation: {
						annotations: {}
					}
				},
				scales: {
					x: {
						stacked: true,
						ticks: {
							font: { size: 10, family: '"Open Sans", sans-serif' },
							autoSkip: true,
							maxRotation: 0,
							minRotation: 0,
							color: "#000"
						}
					},
					y: {
						stacked: true,
						position: 'left',
						title: {
							font: { size: 15, family: '"Open Sans", sans-serif' },
							display: true,
							text: 'App delays'
						},
						grid: {
							color: '#f7f7f7'
						},
						ticks: {
							font: { size: 10, family: '"Open Sans", sans-serif' },
							color: "#000",
							autoSkip: true,
							callback : function(value,index,values){
									window.AppChartAnnotationYvalue = value;
									return `${value} ${window['paramDict'].find((o)=> { return o.name == "Latency" }).units}`;
							}
						}
					},
					y1: {
						position: 'right',
						title: {
							font: { size: 15, family: '"Open Sans", sans-serif' },
							display: true,
							text: 'App loss'
						},
						grid: {
							color: '#f7f7f7'
						},
						ticks: {
							font: { size: 10, family: '"Open Sans", sans-serif' },
							color: "#000",
							autoSkip: true,
							callback : function(value,index,values){
								return `${value} ${window['paramDict'].find((o)=> { return o.name == "Loss" }).units}`;
							}
						}
					}
				}
			},
			ChartData: {
				labels: [],
				datasets: [{
					label: "Downlink Speed",
					backgroundColor: "green",
					data: [],
					yAxisID: 'y',
					type: 'line',
					tension: 0.5
				},
				{
					label: "Uplink Speed",
					backgroundColor: "orange",
					data: [],
					yAxisID: 'y',
					type: 'line',
					tension: 0.5
				},
				{
					label: "Proto Reqs",
					backgroundColor: "rgb(0, 150, 136, 0.5)",
					data: [],
					yAxisID: 'y1',
					type: 'bar'
				},
				{
					label: "Proto Rsps",
					backgroundColor: "#2196F3",
					data: [],
					yAxisID: 'y1',
					type: 'bar'
				},
				{
					label: "Proto Err Rsps",
					backgroundColor: "red",
					data: [],
					yAxisID: 'y1',
					type: 'bar'
				}
				]
			},
			AppChartData: {
				labels: [],
				datasets: []
			},
			severityOffsets
		}
	},
	mounted() {
		this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
		this.loadFilters();
		this.loadNetworkAnalytics();
		this.ChartOptions.plugins.zoom.zoom.onZoomComplete = this.onNetworkGraphZoom;
		this.AppChartOptions.plugins.zoom.zoom.onZoomComplete = this.onAppGraphZoom;
	},
	methods: {
		onNetworkGraphZoom({ chart }) {
			window.appPerf.config.options.scales.x.min = chart.scales.x.min;
			window.appPerf.config.options.scales.x.max = chart.scales.x.max;
			window.appPerf.update();
		},
		onAppGraphZoom({ chart }) {
			window.netPerf.config.options.scales.x.min = chart.scales.x.min;
			window.netPerf.config.options.scales.x.max = chart.scales.x.max;
			window.netPerf.update();
		},
		resetZoom() {
			if (window.netPerf) window.netPerf.resetZoom();
			if (window.appPerf) window.appPerf.resetZoom();
			window.netPerf = window.window.appPerf = null;
		},
		loadFilters() {
			this.getSitesList();
			this.getDevicesList();
			this.getApplicationList();
		},
		processBreadcrumbMessage(selectedOrgId) {
			this.currOrgId = selectedOrgId;
			this.loadFilters();
			this.loadNetworkAnalytics();
		},
		closest(num, arr) {
			var mid;
			var lo = 0;
			var hi = arr.length - 1;
			while (hi - lo > 1) {
				mid = Math.floor((lo + hi) / 2);
				if (arr[mid] < num) {
					lo = mid;
				} else {
					hi = mid;
				}
			}
			if (num - arr[lo] <= arr[hi] - num) {
				return arr[lo];
			}
			return arr[hi];
		},
		editIncidentEventDetails(id, readonly) {
			this.$router.push({ name: "INCIDENTS_EDIT", params: { id: id, readonly: readonly } })
		},
		onIncidentAnnotationClick(e) {
			this.editIncidentEventDetails(this.ChartOptions.plugins.annotation.annotations[e.id].incident.incidentId, 1)
		},
		setAnnotationYvalues(chart) {
			for (const key in this[`${chart}Options`].plugins.annotation.annotations) {
				this[`${chart}Options`].plugins.annotation.annotations[key].yValue = window[`${chart}AnnotationYvalue`];
			}
		},
		loadIncidents() {
			let scope = this;
			let annotations = {};
			let body = {
				fromSecs: moment().subtract(Number(this.timeWindow.split(' ')[0]), this.timeWindow.split(' ')[1]).unix(),
				toSecs: moment().unix(),
				intervalMins: 60
			};
			DashboardService.getIncidents(this.currOrgId, body).then((result) => {
				this.incidents = result.data;
				this.incidents.data.forEach((elem, i) => {
					if (elem.highestSeverity && elem.highestSeverityIncidentId) {
						elem.incidents.forEach((inc, idx) => {
							annotations[`point_${i}_${idx}`] = {
								type: 'polygon',
								sides: 5,
								xValue: this.ChartData.labels.indexOf(moment.unix(this.closest(elem.time, this.xLabels_)).format(this.timeWindow.split(' ')[1] == 'h' ? ' h:mmA ' : ' D MMM h:mmA ')),
								yAdjust: this.severityOffsets[elem.highestSeverity],
								incident: inc,
								yScaleID: 'y',
								backgroundColor: this.utilsSeverityColors[inc.severity].colorRgb,
								click: function (e) {
									scope.onIncidentAnnotationClick(e);
								},
								enter(ctx, event) {
									let tooltipTxt = scope.getTooltipContent(elem);
									scope.showTooltip(ctx, event, tooltipTxt);
								},
								leave(ctx, event) {
									document.getElementsByClassName("annotation-tooltip")[0].style.display = "none";
									document.getElementsByClassName("annotation-tooltip")[1].style.display = "none";
								}
							}
						});
					}
				})
				this.ChartOptions.plugins.annotation.annotations = annotations;
				this.AppChartOptions.plugins.annotation.annotations = annotations;
				this.setAnnotationYvalues("Chart");
				setTimeout ( () => {
						this.setAnnotationYvalues("AppChart");
				}, 100)
			}).catch((err) => {
				console.log(err);
				this.apiStatus = err ? err.response.data.message ? err.response.data.message : err.response.data : "Request failed";
        this.utilsCheckLogout(this.apiStatus);
			});
		},
		getTooltipContent(elem) {
			let tooltipTxt = "";
			if(elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.MINOR}).length)
				tooltipTxt += `Minor:${elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.MINOR}).length}<br>`;
			if(elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.MAJOR}).length)
				tooltipTxt += `Major:${elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.MAJOR}).length}<br>`;
			if(elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.SEVERE}).length)
				tooltipTxt += `Severe:${elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.SEVERE}).length}<br>`;
			if(elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.CRITICAL}).length)
				tooltipTxt += `Critical:${elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.CRITICAL}).length}<br>`;
			if(elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.INFO}).length)
				tooltipTxt += `Info:${elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.INFO}).length}<br>`;
			if(elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.NOT_SET}).length)
				tooltipTxt += `NotSet:${elem.incidents.filter((o) => { return o.severity == ModaConst.FaultService.Severity.NOT_SET}).length}<br>`;
			return tooltipTxt;
		},
		showTooltip(ctx, event, tooltipTxt) {
			let elemIdx = event.chart.config.options?.name == "chartA" ? 0 : 1;
			let elem = document.getElementsByClassName("annotation-tooltip")[elemIdx];
			elem.style.left = event.x + "px";
			elem.style.top = event.y + "px";
			elem.innerHTML = tooltipTxt;
			elem.style.display = "block"; 
		},
		loadNetworkAnalytics() {
			this.AppChartData.datasets = [];
			let body = {
				fromSecs: moment().subtract(Number(this.timeWindow.split(' ')[0]), this.timeWindow.split(' ')[1]).unix(),
				toSecs: moment().unix(),
				intervalMins: 60
			};
			if (this.siteId) body.siteId = this.siteId;
			if (this.deviceId) body.agentDeviceId = this.deviceId;
			if (this.appId) body.appId = this.appId;
			let timeFormat = this.timeWindow.split(' ')[1] == 'h' ? ' h:mmA ' : ' D MMM h:mmA ';
			DashboardService.getNetworkAnalytics(this.currOrgId, body).then((result) => {
				let xLabels = [];
				let xLabels_ = [];
				let downloadSpeeds = [];
				let uploadSpeeds = [];
				let protoReqs = [];
				let protoRsps = [];
				let protoErrReqs = [];
				window.paramDict = result.data.data['paramDict'];
				result.data.data["Downlink Speed"].forEach((el) => {
					xLabels.push(moment.unix(el[0]).format(timeFormat));
					xLabels_.push(el[0]);
					downloadSpeeds.push(el[1] || null);
				});
				result.data.data["Uplink Speed"].forEach((el) => {
					uploadSpeeds.push(el[1] || null);
				})
				result.data.data["Proto Reqs"].forEach((el) => {
					protoReqs.push(el[1] || null);
				})
				result.data.data["Proto Rsps"].forEach((el) => {
					protoRsps.push(el[1] || null);
				})
				result.data.data["Proto Err Rsps"].forEach((el) => {
					protoErrReqs.push(el[1] || null);
				})

				for (const [key, value] of Object.entries(result.data.data["Latency"])) {
					this.AppChartData.datasets.push({
						label: `Latency @ ${key}`,
						backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
						data: value.map(function (e) { return e[1] }),
						yAxisID: 'y',
						type: 'bar'
					});
				}
				for (const [key, value] of Object.entries(result.data.data["Loss"])) {
					this.AppChartData.datasets.push({
						label: `Loss @ ${key}`,
						backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
						data: value.map(function (e) { return e[1] }),
						yAxisID: 'y1',
						type: 'line'
					});
				}

				this.ChartData.labels = this.AppChartData.labels = xLabels;
				this.ChartData.datasets[0].data = downloadSpeeds;
				this.ChartData.datasets[1].data = uploadSpeeds;
				this.ChartData.datasets[2].data = protoReqs;
				this.ChartData.datasets[3].data = protoRsps;
				this.ChartData.datasets[4].data = protoErrReqs;
				this.showChart = true;
				this.xLabels_ = xLabels_;
				this.loadIncidents();
			}).catch((err) => {
				this.apiStatus = err ? err.response.data.message ? err.response.data.message : err.response.data : "Request failed";
        this.utilsCheckLogout(this.apiStatus);
			});
		},
		getSitesList() {
			let body = {};
			DashboardService.getSites(this.currOrgId, body).then((result) => {
				this.sites = result.data.data.objs
			}).catch((err) => {
				console.log(err.response);
			});
		},
		getDevicesList() {
			let body = {};
			DashboardService.getDevices(this.currOrgId, body).then((result) => {
				this.devices = result.data.data;
			}).catch((err) => {
				console.log(err.response);
			});
		},
		getApplicationList() {
			let body = {};
			DashboardService.getApplications(this.currOrgId, body).then((result) => {
				this.applications = result.data.data;
			}).catch((err) => {
				console.log(err.response);
			});
		},
	},
}
</script>
