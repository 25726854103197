
<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-account</v-icon> User {{ titleName }}
			</v-card-title>
			<v-card-text class="pb-0">
				<v-container fluid class="pl-5 pr-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<form ref="userform">
						<v-row v-if="isLoading">
							<v-col cols="12">
								<v-progress-linear indeterminate color="primary"></v-progress-linear>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col>
								<strong>Status</strong>
								<p>{{ utilsStatusLabelString(null, utilStatus1) }}</p>
							</v-col>
							<v-col>
								<strong>Email</strong>
								<p>{{ userdetails.email }}</p>
							</v-col>
							<v-col>
								<strong>Authentication Provider</strong>
								<p>{{ authProvider_list[userdetails.authProvider] }}</p>
							</v-col>
							<v-col>
								<strong>First Name</strong>
								<p>{{ userdetails.firstName }}</p>
							</v-col>
							<v-col>
								<strong>Last Name</strong>
								<p>{{ userdetails.lastName }}</p>
							</v-col>
							<v-col>
								<strong>Phone</strong>
								<p>{{ userdetails.phone }}</p>
							</v-col>
						</v-row>

						<!-- Main user form end -->


						<!--
                    <span v-else-if="props.column.field == 'permissions'">
                        <span    v-for="pbac in props.row.pbac" :key="pbac.pbacId" >
                        [
                            <a href="javascript:;" v-on:click="navigateToPolicy(pbac.policy.policyId)" > {{pbac.policy.policyName}} </a>
                            <span v-if="pbac.targetOrg.orgName" > ;;; </span>
                            <a href="javascript:;" v-on:click="navigateToOrg(pbac.targetOrg.orgId)" > {{pbac.targetOrg.orgName}} </a>
                            <span v-if="pbac.orgGroups.length" > ;;; </span>
                            <span    v-for="(orgGroup, index) in pbac.orgGroups" :key="orgGroup.orgGroupId" >
                                <span v-if="index" > , </span>
                                <a href="javascript:;" v-on:click="navigateToOrgGroup(pbac.targetOrg.orgId, orgGroup.orgGroupId)" >
                                                                                                {{orgGroup.orgGroupName}} </a>
                            </span>
                            <span v-if="pbac.siteGroups.length" > ;;; </span>
                            <span    v-for="(siteGroup, index) in pbac.siteGroups" :key="siteGroup.siteGroupId" >
                                <span v-if="index" > , </span>
                                <a href="javascript:;" v-on:click="navigateToSiteGroup(pbac.targetOrg.orgId, siteGroup.siteGroupId)" >
                                                                                                {{siteGroup.siteGroupName}} </a>
                            </span>
                            <span v-if="pbac.sites.length" > ;;; </span>
                            <span    v-for="(site, index) in pbac.sites" :key="site.siteId" >
                                <span v-if="index" > , </span>
                                <a href="javascript:;" v-on:click="navigateToSite(pbac.targetOrg.orgId, site.siteId)" > {{site.siteName}} </a>
                            </span>
                        ]
                        <br>
                        </span>
                    </span>
-->
						<!-- Permissions table begin -->
						<v-data-table :headers="permission_headers" :items="userdetails.pbac" disable-pagination
							hide-default-footer>
							<template v-slot:item.policy="{ item }">
								<a v-if="item.policy" class="link-text" v-on:click="navigateToPolicy(item.policy.policyId)">
									{{item.policy.policyName}} </a>
							</template>

							<template v-slot:item.targetOrg="{ item }">
								<a v-if="item.targetOrg" class="link-text" v-on:click="navigateToOrg(item.targetOrg.orgId)">
									{{item.targetOrg.orgName}} </a>
							</template>
							<!--
                        <template v-slot:item.orgGroups="{ item }">
                            <span    v-for="(orgGroup, index) in item.orgGroups" :key="orgGroup.orgGroupId" >
                                <span v-if="index" > , </span>
                                <a href="javascript:;" v-on:click="navigateToOrgGroup(item.targetOrg.orgId, orgGroup.orgGroupId)" >
                                                                                                {{orgGroup.orgGroupName}} </a>
                            </span>
                        </template>

                        <template v-slot:item.siteGroups="{ item }">
                            <span    v-for="(siteGroup, index) in item.siteGroups" :key="siteGroup.siteGroupId" >
                                <span v-if="index" > , </span>
                                <a href="javascript:;" v-on:click="navigateToSiteGroup(item.targetOrg.orgId, siteGroup.siteGroupId)" >
                                                                                                {{siteGroup.siteGroupName}} </a>
                            </span>
                        </template>
-->

							<template v-slot:item.sites="{ item }">
								<span v-for="(site, index) in item.sites" :key="site.siteId">
									<span v-if="index"> , </span>
									<a class="link-text" v-on:click="navigateToSite(item.targetOrg.orgId, site.siteId)">
										{{site.siteName}}
									</a>
								</span>
							</template>

							<template v-if="!readonly" v-slot:item.actions="{ item }">
								<v-icon class="mr-2" color="warning" @click="editPbac(item)">mdi-pencil</v-icon>
								<v-icon color="error" @click="deletePbac(item)">mdi-delete</v-icon>
							</template>

							<template v-slot:top>

								<v-toolbar flat color="white">

									<v-toolbar-title>Permissions</v-toolbar-title>
									<v-dialog v-model="dialog" max-width="1000px">

										<!-- Uncomment when RBAC fully implemented
                                    <template v-if="!readonly" v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" >New Permission</v-btn>
                                    </template>
Uncomment when RBAC fully implemented -->

										<v-card>
											<v-card-title>
												<span class="headline">{{ formTitle }}</span>
											</v-card-title>

											<v-card-text>
												<v-container>
													<v-row>
														<v-col cols="12" sm="6" md="6">
															<v-autocomplete v-model="editedItem.policy" :items="all_users_policies_list"
																item-text="policyName" item-value="policyId" solo :return-object="true"
																prepend-icon="mdi-filter-variant" class="moda-required" label="Policy" hint="Policy"
																persistent-hint :error-messages="policyErrors"
																@input="$v.editedItem.policy.policyId.$touch()"
																@blur="$v.editedItem.policy.policyId.$touch()" />
														</v-col>
														<!-- Uncomment when RBAC fully implemented
                                                    <v-col cols="12" sm="6" md="6">
                                                        <v-autocomplete v-model="editedItem.targetOrg"  :items="all_org_list"
                                                                    item-text="orgName" item-value="orgId" solo
                                                                    :return-object="true" prepend-icon="mdi-filter-variant"
                                                                    @change="permissionATOChanged"
                                                                    label="Appy To Org (ATO)" hint="ATO" persistent-hint />
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-autocomplete v-model="editedItem.orgGroups" :items="editedItem.ATO_all_org_group_list" chips
                                                                    item-text="orgGroupName" item-value="orgGroupId" solo
                                                                    :return-object="true" clearable multiple prepend-icon="mdi-filter-variant"
                                                                    label="ATO's Org Groups" hint="ATO's Org Groups" persistent-hint
                                                                    no-data-text="ATO not selected or it has no Org groups">
                                                            <template v-slot:selection="{ item, select, selected }">
                                                                <v-chip :input-value="selected" close @click="select"
                                                                                                      @click:close="readonly || remove_org_group(item)">
                                                                    <strong>{{ item.orgGroupName }}</strong>
                                                                </v-chip>
                                                            </template>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-autocomplete v-model="editedItem.siteGroups" :items="editedItem.ATO_all_site_group_list" chips
                                                                    item-text="siteGroupName" item-value="siteGroupId" solo
                                                                    :return-object="true" clearable multiple prepend-icon="mdi-filter-variant"
                                                                    label="ATO's Site Groups" hint="ATO's Site Groups" persistent-hint
                                                                    no-data-text="ATO not selected or it has no Site groups">
                                                            <template v-slot:selection="{ item, select, selected }">
                                                                <v-chip :input-value="selected" close @click="select"
                                                                                                      @click:close="readonly || remove_site_group(item)">
                                                                    <strong>{{ item.siteGroupName }}</strong>
                                                                </v-chip>
                                                            </template>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-autocomplete v-model="editedItem.sites" :items="editedItem.ATO_all_site_list" chips
                                                                    item-text="siteName" item-value="siteId" solo
                                                                    :return-object="true" clearable multiple prepend-icon="mdi-filter-variant"
                                                                    label="ATO's Sites" hint="ATO's Sites" persistent-hint
                                                                    no-data-text="ATO not selected or it has no Sites">
                                                            <template v-slot:selection="{ item, select, selected }">
                                                                <v-chip :input-value="selected" close @click="select"
                                                                                                      @click:close="readonly || remove_site(item)">
                                                                    <strong>{{ item.siteName }}</strong>
                                                                </v-chip>
                                                            </template>
                                                        </v-autocomplete>
                                                    </v-col>

Uncomment when RBAC fully implemented -->
													</v-row>
												</v-container>
											</v-card-text>

											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
												<v-btn color="blue darken-1" text @click="saveDialog" :disabled="!permSaveEnabled">Save</v-btn>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</v-toolbar>
							</template>

						</v-data-table>

						<v-divider class="mx-4" inset></v-divider>

						<!-- Permissions table end -->

					</form>
				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="!readonly && !isEdit" @click="submit"
					:disabled="this.$v.$invalid">Create</v-btn>
<!--
				<v-btn rounded outlined color="success" v-if="!readonly && isEdit" @click="submit" :disabled="this.$v.$invalid">
					Update</v-btn>
				<v-btn rounded outlined color="error" v-if="!readonly" @click="cancel">Cancel</v-btn>
-->
				<v-btn rounded outlined color="success" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>

	</div>
</template>
<script>

import UserApiService from '@/services/user-api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, email } from 'vuelidate/lib/validators'
import Utils from '@/pages/moda/Utils'
import ModaConst from '../../services/ModaConstants.js'

export default {
    components: {
        Breadcrumb,
    },
    data(){
        let authProvider_list = {}
        authProvider_list[ModaConst.AuthProvider.LOCAL] = "Local"
        authProvider_list[ModaConst.AuthProvider.MICROSOFT] = "Microsoft"
        return {
            currOrgId: null,
            isLoading: false,
            fullPage: true,
            onCancel:true,
            height:35,
            width:35,
            usr_id:'',
            titleName:'Create',
            isEdit:false,
            readonly:true,
            isfirstName:false,
            isEmail:false,
            isPassword:false,
            isPass:false,
            disabled:false,
            userdetails:{
                userId: "",
                email: "",
                password: "",
                firstName: "",
                lastName: "",
                phone: "",
                icon: "",
                orgId:"",
                authProvider:ModaConst.AuthProvider.LOCAL,

                // default Read-Write. No ATO, Sites, org/site groups. No UI to select this until RBAC is fully implemented in FE and BE
                // usrpol.1001 is Read-Write policy ID
                pbac : [{policy:{policyId:"usrpol.1001", policyName: "Policy Generic Read-write"},
                         targetOrg:{}, orgGroups:[], siteGroups:[], sites:[]}],
            },
            authProvider_list,
            password_show: false,
            permission_headers: [
                {
                    text: 'Policy',
                    align: 'start',
                    sortable: false,
                    value: 'policy',
                },
/* Uncomment when RBAC fully implemented
                { text: 'Apply To Org (ATO)', value: 'targetOrg' },
                //{ text: "ATO's Org Groups", value: 'orgGroups' },
                //{ text: "ATO's Site Groups", value: 'siteGroups' },
                { text: "ATO's Sites", value: 'sites' },
                { text: 'Actions', value: 'actions', sortable: false },
Uncomment when RBAC fully implemented */
            ],
            permSaveEnabled: false,
            dialog: false,
            editedIndex: -1,
            editedItem: {
                policy: { },
                targetOrg: {},
                orgGroups: [],
                siteGroups: [],
                sites: [],

                ATO_all_org_group_list: [],
                ATO_all_site_group_list: [],
                ATO_all_site_list: [],
            },
            defaultItem: {
                policy: {},
                targetOrg: {},
                orgGroups: [],
                siteGroups: [],
                sites: [],

                ATO_all_site_group_list: [],
                ATO_all_org_group_list: [],
                ATO_all_site_list: [],
            },

            all_users_policies_list:[],
            all_org_list:[],

            login_userdetails:JSON.parse(localStorage.getItem('user-details')),

            policy_row:{
                siteId : '',
                siteGroupId : '',
                policyId : ''
            },
            isProvider:false,
            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Access Control"},
                           {name:'Users', route: { name: "USERS"}},
                           {name:"View", active:true} ],
            apiStatus: null,
        }
    },

    mixins: [validationMixin, Utils],
    validations: {
        userdetails : {
            email: { required, email },
            password: {
                required: requiredIf ( function() { return !this.isEdit }),
                minLength: minLength(3)
            },
            pbac : { 
                $each : {
                    policy: {
                        policyId: { required  },
                    },
                },
            },
        },
        editedItem : {
            policy: {
                policyId: {
                    required: requiredIf( function() { return this.dialog}),
                },
            }
        },
    },
    computed: {
        emailErrors () {
            const errors = []
            if (!this.$v.userdetails.email.$dirty) return errors
            !this.$v.userdetails.email.required && errors.push('Email is required.')
            !this.$v.userdetails.email.email && errors.push('Invalid email')
            return errors
        },
        passwordErrors () {
            const errors = []
            if (!this.$v.userdetails.password.$dirty) return errors
            !this.$v.userdetails.password.required && errors.push('Password is required.')
            !this.$v.userdetails.password.minLength && errors.push('Password must be atleast 3 characters long')
            return errors
        },
        formTitle () {
            return this.editedIndex === -1 ? 'New Permission' : 'Edit Permission'
        },
        policyErrors () {
            const errors = []
            if (!this.$v.editedItem.policy.policyId.$dirty) return errors
            !this.$v.editedItem.policy.policyId.required && errors.push('Policy is required.')
            return errors
        },
        editedPolicyId() {
            return this.editedItem.policy ? this.editedItem.policy.policyId : undefined;
        }
    },

    watch: {
        dialog (val) {
            val || this.closeDialog()
        },

        editedPolicyId(val) {
            if ( ! this.$v.editedItem.invalid && val )
                this.permSaveEnabled = true
            else
                this.permSaveEnabled = false
        }
    },

    methods: {

        editPbac (item) {
            this.editedIndex = this.userdetails.pbac.indexOf(item)
            //this.editedItem = Object.assign({}, item) // Object.assign shallow copies. inner objects still point to source
            this.editedItem = JSON.parse(JSON.stringify(item))

            this.simulatePermissionATOChange(this.editedItem.targetOrg.orgId);

            this.dialog = true
        },

        deletePbac (item) {
            const index = this.userdetails.pbac.indexOf(item)
            confirm('Are you sure you want to delete "' + item.policy.policyName + '"?') && this.userdetails.pbac.splice(index, 1)
        },

        closeDialog () {
            this.dialog = false
            this.$nextTick(() => {
                //this.editedItem = Object.assign({}, this.defaultItem) // Object.assign shallow copies. inner objects still point to source
                this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
                this.editedIndex = -1
            })
        },

        saveDialog () {
            if (this.editedIndex > -1) {
                //Object.assign(this.userdetails.pbac[this.editedIndex], this.editedItem)
                // Object.assign shallow copies. inner objects still point to source
                // but replacing with entirely new object is not updating table. So, update existing object itself.
                var newpbac = JSON.parse(JSON.stringify(this.editedItem))
                Object.assign(this.userdetails.pbac[this.editedIndex], newpbac)
            } else {
                this.userdetails.pbac.push(this.editedItem)
            }
            this.closeDialog()
        },

/*
        formatNames(orgGroups, nameField) {
            var prefix = "";
            var str = ""
            orgGroups.forEach((org)=> {
                str += prefix + org[nameField];
                if ( prefix == "" ) prefix = ", "
            })
            return str;
        },
*/
        submit () {
            this.$v.$touch()

            this.isLoading=true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            //console.log("user crud submit 0 *****"+JSON.stringify(this.userdetails))

            if ( this.userdetails.firstName )
                this.userdetails.firstName  = this.userdetails.firstName.trim();
            if ( this.userdetails.lastName )
                this.userdetails.lastName   = this.userdetails.lastName.trim();
            this.userdetails.email      = this.userdetails.email.trim();
            if ( this.userdetails.password )
                this.userdetails.password= this.userdetails.password.trim();
            if ( this.userdetails.phone )
                this.userdetails.phone      = this.userdetails.phone.trim();

            this.userdetails.adminStatus = this.utilStatus1;

            //console.log("user crud submit 1 *****"+JSON.stringify(this.userdetails))

            // convert policy, targetOrg, orgGroups, siteGroups, sites to their respective ID(s) instead of object(s)
            var pbacs = this.userdetails.pbac;
            pbacs.forEach((pbac) =>  {

                //console.log("pbac " + JSON.stringify(pbac));

                pbac.policyId = pbac.policy.policyId;
                pbac.targetOrgId = pbac.targetOrg.orgId;

                pbac.orgGroupIds = [];
                pbac.siteGroupIds = [];
                pbac.siteIds = [];

                pbac.orgGroups.forEach((obj) =>  { pbac.orgGroupIds.push(obj.orgGroupId); })
                pbac.siteGroups.forEach((obj) =>  { pbac.siteGroupIds.push(obj.siteGroupId); })
                pbac.sites.forEach((obj) =>  { pbac.siteIds.push(obj.siteId); })

                delete pbac.policy
                delete pbac.targetOrg
                delete pbac.orgGroups
                delete pbac.siteGroups
                delete pbac.sites
                delete pbac.ATO_all_org_group_list
                delete pbac.ATO_all_site_group_list
                delete pbac.ATO_all_site_list
            })

            //console.log("user crud submit 2 *****"+JSON.stringify(this.userdetails))

            if ( this.isEdit ){
                UserApiService.put("/usersvc/api/v1/users/"+this.usr_id,this.userdetails, (err, result) => {
                    //console.log("*update*"+JSON.stringify(result))
                    this.isLoading=false;
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateUser()
                    }
                }, query_params)
            }else {// create
                UserApiService.post("/usersvc/api/v1/users",this.userdetails, (err, result) => {
                    //console.log("*create*"+JSON.stringify(err) + " " + JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        //this.apiStatus = null;
                        this.cancelCreateUser()
                    }
                }, query_params)
            }
        },
        cancel () {
            this.$v.$reset()

            this.cancelCreateUser()
        },
        remove_org_group (item) {
            this.editedItem.orgGroups.splice(this.editedItem.orgGroups.findIndex(x => x.orgGroupId == item.orgGroupId), 1)
            this.editedItem.orgGroups = [...this.editedItem.orgGroups]
        },
        remove_site_group (item) {
            this.editedItem.siteGroups.splice(this.editedItem.siteGroups.findIndex(x => x.siteGroupId == item.siteGroupId), 1)
            this.editedItem.siteGroups = [...this.editedItem.siteGroups]
        },
        remove_site (item) {
            this.editedItem.sites.splice(this.editedItem.sites.findIndex(x => x.siteId == item.siteId), 1)
            this.editedItem.sites = [...this.editedItem.sites]
        },


        navigateToPolicy(id){
            var readonly = 1
            this.$router.push( { name : "USER_POLICIES_EDIT", params: { id: id, readonly: readonly }})
        },
        navigateToOrg(id){
            var readonly = 1
            this.$router.push({name:"ORGS_EDIT", params : {id: id, readonly: readonly}})
        },
        navigateToOrgGroup(targetOrgId, id){
            var readonly = 1
            this.$router.push('/config/orgsGroups/edit/'+id+ "/" + readonly + "/" + targetOrgId)
        },
        navigateToSiteGroup(targetOrgId, id){
            var readonly = 1
            this.$router.push( { name : "SITE_GROUPS_EDIT_TARGETORG", params : { siteGroupId: id, redaonly: readonly, targetOrgId: targetOrgId } } )
        },
        navigateToSite(targetOrgId, id){
            var readonly = 1
            this.$router.push({ name: "SITES_EDIT_TARGETORG", params: { siteId: id, readonly: readonly, targetOrgId: targetOrgId } })
        },

        updateSitesGroupsLists(org){
            this.editedItem.ATO_all_site_list = org.sites;
            this.editedItem.ATO_all_site_group_list = org.siteGroups;
            this.editedItem.ATO_all_org_group_list = org.orgGroups;
        },
        permissionATOChanged(org){
            this.editedItem.sites = []
            this.editedItem.siteGroups = []
            this.editedItem.orgGroups = []
            this.updateSitesGroupsLists(org);
        },
        simulatePermissionATOChange(orgId){
            if ( orgId ) {
                var org = this.all_org_list.find(x => { return x.orgId == orgId } )
                if ( org ) this.updateSitesGroupsLists(org)
            }
        },
        cancelCreateUser(){
            this.$router.go(-1);
        },
        getUserDetails(){
            this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            UserApiService.get("/usersvc/api/v1/users/"+this.usr_id, (err, result) => {
                //console.log("getUserDetails " + JSON.stringify(result))
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.userdetails = result.data.data[0];
                    this.utilStatus1 = this.userdetails.adminStatus;
                    //console.log("getUserDetails " + JSON.stringify(this.userdetails))
                    this.titleName = (this.readonly ? "View - " : "Edit - ") +this.userdetails.email
                }
            }, query_params)
        },
        getOrgFull(){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            UserApiService.get("/api/v1/org/full/" + this.currOrgId , (err, result) => {
            //UserApiService.get("/api/v1/org-full", (err, result) => 
                //this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.all_org_list = result.data.data
                    this.all_org_list.unshift({orgId:null, orgName:""})
                    //console.log("getOrgFull " + JSON.stringify(result.data.data));
                }
            }, query_params)
        },
        getUsersPolicyList(){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            UserApiService.get("/usersvc/api/v1/policy", (err, result) => {
                //this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.all_users_policies_list = result.data.data
                }
            }, query_params)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            if(this.usr_id != "" && this.usr_id !=undefined){
                // Edit can't be done when org is changed, so g back
                this.cancelCreateUser();
            }else
                this.getOrgFull();
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();

        this.getUsersPolicyList();
        this.getOrgFull();

        this.usr_id = this.$route.params.id;
        this.readonly = (this.$route.params.readonly == "1");

        if(this.usr_id != "" && this.usr_id !=undefined){
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
            this.getUserDetails();
        }
    }
}

</script>
