
<template>
	<div>
		<v-dialog v-model="dialog" eager max-width="500" @keydown.esc="closeGeneralDialog" @click:outside="closeGeneralDialog">
			<v-card>
				<v-card-title>
					<v-icon>mdi-information</v-icon> Information
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<strong>
						<p class="mt-5" v-html="generalDialogMessage"></p>
					</strong>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="justify-end">
					<v-btn rounded outlined color="info" @click="closeGeneralDialog">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'GeneralDialog',
	data() {
		return {}
	},
	props: {
		generalDialogMessage: {
			type: String,
			required: false,
			default: null,
		},
		dialog: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	methods: {
		closeGeneralDialog: function () {
			this.$emit('GeneralDialogCallback');
		},
	},
}
</script>
