<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-router-wireless-settings</v-icon> MODA Agent Status
			</v-card-title>
			<v-card-text>
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<v-container fluid class="py-0">
					<v-row>
						<v-col>
							<v-select clearable v-model="siteId" dense label="Site" :items="sites" item-value="siteId"
								item-text="siteName"></v-select>
						</v-col>
						<v-col>
							<v-select clearable v-model="deviceId" dense label="Device" :items="devices" item-value="deviceId"
								item-text="deviceName"></v-select>
						</v-col>
<!--
						<v-col>
							<v-select clearable v-model="appId" dense label="Application" :items="applications" item-value="appId"
								item-text="appName"></v-select>
						</v-col>
-->
						<v-col>
							<v-btn-toggle class="float-right" mandatory dense v-model="timeWindow" tile color="primary" group>
								<v-btn value="1 h">1h</v-btn>
								<v-btn value="6 h">6h</v-btn>
								<v-btn value="12 h">12h</v-btn>
								<v-btn value="1 d">1d</v-btn>
								<v-btn value="7 d">7d</v-btn>
								<v-btn value="30 d">30d</v-btn>
							</v-btn-toggle>
						</v-col>
						<v-col cols="2" :md="2">
							<v-btn rounded outlined block color="primary" @click="loadDeviceAnalytics()"> Submit </v-btn>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn class="mb-2 float-right" rounded outlined color="error"
								@click="resetZoom()"><v-icon>mdi-magnify-minus-outline</v-icon>Reset zoom</v-btn>
						</v-col>
					</v-row>
					<v-row v-if="ChartData.datasets.length > 0 && showChart">
						<v-col>
							<h3 class="text-center">CPU &amp; Memory</h3>
							<div style="position: relative;">
								<LineChart :chartData="ChartData" :chartOptions="ChartOptions" :chartId="'cpuMem'"/>
							</div>
						</v-col>
					</v-row>
					<v-row v-if="fileChartData.datasets.length > 0 && showChart">
						<v-col>
							<h3 class="text-center">File System Usage</h3>
							<div style="position: relative;">
								<LineChart :chartData="fileChartData" :chartOptions="fileChartOptions" :chartId="'fileSys'"/>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import Breadcrumb from '@/components/templates/Breadcrumb';
import LineChart from '@/components/chartjs/Line.vue'
import DashboardService from '@/services/dashboard.service'
import moment from 'moment';
import Utils from '@/pages/moda/Utils.vue'

export default {
	components: {
		Breadcrumb,
		LineChart
	},
	mixins: [Utils],
	data() {
		return {
			currOrgId: null,
			apiStatus: null,
			sites: [],
			showChart: false,
			devices: [],
			applications: [],
			xLabels_: [],
			siteId: null,
			deviceId: null,
			//appId: null,
			timeWindow: "7 d",
			parent_pages: [{ name: 'Home' }, { name: 'Moda Agent Status', active: true }],
			ChartOptions: {
				spanGaps: true,
				animation: {
					onComplete: function ({ chart }) {
						window.devPerf = chart;
					}
				},
				interaction: {
					mode: 'index',
					axis: 'x',
					intersect: false
				},
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					zoom: {
						pan: {
							enabled: true,
							mode: 'x',
							modifierKey: 'ctrl'
						},
						zoom: {
							drag: {
								enabled: true,
								threshold: 20
							},
							mode: 'x'
						}
					},
					legend: {
						display: true,
						onClick: function (e, legendItem) {
							var index = legendItem.datasetIndex;
							var ci = this.chart;
							var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
							let hiddenSet = ci.data.datasets.filter(function (o, i) {
								return ci.getDatasetMeta(i).hidden === true;
							});
							ci.data.datasets.forEach(function (e, i) {
								var meta = ci.getDatasetMeta(i);
								if (i !== index && hiddenSet.length == 0) {
									if (!alreadyHidden) {
										meta.hidden = meta.hidden === null ? !meta.hidden : null;
									} else if (meta.hidden === null) {
										meta.hidden = null;
									}
								} else if (i === index) {
									meta.hidden = hiddenSet.length === 0 ? null : !meta.hidden;
								}
							});
							ci.update();
						},
						position: 'bottom',
						labels: {
							font: {
								size: 12,
								family: '\'Open Sans\', sans-serif'
							}
						}
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								return `${label}: ${context.formattedValue} ${window['paramDict'].find((o)=> { return o.name == context.dataset.label }).units}`;
							}
						}
					}
				},
				scales: {
					x: {
						ticks: {
							font: { size: 10, family: '"Open Sans", sans-serif' },
							autoSkip: true,
							maxRotation: 0,
							minRotation: 0,
							color: "#000",
						}
					},
					y: {
						position: 'left',
						title: {
							font: { size: 15, family: '"Open Sans", sans-serif' },
							display: true,
							text: 'CPU Usage'
						},
						grid: {
							color: '#f7f7f7'
						},
						ticks: {
							font: { size: 10, family: '"Open Sans", sans-serif' },
							color: "#000",
							autoSkip: true,
							callback : function(value,index,values){
								return value + window['paramDict'].find((o)=> { return o.name == "CPU Usage" }).units;
							}
						}
					},
					y1: {
						position: 'right',
						title: {
							font: { size: 15, family: '"Open Sans", sans-serif' },
							display: true,
							text: 'Used Mem'
						},
						grid: {
							color: '#f7f7f7'
						},
						ticks: {
							font: { size: 10, family: '"Open Sans", sans-serif' },
							color: "#000",
							autoSkip: true,
							callback : function(value,index,values, g){
								return `${value} ${window['paramDict'].find((o)=> { return o.name == "Used Mem" }).units}`;
							}
						},
						type: 'logarithmic'
					}
				}
			},
			ChartData: {
				labels: [],
				datasets: []
			},
			fileChartData: {
				labels: [],
				datasets: []
			},
			fileChartOptions: {
				animation: {
					onComplete: function ({ chart }) {
						window.filePerf = chart;
					}
				},
				interaction: {
					mode: 'index',
					axis: 'x',
					intersect: false
				},
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					zoom: {
						pan: {
							enabled: true,
							mode: 'x',
							modifierKey: 'ctrl',
						},
						zoom: {
							drag: {
								enabled: true,
								threshold: 20
							},
							mode: 'x'
						},
					},
					legend: {
						display: true,
						onClick: function (e, legendItem) {
							var index = legendItem.datasetIndex;
							var ci = this.chart;
							var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
							let hiddenSet = ci.data.datasets.filter(function (o, i) {
								return ci.getDatasetMeta(i).hidden === true;
							});
							ci.data.datasets.forEach(function (e, i) {
								var meta = ci.getDatasetMeta(i);
								if (i !== index && hiddenSet.length == 0) {
									if (!alreadyHidden) {
										meta.hidden = meta.hidden === null ? !meta.hidden : null;
									} else if (meta.hidden === null) {
										meta.hidden = null;
									}
								} else if (i === index) {
									meta.hidden = hiddenSet.length === 0 ? null : !meta.hidden;
								}
							});
							ci.update();
						},
						position: 'bottom',
						labels: {
							font: {
								size: 12,
								family: '\'Open Sans\', sans-serif'
							}
						}
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								return `${label}: ${context.formattedValue} ${window['paramDict'].find((o)=> { return o.name == "Disk Usage" }).units}`;
							}
						}
					}
				},
				scales: {
					x: {
						ticks: {
							font: { size: 10, family: '"Open Sans", sans-serif' },
							autoSkip: true,
							maxRotation: 0,
							minRotation: 0,
							color: "#000"
						}
					},
					y: {
						position: 'left',
						title: {
							font: { size: 15, family: '"Open Sans", sans-serif' },
							display: true,
							text: 'Usage'
						},
						grid: {
							color: '#f7f7f7'
						},
						ticks: {
							font: { size: 10, family: '"Open Sans", sans-serif' },
							color: "#000",
							autoSkip: true,
							callback : function(value,index,values){
								return `${value} ${window['paramDict'].find((o)=> { return o.name == "Disk Usage" }).units}`;
							}
						},
						type: 'logarithmic'
					}
				}
			}
		}
	},
	mounted() {
		this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
		this.loadFilters();
		this.loadDeviceAnalytics();
		this.ChartOptions.plugins.zoom.zoom.onZoomComplete = this.onDeviceGraphZoom;
		this.fileChartOptions.plugins.zoom.zoom.onZoomComplete = this.onFileGraphZoom;
	},
	methods: {
		onFileGraphZoom({ chart }) {
			if(window.devPerf) {
				window.devPerf.config.options.scales.x.min = chart.scales.x.min;
				window.devPerf.config.options.scales.x.max = chart.scales.x.max;
				window.devPerf.update();
			}
		},
		onDeviceGraphZoom({ chart }) {
			if(window.filePerf) {
				window.filePerf.config.options.scales.x.min = chart.scales.x.min;
				window.filePerf.config.options.scales.x.max = chart.scales.x.max;
				window.filePerf.update();
			}
		},
		resetZoom() {
			if (window.devPerf) window.devPerf.resetZoom();
			if (window.filePerf) window.filePerf.resetZoom();
			window.devPerf = null;
			window.filePerfPerf = null;
		},
		loadFilters() {
			this.getSitesList();
			this.getDevicesList();
			this.getApplicationList();
		},
		processBreadcrumbMessage(selectedOrgId) {
			this.currOrgId = selectedOrgId;
			this.loadFilters();
			this.loadDeviceAnalytics();
		},
		closest(num, arr) {
			var mid;
			var lo = 0;
			var hi = arr.length - 1;
			while (hi - lo > 1) {
				mid = Math.floor((lo + hi) / 2);
				if (arr[mid] < num) {
					lo = mid;
				} else {
					hi = mid;
				}
			}
			if (num - arr[lo] <= arr[hi] - num) {
				return arr[lo];
			}
			return arr[hi];
		},
		loadDeviceAnalytics() {
			this.fileChartData.datasets = [];
			this.ChartData.datasets = [];
			let body = {
				fromSecs: moment().subtract(Number(this.timeWindow.split(' ')[0]), this.timeWindow.split(' ')[1]).unix(),
				toSecs: moment().unix(),
				intervalMins: 60
			};
			if (this.siteId) body.siteId = this.siteId;
			if (this.deviceId) body.agentDeviceId = this.deviceId;
			//if (this.appId) body.appId = this.appId;
			let timeFormat = this.timeWindow.split(' ')[1] == 'h' ? ' h:mmA ' : ' D MMM h:mmA ';
			DashboardService.getDeviceAnalytics(this.currOrgId, body).then((result) => {
				let xLabels = [];
				let xLabels_ = [];
				let cpuUsages = [];
				let totalMems = [];
				let usedMems = [];
				let fileUsages = [];
				let fileMax = [];

				window.paramDict = result.data.data['paramDict'];

				result.data.data["CPU Usage"].forEach((el) => {
					xLabels.push(moment.unix(el[0]).format(timeFormat));
					xLabels_.push(el[0]);
					cpuUsages.push(el[1] || null);
				});
				result.data.data["Used Mem"].forEach((el) => {
					usedMems.push(el[1] || null);
				})
				result.data.data["Total Mem"].forEach((el) => {
					totalMems.push(el[1] || null);
				})
				for (const [key, value] of Object.entries(result.data.data["Disk Usage"])) {
					let colorRGB = this.generateRGB();
					fileUsages.push({
						label: `${key} Used`,
						backgroundColor: `rgba(${colorRGB}, 0.8)`,
						borderColor: `rgba(${colorRGB}, 0.8)`,
						data: value.used.map(function (e) { return e[1] }),
						yAxisID: 'y',
						type: 'line',
						pointRadius: 0,
						borderWidth: 2
					});
					colorRGB = this.generateRGB();
					fileMax.push({
						label: `${key} Max`,
						backgroundColor: `rgba(${colorRGB}, 0.8)`,
						borderColor: `rgba(${colorRGB}, 0.8)`,
						data: value.max.map(function (e) { return e[1] }),
						yAxisID: 'y',
						type: 'line',
						pointRadius: 0,
						borderWidth: 2,
						borderDash: [5,3]
					});
				}

				this.ChartData.labels = this.fileChartData.labels = xLabels;

				let dataSets = [{
					label: "CPU Usage",
					backgroundColor: "rgba(76, 175, 80, 0.1)",
					borderColor: "rgba(76, 175, 80, 0.8)",
					data: [],
					fill: true,
					yAxisID: 'y',
					type: 'line',
					tension: 0.5,
					pointRadius: 0,
					borderWidth: 2
				},
				{
					label: "Used Mem",
					backgroundColor: "rgb(255, 87, 34, 0.1)",
					borderColor: "rgb(255, 87, 34, 0.8)",
					data: [],
					yAxisID: 'y1',
					fill: true,
					type: 'line',
					tension: 0.5,
					pointRadius: 0,
					borderWidth: 2
				},
				{
					label: "Total Mem",
					backgroundColor: "rgba(0, 150, 136, 0.1)",
					borderColor: "rgba(0, 150, 136, 0.8)",
					data: [],
					yAxisID: 'y1',
					fill: true,
					type: 'line',
					pointRadius: 0,
					borderWidth: 2,
					borderDash: [5,3]
				}];

				dataSets[0].data = cpuUsages;
				dataSets[1].data = usedMems;
				dataSets[2].data = totalMems;
				this.ChartData.datasets = dataSets;
				this.fileChartData.datasets = [...fileUsages, ...fileMax];
				this.xLabels_ = xLabels_;
				this.showChart = true;
			}).catch((err) => {
				console.log(err);
				this.apiStatus = err ? err.response.data.message ? err.response.data.message : err.response.data : "Request failed";
				this.utilsCheckLogout(this.apiStatus);
			});
		},
		generateRGB() {
			return `${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}`;
		},
		getSitesList() {
			let body = {};
			DashboardService.getSites(this.currOrgId, body).then((result) => {
				this.sites = result.data.data.objs
			}).catch((err) => {
				console.log(err.response);
			});
		},
		getDevicesList() {
			let body = {};
			DashboardService.getDevices(this.currOrgId, body).then((result) => {
				this.devices = result.data.data;
			}).catch((err) => {
				console.log(err.response);
			});
		},
		getApplicationList() {
			let body = {};
			DashboardService.getApplications(this.currOrgId, body).then((result) => {
				this.applications = result.data.data;
			}).catch((err) => {
				console.log(err.response);
			});
		},
	},
}
</script>
