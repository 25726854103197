<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="2" class="tile-box">
      <ValidationObserver ref="monitoredDeviceform" v-slot="{ invalid }">
        <v-card-title class="page-title">
          <v-icon>mdi-router-wireless</v-icon> {{ titleName }} Device
          <v-badge title="Liveness"
            v-if="isEdit && devicedetails.imageInfo && devicedetails.imageInfo.images && devicedetails.imageInfo.images.main"
            bordered :color="utilsLiveStatusDisplayObject(devicedetails.liveStatus).colorName" offset-x="-10"
            offset-y="10" :content="utilsLiveStatusDisplayObject(devicedetails.liveStatus).text">
          </v-badge>

          <v-btn v-if="isEdit" class="status-button"
            :color="utilsGetSeverityColor(devicedetails.highestSeverity).colorName" rounded outlined right absolute>
            Status: <v-icon>{{ utilsFaultStatusDisplayObject(devicedetails.faultStatus).icon }}</v-icon><strong>{{
                devicedetails.highestSeverity ?
                  devicedetails.highestSeverity : utilsFaultStatusDisplayObject(devicedetails.faultStatus).text
            }} </strong>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <!-- begin create/edit form -->
          <v-container fluid class="pl-5 pr-5">
            <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
              {{ apiStatus }}
            </v-alert>
            <v-row v-if="isEdit" :key="statusKey1">
              <v-col cols="12" md="3">
                <v-alert text class="link-alert" prominent type="warning" icon="mdi-ticket-confirmation"
                  @click="navigateToIncidents(devicedetails)">
                  <p class="mb-0">Incidents</p>
                  <h3>
                    {{ devicedetails.incCount || 0 }}
                  </h3>
                </v-alert>
              </v-col>
              <v-col cols="12" md="3">
                <v-img height="100" contain :src='"/assets/img/devices/" + devicedetails.imageInfo.images.main'></v-img>
              </v-col>
            </v-row>
            <form>
              <v-row v-if="isLoading">
                <v-col cols="12">
                  <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <ValidationProvider :rules="{ required: true, min: { length: 3 } }" v-slot="{ errors }">
                    <v-text-field dense v-model="devicedetails.monDevName" class="moda-required" label="Device Name"
                      hint="Must be same as in syslog files" :disabled="readonly" :error-messages="errors[0]" />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field dense v-model="devicedetails.monDevMACAddress" label="ID" :disabled="readonly || isEdit" />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field dense v-model="devicedetails.monDevIpAddress" label="IP Address" :disabled="readonly || isEdit" />
                </v-col>
                <v-col cols="12" md="3">
                  <!-- can't change site in edit mode -->
                  <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                    <v-autocomplete v-model="devicedetails.siteId" dense :items="sites_list" item-text="siteName"
                      item-value="siteId" :return-object="false" class="moda-required" label="Site"
                      :error-messages="errors[0]" :disabled="readonly || isEdit" />
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="2">
                  <v-slider v-model="devicedetails.monDevImportance" label="Importance" thumb-label="always" :min=1
                    :max=10 :messages="monDevImportanceMessage" :disabled="readonly"></v-slider>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field dense v-model="devicedetails.monDevVendor" label="Vendor" :disabled="readonly" />
                </v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete label="Type" v-model="devicedetails.monDevType" :items="monDevTypeList"
											class="moda-required" :disabled="readonly" />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field dense v-model="devicedetails.monDevModel" label="Model" :disabled="readonly" />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field dense v-model="devicedetails.monDevOS" label="OS" :disabled="readonly" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field dense v-model="devicedetails.description" label="Description" :disabled="readonly" />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field dense v-model="devicedetails.serviceNames" label="Services" readonly />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="9">
                  <v-autocomplete v-model="devicedetails.assignedTemplates" prepend-inner-icon="mdi-clipboard-pulse"
                    :items="monTemplatesList" item-text="monTemplateName" item-value="monTemplateId" auto-select-first
                    label="Monitoring Template(s)" :disabled="readonly" multiple chips clearable>
                    <template v-slot:item="{ item }">
                      <v-simple-checkbox :value="isAssignedTemplateSelected(item.monTemplateId)"
                        @click="toggleAssignedTemplateSelection(item.monTemplateId)" />
                      <a class="link-text" v-on:click="navigateToMonTemplate(item.monTemplateId)">
                        {{ item.monTemplateName }} </a>
                    </template>
                    <template v-slot:selection="{ item, select, selected }">
                      <v-chip :input-value="selected" close @click="select" color="primary"
                        @click:close="readonly || remove_assignedTemplates(item)">
                        <a class="link-text" v-on:click="navigateToMonTemplate(item.monTemplateId)">
                          {{ item.monTemplateName }} </a>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                  <!--
                  :disabled = "modelSelectorEnabled"
                  :class="[ modelSelectorEnabled ? '' : 'moda-required']"
  -->
                </v-col>
                <v-col cols="12" md="3">
                  <v-checkbox class="ma-2" v-model="devicedetails.adminStatus"
                    :true-value="UtilsModaConstants.AdminStatus.ENABLED"
                    :false-value="UtilsModaConstants.AdminStatus.DISABLED" :label="devicedetails.adminStatus" inset
                    :disabled="readonly" />
                </v-col>
                <!--
                  :label="`Admin Status: ${devicedetails.adminStatus}`"
  -->
                <!-- for the agent device itself, liveStatus will be null - nmap detects it, but ARP won't detect it 
                  if it's explicitly not online/offline, it's shown as Unknown -->
              </v-row>

              <v-card v-if="isSNMPTemplateApplied" outlined>
                <v-card-subtitle>SNMP</v-card-subtitle>
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="3">
                        <ValidationProvider rules="required|IPv4" v-slot="{ errors }">
                            <v-text-field dense v-model="devicedetails2.snmp.mgmtIpAddress" label="IP"
                                :disabled="readonly" class="moda-required" :error-messages="errors"/>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" md="3">
                        <ValidationProvider :rules="{ required: true, min_value:1, max_value:65535 }" v-slot="{ errors }">
                            <v-text-field dense v-model="devicedetails2.snmp.mgmtPort" label="Port" :disabled=readonly
                            hint="1-65535" persistent-hint class="moda-required" :error-messages="errors"/>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" md="3">
                        <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                            <v-autocomplete dense v-model="devicedetails2.snmp.version" :items="snmpVersions"
                                item-text="key" item-value="value" label="Version" :disabled="readonly"
                                class="moda-required" :error-messages="errors[0]"/>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row v-if="devicedetails2.snmp.version != 3">
                      <v-col cols="12" md="3">
                        <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                          <v-text-field class="moda-required" dense v-model="devicedetails2.snmp.community"
                            label="Community" :disabled=readonly :error-messages="errors[0]" />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col cols="12" md="4">
                        <!-- this is always an optional field -->
                        <v-text-field dense v-model="devicedetails2.snmp.contextName" label="Context name"
                          :disabled=readonly />
                      </v-col>
                      <v-col cols="12" md="4">
                        <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                          <v-text-field class="moda-required" :error-messages="errors[0]" dense
                            v-model="devicedetails2.snmp.securityName" label="Security name" :disabled=readonly />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" md="4">
                        <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                          <v-autocomplete class="moda-required" :error-messages="errors[0]" dense
                            v-model="devicedetails2.snmp.securityLevel" :items="snmpSecurityLevels" item-text="key"
                            item-value="value" label="Security evel" :disabled="readonly" />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" md="3" v-if="devicedetails2.snmp.securityLevel == 1 || devicedetails2.snmp.securityLevel == 2">
                        <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                          <v-autocomplete class="moda-required" :error-messages="errors[0]" dense
                            v-model="devicedetails2.snmp.authProtocol" :items="snmpAuthProtocols" item-text="key"
                            item-value="value" label="Authentication" :disabled="readonly" />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" md="3" v-if="devicedetails2.snmp.securityLevel == 1 || devicedetails2.snmp.securityLevel == 2">
                        <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                          <v-text-field class="moda-required" :error-messages="errors[0]" dense
                            v-model="devicedetails2.snmp.authPassphrase" label="Auth passphrase" :disabled=readonly />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" md="3" v-if="devicedetails2.snmp.securityLevel == 2">
                        <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                          <v-autocomplete class="moda-required" :error-messages="errors[0]" dense
                            v-model="devicedetails2.snmp.privacyProtocol" :items="snmpPrivacyProtocols" item-text="key"
                            item-value="value" label="Privacy" :disabled="readonly" />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" md="3" v-if="devicedetails2.snmp.securityLevel == 2">
                        <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                          <v-text-field class="moda-required" :error-messages="errors[0]" dense
                            v-model="devicedetails2.snmp.privacyPassphrase" label="Privacy passphrase"
                            :disabled=readonly />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>

            </form>
          </v-container>
          <!-- end create/edit form -->
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn rounded outlined color="success" v-if="!readonly && !isEdit" @click="submit" :disabled="invalid">
            Create</v-btn>
          <v-btn rounded outlined color="success" v-if="!readonly && isEdit" @click="submit" :disabled="invalid">
            Update</v-btn>
          <v-btn rounded outlined color="error" v-if="!readonly" @click="cancel">Cancel</v-btn>
          <v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </div>
</template>
<script>
import ApiService from "@/services/api.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import Utils from "@/pages/moda/Utils";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      currOrgId: null,
      isLoading: false,
      fullPage: true,
      onCancel: true,
      height: 35,
      width: 35,
      device_id: "",
      titleName: "Create",
      isEdit: false,
      readonly: true,
      statusKey1: 0, // key attribute of elements that depend on fault status data so they can be re-rendered forcefully

      sites_list: [],
      /*
            templates_list: [],
            monDevVendors: [],
            assignedTemplates: [],
      */
      monTemplatesList: [],
      monDevTypeList:[],

      apiStatus: null,
      userdetails: JSON.parse(localStorage.getItem("user-details")),
      parent_pages: [
        { name: "Home" },
        { name: "Devices", route: { name: "MONITORED_DEVICES" } },
        { name: "Create/Edit", active: true },
      ],
      devicedetails: {
        monDevModel: '',
        monDevName: null,
        monDevVendor: '',
        siteId: null,
        description: null,
        monDevMACAddress: null,
        assignedTemplates: [],
        serviceNames: "",
        adminStatus: "Enabled",
        monDevImportance: 0,
      },
      // since snmp object wasn't there for existing Devices, mutating this nested object on Edit is breaking
      // its watchers and hence conditional rendering of SNMP fields. So create a separate obj and copy it over before submitting
      devicedetails2: {
        snmp: { version: 2, mgmtPort: 161, community: "public" },
      },
      monDevImportanceMessage: null,
      snmpVersions: [],
      snmpSecurityLevels: [],
      snmpAuthProtocols: [],
      snmpPrivacyProtocols: [],
    };
  },
  mixins: [Utils],
  watch: {
    'devicedetails.monDevImportance'(val) {
      if (val == undefined) { this.monDevImportanceMessage = null; return }
      let sev1 = this.UtilsModaConstants.FaultService.devImpSevCutOff(Number(val));
      this.monDevImportanceMessage = "Drops alarms < " + sev1
    },
/*
    'devicedetails2.snmp.version'(val) {
      console.log("snmp version changed " + JSON.stringify(val))
    },
*/
  },
  computed: {
    isSNMPTemplateApplied() {
      let num = 0
      //let num = 1 // TBD: temporarily always return true for development purpose
      if (this.devicedetails.assignedTemplates && Array.isArray(this.devicedetails.assignedTemplates)) {
        this.devicedetails.assignedTemplates.forEach(function (sel) {
          let idx = this.monTemplatesList.findIndex((x) => sel == x.monTemplateId &&
            x.monTemplateType == this.UtilsModaConstants.InventoryService.MonitoringTemplateType.EXT_SNMP)
          if (idx >= 0) num++
        }, this)
      }
      return num != 0
    }
  },

  methods: {
    isAssignedTemplateSelected(monTemplateId) {
      return this.devicedetails.assignedTemplates && Array.isArray(this.devicedetails.assignedTemplates) &&
        this.devicedetails.assignedTemplates.includes(monTemplateId)
    },
    toggleAssignedTemplateSelection(monTemplateId) {
      if (!this.devicedetails.assignedTemplates || !Array.isArray(this.devicedetails.assignedTemplates))
        this.devicedetails.assignedTemplates = []
      this.devicedetails.assignedTemplates.includes(monTemplateId) ?
        this.devicedetails.assignedTemplates = this.devicedetails.assignedTemplates.filter(v => v != monTemplateId) :
        this.devicedetails.assignedTemplates.push(monTemplateId)
    },
    navigateToIncidents(row) {
      //console.log("navigateToIncidents monDevRowSelected ", JSON.stringify(row));
      var monDevIds = [], monDevNames = [], states = []//, advSearchString = "";
      monDevIds = [row.monDevId]
      monDevNames = [row.monDevName]
      states = this.utilsOpenStates;
      //advSearchString = "Open incs. in " + row.monDevName;

      var query = [];
      if (monDevIds.length && monDevNames.length) {
        //OLD: query.push({ name: "monDevId", value: monDevIds });
        //query.push({ name: "monDev", value: {monDevIdList: monDevIds, monDevNameList: monDevNames} });
        query.push({ name: "component", value: { componentIdList: monDevIds, componentNameList: monDevNames } });
        //query.push({ name: "componentId", value: monDevIds });
      }
      /*~~~ OLD ~~~
      if (monDevNames.length) {
          query.push({ name: "monDevName", value: monDevNames });
          //query.push({ name: "componentName", value: monDevNames });
      }
      ~~~~~~~~~~~~~~*/

      query.push({ name: "state", value: states });
      console.log("navigateToIncidents query ", JSON.stringify(query));
      query.length ? this.$router.push({ name: "INCIDENTS", query: query }) : this.$router.push({ name: "INCIDENTS" });
    },
    navigateToMonTemplate(monTemplateId) {
      monTemplateId
      const r = this.$router.resolve({ name: "MON_TEMPLATES" });
      window.open(r.href, '_blank')
    },
    submit() {

      this.isLoading = true;
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      try {
        this.devicedetails.monDevName = this.devicedetails.monDevName.trim();
      } catch (e) {
        console.error(e);
      }

      let thisSite = this.sites_list.find(
        (s) => s.siteId === this.devicedetails.siteId
      );
      this.devicedetails.orgId = thisSite.orgId;

      // since snmp object wasn't there for existing Devices, mutating this nested object on Edit is breaking
      // its watchers and hence conditional rendering of SNMP fields. So create a separate obj and copy it over before submitting
      this.devicedetails.snmp = this.devicedetails2.snmp

      if (this.isEdit) {
        ApiService.put("/svcInventory/api/v1/monitoredDevice/" + this.device_id, this.devicedetails, (err, result) => {
          //console.log("*update*"+JSON.stringify(result))
          this.isLoading = false;
          if (err) {
            this.apiStatus = result
              ? result.data.message
                ? result.data.message
                : result.data
              : "Request failed";
            this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            this.cancelCreateDevice();
          }
        },
          query_params, "INVENTORY_SERVICE_URL"
        );
      } else {
        // create
        ApiService.post("/svcInventory/api/v1/monitoredDevice", this.devicedetails,
          (err, result) => {
            //console.log("*create*"+JSON.stringify(err) + " " + JSON.stringify(result))
            this.isLoading = false;
            if (err) {
              this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
              this.utilsCheckLogout(this.apiStatus);
            } else {
              this.apiStatus = null;
              this.cancelCreateDevice();
            }
          },
          query_params, "INVENTORY_SERVICE_URL"
        );
      }
    },

    cancel() {
      this.$refs.monitoredDeviceform.reset();
      this.cancelCreateDevice();
    },
    cancelCreateDevice() {
      this.$router.go(-1);
    },

    remove_assignedTemplates(item) {
      this.devicedetails.assignedTemplates.splice(this.devicedetails.assignedTemplates.findIndex(x => x == item.monTemplateId), 1)
      this.devicedetails.assignedTemplates = [...this.devicedetails.assignedTemplates]
    },

    getSitesList() {
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      //this.isLoading=true;
      ApiService.get("/api/v1/site", (err, result) => {
        this.isLoading = false;
        if (err) {
          this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
          this.utilsCheckLogout(this.apiStatus);
        } else {
          this.apiStatus = null;
          this.sites_list = result.data.data.objs;
          //DEBUG:
          // console.log("this.sites_list ", (this.sites_list));
          this.getDeviceDetails();
        }
      },
        query_params
      );
    },

    updateMonitoredDeviceStatus(monDevStsList) {
      let monDevSts = monDevStsList[0]
      this.devicedetails.highestSeverity = monDevSts.highestSeverity
      this.devicedetails.incCount = monDevSts.incCount
      this.devicedetails.faultStatus = monDevSts.faultStatus
    },
    getMonitoredDevicesStatusList() {
      let query_params = {}, body = {};
      if (this.targetOrgId) query_params.targetOrgId = this.targetOrgId;
      else if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      this.isLoading = true

      query_params.summaryDataType = this.UtilsModaConstants.FaultService.Summary.DataType.MONITORED_DEVICES,
        query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.LISTS

      body = [this.devicedetails.monDevId]

      //console.log("getMonitoredDevicesStatusList body " + JSON.stringify(body))

      ApiService.post("/svcSummary/api/v1/summary", body, (err, result) => {   //### svcFault
        this.isLoading = false;
        if (err) {
          this.apiStatus = "Failed to fetch device's fault status"
          //this.utilsCheckLogout(this.apiStatus);
        } else {
          this.apiStatus = null;
          //console.log("**** getMonioredDevicesStatusList monDevs result = " + JSON.stringify(result, null, 2))
          this.updateMonitoredDeviceStatus(result.data.data)
          //console.log("**** getMonitoredDevicesStatusList monDevs = " + JSON.stringify(this.monDevdetails, null, 2))
          this.statusKey1 += 1; // key attribute of elements that depend on fault status data so they can be re-rendered forcefully
        }
      }, query_params, "SUMMARY_SERVICE_URL"   //###"FAULT_SERVICE_URL"
      );
    },
    getDeviceDetails() {
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      ApiService.get("/svcInventory/api/v1/monitoredDevice/" + this.device_id, (err, result) => {
        //   console.log(result);
        this.isLoading = false;
        if (err) {
          this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
          this.utilsCheckLogout(this.apiStatus);
        } else if (result.data.data[0]) {
          this.apiStatus = null;
          this.devicedetails = result.data.data[0];

          this.adminStatus = this.devicedetails.adminStatus;
          if (this.devicedetails.services) this.devicedetails.serviceNames = this.devicedetails.services.map((s) => s.service + "/" + s.port)

          // since snmp object wasn't there for existing Devices, mutating this nested object on Edit is breaking
          // its watchers and hence conditional rendering of SNMP fields. So create a separate obj point to retrieved one
          if ( ! this.devicedetails.snmp) this.devicedetails.snmp = {}
          this.devicedetails2.snmp.mgmtIpAddress    = this.devicedetails.snmp.mgmtIpAddress || this.devicedetails.monDevIpAddress
          this.devicedetails2.snmp.version          = this.devicedetails.snmp.version || this.devicedetails2.snmp.version
          this.devicedetails2.snmp.mgmtPort         = this.devicedetails.snmp.mgmtPort || this.devicedetails2.snmp.mgmtPort
          this.devicedetails2.snmp.community        = this.devicedetails.snmp.community || this.devicedetails2.snmp.community

          this.titleName = (this.readonly ? "View - " : "Edit - ") + this.devicedetails.monDevName;

          //console.log(JSON.stringify(this.devicedetails));

          this.getMonitoredDevicesStatusList();
        }
      },
        query_params, "INVENTORY_SERVICE_URL"
      );
    },
    getMonitoringTemplates() {
      let query_params = {}
      ApiService.get("/svcInventory/api/v1/monitoringTemplate/", (err, result) => {
        this.isLoading = false;
        if (err) {
          this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
          this.utilsCheckLogout(this.apiStatus);
        } else {
          this.monTemplatesList = result.data.data;
          /*
                    this.monTemplatesList.forEach((template) => {
                      this.monTemplateNames[`${template.monTemplateName}`] = {
                          templateId: template.monTemplateId,
                          templateName: template.monTemplateName,
                      };
                    });
          */
        }
      }, query_params, "INVENTORY_SERVICE_URL")
    },
    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      if (this.device_id != "" && this.device_id != undefined) {
        // Edit can't be done when org is changed, so g back
        this.cancelCreateDevice();
      } else this.getSitesList();
    },
    /*
        setTemplatesList(v){
            this.assignedTemplates = [];    // [];
            this.templates_list = [this.assignedTemplates[v]];
        }
    */
  },

  mounted() {

    this.snmpVersions = this.utilsDictToArray(this.UtilsModaConstants.InventoryService.SNMP.Version);
    this.snmpSecurityLevels = this.utilsDictToArray(this.UtilsModaConstants.InventoryService.SNMP.SecurityLevel);
    this.snmpAuthProtocols = this.utilsDictToArray(this.UtilsModaConstants.InventoryService.SNMP.AuthProtocol);
    this.snmpPrivacyProtocols = this.utilsDictToArray(this.UtilsModaConstants.InventoryService.SNMP.PrivProtocol);

    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    this.device_id = this.$route.params.id;
    this.readonly = this.$route.params.readonly == "1";
    this.getSitesList();
    this.getMonitoringTemplates();

    this.monDevTypeList = Object.values(this.UtilsModaConstants.InventoryService.MonitoredDevice.Type).filter(v => typeof(v) == 'string');

    if (this.device_id != "" && this.device_id != undefined) {
      this.isEdit = true;
      this.titleName = this.readonly ? "View" : "Edit";
    }
  },
};
</script>
