<template>
  <v-expansion-panels v-model="panelsOpen" flat class="grid-filter" @keyup.native.enter="applyAdvancedFilters">
    <v-expansion-panel class="filter-box">
      <v-expansion-panel-header v-slot="{ open }" hide-actions>
        <v-row>
          <v-col cols="12" :md="4" v-if="isEnabled(utilsAdvancedFilterFields.FREEFORM_SEARCH)">
            <!-- @click.native.stop is to disable expansion when clicked -->
            <v-text-field class="pt-0" v-model="freeFormSearchStr" hide-details prepend-inner-icon="mdi-magnify"
                        @input="utilsFilterChanged" @click.native.stop label="Search table" clearable/>
          </v-col>
          <v-col cols="12" :md="isEnabled(utilsAdvancedFilterFields.FREEFORM_SEARCH) ? 6 : 12" >
            <v-text-field v-model="advancedFilterStr" prepend-inner-icon="mdi-filter-multiple-outline"
                        class="pt-0 text-wrap" :class="{'moda-advanced-search':true, 'moda-red-text':true}"
                        hide-details readonly label="Filters"/>
          </v-col>
          <v-col cols="2" :md="2">
            <v-btn rounded outlined block color="primary" @click="applyAdvancedFilters" > Apply </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="mt-5">
        <v-row>

          <!-- !!!! Order matters !!! - list them in the order you want these fields to be displayed -->

          <!-- Common fields -->

          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.ORG_NAME)">
            <v-text-field dense v-model="orgNameFilter" label="Org name contains" hint="Comma separated list" clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.PARENT_ORG_NAME)">
            <v-text-field dense v-model="parentOrgNameFilter" label="Parent Org name contains" hint="Comma separated list" clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.SITE_NAME)">
            <v-text-field dense v-model="siteNameFilter" label="Site name contains" hint="Comma separated list" clearable/>
          </v-col>

          <!-- !!!! Order matters !!! - list them in the order you want these fields to be displayed -->

          <!-- Alarm/Incidents specific -->

          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.EVENT_SOURCE_NAME)">
            <v-text-field dense v-model="eventSourceNameFilter" label="Source name contains" hint="Comma separated list" clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.EVENT_TYPE)">
            <v-autocomplete dense v-model="eventTypeFilter"        :items="eventTypesList" label="Type" multiple clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.EVENT_SUB_TYPE)">
            <v-autocomplete dense v-model="eventSubTypeFilter"     :items="eventSubTypesList[eventTypeFilter]" label="Sub Type" multiple />
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.EVENT_SEVERITY)">
            <v-autocomplete dense v-model="eventSeverityFilter"    :items="eventSeveritiesList" label="Severity" multiple clearable/>
          </v-col>
          <v-col md="6" v-if="isEnabled(utilsAdvancedFilterFields.EVENT_STATE)">
            <v-autocomplete dense v-model="eventStateFilter" :items="eventStatesList" label="State" multiple clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.EVENT_STATUS)">
            <v-autocomplete dense v-model="eventStatusFilter" :items="eventStatusList" label="Status" multiple clearable/>
          </v-col>

          <!-- !!!! Order matters !!! - list them in the order you want these fields to be displayed -->

          <!-- Application fields -->

          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.APP_NAME)">
            <v-text-field dense v-model="appNameFilter" label="App name contains" hint="Comma separated list" clearable/>
          </v-col>

          <!-- Device, MODA Agent fields -->

          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.DEV_NAME)">
            <v-text-field dense v-model="devNameFilter" label="Device name contains" hint="Comma separated list" clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.DEV_MAC_ADDRESS)">
            <v-text-field dense v-model="devMACAddressFilter" label="MAC Addr contains" hint="Comma separated list" clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.DEV_IP_ADDRESS)">
            <v-text-field dense v-model="devIpAddressFilter" label="IP Addr contains" hint="Comma separated list" clearable/>
          </v-col>


          <!-- !!!! Order matters !!! - list them in the order you want these fields to be displayed -->

          <!-- Common fields -->

          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.HIGHEST_SEVERITY)">
            <v-autocomplete dense v-model="highestSeverityFilter" :items="eventSeveritiesList" label="Highest severity" multiple clearable/>
          </v-col>


          <!-- Device, MODA Agent fields -->

          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.DEV_LIVE_STATUS)">
            <v-autocomplete dense v-model="devLiveStatusFilter"        :items="devLiveStatusList" label="Liveness" multiple clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.DEV_SERIAL_NUMBER)">
            <v-text-field dense v-model="devSerialNumberFilter" label="Serial Number contains" hint="Comma separated list" clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.DEV_VENDOR)">
            <v-text-field dense v-model="devVendorFilter" label="Vendor contains" hint="Comma separated list" clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.DEV_MODEL)">
            <v-text-field dense v-model="devModelFilter" label="Model contains" hint="Comma separated list" clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.DEV_OS)">
            <v-text-field dense v-model="devOSFilter" label="OS contains" hint="Comma separated list" clearable/>
          </v-col>


          <!-- !!!! Order matters !!! - list them in the order you want these fields to be displayed -->

          <!-- Common fields -->

          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.ADMIN_STATUS)">
            <v-autocomplete v-model="adminStatusFilter" dense :items="adminStatusList" label="Admin" multiple clearable/>
          </v-col>


          <!-- Site fields -->

          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.ADDRESS)">
            <v-text-field dense v-model="addressFilter" label="Address contains" hint="Comma separated list" clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.REGION)">
            <v-text-field dense v-model="regionFilter" label="Region contains" hint="Comma separated list" clearable/>
          </v-col>


          <!-- !!!! Order matters !!! - list them in the order you want these fields to be displayed -->

          <!-- Application fields -->

          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.APP_TYPE)">
            <v-autocomplete v-model="appTypeFilter" dense :items="appTypesList" label="Type" multiple clearable/>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.APP_SUB_TYPE)">
            <v-autocomplete v-model="appSubTypeFilter"  dense   :items="appSubTypesList[appSubTypeFilter]" label="Sub Type" multiple />
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.APP_PROTOCOL)">
            <v-autocomplete v-model="appProtocolFilter" dense :items="appProtomdList" label="Protocol" multiple />
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.APP_DOMAINS)">
            <v-text-field v-model="appDomainsFilter" dense label="Domains contain" hint="Comma separated list" clearable/>
          </v-col>

          <!-- Device fields -->
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.DEV_OPER_STATUS)">
            <v-autocomplete v-model="devOperStatusFilter" dense :items="devOperStatusList" label="Oper" multiple clearable/>
          </v-col>
          <!-- !!!! Order matters !!! - list them in the order you want these fields to be displayed -->

          <!-- Common fields -->

          <v-col cols="12" md="3" v-if="isEnabled(utilsAdvancedFilterFields.MODIFIED_AFTER)" >
              <v-datetime-picker :textFieldProps="{prependIcon: 'mdi-calendar-clock'}" label="Updated after"
                                                  v-model="modifiedAfterDate" @input="modifiedAfterDateChanged">
                  <template slot="dateIcon" slot-scope="props">
                      <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon" slot-scope="props">
                      <v-icon>mdi-clock</v-icon>
                  </template>
                  <template slot="actions" slot-scope="{ parent }">
                      <v-btn outlined rounded color="error" @click.native="parent.clearHandler">CLEAR</v-btn>
                      <v-btn outlined rounded color="success" @click="parent.okHandler">OK</v-btn>
                  </template>
              </v-datetime-picker>
          </v-col>

          <v-col cols="12" md="3" v-if="isEnabled(utilsAdvancedFilterFields.MODIFIED_BEFORE)" >
              <v-datetime-picker :textFieldProps="{prependIcon: 'mdi-calendar-clock'}" label="Updated before"
                                                  v-model="modifiedBeforeDate" @input="modifiedBeforeDateChanged">
                  <template slot="dateIcon" slot-scope="props">
                      <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon" slot-scope="props">
                      <v-icon>mdi-clock</v-icon>
                  </template>
                  <template slot="actions" slot-scope="{ parent }">
                      <v-btn outlined rounded color="error" @click.native="parent.clearHandler">CLEAR</v-btn>
                      <v-btn outlined rounded color="success" @click="parent.okHandler">OK</v-btn>
                  </template>
              </v-datetime-picker>
          </v-col>
<!--  Date selector only. No time selector
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.MODIFIED_AFTER)">
            <v-menu ref="modifiedAfterMenu" :close-on-content-click="false" :return-value.sync="modifiedAfterFilter" offset-y min-width="290px" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense v-model="modifiedAfterFilter" label="Updated after" prepend-inner-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" clearable/>
              </template>
              <v-date-picker v-model="modifiedAfterDate" no-title scrollable @change="$refs.modifiedAfterMenu.save(modifiedAfterDate)" />
            </v-menu>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.MODIFIED_BEFORE)">
            <v-menu ref="modifiedBeforeMenu" :close-on-content-click="false" :return-value.sync="modifiedBeforeFilter" offset-y min-width="290px" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense v-model="modifiedBeforeFilter" label="Updated before" prepend-inner-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" clearable/>
              </template>
              <v-date-picker v-model="modifiedBeforeDate" no-title scrollable @change="$refs.modifiedBeforeMenu.save(modifiedBeforeDate)" />
            </v-menu>
          </v-col>
-->
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.CREATED_AFTER)">
            <v-menu ref="createdAfterMenu" :close-on-content-click="false" :return-value.sync="createdAfterFilter" offset-y min-width="290px" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense v-model="createdAfterFilter" label="Created after" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="on" clearable/>
              </template>
              <v-date-picker v-model="createdAfterDate" no-title scrollable @change="$refs.createdAfterMenu.save(createdAfterDate)" />
            </v-menu>
          </v-col>
          <v-col md="3" cols="12" v-if="isEnabled(utilsAdvancedFilterFields.CREATED_BEFORE)">
            <v-menu ref="createdBeforeMenu" :close-on-content-click="false" :return-value.sync="createdBeforeFilter" offset-y min-width="290px" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense v-model="createdBeforeFilter" label="Created before" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="on" clearable/>
              </template>
              <v-date-picker v-model="createdBeforeDate" no-title scrollable @change="$refs.createdBeforeMenu.save(createdBeforeDate)" />
            </v-menu>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col md="12" align="center">
            <v-btn color="primary" @click="applyAdvancedFilters" > Apply </v-btn>
          </v-col>
        </v-row> -->

      </v-expansion-panel-content>

    </v-expansion-panel>
  </v-expansion-panels>
</template>


<script>

import ModaConst from '@/services/ModaConstants.js';
import Utils from '@/pages/moda/Utils.vue'

export default {
    name : "TableAdvancedFilter",
    props : {
        enabledFieldsIn: Array,
    },
    mixins: [
        Utils,
    ],
    components: {
    },
    data(){
        return {
            panelsOpen: null, // keep the first expansion panel open by default
            freeFormSearchStr: null,

            // Common fields
            orgNameFilter: null,
            siteNameFilter: null,

            parentOrgNameFilter: null,

            adminStatusFilter: null,
            adminStatusList: [
                ModaConst.AdminStatus.ENABLED,
                ModaConst.AdminStatus.DISABLED,
            ],

            highestSeverityFilter: null,

            modifiedAfterDate: null,
            modifiedAfterFilter: null,

            modifiedBeforeDate: null,
            modifiedBeforeFilter: null,

            createdAfterDate: null,
            createdAfterFilter: null,

            createdBeforeDate: null,
            createdBeforeFilter: null,


            // site specific 

            addressFilter: null,
            regionFilter: null,


            // application specific

            appNameFilter: null,
            appTypeFilter: null,
            appTypesList: [
                ModaConst.AppType.APP_MONITORING,
                ModaConst.AppType.SITE_MONITORING,
                ModaConst.AppType.DEVICE_MONITORING,
            ],

            appSubTypeFilter: null,
            appSubTypesList: [
            ],
            appProtocolFilter: null,
            appProtomdList: [
                ModaConst.AppProtocol.TCP_UDP,
                ModaConst.AppProtocol.TCP,
                ModaConst.AppProtocol.UDP,
                ModaConst.AppProtocol.ICMP,
            ],
            appDomainsFilter: null,


            // device, monitored device specific

            devOperStatusFilter: null,
            devOperStatusList: [
                ModaConst.DeviceOperStatus.PREPROVISIONED,
                ModaConst.DeviceOperStatus.PROVISIONED,
                ModaConst.DeviceOperStatus.REGISTERED,
            ],

            devLiveStatusFilter: null,
            devLiveStatusList: [
                ModaConst.DeviceLiveStatus.ONLINE,
                ModaConst.DeviceLiveStatus.OFFLINE,
                ModaConst.DeviceLiveStatus.FAULTY,
                ModaConst.DeviceLiveStatus.UNKNOWN,
            ],

            devMACAddressFilter: null,
            devIpAddressFilter: null,
            devSerialNumberFilter: null,
            devNameFilter: null,
            devModelFilter: null,
            devVendorFilter: null,
            devOSFilter: null,


            // Alarms/Incidents

            eventSourceNameFilter: null,

            eventTypeFilter: null,
            eventTypesList: [
                ModaConst.FaultService.CategoryType.ML_ANOMALY_TELEMETRY,
                ModaConst.FaultService.CategoryType.ML_ANOMALY_LOG_ANALYSIS,
                ModaConst.FaultService.CategoryType.DEVICE_AVAILABILITY,
                ModaConst.FaultService.CategoryType.DEVICE_PROBLEM,
                ModaConst.FaultService.CategoryType.EXTERNAL_SYS,
            ],

            eventSubTypeFilter: null,
            eventSubTypesList: [],
/* how to handle multiple eventTypes selection
            {
                MLAnomalyTelemetry : [
                ],
                MLAnomalyLogAnalysis : [
                    ModaConst.FaultService.CategorySubType.LogAnalysis.ML_LOG_ANALYSIS_SEQUENCE,
                    ModaConst.FaultService.CategorySubType.LogAnalysis.ML_LOG_ANALYSIS_PARAMS,
                ],
                DeviceAvailability : [
                ],
                DeviceProblem : [
                ],
                ExternalSystem : [
                    ModaConst.FaultService.CategorySubType.ExternalSystem.CONNECTWISE,
                ],
            },
*/
            eventSeverityFilter: null,
            eventSeveritiesList: [
                ModaConst.FaultService.Severity.CRITICAL,
                ModaConst.FaultService.Severity.SEVERE,
                ModaConst.FaultService.Severity.MAJOR,
                ModaConst.FaultService.Severity.MINOR,
                ModaConst.FaultService.Severity.INFO,
                ModaConst.FaultService.Severity.NOT_SET,
            ],

            eventStateFilter: null,
            eventStatesList: [
                ModaConst.Incident.State.NEW,
                ModaConst.Incident.State.OPEN,
                ModaConst.Incident.State.IN_PROGRESS,
                ModaConst.Incident.State.HOLD,
                ModaConst.Incident.State.REOPEN,

                ModaConst.Incident.State.RESOLVED,
                ModaConst.Incident.State.UN_REPRODUCEABLE,
                ModaConst.Incident.State.CLOSED,
                ModaConst.Incident.State.DUPLICATE,
                ModaConst.Incident.State.JUNK,
            ],

            eventStatusFilter: null,
            eventStatusList: [
                ModaConst.Alarm.Status.NEW,
                ModaConst.Alarm.Status.ACKNOWLEDGED,
            ],


            // display string

            advancedFilterStr: null,
        };
    },
    watch : {
        // common fields
        orgNameFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        siteNameFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        parentOrgNameFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        adminStatusFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        highestSeverityFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        modifiedAfterFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        modifiedBeforeFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        createdAfterFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        createdBeforeFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },


        // site fields
        addressFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        regionFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },

        // application fields
        appNameFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        appTypeFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        appSubTypeFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        appProtocolFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        appDomainsFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },

        // device, monitored device fields
        devOperStatusFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        devLiveStatusFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        devMACAddressFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        devIpAddressFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        devSerialNumberFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        devNameFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        devModelFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        devVendorFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        devOSFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },


        // alarms, incidents fields
        eventSourceNameFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        eventTypeFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        eventSubTypeFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        eventSeverityFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        eventStateFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },
        eventStatusFilter:{
            handler() {
                this.setAdvancedFilterString()
            }
        },


    },
    methods: {
        isEnabled(field){
            return this.enabledFieldsIn.includes(field)
        },
        appendString(big, small) {
            if ( big == "" ) big = small;
            else big += "," + small;
            return big
        },
        modifiedAfterDateChanged(val){
            this.modifiedAfterFilter = val ? this.utilsFormatDateTime2(val.getTime()) : ""
            //console.log("modifiedAfterDateChanged ", this.modifiedAfterFilter)
        },
        modifiedBeforeDateChanged(val){
            this.modifiedBeforeFilter = val ? this.utilsFormatDateTime2(val.getTime()) : ""
            //console.log("modifiedAfterDateChanged ", this.modifiedBeforeFilter)
        },
        // Called by watcher when any of the filter fileds change
        // This routine builds and updates display string
        setAdvancedFilterString(){
            this.advancedFilterStr = ""

            // common fields
            if ( this.orgNameFilter && this.orgNameFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Org(s):" + this.orgNameFilter)
            if ( this.siteNameFilter && this.siteNameFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Site(s):" + this.siteNameFilter)
            if ( this.eventSourceNameFilter && this.eventSourceNameFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Source(s):" + this.eventSourceNameFilter)

            if ( this.parentOrgNameFilter  && this.parentOrgNameFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Parent Org(s):" + this.parentOrgNameFilter )
            if ( this.adminStatusFilter  && this.adminStatusFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Admin:" + this.adminStatusFilter )
            if ( this.highestSeverityFilter  && this.highestSeverityFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Highest Severity(ies):" + this.highestSeverityFilter )

            // site fields
            if ( this.addressFilter  && this.addressFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Address fields:" + this.addressFilter )
            if ( this.regionFilter  && this.regionFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Region:" + this.regionFilter )

            // application fields
            if ( this.appNameFilter && this.appNameFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "App(s):" + this.appNameFilter)
            if ( this.appTypeFilter && this.appTypeFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Type(s):" + this.appTypeFilter)
            if ( this.appSubTypeFilter && this.appSubTypeFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "SubType(s):" + this.appSubTypeFilter)
            if ( this.appProtocolFilter && this.appProtocolFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Protocol(s):" + this.appProtocolFilter)
            if ( this.appDomainsFilter && this.appDomainsFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Domains(s):" + this.appDomainsFilter)

            // device, monitored device fields
            if ( this.devNameFilter  && this.devNameFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Device(s):" + this.devNameFilter )
            if ( this.devOperStatusFilter  && this.devOperStatusFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Oper:" + this.devOperStatusFilter )
            if ( this.devLiveStatusFilter  && this.devLiveStatusFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Liveness:" + this.devLiveStatusFilter )
            if ( this.devMACAddressFilter  && this.devMACAddressFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "MAC:" + this.devMACAddressFilter )
            if ( this.devIpAddressFilter  && this.devIpAddressFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "IP:" + this.devIpAddressFilter )
            if ( this.devSerialNumberFilter  && this.devSerialNumberFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "S/N:" + this.devSerialNumberFilter )
            if ( this.devModelFilter  && this.devModelFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Model:" + this.devModelFilter )
            if ( this.devVendorFilter  && this.devVendorFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Vendor:" + this.devVendorFilter )
            if ( this.devOSFilter  && this.devOSFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "OS:" + this.devOSFilter )

            // alarms, incidents
            if ( this.eventTypeFilter && this.eventTypeFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Type(s):" + this.eventTypeFilter)
            if ( this.eventSubTypeFilter && this.eventSubTypeFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "SubType(s):" + this.eventSubTypeFilter)
            if ( this.eventSeverityFilter && this.eventSeverityFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Severity(ies):" + this.eventSeverityFilter)
            if ( this.eventStateFilter && this.eventStateFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "State(s):" + this.eventStateFilter)
            if ( this.eventStatusFilter  && this.eventStatusFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Status(es):" + this.eventStatusFilter )

            // common fields
            if ( this.modifiedAfterFilter  && this.modifiedAfterFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "After:" + this.modifiedAfterFilter )
            if ( this.modifiedBeforeFilter  && this.modifiedBeforeFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Before:" + this.modifiedBeforeFilter )
            if ( this.createdAfterFilter  && this.createdAfterFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "After:" + this.createdAfterFilter )
            if ( this.createdBeforeFilter  && this.createdBeforeFilter.length)
                this.advancedFilterStr = this.appendString(this.advancedFilterStr, "Before:" + this.createdBeforeFilter )

            //console.log("setAdvancedFilterString " + this.advancedFilterStr)
        },
        // called by Utils when there is pause inentering free form text
        freeFormSearchChanged(){
            console.log("TableAdvancedFilter freeFormSearchChanged " + this.freeFormSearchStr)
            this.$emit('freeFormSearchChanged', this.freeFormSearchStr);
        },
        // called when  APPLY button clicked
        applyAdvancedFilters(){
            //console.log("TableAdvancedFilter applyAdvancedFilters this.siteNameFilter " + JSON.stringify(this.siteNameFilter) )
            //console.log("TableAdvancedFilter applyAdvancedFilters this.eventSourceNameFilter " + JSON.stringify(this.eventSourceNameFilter))

            var filter = {}

            // common fields
            if ( this.orgNameFilter && this.orgNameFilter.length )
                filter.orgNameFilter = (typeof this.orgNameFilter === 'string' ? this.orgNameFilter.split(",") : this.orgNameFilter)
            if ( this.siteNameFilter && this.siteNameFilter.length )
                filter.siteNameFilter = (typeof this.siteNameFilter === 'string' ? this.siteNameFilter.split(",") : this.siteNameFilter)
            if ( this.parentOrgNameFilter && this.parentOrgNameFilter.length )
                filter.parentOrgNameFilter = (typeof this.parentOrgNameFilter === 'string' ? this.parentOrgNameFilter.split(",") : this.parentOrgNameFilter)
            if ( this.adminStatusFilter && this.adminStatusFilter.length ) filter.adminStatusFilter = this.adminStatusFilter
            if ( this.highestSeverityFilter && this.highestSeverityFilter.length ) filter.highestSeverityFilter = this.highestSeverityFilter
            if ( this.modifiedAfterFilter ) filter.modifiedAfterFilter = this.modifiedAfterFilter
            if ( this.modifiedBeforeFilter ) filter.modifiedBeforeFilter = this.modifiedBeforeFilter
            if ( this.createdAfterFilter ) filter.createdAfterFilter = this.createdAfterFilter
            if ( this.createdBeforeFilter ) filter.createdBeforeFilter = this.createdBeforeFilter

            // site fields
            if ( this.addressFilter && this.addressFilter.length )
                filter.addressFilter = (typeof this.addressFilter === 'string' ? this.addressFilter.split(",") : this.addressFilter)
            if ( this.regionFilter && this.regionFilter.length )
                filter.regionFilter = (typeof this.regionFilter === 'string' ? this.regionFilter.split(",") : this.regionFilter)

            // application fields
            if ( this.appNameFilter && this.appNameFilter.length )
                filter.appNameFilter = (typeof this.appNameFilter === 'string' ? this.appNameFilter.split(",") : this.appNameFilter)
            if ( this.appTypeFilter && this.appTypeFilter.length ) filter.appTypeFilter = this.appTypeFilter
            if ( this.appSubTypeFilter && this.appSubTypeFilter.length ) filter.appSubTypeFilter = this.appSubTypeFilter
            if ( this.appProtocolFilter && this.appProtocolFilter.length ) filter.appProtocolFilter = this.appProtocolFilter
            if ( this.appDomainsFilter && this.appDomainsFilter.length )
                filter.appDomainsFilter = (typeof this.appDomainsFilter === 'string' ? this.appDomainsFilter.split(",") : this.appDomainsFilter)

            // device, monitored device fields
            if ( this.devMACAddressFilter && this.devMACAddressFilter.length )
                filter.devMACAddressFilter = (typeof this.devMACAddressFilter === 'string' ? this.devMACAddressFilter.split(",") : this.devMACAddressFilter)
            if ( this.devIpAddressFilter && this.devIpAddressFilter.length )
                filter.devIpAddressFilter = (typeof this.devIpAddressFilter === 'string' ? this.devIpAddressFilter.split(",") : this.devIpAddressFilter)
            if ( this.devSerialNumberFilter && this.devSerialNumberFilter.length )
                filter.devSerialNumberFilter = (typeof this.devSerialNumberFilter === 'string' ? this.devSerialNumberFilter.split(",") :
                                                                                                 this.devSerialNumberFilter)
            if ( this.devNameFilter && this.devNameFilter.length )
                filter.devNameFilter = (typeof this.devNameFilter === 'string' ? this.devNameFilter.split(",") : this.devNameFilter)
            if ( this.devModelFilter && this.devModelFilter.length )
                filter.devModelFilter = (typeof this.devModelFilter === 'string' ? this.devModelFilter.split(",") : this.devModelFilter)
            if ( this.devVendorFilter && this.devVendorFilter.length )
                filter.devVendorFilter = (typeof this.devVendorFilter === 'string' ? this.devVendorFilter.split(",") : this.devVendorFilter)
            if ( this.devOSFilter && this.devOSFilter.length ) filter.devOSFilter = this.devOSFilter
                filter.devOSFilter = (typeof this.devOSFilter === 'string' ? this.devOSFilter.split(",") : this.devOSFilter)
            if ( this.devOperStatusFilter && this.devOperStatusFilter.length ) filter.devOperStatusFilter = this.devOperStatusFilter
            if ( this.devLiveStatusFilter && this.devLiveStatusFilter.length ) filter.devLiveStatusFilter = this.devLiveStatusFilter

            // event fields
            if ( this.eventSourceNameFilter && this.eventSourceNameFilter.length )
                filter.eventSourceNameFilter = (typeof this.eventSourceNameFilter === 'string' ? this.eventSourceNameFilter.split(",") :
                                                                                                 this.eventSourceNameFilter)
            if ( this.eventTypeFilter && this.eventTypeFilter.length ) filter.eventTypeFilter = this.eventTypeFilter
            if ( this.eventSubTypeFilter && this.eventSubTypeFilter.length ) filter.eventSubTypeFilter = this.eventSubTypeFilter
            if ( this.eventSeverityFilter && this.eventSeverityFilter.length ) filter.eventSeverityFilter = this.eventSeverityFilter
            if ( this.eventStateFilter && this.eventStateFilter.length ) filter.eventStateFilter = this.eventStateFilter
            if ( this.eventStatusFilter && this.eventStatusFilter.length ) filter.eventStatusFilter = this.eventStatusFilter

            console.log("TableAdvancedFilter applyAdvancedFilters applying filter " + JSON.stringify(filter))

            // send the msg always even if filter is empty. All/some filters may have be cleared.
            this.$emit('advancedFilterChanged', filter);
        },
/* Not used
        // called by parent when filters string is cleared
        clearAdvancedFilters(){
            // common fields
            this.orgNameFilter          = null
            this.siteNameFilter         = null
            this.parentOrgNameFilter    = null
            this.adminStatusFilter      = null
            this.highestSeverityFilter  = null
            this.modifiedAfterFilter    = null
            this.modifiedBeforeFilter   = null
            this.createdAfterFilter     = null
            this.createdBeforeFilter    = null

            // site fields
            this.addressFilter          = null
            this.regionFilter           = null

            // application fields
            this.appNameFilter          = null
            this.appTypeFilter          = null
            this.appSubTypeFilter       = null
            this.appProtocolFilter      = null
            this.appDomainsFilter       = null

            // device, monitored device fields
            this.devMACAddressFilter    = null
            this.devIpAddressFilter     = null
            this.devSerialNumberFilter  = null
            this.devNameFilter          = null
            this.devOperStatusFilter    = null
            this.devLiveStatusFilter    = null
            this.devModelFilter         = null
            this.devVendorFilter        = null
            this.devOSFilter            = null

            // alarms, incidents fields
            this.eventSourceNameFilter  = null
            this.eventTypeFilter        = null
            this.eventSubTypeFilter     = null
            this.eventSeverityFilter    = null
            this.eventStateFilter       = null
            this.eventStatusFilter      = null

            this.advancedFilterStr      = ""
        },
*/
        // called by parent after it mounted
        setAdvancedFilters(tableAdvancedFilter, freeFormSearchStrIn) {
            if ( tableAdvancedFilter ) {
                // common fields
                this.orgNameFilter          = tableAdvancedFilter.orgNamesFilterIn
                this.siteNameFilter         = tableAdvancedFilter.siteNamesFilterIn
                this.parentOrgNameFilter    = tableAdvancedFilter.parentOrgNamesFilterIn
                this.adminStatusFilter      = tableAdvancedFilter.adminStatusFilterIn
                this.highestSeverityFilter  = tableAdvancedFilter.highestSeveritiesFilterIn
                this.modifiedBeforeFilter   = tableAdvancedFilter.modifiedBeforeFilterIn
                if ( this.modifiedBeforeFilter ) this.modifiedBeforeDate = new Date(this.modifiedBeforeFilter)
                this.modifiedAfterFilter    = tableAdvancedFilter.modifiedAfterFilterIn
                if ( this.modifiedAfterFilter ) this.modifiedAfterDate = new Date(this.modifiedAfterFilter)
                this.createdBeforeFilter    = tableAdvancedFilter.createdBeforeFilterIn
                if ( this.createdBeforeFilter ) this.createdBeforeDate = new Date(this.createdBeforeFilter)
                this.createdAfterFilter     = tableAdvancedFilter.createdAfterFilterIn
                if ( this.createdAfterFilter ) this.createdAfterDate = new Date(this.createdAfterFilter)

                // site fields
                this.addressFilter          = tableAdvancedFilter.addressFilterIn
                this.regionFilter           = tableAdvancedFilter.regionFilterIn

                // application fields
                this.appNameFilter          = tableAdvancedFilter.appNamesFilterIn
                this.appTypeFilter          = tableAdvancedFilter.appTypesFilterIn
                this.appSubTypeFilter       = tableAdvancedFilter.appSubTypesFilterIn
                this.appProtocolFilter      = tableAdvancedFilter.appProtocolFilterIn
                this.appDomainsFilter       = tableAdvancedFilter.appDomainsFilterIn

                // device fields
                this.devMACAddressFilter    = tableAdvancedFilter.devMACAddressFilterIn
                this.devIpAddressFilter     = tableAdvancedFilter.devIpAddressFilterIn
                this.devSerialNumberFilter  = tableAdvancedFilter.devSerialNumberFilterIn
                this.devNameFilter          = tableAdvancedFilter.devNameFilterIn
                this.devOperStatusFilter    = tableAdvancedFilter.devOperStatusFilterIn
                this.devLiveStatusFilter    = tableAdvancedFilter.devLiveStatusFilterIn
                this.devVendorFilter        = tableAdvancedFilter.devVendorFilterIn
                this.devModelFilter         = tableAdvancedFilter.devModelFilterIn
                this.devOSFilter            = tableAdvancedFilter.devOSFilterIn

                // alarms, incidents fields
                this.eventSourceNameFilter  = tableAdvancedFilter.eventSourceNamesFilterIn
                this.eventTypeFilter        = tableAdvancedFilter.eventTypesFilterIn
                this.eventSubTypeFilter     = tableAdvancedFilter.eventSubTypesFilterIn
                this.eventSeverityFilter    = tableAdvancedFilter.eventSeveritiesFilterIn
                this.eventStateFilter       = tableAdvancedFilter.eventStatesFilterIn
                this.eventStatusFilter      = tableAdvancedFilter.eventStatusesFilterIn
            }

            this.freeFormSearchStr      = freeFormSearchStrIn

            console.log("TableAdvancedFilter setAdvancedFilters : " + JSON.stringify(tableAdvancedFilter) + ", freeFormSearchStr " + freeFormSearchStrIn)
        },
    },
    created() {
        //this.init();
    },
    mounted() {
        // Utils calles this routine when there is pause inentering free form text
        this.utilsDataRefreshFunc = this.freeFormSearchChanged
    }
}
</script>
