<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-ticket-confirmation</v-icon>Incident Details
			</v-card-title>
			<v-card-text class="pb-0">
				<v-container fluid>
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<v-row>
						<v-col cols="12" v-if="isLoading">
							<v-progress-linear indeterminate color="primary"></v-progress-linear>
						</v-col>
					</v-row>

					<!-- !!! Some of the params and functions used here are defined in AdsTreeTableUtils.vue mixin !!!!  -->
					<!-- !!! When async functionality implemented for this table, callRows, callChildren, callTempRows routines need to be
                 brought back to this file !!!!! -->

					<!-- Incident details tabs -->
					<v-row>
						<v-col cols="12" md="4" sm="6">
							<strong>ID</strong>
							<p>{{ incidentDetails.incidentIdCode }}</p>
							<strong>Headline</strong>
							<p> {{ incidentDetails.headline }} </p>
							<strong>Details</strong>
							<p>{{ incidentDetails.description }}</p>
							<strong>Source</strong>
							<p>{{ incidentDetails.componentName }}</p>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<strong>Date Created</strong>
							<p>{{ incidentDetails.createdDateString }}</p>
							<strong>Site</strong>
							<p><a href="javascript:;" v-on:click="navigateToSite(incidentDetails.orgId, incidentDetails.siteId)">
									{{ incidentDetails.siteName }} </a>
							</p>
							<strong>Assigned To</strong>
							<p>{{ incidentDetails.assignedToName || 'Unassigned' }}</p>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<strong>State</strong>
                            <div>
                                <v-chip
                                    v-if="incidentDetails.state == UtilsModaConstants.Incident.State.NEW || incidentDetails.state == UtilsModaConstants.Incident.State.OPEN"
                                    :color="utilsNonSeverityColors[UtilsModaConstants.Incident.State.NEW].colorName"
                                    :dark="utilsNonSeverityColors[UtilsModaConstants.Incident.State.NEW].darkMode">
                                    {{ incidentDetails.state }}</v-chip>
                                <v-chip
                                    v-else-if="incidentDetails.state == UtilsModaConstants.Incident.State.RESOLVED || incidentDetails.state == UtilsModaConstants.Incident.State.CLOSED"
                                    :color="utilsNonSeverityColors[UtilsModaConstants.Incident.State.CLOSED].colorName"
                                    :dark="utilsNonSeverityColors[UtilsModaConstants.Incident.State.CLOSED].darkMode">
                                    {{ incidentDetails.state }} </v-chip>
                                <v-chip v-else :color="utilsNonSeverityColors[UtilsModaConstants.Incident.State.IN_PROGRESS].colorName"
                                    :dark="utilsNonSeverityColors[UtilsModaConstants.Incident.State.IN_PROGRESS].darkMode">
                                    {{ incidentDetails.state }}</v-chip>
                            </div>

                            <!-- rootCauseInc Id, if this Incident is duplicated -->
                            <div v-if="incidentDetails.state==UtilsModaConstants.Incident.State.DUPLICATE">
                                <strong>Duplicated By: </strong>
                                <nobr>
                                    <v-icon :class="utilsNonSeverityColors['MiscBlue'].colorClass">mdi-ticket-confirmation</v-icon>
                                    <a class="link-text" v-on:click="navigateToIncident(incidentDetails.rootCauseIncId)"> {{ incidentDetails.rootCauseIncIdCode }} </a>
                                </nobr>
                            </div>

							<strong>Severity</strong>
							<div>
								<v-chip :class="utilsSeverityColors[incidentDetails.severity].colorName"
									:dark="utilsSeverityColors[incidentDetails.severity].darkMode">
									{{ incidentDetails.severity }} </v-chip>
							</div>

                            <div class="pt-4"><strong>Type</strong></div>
                            <TypeIcon :item="incidentDetails" :UtilsModaConstants="UtilsModaConstants" :showlabels="true" />
                            <!-- sub type -->
                            <div class="pt-4 float-left"><strong>Sub Type</strong></div>
                            <SubtypeIcon :item="incidentDetails" :UtilsModaConstants="UtilsModaConstants" :showlabels="true" />
						</v-col>
					</v-row>
					<v-divider></v-divider>
					<h3 class="mt-5 mb-4">Actions/Info</h3>
					<v-tabs v-model="activeTab" background-color="primary" dark slider-size="3" class="mb-5">
						<v-tabs-slider color="white"></v-tabs-slider>
						<v-tab>
							<v-icon left> mdi-bell </v-icon> Events
						</v-tab>
						<v-tab>
							<v-icon left> mdi-history </v-icon> History
						</v-tab>
						<v-tab>
							<v-icon left> mdi-content-duplicate </v-icon> Related Incidents
						</v-tab>
                        <v-tab>
                            <!-- mdi-trending-up ? -->
							<v-icon left> mdi-chart-line </v-icon> ML Trend
						</v-tab>
                        <v-tab :class="isShow" :disabled="readonly">
							<v-icon left> mdi-pencil </v-icon> Edit
						</v-tab>

						<!-- Event table Tab -->
						<v-tab-item>
							<v-card class="pt-3">
								<!-- v-data-table Alarm Table -->
								<v-data-table :headers="alarmEventTableColumnsVueTable" :search="filterValueSearch"
									:options.sync="pagingOptions" :server-items-length="utilsTotalEntriesAllPages"
									:footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }" multi-sort show-select
									v-model="alarmsDisassociated" @input="alarmRowsSelected" :items="alarmEvent_table_rows"
									item-key="alarmId">

									<template v-slot:header.data-table-select="{ props, on }">
										<v-simple-checkbox @input="on.input($event)" :value="props.value"
											:indeterminate="props.indeterminate" :disabled="readonly">
										</v-simple-checkbox>
									</template>

									<template v-slot:item.data-table-select="{ item, isSelected, select }">
										<v-icon v-if="isSelected" @click="select(false)" :disabled="readonly" class="mr-2" color="success"
											title="Associate">mdi-link-variant</v-icon>

										<v-icon v-else @click="select(true)" :disabled="readonly" class="mr-2" color="warning"
											title="Disassociate">mdi-link-variant-off</v-icon>
									</template>

									<template v-slot:item.alarmIdCode="{ item }">
										<v-row>
											<nobr>
												<v-icon v-if="isAlarmRaise(item.type)" color="error" title="Raise">mdi-arrow-up</v-icon>
												<v-icon v-else color="success" title="Clear">mdi-arrow-down
												</v-icon>
												<a href="javascript:;" v-on:click="navigateToAlarm(item.alarmId)">
													{{ item.alarmIdCode }} </a>
											</nobr>
										</v-row>
									</template>

									<template v-slot:item.siteName="{ item }">
										<a href="javascript:;" v-on:click="navigateToSite(item.orgId, item.siteId)">
											{{ item.siteName }} </a>
									</template>

                                    <template v-slot:item.typeSubtype="{ item }">
                                        <TypeIcon :item="item" :UtilsModaConstants="UtilsModaConstants" />
                                        <SubtypeIcon :item="item" :UtilsModaConstants="UtilsModaConstants" />
                                    </template>

									<template v-slot:item.severityStatePri="{ item }">
										<v-chip small :color="utilsSeverityColors[item.severity].colorName"
											:dark="utilsSeverityColors[item.severity].darkMode"> {{ item.severity }}
										</v-chip>
										<!--
                                    <v-row>
                                        <div v-if="item.status == UtilsModaConstants.Alarm.Status.NEW"
                                            :class="utilsNonSeverityColors[UtilsModaConstants.Alarm.Status.NEW].colorClass"> /{{item.status}} </div>
                                        <div v-else :class="utilsNonSeverityColors[UtilsModaConstants.Alarm.Status.ACKNOWLEDGED].colorClass">
                                            /{{item.status}} </div>
                                    </v-row>
-->
									</template>

									<template v-slot:item.actions="{ item }">
										<v-row>
											<v-icon @click="editAlarmEventDetails(item.alarmId, 1)" color="primary" class="mr-2 record-view"
												title="View"> mdi-eye </v-icon>
											<v-icon :class="utilsGetDisplayClass(item)" @click="editAlarmEventDetails(item.alarmId, 0)"
												class="mr-2" color="warning" title="Edit"> mdi-pencil</v-icon>
											<v-icon :class="utilsGetDisplayClass(item)"
												@click="deleteAlarmEventDetails(item.alarmId, item.shortDescription)" color="red"
												title="Delete">mdi-delete</v-icon>
										</v-row>
									</template>

								</v-data-table>

							</v-card>
						</v-tab-item>

						<!-- History tab -->
						<v-tab-item>
							<v-card class="pt-3">
								<v-data-table hide-default-footer :headers="historyEvent_table_columns"
									:items="historyEvent_table_rows">
									<template v-slot:item.incHistId="{ item }">
										<span> {{ item.incHistId.split('.')[1].split('_')[0] }} </span>
									</template>
									<template v-slot:item.changeString="{ item }">
										<span v-if="item.duplicateIncs.length">
											<span v-for="(dup, i) in item.duplicateIncs" :key="i">
												<span v-if="i>0"> , </span>
												<a class="link-text" v-on:click="navigateToIncident(dup.fromId)"> {{ dup.fromIdCode }} </a> ->
												<a class="link-text" v-on:click="navigateToIncident(dup.toId)"> {{ dup.toIdCode }} </a>
											</span>
										</span>
										<span v-else> {{ item.changeString }} </span>
									</template>
								</v-data-table>
							</v-card>
						</v-tab-item>

						<!-- Related Incidents Tab -->
						<v-tab-item>
							<v-card class="pt-3">
								<v-data-table hide-default-footer 
                                    :headers="relatedIncidents_table_columns"
									:items="relatedIncidents_table_rows">

                                    <template v-slot:item.incidentIdCode="{item}">
                                        <nobr>
                                            <v-icon :class="utilsNonSeverityColors['MiscBlue'].colorClass">mdi-ticket-confirmation</v-icon>
                                            <a class="link-text" v-on:click="navigateToIncident(item.incidentId)"> {{ item.incidentIdCode }} </a>
                                        </nobr>
                                    </template>
                                    <template v-slot:item.siteName="{ item }">
                                        <a href="javascript:;" v-on:click="navigateToSite(item.orgId, item.siteId)"> {{item.siteName}}
                                        </a>
                                    </template>
                                    <template v-slot:item.sourceName="{ item }">
                                        <nobr>
                                            <a class="float-left" href="javascript:;" v-on:click="navigateToComponent(item)">
                                                <v-img class="float-left mr-1" v-if="item.componentIconImg" :src="item.componentIconImg" height="24"
                                                    width="24" />
                                                <v-img class="float-left mr-1" v-else-if="item.sourceIcon"
                                                    :src="require('../../../public/assets/img/moda/' + item.sourceIcon)" height="24" width="24" />
                                                {{item.sourceName}}
                                            </a>
                                        </nobr>
                                    </template>
                                    <template v-slot:item.typeSubtype="{ item }">
                                        <TypeIcon :item="item" :UtilsModaConstants="UtilsModaConstants" />
                                        <SubtypeIcon :item="item" :UtilsModaConstants="UtilsModaConstants" />
                                    </template>
                                    <template v-slot:item.severityStatePri="{item}">
                                        <v-chip small class="mr-1" :color="utilsSeverityColors[item.severity].colorName"
                                            :dark="utilsSeverityColors[item.severity].darkMode"> {{item.severity}} </v-chip>
                                        <v-chip small class="mr-1" :color="utilsNonSeverityColors[item.state].colorName"
                                            :dark="utilsNonSeverityColors[item.state].darkMode"> {{item.state}} </v-chip>
                                        <v-chip small v-if="item.priority"> {{item.priority}} </v-chip>
                                    </template>
								</v-data-table>
							</v-card>
						</v-tab-item>

						<!-- Event Trend tab -->
						<v-tab-item>
							<v-card class="pt-3">
                                <LineChart :style="trendChartStyle" :chartData="trendChartData" :chartOptions="trendChartOptions">
                                </LineChart>
							</v-card>
						</v-tab-item>

						<!-- Edit tab -->
						<v-tab-item>
							<v-card class="pt-3">
								<v-row>
									<v-col cols="12" md="6">
										<v-autocomplete label="State" v-model="incidentDetails.state" :items="stateValues"
											class="moda-required" :disabled="readonly" />
                                        
                                        <v-autocomplete v-if="incidentDetails.state == 'Duplicate'" label="Duplicate Of" v-model="selectedRootCauseIncidentIdCode" :items="similarIncidentsList" dense
											:disabled="readonly" />

										<v-autocomplete label="Severity" v-model="incidentDetails.severity" :items="severityValues"
											class="moda-required" dense :disabled="readonly" />
										<v-autocomplete label="Priority" v-model="incidentDetails.priority" :items="priorities" dense
											:disabled="readonly" />
										<v-autocomplete label="Assigned To" v-model="incidentDetails.assignedTo" :items="users_table_rows"
											item-text="displayName" item-value="userId" :error-messages="apiStatus" dense
											:disabled="readonly" />
										<!--- attachement upload TBD -->
										<!--
                                    <v-row>
                                        <v-text-field label="Attachments" outlined v-model="incidentDetails.attachments" dense :readonly="readonly" />
                                    </v-row>
-->

										<!--- disassociate alarms TBD : select alarms from alarmTable in ALARMS tab, then send to API to disassociate-->
										<v-text-field label="Event(s) to be disassociated (select in Events tab)"
											v-model="incidentDetails.alarmsDisassociated" dense disabled />
									</v-col>

									<v-col cols=12 md=6>
										<v-textarea v-model="incidentDetails.changeComment" :full-width=true label="Comment"
											:disabled="readonly"
											placeholder="This is a sample comment that will be added as a History item to the Incident" />
									</v-col>
								</v-row>
							</v-card>

						</v-tab-item>

					</v-tabs>
				</v-container>
				<!-- end create/edit form -->
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
                <!-- <v-btn rounded outlined color="success" @click="investigateIncident()">Investigate</v-btn> -->
				<v-btn rounded outlined color="success" v-if="!readonly" @click="saveIncidentChanges">Save</v-btn>
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancelIncidentChanges">Go Back</v-btn>
				<v-btn rounded outlined color="error" v-else @click="cancelIncidentChanges">Cancel</v-btn>
			</v-card-actions>
		</v-card>
		<DeleteDialog v-if="deleteDialog" :currentObjectName="currName" :delMessage="deleteMessage"
			@DeleteDialogCallback='deleteObjectChildEvent'>
		</DeleteDialog>
	</div>
</template>

<script>
import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import TypeIcon from '@/components/templates/TypeIcon'
import SubtypeIcon from '@/components/templates/SubtypeIcon'
import LineChart from '@/components/chartjs/Line.vue'

export default {
    components: {
        Breadcrumb,
        DeleteDialog,
        TypeIcon,
        SubtypeIcon,
        LineChart
    },
    mixins: [
        Utils,
    ],
    data(){
        var alarmEventTableColumnsVueTable = [
           { 
               text: 'Id',
               value: 'alarmIdCode',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Site',
               value: 'siteName',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Type / Subtype',
               value: 'typeSubtype',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Source',
               value: 'componentNameIcon',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Severity',
               value: 'severityStatePri',
               sortable: true,
               filterable: true,
           },
/*
           {
               text: 'Status',
               value: 'status',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Short Description',
               value: 'shortDescription',
               sortable: true,
               filterable: true,
           },
*/
           {
               text: 'Details',
               value: 'description',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Creation Time',
               value: 'createdDateString',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Actions',
               value: 'actions',
               width: 120
           },
        ];

        var historyEvent_table_columns= [
           {
               text: 'HistoryId',
               value: 'incHistIdCode',
               direction: true,
               filterable: true,
           },
           {
               text: 'By',
               value: 'userDisplayName',
               direction: true,
               filterable: true,
           },
           {
               text: 'Comments',
               value: 'comment',
               direction: true,
               filterable: true,
           },
           {
               text: 'Attachment',
               value: 'attachment',
               direction: true,
               filterable: true,
           },
           {
               text: 'Change',
               value: 'changeString',
           },
           {
               text: 'Creation Time',
               value: 'createdDateString',
               direction: true,
               filterable: true,
           },
        ];

        var relatedIncidents_table_columns = [
           {
               text: 'RelIncId',
               value: 'incidentIdCode',
               direction: true,
               filterable: true,
           },
           {
               text: 'RelType',
               value: 'relationType',
               direction: true,
               filterable: true,
           },
           /*~~~
           { 
               text: 'Site',
               value: 'siteName',
               sortable: true,
               filterable: true,
           },
           { 
               text: 'Source',
               value: 'sourceName',
               sortable: true,
               filterable: true,
           },
           ~~~*/
           {
               text: 'Severity/State/Priority',
               value: 'severityStatePri',
               sortable: true,
               filterable: true,
           },
           { 
               text: 'Details',
               value:'description', 
               sortable: false,
               filterable: true,
           },
           /*~~~
           {
               text: 'Headline',
               value: 'headline',
               direction: true,
               filterable: true,
           },
           ~~~*/
           {
               text: 'State',
               value: 'state',
               direction: true,
               filterable: true,
           },
        ];

        return {
            readonly:true,
            currOrgId: null,
            incident_Id: null,
            isLoading: false,
            userdetails:{},
            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, {name:'Incidents', route: { name: "INCIDENTS" }}, {name:"Create/Edit", active:true} ],
            apiStatus: null,

            deleteDialog:false,
            currId:null,
            currName:null,
            activeTab: 0,

            // Incidents data
            incidentDetails: {
                // these params needed with some initial value as they are used in rendering
                state : "Closed",
                severity: "Info",
                priority: "Low",
                categoryType: "",
                categorySubType: ""
            },
            incidentExtDetails: {},
            priorities: [ "Low", "Medium", "High" ],
            
            selectedRootCauseIncidentIdCode: null,
            similarIncidents: [],           // Returned Inc list
            similarIncidentsList : [],      // Single string entries for display in dropdown list


            // Alarms related to Incident 
            alarmEventTableColumnsVueTable,
            alarmEvent_table_rows: [],
            alarmsDisassociated: [],

            // history incident related
            historyEvent_table_columns,
            historyEvent_table_rows: [],

            // related incidents
            relatedIncidents_table_columns,
            relatedIncidents_table_rows: [],

            users_table_rows:[],
            severityValues:[],
            stateValues:[],

            pagingOptions:this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
            //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable

            deleteMessage:null,

            // Event Trend chart
            trendChartData: null,
            trendChartOptions: {
                responsive: true, 
                maintainAspectRatio: false
            },
            trendChartStyle : {"max-height":"360px"},
            trendParamColors : ["#3F51B5", "#E91E63", "#9C27B0", "#009688", "#FF9800", "#4CAF50", "#FF5722", "#00695C", "#311B92"],
        };
    },
    watch: {
      pagingOptions: {
        handler () {
            //console.log("incident watch pagingOptions " + JSON.stringify(this.pagingOptions));
            this.getIncidentAlarmsList(this.incident_id);
            //options:pageReqInfo {"page":2,"itemsPerPage":10,"sortBy":[],"sortDesc":[],"groupBy":[],"groupDesc":[],"mustSort":false,"multiSort":false}
        },
        deep: false,
        immediate: false,
      },

    },
    methods: {
        isAlarmRaise(type){
            return(type=="Raise")
        },

        navigateToSite(targetOrgId, sId) {
            var readonly = 1
            this.$router.push({ name: "SITES_EDIT_TARGETORG", params: { siteId: sId, readonly: readonly, targetOrgId: targetOrgId } })
        },

        navigateToAlarm(almId) {
            var readonly = 1
            this.$router.push( { name: "ALARMS_EDIT", params: { id: almId, readonly: readonly}})
        },

        navigateToIncident(incId) {
            var readonly = 1
            this.$router.push({ name: "INCIDENTS_EDIT", params: { id: incId, readonly: readonly}})
        },

        buildFilterableColumns(objs) {
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                if ( obj._children ) this.buildFilterableColumns(obj._children);
                obj.componentIdTypeIcon = obj.componentIdType=="citApp" ? "💻" : "🏢";
                obj.componentNameIcon = obj.componentName; //CHECK: How to prefix with the obj.componentIcon ?
            })
        },

        alarmRowsSelected(event) {
            event
            //console.log("alarmRowsSelected 0 ", JSON.stringify(event));
            this.incidentDetails.alarmsDisassociated = this.alarmsDisassociated.map( a => a.alarmIdCode )
            //console.log("alarmRowsSelected 1 ", JSON.stringify(this.alarmsDisassociated));
            //console.log("alarmRowsSelected 2 ", JSON.stringify(this.incidentDetails.alarmsDisassociated));
        },

        submit () {
            //this.$v.$touch()
            this.isLoading=true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            if (this.isEdit) {

                if (this.incidentDetails.state == 'Duplicate') {
                    if (this.selectedRootCauseIncidentIdCode != null) {
                        let selInc = this.similarIncidents.find(si => si.incidentIdCode == this.selectedRootCauseIncidentIdCode);
                        if (selInc != null) {
                            this.incidentDetails.duplicatedByIncidentId = selInc.incidentId;
                        }
                    }
                } else {
                    this.incidentDetails.duplicatedByIncidentId = null;
                }

                ApiService.put("/svcFault/api/v1/incident/" + this.incidentDetails.incidentId, this.incidentDetails , 
                    (err, result) => {
                    this.isLoading=false;
                    if (err){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        if ( this.alarmsDisassociated.length == 0)
                            this.cancelCreateDevice();
                    }
                }, query_params, "FAULT_SERVICE_URL");

                if ( this.alarmsDisassociated.length > 0 ){
                    this.isLoading=true;
                    var body = {alarmIds: this.alarmsDisassociated.map (a => a.alarmId) } 
                    //console.log("alarmsDisassociated ", JSON.stringify(body) );
                    ApiService.post("/svcFault/api/v1/alarm/disassociate", body, 
                            (err, result) => {
                        this.isLoading=false;
                        if (err){
                            this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                            this.utilsCheckLogout(this.apiStatus);
                        } else {
                            this.apiStatus = null;
                            this.cancelCreateDevice();
                        }
                    }, query_params, "FAULT_SERVICE_URL");
                }
            } else {// create
                ApiService.post("/svcFault/api/v1/incident/", this.incidentDetails, (err, result) => {
                    this.isLoading=false;
                    if (err){
                        this.apiStatus =
                        (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateDevice();
                    }
                }, query_params, "FAULT_SERVICE_URL");
            }
        },

        getIncidentAlarmsList(incidentId){
            //this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            if ( incidentId ) query_params.incidentId = incidentId;
            //if ( incidentId ) query_params.searchString = 'incidentId:+:' + incidentId;

            query_params.pageNum = this.pagingOptions.page;
            query_params.pageSize= this.pagingOptions.itemsPerPage;
            var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, {componentName: 'componentId'} )
            if ( sortCriteria ) query_params.sortCriteria = sortCriteria

            //ApiService.get("/svcFault/api/v1/alarm?shorten=true",
            ApiService.get("/svcFault/api/v1/alarm/page?shorten=true",
                (err, result) => {
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //this.totalPages = result.data.data.totalPages ;
                    this.utilsTotalEntriesAllPages = result.data.data.totalEntries ;
                    //console.log("getIncidentAlarmsList " + result.data.data.totalEntries + " " +
                                    //result.data.data.totalPages + " " + result.data.data.currentPage + " " );
                    this.alarmEvent_table_rows=result.data.data.docs ;
                    this.buildFilterableColumns(this.alarmEvent_table_rows);
                    //console.log("getIncidentAlarmsList " + this.alarmEvent_table_rows[0].type);
                    //console.log("getIncidentAlarmsList " + JSON.stringify(this.alarmEvent_table_rows));
                }

            }, query_params, "FAULT_SERVICE_URL")
        },

        editAlarmEventDetails(id, readonly) {
            this.$router.push( { name: "ALARMS_EDIT", params: { id: id, readonly: readonly}})
        },

        deleteAlarmEventDetails(Id, name) {
            this.deleteDialog = true;
            this.currId = Id;
            this.currName = name;
            this.deleteDialogOper = true;
        },

        deleteAlarmChildEvent(cancelFlag, isBackupDel) {
            cancelFlag, isBackupDel
            //console.log("** delete " + isBackupDel + cancelFlag);
            this.isLoading =true;

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

            ApiService.delete("/svcFault/api/v1/alarm/"+this.currId, 
                    (err, result) => {
                //console.log("**"+JSON.stringify(result))
                this.isLoading=false
                if ( err ){
                    this.apiStatus =
                        (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.getIncidentAlarmsList(this.incident_id);
                }
            }, query_params, "FAULT_SERVICE_URL")
        },

        deleteObjectChildEvent(cancelFlag, isBackupDel) {
            cancelFlag, isBackupDel
            //console.log("** delete " + isBackupDel);
            this.deleteDialog = false;
            if(cancelFlag == true) return;

            if(this.deleteDialogOper == true)
                this.deleteAlarmChildEvent(cancelFlag, isBackupDel); 
            else{
                //console.log("** linkUnlink " + isBackupDel + " link " + this.linkDialog);
            }
        },

        getIncidentEventDetails(incidentId) {
            this.isLoading = true;

            let query_params = {};
            if ( this.currOrgId ) 
                query_params.targetOrgId = this.currOrgId;
            query_params.shorten = "true"

            ApiService.get("/svcFault/api/v1/incident/extn/"+incidentId, (err, result) => {
                this.isLoading=false;
                if ( err ) {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.incidentExtDetails = result.data.data[0];
                    //this.alarmEvent_table_rows = result.data.data[0].alarmsInfo;
                    this.historyEvent_table_rows = result.data.data[0].incHist;
                    this.historyEvent_table_rows.sort(function compareHist(a, b) { return b.createdDate - a.createdDate } );

                    //No need to keep alarmsInfo,incHist hanging around and submit it back. It's causing max payload size exceeded error on the backend
                    delete this.incidentExtDetails.alarmsInfo // not needed.
                    delete this.incidentExtDetails.incHist // not needed.

                    this.historyEvent_table_rows.forEach((obj) => {
                        obj.userDisplayName = this.getUserDisplayName(obj.changeBy, obj.changeByName); 
                        obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                        obj.changeString = "";
                        obj.duplicateIncs = []
                        if ( obj.change )
                            obj.change.forEach((change) => {
                                if(change.currAssignedTo) 
                                    change.currAssignedToName = this.getUserDisplayName(change.currAssignedTo, change.currAssignedToName); 

                                if(change.prevAssignedTo) 
                                    change.prevAssignedToName = this.getUserDisplayName(change.prevAssignedTo, change.prevAssignedToName); 

                                obj.changeString += change.entity + ": " 
                                    + ((change.prevState) ? change.prevState : "" ) 
                                    + ((change.prevSeverity) ? change.prevSeverity : "" )
                                    + ((change.prevAssignedToName) ? change.prevAssignedToName : "" )
                                    /*~~~ NOT_WORKING ~~~
                                    + ((change.duplicateIncidentId) ?
                                        "<a class='link-text' v-on:click=navigateToIncident(" + change.duplicateIncidentId + ")> " + change.duplicateIncidentIdCode + "</a>" : "")
                                    ~~~~~~~~~~~~~~~*/
                                    /*+ ((change.duplicateIncidentIdCode) ? change.duplicateIncidentIdCode : "")*/
                                    + ((change.prevState || 
                                        change.prevSeverity || 
                                        change.prevAssignedToName
                                        //|| change.duplicateIncidentIdCode
                                        ) ?  "->" : ""
)

                                    + ((change.currState) ? change.currState : "" )  
                                    + ((change.currSeverity) ? change.currSeverity  : "" ) 
                                    + ((change.currAssignedToName) ? change.currAssignedToName : "" ) 
                                    /*+ ((change.rootCauseIncidentIdCode) ? change.rootCauseIncidentIdCode : "")*/
                                    + ',\n';

                                 if (change.duplicateIncidentIdCode && change.rootCauseIncidentIdCode)
                                    obj.duplicateIncs.push({fromIdCode: change.duplicateIncidentIdCode, fromId: change.duplicateIncidentId,
                                                            toIdCode: change.rootCauseIncidentIdCode, toId: change.rootCauseIncidentId})
                            });
                    });
                    this.alarmEvent_table_rows.forEach((obj) => {
                        obj.createdDateString = this.utilsFormatDateTime2(obj.createdDate);
                    });

                    this.incidentDetails = this.incidentExtDetails
                    this.incidentDetails.createdDateString = this.utilsFormatDateTime(this.incidentDetails.createdDate);

                    this.getSimilarIncidents(this.incidentDetails);

                    //console.log("getIncidentEventDetails: " + JSON.stringify(this.incidentDetails));
                    //console.log("getAlarmEventStatusList " + JSON.stringify(result.data.data));
                }
            }, query_params, "FAULT_SERVICE_URL");
        },

        //--- get alarms trend info, for the Incident -------------
        getMLAlarmParamsTrend(incidentId) {
            //this.isLoading = true;

            let query_params = {};
            if ( this.currOrgId ) 
                query_params.targetOrgId = this.currOrgId;

            var body = {searchCriteria: {incidentId: incidentId}};
            ApiService.post("/svcFault/api/v1/alarm/ml/trend", body, (err, result) => {
                this.isLoading = false;
                if ( err ) {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //DEBUG: 
                    console.log("res = " + JSON.stringify(result.data.data).substring(1, 900));
                    this.trendChartData = result.data.data;
                }

            }, query_params, "FAULT_SERVICE_URL")

            //TODO: Move this to init
        },

        // Get List of Incidents 'associated' (Duplicated) to 'this' Incident
        getRelatedIncidents(incidentId)  {
            this.isLoading = true;

            let query_params = {};
            if ( this.currOrgId ) 
                query_params.targetOrgId = this.currOrgId;
            query_params.shorten = "true"

            ApiService.get("/svcFault/api/v1/incident/related/"+incidentId, (err, result) => {
                this.isLoading = false;

                if ( err ) {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    console.log("result.data.data = " + JSON.stringify(result.data.data));
                    this.relatedIncidents_table_rows = [];
                    result.data.data;

                    result.data.data.forEach((obj) => {
                        if (obj.incidentId != incidentId) {
                            obj.componentIdTypeIcon = obj.componentIdType=="citApp" ? "💻" : "🏢";
                            obj.componentIconImg = (obj.iconImg) ? obj.iconImg : null;
                            obj.sourceIcon = (obj.componentIcon)  ? obj.componentIcon : null;
                            obj.sourceName = (obj.componentName) ? obj.componentName : null;

                             
                            if (obj.duplicatedByIncidentId) {
                                obj.relationType = "Duplicated";
                            } else {
                                obj.relationType = "RootCause";
                                this.incidentDetails.rootCauseIncId = obj.incidentId;
                                this.incidentDetails.rootCauseIncIdCode = obj.incidentIdCode;
                            }
                            obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                            obj.modifiedDateString = this.utilsFormatDateTime(obj.modifiedDate);

                            this.relatedIncidents_table_rows.push(obj);
                        }
                    });
                }

            }, query_params, "FAULT_SERVICE_URL");

        },

        // Get list of Incidents similar to 'this' Incident for potential duplication
        getSimilarIncidents(incDetails)  {
            this.isLoading = true;

            let query_params = {};
            if ( this.currOrgId )
                query_params.targetOrgId = this.currOrgId;
            query_params.shorten = "true"

            //DEBUG: console.log("incDetails = " + JSON.stringify(incDetails));
            let body = {"inc": {
                orgId       : incDetails.orgId,
                siteId      : incDetails.siteId,
                incidentId  : incDetails.incidentId,
                createdDate : incDetails.createdDate,
                modifiedDate: incDetails.modifiedDate
            }};

            ApiService.post("/svcFault/api/v1/incident/similar/incidents", body, (err, result) => {
                this.isLoading = false;

                if ( err ) {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    console.log("result.data.data (similarInc) = " + JSON.stringify(result.data.data));
                    this.similarIncidents = result.data.data;
                    let n = this.similarIncidents.length;

                    this.similarIncidentsList = [];
                    for (let i=0; i<n; i++) {
                        if (this.similarIncidents[i].incidentId != incDetails.incidentId) {
                            let siEntry = this.similarIncidents[i].incidentIdCode || 
                                            this.similarIncidents[i].incidentId;
                            this.similarIncidentsList.push(siEntry);
                        }
                    }
                }

            }, query_params, "FAULT_SERVICE_URL");

        },
       
        investigateIncident() {
            //DEBUG: console.log("Open Investigation Room for : incidentDetails = " +JSON.stringify(this.incidentDetails));
            this.$router.push({ name: "INCIDENT_INVESTIGATE", params: { inc: this.incidentDetails}});
        },

        cancelIncidentChanges() {
            //console.log("cancelIncidentChanges called");
            this.$router.go(-1)
        },

        saveIncidentChanges() {
            //console.log("saveIncidentChanges called");
            this.submit ();
            this.cancelIncidentChanges();
        },

        getUsersList(){
            //this.isLoading=true
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/usersvc/api/v1/users", (err, result) => {
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.users_table_rows=result.data.data
                    //this.buildFilterableColumns(this.users_table_rows);
                    this.users_table_rows.forEach((obj) => {
                        obj.displayName = obj.firstName + " "  + obj.lastName;
                    });
                    //console.log("Users " + JSON.stringify(this.users_table_rows));
                }
            }, query_params)
        },
        getUserDisplayName(userId, userName) {
            var name = "Unknown";
            if (userName) {
                name = userName;
            } else if (userId) {
                name = userId;
            }

            return name; 
        },

        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },

        processBreadcrumbMessage(selectedOrgId) {
            this.currOrgId = selectedOrgId;
            if (this.incident_id) {
                // Edit can't be done when org is changed, so g back
                //this.cancelIncidentChanges();
                this.getIncidentEventDetails(this.incident_id);
                this.getIncidentAlarmsList(this.incident_id);
                this.getRelatedIncidents(this.incident_id);
            }
            //else{
             //   this.getUsersList();
            //}
        },
    },

    beforeRouteUpdate(to, from, next) { // Call the API query method when the URL changes
        this.incident_id  = to.params.id;
        this.processBreadcrumbMessage(this.currOrgId)
        this.activeTab  = 0;
        next()
    },

    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.incident_id = this.$route.params.id; 
        this.readonly = (this.$route.params.readonly == "1");

        this.isEdit = true;

        this.severityValues = Object.values(this.UtilsModaConstants.FaultService.Severity).filter(v => typeof(v) == 'string');
        this.stateValues = Object.values(this.UtilsModaConstants.Incident.State).filter(v => typeof(v) == 'string');

        this.get_auth_details();

        if (this.incident_id != "" && this.incident_id !=undefined) {
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
            this.getUsersList();
            this.getIncidentEventDetails(this.incident_id);
            this.getRelatedIncidents(this.incident_id);
            //Alarms tab is not even created at this point until it's clicked. so, we'll get pagingOptions undefined variable errors here
            //this.getIncidentAlarmsList(this.incident_id);

            this.getMLAlarmParamsTrend(this.incident_id);
        }
    }
}
</script>
