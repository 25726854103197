import axios from 'axios'
import { globalAPIUrlStore } from '@/main'

const ExtSysService = {

	getConnectWiseProfile(payload, query_params) {
		let headers = this.getHeaders();
		//const params = new URLSearchParams(query_params).toString();
		//return axios.post(`${globalAPIUrlStore['EXTSYS_SERVICE_URL']}/extSys/api/v1/extSys/cw/profile/search${params}`,
		//	payload,
		//{ headers, withCredentials: true, params: query_params });		// OR add params here ? 

		return axios.post(`${globalAPIUrlStore['EXTSYS_SERVICE_URL']}/svcExtSys/api/v1/extSys/cw/profile/search`,
			payload,
			{ headers, withCredentials: true, params: query_params });
	},
	//Ex: http://localhost:3660/svcExtSys/api/v1/extSys/cw/profile/search?targetOrgId=orgobj.1420

	syncWithConnectWiseNow(query_params) {
		let headers = this.getHeaders();
		return axios.post(`${globalAPIUrlStore['EXTSYS_SERVICE_URL']}/svcExtSys/api/v1/extSys/cw/sync/config`,
			{},
			{ headers, withCredentials: true, params: query_params });
	},

	createConnectWiseProfile(payload, query_params) {
		let headers = this.getHeaders();
		return axios.post(`${globalAPIUrlStore['EXTSYS_SERVICE_URL']}/svcExtSys/api/v1/extSys/cw/profile`,
			payload,
			{ headers, withCredentials: true, params: query_params });
	},

	updateConnectWiseProfile(profile_id, payload, query_params) {
		let headers = this.getHeaders();
		return axios.put(`${globalAPIUrlStore['EXTSYS_SERVICE_URL']}/svcExtSys/api/v1/extSys/cw/profile/${profile_id}`,
			payload,
			{ headers, withCredentials: true, params: query_params });
/*
        axios.put(url,postObj,{headers, params:query_params, withCredentials:true}).then(result  => {
            callback(null, result) 
        }).catch(error => {
            //console.log(error)
            callback(error, error.response)
        })
*/
	},

	deleteConnectWiseProfile(profile_id, query_params) {
		let headers = this.getHeaders();
		return axios.delete(`${globalAPIUrlStore['EXTSYS_SERVICE_URL']}/svcExtSys/api/v1/extSys/cw/profile/${profile_id}`,
			{ headers, withCredentials: true, params: query_params });
	},

	getHeaders() {
		return localStorage.getItem('MODA_Authorization') ?
			{ "MODA_Authorization": localStorage.getItem('MODA_Authorization') } : {};
	}
}

export default ExtSysService;
