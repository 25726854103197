import axios from 'axios'
import { globalAPIUrlStore } from '@/main'

const DashboardService = {

	getNetworkAnalytics(orgId, data) {
		let headers = this.getHeaders();
		const params = new URLSearchParams({targetOrgId: orgId}).toString();
		return axios.post(`${globalAPIUrlStore['STATS_SERVICE_URL']}/svcStats/api/v1/network/aggregated?${params}`,
		data,
		{ headers, withCredentials: true })
	},
	getDeviceAnalytics(orgId, data) {
		let headers = this.getHeaders();
		const params = new URLSearchParams({targetOrgId: orgId}).toString();
		return axios.post(`${globalAPIUrlStore['STATS_SERVICE_URL']}/svcStats/api/v1/agentDevice/aggregated?${params}`,
		data,
		{ headers, withCredentials: true })
	},
	getSites(orgId, data) {
		let headers = this.getHeaders();
		const params = new URLSearchParams({targetOrgId: orgId}).toString();
		return axios.post(`${globalAPIUrlStore['API_SERVICE_URL']}/api/v1/site/minimum?${params}`,
		data,
		{ headers, withCredentials: true })
	},
	getDevices(orgId, data) {
		let headers = this.getHeaders();
		const params = new URLSearchParams({targetOrgId: orgId }).toString();
		return axios.post(`${globalAPIUrlStore['API_SERVICE_URL']}/api/v1/device/minimum?${params}`,
		data,
		{ headers, withCredentials: true })
	},
	getApplications(orgId, data) {
		let headers = this.getHeaders();
		const params = new URLSearchParams({targetOrgId: orgId, appType: "ApplicationMonitoring"}).toString();
		return axios.post(`${globalAPIUrlStore['API_SERVICE_URL']}/api/v1/application/minimum?${params}`,
		data,
		{ headers, withCredentials: true })
	},
	getIncidents(orgId, data) {
		let headers = this.getHeaders();
		const params = new URLSearchParams({targetOrgId: orgId}).toString();
		return axios.post(`${globalAPIUrlStore['FAULT_SERVICE_URL']}/svcFault/api/v1/incident/bucketized?${params}`,
		data,
		{ headers, withCredentials: true })
	},
	getHeaders() {
		return localStorage.getItem('MODA_Authorization') ?
			{ "MODA_Authorization": localStorage.getItem('MODA_Authorization') } : {};
	}
}

export default DashboardService;
