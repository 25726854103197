
<!------ !!!!!! NOT USED !!!! --->

<template>
    <div>

        <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage"/>

        <!-- begin page-header -->
        <h1 class="page-header">Devices</h1>

        <!-- !!! Some of the params and functions used here are defined in AdsTreeTableUtils.vue mixin !!!!  -->
        <!-- !!! When async functionality implemented for this table, callRows, callChildren, callTempRows routines need to be
                 brought back to this file !!!!! -->
        <v-row>
            <!-- TBD: replace this to TableFilter component -->
            <v-text-field v-model="filterValueSearch" hide-details prepend-icon="fas fa-search"
                single-line @input="utilsFilterChanged" />
        </v-row>

        <v-divider/>

        <v-overlay :value="isLoading"> <v-progress-circular indeterminate size="64" ></v-progress-circular> </v-overlay>

        <v-data-table dense :headers="monDev_table_vuetify_columns" :items="monDev_table_rows"
            :options.sync="pagingOptions" :server-items-length="utilsTotalEntriesAllPages" multi-sort
            :footer-props="{'items-per-page-options':[5, 10, 15, 25, 100]}" item-key="monDeviceId" @click:row="navigateToIncidents">

            <template v-slot:item.monDevName="{ item }">
                <div v-if="item.highestSeverity" :class="utilsSeverityColors[item.highestSeverity].colorClass">
                    {{item.monDevName}}
                </div>
                <div v-else :class="utilsSeverityColors[UtilsModaConstants.FaultService.Severity.INFO].colorClass">
                    {{item.monDevName}} </div>
            </template>
            <template v-slot:item.highestSeverity="{ item }">
                <div v-if="item.highestSeverity" :class="utilsSeverityColors[item.highestSeverity].colorClass">
                    {{item.highestSeverity}}
                </div>
            </template>
            <template v-slot:item.incCount="{ item }">
                <a v-if="item.incCount" href="javascript:;" :title="item.incCount"
                    v-on:click="navigateToIncidents(item)" > {{ item.incCount }} </a>
                <div v-else> {{ item.incCount }} </div>
            </template>

        </v-data-table>

        <p class="help is-danger text-red" v-show="apiStatus" v-text="apiStatus"></p>

    </div>
</template>

<script>
import 'vue-loading-overlay/dist/vue-loading.css';
import ApiService from '../../services/api.service'
import auth from '../../services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from './Utils.vue'


export default {
    components: {
        Breadcrumb,
    },
    mixins: [
        Utils,
    ],
    data(){
        var monDev_table_vuetify_columns = [
           {
               text: 'Name',
               value: 'monDevName',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Incidents',
               value: 'incCount',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Highest Severity',
               value: 'highestSeverity',
               sortable: true,
               filterable: true,
           },
           {
               text: 'Status',
               value: 'status',
               sortable: true,
               filterable: true,
           },
        ]
        return {
            currOrgId: null,
            monDev_table_vuetify_columns,
            monDev_table_rows: [],
            pagingOptions:this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
            //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable
            isLoading: false,
            isShow:'display-none',
            parent_pages:[ {name:'Home'}, { name: "Monitoring" }, { name: "Devices" },
                            {name:'Devices', active:true} ],
            userdetails:null,
            apiStatus: null,
        };
    },
    watch: {
      pagingOptions: {
        handler () {
            this.getMonitoredDevicesStatusList();
        },
        deep: false,
        immediate: false,
      },

    },
    methods: {
        navigateToIncidents(row) {
            //console.log("navigateToIncidents monDevRowSelected ", JSON.stringify(row));
            var monDevIds = [], monDevNames=[], states = [], advSearchString = "";
            monDevIds = [row.monDevId]
            monDevNames = [row.monDevName]
            states = this.utilsOpenStates;
            advSearchString = "Open incs. in " + row.monDevName;

            var query = [];
            if (monDevIds.length && monDevNames.length) {
                //query.push({ name: "monDevId", value: monDevIds });
                query.push({name: "component", value: {
                        componentIdType: this.UtilsModaConstants.FaultService.ComponentIdType.MONITORED_DEVICE,
                        componentIdList: monDevIds,
                        componentNameList: monDevNames
                    }
                });
            }
            /*~~~ OLD ~~~
            if (monDevIds.length) {
                query.push({ name: "monDevId", value: monDevIds });
                query.push({ name: "componentId", value: monDevIds });
            }
            if (monDevNames.length) {
                query.push({ name: "monDevName", value: monDevNames });
                query.push({ name: "componentName", value: monDevNames });
            }
            ~~~~~~~~~~~~~*/

            if (states.length) query.push({ name: "state", value: states });
            console.log("navigateToIncidents query ", JSON.stringify(query));
            query.length
                ? this.$router.push({ name: "INCIDENTS", query: query,
                                        params: { advSearchString: advSearchString }, })
                : this.$router.push({ name: "INCIDENTS" });
        },

        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
        buildUIData(objs){
            objs.forEach((obj) => {
                //obj.status = obj.incCount ? this.UtilsModaConstants.DeviceLiveStatus.FAULTY
                                             //this.UtilsModaConstants.DeviceLiveStatus.ONLINE;
                obj
            })
            return objs
        },
        getMonitoredDevicesStatusList(){
            let query_params = {}, body={};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            if ( this.filterValueSearch ) query_params.searchString= "any:*:" + this.filterValueSearch
            query_params.pageNum = this.pagingOptions.page;
            query_params.pageSize = this.pagingOptions.itemsPerPage;
            var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, {billingCodesJoined: 'billingCode'} )
            if ( sortCriteria ) query_params.sortCriteria = sortCriteria

            if ( this.advSearchCriteria ) {
                console.log("getMonitoredDevicesStatusList advSearchCriteria ", JSON.stringify(this.advSearchCriteria))
                var advSearchCriteria = []
                Object.keys(this.advSearchCriteria).forEach( (k) => advSearchCriteria.push(this.advSearchCriteria[k]) )
                if ( advSearchCriteria.length ) body.advSearchCriteria = advSearchCriteria
                console.log("getMonitoredDevicesStatusList body ", JSON.stringify(body))
                // TBD: change below as POST request
            }

            this.isLoading=true

            query_params.summaryDataType = this.UtilsModaConstants.FaultService.Summary.DataType.MONITORED_DEVICES,
            query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.LISTS

            ApiService.get( "/svcSummary/api/v1/summary", (err, result) => {      //### svcFault
                this.isLoading = false;
                if (err) {
                    this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
                    this.utilsCheckLogout(this.apiStatus);
                }else {
                    this.apiStatus = null;
/*
                    var s = result.data.data.summaryData;
                    //console.log("**** getMonitoredDevicesStatusList monDevs s = " + JSON.stringify(s, null, 2))

                    this.monDev_table_rows = []
                    this.monDev_table_rows = this.monDev_table_rows.concat(s.list.okList)
                    this.monDev_table_rows = this.monDev_table_rows.concat(s.list.faultedList)
*/
                    this.monDev_table_rows = result.data.data
                    this.monDev_table_rows = this.buildUIData(this.monDev_table_rows)
                    this.utilsTotalEntriesAllPages = this.monDev_table_rows.length // TBD

                    //console.log("**** getMonitoredDevicesStatusList monDevs 2 = " + JSON.stringify(this.monDev_table_rows, null, 2))
                }
                }, query_params, "SUMMARY_SERVICE_URL"     //###"FAULT_SERVICE_URL"
            );

        },

        get_auth_details(){
            let title = this.$route.meta.title;
            //console.log(title+'**000000*'+monDeviceId)
            this.isShow = auth.AuthService.get_usr_auth_details(title)
            //console.log("get_auth_details " + this.isShow);
        },

        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.getMonitoredDevicesStatusList();
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        if ( this.$route.query && Object.keys(this.$route.query).length /* query type is coming as object */ )
            this.advSearchCriteria = this.$route.query
        this.userdetails =  auth.AuthService.get_usrdetails(); 
        this.utilsDataRefreshFunc = this.getMonitoredDevicesStatusList;
        this.get_auth_details();
        //this.getMonitoredDevicesStatusList(); // will be called on pagingOptions change watcher
    }
}
</script>


<!------ !!!!!! NOT USED !!!! --->
