<template>
  <div class="content-wrapper dashboard">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />

    <v-container fluid v-show="!isMSPOrg()">
      <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
        {{ apiStatus }}
      </v-alert>
      <v-row>

        <v-col cols="12" class="px-0">
          <v-card :elevation="2" class="tile-box">
            <v-card-title class="pb-0">
              Incidents
            </v-card-title>
            <v-card-text class="pt-2">
              <v-row>
                <v-col sm="6" md="3" cols="12" v-for="level in incidentsSummary.levels" :key="level.severity">
                  <v-alert small @click="incidentCountClicked(level.severity)" text class="link-alert mb-0" prominent
                    :color="level.colorName">
                    <div class="text-center text-h2" :class="level.count ? ' font-weight-regular' : ''">
                      {{ level.count }}
                    </div>
                    <h3 class="text-center">
                      {{ level.severity }}
                    </h3>
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col sm="6" md="3" class="px-0">
          <v-card :elevation="2" class="tile-box">
            <v-card-title class="pb-0">
              Organizations
            </v-card-title>
            <v-card-text>
              <DoughnutChart :chartData="orgChartData" :chartOptions="orgChartOptions" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="3" class="px-0">
          <v-card :elevation="2" class="tile-box">
            <v-card-title class="pb-0">
              Sites
            </v-card-title>
            <v-card-text>
              <DoughnutChart :chartData="siteChartData" :chartOptions="siteChartOptions" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="3" class="px-0">
          <v-card :elevation="2" class="tile-box">
            <v-card-title class="pb-0">
              MODA Agents
            </v-card-title>
            <v-card-text>
              <DoughnutChart :chartData="agentChartData" :chartOptions="agentChartOptions" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="3" class="px-0">
          <v-card :elevation="2" class="tile-box">
            <v-card-title class="pb-0">
              Devices
            </v-card-title>
            <v-card-text>
              <DoughnutChart :chartData="mondevChartData" :chartOptions="mondevChartOptions" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col md="6" cols="12" class="px-0">
          <v-card :elevation="2" class="tile-box">
            <v-card-title class="pb-0">
              Sites
            </v-card-title>
            <v-card-text class="pt-2 pb-0 px-0 overflow-hidden">
              <GmapMap ref="mapRef" :center="mapCenter" map-type-id="roadmap" :style="mapStyle"
                :options="GmapMapOptions" @zoom_changed="mapChartMoved" @dragend="mapChartMoved">
                <GmapMarker v-for="(m, index) in sitesSummary_map_data2" :key="index" :ref="`marker${index}`"
                  :title="m.title" :position="m.position" :clickable="true" :draggable="false" :icon="m.markerOptions"
                  @click="mapChartSelected" />
              </GmapMap>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" class="px-0" v-if="applicationsList.length">
          <v-card :elevation="2" class="tile-box">
            <v-card-title class="pb-0">
              Applications
            </v-card-title>
            <v-card-text class="pt-2">
              <v-row justify="center" class="ma-1 pa-1">
                <!-- Faulty apps -->
                <v-col cols="12">
                  <v-badge v-for="app in applicationsList" :key="app.appId" @click.native="appIconClicked(app)"
                    :title="`${app.openIncidentsTotal} Incidents`" :color="app.colorName"
                    :content="app.openIncidentsTotal" class="dashboard-app-icon" :offset-x="24" :offset-y="8">
                    <v-avatar v-on:click="appIconClicked(app)" size="64">
                      <img :title="app.appName" :src="require('../../../public/assets/img/moda/' + app.appIcon)">
                    </v-avatar>
                  </v-badge>

                  <!-- OK app -->
                  <div :title="`${applicationsSummary.counts.ok} More apps OK`" class="more-apps-icon"
                    v-if="applicationsSummary.counts.ok" v-on:click="appIconClicked">
                    <v-avatar color="blue" size="64">
                    </v-avatar>
                    <v-avatar color="primary" size="64">
                    </v-avatar>
                    <v-avatar color="indigo" size="64">
                      <v-icon dark>
                        mdi-apps
                      </v-icon>
                    </v-avatar>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- conditional rendering v-if, v-else is causing GChart and GmapMap parent dependency errors. So, use v-show so
         all conditional elements are just hidden/displayed -->
    <v-container v-show="isMSPOrg()" fluid>

      <v-row>
        <v-col class="px-0">
          <v-card :elevation="2" class="tile-box">
            <v-card-title class="pb-0">
              Organizations
            </v-card-title>
            <v-card-text class="pt-2">
              <v-row class="md-content-stretch">
                <v-col md="auto" cols="12" v-for="(level, sevNum) in orgsSummary.slice(1)" :key="level[0]">
                  <v-alert @click="orgsPanelSelected(level[0])" text class="link-alert mb-0" prominent
                    :color="utilsGetSeverityColor(level[0]).colorName">
                    <div v-if="level[0] == UtilsModaConstants.FaultService.FaultStatus.GOOD" class="text-center"
                      :class="level[1] - 1 ? ' text-h2 font-weight-regular' : ' text-h2'">
                      <!-- for MSP org with zero sites of its own, don't count itself in panel -->
                      {{ level[1] - 1 }}
                    </div>
                    <div v-else class="text-center" :class="level[1] ? ' text-h2 font-weight-regular' : ' text-h2'">
                      {{ level[1] }}
                    </div>
                    <h3 class="text-center">
                      {{ level[0] }}
                    </h3>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="v-data-table">
                    <div class="v-data-table__wrapper">
                      <vue-ads-table :columns="org_table_columns" :rows="org_table_rows" class="grid-tree-view mb-5">
                        <template slot="sort-icon" slot-scope="props">
                          <v-icon small>{{ props.direction === null ? '' : (props.direction ? 'mdi-arrow-up' :
                              'mdi-arrow-down')
                          }} </v-icon>
                        </template>
                        <template slot="orgName" slot-scope="props">
                          <a href="javascript:;" :title="props.row.orgName" v-on:click="navigateToOrg(props.row.orgId)">
                            {{ props.row.orgName }}
                          </a>
                        </template>
                        <template slot="parentOrgName" slot-scope="props">
                          <a v-if="props.row.parentClickable" href="javascript:;" :title="props.row.parentOrgName"
                            v-on:click="navigateToOrg(props.row.parentOrgId)">
                            {{ props.row.parentOrgName }}
                          </a>
                          <span v-else> {{ props.row.parentOrgName }} </span>
                        </template>
                        <template slot="counts" slot-scope="props">
                          <a v-if="props.row.siteCount" href="javascript:;" title="Sites"
                            v-on:click="navigateToSites(props.row)">
                            {{ props.row.siteCount }}
                          </a>
                          <span v-else> {{ props.row.siteCount }} </span>
                          /
                          <a v-if="props.row.applicationCount" href="javascript:;" title="Apps"
                            v-on:click="navigateToApplications(props.row)">
                            {{ props.row.applicationCount }}
                          </a>
                          <span v-else> {{ props.row.applicationCount }} </span>
                          /
                          <span :key="countsKey1">
                            <a v-if="props.row.monDevCount" href="javascript:;" title="Devs"
                              v-on:click="navigateToMonitoredDevices(props.row)">
                              {{ props.row.monDevCount }}
                            </a>
                            <span v-else> {{ props.row.monDevCount }} </span>
                          </span>
                        </template>
                        <template slot="statuses" slot-scope="props">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" x-large
                                :color="utilsAdminStatusDisplayObject(props.row.adminStatus).colorName">
                                {{ utilsAdminStatusDisplayObject(props.row.adminStatus).icon }}
                              </v-icon>
                            </template>
                            <span>{{ utilsAdminStatusDisplayObject(props.row.adminStatus).text }}</span>
                          </v-tooltip>
                          <!-- key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully -->
                          <v-tooltip top :key="statusesKey1"
                            v-if="props.row.siteCount || props.row.applicationCount || props.row.monDevCount">
                            <!-- display icon (faulty/good) with highestSeverity color -->
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on"
                                :color="utilsGetSeverityColor(props.row.highestSeverity).colorName">
                                {{ utilsFaultStatusDisplayObject(props.row.faultStatus).icon }}
                              </v-icon>
                            </template>
                            <span>{{ props.row.highestSeverity ||
                                utilsFaultStatusDisplayObject(props.row.faultStatus).text
                            }}</span>
                          </v-tooltip>
                          <span v-if="props.row.incCount" :key="statusesKey2">
                            (<a href="javascript:;" title="# Incidents" v-on:click="navigateToIncidents(props.row)">{{
                                props.row.incCount
                            }}</a>)
                          </span>
                        </template>
                      </vue-ads-table>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import auth from "@/services/auth.service";
import AdsTreeTableUtils from "@/pages/moda/AdsTreeTableUtils.vue";
import { globalAPIUrlStore } from "@/main.js";
import ApiService from "@/services/api.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import Utils from "@/pages/moda/Utils";
import { gmapApi } from "gmap-vue";

//--- socket.io ---
import socketService from "@/services/socket.io.service";
import ModaConst from "@/services/ModaConstants.js";

import DoughnutChart from '@/components/chartjs/Doughnut.vue'

// abstracting out common part and using require(imgsUrl + filename) doesn't work with webpack !!!
//const imgsUrl = '../../../public/assets/img/moda/'
const GmapMapIcons = {
  "grey darken-1": require("../../../public/assets/img/moda/Map_marker_grey.svg"),
  green: require("../../../public/assets/img/moda/Map_marker_green.svg"),
  "green lighten-2": require("../../../public/assets/img/moda/Map_marker_green_lighten2.svg"),
  orange: require("../../../public/assets/img/moda/Map_marker_orange.svg"),
  "red lighten-2": require("../../../public/assets/img/moda/Map_marker_red_lighten2.svg"),
  red: require("../../../public/assets/img/moda/Map_marker_red.svg"),
  grey: require("../../../public/assets/img/moda/Map_marker_grey.svg"),
};

export default {
  name: "Summary",
  components: {
    Breadcrumb,
    DoughnutChart
  },
  mixins: [AdsTreeTableUtils, Utils],
  data() {
    var org_table_columns = [
      {
        title: "Name",
        property: "orgName",
        direction: true,
        filterable: true
      },
      {
        title: "Parent Org",
        property: "parentOrgName",
        direction: null,
        filterable: true,
      },
      {
        title: "Sites/Apps/Devices",
        property: "counts",
        direction: null,
        filterable: true,
      },
      {
        title: "Statuses (#Incidents)",
        property: "statuses",
        direction: null,
        filterable: true,
      },
    ];

    var baseUrl = "/assets/img/moda/";
    return {
      transactions: [
        {
          icon: "fas fa-arrow-down",
          color: "#ea0606",
          title: "Netflix",
          date: "27 March 2020, at 12:30 PM",
          value: "- $ 2,500",
          gradient: "text-danger",
        },
        {
          icon: "fas fa-arrow-up",
          color: "#82d616",
          title: "Apple",
          date: "23 March 2020, at 04:30 AM",
          value: "+ $ 2,000",
          gradient: "text-success",
        },
        {
          icon: "fas fa-arrow-up",
          color: "#82d616",
          title: "Partner #22213",
          date: "19 March 2020, at 02:50 AM",
          value: "+ $ 1,400",
          gradient: "text-success",
        },
      ],
      currOrgId: null,
      isLoading: false,

      // summary data
      incidentsSummary: {},

      mapSettings: {
        packages: ["map"],
        mapsApiKey: globalAPIUrlStore.googleMapAPIKey,
      },

      mapCenter: { lat: 37.8097343, lng: -95.5556199 },
      mapZoomLevel: 4,
      GmapMapOptions: {
        scrollwheel: true,
        streetViewControl: false,
        //disableDefaultUI: true,
        fullscreenControl: false,
        mapTypeControl: false,
      },
      GmapMapIcons,

      ChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: []
          }
        ]
      },
      ChartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 10,
              font: {
                size: 11,
                family: '"Open Sans", sans-serif'
              }
            }
          }
        },
      },
      ChartColors: ['#FF1744'/*Critical*/, '#E57373'/*Severe*/, '#FF9800'/*Major*/, '#81C784'/*Minor*/, '#4CAF50'/*Good*/],
      MonDevChartColors: ['#FF1744'/*Critical*/, '#E57373'/*Severe*/, '#FF9800'/*Major*/, '#81C784'/*Minor*/, '#4CAF50'/*Good*/, '#5c5c5c' /*Unknown*/],
      AgentChartColors: ['red' /*Faulty*/, '#FF9800' /*Offline*/, 'green' /*Online*/, '#5c5c5c' /*Unknown*/],
      orgChartData: {},
      siteChartData: {},
      agentChartData: {},
      mondevChartData: {},

      orgChartOptions: {},
      siteChartOptions: {},
      agentChartOptions: {},
      mondevChartOptions: {},

      cardHeight: 220,
      mapStyle: "width: 100%; height: 300px", // map height should be same as cardHeight

      statusesKey1: 0, // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
      statusesKey2: 1, // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
      countsKey1: 1000, // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
      orgMSP: false,
      orgsSummary: [],
      org_table_columns,
      //org_table_vuetify_columns,
      org_table_rows: [],
      pagingOptions: { page: 1, itemsPerPage: 10 }, // Data-table paging is 1 based

      numSites: 0,
      sitesSummary_table_rows: [],
      sitesSummary_map_data2: [],

      applicationsList: [],
      applicationsSummary: null,

      numAgentDevices: 0,
      agentDevicesSummary: [],

      fullPage: true,
      onCancel: true,
      height: 35,
      width: 35,
      apiStatus: null,
      isShow: "display-none",
      parent_pages: [{ name: "Home" }, { name: "Status Summary", active: true }],

      //---socket ---
      pageName: ModaConst.Socket.Page.SUMMARY,
      isConnected: false,
      isPageActivated: false,
      isPageVisible: true,
      socketMessage: "",
      eventData: null, // Data from a Socket event
    };
  },

  //=== Hooks (Refer to EventsAlarms.vue for usage of all hooks) ================
  created: function () {
    /* TBD Commenting out websockets for now until Kong issue is figured out
        // Connect to the Socket server on 'created' hook only
        let cSocket1 = socketService.setupSocketConnection(this.pageName, "FAULT_SERVICE_WS_URL", ModaConst.Service.FAULT);
        this.socketMessage = "Connected to the FaultServer";

        socketService.sendMessage(this.pageName, "in hook: created. Connected");

        // Refer: https://socket.io/docs/v4/client-socket-instance/
        cSocket1.on("connect", () => {
            console.log("Connected to the Socket server. socket.id = " + cSocket1.id);
            socketService.addSocketToActiveMap(this.pageName, cSocket1);

            socketService.sendEvent(this.pageName, "page", {pageName: this.pageName, orgId: this.currOrgId});

            this.attachSummarySocketMessageHandlers(this, cSocket1, ModaConst.Socket.Event.SUMMARY);
        });
        this.isConnected = true;
        this.socketMessage = "Connected to the FaultServer";
        socketService.sendMessage(this.pageName, "in hook: created. Connected");

        let cSocket2 = socketService.setupSocketConnection(this.pageName, "STATUS_SERVICE_WS_URL", ModaConst.Service.STATUS);

        // Refer: https://socket.io/docs/v4/client-socket-instance/
        cSocket2.on("connect", () => {
            console.log("Connected to the Socket server. socket.id = " + cSocket2.id);
            socketService.addSocketToActiveMap(this.pageName, cSocket2);

            socketService.sendEvent(this.pageName, "page", {pageName: this.pageName, orgId: this.currOrgId});

            this.attachStatusSocketMessageHandlers(this, cSocket2, ModaConst.Socket.Event.DEVICE_STATUS);
        });
*/
  },

  mounted: function () {
    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    //this.currOrgName = this.$refs.breadcrumb.getLastBreadcrumbOrgName();
    this.get_auth_details();

    this.initChartData();

    // if keep-alive is enabled at router level, comment out below three lines and uncomment in activated/deactivated
    this.setOrgCookie();
    this.keepRefreshing()

    //--- socket ---
    socketService.sendMessage(this.pageName, "in hook: mounted");
    document.addEventListener("visibilitychange", this.handleVisibility, false);

    //console.log("Dashboard mounted")
    this.refreshTimer = setInterval(this.keepRefreshing, 30000) // every 30 secs
  },

  //TRACE: beforeUpdate: function() { socketService.sendMessage(this.pageName, "in hook: beforeUpdate"); },
  //TRACE: updated: function() { socketService.sendMessage(this.pageName, "in hook: updated"); },
  updated() {
    this.expandAllRows();
    setTimeout(this.expandAllRows, 100);
    setTimeout(this.expandAllRows, 100);
  },
    // activated and deactivated are called only when KeepAlive flag used i.e. when Component doesn't get destroyed once it's created
  activated: function () {

    this.isPageActivated = true;
    socketService.sendMessage( this.pageName, "in hook: " + this.pageName + " activated");

    //console.log("Dashboard activated")
    this.refreshTimer = setInterval(this.keepRefreshing, 30000) // every 30 secs
  },
    // activated and deactivated are called only when KeepAlive flag used i.e. when Component doesn't get destroyed once it's created
  deactivated: function () {
    this.isPageActivated = false;
    socketService.sendMessage( this.pageName, "in hook: " + this.pageName + " deactivated");

    //console.log("Dashboard deactivated")
    if ( this.refreshTimer ) clearInterval(this.refreshTimer)
  },

  destroyed: function () {
    socketService.sendMessage( this.pageName, "in hook: destroyed. Disconnecting socket");

    // Disconnect from the Socket server on 'destroyed' hook only
    socketService.disconnectSocket(this.pageName);
    this.isConnected = false;

    //console.log("Dashboard destroyed")
    if ( this.refreshTimer ) clearInterval(this.refreshTimer)
  },
  //===========================

  watch: {
    currOrgsPage: {
      handler() {
        //console.log("currOrgsPage changed ", this.currOrgsPage, " num pages ", this.orgsTableNumPages);
        this.currOrgsPageChanged();
      },
      deep: false,
      immediate: false,
    },
  },
  computed: {
    // The below example is the same as writing as later one
    google() {
      var g = gmapApi();
      //this.googleLoaded(g);
      return g;
    },
    // The below example is the same as writing as above
    //google: gmapApi,
  },
  methods: {
    onScroll() {
      console.log("onScroll");
    },

    orgsPanelSelected(severity) {
      var query = [];
     // if selected segment is GOOD, add INFO, NOT_SET and null to filter
      if ( severity == this.UtilsModaConstants.FaultService.FaultStatus.GOOD) {
         query.push({ name: "highestSeverity", value: [this.UtilsModaConstants.FaultService.Severity.INFO,
                                                       this.UtilsModaConstants.FaultService.Severity.NOT_SET,
                                                       null]})
      }else
         query.push({ name: "highestSeverity", value: [severity] });
      console.log("orgsPanelSelected query ", JSON.stringify(query));
      query.length ? this.$router.push({ name: "ORGS", query: query}) : this.$router.push({ name: "ORGS" });
    },

    orgsChartSelected(e, d) {
      var severity = this.orgChartData.labels[d[0].index];
      var query = [];

     // if selected segment is GOOD, add INFO, NOT_SET and null to filter
      if ( severity == this.UtilsModaConstants.FaultService.FaultStatus.GOOD) {
         query.push({ name: "highestSeverity", value: [this.UtilsModaConstants.FaultService.Severity.INFO,
                                                       this.UtilsModaConstants.FaultService.Severity.NOT_SET,
                                                       null]})
      }else
         query.push({ name: "highestSeverity", value: [severity] });
      console.log("orgsChartSelected query ", JSON.stringify(query));
      query.length ? this.$router.push({ name: "ORGS", query: query}) : this.$router.push({ name: "ORGS" });

    },

    orgsChartReady(chart) {
      this.orgsChart = chart;
    },

    sitesChartSelected(e, d) {
      var query = [];
      var state = this.siteChartData.labels[d[0].index];

     // if selected segment is GOOD, add INFO, NOT_SET and null to filter
      if ( state == this.UtilsModaConstants.FaultService.FaultStatus.GOOD) {
            query.push({ name: "highestSeverity", value: [this.UtilsModaConstants.FaultService.Severity.INFO,
                                                          this.UtilsModaConstants.FaultService.Severity.NOT_SET,
                                                          null]})
      }else
            query.push({ name: "highestSeverity", value: [state] });
      //console.log("sitesChartSelected query ", JSON.stringify(query));
      query.length ? this.$router.push({ name: "SITES", query: query}) : this.$router.push({ name: "SITES" });

    },
    sitesChartReady(chart) {
      this.sitesChart = chart;
    },

    agentDevicesChartSelected(e, d) {
      var status = this.agentChartData.labels[d[0].index];
      var query = [];
      query.push({ name: "liveStatus", value: [status] });
      console.log("agentDevicesChartSelected query ", JSON.stringify(query));
      query.length ? this.$router.push({ name: "MODA_AGENTS", query: query}) : this.$router.push({ name: "MODA_AGENTS" });
    },
    agentDevicesChartReady(chart) {
      this.agentDevicesChart = chart;
    },

    monitoredDevicesChartSelected(e, d) {
        var status = this.mondevChartData.labels[d[0].index];
        var query = []

        // if selected segment is GOOD, add INFO, NOT_SET and null to filter
        if ( status == this.UtilsModaConstants.FaultService.FaultStatus.GOOD){
            query.push({ name: "highestSeverity", value: [this.UtilsModaConstants.FaultService.Severity.INFO,
                                                          this.UtilsModaConstants.FaultService.Severity.NOT_SET,
                                                          null]})
        }else
            query.push({ name: "highestSeverity", value: [status] });
        console.log("monitoredDevicesChartSelected query ", JSON.stringify(query));
        query.length ? this.$router.push({ name: "MONITORED_DEVICES", query: query}) : this.$router.push({ name: "MONITORED_DEVICES" });
    },
    monitoredDevicesChartReady(chart) {
      this.monitoredDevicesChart = chart;
    },

    mapChartSelected(event) {
      /* GMapMap */
      var siteSelected = this.sitesSummary_map_data2.find(
        (el) =>
          event.latLng.lat() == el.position.lat &&
          event.latLng.lng() == el.position.lng
      );
      //console.log("mapChartSelected ", JSON.stringify(siteSelected))
      if (siteSelected) {
        var query = [ {name: "site", value: { siteIdList: [siteSelected.siteId], siteNameList: [siteSelected.siteName] }}];
        //var advSearchString = "Incs. in " + siteSelected.siteName;
        this.$router.push({ name: "SITES", query: query });
      }
    },
    mapChartMoved() {
      let d = {
        center: this.$refs.mapRef.$mapObject.getCenter(),
        zoom: this.$refs.mapRef.$mapObject.getZoom(),
        bounds: this.$refs.mapRef.$mapObject.getBounds()
      };
      let cache = sessionStorage.getItem("gmapCache") ? JSON.parse(sessionStorage.getItem("gmapCache")) : {};
      cache[this.currOrgId] = d;
      sessionStorage.setItem("gmapCache", JSON.stringify(cache));
    },
    mapChartReady(chart) {
      this.mapChart = chart;
    },

    incidentCountClicked(severity) {
      var query = [{ name: "severity", value: [severity] }];
      query.push({ name: "state", value: this.utilsOpenStates });
      var advSearchString = severity + " incs.";
      this.$router.push({
        name: "INCIDENTS",
        query: query,
        params: { advSearchString: advSearchString },
      });
    },

    appIconClicked(arg) {
      console.log("appIconClicked arg " + JSON.stringify(arg))
      var query
      if (arg.appId) { // clicked on specific app
        var appIds = [arg.appId]
        var appNames = [arg.appName]
        query = [{ name: "application", value: { appIdList: appIds, appNameList: appNames } }];
      } else {
        appIds = this.applicationsList.filter((a) => a.appId)
        appNames = this.applicationsList.filter((a) => a.appName)
        query = [{
          name: "highestSeverity", value: [this.UtilsModaConstants.FaultService.Severity.INFO,
          this.UtilsModaConstants.FaultService.Severity.NOT_SET,
            null]
        }]
      }
      console.log("appIconClicked query " + JSON.stringify(query))
      this.$router.push({ name: "APPLICATIONS", query: query })
    },

    get_auth_details() {
      let title = this.$route.meta.title;
      this.isShow = auth.AuthService.get_usr_auth_details(title);
      this.userdetails =  auth.AuthService.get_usrdetails();
      this.loggedin_org = this.userdetails.orgInfo
    },
    createDummyApps(appsSummary) {
      var severities = [
        this.UtilsModaConstants.FaultService.Severity.CRITICAL,
        this.UtilsModaConstants.FaultService.Severity.SEVERE,
        this.UtilsModaConstants.FaultService.Severity.MAJOR,
        this.UtilsModaConstants.FaultService.Severity.MINOR,
        //this.UtilsModaConstants.FaultService.Severity.INFO, // map INFO, NOT_SET to GOOD
        this.UtilsModaConstants.FaultService.FaultStatus.GOOD,
      ];
      var apps = JSON.parse(JSON.stringify(appsSummary.apps));
      //console.log("apps 1 ", JSON.stringify(apps));
      apps.forEach((app, i) => {
        app.appSummarySeverity = severities[i % 5];
        app.totalOpenIncidents = i;
        app.appId = apps.length + i;
      });
      //console.log("apps 2 ", JSON.stringify(apps));
      appsSummary.apps = appsSummary.apps.concat(apps);
      //console.log("apps 3 ", JSON.stringify(appsSummary.apps));
    },

    setLevelsColorClasses(objs) {
      var counts = {};
      // order of these keys should match order in this.ChartColors
      counts[this.UtilsModaConstants.FaultService.Severity.CRITICAL] = 0;
      counts[this.UtilsModaConstants.FaultService.Severity.SEVERE] = 0;
      counts[this.UtilsModaConstants.FaultService.Severity.MAJOR] = 0;
      counts[this.UtilsModaConstants.FaultService.Severity.MINOR] = 0; // map INFO, NOT_SET to GOOD
      //counts[this.UtilsModaConstants.FaultService.Severity.INFO] = 0;
      counts[this.UtilsModaConstants.FaultService.FaultStatus.GOOD] = 0;
      objs.forEach((obj) => {
        // used by multiple summaryData. Take whichever is available
        var severity = obj.severity || obj.appSummarySeverity || obj.highestSeverity || obj.deviceLiveStatus;

/* - let's keep Minor in light green color
        // ignore severities MINOR and INFO
        if ( severity == this.UtilsModaConstants.FaultService.Severity .CRITICAL ||
             severity == this.UtilsModaConstants.FaultService.Severity.SEVERE ||
             severity == this.UtilsModaConstants.FaultService.Severity.MAJOR){
*/
            if (severity) {
                obj.colorClass = this.utilsSeverityColors[severity].colorClass;
                obj.colorRgb = this.utilsSeverityColors[severity].colorRgb;
                obj.colorName = this.utilsSeverityColors[severity].colorName;
            }
            // combine INFO and NOT_SET into GOOD
            if (severity == this.UtilsModaConstants.FaultService.Severity.INFO ||
                severity == this.UtilsModaConstants.FaultService.Severity.NOT_SET)
                severity = this.UtilsModaConstants.FaultService.FaultStatus.GOOD

            if (counts[severity] !== undefined) counts[severity]++;
/*
        }
*/
        if (obj.children) this.setLevelsColorClasses(obj.children);
      });
      return counts;
    },
    filterInObjsWithIncidents(objs) {
      //console.log("filterInObjsWithIncidents objs ", JSON.stringify(objs, null, 2));
      objs.forEach((obj) => {
        if (obj.children) obj.children = obj._children = this.filterInObjsWithIncidents( obj.children);
      });
      return objs.filter((obj) => {
          // uncomment for production, comment out later lines
          return obj.incCount || (obj.children && obj.children.length);
          //obj
          //return true
        })
        .sort((e1, e2) => {
          return e2.incCount - e1.incCount;
        }); // descending order;
    },

    googleLoaded(google) {
      let cache = sessionStorage.getItem("gmapCache") ? JSON.parse(sessionStorage.getItem("gmapCache"))[this.currOrgId] : null;
      if ( google && this.sitesSummary_map_data2 && this.sitesSummary_map_data2.length) {
        if(cache && this.$refs.mapRef.$mapObject){
          this.$refs.mapRef.$mapObject.setCenter(cache.center);
          this.$refs.mapRef.$mapObject.setZoom(cache.zoom);
        } else {
          var bounds = new google.maps.LatLngBounds();
          this.sitesSummary_map_data2.forEach((d) => bounds.extend(d.position));
          this.googleMap.fitBounds(bounds, {bottom:20, left:20, right:20, top:20});
        }
      }
    },
    buildSitesMapData(sites) {
      this.sitesSummary_map_data2 = [];
      //console.log("this.google ", this.google)
      //var bounds = new this.google.maps.LatLngBounds();

      sites.forEach((site) => {
        var severity = site.highestSeverity ? this.utilsSeverityColors[site.highestSeverity].colorName : "green";
        this.sitesSummary_map_data2.push({
          title: site.siteName + " (" + site.incCount + " incs.)",
          position: { lat: site.location.lat, lng: site.location.long },
          markerOptions: {
            url: GmapMapIcons[severity],
            //size: {width: 60, height: 90, f: 'px', b: 'px',},
            scaledSize: { width: 50, height: 50, f: "px", b: "px" },
          },
          siteId: site.siteId,
          siteName: site.siteName,
        });
        //bounds.extend( { lat: site.location.lat, lng: site.location.long } )
      }, this);
      this.googleLoaded(this.google);
      //if ( this.sitesSummary_map_data2.length ) {
      //this.googleMap.fitBounds(bounds, 0)
      //}
      //console.log("**** sites sitesSummary_map_data = " + JSON.stringify(this.sitesSummary_map_data, null, 2))
    },
    getFaultSummaryData() {
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      //this.isLoading=true

      var summaryDataTypes = [
        this.UtilsModaConstants.FaultService.Summary.DataType.INCIDENTS,
        this.UtilsModaConstants.FaultService.Summary.DataType.ORGS,
        this.UtilsModaConstants.FaultService.Summary.DataType.SITES,
        this.UtilsModaConstants.FaultService.Summary.DataType.MONITORED_DEVICES,
        this.UtilsModaConstants.FaultService.Summary.DataType.APPLICATIONS,
      ];

      summaryDataTypes.forEach((summaryDataType) => {
        query_params.summaryDataType = summaryDataType;
        query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.COUNTS

        ApiService.get( "/svcSummary/api/v1/summary", (err, result) => {    //### svcFault
            var counts;
            this.isLoading = false;
            if (err) {
              this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
              console.log("getFaultSummary failed " + JSON.stringify(err));
              this.utilsCheckLogout(this.apiStatus);
            }else {
              this.apiStatus = null;
              //DEBUG:
              if ( result.data.data.summaryDataType == this.UtilsModaConstants.FaultService.Summary.DataType.INCIDENTS) {
                //console.log("**** incidents result.data = " + JSON.stringify(result.data, null, 2))

                this.incidentsSummary.levels = result.data.data.summaryData.openIncidents.levels.filter( (l) =>
                                                    l.severity == this.UtilsModaConstants.FaultService.Severity .CRITICAL ||
                                                    l.severity == this.UtilsModaConstants.FaultService.Severity.SEVERE ||
                                                    l.severity == this.UtilsModaConstants.FaultService.Severity.MAJOR ||
                                                    l.severity == this.UtilsModaConstants.FaultService.Severity.MINOR);

                //DEBUG: console.log("=> incSumm = " + JSON.stringify(this.incidentsSummary));
                this.setLevelsColorClasses(this.incidentsSummary.levels);
              } else if ( result.data.data.summaryDataType == this.UtilsModaConstants.FaultService.Summary.DataType.APPLICATIONS) {
                //console.log("**** applications result.data = " + JSON.stringify(result.data, null, 2))
                this.applicationsSummary = result.data.data.summaryData;
                //console.log("**** applications this.applicationsSummary= " + JSON.stringify(this.applicationsSummary, null, 2))

                /* !!!! TBD comment these out in production !!!!
                            this.createDummyApps(this.applicationsSummary);
                            this.createDummyApps(this.applicationsSummary);
                            this.createDummyApps(this.applicationsSummary);
                            */

/* let's keep Minor in light green color
                this.applicationsList = this.applicationsSummary.list.faultedList.filter ( (a) =>
                                                    a.highestSeverity == this.UtilsModaConstants.FaultService.Severity.CRITICAL ||
                                                    a.highestSeverity == this.UtilsModaConstants.FaultService.Severity.SEVERE ||
                                                    a.highestSeverity == this.UtilsModaConstants.FaultService.Severity.MAJOR);
*/
                this.applicationsList = this.applicationsSummary.list.faultedList
                this.setLevelsColorClasses(this.applicationsList);
              } else if ( result.data.data.summaryDataType == this.UtilsModaConstants.FaultService.Summary.DataType.SITES) {
                var s = result.data.data.summaryData;
                //console.log("**** sites s = " + JSON.stringify(s, null, 2))

                this.faultedSitesList = s.list.faultedList;
                this.okSitesList = s.list.okList;
                this.unknownInfoSitesList = []; // s.list.unknownInfoList

                //TODO: If all sites needs to be displayed on the map, pass the merged list
                let allSitesList = s.list.okList.concat(s.list.faultedList);
                this.buildSitesMapData(allSitesList); // if you need to display all sites, call here

                this.sitesSummary_table_rows = this.filterInObjsWithIncidents(allSitesList);
                counts = this.setLevelsColorClasses( this.sitesSummary_table_rows);
                counts[this.UtilsModaConstants.FaultService.FaultStatus.GOOD] += this.okSitesList.length;

                this.siteChartData.labels = []
                this.siteChartData.datasets[0].data = []
                Object.keys(counts).forEach((key) => {
                  this.siteChartData.labels.push(key);
                  this.siteChartData.datasets[0].data.push(counts[key]);
                });

                //console.log(this.siteChartData);
                //console.log("**** sites summary = " + JSON.stringify(this.sitesSummary, null, 2))
                this.numSites = allSitesList.length

                //this.buildSitesMapData(this.sitesSummary_table_rows); // if you need to display only anomaly sites, call here
              } else if ( result.data.data.summaryDataType == this.UtilsModaConstants.FaultService.Summary.DataType.ORGS) {
                s = result.data.data.summaryData;
                //console.log("**** orgs s = " + JSON.stringify(s, null, 2))
                s.orgTree.children[0].orgMSP = this.orgMSP = (s.orgTree.children[0].children && s.orgTree.children[0].children.length > 0)
                this.orgsSummary = [
                  ["Status", "Count"],
                ];

                this.faultedOrgsList = s.list.faultedList;
                this.okOrgsList = s.list.okList;
                this.unknownInfoOrgsList = []; // s.list.unknownInfoList

                let org_table_s_rows = this.filterInObjsWithIncidents(s.orgTree.children);
                //this.orgsTableBuildAllFields(org_table_s_rows);
                //console.log("**** orgs org_table_s_rows = " + JSON.stringify(org_table_s_rows, null, 2))

                counts = this.setLevelsColorClasses(org_table_s_rows);
                counts[this.UtilsModaConstants.FaultService.FaultStatus.GOOD] += this.okOrgsList.length;

                // New method to calculate the Org severity counts ----------

                this.orgChartData.labels = []
                this.orgChartData.datasets[0].data = []
                for (let i=1; i<5; i++) {     // Sevs: Critical(1) to Minor(4) are only considered as faulted
                    this.orgsSummary.push([s.severityCounts[i].severity, s.severityCounts[i].count]);
                    this.orgChartData.labels.push(s.severityCounts[i].severity);
                    this.orgChartData.datasets[0].data.push(s.severityCounts[i].count);

                }
                let goodCount = s.severityCounts[5].count +   // Add any remaining (Sev 5-6) as Good. Change this as needed
                                s.severityCounts[6].count; 
                this.orgsSummary.push(["Good", goodCount]);

                this.orgChartData.labels.push("Good");
                this.orgChartData.datasets[0].data.push(s.severityCounts[5].count + s.severityCounts[6].count);

                //DEBUG: console.log("**** orgs summary = " + JSON.stringify(this.orgsSummary, null, 2))

                if ( this.orgMSP ) this.getOrganizationsList(this.currOrgId, s.orgTree.children[0].orgId)

              } else if ( result.data.data.summaryDataType == this.UtilsModaConstants.FaultService.Summary.DataType .MONITORED_DEVICES) {
                s = result.data.data.summaryData;
                //console.log("**** monitoredDevices s.list = ", s.list)
                // if reflectedFaultStatus is UNKNOWN, treat them as unknown
                this.unknownInfoMonDevsList = s.list.faultedList.filter ( (m) => m.reflectedFaultStatus ==
                                                                                 ModaConst.FaultService.FaultStatus.UNKNOWN )
                this.unknownInfoMonDevsList = this.unknownInfoMonDevsList.concat(s.list.okList.filter ( (m) => m.reflectedFaultStatus ==
                                                                                 ModaConst.FaultService.FaultStatus.UNKNOWN ))
                this.faultedMonDevsList = s.list.faultedList.filter ( (m) => m.reflectedFaultStatus !=
                                                                                 ModaConst.FaultService.FaultStatus.UNKNOWN );
                this.okMonDevsList = s.list.okList.filter ( (m) => m.reflectedFaultStatus !=
                                                                                 ModaConst.FaultService.FaultStatus.UNKNOWN );
                this.monitoredDevicesSummary_table_rows = this.filterInObjsWithIncidents(this.faultedMonDevsList);
                counts = this.setLevelsColorClasses( this.monitoredDevicesSummary_table_rows);
                counts[this.UtilsModaConstants.FaultService.FaultStatus.GOOD] += this.okMonDevsList.length;
                counts[ModaConst.FaultService.FaultStatus.UNKNOWN] = this.unknownInfoMonDevsList.length;

                //console.log("**** monitoredDevices faultList = ", this.faultedMonDevsList)
                //console.log("**** monitoredDevices okMonDevsList = ", this.okMonDevsList)
                this.mondevChartData.labels = []
                this.mondevChartData.datasets[0].data = []
                Object.keys(counts).forEach((key) => {
                  this.mondevChartData.labels.push(key);
                  this.mondevChartData.datasets[0].data.push(counts[key]);
                });
                //console.log("**** monitoredDevices counts = ", counts)
                //console.log("**** monitoredDevices summary = " + JSON.stringify(this.monitoredDevicesSummary, null, 2))
              }
            }
          }, query_params, "SUMMARY_SERVICE_URL"  //###"FAULT_SERVICE_URL"
        );
      }, this);
    },

    getAgentDevicesSummaryData() {
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      //this.isLoading=true

      var summaryDataTypes = [
                this.UtilsModaConstants.FaultService.Summary.DataType.AGENT_DEVICES,
            ];

      summaryDataTypes.forEach((summaryDataType) => {
        query_params.summaryDataType = summaryDataType;

        ApiService.get( "/svcSummary/api/v1/summary", (err, result) => {
            this.isLoading = false;
            if (err){
              this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
              console.log("getAgentDevicesSummary failed");
              this.utilsCheckLogout(this.apiStatus);
            }else {
              this.apiStatus = null;
              //DEBUG: console.log("**** agentDevicesSummary s = " + JSON.stringify(result, null, 2))
              var data = result.data.data;
              if ( data.summaryDataType == this.UtilsModaConstants.FaultService.Summary.DataType.AGENT_DEVICES) {
                var s = data.summaryData;
                //console.log("**** agentDevicesSummary s = " + JSON.stringify(s, null, 2))
                this.agentDevicesSummary = [
                  ["Status", "Count"],
                  [ModaConst.DeviceLiveStatus.FAULTY,   s.counts.faulted],
                  [ModaConst.DeviceLiveStatus.OFFLINE,  s.counts.offline],
                  [ModaConst.DeviceLiveStatus.ONLINE,   s.counts.online],
                  [ModaConst.DeviceLiveStatus.UNKNOWN,  s.counts.unknown],
                ];

                this.agentChartData.labels = [ModaConst.DeviceLiveStatus.FAULTY, ModaConst.DeviceLiveStatus.OFFLINE,
                                                ModaConst.DeviceLiveStatus.ONLINE, ModaConst.DeviceLiveStatus.UNKNOWN];
                this.agentChartData.datasets[0].data = [s.counts.faulted, s.counts.offline,s.counts.online, s.counts.unknown];

                this.faultedDevicesList = s.list.faultedList;
                this.offlineDevicesList = s.list.offlineList;
                this.onlineDevicesList = s.list.onlineList;
                this.unknownInfoDevicesList = s.list.unknownList;

                this.agentDevicesSummary_table_rows = s.list.offlineList.map( (d) => {
                    return { deviceId: d.deviceId, deviceName: d.deviceName, deviceLiveStatus: d.deviceLiveStatus, };
                  }
                );

                this.agentDevicesSummary_table_rows = this.agentDevicesSummary_table_rows.concat( s.list.faultedList.map((d) => {
                            return { deviceId: d.deviceId, deviceName: d.deviceName, deviceLiveStatus: this.utilsFaultyLabel, };
                    })
                );
                this.setLevelsColorClasses(this.agentDevicesSummary_table_rows);
                this.numAgentDevices = this.agentDevicesSummary_table_rows.length
              }
            }
          },
          query_params,
          //###"STATUS_SERVICE_URL"
          "SUMMARY_SERVICE_URL"
        );
      }, this);
    },


    isMSPOrg(){
        return this.orgMSP && this.numSites == 0 && this.numAgentDevices == 0
    },
    // copy and paste from Organization.vue
    expandAllRows() {
      document
        .querySelectorAll("i.vue-ads-pr-2.fa-plus-square")
        .forEach((e) => e.click());
    },

    navigateToOrg(currOrgId) {
      this.currOrgId = currOrgId;
      this.$refs.breadcrumb.navigateToOrg(currOrgId);
      this.setOrgCookie();
      this.keepRefreshing()
    },
    navigateToSites(row) {
      console.log("navigateToSites orgRowSelected ", JSON.stringify(row.orgId));
      // empty query is needed. Else, that component will use default query
      this.$router.push({ name: "SITES", query: [], params: { targetOrgId: row.orgId }, })
    },
    navigateToApplications(row) {
      console.log("navigateToApplications orgRowSelected ", JSON.stringify(row.orgId));
      let query = [ {name: "org", value : { orgNameList: [row.orgName] } } ]
      // empty query is needed. Else, that component will use default query
      this.$router.push({ name: "APPLICATIONS", query: query, params: { targetOrgId: row.orgId }, })
    },
    navigateToMonitoredDevices(row) {
      console.log("navigateToMonitoredDevices orgRowSelected ", JSON.stringify(row.orgId));
      // empty query is needed. Else, that component will use default query
      this.$router.push({ name: "MONITORED_DEVICES", query: [], params: { targetOrgId: row.orgId }, })
    },
    navigateToIncidents(row) {
      //console.log("navigateToIncidents orgRowSelected ", JSON.stringify(row));
      var query = [];
      var states = this.utilsOpenStates;
      query.push({ name: "state", value: states });
      console.log("navigateToIncidents query ", JSON.stringify(query));
      this.$router.push({ name: "INCIDENTS", query: query, params: { targetOrgId: row.orgId }, })
    },
    formatBillingCodes(rowObj) {
      return rowObj.billingCode ? rowObj.billingCode.join() : "";
    },
    // table filter functionality doesn't work unless it's rendering from native fields
    // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
    buildFilterableColumns(objs) {
      objs.forEach((obj) => {
        obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
        obj.billingCodesJoined = this.formatBillingCodes(obj);
        obj.parentClickable = ( obj.orgId == this.loggedin_org.orgId ) ? false : true
        if (obj._children) this.buildFilterableColumns(obj._children);
        obj.children = obj._children;
      });
    },
    collectOrgIds ( orgIds, orgs ){
        orgIds = orgIds.concat(orgs.map ( (org) => org.orgId ))
        orgs.forEach ( function(org){
            if ( org._children ) orgIds = this.collectOrgIds(orgIds, org._children)
        }, this)
        return orgIds
    },
    updateOrgStatus ( orgCfgList, orgStsList ){
        orgCfgList.forEach ( function (orgCfg) {
            let orgSts = orgStsList.find( (orgSts) => orgSts.orgId == orgCfg.orgId )
            if ( orgSts ) {
                orgCfg.faultStatus      = orgSts.faultStatus
                orgCfg.highestSeverity  = orgSts.highestSeverity
                orgCfg.incCount         = orgSts.incCount
            }
            if ( orgCfg._children ) this.updateOrgStatus (orgCfg._children, orgStsList)
        }, this)
    },
    updateMonDevCounts ( orgCfgList, monDevCounts ){
        orgCfgList.forEach ( function (orgCfg) {
            let c = monDevCounts.find( (m) => m.orgId == orgCfg.orgId )
            orgCfg.monDevCount = c ? c.monDevCount : 0
            if ( orgCfg._children ) this.updateMonDevCounts (orgCfg._children, monDevCounts)
        }, this)
    },
    getMonitoredDevicesCount(currOrgId = null) {
      let query_params = {}, body={};
      var targetOrgId = currOrgId ? currOrgId : this.currOrgId;
      query_params.targetOrgId = targetOrgId;

      //this.isLoading=true

      body.orgIds = this.collectOrgIds([], this.org_table_rows)

      //console.log("getMonitoredDevicesCount body " + JSON.stringify(body, null, 2))

      ApiService.post( "/svcInventory/api/v1/monitoredDevice/counts", body, (err, result) => {
          this.isLoading = false;
          if (err) {
            this.apiStatus = "Failed to fetch devices counts"
            //this.utilsCheckLogout(this.apiStatus);
          }else {
            this.apiStatus = null;
            //console.log("**** getMonitoredDevicesCount result = " + JSON.stringify(result, null, 2))
            // Output format
            //[{"orgId":"orgobj.1000","sites":[{"siteId":"sitobj.2000078_1fd8ba97-748f-4e00-ad71-05d6a246d73f","monDevCount":4},{"siteId":"sitobj.2000046_81222a6d-fe4f-472f-909c-19e63c8eb2ed","monDevCount":84}],"monDevCount":88},{"orgId":"orgobj.2001_68e21c61-014b-4bcb-9119-31607cd0bf3e","sites":[{"siteId":"sitobj.2000111_0feb497d-3bbe-4885-9a99-bc9ba2374ca7","monDevCount":1}],"monDevCount":1}]

            this.updateMonDevCounts (this.org_table_rows, result.data.data)
            this.countsKey1 +=1 ; // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
            //console.log("**** getMonitoredDevicesCount orgs = " + JSON.stringify(this.org_table_rows, null, 2))
          }
        }, query_params, "INVENTORY_SERVICE_URL"
      );
    },

    getOrganizationsStatusCount(currOrgId = null) {
      let query_params = {}, body={};
      var targetOrgId = currOrgId ? currOrgId : this.currOrgId;
      query_params.targetOrgId = targetOrgId;

      //this.isLoading=true

      query_params.summaryDataType = this.UtilsModaConstants.FaultService.Summary.DataType.ORGS;
      query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.COUNTS;

      body = this.collectOrgIds([], this.org_table_rows)

      //console.log("getOrganizationsStatusCount body " + JSON.stringify(body))

      ApiService.post( "/svcSummary/api/v1/summary", body, (err, result) => {
          this.isLoading = false;
          if (err) {
            //console.log("orgs fault status fetch failed " + JSON.stringify(err))
            this.apiStatus = "Failed to fetch orgs' fault status"
            //this.utilsCheckLogout(this.apiStatus);
          }else {
            this.apiStatus = null;
            //console.log("**** getOrganizationsStatusCount orgs result = " + JSON.stringify(result, null, 2))
            this.updateOrgStatus(this.org_table_rows, result.data.data.summaryData.list.okList)
            this.updateOrgStatus(this.org_table_rows, result.data.data.summaryData.list.faultedList)
            //console.log("**** getOrganizationsStatusCount orgs = " + JSON.stringify(this.org_table_rows, null, 2))
            this.statusesKey1 +=1 ; // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
            this.statusesKey2 +=1 ; // key attribute of elements that depend on liveStatus data so they can be re-rendered forcefully
          }
        }, query_params, "SUMMARY_SERVICE_URL"
      );
    },

    getOrganizationsList(currOrgId = null, mspOrgId = null) {
      let query_params = {}, body = {};

      var targetOrgId = currOrgId ? currOrgId : this.currOrgId;
      //query_params.targetOrgId = targetOrgId;
      query_params.pageNum = this.currPage + 1; // Ads table uses 0 based page numbers, but backends expects 1 based
      query_params.pageSize = this.itemsPerPage;
      query_params.pageNum = this.pagingOptions.page;
      query_params.pageSize = this.pagingOptions.itemsPerPage;
      query_params.sortCriteria = "highestSeverityNum:desc"

      //this.isLoading=true
      ApiService.post( "/api/v1/org/children/tree/" + targetOrgId + "/0", body, (err, result) => {
          this.isLoading = false;
          if (err) {
            this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
            console.log("getOrganizationsList failed");
            this.utilsCheckLogout(this.apiStatus);
          } else {
            //console.log("getOrganizationsList result " + JSON.stringify(result));
            this.apiStatus = null;
            this.org_table_rows = result.data.data.docs;
            this.utilsTotalEntriesAllPages = result.data.data.totalEntries;
            // remove top level MSP org from table display
            if ( this.org_table_rows.length && mspOrgId && this.org_table_rows[0].orgId == mspOrgId ) {
                this.org_table_rows = this.org_table_rows[0]._children;
            }
            this.buildFilterableColumns(this.org_table_rows);
            this.getOrganizationsStatusCount(currOrgId)
            this.getMonitoredDevicesCount(currOrgId)
          }
        },
        query_params
      );
    },

    setOrgCookie() {
      // This does not overwrite entire document.cookie string. It just adds/updates specified key=value only
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
      // Note: document.cookie is an accessor property with native setter and getter functions, and consequently is not a data property with a value: what you write is not the same as what you read, everything is always mediated by the JavaScript interpreter.
      document.cookie = "MODA_targetOrgId=" + this.currOrgId;
    },
    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      this.setOrgCookie();

      this.keepRefreshing()
    },

    keepRefreshing(){
      this.getFaultSummaryData();
      this.getAgentDevicesSummaryData();

      // When it's mounted, its map has not been initialized.
      // Therefore we need to write mapRef.$mapPromise.then(() => ...)
      if ( this.$refs.mapRef ){
            this.$refs.mapRef.$mapPromise.then((map) => {
            //map.panTo({lat: 1.38, lng: 103.80})
            //console.log("googleMap created ", map)
            this.googleMap = map;
            this.googleLoaded(this.google);
        });
      }
    },

    //---socket ---
    attachSummarySocketMessageHandlers(self, clientSocket, eventName) {
      console.log( "attachSocketMessageHandlers: clientSocket.id = " + clientSocket ? clientSocket.id : null + " " + eventName);

      clientSocket.on(ModaConst.Socket.Event.SUMMARY, (data) => {
        console.log( "Got an Summary event from server: data = " + JSON.stringify(data));
        self.eventData = data;
        let relevantEvent = true; //TODO: determine to refersh the of the individual panels based on the eventData -
        if (relevantEvent && self.isPageVisible) {
          //DEBUG: console.log("Socket: getting summary list ...");
          //self.getFaultSummaryData();
          this.keepRefreshing()
        }
      });

      clientSocket.on("message", (data) => {
        console.log("Got message from Server: data = " + JSON.stringify(data));
        self.socketMessage = data;
      });
    },

    attachStatusSocketMessageHandlers(self, clientSocket, eventName) {
      console.log( "attachSocketMessageHandlers: clientSocket.id = " + clientSocket ? clientSocket.id : null + " " + eventName);

      clientSocket.on(ModaConst.Socket.Event.DEVICE_STATUS, (data) => {
        console.log( "Got an Summary event from server: data = " + JSON.stringify(data));
        self.eventData = data;
        let relevantEvent = true; //TODO: determine to refersh the of the individual panels based on the eventData -
        if (relevantEvent && self.isPageVisible) {
          //DEBUG: console.log("Socket: getting summary list ...");
          self.getAgentDevicesSummaryData();
        }
      });

      clientSocket.on("message", (data) => {
        console.log("Got message from Server: data = " + JSON.stringify(data));
        self.socketMessage = data;
      });
    },

    handleVisibility() {
      if (document.visibilityState === "hidden") {
        this.isPageVisible = false;
        this.socketMessage = "Client's Page - Dashboard Summary - is hidden";
      } else {
        this.isPageVisible = true;
        this.socketMessage = "Client's page - Dashboard Summary - is visible " +
          this.isPageActivated;
        if (this.isPageActivated) {
          this.keepRefreshing()
        }
      }
      //DEBUG:
      socketService.sendMessage(this.pageName, this.socketMessage);
    },

    initChartData() {
        this.siteChartOptions                               = JSON.parse(JSON.stringify(this.ChartOptions));
        this.siteChartOptions.onClick                       = this.sitesChartSelected;
        // actual data for chart will be hooked below this after fetch, so make a copy of global object
        this.siteChartData                                  = JSON.parse(JSON.stringify(this.ChartData));
        this.siteChartData.datasets[0].backgroundColor      = this.ChartColors

        this.orgChartOptions                                = JSON.parse(JSON.stringify(this.ChartOptions));
        this.orgChartOptions.onClick                        = this.orgsChartSelected
        // actual data for chart will be hooked below this after fetch, so make a copy of global object
        this.orgChartData                                   = JSON.parse(JSON.stringify(this.ChartData));
        this.orgChartData.datasets[0].backgroundColor       = this.ChartColors

        this.mondevChartOptions                             = JSON.parse(JSON.stringify(this.ChartOptions));
        this.mondevChartOptions.onClick                     = this.monitoredDevicesChartSelected;
        // actual data for chart will be hooked below this after fetch, so make a copy of global object
        this.mondevChartData                                = JSON.parse(JSON.stringify(this.ChartData));
        this.mondevChartData.datasets[0].backgroundColor    = this.MonDevChartColors

        this.agentChartOptions                              = JSON.parse(JSON.stringify(this.ChartOptions));
        this.agentChartOptions.onClick                      = this.agentDevicesChartSelected;
        // actual data for chart will be hooked below this after fetch, so make a copy of global object
        this.agentChartData                                 = JSON.parse(JSON.stringify(this.ChartData));
        this.agentChartData.datasets[0].backgroundColor     = this.AgentChartColors
    },
  },
};
</script>
