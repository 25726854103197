import Vue from 'vue'
import routes from '@/routers'

// plugins
import VueRouter from 'vue-router'
import VueInsProgressBar from 'vue-ins-progress-bar'
import { VueAdsTable } from 'vue-ads-table-tree';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination'
import * as VueGoogleMaps from 'gmap-vue'
import VueMsal from '@sirip1/vue-msal'

// plugins css
import 'vue-ads-pagination/dist/vue-ads-pagination.css' //remove this after the tree grid ui fix
import 'vue-ads-table-tree/dist/vue-ads-table-tree.css' //remove this after the tree grid ui fix
//import "vue-vis-network/dist/vueVisNetwork.css";
import "vue-vis-network/node_modules/vis-network/dist/vis-network.css";
import 'vue-context/dist/css/vue-context.css';

import 'vue-json-pretty/lib/styles.css'
import '@/scss/theme.scss';
import '@/scss/responsive.scss';

import { library } from '@fortawesome/fontawesome-svg-core' // to be removed after ui fix
import { fas } from '@fortawesome/free-solid-svg-icons' // to be removed after ui fix
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome' // to be removed after ui fix

import "@/utils/veeValidateRules";

import App from '@/App.vue'
//import VMdDateRangePicker from "v-md-date-range-picker";
import DatetimePicker from 'vuetify-datetime-picker';

import config from '@/config'

Vue.config.productionTip = false

Vue.use(DatetimePicker)

Vue.use(VueRouter)
Vue.use(VueAdsTable)
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import '@mdi/font/css/materialdesignicons.css'

//import AWS from "aws-sdk";

/* Use ion material design icons imported above, i.e. ion-md-xxxx, as much as possible.
Below ones don't seem to have all icons, but are needed to Vuetify components to appear correctly */
export default new Vuetify({
  defaultAssets: {
    font: true,
    icons: 'mdi'
  },
  icons: {
    iconfont: 'mdi',
  },
})

Vue.use(Vuetify);

Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})

Vue.component('vue-ads-table', VueAdsTable);
Vue.component('vue-ads-pagination', VueAdsPagination);
Vue.component('VueAdsPageButton', VueAdsPageButton);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCp7kVUFphxTZ63zC2HLS_oVqQqmO3Ek7g',
    libraries: 'places,drawing,visualization', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'gmap-view/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})
//Vue.component(VueCountdown.name, VueCountdown);
//Vue.component(ChartPlugin.name, ChartComponent); 
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem('user-details')) {
      sessionStorage.setItem("_path", to.path);
      next({ path: '/login' })
    } else {
      if (to.meta.pageAuth) {
        var acces_type = "read-write";
        /*  TBD
                let title = to.meta.title;
                var user_details = JSON.parse(localStorage.getItem('user-details'))
                if(user_details.pbac && user_details.pbac.length>0){
                  var policies = user_details.pbac;
                  policies.forEach(polObj => {
                      //console.log(polObj)
                      if (polObj.policyDetails.features[0] == title && title !='site-management'){
                        if(polObj.policyDetails.permissions[0]){
                          acces_type =polObj.policyDetails.permissions[0]
                        }
                      }
                  }); 
                }
        */
        if (to.path == '/') {
          next({ path: '/dashboards/summary-status' })
        }
        if (acces_type == 'read-only' && from.path != '/') {
          next({ path: '/page-not-auth' })
        } else {
          next()
        }
      } else {
        next() // go to wherever I'm going
      }
    }
  } else {
    if (!localStorage.getItem('user-details')) {
      //console.log(JSON.stringify(to)+"&&&&&&"+JSON.stringify(from))
      next()
    } else {
      if (to.path == '/login') {
        next({ path: '/dashboards/summary-status' })
      } else {
        next()
      }
    }
  }
})

export const globalAPIUrlStore = new Vue({
  data: {
    API_SERVICE_URL: config.server.api_url,
    //globalAPIUserUrl:config.server.usr_api_url,
    MCP_SERVICE_URL: config.server.mcp_svc_url,
    JOBS_SERVICE_URL: config.server.jobs_svc_url,
    INVENTORY_SERVICE_URL: config.server.inventory_svc_api_url,
    FAULT_SERVICE_URL: config.server.fault_svc_api_url,
    FAULT_SERVICE_WS_URL: config.server.fault_svc_ws_url,
    STATUS_SERVICE_URL: config.server.status_api_url,
    STATS_SERVICE_URL: config.server.stats_api_url,
    STATUS_SERVICE_WS_URL: config.server.status_ws_url,

    SUMMARY_SERVICE_URL: config.server.summary_svc_api_url,
    SUMMARY_SERVICE_WS_URL: config.server.summary_svc_ws_url,

    EXTSYS_SERVICE_URL: config.server.extsys_api_url,

    GRAFANA_SERVICE_URL: config.server.grafana_url,
    googleMapAPIUrl: config.server.google_map_api_url,
    googleMapAPIKey: config.server.google_map_api_key,
    /*
    AWS_S3_RAWLOGS_BUCKET : config.server.aws_s3_rawlogs_bucket,
    AWS_S3_KEY_ID : config.server.aws_s3_key_id,
    AWS_S3_KEY_SECRET : config.server.aws_s3_key_secret,
    AWS_S3_REGION : config.server.aws_s3_region
    */

    MICROSOFT_CLIENT_ID : config.server.microsoft_client_id,
  }
})

Vue.use(VueMsal, {
    auth: {
      clientId: globalAPIUrlStore.MICROSOFT_CLIENT_ID,
      redirectUri: window.location.href.split("#")[0],
      //postLogoutRedirectUri: window.location.href.split("#")[0] // not needed as we do not do msal logOut coz it's not redirecting to this URL
    },
});


/*
AWS.config.update({
    region: config.server.aws_s3_region,
    credentials: new AWS.Credentials(config.server.aws_s3_key_id, config.server.aws_s3_key_secret)
});
Vue.prototype.$aws_s3 = new AWS.S3(); // global variable
*/

new Vue({
  render: h => h(App),
  vuetify: new Vuetify(),
  router
}).$mount('#app')
