<script>
/*
  Common mixin code : variables, routines, etc. shared by all Components
*/

import ApiService from "@/services/api.service";
import ModaConstants from "@/services/ModaConstants.js";
import { ipAddress } from "vuelidate/lib/validators";

export default {
  // add here all the methods, props… to share
  data() {
    return {
      utilsDataRefreshFunc: null, // parent func to be called to refresh data upon search field entered

      // v-data-table paging options
      utilsPagingOptions: { page: 1, itemsPerPage: 10 }, // Data-table paging is 1 based
      utilsTotalEntriesAllPages: 0,

      // table filtering option
      filterValueSearch: "",
      utilsAwaitingSearch: null,

      // admin status related
      utilStatusLocal: [],
      UtilsModaConstants: {
        AdminStatus: {
          ENABLED: "Enabled", // gap filler until real values are retrieved from backend. Must match backend value
        },
      },

      // severity display colors related
      utilsSeverityColors: [],

      // non-severity display colors related
      utilsNonSeverityColors: [],

/*
      utilsFaultyLabel: "Faulty",
      utilsOkLabel: "Good",
      utilsUnknownInfoLabel: "Unknown",
      utilsOfflineLabel: "Offline",
      utilsOnlineLabel: "Online",
*/

      utilsOpenStates: ["New", "Open", "InProgress", "Hold", "Reopen"], // all OPEN states of Incidents

      // fields supported by all *AdvancedFilter components.
      // Parent component will pass in props what fields it wants child component to display
      utilsAdvancedFilterFields: {

        // common fields
        FREEFORM_SEARCH : 'freeFormSearch',
        ORG_NAME        : 'orgName',
        SITE_NAME       : 'siteName',
        PARENT_ORG_NAME : 'parentOrgName',
        ADMIN_STATUS    : 'adminStatus',
        HIGHEST_SEVERITY: 'highestSeverity',
        MODIFIED_BEFORE : 'modifiedBefore',
        MODIFIED_AFTER  : 'modifiedAfter',
        CREATED_BEFORE  : 'createdBefore',
        CREATED_AFTER   : 'createdAfter',

        // Alarm/Incident specific
        EVENT_SOURCE_NAME: 'eventSourceName',
        EVENT_TYPE      : 'eventType',
        EVENT_SUB_TYPE  : 'eventSubType',
        EVENT_STATE     : 'eventState',
        EVENT_SEVERITY  : 'eventSeverity',
        EVENT_STATUS    : 'eventStatus',


        // Application specific
        APP_NAME        : 'appName',
        APP_TYPE        : 'appType',
        APP_SUB_TYPE    : 'appSubType',
        APP_PROTOCOL    : 'appProtocol',
        APP_DOMAINS     : 'appDomains',

        // Site specific
        ADDRESS         : 'address',
        REGION          : 'region',

        // Device and MonitoredDevice specific
        DEV_OPER_STATUS     : 'devOperStatus',
        DEV_LIVE_STATUS     : 'devLiveStatus',
        DEV_MAC_ADDRESS     : 'devMACAddress',
        DEV_IP_ADDRESS      : 'devIpAddress',
        DEV_SERIAL_NUMBER   : 'devSerialNumber',
        DEV_NAME            : 'devName',
        DEV_MODEL           : 'devModel',
        DEV_VENDOR          : 'devVendor',
        DEV_OS              : 'devOS',
      },
    };
  },
  computed: {
    // A special status enable/disable variable to be used in CRUD forms
    // This needs to be computed as we are using this.UtilsModaConstants.AdminStatus.ENABLED as default value
    // If an existing value is set, utilStatusLocal[] is used as backend storage for this variable.
    utilStatus1: {
      get: function () {
        return this.utilStatusGet(0);
      },
      set: function (newval) {
        this.utilStatusSet(0, newval);
      },
    },
    //utilStatus2  : { get : function() { return this.utilStatusGet(1) },  set : function(newval) { this.utilStatusSet(1, newval) }, },
    //utilStatus3  : { get : function() { return this.utilStatusGet(2) },  set : function(newval) { this.utilStatusSet(2, newval) }, },
    //utilStatus4  : { get : function() { return this.utilStatusGet(3) },  set : function(newval) { this.utilStatusSet(3, newval) }, },
    //utilStatus5  : { get : function() { return this.utilStatusGet(4) },  set : function(newval) { this.utilStatusSet(4, newval) }, },
    //utilStatus6  : { get : function() { return this.utilStatusGet(5) },  set : function(newval) { this.utilStatusSet(5, newval) }, },
    //utilStatus7  : { get : function() { return this.utilStatusGet(6) },  set : function(newval) { this.utilStatusSet(6, newval) }, },
    //utilStatus8  : { get : function() { return this.utilStatusGet(7) },  set : function(newval) { this.utilStatusSet(7, newval) }, },
    //utilStatus9  : { get : function() { return this.utilStatusGet(8) },  set : function(newval) { this.utilStatusSet(8, newval) }, },
    //utilStatus10 : { get : function() { return this.utilStatusGet(9) },  set : function(newval) { this.utilStatusSet(9, newval) }, },
    //utilStatus11 : { get : function() { return this.utilStatusGet(10) }, set : function(newval) { this.utilStatusSet(10, newval) }, },
    //utilStatus12 : { get : function() { return this.utilStatusGet(11) }, set : function(newval) { this.utilStatusSet(11, newval) }, },
    //utilStatus13 : { get : function() { return this.utilStatusGet(12) }, set : function(newval) { this.utilStatusSet(12, newval) }, },
    //utilStatus14 : { get : function() { return this.utilStatusGet(13) }, set : function(newval) { this.utilStatusSet(13, newval) }, },
    //utilStatus15 : { get : function() { return this.utilStatusGet(14) }, set : function(newval) { this.utilStatusSet(14, newval) }, },
    //utilStatus16 : { get : function() { return this.utilStatusGet(15) }, set : function(newval) { this.utilStatusSet(15, newval) }, },
    //utilStatus17 : { get : function() { return this.utilStatusGet(16) }, set : function(newval) { this.utilStatusSet(16, newval) }, },
    //utilStatus18 : { get : function() { return this.utilStatusGet(17) }, set : function(newval) { this.utilStatusSet(17, newval) }, },
  },
  methods: {
    utilsIsMODAUser(){
        let udObj = localStorage.getItem("user-details");
        if (udObj) {
            let userDetails = JSON.parse(udObj);
            if ( userDetails.email.includes("@modatechnologies.com")){
                return true
            }
        }
        return false
    },
    utilsCheckLogout(apiStatus){
        if ( apiStatus.includes && apiStatus.includes("Login expired") ) {
            sessionStorage.setItem("_path", this.$route.path);
            localStorage.clear()
            this.$router.push("/login")
        }
    },
    utilsAppendString(big, small) {
        if ( big == "" ) big = small;
        else big += "," + small;
        return big
    },
    utilStatusGet(index) {
      return this.utilStatusLocal[index]
        ? this.utilStatusLocal[index]
        : this.UtilsModaConstants.AdminStatus.ENABLED;
    },
    utilStatusSet(index, newval) {
      this.utilStatusLocal.splice(index, 1, newval);
    },
    utilsAdminStatusDisplayObject(adminStatus) {
      var adminStatuses = {};
      var enums = this.UtilsModaConstants.AdminStatus;
      //adminStatuses[enums.ENABLED]   = { class:"mr-2 status-enabled",  icon: "fas fa-check", text:"Enabled" }
      //adminStatuses[enums.DISABLED]  = { class:"mr-2 status-disabled", icon: "fas fa-times", text:"Disabled" }
      adminStatuses[enums.ENABLED] = {
        class: "mr-2",
        icon: "mdi-toggle-switch",
        text: "Enabled",
        colorName: 'success'
      };
      adminStatuses[enums.DISABLED] = {
        class: "mr-2",
        icon: "mdi-toggle-switch-off",
        text: "Disabled",
        colorName: 'grey'
      };
      return adminStatuses[adminStatus];
    },

    utilsOperStatusDisplayObject(operStatus) {
      var operStatuses = {};
      var enums = this.UtilsModaConstants.DeviceOperStatus;
      //operStatuses[enums.PREPROVISIONED]   = { class:"mr-2 event-pending",  icon: "fas fa-spinner fa-spin", text:"Pre-provisioned" }
      //operStatuses[enums.PROVISIONED]  = { class:"mr-2 event-pending", icon: "fas fa-circle-notch fa-spin", text:"Provisioned" }
      operStatuses[enums.PREPROVISIONED] = {
        class: "",
        icon: "mdi-registered-trademark",
        text: "Pre-provisioned",
        colorName: 'grey'
      };
      operStatuses[enums.PROVISIONED] = {
        class: "",
        icon: "mdi-registered-trademark",
        text: "Provisioned",
        colorName: 'grey'
      };
      operStatuses[enums.REGISTERED] = {
        class: "",
        icon: "mdi-registered-trademark",
        text: "Registered",
        colorName: 'success'
      };
      operStatuses["UNKNOWN"] = {
        class: "",
        icon: "mdi-help-circle",
        text: "Unknown",
        colorName: ''
      };
      return operStatus ? operStatuses[operStatus] : operStatuses["UNKNOWN"];
    },

    utilsLiveStatusDisplayObject(liveStatus) {
      var liveStatuses = {};
      var enums = this.UtilsModaConstants.DeviceLiveStatus;
      liveStatuses[enums.ONLINE] = {
        class: "mr-2",
        icon: "mdi-check-circle",
        text: "Online",
        colorName: "green"
      };
      liveStatuses[enums.OFFLINE] = {
        class: "mr-2 status-disabled",
        icon: "mdi-close-circle",
        text: "Offline",
        colorName: "orange"
      };
      liveStatuses[enums.FAULTY] = {
        class: "mr-2",
        icon: "mdi-alert-circle",
        text: "Fault",
        colorName: "red"
      };
      liveStatuses[enums.UNKNOWN] = {
        class: "mr-2",
        icon: "mdi-help-circle",
        text: "Unknown",
        colorName: "grey"
      };

      return liveStatus && liveStatuses[liveStatus] ? liveStatuses[liveStatus] : liveStatuses[enums.UNKNOWN];
    },

    utilsFaultStatusDisplayObject(faultStatus) {
      var faultStatuses = {};
      var enums = this.UtilsModaConstants.FaultService.FaultStatus;
      faultStatuses[enums.GOOD] = {
        class: "mr-2 status-enabled",
        icon: "mdi-check-circle",
        text: "No faults",
      };
      faultStatuses[enums.FAULTY] = {
        class: "mr-2 status-fault",
        icon: "mdi-alert-circle",
        text: "Faulty",
      };
      faultStatuses[enums.UNKNOWN] = {
        class: "mr-2 status-unknown",
        icon: "mdi-help-circle",
        text: "Unknown",
      };

      return faultStatus ? faultStatuses[faultStatus] : faultStatuses[enums.UNKNOWN];
    },

    utilsSyslogStatusDisplayObject(syslogStatus) {
      var syslogStatuses = {};
      var enums = this.UtilsModaConstants.AdminStatus;
      syslogStatuses[enums.ENABLED] = {
        class: "mr-2",
        icon: "mdi-toggle-switch",
        text: "Enabled",
        colorName: 'success'
      };
      syslogStatuses[enums.DISABLED] = {
        class: "mr-2",
        icon: "mdi-toggle-switch-off",
        text: "Disabled",
        colorName: 'grey'
      };
      return syslogStatuses[syslogStatus];
    },

    utilsJobStateDisplayObject(jobState) {
      var jobStates = {};
      var enums = this.UtilsModaConstants.JobState;
      jobStates[enums.CREATED] = {
        class: "event-created",
        color: "primary",
        icon: "mdi-plus-circle",
        text: "Created",
        colorName: "primary"
      };
      jobStates[enums.IN_PROGRESS] = {
        class: "event-inprogress",
        color: "warning",
        icon: "mdi-progree-clock",
        text: "InProgress",
        colorName: "warning"
      };
      jobStates[enums.ENDED] = {
        class: "event-ended",
        color: "success",
        icon: "mdi-stop-circle",
        text: "Ended",
        colorName: "success"
      };
      jobStates[enums.CANCELLED] = {
        class: "event-cancelled",
        color: "red",
        icon: "mdi-cancel",
        text: "Cancelled",
        colorName: "red",
      };

      if (!jobStates[jobState]) {
        console.log("utilsJobStateDisplayObject unknown jobstate " + jobState);
        return jobStates[enums.CREATED];
      }
      return jobStates[jobState];
    },

    utilsJobStatusDisplayObject(jobStatus) {
      var jobStatuses = {};
      var enums = this.UtilsModaConstants.JobStatus;

      //jobStatuses[enums.UNKNOWN]  = { class:"event-unknown",          icon: "fas fa-clock",           text:"Unknown" }
      jobStatuses[enums.UNKNOWN] = {
        class: "event-unknown",
        color: "grey",
        icon: "mdi-help-circle",
        text: "Unknown?",
        colorName: ""
      };
      jobStatuses[enums.SUCCESS] = {
        class: "event-success",
        color: "green",
        icon: "mdi-check-circle",
        text: "Success",
        colorName: "success"
      };
      jobStatuses[enums.PARTIAL_SUCCESS] = {
        class: "event-partial-success",
        color: "orange",
        icon: "check-check-circle-outline",
        text: "Partial Success",
        colorName: "warning"
      };
      jobStatuses[enums.FAIL] = {
        class: "event-fail",
        color: "red",
        icon: "mdi-alert",
        text: "Fail",
        colorName: "red"
      };

      if (!jobStatuses[jobStatus]) {
        console.log(
          "utilsJobStatusDisplayObject unknown jobStatus " + jobStatus
        );
        return jobStatuses[enums.UNKNOWN];
      }
      return jobStatuses[jobStatus];
    },
    utilsJobScheduleTypeDisplayObject(jobScheduleType) {
      var jobScheduleTypes = {};
      var enums = this.UtilsModaConstants.ModaScheduleType;
      jobScheduleTypes[enums.IMMEDIATE] = {
        class: "event-complete",
        icon: "mdi-calendar-check",
        text: "Executed immediately at ",
        colorName: "success"
      };
      jobScheduleTypes[enums.SCHEDULED] = {
        class: "event-pending",
        icon: "mdi-calendar-clock",
        text: "Scheduled at ",
        colorName: "warning"
      };
      jobScheduleTypes[enums.PERIODIC] = {
        class: "event-pending",
        icon: "mdi-calendar-sync",
        text: "Scheduled Periodic",
        colorName: "warning"
      };
      return jobScheduleTypes[jobScheduleType];
    },

    utilsJobRunStateDisplayObject(jobRunState) {
      var jobRunStates = {};
      var enums = this.UtilsModaConstants.JobRunState;

      //DEBUG: console.log( "utilsJobRunStateDisplayObject = " + JSON.stringify(enums) + ", jobRunState = " + jobRunState);
      jobRunStates[enums.TRIGGERED] = {
        class: "event-triggered",
        color: "green",
        icon: "mdi-clock-check",
        text: "Job run triggered by Job Scheduler",
      };
      jobRunStates[enums.INITIATED] = {
        class: "event-initiated",
        color: "green",
        icon: "mdi-motion-play-outline",
        text: "Job Task is initiated",
      };
      jobRunStates[enums.RUNNING] = {
        class: "event-running",
        color: "green",
        icon: "mdi-play-circle",
        text: "Job is Running",
      };
      jobRunStates[enums.COMPLETED] = {
        class: "event-completed",
        color: "green",
        icon: "mdi-check-circle",
        text: "Job Completed successfully",
      };
      jobRunStates[enums.NO_RESPONSE] = {
        class: "event-noresponse",
        color: "red",
        icon: "mdi-alert",
        text: "Job is Stuck",
      };
      jobRunStates[enums.ABORTED] = {
        class: "event-aborted",
        color: "red",
        icon: "mdi-cancel",
        text: "Job is Aborted",
      };
      jobRunStates["Unknown"] = {
        class: "event-unknown",
        color: "grey",
        icon: "mdi-help-circle",
        text: "Job state is Unknown",
      };
      return jobRunState ? jobRunStates[jobRunState] : jobRunStates["Unknown"];
    },

    utilsJobRunStatusDisplayObject(jobRunStatus) {
      var jobRunStatuses = {};
      var enums = this.UtilsModaConstants.JobRunStatus;
      //console.log("utilsJobRunStatusDisplayObject " + JSON.stringify(enums) + " jobRunStatus " + jobRunStatus);
      jobRunStatuses[enums.UNKNOWN] = {
        class: "event-unknown",
        color: "grey",
        icon: "mdi-help-circle",
        text: "Job Run status is Unknown",
        colorName: ""
      };
      jobRunStatuses[enums.SUCCESS] = {
        class: "event-success",
        color: "green",
        icon: "mdi-check-circle",
        text: "Job Run Completed",
        colorName: "success"
      };
      jobRunStatuses[enums.FAIL] = {
        class: "event-failed",
        color: "red",
        icon: "mdi-alert",
        text: "Job Run Failed",
        colorName: "red"
      };
      return jobRunStatus
        ? jobRunStatuses[jobRunStatus]
        : jobRunStatuses[enums.UNKNOWN];
    },

    utilsComponentIdTypeDisplayObject(jobRunStatus) {
      var idTypes = {};
      var enums = this.UtilsModaConstants.Event.ComponentIdType;
      console.log(
        "utilsComponentIdTypeDisplayObject" +
          JSON.stringify(enums) +
          " IdType " +
          jobRunStatus
      );
      if (!jobRunStatus) jobRunStatus = enums.OTHER;
      idTypes[enums.DEVICE_AGENT] = {
        class: "event-unknown",
        color: "red",
        icon: "far fa-exclamation-triangle",
        text: "citDevAgt",
      };
      idTypes[enums.MONITORED_DEVICE] = {
        class: "event-unknown",
        color: "red",
        icon: "far fa-hdd",
        text: "citMonDev",
      };
      idTypes[enums.SITE] = {
        class: "event-unknown",
        color: "red",
        icon: "fa fa-university",
        text: "citSite",
      };
      idTypes[enums.OTHER] = {
        class: "event-unknown",
        color: "red",
        icon: "far fa-hdd",
        text: "citOther",
      };
      console.log(
        "utilsComponentIdTypeDisplayObject" +
          JSON.stringify(idTypes[jobRunStatus]) +
          " IdType " +
          jobRunStatus
      );
      return idTypes[jobRunStatus];
    },
    // ModaConst.Alarm.Source
    utilsComponentSourceDisplayObject(jobRunStatus) {
      var idTypes = {};
      var enums = this.UtilsModaConstants.Component;
      idTypes[enums.DEVICE_AGENT] = {
        class: "event-unknown",
        color: "red",
        icon: "far fa-exclamation-triangle",
        text: "DevAgt",
      };
      idTypes[enums.MODA_CMS] = {
        class: "event-success",
        color: "green",
        icon: "fas fa-check-circle",
        text: "CMS",
      };
      idTypes[enums.OTHER] = {
        class: "event-failed",
        color: "red",
        icon: "fas fa-thumbs-down",
        text: "Other",
      };
      return idTypes[jobRunStatus];
    },

    // takes Epoch time in millisecs. Returns MM-DD-YYYY HH:MM:SS AM/PM format in browser timezone
    utilsFormatDateTime(dateNumber) {
      if (dateNumber > 0) {
        var dateTime = new Date(dateNumber)
        var dateStr =
            ("0" + (dateTime.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + dateTime.getDate()).slice(-2) +
            "-" +
            dateTime.getFullYear() +
            " " +
            ("0" + dateTime.getHours()).slice(-2) +
            ":" +
            ("0" + dateTime.getMinutes()).slice(-2) +
            ":" +
            ("0" + dateTime.getSeconds()).slice(-2);
        return dateStr;
      } else return "";
    },
    // takes Epoch time in millisecs. Returns MM-DD-YYYY HH:MM:SS AM/PM format in UTC timezone
    utilsFormatDateTimeUTC(dateNumber) {
      if (dateNumber > 0) {
        var dateTime = new Date(dateNumber)
        var dateStr =
            ("0" + (dateTime.getUTCMonth() + 1)).slice(-2) +
            "-" +
            ("0" + dateTime.getUTCDate()).slice(-2) +
            "-" +
            dateTime.getUTCFullYear() +
            " " +
            ("0" + dateTime.getUTCHours()).slice(-2) +
            ":" +
            ("0" + dateTime.getUTCMinutes()).slice(-2) +
            ":" +
            ("0" + dateTime.getUTCSeconds()).slice(-2);
        return dateStr;
      } else return "";
    },


    // takes Epoch time in millisecs. Returns YYYY-MM-DD HH:MM:SS format (in 24hr format) in browser timezone
    // if dateNumber is not supplied, it returns current date and time string
    // !!!!!!!
    // !!! This is used to format input/output to Vuetify date/time pickers only !!!! do not use for display purposes ....!!!! Do not modify !!!!!!
    // !!!!!!!
    utilsFormatDateTime2(dateNumber) {
      var dateTime = dateNumber ? new Date(dateNumber) : new Date();
      var dateStr =
        dateTime.getFullYear() +
        "-" +
        ("0" + (dateTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dateTime.getDate()).slice(-2) +
        " " +
        ("0" + dateTime.getHours()).slice(-2) +
        ":" +
        ("0" + dateTime.getMinutes()).slice(-2) +
        ":" +
        ("0" + dateTime.getSeconds()).slice(-2);
      //console.log("utilsFormatDateTime2 " + dateStr);
      return dateStr;
    },
    // takes Epoch time in millisecs. Returns YYYY-MM-DD HH:MM:SS format (in 24hr format) in UTC timezone
    // if dateNumber is not supplied, it returns current date and time string
    // !!!!!!!
    // !!! This is used to format input/output to Vuetify date/time pickers only !!!! do not use for display purposes ....!!!! Do not modify !!!!!!
    // !!!!!!!
    utilsFormatDateTime2UTC(dateNumber) {
      var dateTime = dateNumber ? new Date(dateNumber) : new Date();
      var dateStr =
        dateTime.getUTCFullYear() +
        "-" +
        ("0" + (dateTime.getUTCMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dateTime.getUTCDate()).slice(-2) +
        " " +
        ("0" + dateTime.getUTCHours()).slice(-2) +
        ":" +
        ("0" + dateTime.getUTCMinutes()).slice(-2) +
        ":" +
        ("0" + dateTime.getUTCSeconds()).slice(-2);
      //console.log("utilsFormatDateTime2 " + dateStr);
      return dateStr;
    },

    // Seconds to Days/Hours/Minutes/Seconds
    utilsFormatSeconds2Str(seconds) {
      var nYears = Math.floor(seconds / 31536000);
      var nDays = Math.floor((seconds % 31536000) / 86400); 
      var nHours = Math.floor(((seconds % 31536000) % 86400) / 3600);
      var nMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
      var nSeconds = (((seconds % 31536000) % 86400) % 3600) % 60;

      var periodStr = "";
      if (nYears > 0) periodStr += (nYears + "y,");
      if (nDays > 0) periodStr += (nDays + "d,");
      if (nHours > 0) periodStr += (nHours + "h,");
      if (nMinutes > 0) periodStr += (nMinutes + "m,");
      if (nSeconds > 0) periodStr += (nSeconds + "s");

      return periodStr;
    },

    utilsIsNumber: function (evt) {
      //console.log("utilsIsNumber evt " + JSON.stringify(evt));
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ( charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    /*      { 'value' : 'siteMonitoring', 'displayName': "Site Monitoring", "isProtocol": false},

            utilsGetKey (..) will return  'value'
*/

    utilsGetKey: function (obj) {
      return Object.keys(obj)[0];
    },

    utilsStatusLabelString: function (prefix, status, suffix) {
      var r = "";

      if (prefix) r += prefix + " ";
      r += status; // for now, both display and value are same
      if (suffix) r += " " + suffix;

      return r;
    },

/*
        [ { 'value' : 'siteMonitoring', 'displayName': "Site Monitoring", "isProtocol": false},
          { 'value' : 'applicationMonitoring', 'displayName': "Application Monitoring", "isProtocol": true}, ]

        utilsGetArrayElementByFieldValue(..., value, 'siteMonitoring') will return first element
*/

    utilsGetArrayElementByFieldValue: function (arr, fieldName, fieldValue) {
      //console.log("utilsGetArrayElementByFieldValue arr " +  JSON.stringify(arr) + " fieldName " + fieldName +  " fieldValue " + fieldValue);
      return arr.find((item) => {
        //console.log("utilsGetArrayElementByFieldValue item " +  JSON.stringify(item[fieldName]));
        return item[fieldName] == fieldValue;
      });
    },

    /* Converts Dictionary to Array of objects i.e.
        { key1 : 1, key2: 2, key3: 3 } => [ {key: key1, value: 1}, {key: key2, value: 2}, {key: key3, value: 3} ]

        Used for displaying backend values defined as dictionary in ModaConstants
        in frontend v-autocomplete which requires an items array
    */
    utilsDictToArray(dict) {
        return Object.entries(dict).map(x => { return {key: x[0], value: x[1]} })
    },

    utilsGetModaConstants(callback) {
      ApiService.getModaConstants((err, data) => {
        this.UtilsModaConstants = data;
        console.log(
          "this.UtilsModaConstants " + JSON.stringify(this.UtilsModaConstants)
        );
        if (callback) callback(err);
      });
    },

    // https://stackoverflow.com/questions/41435985/how-to-validate-an-ipv6-address-using-a-combination-of-regex-and-code
    /**
     * @param {String} a String
     * @return {Boolean} true if the String is a valid IPv6 address; false otherwise
     */
    _utilsValidateIPv6Address(value, max_compressions) {
      if (!value) return true;
      value = value.trim();
      if (value == "") return true;
      // See https://blogs.msdn.microsoft.com/oldnewthing/20060522-08/?p=31113 and
      // https://4sysops.com/archives/ipv6-tutorial-part-4-ipv6-address-syntax/
      const components = value.split(":");
      //console.log("Ipv6 address " + value + " : components " + JSON.stringify(components));
      if (components.length < 2 || components.length > 8) {
        console.log("Ipv6 address " + value + " : # components must be 2-8");
        return false;
      }
      if (components[0] !== "" || components[1] !== "") {
        // Address does not begin with a zero compression ("::")
        let str = components[0];
        if (
          str.length > 4 || // more than 4 chars
          str.match(/[\W_g-z]/i)
        ) {
          // non-word charater, underscore, non-hex char => remaining are digits, a-z, A-Z
          // Component must contain 1-4 hex characters
          console.log(
            "Ipv6 address " +
              value +
              " : Component 0 must contain 1-4 hex characters " +
              components[0]
          );
          return false;
        }
      }

      let numberOfZeroCompressions = 0;
      for (let i = 1; i < components.length; ++i) {
        if (components[i] === "") {
          // We're inside a zero compression ("::")
          ++numberOfZeroCompressions;
          if (numberOfZeroCompressions > max_compressions) {
            // Zero compression can only occur once in an address
            console.log( "IPv6 address " + value + " : component " + i + " Zero compression can only occur once in an address");
            return false;
          }
          continue;
        }
        let str = components[i];
        //console.log("Ipv6 address " + value + " : Component " + i + " " + str);
        if (
          str.length > 4 || // more than 4 chars
          str.match(/[\W_g-z]/i)
        ) {
          // non-word charater, underscore, non-hex char => remaining are digits, a-z, A-Z
          // Component must contain 1-4 hex characters
          console.log(
            "IPv6 address " +
              value +
              " : Component must contain 1-4 hex characters " +
              components[i]
          );
          return false;
        }
      }
      //console.log("IPv6 address valid")
      return true;
    },

    utilsValidateIPv6Address(value) {
      return this._utilsValidateIPv6Address(value, 1);
    },
    utilsValidateIPv6Netmask(value) {
      if (!value) return true;
      value = value.split("/")[0]; // remove /32 or /64
      return value ? this._utilsValidateIPv6Address(value, 2) : true;
    },

    // Correct validator which allows leading 0s
    utilsValidateIPv4Address(value) {
        let toks=value.split(".")
        if ( toks.length != 4 ) return false;
        let valid = true;
        toks.forEach((tok) => {
            let val = parseInt(tok)
            valid &= (0 <= val && val <= 254)
            //console.log("utilsValidateIPv4Address tok " + tok + " int " + val + " valid " + valid)
        })
        //console.log("utilsValidateIPv4Address valid " + valid)
        return valid
    },

    utilsValidateIPAddressList(version, value) {
      var valid = true;
      if (value && typeof value == "string") {
        if (version == "v4")
          //value.split(",").forEach((tok) => (valid &= ipAddress(tok.trim())));
          value.split(",").forEach((tok) => (valid &= this.utilsValidateIPv4Address(tok.trim())));
        else if (version == "v6")
          value
            .split(",")
            .forEach(
              (tok) => (valid &= this.utilsValidateIPv6Address(tok.trim()))
            );
      }
      //console.log("utilsValidateIPAddressList version " + version + " value " + value + " valid " + valid);
      return valid;
    },

    utilsIsSystem(obj) {
      // if obj is not a USER created one, it's a system obj.
      if (obj.entryType == this.UtilsModaConstants.EntryType.SYSTEM) {
        return true
      } else {
        return false
      }
    },
    utilsGetDisplayClass(obj) {
      return this.isShow == "display-none" || this.utilsIsSystem(obj) ? "display-none" : "display-block";
    },

    utilsSetSeverityColors() {
      // !!! All fields (Class, Rgb, Hex) must correspond to same color !!!!
      // !!! Pick one of the colors from  below link for class, hex values before you change anything. !!!!
      // !!! Rgb values must then be manually derived from hex values !!!
      // https://vuetifyjs.com/en/styles/colors/#material-colors

      // severity
      this.utilsSeverityColors[this.UtilsModaConstants.FaultService.Severity.CRITICAL] =
      {
        colorName: "red",
        colorClass: "red--text text--accent-3",
        colorRgb: "rgb(255, 23, 68, 0.5)",
        colorHex: "#FF1744",
        darkMode: true
      };
      this.utilsSeverityColors[this.UtilsModaConstants.FaultService.Severity.SEVERE] =
        {
          colorName: "red lighten-2",
          colorClass: "red--text text--lighten-2",
          colorRgb: "rgb(229, 115, 115, 0.5)",
          colorHex: "#E57373",
          darkMode: true
        };
      this.utilsSeverityColors[this.UtilsModaConstants.FaultService.Severity.MAJOR] = 
      {
        colorName: "orange",
        colorClass: "orange--text",
        colorRgb: "rgb(255, 152, 0, 0.5)",
        colorHex: "#FF9800",
        darkMode: true
      };
      this.utilsSeverityColors[this.UtilsModaConstants.FaultService.Severity.MINOR] =
/* Changing Minor to light green
      {
        colorName: "amber-lighten-1",
        colorClass: "amber--text text--lighten-1",
        colorRgb: "rgb(255, 202, 40, 0.5)",
        colorHex: "#FFCA28",
      };
*/
      {
        colorName: "green lighten-2",
        colorClass: "green--text text--lighten-2",
        colorRgb: "rgb(129, 199, 132, 0.5)",
        colorHex: "#81C784",
        darkMode: true
      };
      this.utilsSeverityColors[this.UtilsModaConstants.FaultService.Severity.INFO] =
      {
        colorName: "green",
        colorClass: "green--text",
        colorRgb: "rgb(76, 175, 80, 0.5)",
        colorHex: "#4CAF50",
        darkMode: true
      };
      this.utilsSeverityColors[this.UtilsModaConstants.FaultService.Severity.NOT_SET] =
      {
        colorName: "grey darken-1",
        colorClass: "grey--text text--darken-1",
        colorRgb: "rgb(117, 117, 117, 0.5)",
        colorHex: "#757575",
        darkMode: true
      };


      // !!! All fields (Class, Rgb, Hex) must correspond to same color !!!!
      // !!! Pick one of the colors from  below link for class, hex values before you change anything. !!!!
      // !!! Rgb values must then be manually derived from hex values !!!
      // https://vuetifyjs.com/en/styles/colors/#material-colors

      // deviceLiveStatus
      this.utilsSeverityColors[this.UtilsModaConstants.DeviceLiveStatus.FAULTY] =
      {
        colorName: "red",
        colorClass: "red--text text--accent-3",
        colorRgb: "rgb(255, 23, 68, 0.5)",
        colorHex: "#FF1744",
        darkMode: true
      };
      this.utilsSeverityColors[this.UtilsModaConstants.DeviceLiveStatus.OFFLINE] =
      {
        colorName: "orange",
        colorClass: "orange--text",
        colorRgb: "rgb(255, 152, 0, 0.5)",
        colorHex: "#FF9800",
      };
      this.utilsSeverityColors[this.UtilsModaConstants.DeviceLiveStatus.ONLINE] =
      {
        colorName: "green",
        colorClass: "green--text",
        colorRgb: "rgb(76, 175, 80, 0.5)",
        colorHex: "#4CAF50",
        darkMode: true
      };
      this.utilsSeverityColors[this.UtilsModaConstants.DeviceLiveStatus.UNKNOWN] =
      {
        colorName: "grey darken-1",
        colorClass: "grey--text text--darken-1",
        colorRgb: "rgb(117, 117, 117, 0.5)",
        colorHex: "#757575",
        darkMode: true
      };
    },
    utilsGetSeverityColor(severity) {
        if ( severity === undefined ) {// not present means, unknown
            return this.utilsSeverityColors[this.UtilsModaConstants.FaultService.Severity.NOT_SET]
        }else if ( severity == null || severity == this.UtilsModaConstants.FaultService.FaultStatus.GOOD) { // present, null or Good
            return this.utilsSeverityColors[this.UtilsModaConstants.FaultService.Severity.INFO]
        } else // present not null
            return this.utilsSeverityColors[severity];
    },

    utilsSetNonSeverityColors() {
      // !!! All fields (Class, Rgb, Hex) must correspond to same color !!!!
      // !!! Pick one of the colors from  below link for class, hex values before you change anything. !!!!
      // !!! Rgb values must then be manually derived from hex values !!!
      // https://vuetifyjs.com/en/styles/colors/#material-colors

      this.utilsNonSeverityColors[this.UtilsModaConstants.Alarm.Status.NEW] =

        this.utilsNonSeverityColors[this.UtilsModaConstants.Incident.State.NEW] =
        this.utilsNonSeverityColors[this.UtilsModaConstants.Incident.State.OPEN] =
        this.utilsNonSeverityColors[this.UtilsModaConstants.Incident.State.REOPEN] =
        this.utilsNonSeverityColors["MiscBlue"] =
          {
            colorName: "blue",
            colorClass: "blue--text text--accent-4",
            colorRgb: "rgb(41, 98, 255, 0.5)",
            colorHex: "#2962FF",
            darkMode: true
          };
      this.utilsNonSeverityColors[this.UtilsModaConstants.Alarm.Status.ACKNOWLEDGED] =

        this.utilsNonSeverityColors[this.UtilsModaConstants.Incident.State.RESOLVED] =
        this.utilsNonSeverityColors[this.UtilsModaConstants.Incident.State.CLOSED] =
        this.utilsNonSeverityColors["MiscGreen"] =
          {
            colorName: "green",
            colorClass: "green--text",
            colorRgb: "rgb(76, 175, 80, 0.5)",
            colorHex: "#4CAF50",
            darkMode: true
          };
      this.utilsNonSeverityColors[this.UtilsModaConstants.Incident.State.IN_PROGRESS] =
      this.utilsNonSeverityColors[this.UtilsModaConstants.Incident.State.HOLD] =
        this.utilsNonSeverityColors["MiscOrange"] =
          {
            colorName: "orange",
            colorClass: "orange--text",
            colorRgb: "rgb(255, 152, 0, 0.5)",
            colorHex: "#FF9800",
          };
      this.utilsNonSeverityColors[this.UtilsModaConstants.Incident.State.UN_REPRODUCEABLE] =
      this.utilsNonSeverityColors[this.UtilsModaConstants.Incident.State.DUPLICATE] =
      this.utilsNonSeverityColors[this.UtilsModaConstants.Incident.State.JUNK] =
        this.utilsNonSeverityColors["MiscGrey"] =
          {
            colorName: "grey-darken-1",
            colorClass: "grey--text text--darken-1",
            colorRgb: "rgb(117, 117, 117, 0.5)",
            colorHex: "#757575",
            darkMode: true
          };
    },

    utilsFilterChanged() {
      //console.log("utilsFilterChanged 1 ", this.filterValueSearch);
      if (this.utilsAwaitingSearchTimer) {
        clearTimeout(this.utilsAwaitingSearchTimer);
        this.utilsAwaitingSearchTimer = null;
      }
      this.utilsAwaitingSearchTimer = setTimeout(() => {
        //console.log("utilsFilterChanged 2 ", this.filterValueSearch);
        this.utilsAwaitingSearch = false;
        if (this.utilsDataRefreshFunc) this.utilsDataRefreshFunc();
      }, 500); // 500 msec delay
    },

    // common params like createdDateString, *IdCode will be replaced by this routine
    // renameParams is an object whose keys are original column names and values are new column names to be replaced with
    utilsMakeSortCriteria(pagingOptions, renameParams) {
      var sortCriteria = "";
      var sortBy = pagingOptions.sortBy;
      var sortDesc = pagingOptions.sortDesc;

      sortBy.forEach((column, i) => {
        // replace common display column names with real column names
        if (renameParams[column]) column = renameParams[column];
        // most Date are converted to DateString by frontend
        else if (column.endsWith("DateString"))
          column = column.replace(/DateString/gi, "Date");
        // most Id are converted to IdCode by backend
        else if (column.endsWith("IdCode"))
          column = column.replace(/IdCode/gi, "Id");

        sortCriteria += (sortCriteria ? "," : "") + column + ":"
        if (column == "highestSeverityNum")
            sortCriteria += sortDesc[i] ? "asc" : "desc";
        else
            sortCriteria += sortDesc[i] ? "desc" : "asc";
      });
      //console.log("Utils utilsMakeSortCriteria " + sortCriteria)
      return sortCriteria;
    },
/* Not used. Frontend goes via backend for this
    utilsGetFileFromS3(bucket, filename, arg, next) {
      let s3 = this.$aws_s3;
      var params = {
        Bucket: bucket,
        Key: filename,
      };
      s3.getObject(params, function (err, content) {
        if (err) {
          console.log("Failed to fetch file from s3 ", JSON.stringify(err));
          next(err, arg);
        } else {
          var body = content["Body"];
          //console.log("got s3 file ", filename, " content ", typeof(body))
          var fileContent =
            typeof body === "object" ? String.fromCharCode(...body) : body;
          //console.log(batch.fileContent)
          next(null, fileContent, arg);
        }
      });
    },
*/
    getFileFromAPI({ bucket, filename, targetOrgId, arg, next }) {
      ApiService.post(
        `/api/v1/dataManagement/getfile/s3?targetOrgId=${targetOrgId}`, {
            bucket,
            filename,
            getData: true,
            contentType: "application/text",
        }, (err, result) => {
          if (err) {
            console.error("Failed to fetch file", err);

            next(err, arg);
          } else {
            const body = result.data;

            const fileContent =
              typeof body === "object" ? String.fromCharCode(...body) : body;

            next(null, fileContent, arg);
          }
        }
      );
    },

        // helper function to compare two lists.
        utilsIsSameList(list1, list2) {
            //DEBUG: console.log("isSameList: list1 = [" + list1 + "], list2 = [" + list2 + "]");
            let res = false;
            if (list1 == list2) {       //TODO: Use token based comparision
                res = true;
            } 

            console.log("isSameList: res = " + res);
            return res;
        },

  },
  /*
    // promise version of created
    async created() {
        console.log("ApiService.UtilsModaConstants " + JSON.stringify(ApiService.UtilsModaConstants));
        ApiService.ModaConstants ? this.UtilsModaConstants = ApiService.ModaConstants :
                            await ApiService.getModaConstants().
                                    then((data) => {
                                            this.UtilsModaConstants = data;
                                            console.log("created this.utilsGetModaConstants " + JSON.stringify(this.UtilsModaConstants))
                                        }
                                    );
    },
*/
  /* - regular version of created
   */
  created() {
    // ApiService.get isn't working. First time when it's loaded, it takes a while
    // and if the caller of this component is using these constants in template rendering, it's failing.

    //this.utilsGetModaConstants();

    // so, only way is maintain two copies & import this file here.
    // Tried various things like beforeRoute, or maintaining single copy and sharing with both frontend and backend.
    // But coz of ECMAScript export defailt, CommonJS module.exports difference, there is absolutely noway to do that.

    this.UtilsModaConstants = ModaConstants;
    this.utilsSetSeverityColors();
    this.utilsSetNonSeverityColors();
  },
};
</script>
