<template>
    <v-app>
        <v-app-bar :elevation="1" v-if="isLoggedin" app color="white" dark clipped-left>
            <Header />
        </v-app-bar>
        <Sidebar v-if="isLoggedin" />
        <v-main>
            <v-container fluid fill-height class="pa-0">
                <v-layout>
                    <v-flex>
                        <router-view></router-view>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
        <vue-ins-progress-bar></vue-ins-progress-bar>
        <Footer v-if="isLoggedin" />
    </v-app>
</template>

<script>
import Sidebar from '@/components/templates/Sidebar.vue'
import Header from '@/components/templates/Header.vue'
import Footer from '@/components/templates/Footer.vue'

export default {
    name: 'app',
    components: {
        Sidebar,
        Header,
        Footer
    },
    data() {
        return {
            isLoggedin: false
        }
    },
    methods: {
    },
    mounted() {
        this.$insProgress.finish()
    },
    watch: {
        $route(to, from) {
            if (localStorage.getItem('user-details')) {
                this.isLoggedin = true;
            } else {
                this.isLoggedin = false;
            }
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        this.$insProgress.start()
        this.$router.beforeEach((to, from, next) => {
            this.$insProgress.start()
            next()
        })
        this.$router.afterEach(() => {
            this.$insProgress.finish()
        })
        if (localStorage.getItem('user-details')) {
            this.isLoggedin = true;
        }
    }
}
</script>
