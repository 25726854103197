<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="2" class="tile-box">
      <v-card-title class="page-title">
        <v-icon>mdi-router-wireless</v-icon> {{ titleName }} Device
        <v-badge title="Liveness"
          v-if="devicedetails.imageInfo && devicedetails.imageInfo.images && devicedetails.imageInfo.images.main"
          bordered :color="utilsLiveStatusDisplayObject(devicedetails.liveStatus).colorName" offset-x="-10"
          offset-y="10" :content="utilsLiveStatusDisplayObject(devicedetails.liveStatus).text">
        </v-badge>

        <v-btn class="status-button" :color="utilsGetSeverityColor(devicedetails.highestSeverity).colorName" rounded
          outlined right absolute>
          Status: <v-icon>{{ utilsFaultStatusDisplayObject(devicedetails.faultStatus).icon }}</v-icon><strong>{{
              devicedetails.highestSeverity ?
                devicedetails.highestSeverity : utilsFaultStatusDisplayObject(devicedetails.faultStatus).text
          }} </strong>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <!-- begin create/edit form -->
        <v-container fluid class="pa-0">
          <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
            {{ apiStatus }}
          </v-alert>
          <v-row v-if="isLoading">
            <v-col cols="12">
              <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-col>
          </v-row>
          <v-row :key="statusKey1">
            <v-col cols="12">
              <v-card color="blue-grey darken-4" dark class="rounded-box mb-5">
                <v-row>
                  <v-col>
                    <div class="d-flex flex-no-wrap">
                      <v-avatar class="ma-3" size="125" tile>
                        <v-img class="ma-3" :max-width="100" contain
                          v-if="devicedetails.imageInfo && devicedetails.imageInfo.images && devicedetails.imageInfo.images.main"
                          :src='"/assets/img/devices/" + devicedetails.imageInfo.images.main'>
                        </v-img>
                      </v-avatar>
                      <div>
                        <v-card-title> {{ devicedetails.monDevName || 'Unnamed Device' }}
                          <v-chip class="ma-2" color="primary" text-color="white">
                            Importance
                            <v-avatar right class="darken-4 blue">
                              {{ devicedetails.monDevImportance }}
                            </v-avatar>
                          </v-chip>
                        </v-card-title>
                        <v-card-subtitle class="pb-2">{{ devicedetails.description }}</v-card-subtitle>
                        <v-autocomplete v-model="devicedetails.siteId" prepend-inner-icon="mdi-map-marker" dense
                          :items="sites_list" item-text="siteName" item-value="siteId" :return-object="false"
                          class="label-input" disabled />
                      </div>
                    </div>
                  </v-col>
                  <v-col md="3">
                    <v-alert class="link-alert ma-3" prominent type="warning" icon="mdi-ticket-confirmation"
                      @click="navigateToIncidents(devicedetails)">
                      <p class="mb-0">Incidents</p>
                      <h3>
                        {{ devicedetails.incCount || 0 }}
                      </h3>
                    </v-alert>
                  </v-col>
                </v-row>

                <v-container class="py-0">
                  <v-row dense>
                    <v-col>
                      <strong>Mac</strong>
                      <p>{{ devicedetails.monDevMACAddress }}</p>
                    </v-col>
                    <v-col>
                      <strong>IP Address</strong>
                      <p>{{ devicedetails.monDevIpAddress }}</p>
                    </v-col>
                    <v-col>
                      <strong>Vendor</strong>
                      <p>{{ devicedetails.monDevVendor }}</p>
                    </v-col>
                    <v-col>
                      <strong>Type</strong>
                      <p>{{ devicedetails.monDevType }}</p>
                    </v-col>
                    <v-col>
                      <strong>Model</strong>
                      <p>{{ devicedetails.monDevModel }}</p>
                    </v-col>
                    <v-col>
                      <strong>OS</strong>
                      <p>{{ devicedetails.monDevOS }}</p>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <strong>First Seen</strong>
                      <p>{{ devicedetails.firstSeenStr }}</p>
                    </v-col>
                    <v-col>
                      <strong>Last Seen</strong>
                      <p>{{ devicedetails.lastSeenStr || "-"}}</p>
                    </v-col>
                    <v-col>
                      <strong>Uptime</strong>
                      <p>{{ devicedetails.upTimeStr || "-"}}</p>
                    </v-col>
                    <v-col>
                      <strong>SNMP Enabled</strong>
                      <p>{{ devicedetails.snmp.enabled }}</p>
                    </v-col>
                    <v-col>
                      <strong>Transient</strong>
                      <p>{{ devicedetails.isTransient }}</p>
                    </v-col>
                    <v-col>
                      <strong>Device Location</strong>
                      <p>{{ devicedetails.monDevLocation || "-"}}</p>
                    </v-col>
                  </v-row>
                  <v-autocomplete v-if="devicedetails.assignedTemplates.length" class="label-input"
                    v-model="devicedetails.assignedTemplates" prepend-inner-icon="mdi-clipboard-pulse"
                    :items="monTemplatesList" item-text="monTemplateName" item-value="monTemplateId" auto-select-first
                    label="Monitoring Template(s)" disabled multiple chips>
                    <template v-slot:item="{ item }">
                      <v-simple-checkbox :value="isAssignedTemplateSelected(item.monTemplateId)"
                        @click="toggleAssignedTemplateSelection(item.monTemplateId)" />
                      <a class="link-text" v-on:click="navigateToMonTemplate(item.monTemplateId)">
                        {{ item.monTemplateName }} </a>
                    </template>
                    <template v-slot:selection="{ item, select, selected }">
                      <v-chip :input-value="selected" @click="select" color="primary">
                        <a class="link-text" v-on:click="navigateToMonTemplate(item.monTemplateId)">
                          {{ item.monTemplateName }} </a>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="chartTemplates.length > 0">
              <v-card class="rounded-box mb-5">
                <v-card-title>
                  Metrics
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-select label="Select Metric" multiple dense @change="getMetricsData()"
                        v-model="templateInfoIds" :items="chartTemplates" item-text="name" item-value="itemId"
                        return-object></v-select>
                    </v-col>
                    <v-col>
                      <v-btn-toggle class="float-right" mandatory dense @change="getMetricsData()"
                        v-model="metricTimeWindow" tile color="primary" group>
                        <v-btn value="1 h">1h</v-btn>
                        <v-btn value="6 h">6h</v-btn>
                        <v-btn value="12 h">12h</v-btn>
                        <v-btn value="1 d">1d</v-btn>
                        <v-btn value="7 d">7d</v-btn>
                        <v-btn value="30 d">30d</v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>

                  <v-row v-if="isGrpahLoading">
                    <v-col cols="12" class="text-center pt-10 mt-10" style="height:200px">
                      <v-progress-circular :size="80" color="primary" indeterminate></v-progress-circular>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col lg="4" md="6" v-for="(item, index) in chartData" :key="item.itemid">
                      <LineChart style="max-height:230px" :chartData="item" :chartOptions="chartOptions[index]">
                      </LineChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- end create/edit form -->
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn rounded outlined color="success" @click="cancel">Go Back</v-btn>
      </v-card-actions>

      <!-- Line chart with Annotation start ( WIP - uncomment for development )
      <div style="position: relative;">
        <div id="overlayTooltip">Tooltip</div>
        <LineChart style="max-height:230px" :chartData="{
                        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                        datasets: [{
                          label: 'My First Dataset',
                          data: [65, 59, 80, 81, 56, 55, 40],
                          fill: false,
                          borderColor: 'rgb(75, 192, 192)',
                          tension: 0.1
                        }]
                      }" :chartOptions="{
                    responsive: true,
                    maintainAspectRatio: false,
                      elements: {
                        line: {
                          borderColor: '#1976d2'
                        }
                      },
                      plugins: {
                        tooltip: {
                          enabled: false
                        },
                        legend: {
                          display: true,
                          labels: {
                            boxWidth: 0,
                            font: {
                              size: 12,
                              family: '\'Open Sans\', sans-serif'
                            }
                          }
                        },
                        annotation: {
                          annotations: {
                            box: {
                              type: 'box', //line, 
                              xMin: 1,
                              xMax: 2,
                              yMin: 50,
                              yMax: 70,
                              backgroundColor: 'rgba(255, 99, 132, 0.25)',
                              drawTime: 'afterDatasetsDraw',
                              scaleID: 'y',
                              label: {
                                enabled: false,
                                backgroundColor: 'green',
                                borderWidth: 0,
                                drawTime: 'afterDatasetsDraw',
                                color: 'white',
                                content: (ctx) => ['Average of dataset'],
                                textAlign: 'center'
                              },
                              enter(ctx, event) {
                                showTooltip(ctx, event);
                              },
                              leave(ctx, event) {
                                hideTooltip();
                              },
                              click(ctx, event) {
                                overlayClick(ctx, event);
                              }
                            },
                            pentagon: {
                              type: 'polygon',
                              xValue: 3,
                              yValue: 60,
                              sides: 5,
                              radius: 60,
                              backgroundColor: 'rgba(255, 99, 132, 0.25)',
                              enter(ctx, event) {
                                showTooltip(ctx, event);
                              },
                              leave(ctx, event) {
                                hideTooltip();
                              }, 
                              click(ctx, event) {
                                overlayClick(ctx, event); 
                              }
                            }
                          }
                        } 
                      } }">
        </LineChart>
      </div> -->
      <!-- Line chart with Annotation End -->

    </v-card>
  </div>
</template>
<style>
#overlayTooltip{
  width: 100px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: #FFF;
  border: 1px solid;
  text-align: center;
}
</style>
<script>
import ApiService from "@/services/api.service";
import InventoryService from "@/services/inventory.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import Utils from "@/pages/moda/Utils";
import LineChart from '@/components/chartjs/Line.vue'
import moment from 'moment';

export default {
  components: {
    Breadcrumb,
    LineChart
  },
  data() {
    return {
      currOrgId: null,
      isGrpahLoading: false,
      isLoading: false,
      device_id: "",
      titleName: "View",
      statusKey1: 0, // key attribute of elements that depend on fault status data so they can be re-rendered forcefully
      sites_list: [],
      metricTimeWindow: '1 h',
      monTemplatesList: [],
      apiStatus: null,
      parent_pages: [
        { name: "Home" },
        { name: "Devices", route: { name: "MONITORED_DEVICES" } },
        { name: "View", active: true },
      ],
      devicedetails: {
        monDevModel: '',
        monDevName: null,
        monDevVendor: '',
        siteId: null,
        description: null,
        monDevMACAddress: null,
        assignedTemplates: [],
        serviceNames: "",
        adminStatus: "Enabled",
        monDevImportance: 0,

        snmp: {enabled: false},
        firstSeenStr: '',
        lastSeenStr: '',
        upTimeStr: '',
      },
      monDevImportanceMessage: null,
      chartTemplates: [],
      templateInfoIds: [],
      ChartData: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '#1976d2',
            data: []
          }
        ]
      },
      ChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            borderColor: "#1976d2"
          }
        },
        plugins: {
          legend: {
            display: true,
            labels: {
              boxWidth: 0,
              font: {
                size: 12,
                family: '"Open Sans", sans-serif'
              }
            }
          },
/* WIP - commented out
          annotation: {
            annotations: {
              box1: {
                // Indicates the type of annotation
                type: 'box',
                xMin: 1,
                xMax: 2,
                yMin: 50,
                yMax: 70,
                backgroundColor: 'rgba(255, 99, 132, 0.25)'
              }
            }
          }
*/
        },
        scales: {
          x: {
            ticks: {
              font: { size: 10, family: '"Open Sans", sans-serif' },
              autoSkip: true,
              maxRotation: 0,
              minRotation: 0,
              color: "#000"
            }
          },
          y: {
            position: 'left',
            title: {
              font: { size: 15, family: '"Open Sans", sans-serif' },
              display: true,
              text: '',
              color: "#1976d2"
            },
            grid: {
              color: '#f7f7f7'
            },
            ticks: {
              font: { size: 10, family: '"Open Sans", sans-serif' },
              color: "#000"
            }
          }
        }
      },
      chartData: [],
      chartOptions: [],
      graphColors: ["#3F51B5", "#E91E63", "#9C27B0", "#009688", "#FF9800", "#4CAF50", "#FF5722", "#00695C", "#311B92"]
    };
  },

  mixins: [
    Utils
  ],

  methods: {
    showTooltip(ctx, event) {
      let tooltip = document.getElementById("overlayTooltip");
      tooltip.style.left = event.x + "px";
      tooltip.style.top = event.y + "px";
      tooltip.style.display = 'block';
    },
    overlayClick(ctx, event) {
      console.log(ctx, event);
    },
    hideTooltip() {
      document.getElementById('overlayTooltip').style.display = 'none'; 
    },
    navigateToIncidents(row) {
      var monDevIds = [], monDevNames = [], states = []//, advSearchString = "";
      monDevIds = [row.monDevId]
      monDevNames = [row.monDevName]
      states = this.utilsOpenStates;
      var query = [];
      if (monDevIds.length && monDevNames.length) {
        query.push({ name: "component", value: { componentIdList: monDevIds, componentNameList: monDevNames } });
      }
      query.push({ name: "state", value: states });
      query.length ? this.$router.push({ name: "INCIDENTS", query: query }) : this.$router.push({ name: "INCIDENTS" });
    },
    navigateToMonTemplate(monTemplateId) {
      monTemplateId
      const r = this.$router.resolve({ name: "MON_TEMPLATES" });
      window.open(r.href, '_blank')
    },
    cancel() {
      this.$router.go(-1);
    },
    getSitesList() {
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      //this.isLoading=true;
      ApiService.get("/api/v1/site", (err, result) => {
        this.isLoading = false;
        if (err) {
          this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
          this.utilsCheckLogout(this.apiStatus);
        } else {
          this.apiStatus = null;
          this.sites_list = result.data.data.objs;
          this.getDeviceDetails();
        }
      },
        query_params
      );
    },

    updateMonitoredDeviceStatus(monDevStsList) {
      let monDevSts = monDevStsList[0]
      this.devicedetails.highestSeverity = monDevSts.highestSeverity
      this.devicedetails.incCount = monDevSts.incCount
      this.devicedetails.faultStatus = monDevSts.faultStatus
    },
    getMonitoredDevicesStatusList() {
      let query_params = {}, body = {};
      if (this.targetOrgId) query_params.targetOrgId = this.targetOrgId;
      else if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      this.isLoading = true

      query_params.summaryDataType = this.UtilsModaConstants.FaultService.Summary.DataType.MONITORED_DEVICES,
        query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.LISTS

      body = [this.devicedetails.monDevId]

      ApiService.post("/svcSummary/api/v1/summary", body, (err, result) => {   //### svcFault
        this.isLoading = false;
        if (err) {
          this.apiStatus = "Failed to fetch device's fault status"
        } else {
          this.apiStatus = null;
          this.updateMonitoredDeviceStatus(result.data.data)
          this.statusKey1 += 1; // key attribute of elements that depend on fault status data so they can be re-rendered forcefully
        }
      }, query_params, "SUMMARY_SERVICE_URL"   //###"FAULT_SERVICE_URL"
      );
    },
    getDeviceDetails() {
      let query_params = {};
      this.isLoading = true;
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      InventoryService.getDeviceDetails(this.device_id, query_params).then((result) => {
        this.isLoading = false;
        if (result.data.data[0]) {
          this.apiStatus = null;
          this.devicedetails = result.data.data[0];
          //DEBUG: console.log("devicedetails = " + JSON.stringify(this.devicedetails, null, 2));
          this.adminStatus = this.devicedetails.adminStatus;
          if (this.devicedetails.services) {
            this.devicedetails.serviceNames = this.devicedetails.services.map((s) => s.service + "/" + s.port);
          }

          this.devicedetails.firstSeenStr = this.utilsFormatDateTime(this.devicedetails.monDevFirstSeen);
          this.devicedetails.lastSeenStr = this.utilsFormatDateTime(this.devicedetails.monDevLastSeen);
          this.devicedetails.upTimeStr = this.utilsFormatSeconds2Str(this.devicedetails.monDevUptime);

          this.titleName = "View - " + this.devicedetails.monDevName;
          this.getMonitoredDevicesStatusList();
          this.getTemplates();
        }
      }).catch((err) => {
        this.isLoading = false;
        this.apiStatus = err ? err.response.data.message ? err.response.data.message : err.response.data : "Request failed";
        this.utilsCheckLogout(this.apiStatus);
      });
    },
    getMonitoringTemplates() {
      InventoryService.getMonitoringTemplates().then((result) => {
        this.apiStatus = null;
        this.monTemplatesList = result.data.data;
      }).catch((err) => {
        this.apiStatus = err ? err.response.data.message ? err.response.data.message : err.response.data : "Request failed";
        this.utilsCheckLogout(this.apiStatus);
      });
    },
    processBreadcrumbMessage(e) {
      this.getDeviceDetails();
    },
    getTemplates() {
      let body = { monDevIpAddress: this.devicedetails.monDevIpAddress };
      InventoryService.getTemplateInfo(body).then((res) => {
        //console.log("InventoryService.getTemplates " + JSON.stringify(res))
        this.apiStatus = null;
        this.chartTemplates = res.data.data.info.templateDetails;
        this.templateInfoId = this.chartTemplates[0];
      }).catch((err) => {
        this.apiStatus = err ? err.response.data.message ? err.response.data.message : err.response.data : "Failed to fetch Devices' fault status";
      });
    },
    getMetricsData() {
      this.isGrpahLoading = this.templateInfoIds.length > 0;
      this.chartData = [];
      this.chartOptions = [];

      if (this.templateInfoIds.length > 9) {
        this.templateInfoIds.pop();
      }

      let body = {
        timeFromEpochSec: moment().subtract(Number(this.metricTimeWindow.split(' ')[0]), this.metricTimeWindow.split(' ')[1]).unix(),
        timeToEpochSec: moment().unix(),
        maxBuckets: 32
      };
      this.templateInfoIds.forEach((el, index) => {

        this.chartData.push(JSON.parse(JSON.stringify(this.ChartData)));
        this.chartOptions.push(JSON.parse(JSON.stringify(this.ChartOptions)));
        body.itemid = el.itemid;
        InventoryService.getMetricsData(body).then((res) => {
          let timeFormat = this.metricTimeWindow.split(' ')[1] == 'h' ? ' h:mmA ' : ' D MMM h:mmA ';
          this.apiStatus = null;
          if (res.data.data) {
            let itemid = res.data.data.itemid;
            this.chartData[index].datasets[0].label = this.chartTemplates.find((o) => { return o.itemid == itemid }).name;
            this.chartOptions[index].scales.y.title.text = this.chartTemplates.find((o) => { return o.itemid == itemid }).units;
            this.chartOptions[index].elements.line.borderColor = this.graphColors[index];
            this.chartOptions[index].scales.y.title.color = this.graphColors[index];
            this.chartData[index].datasets[0].backgroundColor = this.graphColors[index]; 
            this.chartData[index].datasets[0].data = [];
            this.chartData[index].labels = [];
            res.data.data.history.forEach((item) => {
              this.chartData[index].datasets[0].data.push(Number(item.value));
              this.chartData[index].labels.push(moment.unix(item.clock).format(timeFormat));
            });
          }
          this.isGrpahLoading = false;
        }).catch((err) => {
          this.apiStatus = err ? err.response.data.message ? err.response.data.message : err.response.data : "Failed to fetch Devices' fault status";
        });
      });



    }
  },
  created() {
  },
  mounted() {
    if ( this.$route.params && this.$route.params.targetOrgId ){
        this.$refs.breadcrumb.navigateToOrg(this.$route.params.targetOrgId)
        this.currOrgId = this.$route.params.targetOrgId
    } else
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    this.device_id = this.$route.params.id;
    this.readonly = this.$route.params.readonly == "1";
    this.getSitesList();
    this.getMonitoringTemplates();
  }
};
</script>
