<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-window-shutter-cog</v-icon> System Profiles
				<v-btn rounded outlined color="primary" absolute right :class="isShow" v-on:click="createSystemConfigProfile()">
					<v-icon>mdi-plus</v-icon> New
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<div class="search-wrapper">
					<v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify"/>
				</div>
			</v-card-text>
			<v-container fluid class="pt-0">
				<v-data-table :loading="isLoading" :headers="headers" :search="filterValueSearch"
					:items="systemConfigProfile_table_rows" multi-sort
					:footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }">
					<template v-slot:item.systemConfigProfileName="{ item }">
						<nobr>
							<span class="float-left">{{ item.systemConfigProfileName }}
								<v-chip v-if="utilsIsSystem(item)" x-small>
									SYS
								</v-chip>
							</span>
						</nobr>
					</template>
					<template v-slot:item.lte="{ item }">
						<span v-if="item.profileDetails.lte && item.profileDetails.lte.lte0">
							LTE0:
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-icon v-bind="attrs" v-on="on" x-large
										:color="utilsSyslogStatusDisplayObject(item.profileDetails.lte.lte0.adminStatus).colorName">
										{{utilsSyslogStatusDisplayObject(item.profileDetails.lte.lte0.adminStatus).icon}}
									</v-icon>
								</template>
								<span>{{utilsSyslogStatusDisplayObject(item.profileDetails.lte.lte0.adminStatus).text}}</span>
							</v-tooltip>
							{{ item.profileDetails.lte.lte0.apn + ", " + item.profileDetails.lte.lte0.preferredSIMSlot }}
						</span>
					</template>
					<template v-slot:item.numDeviceConfigMaps="{ item }">
						<a v-if="item.numDeviceConfigMaps" href="javascript:;" :title="item.numDeviceConfigMaps"
							v-on:click="navigateToDeviceConfigMaps(item.systemConfigProfileName)"> {{itemnumDeviceConfigMaps}} </a>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-icon @click="editSystemConfigProfileDetails(item.systemConfigProfileId, 1)" class="mr-2" color="primary"
							title="View">
							mdi-eye
						</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)"
							@click="editSystemConfigProfileDetails(item.systemConfigProfileId, 0)" class="mr-2" title="Edit"
							color="warning">mdi-pencil </v-icon>
						<v-icon v-if="item.numDeviceConfigMaps == 0" :class="utilsGetDisplayClass(item)"
							@click="deleteSystemConfigProfileDetails(item.systemConfigProfileId, item.systemConfigProfileName)"
							color="red" title="Delete">
							mdi-delete</v-icon>
					</template>
				</v-data-table>
			</v-container>
		</v-card>

		<DeleteDialog v-if="dialog" :currentObjectName="currentObjectName" :isBackupBeforeDelete="false"
			@DeleteDialogCallback='deleteDeviceDetailsChildEvent' :delMessage="deleteMessage">
		</DeleteDialog>

	</div>
</template>

<script>

import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'

export default {
    components: {
        Breadcrumb,
        DeleteDialog,
    },
    mixins: [
        Utils,
    ],
    data(){
        var headers = [
            {
                text: 'Name',
                value: 'systemConfigProfileName',
                filterable: true
            },
            {
                text: 'Belongs To',
                value: 'orgName',
                filterable: true,
            },
            {
                text: 'Device Model',
                value: 'deviceModelName',
                filterable: true,
            },
            {
                text: 'Stats Reporting Intrvl',
                value: 'statsReportingIntervalSecs',
                filterable: true,
            },
            {
                text: 'LTE',
                value: 'lte',
                filterable: true,
            },
            {
                text: 'Used by',
                value: 'numDeviceConfigMaps',
                filterable: true,
            },
            {
                text: 'Current version',
                value: 'systemConfigProfileVersion',
                filterable: true,
            },
            {
                text: 'Last changed',
                value: 'modifiedDateString',
                filterable: true,
            },
            {
                text: 'Actions',
                value: 'actions',
            },
        ];
        return {
            currOrgId: null,
            headers,
            systemConfigProfile_table_rows: [],
            isLoading: false,
            userdetails:{},
            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Profiles & Jobs"},
                           {name:'System Config Profiles', active:true} ],
            apiStatus: null,
            dialog: false,
            currentObjectId: null,
            currentObjectName: '',
        };
    },
    methods: {
        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
        buildFilterableColumns(objs){
            objs.forEach((obj) => {
                obj.statsReportingIntervalSecs = obj.profileDetails.statsReportingIntervalSecs; // display, search, sort only works for first level params
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                obj.modifiedDateString = this.utilsFormatDateTime(obj.modifiedDate);
                if ( obj.deviceModel ) obj.deviceModelName = obj.deviceModel.deviceModelName;
                if ( obj._children ) this.buildFilterableColumns(obj._children);
            })
        },
        navigateToApplication(id){
            var readonly = 1
            this.$router.push({name: "APPLICATIONS_EDIT", params: { appId: id, readonly: readonly} } )
        },
        navigateToDeviceConfigMaps(name){
            this.$router.push( { name: "DEVICE_CONFIG_MAP_FILTER", params : { configProfileName: encodeURIComponent(name)}})
        },
        getSystemConfigProfileList(){
            this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/systemConfigProfile", (err, result) => {
                this.isLoading=false
                //console.log("getSystemConfigProfileList " + JSON.stringify(result.data));
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.systemConfigProfile_table_rows=result.data.data
                    this.buildFilterableColumns(this.systemConfigProfile_table_rows);
                }

            }, query_params)
        },
        editSystemConfigProfileDetails(id, readonly){
            this.$router.push( { name : "SYS_CONFIG_PROFILES_EDIT", params : { systemConfigProfileId: id, readonly: readonly}})
        },
        deleteSystemConfigProfileDetails(Id, name){
            this.dialog = true;
            this.currentObjectId = Id;
            this.currentObjectName = name;
        },

        deleteDeviceDetailsChildEvent(cancelFlag, isBackupDel) {
		console.log("** Delete Site:"+  this.currentDeviceId + ' ' + this.currentDeviceName + ' ' + isBackupDel);
		this.dialog = false;
		if(cancelFlag == true) return;

        // if(confirm("Do you really want to delete - '" + name + "' ?"))
        {
                this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
                query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

                ApiService.delete("/api/v1/systemConfigProfile/"+this.currentObjectId, (err, result) => {
                    //console.log("**"+JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getSystemConfigProfileList();
                    }
                }, query_params)
            }
        },
        createSystemConfigProfile(){
            this.$router.push( { name : "SYS_CONFIG_PROFILES_CREATE" })
        },
        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.getSystemConfigProfileList();
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.get_auth_details();
        this.getSystemConfigProfileList();
    }
}
</script>
