<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-home-city</v-icon>Organizations {{ titleName }}
                <v-btn v-if="isEdit" class="status-button" :color="utilsGetSeverityColor(orgdetails.highestSeverity).colorName" rounded outlined right absolute>
                Status: <v-icon>{{ utilsFaultStatusDisplayObject(orgdetails.faultStatus).icon }}</v-icon><strong>{{ orgdetails.highestSeverity ?
                    orgdetails.highestSeverity :utilsFaultStatusDisplayObject(orgdetails.faultStatus).text }} </strong>
            </v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<!-- begin create/edit form -->
				<v-container fluid class="pl-5 pr-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<v-row v-if="isEdit" class="md-content-stretch" :key="statusKey1">
						<v-col cols="12" md="auto">
							<v-alert text class="link-alert" prominent color="lime darken-2" icon="mdi-router-wireless"
								@click="navigateToSites(orgdetails)">
								<p class="mb-0">Sites</p>
								<h3>
									{{ orgdetails.siteCount || 0 }}
								</h3>
							</v-alert>
						</v-col>
						<v-col cols="12" md="auto">
							<v-alert text class="link-alert" prominent type="warning" icon="mdi-ticket-confirmation"
								@click="navigateToIncidents(orgdetails)">
								<p class="mb-0">Incidents</p>
								<h3>
									{{ orgdetails.incCount || 0 }}
								</h3>
							</v-alert>
						</v-col>
						<v-col cols="12" md="auto">
							<v-alert text class="link-alert" prominent type="info" icon="mdi-apps"
								@click="navigateToApplications(orgdetails)">
								<p class="mb-0">Applications</p>
								<h3>
									{{ orgdetails.applicationCount || 0 }}
								</h3>
							</v-alert>
						</v-col>
						<v-col cols="12" md="auto">
							<v-alert text class="link-alert" prominent color="deep-purple" icon="mdi-router-wireless"
								@click="navigateToMonitoredDevices(orgdetails)">
								<p class="mb-0">Devices</p>
								<h3 :key="countsKey1">
									{{ orgdetails.monDevCount || 0 }}
								</h3>
							</v-alert>
						</v-col>
					</v-row>
					<form ref="orgform">
						<v-row v-if="isLoading">
							<v-col cols="12">
								<v-progress-linear indeterminate color="primary"></v-progress-linear>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="2">
								<v-switch v-model="utilStatus1" :true-value="UtilsModaConstants.AdminStatus.ENABLED"
									:false-value="UtilsModaConstants.AdminStatus.DISABLED" inset :label="utilsStatusLabelString(null, utilStatus1)" :disabled="readonly">
								</v-switch>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field v-model="orgdetails.orgName" :error-messages="nameErrors"
									@input="$v.orgdetails.orgName.$touch()" @blur="$v.orgdetails.orgName.$touch()" class="moda-required"
									label="Org Name" :disabled="readonly" />
							</v-col>
							<v-col cols="12" md="6">
								<v-text-field v-model="orgdetails.description" label="Description" :disabled="readonly">
								</v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field dense v-model="orgdetails.contactName" label="Contact Name" :disabled="readonly">
								</v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field dense v-model="orgdetails.email" :error-messages="emailErrors"
									@input="$v.orgdetails.email.$touch()" @blur="$v.orgdetails.email.$touch()" class="moda-required"
									label="Contact Email" :disabled="readonly" />
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field dense v-model="orgdetails.phone" label="Phone" :disabled="readonly"></v-text-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-text-field dense v-model="orgdetails.address.line1" label="Address Line 1" :disabled="readonly">
								</v-text-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-text-field dense v-model="orgdetails.address.line2" label="Address Line 2" :disabled="readonly">
								</v-text-field>
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field dense v-model="orgdetails.address.city" :error-messages="addressCityErrors"
									class="moda-required" label="City" @input="$v.orgdetails.address.city.$touch()"
									@blur="$v.orgdetails.address.city.$touch()" :disabled="readonly" />
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field dense v-model="orgdetails.address.state" label="State" :disabled="readonly">
								</v-text-field>
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field dense v-model="orgdetails.address.zipcode" label="Zipcode" :disabled="readonly">
								</v-text-field>
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field dense v-model="orgdetails.address.country" label="Country" :disabled="readonly">
								</v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field dense v-model="orgdetails.billingCode0" label="Billing Code 1" :disabled="readonly">
								</v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field dense v-model="orgdetails.billingCode1" label="Billing Code 2" :disabled="readonly">
								</v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field dense v-model="orgdetails.billingCode2" label="Billing Code 3" :disabled="readonly">
								</v-text-field>
							</v-col>
						</v-row>
					</form>
				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="!readonly && !isEdit" @click="submit"
					:disabled="this.$v.$invalid">
					Create</v-btn>
				<v-btn rounded outlined color="success" v-if="!readonly && isEdit" @click="submit" :disabled="this.$v.$invalid">
					Update</v-btn>
				<v-btn rounded outlined color="error" v-if="!readonly" @click="cancel">Cancel</v-btn>
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import ApiService from '@/services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
import Utils from '@/pages/moda/Utils'

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            currOrgId: null,
            isLoading: false,
            fullPage: true,
            onCancel:true,
            height:35,
            width:35,
            types_list:[{id:'sp',value:'SP'},{id:'msp',value:'MSP'}],
            beingEditedOrgId:'',
            titleName:'Create',
            readonly:true,
            isEdit:false,
            statusKey1: 0, // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
            countsKey1: 1000, // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully

            userdetails:JSON.parse(localStorage.getItem('user-details')),
            orgdetails:{
                orgId :'',
                orgName : '',
                description : '',
                type : '',
                address : {
                    line1:'',
                    line2:'',
                    city:'',
                    state:'',
                    country:'',
                    zipcode:'',
                },
                contactName : '',
                email : '',
                phone  : '',
                billingCode0 :'',
                billingCode1 :'',
                billingCode2 :'',
                icon:'',
           },
           parent_pages:[ {name:'Home'}, {name:'Organizations', route:{ name: 'ORGS'}},
                          {name:'Create/Edit', active:true} ],
           apiStatus: null,
        };
    },

    mixins: [validationMixin, Utils],
    validations: {
        orgdetails : {
            orgName: { required, minLength: minLength(3) },
            email: { required, email },
            address: {
                city: { required },
            },
        },
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.orgdetails.orgName.$dirty) return errors
            !this.$v.orgdetails.orgName.required && errors.push('Name is required.')
            !this.$v.orgdetails.orgName.minLength && errors.push('Name must be atleast 3 characters long')
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.orgdetails.email.$dirty) return errors
            !this.$v.orgdetails.email.required && errors.push('Email is required.')
            !this.$v.orgdetails.email.email && errors.push('Invalid Email.')
            return errors
        },
        addressCityErrors () {
            const errors = []
            if (!this.$v.orgdetails.address.city.$dirty) return errors
            !this.$v.orgdetails.address.city.required && errors.push('City is required.')
            return errors
        },
    },

    methods: {

        navigateToSites(row) {
          console.log("navigateToSites orgdetails ", JSON.stringify(row.orgId));
          // empty query is needed. Else, that component will use default query
          this.$router.push({ name: "SITES", query: [], params: { targetOrgId: row.orgId }, })
        },
        navigateToApplications(row) {
          console.log("navigateToApplications orgdetails ", JSON.stringify(row.orgId));
          // empty query is needed. Else, that component will use default query
          this.$router.push({ name: "APPLICATIONS", query: [], params: { targetOrgId: row.orgId }, })
        },
        navigateToMonitoredDevices(row) {
          console.log("navigateToMonitoredDevices orgdetails ", JSON.stringify(row.orgId));
          // empty query is needed. Else, that component will use default query
          this.$router.push({ name: "MONITORED_DEVICES", query: [], params: { targetOrgId: row.orgId }, })
        },
        navigateToIncidents(row) {
          var states = this.utilsOpenStates;
          var query = [];
/*
          //console.log("navigateToIncidents orgRowSelected ", JSON.stringify(row));
          var orgIds = [], orgNames=[], states = [], advSearchString = "";
          orgIds = Array.isArray(row) ? row.map ( (r) => r.orgId ) : [row.orgId]
          orgNames = Array.isArray(row) ? row.map ( (r) => r.orgName ) : [row.orgName]
          advSearchString = "Open incs. in " + orgNames;
          if (orgIds.length) query.push({ name: "orgId", value: orgIds });
          if (orgNames.length) query.push({ name: "orgName", value: orgNames });
            // TBD : componentId, componentName ?????
          if (states.length) query.push({ name: "state", value: states });
          console.log("navigateToIncidents query ", JSON.stringify(query));
          query.length
            ? this.$router.push({ name: "INCIDENTS", query: query, params: { advSearchString: advSearchString, targetOrgId: row.orgId }, })
            : this.$router.push({ name: "INCIDENTS" });
*/

          query.push({ name: "state", value: states });
          console.log("navigateToIncidents query ", JSON.stringify(query));
          query.length ? this.$router.push({ name: "INCIDENTS", query: query, params: { targetOrgId: row.orgId }, }) :
                     this.$router.push({ name: "INCIDENTS", params: { targetOrgId: row.orgId } });
        },

        submit () {
            this.$v.$touch()

            //console.log("submit*****"+JSON.stringify(this.orgdetails))

            this.isLoading=true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            this.orgdetails.orgName         = this.orgdetails.orgName.trim();
            this.orgdetails.email           = this.orgdetails.email.trim();
            if ( this.orgdetails.contactName )
                this.orgdetails.contactName     = this.orgdetails.contactName.trim();
            if ( this.orgdetails.phone )
                this.orgdetails.phone           = this.orgdetails.phone.trim();
            if ( this.orgdetails.address.line1 )
                this.orgdetails.address.line1   = this.orgdetails.address.line1.trim();
            if ( this.orgdetails.address.line2 )
                this.orgdetails.address.line2   = this.orgdetails.address.line2.trim();
            this.orgdetails.address.city    = this.orgdetails.address.city.trim();
            if ( this.orgdetails.address.state )
                this.orgdetails.address.state   = this.orgdetails.address.state.trim();
            if ( this.orgdetails.address.country )
                this.orgdetails.address.country = this.orgdetails.address.country.trim();
            if ( this.orgdetails.address.zipcode )
                this.orgdetails.address.zipcode = this.orgdetails.address.zipcode.trim();

            this.orgdetails.adminStatus = this.utilStatus1;

            this.orgdetails.billingCode    = []
            this.orgdetails.billingCode[0] = this.orgdetails.billingCode0 ? this.orgdetails.billingCode0.trim() : null;
            this.orgdetails.billingCode[1] = this.orgdetails.billingCode1 ? this.orgdetails.billingCode1.trim() : null;
            this.orgdetails.billingCode[2] = this.orgdetails.billingCode2 ? this.orgdetails.billingCode2.trim() : null;
            delete this.orgdetails.billingCode0
            delete this.orgdetails.billingCode1
            delete this.orgdetails.billingCode2
            if ( this.isEdit ){
                ApiService.put("/api/v1/org/" + this.beingEditedOrgId,this.orgdetails, (err, result) => {
                    //console.log("*update*"+JSON.stringify(result))
                    this.isLoading=false;
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateOrg()
                    }
                }, query_params)
            }else {// create
                ApiService.post("/api/v1/org", this.orgdetails, (err, result) => {
                    //console.log("*create*"+JSON.stringify(err) + " " + JSON.stringify(result))
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateOrg()
                    }
                    // update breadcrumb if org is created
                    this.$refs.breadcrumb.getOrganizationsList(this.currOrgId);
                }, query_params)
            }
        },
        cancel () {
            this.$v.$reset()
            this.cancelCreateOrg()
        },
        cancelCreateOrg(){
            this.$router.go(-1)
        },
        updateOrgStatus ( orgStsList ){
            let orgSts = orgStsList[0]
            this.orgdetails.highestSeverity = orgSts.highestSeverity
            this.orgdetails.incCount = orgSts.incCount
            this.orgdetails.faultStatus = orgSts.faultStatus
        },
        getMonitoredDevicesCount() {
          let query_params = {}, body={};
          query_params.targetOrgId = this.beingEditedOrgId;

          this.isLoading=true

          body.orgIds = [this.beingEditedOrgId]

          //console.log("getOrganizationsStatusList body " + JSON.stringify(body))

          ApiService.post( "/svcInventory/api/v1/monitoredDevice/counts", body, (err, result) => {
              this.isLoading = false;
              if (err) {
                this.apiStatus = "Failed to fetch devices counts"
                //this.utilsCheckLogout(this.apiStatus);
              }else {
                this.apiStatus = null;
                //console.log("**** getMonitoredDevicesCount result = " + JSON.stringify(result, null, 2))
                // Output format
                //[{"orgId":"orgobj.1000","sites":[{"siteId":"sitobj.2000078_1fd8ba97-748f-4e00-ad71-05d6a246d73f","monDevCount":4},{"siteId":"sitobj.2000046_81222a6d-fe4f-472f-909c-19e63c8eb2ed","monDevCount":84}],"monDevCount":88},{"orgId":"orgobj.2001_68e21c61-014b-4bcb-9119-31607cd0bf3e","sites":[{"siteId":"sitobj.2000111_0feb497d-3bbe-4885-9a99-bc9ba2374ca7","monDevCount":1}],"monDevCount":1}]

                this.orgdetails.monDevCount =  result.data.data.length ? result.data.data[0].monDevCount : 0
                this.countsKey1 +=1 ; // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
                //console.log("**** getMonitoredDevicesCount orgdetails = " + JSON.stringify(this.orgdetails, null, 2))
              }
            }, query_params, "INVENTORY_SERVICE_URL"
          );
        },

        getOrganizationsStatusList() {
          let query_params = {}, body={};
          query_params.targetOrgId = this.beingEditedOrgId;

          this.isLoading=true

          query_params.summaryDataType = this.UtilsModaConstants.FaultService.Summary.DataType.ORGS,
          query_params.summaryType = this.UtilsModaConstants.FaultService.Summary.Type.LISTS

          body = [ this.beingEditedOrgId ]

          //console.log("getOrganizationsStatusList body " + JSON.stringify(body))

          ApiService.post( "/svcSummary/api/v1/summary", body, (err, result) => {       //### svcFault
              this.isLoading = false;
              if (err) {
                this.apiStatus = "Failed to fetch orgs' fault status"
                //this.utilsCheckLogout(this.apiStatus);
              }else {
                this.apiStatus = null;
                //console.log("**** getOrganizationsStatusList orgs result = " + JSON.stringify(result, null, 2))
                this.updateOrgStatus(result.data.data)
                //console.log("**** getOrganizationsStatusList orgs = " + JSON.stringify(this.orgdetails, null, 2))
                this.statusKey1 +=1 ; // key attribute of elements that depend on faultStatus data so they can be re-rendered forcefully
                this.getMonitoredDevicesCount()
              }
            }, query_params, "SUMMARY_SERVICE_URL"   //###"FAULT_SERVICE_URL"
          );
        },
        getOrgDetails(){
            //this.isLoading=true

            let query_params = {};
            query_params.targetOrgId = this.beingEditedOrgId;

            ApiService.get("/api/v1/org/" + this.beingEditedOrgId, (err, result) => {
                //console.log(JSON.stringify(result.data.data[0]))
                this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else if ( result.data.data[0] ){
                    this.apiStatus = null;
                    this.orgdetails = result.data.data[0];
                    if ( this.orgdetails.billingCode ){
                        this.orgdetails.billingCode0 = this.orgdetails.billingCode[0];
                        this.orgdetails.billingCode1 = this.orgdetails.billingCode[1];
                        this.orgdetails.billingCode2 = this.orgdetails.billingCode[2];
                    }
                    this.utilStatus1 = this.orgdetails.adminStatus;
                    this.titleName = (this.readonly ? "View - " : "Edit - ") +this.orgdetails.orgName
                    this.getOrganizationsStatusList()
                }
            }, query_params)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            if(this.beingEditedOrgId != "" && this.beingEditedOrgId !=undefined){
                // Edit can't be done when org is changed, so g back
                this.cancelCreateOrg();
            }
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.beingEditedOrgId = this.$route.params.id;
        this.readonly = (this.$route.params.readonly == "1");
        if(this.beingEditedOrgId != "" && this.beingEditedOrgId !=undefined){
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
            this.getOrgDetails();
        }
    }
}

</script>


