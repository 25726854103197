<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />

		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-transit-connection-variant</v-icon> Correlation Rules
				<v-btn rounded outlined color="primary" absolute right :class="isShow" @click="createIncidentCorrelationRule()">
					<v-icon>mdi-plus</v-icon> New
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<div class="search-wrapper">
					<v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify" />
				</div>
			</v-card-text>
			<v-container fluid class="pt-0">
				<v-data-table :loading="isLoading" :headers="incidentCorrelationRuleTableColumnsVueTable" multi-sort
					no-data-text="No rules" :items="incidentCorrelationRule_table_rows" :options.sync="pagingOptions"
					:footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }" item-key="correlationRuleId">

<!-- no need additional column, its part of actions
					<template v-slot:item.status="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" x-large
									:color="utilsAdminStatusDisplayObject(item.adminStatus).colorName">
									{{ utilsAdminStatusDisplayObject(item.adminStatus).icon }}
								</v-icon>
							</template>
							<span>{{ utilsAdminStatusDisplayObject(item.adminStatus).text }}</span>
						</v-tooltip>
					</template>
-->

					<template v-slot:item.correlationRuleDefinition.rootCause.all[0].categoryType.in="{ item }">
						{{ item.correlationRuleDefinition.rootCause.all[0].categoryType.in.join(", ") }}
					</template>
					<template v-slot:item.correlationRuleDefinition.rootCause.all[1].categorySubType.in="{ item }">
						{{ item.correlationRuleDefinition.rootCause.all[1].categorySubType.in.join(", ") }}
					</template>

					

					<template v-slot:item.actions="{ item }">
                        <v-icon :class="utilsGetDisplayClass(item)" @click="setAdminStatus(item)" x-large
							:color="utilsAdminStatusDisplayObject(item.adminStatus).colorName"
							title="Enable">{{ utilsAdminStatusDisplayObject(item.adminStatus).icon }}</v-icon>
						<v-icon @click="viewIncidentCorrelationRuleDetails(item.correlationRuleId, 1)" color="primary" title="View">mdi-eye </v-icon>
						<v-icon :class="utilsGetDisplayClass(item)"
							@click="editIncidentCorrelationRuleDetails(item.correlationRuleId, 0)" color="warning" title="Edit">mdi-pencil</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)"
							@click="deleteIncidentCorrelationRule(item.correlationRuleId, item.correlationRuleName)" color="error"
							title="Delete">mdi-delete</v-icon>
					</template>

				</v-data-table>
			</v-container>
		</v-card>

		<GeneralConfirmDialog v-if=setAdminStatusDialog :generalDialogMessage="adminStatusDialogMessage"
			@GeneralConfirmDialogCallback='setAdminStatusChildIncident'>
		</GeneralConfirmDialog>

		<DeleteDialog v-if="deleteDialog" :currentObjectName="currName" @DeleteDialogCallback='deleteObjectChildIncident'>
		</DeleteDialog>

	</div>
</template>

<script>

import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import GeneralConfirmDialog from '@/components/dialogs/GeneralConfirmDialog.vue'
import ModaConst from '../../services/ModaConstants.js'

export default {
    name: 'Rules',
    components: {
        Breadcrumb,
        DeleteDialog,
        GeneralConfirmDialog,
    },
    mixins: [
        //AdsTreeTableUtils,
        Utils,
    ],
    data(){
        var incidentCorrelationRuleTableColumnsVueTable = [
           {
               text: 'Name',
               value: 'correlationRuleName',
               sortable: true,
               filterable: true,
           },
/* Not needed until we add Root Device Type
           {
               text:'Site',
               value:'siteId',
               sortable: true,
               filterable: true,
           },
*/
           {
               text:'Root Device(s)',
               //value:'correlationRuleDefinition.rootCause.all[2].componentId.in',
               value:'correlationRuleDefinition.rootComponentNames',
               sortable: true,
               filterable: true,
           },
           {
               text:'Root Incident Type(s)',
               value:'correlationRuleDefinition.rootCause.all[0].categoryType.in',
               sortable: true,
               filterable: true,
           },
           {
               text:'Root Incident Subtype(s)',
               value:'correlationRuleDefinition.rootCause.all[1].categorySubType.in',
               sortable: true,
               filterable: true,
           },
           {
               text:'Time window (mins)',
               value:'correlationRuleDefinition.timePeriodMins',
               sortable: true,
               filterable: true,
           },
/* Only one type for now
           {
               text:'Related Incident type',
               value:'correlationRuleDefinition.dependent.all[0].categoryType.in',
               sortable: true,
               filterable: true,
           },
*/
           {
               text:'Related App(s)/Site',
               //value:'correlationRuleDefinition.dependent.all[1].componentId.in',
               value:'correlationRuleDefinition.dependentComponentNames',
               sortable: true,
               filterable: true,
           },
           {
               text:'Created',
               value:'createdDateString',
               sortable: true,
               filterable: true,
           },
/* no need additional column, its part of actions
            {
                text: "Status",
                value: "status",
                sortable: true,
                filterable: true,
            },
*/
           {
               text:'Actions',
               value:'actions',
               sortable: false,
               filterable: false,
               width: 160
           },
        ];

        return {
            readonly:true,
            currOrgId: null,
            //advSearchCriteria: null,
            //advSearchString: null,

            // Rules data
            incidentCorrelationRuleTableColumnsVueTable,
            incidentCorrelationRule_table_rows: [],
            componentList: [],
            monDevList: [],

            isLoading: false,
            userdetails:{},

            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, {name: "Configuration"},
                           {name:'Correlation Rules', active:true} ],
            apiStatus: null,

            setAdminStatusDialog: false,
            adminStatusDialogMessage: "",
            deleteDialog:false,
            currId:null,
            currName:null,

            pagingOptions:this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
            //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable
        };
    },


    //=== Hooks (Refer to IncidentsAlarms.vue for usage of all hooks) ==============
    mounted() {
        //console.log("Incidents Rules mounted query " + JSON.stringify(this.$route.query))
        //console.log("Incidents Rules mounted params " + JSON.stringify(this.$route.params))
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.get_auth_details();
        //this.utilsDataRefreshFunc = this.getCorrelationRulesList;
        this.utilsDataRefreshFunc = this.getMonDevList;
    },

    watch: {
      pagingOptions: {
        handler () {
            //console.log("rule watch pagingOptions " + JSON.stringify(this.pagingOptions));
            //this.getCorrelationRulesList();
            this.getMonDevList();
        },
        deep: false,
        immediate: false,
      },

    },
    methods: {
        handlePageChange(value) {
            this.currentPage = value;
        },

        isCancellable(){
            return true;
        },

        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. 
        // So expand/build fields here
        buildFilterableColumns(objs) {
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                let defn = obj.correlationRuleDefinition;

                // map root component IDs to names
                //console.log("defn " + JSON.stringify(defn.rootCause.all[2].componentId))
                defn.rootComponentNames = []
                if ( defn.rootCause.all[2].componentId )
                    defn.rootComponentNames = defn.rootComponentNames.concat(defn.rootCause.all[2].componentId.in.reduce (
                                                                            (arr, val) => {
                                                                                let m = this.monDevList.find( (a) => a.monDevId == val)
                                                                                //console.log("arr " + JSON.stringify(arr) + ", val " + val + " monDev " +
                                                                                        //JSON.stringify(m))
                                                                                if ( m ) arr.push(m.monDevName)
                                                                                return arr;
                                                                            }, []))
                if ( defn.rootCause.all[2].monDevType )
                    defn.rootComponentNames = defn.rootComponentNames.concat(defn.rootCause.all[2].monDevType.in)

                //console.log("defn " + JSON.stringify(defn.rootCause.all[2].componentId))
                defn.dependentComponentNames = []
                if ( defn.dependent.all[1].componentId )
                    defn.dependentComponentNames = defn.dependent.all[1].componentId.in.reduce (
                                                                            (arr, val) => {
                                                                                let m = this.componentList.find( (a) => a.appId == val)
                                                                                //console.log("arr " + JSON.stringify(arr) + ", val " + val + " app " +
                                                                                        //JSON.stringify(m))
                                                                                if ( m ) arr.push(m.appName)
                                                                                return arr;
                                                                            }, [])
                                                           
                if ( obj._children ) this.buildFilterableColumns(obj._children);
            })
        },

        getMonDevList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            //this.isLoading=true;
            ApiService.post("/svcInventory/api/v1/monitoredDevice/minimum", {}, (err, result) => {
                this.isLoading=false;
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.monDevList = result.data.data;

                    this.monDevList.forEach(function(md, i) {
                        if (md.monDevName.startsWith("MAC-")) {
                            md.monDevNameExt = md.monDevName.slice(4);
                        } else {
                            md.monDevNameExt = md.monDevName;
                        }
                        md.monDevNameExt += (" (" + md.monDevIpAddress + ", " + md.monDevType + ", " + md.monDevVendor + ")");
                    });
                    //DEBUG: console.log("this.monDevList = " + JSON.stringify(this.monDevList));
                    this.getAppList()
                }
            }, query_params, "INVENTORY_SERVICE_URL")
        },
        getAppList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            query_params.appType = ModaConst.AppType.APP_MONITORING

            //this.isLoading=true;
            ApiService.post("/api/v1/application/minimum", {}, (err, result) => {
                this.isLoading=false;
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.componentList = result.data.data;
                    this.componentList.unshift({appId: ModaConst.AppType.SITE_MONITORING, appName: "Site Monitoring"})
                    //console.log("this.sitesList " + JSON.stringify(this.sitesList));
                    this.getCorrelationRulesList()
                }
            }, query_params)
        },
        getCorrelationRulesList() {
            this.isLoading=true

            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            query_params.pageNum = this.pagingOptions.page;
            query_params.pageSize= this.pagingOptions.itemsPerPage;

            ApiService.get("/svcFault/api/v1/correlationRule?shorten=true",
                (err, result) => {
                this.isLoading=false;
                //console.log("getCorrelationRulesList " + JSON.stringify(result, null, 2));
                if ( err ) {
                    this.apiStatus =
                        (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //console.log("getCorrelationRulesList " + JSON.stringify(result.data.data));

                    // for paginated response 
                    //this.utilsTotalEntriesAllPages = result.data.data.totalEntries ;
                    //this.incidentCorrelationRule_table_rows=result.data.data.docs;

                    // for non-paginated response 
                    this.incidentCorrelationRule_table_rows = result.data.data;
                    this.buildFilterableColumns(this.incidentCorrelationRule_table_rows);
                    //console.log("getCorrelationRulesList " + JSON.stringify(this.incidentCorrelationRule_table_rows, null, 2));
                }

            }, query_params, "FAULT_SERVICE_URL")
        },
        createIncidentCorrelationRule() {
            this.$router.push( { name: "INCIDENT_CORRELATION_RULES_CREATE" })
        },
        viewIncidentCorrelationRuleDetails(id, readonly) {
            this.$router.push( { name: "INCIDENT_CORRELATION_RULES_VIEW", params: { id: id, readonly: readonly } })
        },
        editIncidentCorrelationRuleDetails(id, readonly) {
            this.$router.push( { name: "INCIDENT_CORRELATION_RULES_EDIT", params: { id: id, readonly: readonly } })
        },

        setAdminStatus(item) {
            item.newAdminStatus = item.newAdminStatus || item.adminStatus; // initialize first time
            item.newAdminStatus = item.newAdminStatus == this.UtilsModaConstants.AdminStatus.ENABLED ? 
                                        this.UtilsModaConstants.AdminStatus.DISABLED : this.UtilsModaConstants.AdminStatus.ENABLED;
            this.editedItem = item;
            //console.log("Adminstatus changed to " + item.newAdminStatus)
            this.adminStatusDialogMessage = "Really change " + item.correlationRuleName + " to '" + item.newAdminStatus + "'";
            this.setAdminStatusDialog = true;
        },

        setAdminStatusChildIncident(cancelFlag)
        {
            //console.log("** Set Rule's AdminStatus to Enable/Disable cancel : " + cancelFlag);
            this.setAdminStatusDialog = false;
            if (cancelFlag == true) { delete this.editedItem.newAdminStatus; delete this.editeditem; return }

            this.isLoading = true;

            let query_params = {};
            if ( this.currOrgId ) 
                query_params.targetOrgId = this.currOrgId;
            let body = {
                    //orgId: query_params.targetOrgId, 
                    //correlationRuleId: this.editedItem.correlationRuleId, 
                    adminStatus: this.editedItem.newAdminStatus
            };

            ApiService.put("/svcFault/api/v1/correlationRule/" + this.editedItem.correlationRuleId, body, (err, result) => {
                this.isLoading = false;
                if ( err ) {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.getCorrelationRulesList();
                }
            }, query_params, "FAULT_SERVICE_URL")
        },

        deleteIncidentCorrelationRule(Id, name) {
            this.deleteDialog = true;
            this.currId = Id;
            this.currName = name;
        },

        deleteObjectChildIncident(cancelFlag, isBackupDel) 
        {
            console.log("** delete " + isBackupDel);
            this.deleteDialog = false;
            if(cancelFlag == true) return;

            //if(confirm("Do you really want to delete - '" + name + "' ?"))
            {
                //this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
                query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

                ApiService.delete("/svcFault/api/v1/correlationRule/"+this.currId, 
                    (err, result) => {
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getCorrelationRulesList();
                    }
                }, query_params, "FAULT_SERVICE_URL")
            }
        },

        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            //this.getCorrelationRulesList();
            this.getMonDevList()
        },
    }

}
</script>
