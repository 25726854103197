<template>
    <div>

        <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage"/>

        <!-- begin page-header -->
        <h1 class="page-header">Device Status</h1>
        <!-- end page-header -->

        <loading :active.sync="isLoading" :can-cancel="onCancel" :is-full-page="fullPage" :height="height" :width="width"></loading>

        <v-row>
            <v-col cols="12" sm="2" md="3" v-for="(monDev, index) in monDevsList" :key="index" >
                <v-card class="mx-auto" max-width="320" :color="monDev.colorHex" dark outlined @click="monitoredDeviceClicked(monDev)"
                                                                                            :disabled="monDev.incCount == 0">

                    <v-list-item dense>
                        <font-awesome-icon v-if="monDev.imageInfo && monDev.imageInfo.icons && monDev.imageInfo.icons.fa" small class="mr-2 black"
                               :icon="monDev.imageInfo.icons.fa" /> <!-- TBD: replace icon with correct monDev.imageInfo.icons.fa value -->
                        <v-icon v-else small class="mr-2 black"> fa-solid fa-server </v-icon>
                        <v-img  v-if="monDev.imageInfo && monDev.imageInfo.images && monDev.imageInfo.images.main" 
                                :src='"/assets/img/devices/" + monDev.imageInfo.images.main' max-height="48" max-width="32" alt=""></v-img>
                        <v-list-item-title align="center" class="text-h7 mb-1">
                            <b>{{ monDev.incCount }}</b> 
                            <br>open incident(s)
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item dense>
                        <v-list-item-content align="center" dense>
                            <!--<div class="text-overline">{{ monDev.monDevName }}</div>-->
                            <v-list-item-title class="text-h8">{{ monDev.monDevName }}</v-list-item-title>
                            <!-- <v-card-title > {{ monDev.monDevIpAddress }} </v-card-title> -->
                            <v-list-item-title class="text-h6">{{ monDev.monDevIpAddress }}
                                <v-icon v-if="monDev.operStatus == UtilsModaConstants.DeviceLiveStatus.OFFLINE" title="Status" size="12px" color="black">
                                        fa-solid fa-circle-notch
                                </v-icon>
                                <!-- for the agent device itself, operStatus will be null - nmap detects it, but ARP won't detect it 
                                        Hence, if it's explicitly not offline, it's treated as online -->
                                <v-icon v-else title="Status" size="12px" color="green">
                                        fa-solid fa-power-off
                                </v-icon>
                            </v-list-item-title>
                            <!--<v-card-subtitle >{{ monDev.monDevDetails }}</v-card-subtitle > -->
                            <!-- <v-list-item-subtitle> {{ monDev.monDevDetails }} </v-list-item-subtitle> -->
                            <v-list-item-title class="text-h8">{{ monDev.monDevDetails }}</v-list-item-title>

                            <!--<v-card-text v-if="monDev.monDevLocation">{{ monDev.monDevLocation }}</v-card-text >-->
                            <v-list-item-title class="text-h8">{{ monDev.monDevLocation }}</v-list-item-title>

                        </v-list-item-content>
                    </v-list-item>

                </v-card>
            </v-col>
        </v-row>

        <p class="help is-danger text-red" v-show="apiStatus" v-text="apiStatus"></p>

    </div>
</template>


<script>
import auth from '@/services/auth.service'
import ApiService from '@/services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'

export default {
    name: 'DeviceSummary',
    components: {
        Breadcrumb,
    },
    mixins: [
        Utils,
    ],
    data(){

        return {
            currOrgId: null,
            isLoading: false,

            monDevsList: [],

            fullPage: true,
            onCancel:true,
            height:35,
            width:35,
            apiStatus: null,
            isShow:'display-none',
            parent_pages:[ {name:'Home'}, {name:'Devices Status', active:true} ],

            //---socket ---
            isConnected : false,
            isPageActivated : false,
            isPageVisible : true,
            socketMessage : "",
            eventData : null            // Data from a Socket event

        }
    },

    //=== Hooks (Refer to EventsAlarms.vue for usage of all hooks) ================
    created: function() {
    },
    mounted: function() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.get_auth_details();

        this.getFaultDeviceSummaryData();
    },

    activated: function() {
    },
    deactivated: function() {
    },

    destroyed: function() {
    },
    //===========================

    watch : {
    },
    computed: {
    },

    methods: {

        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },

        monitoredDeviceClicked(monDev){
            //console.log("monitoredDeviceClicked ", JSON.stringify(monDev))
            //var advSearchString = monDev.monDevIpAddress + " (" + monDev.monDevName + ") incident(s)"
            var monDevIds=[monDev.monDevId]
            var monDevNames=[monDev.monDevName]
            var states = this.utilsOpenStates;
            var query = []

            query.push({ name: "component", value: {componentIdList: monDevIds, componentNameList: monDevNames} });
            query.push({ name: "state", value: states });

            console.log("monitoredDeviceClicked query ", JSON.stringify(query));
            query.length ? this.$router.push({ name: "INCIDENTS", query: query }) : this.$router.push({ name: "INCIDENTS" });
        },

        setLevelsColorClasses(objs){
            objs.forEach( (obj) => {
                var severity = obj.highestSeverity
                if ( ! severity ) severity = this.UtilsModaConstants.FaultService.Severity.INFO
                obj.colorClass = this.utilsSeverityColors[severity].colorClass
                obj.colorRgb = this.utilsSeverityColors[severity].colorRgb
                obj.colorHex = this.utilsSeverityColors[severity].colorHex
                if ( obj.children ) this.setLevelsColorClasses (obj.children);
                obj.monDevDetails = ""
                if ( obj.monDevVendor ) obj.monDevDetails = obj.monDevDetails.concat(obj.monDevVendor + " - ")
                if ( obj.monDevType ) obj.monDevDetails = obj.monDevDetails.concat(obj.monDevType + " - ")
                if ( obj.monDevModel ) obj.monDevDetails = obj.monDevDetails.concat(obj.monDevModel)
            })
        },

        compareSeverities(a, b){
            var sevNums = {}
            sevNums[this.UtilsModaConstants.FaultService.Severity.CRITICAL]= 4
            sevNums[this.UtilsModaConstants.FaultService.Severity.SEVERE]  = 3
            sevNums[this.UtilsModaConstants.FaultService.Severity.MAJOR]   = 2
            sevNums[this.UtilsModaConstants.FaultService.Severity.MINOR]   = 1
            sevNums[this.UtilsModaConstants.FaultService.Severity.INFO]    = 0
            return sevNums[a.highestSeverity] - sevNums[b.highestSeverity];
        },

        getFaultDeviceSummaryData(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            this.isLoading=true

            var summaryDataTypes = [ this.UtilsModaConstants.FaultService.Summary.DataType.MONITORED_DEVICES ]

            summaryDataTypes.forEach( (summaryDataType) => {

                query_params.summaryDataType = summaryDataType

                ApiService.get("/svcSummary/api/v1/summary", (err, result) => {       //### svcFault
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        if ( result.data.data.summaryDataType == this.UtilsModaConstants.FaultService.Summary.DataType.MONITORED_DEVICES ){
                            var s=result.data.data.summaryData
                            //console.log("**** monitoredDevices s = " + JSON.stringify(s, null, 2))
                            this.monDevsList            = s.list.faultedList
/*  Let's keep Minor also in light green color
                            this.monDevsList            = s.list.faultedList.filter( (m) => 
                                                                m.highestSeverity == this.UtilsModaConstants.FaultService.Severity.CRITICAL ||
                                                                m.highestSeverity == this.UtilsModaConstants.FaultService.Severity.SEVERE ||
                                                                m.highestSeverity == this.UtilsModaConstants.FaultService.Severity.MAJOR)
*/
                            this.monDevsList.sort(this.compareSeverities).reverse()
                            if ( s.list.unknownInfoList )
                                this.monDevsList            = this.monDevsList.concat(s.list.unknownInfoList)
                            //this.monDevsList            = this.monDevsList.concat(s.list.okList) // complete OK list
                            // just add one card with summary of OK devices
                            if ( this.monDevsList.length  )
                                this.monDevsList            = this.monDevsList.concat({monDevIpAddress: s.list.okList.length + ' more device(s)', incCount: 0})
                            else
                                this.monDevsList            = [{monDevIpAddress: s.list.okList.length + ' device(s)', incCount: 0}]
                            this.setLevelsColorClasses(this.monDevsList)
                        }
                    }
                }, query_params, "SUMMARY_SERVICE_URL"     //###"FAULT_SERVICE_URL"
                )
            }, this)
        },

        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            this.getFaultDeviceSummaryData();
        },

    }

}
</script>
