<template>
	<v-container class="login-wrapper mt-5 pt-10">
		<div class="login-cover-placeholder">
			<div class="area">
				<ul class="circles">
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
				</ul>
			</div>
		</div>
		<div class="mx-auto logo mt-6 mb-5">
			<div class="moda-logo"></div> MODA Technologies
		</div>
		<v-card elevation="10" class="mx-auto login-box" max-width="400">
			<v-card-title>
				<div class="mx-auto">LOGIN</div>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12">
						<v-alert v-if="errMsg" :border="'top'" color="red lighten-2" dark>
							{{ errMsg }}
						</v-alert>
						<form v-if="msal.custom.modastate != 'AUTH-INPROGRESS'" v-on:submit="submitForm" method="POST"
							class="margin-bottom-0">
							<v-text-field v-if="step == 1" v-model="email" outlined label="Email Address" prepend-inner-icon="mdi-account"
								@input="errMsg = null" />

							<p v-if="step == 2" class="text-center"><strong class="text-center">{{ this.email }}</strong>
                                                    <v-icon small @click="step=1">mdi-pencil</v-icon></p>
							<v-text-field v-if="isStep2AuthLocal()" prepend-inner-icon="mdi-key-variant" v-model="password" label="Password"
								:append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'" :type="password_show ? 'text' : 'password'"
								@click:append="password_show = !password_show" outlined @input="errMsg = null" hide-details />
							<div><a v-if="isStep2AuthLocal()" @click="forgotPassword" class="py-2 float-right">Forgot password ?</a></div>
							<v-btn v-if="step == 1" block rounded class="mb-4" color="primary" @click="step1Clicked()" large :disabled="!isValidEmail">
                                Continue</v-btn>
							<v-btn v-if="isStep2AuthLocal()" block rounded type="submit" class="mb-4" color="primary" :loading="isLoading"
								:disabled="!password" large>
								Sign in
							</v-btn>
							<v-btn id="msBtn" v-if="isStep2AuthMicrosoft()" block rounded class="mb-4 mt-5" :loading="isMsLoading" large @click="startMSLogin()">
                                Continue with <v-icon small class="mx-1" color="primary">mdi-microsoft</v-icon> Microsoft</v-btn>
						</form>

<!-- msal.custm.modastate == AUTH-INPROGRESS show progress circle -->
						<span v-else>
							<div class="text-center my-15"><v-progress-circular color="primary"
								indeterminate></v-progress-circular></div>
							<v-btn block rounded type="submit" class="float-right" color="primary" :loading="isLoading" large
								@click="cancelAuthentication">
								Cancel
							</v-btn>
						</span>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

	</v-container>
</template>

<script>
import ModaConst from "@/services/ModaConstants.js"
import UserApiService from "@/services/user-api.service";
import auth from "@/services/auth.service";
import {globalAPIUrlStore} from '../main.js'
// Popup mode is supported by this import
import MSPopupAuth from "@/services/microsoft.auth.service";
// Redirect mode is supported by this import and VueMsal in main.js
import { msalMixin } from "@sirip1/vue-msal";
import { Regex } from '@/utils/RegEx';

export default {
  components: {
  },
  mixins: [msalMixin],
  async mounted() {
      MSPopupAuth.configure(globalAPIUrlStore.MICROSOFT_CLIENT_ID)

      // Restore any cached or saved local user
      this.msPopupUser = MSPopupAuth.user()
      //console.log(`configured ${MSPopupAuth.isConfigured()}`)

      this.$msal.setOnTokenCallback(this.onMicrosoftTokenCb); // plugin
      this.$msal.setOnAuthenticationCallback(this.onMicrosoftAuthenticationCb); // plugin

      console.log("this.msal.custom ", this.msal.custom.modastate)

      if ( this.msPopupUser )
        console.log("Currently logged in Popup user ", this.msPopupUser)
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  data() {
    return {
      email: null,
      password: "",
      password_show: false,
      authProvider: ModaConst.AuthProvider.LOCAL,
      authProviderList: [ModaConst.AuthProvider.LOCAL, ModaConst.AuthProvider.MICROSOFT],
      isLoading: false,
      isMsLoading: false,
      fullPage: true,
      errMsg: null,
      height: 35,
      width: 35,
      onCancel: true,
      imagePath: "microsoftlogo.png",
      redirectRoute: "DASHBOARD_SUMMARY_STATUS",
      step: 1
    };
  },
  computed: {
      msRedirectUser() {
        let user = null;
        if (this.msal.isAuthenticated) { // Note that the dollar sign ($) is missing from this.msal
          user = {
            ...this.msal.user,
            profile: {}
          }
          if (this.msal.graph && this.msal.graph.profile) {
              user.profile = this.msal.graph.profile
          }
          user.idToken = this.msal.idToken
        }
        return user;
      },
			isValidEmail(){
				return Regex.email.test(this.email);
			},
  },
  methods: {

    isStep2AuthLocal(){
        return this.step == 2 && this.authProvider==ModaConst.AuthProvider.LOCAL
    },
    isStep2AuthMicrosoft(){
        return this.step == 2 && this.authProvider==ModaConst.AuthProvider.MICROSOFT
    },
    forgotPassword() {
        let route = {name: "FORGOT_PASSWORD"}
        if ( this.email ) route.query = { email: this.email }
        this.$router.push(route)
    },
	step1Clicked(){
		localStorage.clear();
		this.verifyUserExists( function(err, cbarg, user) {
            if ( ! err ) {
                cbarg.authProvider = user.authProvider
                cbarg.step = 2
                setTimeout(()=> { document.getElementById("msBtn").focus(), 500});
            }
        }, this);
	},
	startMSLogin(){
		this.authProvider = ModaConst.AuthProvider.MICROSOFT;
		//localStorage.clear();
		this.doMicrosoftLoginRedirect_1();
	},
    submitForm: function (e) {
        e.preventDefault();
        if ( ! this.isValidEmail ) return
        if ( this.step == 1)
            this.step1Clicked()
        else{
            this.authProvider = ModaConst.AuthProvider.LOCAL;
            localStorage.clear();
            this.doLocalLogin()
        }
    },
    cancelAuthentication(){
        this.$msal.saveCustomData("modastate", "AUTH-CANCELLED");
        console.log("this.msal.custom ", this.msal.custom.modastate)
    },

    doLocalLogin(){

        var login_details = {
            email: this.email,
            password: this.password,
            authProvider: ModaConst.AuthProvider.LOCAL,
        };

        this.isLoading = true;
        UserApiService.login("/usersvc/api/v1/users/login", login_details, (err, result) => {
            this.isLoading = false;
            if (!err) {
                this.setLocalStorage(result.data.data.jwtToken, result.data.data.user);
            } else {
                if (result && result.data && result.data.message && result.data.message.message)
                    this.errMsg = result.data.message.message
                else
                    this.errMsg = "Login failed"
            }
        });
    },

    async doMicrosoftLoginPopup(){
        var login_details = {
            email: this.email,
            password: this.password,
            authProvider: ModaConst.AuthProvider.MICROSOFT,
        };

        /* First verify user exists in MODA backend, then redirect to Microsoft login */

        this.isLoading = true;
        UserApiService.login("/usersvc/api/v1/users/login", login_details, async (err, result) => {
            this.isLoading = false;
            if (!err) {
                try {
                    await MSPopupAuth.loginPopup()
                    this.msPopupUser = MSPopupAuth.user();
                    // We don't need accessToken. idToken is enough
                    //this.msPopupUser.accessToken = await MSPopupAuth.acquireToken();
                    console.log('Microsoft loginComplete ', this.msPopupUser)
                } catch (err) {
                    this.errMsg = err.toString()
                    console.log('Microsoft loginFailure ', this.errMsg)
                    return;
                }

                /* Then have backend verify MS JWT token and issue our own JWT token */
                login_details.msIdToken = this.msPopupUser.idToken;

                this.isLoading = true;
                UserApiService.login("/usersvc/api/v1/users/mslogin", login_details, async (err1, result1) => {
                    this.isLoading = false;
                    if ( err1 ){
                        //console.log("Auth failed result1 ", result1)
                        if (result1 && result1.data && result1.data.message && result1.data.message.message)
                            this.errMsg = result1.data.message.message
                        else
                            this.errMsg = "Login failed"
                    }else{
                        //console.log("Auth success result1 ", result1)
                        // save our own new JWT token so it goes in further requests
                        this.setLocalStorage(result1.data.data.jwtToken, result1.data.data.user);
                    }
                })

            } else {
                console.log("result " + JSON.stringify(result))
                if (result && result.data && result.data.message && result.data.message.message)
                    this.errMsg = result.data.message.message
                else
                    this.errMsg = "Login failed"
            }
        });
    },

    onMicrosoftTokenCb(ctx, err, resp){
        if ( err ){
            console.log("onMicrosoftTokenCb err ", err)
            this.$msal.saveCustomData("modastate", "AUTH-FAIL");
        }else {
            if ( resp && this.msal.custom.modastate == "AUTH-INPROGRESS" && this.msRedirectUser ){
                if ( this.msRedirectUser.idToken ) this.doMicrosoftLoginRedirect_2 ()
            }
        }
    },
    onMicrosoftAuthenticationCb(ctx, err, resp){
        if ( err ) {
            console.log("onMicrosoftAuthenticationCb err ", err)
            this.$msal.saveCustomData("modastate", "AUTH-FAIL");
        }
    },

    // check if user exists in our backend, then redirect to MSAL
    verifyUserExists (callback, cbarg){
        var login_details = {
            email: this.email,
            //password: this.password,
            //authProvider: ModaConst.AuthProvider.MICROSOFT,
        };

        /* First verify user exists in MODA backend, then redirect to Microsoft login */

        UserApiService.login("/usersvc/api/v1/users/login", login_details, (err, result) => {
            if (!err) {
                callback(null, cbarg, result.data.data.user)
            } else {
                this.$msal.saveCustomData("modastate", "AUTH-FAIL"); // call msal PLUGIN
                if (result && result.data && result.data.message && result.data.message.message)
                    this.errMsg = result.data.message.message
                else
                    this.errMsg = "Login failed"
                callback(err, cbarg)
            }
        });
    },

    doMicrosoftLoginRedirect_1 (){
        this.$msal.saveCustomData("modastate", "AUTH-INPROGRESS");
        this.$msal.signIn(this.email); // call msal PLUGIN
    },

    // back from MSAL, send MS token to our backend to get our own token
    doMicrosoftLoginRedirect_2 (){

        let msRedirectUser = this.msRedirectUser;

        var login_details = {
            email: msRedirectUser.userName,
            authProvider: ModaConst.AuthProvider.MICROSOFT,
            msIdToken: msRedirectUser.idToken,
        };

        this.isMsLoading = true;
        UserApiService.login("/usersvc/api/v1/users/mslogin", login_details, async (err1, result1) => {
            this.isMsLoading = false;
            if ( err1 ){
                this.$msal.saveCustomData("modastate", "AUTH-FAIL"); // call msal PLUGIN
                //console.log("Auth failed result1 ", result1)
                if (result1 && result1.data && result1.data.message && result1.data.message.message)
                    this.errMsg = result1.data.message.message
                else
                    this.errMsg = "Login failed"
            }else{
                this.$msal.saveCustomData("modastate", "AUTH-COMPLETE"); // call msal PLUGIN
                //console.log("Auth success result1 ", result1)
                // save our own new JWT token so it goes in further requests
                this.setLocalStorage(result1.data.data.jwtToken, result1.data.data.user);
            }
        })

    },

    setLocalStorage(jwtToken, user){
        // !!! TBD : security issue. Retrieving and Storing all user details in localStorage
        // !!! TBD: remove authorization. Keep cookie.
        // document.cookie is needed for Dashboards where we are setting URL to iframe.src directly
        document.cookie = "MODA_Authorization=" + jwtToken;
        // MODA_Authorization is also sent as another header. Backend uses Cookie if available, else MODA_Authorization
        // This is useful when frontend is running detached on a separate machine and access APIs somewhere else
        // (Cookie can't be set in that case)
        localStorage.setItem("MODA_Authorization", jwtToken);
        localStorage.setItem("user-details", JSON.stringify(user));
        //console.log("User logged in " + JSON.stringify(result.data.data.user));
        //localStorage.setItem('authorization',result.data.data.jwtToken)
        this.errMsg = null;

        // cleaer out breadcrumbs and orgslists
        auth.globalBreadcrumb = [];
        auth.globalOrgsList = [];
        if(sessionStorage.getItem("_path") && sessionStorage.getItem("_path")!= "/login"){
            this.$router.push(sessionStorage.getItem("_path"));
            sessionStorage.clear();
        } else {
            this.$router.push({ name: this.redirectRoute });
        }
    }
  },
};
</script>
