//==============================================================================
// socket.io.Service: 
//  SocketIO service (wrapper) utility
//==============================================================================

import { io } from 'socket.io-client';
import {globalAPIUrlStore} from '../main.js'

class SocketIoService {

  //activeSocketMap; 		// Map of all client socket connections

  constructor() {
    this.activeSocketMap = new Map();
  }

  setupSocketConnection(page, svcWsUrl, svcName) {

    let url = globalAPIUrlStore[svcWsUrl]
    let urlHost, urlPath
    if ( url.charAt(0) == '/' )
        urlPath = url
    else{
        url = new URL(url)
        urlHost = url.protocol + "//" + url.hostname
        if ( url.port ) urlHost += ":" + url.port
        urlPath = url.pathname
    }

    // Refer: https://socket.io/docs/v4/client-options/#withcredentials
    //        https://socket.io/docs/v4/client-options/#nodejs-specific-options     
    let socketOptions = {
      //withCredentials: true,      // This does not work, if the CORS allowdOrigin is '*'
      //TODO: Add SSL
      query: {},
      autoConnect: true,
      //path: "/ws-" + svcName // two times needed. One for kong, one for backend service
      path: urlPath
    };
    socketOptions.query.cookie = document.cookie; // Contains both MODA_Authorization and MODA_targetOrgId (in name=value format)

    console.log("INFO: Connecting to : svcName = " + svcName + ", page = " + page + ", svcWsUrl = " + svcWsUrl + 
      " Socket server: urlHost " + urlHost + ", urlPath " + urlPath + ", with options = " + JSON.stringify(socketOptions));

    var clientSocket
    if ( urlHost )
        clientSocket = io(urlHost, socketOptions);
    else
        clientSocket = io(socketOptions);

    clientSocket.on("disconnect", (err) => {
      console.log("Disconnected from the Socket server. socket.id = " + clientSocket.id + " error " + JSON.stringify(err));
      this.deleteSocketFromActiveMap(page, clientSocket);
    });
    clientSocket.on("connect_error", (err) => {
      console.log("Connect error from the Socket server. socket.id = " + clientSocket.id + " error " + JSON.stringify(err));
      //this.deleteSocketFromActiveMap(page, clientSocket);
      if ( err && err.data && err.data == "Expired token" )
        clientSocket.disconnect();
    });

    //DEBUG: this.socket.onAny((event, ...args) => { console.log(event, args); });

    return clientSocket;
  }

  addSocketToActiveMap(page, sock) {
    let currSockets = this.activeSocketMap.get(page) || [];
    currSockets.push(sock)
    this.activeSocketMap.set(page, currSockets);
    //let keys = [...this.activeSocketMap.keys()];
    console.log("Added new connected socket to activeMap: " + sock.id + ", activeSocketMap = ");
    console.log([...this.activeSocketMap.keys()]);
    console.log([...this.activeSocketMap.values()]);
  }

  deleteSocketFromActiveMap(page, sock) {
    let currSockets = this.activeSocketMap.get(page);
    console.log("sock.id = " + sock.id);
    if ( currSockets ) {
        let currSocket = currSockets.find(e => e == sock)

        if (currSocket) {
            console.log("currSocket.id = " + currSocket.id);

            if (sock.id != currSocket.id) {
                console.log("ERROR: socket in page activeMap is not matching !");   // These should match; check
            }

            this.activeSocketMap.delete(page);
            let keys = [...this.activeSocketMap.keys()];
            console.log("Deleted a client socket from activeMap: " + currSocket.id + ", activeSocketMap.keys = " + JSON.stringify(keys));
        } else {
            console.log("No socket found for page: " + page);
        }
    }
  }

  sendEvent(page, event, data) {
    let clientSockets = this.activeSocketMap.get(page);

    if ( clientSockets ) {
        clientSockets.forEach( (clientSocket) => {
            if (clientSocket && clientSocket.connected) {
                console.log("sendEvent: " + page + " event = " + event + ", data = " + JSON.stringify(data));
                clientSocket.emit(event, data);
            } else {
                console.log("WARN: Client Socket connection is not ready for emitting an event: event = " + event + " page " + page );
            }
        })
    }
  }
  
  sendMessage(page, msg) { 
    this.sendEvent(page, 'message', msg); 
  }

  getSocket(page) {
    return this.activeSocketMap.get(page);
  }

  disconnectSocket(page) {
    let clientSockets = this.activeSocketMap.get(page);

    if ( clientSockets ) {
        clientSockets.forEach( (clientSocket) => {
            if (clientSocket /*&& clientSocket.connected*/) {
                console.log("INFO: Disconnecting Client socket. socket.id = " + clientSocket.id);
                clientSocket.disconnect();

            } else {
                console.log("ERROR: Disconnecting a Client socket");
            }
        })
    }
  }
}

export default new SocketIoService();
//==============================================================================
