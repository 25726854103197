
<template>
    <div>
		<v-dialog v-model="dialog" eager max-width="500" @keydown.esc="cancelGeneralConfirmDialog" @click:outside="cancelGeneralConfirmDialog">
            <v-card>
                <v-card-title>
                    <v-icon>mdi-information</v-icon> Information
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <strong>
                        <p class="mt-5" v-html="generalDialogMessage"></p>
                    </strong>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                    <v-btn rounded outlined color="error" @click="okGeneralConfirmDialog">OK</v-btn>
                    <v-btn rounded outlined color="info" @click="cancelGeneralConfirmDialog">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'GeneralConfirmDialog',
    data() {
        return { }
    },
    props: {
        generalDialogMessage: {
            type: String,
            required: false,
            default: null,
        },
        dialog: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    methods: {
        okGeneralConfirmDialog: function () {
            this.$emit('GeneralConfirmDialogCallback', false);
        },
        cancelGeneralConfirmDialog: function () {
            this.$emit('GeneralConfirmDialogCallback', true);
        },
    },
}
</script>
