<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="2" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-chart-timeline-variant</v-icon> Analytics Profile {{ titleName }}

			</v-card-title>
			<v-card-text class="pb-0">
				<v-container fluid class="pl-5 pr-5">
					<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
						{{ apiStatus }}
					</v-alert>
					<form ref="analyticsConfigProfileform">
						<v-row v-if="isLoading">
              <v-col cols="12">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </v-col>
            </v-row>
						<v-row>
							<v-col cols=12 md=6>
								<v-text-field dense v-model="analyticsConfigProfileDetails.analyticsConfigProfileName"
									:error-messages="nameErrors"
									@input="$v.analyticsConfigProfileDetails.analyticsConfigProfileName.$touch()"
									@blur="$v.analyticsConfigProfileDetails.analyticsConfigProfileName.$touch()" class="moda-required"
									label="Config Profile Name" :disabled="readonly" />
							</v-col>
							<v-col cols=12 md=6>
								<v-text-field dense v-model="analyticsConfigProfileDetails.description" label="Description"
									:disabled="readonly"></v-text-field>
							</v-col>
							<v-col cols=12>
								<v-autocomplete v-model="selected_applications" :items="all_applications" item-key="appId"
									item-text="appName" chips clearable multiple prepend-inner-icon="mdi-apps"
									:error-messages="applicationErrors" :return-object="true" @input="$v.selected_applications.$touch()"
									@blur="$v.selected_applications.$touch()" class="moda-required" label="Applications"
									:disabled="readonly">

									<template v-slot:item="{ item, on, attrs }">
										<v-list-item v-bind="attrs" v-on="on">
											<template v-slot:default="{ active }">
												<v-list-item-action>
													<v-checkbox :input-value="active"></v-checkbox>
												</v-list-item-action>

												<v-list-item-content>
                                                    <span v-on:click="navigateToApplication(item.appId)">
                                                        <a class="link-text"> {{item.appName}} </a>
                                                    </span>
												</v-list-item-content>
											</template>
										</v-list-item>
									</template>
									<template v-slot:selection="{ item, select, selected }">
										<v-chip :input-value="selected" :close="!readonly" @click="select" color="primary" dark
											@click:close="remove_application(item)">
											<span   v-on:click="navigateToApplication(item.appId)">
												<a class="link-text"> {{item.appName}} </a>
											</span>
										</v-chip>
									</template>

								</v-autocomplete>

							</v-col>
							<v-col cols=12 md=3>
								<v-text-field v-model.number="analyticsConfigProfileDetails.syslog.listenPort"
									label="Syslog Listen Port" type="number" min="1" max="65535" v-on:keypress="utilsIsNumber"
									:disabled="readonly"> </v-text-field>
							</v-col>
							<v-col cols=12 md=3>
								<v-autocomplete v-model="analyticsConfigProfileDetails.syslog.protocol" :items="syslog_protocols" :error-messages="protocolErrors"
									@input="$v.analyticsConfigProfileDetails.syslog.protocol.$touch()"
									@blur="$v.analyticsConfigProfileDetails.syslog.protocol.$touch()" class="moda-required"
									label="Protocol" :disabled="readonly">
								</v-autocomplete>
							</v-col>
							<v-col cols=12 md=3>
								<v-switch v-model="utilStatus1" :true-value="UtilsModaConstants.AdminStatus.ENABLED"
									:false-value="UtilsModaConstants.AdminStatus.DISABLED" inset
									:label="utilsStatusLabelString('Syslog Receive', utilStatus1)" :disabled="readonly"></v-switch>
							</v-col>
						</v-row>
					</form>
				</v-container>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn rounded outlined color="success" v-if="!readonly && !isEdit" @click="submit" :disabled="this.$v.$invalid">Create
				</v-btn>
				<v-btn rounded outlined color="success" v-if="!readonly && isEdit" @click="submit" :disabled="this.$v.$invalid">
					Update</v-btn>
				<v-btn rounded outlined color="error" v-if="!readonly" @click="cancel">Cancel</v-btn>
				<v-btn rounded outlined color="success" v-if="readonly" @click="cancel">Go Back</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>

import ApiService from '@/services/api.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils.vue'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            currOrgId: null,
            targetOrgId: null,
            applicationsSelectedArray:['all'],
            isLoading: false,
            fullPage: true,
            onCancel:true,
            titleName:'Create',
            analyticsConfigProfileId:"",
            isEdit:false,
            readonly:true,

            search:null,
            syslog_protocols:["UDP"],
            all_applications:[],
            all_orgs_flat:[],
            selected_applications: [],
            analyticsConfigProfileDetails: {
                        analyticsConfigProfileId :'',
                        analyticsConfigProfileName : '',
                        applications : [],
                        syslog: {
                            //receive : this.UtilsModaConstants.AdminStatus.ENABLED, // uses computed prop instead
                            protocol : "UDP",
                            listenPort : 10541,
                        }
                    },

            height:35,
            width:35,
            userdetails:JSON.parse(localStorage.getItem('user-details')),
            tag: '',
            parent_pages:[ {name:'Home'}, {name: "Configuration"}, {name: "Profiles & Jobs"},
                           {name:'Analytics Config Profiles',
                            route: { name: "ANAL_CONFIG_PROFILES"} },
                           {name:"Create/Edit", active:true} ],
            apiStatus: null,
        };
    },

    mixins: [validationMixin,
            Utils ],

    validations: {
        analyticsConfigProfileDetails : {
            analyticsConfigProfileName: { required, minLength: minLength(3) },
            syslog: {
                protocol : { required }
            }
        },
        selected_applications : { required },
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.analyticsConfigProfileDetails.analyticsConfigProfileName.$dirty) return errors
            !this.$v.analyticsConfigProfileDetails.analyticsConfigProfileName.required && errors.push('Name is required.')
            !this.$v.analyticsConfigProfileDetails.analyticsConfigProfileName.minLength && errors.push('Name must be atleast 3 characters long')
            return errors
        },
        applicationErrors () {
            const errors = []
            if (!this.$v.selected_applications.$dirty) return errors
            !this.$v.selected_applications.required && errors.push('Atleast one application is required.')
            return errors
        },
        protocolErrors () {
            const errors = []
            if (!this.$v.analyticsConfigProfileDetails.syslog.protocol.$dirty) return errors
            !this.$v.analyticsConfigProfileDetails.syslog.protocol.required && errors.push('Protocol is required.')
            return errors
        },
    },

    methods: {

        submit () {
            this.$v.$touch()

            this.analyticsConfigProfileDetails.analyticsConfigProfileName = this.analyticsConfigProfileDetails.analyticsConfigProfileName.trim();
            var applications = this.selected_applications.map(a => {
                return { appId: a.appId } // we are merging applications array with children array
            });
            this.analyticsConfigProfileDetails.applications = applications
            this.analyticsConfigProfileDetails.syslog.receive = this.utilStatus1

            //console.log("submit*****"+JSON.stringify(this.analyticsConfigProfileDetails))

            this.isLoading=true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            if ( this.isEdit ){
                ApiService.put("/api/v1/analyticsConfigProfile/"+this.analyticsConfigProfileId,this.analyticsConfigProfileDetails, (err, result) => {
                    //console.log("*update*"+JSON.stringify(result))
                    this.isLoading=false;
                    if ( err ) {
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateAnalyticsConfigProfile()
                    }
                }, query_params)
            }else {// create
                ApiService.post("/api/v1/analyticsConfigProfile",this.analyticsConfigProfileDetails, (err, result) => {
                    //console.log("*create*"+JSON.stringify(err) + " " + JSON.stringify(result))
                    this.isLoading=false
                    if ( err ) {
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.cancelCreateAnalyticsConfigProfile()
                    }
                }, query_params)
            }
        },
        cancel () {
            this.$v.$reset()
            this.cancelCreateAnalyticsConfigProfile()
        },
        remove_application (application) {
            //console.log("perm " + JSON.stringify(application) + " arr " + JSON.stringify(this.selected_applications));
            this.selected_applications.splice(this.selected_applications.findIndex(x => x.appId === application.appId), 1)
            this.selected_applications = [...this.selected_applications]
        },
        navigateToApplication(id){
            var readonly = 1
            const r = this.$router.resolve({name: "APPLICATIONS_EDIT", params: { appId: id, readonly: readonly} } )
						window.open(r.href, '_blank');
        },


        cancelCreateAnalyticsConfigProfile(){
            this.$router.go(-1);
        },
        getanalyticsConfigProfileDetails(){
						this.isLoading=true
            let query_params = {};
            if ( this.targetOrgId ) query_params.targetOrgId = this.targetOrgId;
            else if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            ApiService.get("/api/v1/analyticsConfigProfile/"+this.analyticsConfigProfileId, (err, result) => {
                //console.log("analyticsConfigProfile " + JSON.stringify(result))
								this.isLoading=false;
                if ( err ) {
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    this.analyticsConfigProfileDetails = result.data.data[0];
                    this.selected_applications = this.analyticsConfigProfileDetails.applications.map(app => {
                        return {appId:app.appId, appName:app.appName}
                    });
                    this.utilStatus1 = this.analyticsConfigProfileDetails.syslog.receive
                    //console.log(JSON.stringify(this.selected_applications))
                    this.titleName = (this.readonly ? "View - " : "Edit - ") +this.analyticsConfigProfileDetails.analyticsConfigProfileName
                }
            }, query_params)
        },

        getApplicationsList(){
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            //this.isLoading=true
            this.all_applications = [];
            ApiService.get("/api/v1/application", (err, result) => {
                //this.isLoading=false
                if ( err ){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {

                    this.apiStatus = null;
                    //console.log("all_applications ****"+JSON.stringify(org_list))

                    this.all_applications = result.data.data;

                    //this.all_applications.unshift({applicationId:"all", applicationName: "All" }); // special "all" application at the top
                    //console.log("all_applications ****"+JSON.stringify(this.all_applications))

                    if(this.analyticsConfigProfileId != "" && this.analyticsConfigProfileId !=undefined)
                        this.getanalyticsConfigProfileDetails();
                }
            }, query_params)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            if(this.analyticsConfigProfileId != "" && this.analyticsConfigProfileId !=undefined){
                // Edit can't be done when org is changed, so g back
                this.cancelCreateAnalyticsConfigProfile();
            }else
                this.getApplicationsList()
        },
    },
    mounted() {
        this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        this.targetOrgId = this.$route.params.targetOrgId;
        this.analyticsConfigProfileId = this.$route.params.analyticsConfigProfileId;
        this.readonly = (this.$route.params.readonly == "1");
        this.getApplicationsList()
        if(this.analyticsConfigProfileId != "" && this.analyticsConfigProfileId !=undefined){
            this.isEdit =true;
            this.titleName = this.readonly ? "View" : "Edit"
        }
    }
}

</script>



