<template>
	<div class="content-wrapper">
		<Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
		<v-card :elevation="5" class="tile-box">
			<v-card-title class="page-title">
				<v-icon>mdi-ticket-confirmation</v-icon> Incidents
			</v-card-title>
			<v-card-text class="pb-0">
				<v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
					{{ apiStatus }}
				</v-alert>
				<TableAdvancedFilter ref="tableAdvancedFilter" :enabledFieldsIn="tableAdvancedFilter.enabledFilterFields"
					v-on:freeFormSearchChanged="processFreeFormSearchChange"
					v-on:advancedFilterChanged="processAdvancedFilterChange" />
			</v-card-text>
			<!-- !!! Some of the params and functions used here are defined in AdsTreeTableUtils.vue mixin !!!!  -->
			<!-- !!! When async functionality implemented for this table, callRows, callChildren, callTempRows routines need to be
                 brought back to this file !!!!! -->
			<v-container fluid class="pt-0">
				<v-data-table :loading="isLoading" :headers="incidentEventTableColumnsVueTable" multi-sort
					no-data-text="No incidents" :items="incidentEvent_table_rows" :options.sync="pagingOptions"
					:server-items-length="utilsTotalEntriesAllPages"
					:footer-props="{'items-per-page-options':[5, 10, 15, 25, 100]}" item-key="incidentId">

					<template v-slot:item.incidentIdCode="{item}">
						<nobr>
							<v-icon title="ML Anamoly" color="success">
								mdi-brain</v-icon>
							{{item.incidentIdCode }}
							<v-icon small title="Ticket" color="warning">
								mdi-ticket-confirmation</v-icon>
						</nobr>
					</template>
					<template v-slot:item.siteName="{ item }">
						<a href="javascript:;" v-on:click="navigateToSite(item.orgId, item.siteId)"> {{item.siteName}}
						</a>
					</template>
					<template v-slot:item.sourceName="{ item }">
						<nobr>
							<a class="float-left" href="javascript:;" v-on:click="navigateToComponent(item)">
								<v-img class="float-left mr-1" v-if="item.componentIconImg" :src="item.componentIconImg" height="24"
									width="24" />
								<v-img class="float-left mr-1" v-else-if="item.sourceIcon"
									:src="require('../../../public/assets/img/moda/' + item.sourceIcon)" height="24" width="24" />
								{{item.sourceName}}
							</a>
						</nobr>
					</template>
					<template v-slot:item.typeSubtype="{ item }">
						<TypeIcon :item="item" :UtilsModaConstants="UtilsModaConstants" />
						<SubtypeIcon :item="item" :UtilsModaConstants="UtilsModaConstants" />
					</template>

					<template v-slot:item.severityStatePri="{item}">
						<v-chip small class="mr-1" :color="utilsSeverityColors[item.severity].colorName"
							:dark="utilsSeverityColors[item.severity].darkMode"> {{item.severity}} </v-chip>
						<v-chip small class="mr-1" :color="utilsNonSeverityColors[item.state].colorName"
							:dark="utilsNonSeverityColors[item.state].darkMode"> {{item.state}} </v-chip>
						<v-chip small v-if="item.priority"> {{item.priority}} </v-chip>
					</template>

					<template v-slot:item.actions="{ item }">
						<v-icon @click="editIncidentEventDetails(item.incidentId, 1)" class="mr-2" color="primary" title="View">
							mdi-eye</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)" @click="editIncidentEventDetails(item.incidentId, 0)"
							class="mr-2" color="warning" title="Edit">mdi-pencil</v-icon>
						<v-icon :class="utilsGetDisplayClass(item)" @click="deleteEventIncident(item.incidentId, item.headline)"
							class="mr-2" title="Delete" color="red">mdi-delete</v-icon>
                        <v-icon :class="utilsGetDisplayClass(item)" @click="openQuickRuleDialog(item)" title="Quick Rule" color="purple">mdi-filter-cog</v-icon>

					</template>

				</v-data-table>
			</v-container>
		</v-card>

		<DeleteDialog v-if="deleteDialog" :currentObjectName="currName" @DeleteDialogCallback='deleteObjectChildEvent'>
		</DeleteDialog>
		<!-- QuickRule dialog -->
		<v-dialog v-model="quickRuleDialog" persistent max-width="1000px">
			<v-card>
				<ValidationObserver ref="quickRuleform" v-slot="{ invalid }">
					<v-card-title><v-icon>mdi-filter-cog</v-icon> Quick Rule</v-card-title>
					<v-card-text class="pb-0">
						<v-container>
							<!--- Row-1 -->
							<v-row>
								<v-col cols="12">
									<ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
										<v-text-field label="Rule name" v-model="quickRule.ruleName" :error-messages="errors[0]" class="moda-required"></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<!--- Row-2 -->
							<v-row>
								<v-col cols="12" sm="6" md="3">
										<p class="my-0">Site:</p>
										<v-checkbox dense :label="quickRule.site.value" v-model="quickRule.site.flag"></v-checkbox>
								</v-col>

								<v-col cols="12" sm="6" md="3">
									<v-autocomplete label="Type" v-model="quickRule.type.value" hint="Incident type" :items="eventTypes"
										multiple clearable>
									</v-autocomplete>
								</v-col>

								<v-col cols="12" sm="6" md="3">
									<v-autocomplete label="Subtype" v-model="quickRule.subType.value" hint="Incident subtype"
										:items="eventSubtypes" multiple clearable>
									</v-autocomplete>
								</v-col>

								<v-col cols="12" sm="6" md="3">
									<p class="my-0">Source:</p>
									<v-checkbox dense :label="quickRule.source.value" v-model="quickRule.source.flag"></v-checkbox>
								</v-col>
							</v-row>

							<!--- Row-3 -->
							<v-row v-if="quickRule.action.name != 'Drop Event'">
								<v-col cols="12" sm="6" md="4">
									<v-autocomplete dense label="Incident Severity" multiple v-model="quickRule.severity.value" :items="eventSeverities"
										clearable>
									</v-autocomplete>
								</v-col>

								<v-col cols="12" sm="6" md="4">
									<v-autocomplete dense label="Incident State" multiple v-model="quickRule.state.value" :items="eventStates" clearable>
									</v-autocomplete>
								</v-col>

								<v-col cols="12" sm="6" md="4">
									<v-autocomplete dense label="Incident Priority" multiple v-model="quickRule.priority.value" :items="eventPriorities"
										clearable>
									</v-autocomplete>
								</v-col>
							</v-row>


							<!--- Row-4 -->
							<v-row>
								<v-col cols="12" sm="6" md="4">
									<v-select dense label="Action" class="moda-required" v-model="quickRule.action.name" :items="eventActions">
									</v-select>
								</v-col>
                                <v-col v-if="quickRule.action.name == 'Drop Event'" cols="12" sm="6" md="4">
                                    <v-autocomplete dense label="Event Severity" multiple v-model="quickRule.eventSeverity.value" :items="eventSeverities"
										clearable>
									</v-autocomplete>
                                </v-col>

                                <v-col v-if="quickRule.action.name == 'Drop Event'" cols="12" sm="6" md="4">
                                    <v-alert variant="outlined" text type="info" dense>
                                        Note: Drop Events that lead to this type of Incident
                                    </v-alert>
                                </v-col>

								<v-col v-if="quickRule.action.name == 'Notify by Email'" cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required|emails" v-slot="{ errors }">
                                        <v-text-field dense  :error-messages="errors[0]" class="moda-required" label="Email(s)" hint="Use comma to separate multiple emails" v-model="quickRule.action.params.email"></v-text-field>
                                    </ValidationProvider>
								</v-col>
<!-- Placeholder for new param
								<v-col cols="12" sm="6" md="3">
									<v-text-field label="Param" v-model="quickRule.action.params.param1"></v-text-field>
								</v-col>
-->
							</v-row>

							<!--- Row-5 -->
							<v-row>
								<v-col cols="12" sm="6" md="6" class="datepicker-input-wrapper">
									<v-datetime-picker :textFieldProps="{ prependInnerIcon: 'mdi-calendar-clock'}" dense label="Expires at"
                                                                        v-model="quickRule.expiresAt.endDate" @input="dateTimeChanged">
										<template slot="dateIcon" slot-scope="props">
											<v-icon>mdi-calendar</v-icon>
										</template>
										<template slot="timeIcon" slot-scope="props">
											<v-icon>mdi-clock</v-icon>
										</template>
                                        <template slot="actions" slot-scope="{ parent }">
                                            <v-btn outlined rounded color="error" @click.native="parent.clearHandler">Cancel</v-btn>
                                            <v-btn outlined rounded color="success" @click="parent.okHandler">OK</v-btn>
                                        </template>
									</v-datetime-picker>
								</v-col>

								<v-col cols="12" sm="6" md="6">
									<p class="my-0">Expires in</p>
									<v-btn-toggle color="primary" dense @change="setExpiresAt()" v-model="quickRule.expiresIn" tile group>
										<v-btn value="10 m">10m</v-btn>
										<v-btn value="1 h">1h</v-btn>
										<v-btn value="1 d">1d</v-btn>
										<v-btn value="Never">Never</v-btn>
									</v-btn-toggle>
								</v-col>
							</v-row>

						</v-container>
						<v-divider></v-divider>
					</v-card-text>

					<v-card-actions class="justify-end">
						<v-btn rounded outlined color="error" text @click="cancelQuickRule()">Cancel</v-btn>
						<v-btn rounded outlined color="success" :disabled="invalid" text @click="createQuickRule()">Create</v-btn>
					</v-card-actions>
				</ValidationObserver>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
import ApiService from '@/services/api.service'
import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import TableAdvancedFilter from '@/components/templates/TableAdvancedFilter'
import Utils from '@/pages/moda/Utils.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'

//--- socket.io ---
import socketService from '@/services/socket.io.service';
import ModaConst from '@/services/ModaConstants';
import TypeIcon from '@/components/templates/TypeIcon'
import SubtypeIcon from '@/components/templates/SubtypeIcon'

export default {
    name: 'Incidents',
    components: {
        Breadcrumb,
        TableAdvancedFilter,
        DeleteDialog,
        TypeIcon,
        SubtypeIcon
    },
    mixins: [
        //AdsTreeTableUtils,
        Utils,
    ],
    data(){
        var incidentEventTableColumnsVueTable = [
           { 
               text: 'Id',
               value: 'incidentIdCode',
               sortable: true,
               filterable: true,
           },
           { 
               text:'Type / Subtype', 
               value:'typeSubtype', 
               sortable: true,
               filterable: true,
           },
           { 
               text: 'Site',
               value: 'siteName',
               sortable: true,
               filterable: true,
           },
           { 
               text: 'Source',
               value: 'sourceName',
               sortable: true,
               filterable: true,
           },
/*
           { 
               text:'Type', 
               value:'componentIdTypeIcon', 
               sortable: true,
               filterable: true,
           },
*/
           {
               text: 'Severity/State/Priority',
               value: 'severityStatePri',
               sortable: true,
               filterable: true,
           },
           { 
               text: 'Details',
               value:'details',
               sortable: false,
               filterable: true,
           },
/*
           { 
               text: 'Headline',
               value:'headline', 
               sortable: true,
               filterable: true,
           },
           { 
               text: 'Category',
               value: 'categoryType',
               sortable: true,
               filterable: true,
           },
*/
/*
           {
               text: 'Severity',
               value: 'severity',
               sortable: true,
               filterable: true,
           },
           {
               text: 'State',
               value: 'state',
               sortable: true,
               filterable: true,
           },
*/
           { 
               text: 'Last updated',
               value: 'modifiedDateString',
               sortable: true,
               filterable: true,
           },
           { 
               text: 'Actions',
               value: 'actions',
               sortable: false,
               width: 160
           },
        ];

        var quickRule = {
                ruleName    : "QuickRule - ",
                fact        : "fault",
                site        : {flag: true, value: null},
                type        : {flag: true, value: null},
                subType     : {flag: true, value: null},
                source      : {flag: true, value: null},
                severity    : {flag: true, value: null},
                eventSeverity : {flag: true, value: null},
                state       : {flag: true, value: null},
                priority    : {flag: true, value: null},
                action      : {name: ModaConst.FaultService.RuleAction.NOTIFY_EMAIL, params: {email: null}},
                expiresIn   : "Never",
                expiresAt   : {startDate: new Date(), endDate: ModaConst.Date.EOL_DATE, endDateBackup: ModaConst.Date.EOL_DATE},
                reference : {
                    incidentId      : null,
                    incidentInfo    : null,
                    note            : "Quick Rule created from Incident - Manual"
                }
        };

        return {
            readonly:true,
            currOrgId: null,
            advSearchCriteria: null,
            //advSearchString: null,

            defaultAdvSearchCriteria: [
                    {"name":"state", // default all open states
                      "value": [
                            ModaConst.Incident.State.NEW,
                            ModaConst.Incident.State.OPEN,
                            ModaConst.Incident.State.IN_PROGRESS,
                            ModaConst.Incident.State.HOLD,
                            ModaConst.Incident.State.REOPEN,
                        ]
                    },
                    {"name":"severity", // default all open states
                      "value": [
                            ModaConst.FaultService.Severity.CRITICAL,
                            ModaConst.FaultService.Severity.SEVERE,
                            ModaConst.FaultService.Severity.MAJOR,
                        ]
                    },
            ],

            // Incidents data
            incidentEventTableColumnsVueTable,
            incidentEvent_table_rows: [],

            isLoading: false,
            userdetails:{},

            isShow: 'display-none',
            parent_pages:[ {name:'Home'}, { name: "Monitoring" }, {name:'Incidents', active:true} ],

            tableAdvancedFilter : {
                // These names match with those defined in the common 'TableAdvancedFilter.vue'
                enabledFilterFields : [], //this.utilsAdvancedFilterFields template doesn't work directly using mixin's data. Init it in mounted()
            },

            apiStatus: null,

            deleteDialog:false,

            
            quickRuleDialog:false,
            quickRule,
            eventTypes:         Object.values(ModaConst.FaultService.CategoryType).filter((x) => typeof(x) == "string"),
            eventSubtypes:      Object.values(ModaConst.FaultService.CategorySubType).map((x) => Object.values(x).filter((y) => typeof(y) == "string")).flat(),
            eventSeverities:    Object.values(ModaConst.FaultService.Severity).filter((x) => typeof(x) == "string"),
            eventStates:        Object.values(ModaConst.Incident.State).filter((x) => typeof(x) == "string"),
            eventPriorities:    Object.values(ModaConst.FaultService.Priority).filter((x) => typeof(x) == "string"),
            eventActions:       [ModaConst.FaultService.RuleAction.DROP_ALERT,      // Not all actions are supported as of now
                                ModaConst.FaultService.RuleAction.AUTO_CLOSE_INCIDENT,
                                ModaConst.FaultService.RuleAction.NOTIFY_EMAIL],
            //--- DataTime picker related ---
            textFieldProps: {
                appendIcon: 'event'
            },
            dateProps: {
                headerColor: 'red'
            },
            timeProps: {
                useSeconds: true,
                ampmInTitle: true
            },


            currId:null,
            currName:null,

            pagingOptions: sessionStorage.getItem(`_pagination${this.$route.path}`) ? JSON.parse(sessionStorage.getItem(`_pagination${this.$route.path}`)) :  this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
            //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable

            //Note: Values of site(List) and component(List) are passed in a tuple of (idList & nameList)
            //      Map() or Set() can not be used as 'id' or 'name' could be empty
            // During a cross launch to this page from other pages, the passed in values are set in xLaunch
            xLaunch : {
                site: {
                    siteIdList: [],         // ["sitobj.1001"] 
                    siteNameList: []        // ["Austin"]
                },
                component : {               // == "source"
                    componentIdType: ModaConst.FaultService.ComponentIdType.OTHER,  // default
                    componentIdList: [],    // ["appobj.1001"]
                    componentNameList: []   // ["Zoom"]
                }
            },
            //---socket ---
            pageName : ModaConst.Socket.Page.INCIDENTS,
            isConnected : false,
            isPageActivated : false,
            isPageVisible : true,
            socketMessage : "",
            eventData : null
        };
    },


    //=== Hooks (Refer to EventsAlarms.vue for usage of all hooks) ==============
    created: function() {
/* TBD Commenting out websockets for now until Kong issue is figured out !!!!!!!!!!!!
        // Connect to the Socket server on 'created' hook only
        let cSocket = socketService.setupSocketConnection(this.pageName, "FAULT_SERVICE_WS_URL", ModaConst.Service.FAULT);
        this.isConnected = true;
        this.socketMessage = "Connected to the FaultServer";

        socketService.sendMessage(this.pageName, "in hook: created");

        cSocket.on("connect", () => {
            console.log("Connected to the Socket server. socket.id = " + cSocket.id);
            socketService.addSocketToActiveMap(this.pageName, cSocket);

            socketService.sendEvent(this.pageName, "page", {pageName: this.pageName, orgId: this.currOrgId});

            this.attachSocketMessageHandlers(this, cSocket);
        });
!!!!!!!!!!!!!!!!!! */
    },

    mounted() {
        console.log("Subtypes " + this.eventSubtypes)
        // template doesn't work directly using mixin's data
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.FREEFORM_SEARCH)
        //this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.ORG_NAME) // orgName removed
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.SITE_NAME)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.EVENT_SOURCE_NAME)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.EVENT_TYPE)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.EVENT_SEVERITY)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.EVENT_STATE)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.MODIFIED_AFTER)
        this.tableAdvancedFilter.enabledFilterFields.push(this.utilsAdvancedFilterFields.MODIFIED_BEFORE)

        //DEBUG: console.log("Events Incidents mounted query " + JSON.stringify(this.$route.query) + ", type " + typeof(this.$route.query))
        //DEBUG: console.log("Events Incidents mounted params " + JSON.stringify(this.$route.params) + ", type " + typeof(this.$route.params))

        if ( this.$route.params && this.$route.params.targetOrgId ){
            this.currOrgId = this.$route.params.targetOrgId
            this.$refs.breadcrumb.getOrganizationsList(this.currOrgId) // needs reload here if we came in from Orgs status page
        }else
            this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
        //console.log("Events Incidents mounted params " + this.currOrgId)

        if ( this.$route.query && Array.isArray(this.$route.query) ) {
            // query passed in from cross Launch
            this.advSearchCriteria = this.$route.query
            //auth.globalIncidentsAdvSearch = this.advSearchCriteria - //don't save cross launch filter as global.
                                                                    // We should be able to see complete list by clicking on left panel
        } else if (auth.crossLaunchIncidentsAdvSearch)
            // else, take the one from crossLaunch state - the one we set when we navigated away from this page
            this.advSearchCriteria = auth.crossLaunchIncidentsAdvSearch
        else if ( auth.globalIncidentsAdvSearch )
            // else, take the one from global state
            this.advSearchCriteria = auth.globalIncidentsAdvSearch
        else // else, default
            this.advSearchCriteria = this.defaultAdvSearchCriteria;

/*
        if ( this.$route.params && this.$route.params.advSearchString )
            this.advSearchString = this.$route.params.advSearchString
        else if ( auth.globalIncidentsAdvSearchString )
            this.advSearchString = auth.globalIncidentsAdvSearchString
*/

        if ( auth.globalIncidentsFreeFormSearchStr )
             this.freeFormSearchStr = auth.globalIncidentsFreeFormSearchStr

        this.init()

        this.get_auth_details();
        this.utilsDataRefreshFunc = this.getIncidentsList;

        //--- socket ---
        socketService.sendMessage(this.pageName, "in hook: mounted");
        document.addEventListener('visibilitychange', this.handleVisibility, false);
    },

    //TRACE: beforeUpdate: function() { socketService.sendMessage(this.pageName, "in hook: beforeUpdate"); },
    //TRACE: updated: function() { socketService.sendMessage(this.pageName, "in hook: updated"); },
    activated: function() {
        this.isPageActivated = true;
        console.log(this.pageName, "in hook: " + this.pageName + " activated");
        socketService.sendMessage(this.pageName, "in hook: " + this.pageName + " activated");
        if ( ! this.isLoading2 ) this.getIncidentsList(); // refresh alarms list when page becomes visible
    },
    deactivated: function() {
        this.isPageActivated = false;
        console.log(this.pageName, "in hook: " + this.pageName + " deactivated");
        socketService.sendMessage(this.pageName, "in hook: " + this.pageName + " deactivated");
    },

    destroyed: function() {
        socketService.sendMessage(this.pageName, "in hook: destroyed. Disconnecting socket");
 
        // Disconnect from the Socket server on 'destroyed' hook only
        socketService.disconnectSocket(this.pageName); 
        this.isConnected = false; 
    },
    //=========================

    watch: {
      pagingOptions: {
        handler () {
            //console.log("incident watch pagingOptions " + JSON.stringify(this.pagingOptions));
            sessionStorage.setItem(`_pagination${this.$route.path}`, JSON.stringify(this.pagingOptions));
            if ( ! this.isLoading2 ) this.getIncidentsList(); // called in activated()
        },
        deep: false,
        immediate: false,
      },

    },
    methods: {
        handlePageChange(value) {
            this.currentPage = value;
        },
/* not used
        advancedSearchCleared(){
            this.advSearchCriteria = null
            this.getIncidentsList()
            this.$refs.tableAdvancedFilter.clearFilters()
        },
*/

        isCancellable(){
            return true;
        },

        navigateToComponent(item){
            var readonly = 1
            if ( item.componentIdType ==  this.UtilsModaConstants.FaultService.ComponentIdType.MONITORED_DEVICE)
                this.$router.push({ name: "MONITORED_DEVICES_EDIT", params: { id: item.componentId, readonly: readonly}})
            else if ( item.componentIdType ==  this.UtilsModaConstants.FaultService.ComponentIdType.DEVICE_AGENT)
                this.$router.push({ name: "MODA_AGENTS_EDIT", params: { id: item.componentId, readonly: readonly}})
            else if ( item.componentIdType ==  this.UtilsModaConstants.FaultService.ComponentIdType.SITE)
                this.$router.push({ name: "SITES_EDIT", params: { siteId: item.componentId, readonly: readonly} })
            else if ( item.componentIdType ==  this.UtilsModaConstants.FaultService.ComponentIdType.APP)
                this.$router.push({ name: "APPLICATIONS_EDIT", params: { appId: item.componentId, readonly: readonly} })
        },
        navigateToSite(targetOrgId, id){
            var readonly = 1
            this.$router.push({ name: "SITES_EDIT_TARGETORG", params: { siteId: id, readonly: readonly, targetOrgId: targetOrgId } })
        },

        // table filter functionality doesn't work unless it's rendering from native fields
        // i.e. doesn't work if it's rendered using slots and dynamically created content. 
        // So expand/build fields here
        buildFilterableColumns(objs){
            objs.forEach((obj) => {
                obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
                obj.modifiedDateString = this.utilsFormatDateTime(obj.modifiedDate);
                if ( obj._children ) this.buildFilterableColumns(obj._children);

                obj.componentIdTypeIcon = obj.componentIdType=="citApp" ? "💻" : "🏢";

                if (obj.iconImg) {
                    obj.componentIconImg = obj.iconImg;
                } else {
                    obj.componentIconImg = null;
                }
                obj.sourceIcon = obj.componentIcon;
                obj.sourceName = obj.componentName;
                // for ML type incidents, use details = description, else details=headline in the table
                obj.details = ( obj.categoryType == ModaConst.FaultService.CategoryType.ML_ANOMALY_TELEMETRY  ||
                                obj.categoryType == ModaConst.FaultService.CategoryType.ML_ANOMALY_LOG_ANALYSIS ) ? 
                                obj.details = obj.description : obj.headline
            })
        },

        getIncidentsList() {
            this.isLoading=true
            this.isLoading2=true

            let query_params = {}, body = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
            if ( this.freeFormSearchStr ) query_params.searchString= "any:*:" + this.freeFormSearchStr
            query_params.pageNum = this.pagingOptions.page;
            query_params.pageSize= this.pagingOptions.itemsPerPage;
            var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions,
                                            {typeSubtype: "categoryType", severityStatePri: "severityNum", sourceName:"componentName"} )
            if ( sortCriteria ) query_params.sortCriteria = sortCriteria
            if ( this.advSearchCriteria ) {
                //console.log("getIncidentsList advSearchCriteria ", JSON.stringify(this.advSearchCriteria))
                var advSearchCriteria = []
                Object.keys(this.advSearchCriteria).forEach( (k) => advSearchCriteria.push(this.advSearchCriteria[k]) )
                if ( advSearchCriteria.length ) body.advSearchCriteria = advSearchCriteria
                //console.log("getIncidentsList body ", JSON.stringify(body))
            }

            ApiService.post("/svcFault/api/v1/incident/page2?shorten=true", body,
                (err, result) => {
                this.isLoading=false;
                this.isLoading2=false;
                //console.log("getIncidentsList " + JSON.stringify(result));
                if ( err ) {
                    this.apiStatus =
                        (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                    //console.log("getIncidentsList " + JSON.stringify(result.data.data));
                    this.utilsTotalEntriesAllPages = result.data.data.totalEntries ;
                    this.incidentEvent_table_rows=result.data.data.docs;
                    this.buildFilterableColumns(this.incidentEvent_table_rows);
                    //console.log("getIncidentsList " + JSON.stringify(this.incidentEvent_table_rows, null, 2));
                }

            }, query_params, "FAULT_SERVICE_URL")
        },
        editIncidentEventDetails(id, readonly) {
            auth.crossLaunchIncidentsAdvSearch = this.advSearchCriteria // save this state to come back to same search state
            this.$router.push({ name: "INCIDENTS_EDIT", params: { id: id, readonly: readonly}})
        },

        deleteEventIncident(Id, name){
            this.deleteDialog = true;
            this.currId = Id;
            this.currName = name;
        },

        deleteObjectChildEvent(cancelFlag, isBackupDel) 
        {
            console.log("** delete " + isBackupDel);
            this.deleteDialog = false;
            if(cancelFlag == true) return;

            //if(confirm("Do you really want to delete - '" + name + "' ?"))
            {
                this.isLoading =true;

                let query_params = {};
                if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;
                query_params.deleteMode=this.UtilsModaConstants.DeleteMode.FORCE;

                ApiService.delete("/svcFault/api/v1/incident/"+this.currId, 
                    (err, result) => {
                    this.isLoading=false
                    if ( err ){
                        this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                        this.utilsCheckLogout(this.apiStatus);
                    } else {
                        this.apiStatus = null;
                        this.getIncidentsList();
                    }
                }, query_params, "FAULT_SERVICE_URL")
            }
        },

        openQuickRuleDialog(inc) {
            //DEBUG: console.log("\ninc = " + JSON.stringify(inc));
            this.quickRule.ruleName                 = "QuickRule - from " + inc.incidentIdCode;
            this.quickRule.site.value               = inc.siteName;
            this.quickRule.type.value               = [inc.categoryType];
            this.quickRule.subType.value            = [inc.categorySubType];
            this.quickRule.source.value             = inc.sourceName;
            this.quickRule.severity.value           = [inc.severity];
            this.quickRule.eventSeverity.value      = [inc.severity];
            this.quickRule.state.value              = [inc.state];
            this.quickRule.priority.value           = [inc.priority];
            this.quickRule.reference.incidentId     = inc.incidentId;
            this.quickRule.reference.incidentInfo   = inc.headline;

            this.quickRuleDialog = true;
        },

        cancelQuickRule() {
            console.log("in cancelQuickRule");
            this.quickRuleDialog = false;
        },

        //--- helper util ------------
        makeCond (factType, param, paramName) {
            let cond = null;

            if (param.flag) {
                // Need not create cond, if the value (of array type) is empty
                if (Array.isArray(param.value)) {
                    if (param.value.length > 0 && param.value[0]) {
                        cond = {
                            fact : this.quickRule.fact,
                            path : factType + paramName,
                            operator : "in",
                            value : param.value
                        };
                    }
                } else if (param.value) {    // create cond, iff (single) value is not empty
                    cond = {
                        fact : this.quickRule.fact,
                        path : factType + paramName,
                        operator : "equal",
                        value : param.value
                    };
                }
            }

            return cond;
        },

        determineRuleType(actionName) {
            if (actionName == ModaConst.FaultService.RuleAction.NOTIFY_EMAIL ||
                actionName == ModaConst.FaultService.RuleAction.NOTIFY_SMS) {
                    return ModaConst.FaultService.RuleType.NOTIFY;
            } else {
                    return ModaConst.FaultService.RuleType.FILTER;
            }
        },

        makeActionParams(actionName, params) {
            let apObj = {};

            console.log("actionName = " + actionName + ", params = " + JSON.stringify(params));

            if (actionName == ModaConst.FaultService.RuleAction.NOTIFY_EMAIL) {
                apObj.email = params.email.split(",").map((el) => el.trim());
            } else if (actionName == ModaConst.FaultService.RuleAction.NOTIFY_SMS) {
                apObj.phone = params.phone;
            } else if (ModaConst.FaultService.RuleAction.AUTO_CLOSE_INCIDENT) {
                apObj.incidentId = params.incidentId;
            } else {        // ie Drop Event
                // Empty;
            }

            return apObj;
        },

        createQuickRule() {
            //DEBUG:
            console.log("this.quickRule = " + JSON.stringify(this.quickRule, null, 2));

            //this.$v.$touch()
            this.isLoading = true;
            let query_params = {};
            if ( this.currOrgId ) query_params.targetOrgId = this.currOrgId;

            let condList = [];
            let factType = (this.quickRule.action.name == ModaConst.FaultService.RuleAction.DROP_ALERT) ? "$.alert." : "$.incident.";

            // In every rule orgId condition is mandatory
            let orgCond = {
                    fact : this.quickRule.fact,
                    path : factType + "orgId",
                    operator : "equal",
                    value : query_params.targetOrgId
            };
            condList.push(orgCond);

            let cond = null;
            if (this.quickRule.action.name == ModaConst.FaultService.RuleAction.DROP_ALERT) {
                // Only the following params are relevant at Alert stage
                if ((cond = this.makeCond(factType, this.quickRule.site, "siteName")) != null) condList.push(cond);
                if ((cond = this.makeCond(factType, this.quickRule.type, "categoryType")) != null) condList.push(cond);
                if ((cond = this.makeCond(factType, this.quickRule.subType, "categorySubType")) != null) condList.push(cond);
                if ((cond = this.makeCond(factType, this.quickRule.source, "componentName")) != null) condList.push(cond);
                if ((cond = this.makeCond(factType, this.quickRule.eventSeverity, "severity")) != null) condList.push(cond);
                //state - N/A
                //priority - N/A
            } else {        // ie if (this.quickRule.action.name == ModaConst.FaultService.RuleAction.AUTO_CLOSE_INCIDENT) {
                if ((cond = this.makeCond(factType, this.quickRule.site, "siteName")) != null) condList.push(cond);
                if ((cond = this.makeCond(factType, this.quickRule.type, "categoryType")) != null) condList.push(cond);
                if ((cond = this.makeCond(factType, this.quickRule.subType, "categorySubType")) != null) condList.push(cond);
                if ((cond = this.makeCond(factType, this.quickRule.source, "componentName")) != null) condList.push(cond);
                if ((cond = this.makeCond(factType, this.quickRule.severity, "severity")) != null) condList.push(cond);
                if ((cond = this.makeCond(factType, this.quickRule.state, "state")) != null) condList.push(cond);
                if ((cond = this.makeCond(factType, this.quickRule.priority, "priority")) != null) condList.push(cond);

                this.quickRule.action.params.incidentId = this.quickRule.reference.incidentId;
            }

            let ruleBody = {
                    ruleName : this.quickRule.ruleName,
                    description : "Quick Rule created from Alarms/Incidents page",
                    ruleType : this.determineRuleType(this.quickRule.action.name),
                    facts : [this.quickRule.fact],
                    ruleDefinition : {
                        name : this.quickRule.ruleName,
                        conditions: {
                            all : condList
                        },
                        event : {
                            type : this.determineRuleType(this.quickRule.action.name),
                            params : {
                                action : this.quickRule.action.name,
                                actionParams : this.makeActionParams(this.quickRule.action.name, this.quickRule.action.params),
                                ruleInfo : {
                                    ruleName : this.quickRule.ruleName,
                                    orgId: query_params.targetOrgId,        // Not needed (as it will be available while alert processing)
                                    ruleId : "rule.9999",                   // update with proper value later
                                    expiryDateEpoch : 0                     // update with proper value later
                                }
                            }
                        }
                    },
                    ruleDefStr : {conditions: null, action: null},
                    expiryDate : this.quickRule.expiresAt.endDate,
                    //expiresAt : this.quickRule.expiresAt,     // not needed now. Use if startDate is also needed
                    //expiresIn : this.quickRule.expiresIn,     // not needed
                    reference : this.quickRule.reference
            };

            //DEBUG: console.log("QuickRule. ruleBody = ", JSON.stringify(ruleBody, null, 2));

            ApiService.post("/svcFault/api/v1/rule/", ruleBody, (err, result) => {
                this.isLoading = false;
                if (err){
                    this.apiStatus = (result ? (result.data.message ? result.data.message : result.data) : "Request failed");
                    this.utilsCheckLogout(this.apiStatus);
                } else {
                    this.apiStatus = null;
                }
            }, query_params, "FAULT_SERVICE_URL");

            this.quickRuleDialog = false;
        },

        setExpiresAt() {
            //this.quickRule.expiresAt.startDate = Date.now();
           
            if (this.quickRule.expiresIn == "Never") {
                this.quickRule.expiresAt.endDate = this.quickRule.expiresAt.endDateBackup = ModaConst.Date.EOL_DATE;
            } else {
                let mins = 0;
                if (this.quickRule.expiresIn == "10 m") mins = 10;
                else if (this.quickRule.expiresIn == "1 h") mins = 60;
                else if (this.quickRule.expiresIn == "1 d") mins = 1440;     // 60*24 = 1 day
                else console.log("ERROR: Invalid expiresIn value. Ignoring");

                if (mins != 0) {
                    let now = new Date();
                    this.quickRule.expiresAt.endDate = this.quickRule.expiresAt.endDateBackup = new Date(now.getTime() + mins*60000);
                }
            }
        },
        dateTimeChanged(val) {
            //console.log("Date time changed to " + val)
            if ( val ) // save it
                this.quickRule.expiresAt.endDateBackup = val;
            else // Cleared. Set back to old value
                setTimeout( () => { this.quickRule.expiresAt.endDate = this.quickRule.expiresAt.endDateBackup }, 100)
        },

        get_auth_details(){
            let title = this.$route.meta.title;
            this.isShow = auth.AuthService.get_usr_auth_details(title)
        },
        processBreadcrumbMessage(selectedOrgId){
            this.currOrgId = selectedOrgId;
            //this.advSearchCriteria = this.defaultAdvSearchCriteria;
            this.clearPaginationCache();
            this.getIncidentsList();
        },
        clearPaginationCache(){
            sessionStorage.removeItem(`_pagination${this.$route.path}`);
            this.pagingOptions.page = 1;
        },

        // helper function to compare two lists. //TODO: Move this to common utils file
        isSameList(list1, list2) {
            //DEBUG: console.log("isSameList: list1 = [" + list1 + "], list2 = [" + list2 + "]");
            let res = false;
            if (list1 == list2) {       //TODO: Use token based comparision
                res = true;
            } 

            console.log("isSameList: res = " + res);
            return res;
        },

        processFreeFormSearchChange(freeFormSearchStr){
            this.freeFormSearchStr = freeFormSearchStr
            auth.globalIncidentsFreeFormSearchStr = freeFormSearchStr
            console.log("EventsIncidents processFreeFormSearchChange " + this.freeFormSearchStr)
            this.getIncidentsList();
        },

        processAdvancedFilterChange(advancedFilter){
            //console.log("Table filter msg received " + JSON.stringify(advancedFilter))

            this.pagingOptions.page = 1;        // Reset page to 1st page, for AdvSearch APPLY

            this.advSearchCriteria = []
            //this.advSearchString = ""

/* orgName filter removed
            if ( advancedFilter.orgNameFilter ){
                this.advSearchCriteria.push({"name":"orgName", "value": advancedFilter.orgNameFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.orgNameFilter)
            }
*/
            if ( advancedFilter.siteNameFilter ){
                if (advancedFilter.siteNameFilter == null ||
                    advancedFilter.siteNameFilter == [] || 
                    !this.isSameList(advancedFilter.siteNameFilter, this.xLaunch.site.siteNameList)) {
                    //advancedFilter.siteNameFilter != this.xLaunch.site.siteNameList) {    
                        // siteNameList is empty or cross-launched siteNameList is modified. 
                        // Let the backend calculate the Ids, as needed
                        this.xLaunch.site.siteIdList = null;
                }
                this.advSearchCriteria.push({"name":"site",
                                             "value": {siteIdList: this.xLaunch.site.siteIdList, siteNameList: advancedFilter.siteNameFilter}})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.siteNameFilter)
            }
            
            if ( advancedFilter.eventSourceNameFilter ){
                if (advancedFilter.componentNameFilter == null ||
                    advancedFilter.componentNameFilter == [] || 
                    !this.isSameList(advancedFilter.componentNameFilter, this.xLaunch.component.componentNameList)) {
                    //advancedFilter.componentNameFilter != this.xLaunch.component.componentNameList) {
                        // componentNameList is empty or cross-launched componentNameList is modified. 
                        // Let the backend calculate the Ids, as needed
                        this.xLaunch.component.componentIdList = null;
                }
                this.advSearchCriteria.push({"name":"component", "value": 
                        {componentIdType: this.xLaunch.component.componentIdType, 
                        componentIdList: this.xLaunch.component.componentIdList, 
                        componentNameList: advancedFilter.eventSourceNameFilter}})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.eventSourceNameFilter)
            }
            if ( advancedFilter.eventTypeFilter ){
                this.advSearchCriteria.push({"name":"categoryType", "value": advancedFilter.eventTypeFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.eventTypeFilter)
            }
            if ( advancedFilter.eventSeverityFilter ){
                this.advSearchCriteria.push({"name":"severity", "value": advancedFilter.eventSeverityFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.eventSeverityFilter)
            }
            if ( advancedFilter.eventStateFilter ){
                this.advSearchCriteria.push({"name":"state", "value": advancedFilter.eventStateFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, advancedFilter.eventStateFilter)
            }
            if ( advancedFilter.modifiedAfterFilter && advancedFilter.modifiedBeforeFilter ){
                this.advSearchCriteria.push({"name":"modifiedDate",
                                  "value": [advancedFilter.modifiedAfterFilter, advancedFilter.modifiedBeforeFilter]})
                //this.advSearchString = this.utilsAppendString(this.advSearchString,
                       //"Between " + advancedFilter.modifiedAfterFilter + " & " + advancedFilter.modifiedBeforeFilter)
            } else if ( advancedFilter.modifiedAfterFilter ){
                this.advSearchCriteria.push({"name":"modifiedDate", "value": ">"+advancedFilter.modifiedAfterFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, "After " + advancedFilter.modifiedAfterFilter)
            }else if ( advancedFilter.modifiedBeforeFilter ){
                this.advSearchCriteria.push({"name":"modifiedDate", "value": "<"+advancedFilter.modifiedBeforeFilter})
                //this.advSearchString = this.utilsAppendString(this.advSearchString, "Before " + advancedFilter.modifiedBeforeFilter)
            }
            auth.globalIncidentsAdvSearch       = this.advSearchCriteria;
            //auth.globalIncidentsAdvSearchString = this.advSearchString;
            this.clearPaginationCache();
            this.getIncidentsList();
        },

        //---socket ---
        attachSocketMessageHandlers(self, clientSocket) {
            console.log("attachSocketMessageHandlers: clientSocket.id = " + (clientSocket) ? clientSocket.id : null);

            clientSocket.on(ModaConst.Socket.Event.INCIDENT, data => {
                console.log("Got an Incident event from server: data = " + JSON.stringify(data));
                self.eventData = data;

                let relevantEvent = true;   //TODO: Refer to EventAlarms.vue code
                if (relevantEvent && self.isPageVisible) {
                    //DEBUG: console.log("Socket: getting incidents list ...");
                    self.getIncidentsList();
                }
            });

            clientSocket.on('message', (data) => {
                console.log("Got message from FaultServer: data = " + JSON.stringify(data));
                self.socketMessage  = data;
            });
        },

        handleVisibility () {
            if (document.visibilityState === 'hidden') {
                this.isPageVisible = false;
                this.socketMessage = "Client's Incidents Page is hidden";
            } else {
                this.isPageVisible = true;
                this.socketMessage = "Client's Incidents page is visible " + this.isPageActivated;
                if ( this.isPageActivated ) this.getIncidentsList(); // refresh alarms list when page becomes visible
            }
            //DEBUG:
            socketService.sendMessage(this.pageName, this.socketMessage);
        },

        init() {
            if ( this.advSearchCriteria ) {
                console.log("EventsIncidents init 1 " + JSON.stringify(this.advSearchCriteria))
                var tmp
/* orgName filter removed
                tmp = this.advSearchCriteria.find(e => e.name == "orgName")
                if ( tmp ) {
                    this.tableAdvancedFilter.orgNamesFilterIn  = tmp.value.orgNameList;
                    // orgNames are only to pass to this component, orgIds are sent to backend
                    // send names to backend
                    // this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "orgName")
                }
*/

                tmp = this.advSearchCriteria.find(e => e.name == "site")
                if ( tmp ) {
                    this.tableAdvancedFilter.siteNamesFilterIn  = tmp.value.siteNameList;
                    // send names to backend
                    // this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "siteName")
                    this.xLaunch.site = tmp.value;
                } else { // set default
                    this.tableAdvancedFilter.siteNamesFilterIn = [];
                    this.xLaunch.site = {siteIdList: [], siteNameList: []};
                }

/* Replaced with generic componentId, componentName filters
                tmp = this.advSearchCriteria.find(e => e.name == "monDevName" || e.name == "deviceName" || e.name == "appName" )
                if ( tmp ) {
                    this.tableAdvancedFilter.eventSourceNamesFilterIn  = tmp.value;
                    // send names to backend
                    // this.advSearchCriteria = this.advSearchCriteria.filter(e => e.name != "monDevName")
                }
*/
                tmp = this.advSearchCriteria.find(e => e.name == "component")
                if ( tmp ) {
                    this.tableAdvancedFilter.eventSourceNamesFilterIn = tmp.value.componentNameList;
                    this.xLaunch.component = tmp.value;
                } else { // set default
                    this.tableAdvancedFilter.eventSourceNamesFilterIn = [];
                    this.xLaunch.component = {componentIdType: ModaConst.FaultService.ComponentIdType.OTHER, componentIdList: [], componentNameList: []};
                }

                tmp = this.advSearchCriteria.find(e => e.name == "categoryType")
                if ( tmp ) this.tableAdvancedFilter.eventTypesFilterIn  = tmp.value

                tmp = this.advSearchCriteria.find(e => e.name == "severity")
                if ( tmp ) this.tableAdvancedFilter.eventSeveritiesFilterIn  = tmp.value

                tmp = this.advSearchCriteria.find(e => e.name == "state")
                if ( tmp ) this.tableAdvancedFilter.eventStatesFilterIn  = tmp.value

                tmp = this.advSearchCriteria.find(e => e.name == "modifiedDate")
                if ( tmp ) {
                    tmp = tmp.value
                    if ( Array.isArray(tmp) ){
                        this.tableAdvancedFilter.modifiedAfterFilterIn  = tmp[0]
                        this.tableAdvancedFilter.modifiedBeforeFilterIn = tmp[1]
                    }else if (tmp.charAt(0) == ">" ){
                        this.tableAdvancedFilter.modifiedAfterFilterIn  = tmp.slice(1)
                    }else if (tmp.charAt(0) == "<" ){
                        this.tableAdvancedFilter.modifiedBeforeFilterIn  = tmp.slice(1)
                    }
                }
            }
            this.$refs.tableAdvancedFilter.setAdvancedFilters(this.tableAdvancedFilter, this.freeFormSearchStr)

             //console.log("EventIncidents init 2 " + JSON.stringify(this.advSearchCriteria))
        },

    }

}
</script>
