<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="5" class="tile-box">
      <v-card-title class="page-title">
        <v-icon>mdi-application-cog-outline</v-icon> System Settings
      </v-card-title>
      <v-card-text class="pb-0">
        <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
          {{ apiStatus }}
        </v-alert>
        <v-form ref="sysSettingsForm" class="px-5">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model.number="sysSettings.heartbeatInterval" label="Agent Heartbeat Interval (secs)"
                :disabled="readonly" type="number" @input="dirty = true" />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model.number="sysSettings.maxMissedKAInterval"
                label="Max Missing Heartbeats Interval (secs)" :disabled="readonly" type="number"
                @input="dirty = true" />
            </v-col>
            <!--
            <v-col cols="12" md="3">
                <v-text-field v-model.number="sysSettings.sessionTimeoutInterval" label="What's this for ??(secs)"
                                        :disabled="readonly" type="number" @input="dirty = true"/>
            </v-col>
-->
            <v-col cols="12" md="4">
              <v-subheader><strong>Last Modified: </strong> {{ sysSettings.modifiedDateString }}</v-subheader>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="justify-end px-5">
        <v-btn color="success" rounded outlined v-if="!readonly" @click="submit" :disabled="!dirty">Update</v-btn>
      </v-card-actions>
      <v-card-text>
        <div class="search-wrapper">
          <v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify" />
        </div>
      </v-card-text>
      <v-container fluid class="pt-0">
        <v-data-table :headers="sysSettings_table_vuetify_columns" :items="sysSettings.defaultConfigProfiles" :options.sync="pagingOptions"
      :server-items-length="utilsTotalEntriesAllPages" multi-sort :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }" >

          <template v-slot:item.deviceModelName="{ item }">
            <a class="link-text" v-on:click="navigateToDeviceModel(item.deviceModelId)"> {{item.deviceModelName}}</a>
          </template>
          <template v-slot:item.configProfileName="{ item }">
            <a class="link-text" v-on:click="navigateToConfigProfile(item.configProfileType, item.configProfileId)">
              {{item.configProfileName}}</a>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import auth from "@/services/auth.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import Utils from "@/pages/moda/Utils.vue";

export default {
  components: {
    Breadcrumb,
  },
  mixins: [Utils],
  data() {
    var sysSettings_table_vuetify_columns = [
      {
        text: "Device Model",
        value: "deviceModelName",
        sortable: true,
        filterable: true,
      },
      {
        text: "Profile Type",
        value: "configProfileType",
        sortable: true,
        filterable: true,
      },
      {
        text: "Profile Name",
        value: "configProfileName",
        sortable: true,
        filterable: true,
      },
    ];

    return {
      currOrgId: null,

      sysSettings_table_vuetify_columns,
      sysSettings: { defaultConfigProfiles:[] },
      dirty: false,
      readonly: false,

      isLoading: false,

      userdetails: {},
      isShow: "display-none",
      parent_pages: [{ name: "Home" }, {name: "Admin Tasks"}, { name: "System Settings", active: true }],
      apiStatus: null,
      currId: null,
      currName: null,

      pagingOptions: this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
      //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable
    };
  },
  watch: {
    pagingOptions: {
      handler() {
        this.getSystemSettings();
      },
      deep: false,
      immediate: false,
    },
  },
  methods: {
    // table filter functionality doesn't work unless it's rendering from native fields
    // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
    buildFilterableColumns(obj) {
        if ( obj.modifiedDate ) obj.modifiedDateString = this.utilsFormatDateTime(obj.modifiedDate);
    },

    navigateToDeviceModel(id){
        id
        this.$router.push( { name: "DEVICE_MODELS" } )
    },
    navigateToConfigProfile(type, id){
        var readonly = 1
        if ( type == this.UtilsModaConstants.ConfigProfileType.ANALYTICS )
            this.$router.push( { name : "ANAL_CONFIG_PROFILES_VIEW", params : { analyticsConfigProfileId: id}})
        else if ( type == this.UtilsModaConstants.ConfigProfileType.SYSTEM )
            this.$router.push( { name : "SYS_CONFIG_PROFILES_EDIT", params : { systemConfigProfileId: id, readonly: readonly}})
    },

    submit() {

        this.isLoading=true
        let query_params = {}
        if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

        ApiService.put( "/api/v1/systemSettings", this.sysSettings, (err, result) => {
          this.isLoading = false;
          this.dirty = false;
          if (err){
            this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
            this.utilsCheckLogout(this.apiStatus)
          }else {
            this.apiStatus = null;
            this.getSystemSettings();
          }
        }, query_params);
    },

    getSystemSettings() {
      this.isLoading=true
      let query_params = {}
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      if (this.filterValueSearch)
        query_params.searchString = "any:*:" + this.filterValueSearch;

      ApiService.get( "/api/v1/systemSettings", (err, result) => {
          this.isLoading = false;
          if (err){
            this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
            this.utilsCheckLogout(this.apiStatus)
          }else {
            this.apiStatus = null;

            this.sysSettings = result.data.data;
            this.utilsTotalEntriesAllPages = this.sysSettings.defaultConfigProfiles.length

            this.buildFilterableColumns(this.sysSettings);
            //console.log("getSystemSettings " + JSON.stringify(this.sysSettings));
          }
        },
        query_params
      );
    },

    get_auth_details() {
      let title = this.$route.meta.title;
      this.isShow = auth.AuthService.get_usr_auth_details(title);
    },
    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      this.getSystemSettings();
    },
  },
  mounted() {
    this.readonly = false
    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    this.get_auth_details();
    this.utilsDataRefreshFunc = this.getSystemSettings;
    //this.getSystemSettings();
  },
};
</script>
