<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="2" class="tile-box">
      <v-card-title class="page-title">
        <v-icon>mdi-bell</v-icon>Event Details {{ titleName }}
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container fluid>
          <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
            {{ apiStatus }}
          </v-alert>
          <v-row>
            <v-col cols="12" v-if="isLoading">
              <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="6">

              <strong>ID</strong>
              <p>{{ alarmSelected.alarmIdCode }}
                <v-icon v-if="isAlarmRaise(alarmSelected.type)" color="error" title="Raise">mdi-arrow-up</v-icon>
                <v-icon v-else color="success" title="Clear">mdi-arrow-down</v-icon>
              </p>
              <strong>Related Incident</strong>
              <p> {{ alarmSelected.incidentIdCode }}
                <span v-if="!readonly">
                  <v-icon v-if="isLinkable(alarmSelected.incidentId)" @click="
                    linkUnlinkAlarmEventDetails(
                      alarmSelected.alarmId,
                      alarmSelected.incidentId,
                      alarmSelected.shortDescription,
                      true
                    )
                  " color="primary" title="Link">
                    mdi-link-variant
                  </v-icon>

                  <v-icon v-else @click="
                    linkUnlinkAlarmEventDetails(
                      alarmSelected.alarmId,
                      alarmSelected.incidentId,
                      alarmSelected.shortDescription,
                      false
                    )
                  " color="warning" title="Un-Link">
                    mdi-link-variant-off
                  </v-icon>
                </span>
              </p>
              <v-autocomplete v-model="incidentIdToLinkToAlarm" v-if="!readonly && isLinkOper"
                :items="relatedIncidentList" item-text="incidentIdHeadline" item-value="incidentId"
                :error-messages="apiStatus" />

              <strong>Headline</strong>
              <p>{{ alarmSelected.shortDescription }}</p>

              <strong>Source : </strong>
              <p>{{ alarmSelected.componentName }}</p>

            </v-col>
            <v-col cols="12" md="4" sm="6">
              <strong>Details</strong>
              <p>{{ alarmSelected.description }}</p>
              <strong>Site: </strong>
              <p><a class="link-text" v-on:click="navigateToSite(alarmSelected.orgId, alarmSelected.siteId)">
                  {{ alarmSelected.siteName }} </a></p>
              <strong>Date Created</strong>
              <p>{{ alarmSelected.createdDateString }}</p>
            </v-col>
            <v-col cols="12" md="4" sm="6">

              <!-- <v-subheader
                ><strong>State : </strong>
                <span
                  :class="utilsNonSeverityColors[alarmSelected.status].colorClass"
                >
                  {{ alarmSelected.status }}
                </span>
              </v-subheader> -->
              <strong>Severity</strong>
              <div>
                <v-chip :color="utilsSeverityColors[alarmSelected.severity].colorName"
                  :dark="utilsSeverityColors[alarmSelected.severity].darkMode">
                  {{ alarmSelected.severity }} </v-chip>
              </div>

              <div class="pt-4"><strong>Type</strong></div>
              <TypeIcon :item="alarmSelected" :UtilsModaConstants="UtilsModaConstants" :showlabels="true" />
              <!-- sub type -->
              <div class="pt-4 float-left"><strong>Sub Type</strong></div>
              <SubtypeIcon :item="alarmSelected" :UtilsModaConstants="UtilsModaConstants" :showlabels="true" />
              
            </v-col>
            <v-col cols="12">
              <div v-if="paramList">
                <strong>Parameters</strong>
                <v-data-table dense hide-default-footer :headers="paramListColumnsVueTable" :items="paramList">
                </v-data-table>
              </div>
              <div v-if="anomalyBatches.length">
                <strong> Log file anomalies : </strong>
                <div>
                  <v-chip-group class="ml-3" column mandatory active-class="primary--text">
                    <v-chip v-for="index in highLightCounter" :key="index" :id="'highlight-button-' + index"
                      v-on:click="scrollIntoView(index)" pill color="yellow">
                      {{ index }}
                    </v-chip>
                  </v-chip-group>
                </div>
                <v-card outlined class="mb-2" v-for="batch in anomalyBatches" :key="batch.anomalyIndexFileName">
                  {{ batch.anomalyIndexFileName }}
                  <div style="height: 150px; overflow: scroll; white-space: nowrap" v-html="batch.fileContent" />
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn rounded outlined color="success" v-if="isLinkUnlinkOperActive" @click="saveIncidentLinkChanges">Save
        </v-btn>
        <v-btn rounded outlined v-if="!readonly" color="error" @click="cancel">Cancel</v-btn>
        <v-btn rounded outlined v-else color="success" @click="cancel">Go Back</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import ApiService from "@/services/api.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import Utils from "@/pages/moda/Utils.vue";
import { validationMixin } from "vuelidate";
import TypeIcon from '@/components/templates/TypeIcon'
import SubtypeIcon from '@/components/templates/SubtypeIcon'
// import { globalAPIUrlStore } from "../../main.js";

export default {
  components: {
    Breadcrumb,
    TypeIcon,
    SubtypeIcon
  },
  data() {
    var paramListColumnsVueTable = [
      {
        text: "Parameter",
        value: "parameter",
      },
      {
        text: "Current value",
        value: "value",
      },
      {
        text: "Absolute limit",
        value: "absoluteLimit",
      },
      {
        text: "ML imposed limit",
        value: "mlLimit",
      },
      {
        text: "Reason",
        value: "reason",
      },
    ];
    return {
      highLightCounter: 0,
      currOrgId: null,
      isLoading: false,
      fullPage: true,
      onCancel: true,
      height: 35,
      width: 35,
      Alarm_id: "",
      AlarmIdCode: "",
      titleName: "Create",
      isEdit: false,
      readonly: true,

      alarmSelected: {
        // below params needed to be initialized to a valid initial value as they are used during rendering
        severity: "Info",
        status: "Ack",
        categoryType: "",
        categorySubType: "",
      },

      isLinkUnlinkOperActive: false,
      isLinkOper: false,
      linkIncidentIdEnabled: false,
      incidentIdToLinkToAlarm: null,
      relatedIncidentList: [],
      userdetails: JSON.parse(localStorage.getItem("user-details")),
      parent_pages: [
        { name: "Home" },
        { name: "Events", route: { name : "ALARMS" } },
        { name: "Create/Edit", active: true },
      ],

      apiStatus: null,

      paramListColumnsVueTable,
      paramList: null,
      anomalyBatches: [],
      // !!!! This object must be same as ML multi_param.my : paramDisplayNames !!!!
      paramDisplayNames: {
        latency_avg:                { name: "App path latency",                     units: "msec",  divider: 1 },
        latency_std_dev_avg:        { name: "App path latency variation",           units: "msec",  divider: 1, },
        loss_percent_avg:           { name: "Loss",                                 units: "%",     divider: 1 },
        downlink_speed_avg:         { name: "Downlink speed",                       units: "Mbps",  divider: 1000000, },
        uplink_speed_avg:           { name: "Uplink speed",                         units: "Mbps",  divider: 1000000, },
        flow_iat_transactions_avg:  { name: "TCP/UDP session transaction latency",  units: "msec",  divider: 1, },
        flow_proto_rsps_reqs_ratio: { name: "Protocol responses vs requests ratio", units: "",      divider: 1, },
        flow_proto_err_rsps_total:  { name: "Protocol error responses",             units: "",      divider: 1, },
      },
    };
  },
  mixins: [Utils, validationMixin],
  validations: {
    Alarmdetails: {},
  },
  watch: {
    highLightCounter: function () {
      setTimeout(() => this.scrollIntoView(1), 200);
    },
  },
  methods: {
    isAlarmRaise(type) {
      return type == "Raise";
    },
    isCancellable() {
      return true;
    },
    isLinkable(id) {
      return !this.isUnLinkable(id);
    },
    isUnLinkable(id) {
      if (id && id.length > 2) return true;
      else return false;
    },
    linkUnlinkAlarmEventDetails(Id, incidentId, name, isLink) {
      this.isLinkUnlinkOperActive = true;
      this.isLinkOper = isLink;
      if (this.isLinkOper) this.getAlarmRelatedIncidentList();
    },
    navigateToSite(targetOrgId, id){
        var readonly = 1
        this.$router.push({ name: "SITES_EDIT_TARGETORG", params: { siteId: id, readonly: readonly, targetOrgId: targetOrgId } })
    },
    cancelIncidentLinkChanges() {
      this.isLinkUnlinkOperActive = false;
      this.isLinkOper = false;
      //console.log("cancelIncidentChanges called");
    },
    saveIncidentLinkChanges() {
      //console.log("saveIncidentChanges called "+ this.incidentIdToLinkToAlarm );
      //this.submit ();
      if (this.isLinkOper == false) this.alarmSelected.incidentId = "";
      else this.alarmSelected.incidentId = this.incidentIdToLinkToAlarm;

      this.isLinkUnlinkOperActive = false;
      this.isLinkOper = false;
      let query_params = {};
      ApiService.put(
        "/svcFault/api/v1/alarm/" + this.alarmSelected.alarmId,
        this.alarmSelected,
        (err, result) => {
          this.isLoading = false;
          if (err) {
            this.apiStatus = result
              ? result.data.message
                ? result.data.message
                : result.data
              : "Request failed";
            this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            //this.cancelCreateDevice();
          }
        },
        query_params,
        "FAULT_SERVICE_URL"
      );
      this.cancelCreateAlarm();
    },
    getAlarmRelatedIncidentList() {
      //this.isLoading=true

      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      //query_params.targetOrgId = "orgobj.1420";

      ApiService.get(
        "/svcFault/api/v1/incident?shorten=true",
        (err, result) => {
          this.isLoading = false;
          if (err) {
            this.apiStatus = result
              ? result.data.message
                ? result.data.message
                : result.data
              : "Request failed";
            this.utilsCheckLogout(this.apiStatus);
          } else {
            this.apiStatus = null;
            this.incidentIdToLinkToAlarm = null;
            this.relatedIncidentList = result.data.data;
            this.relatedIncidentList.forEach((obj) => {
              obj.incidentIdHeadline =
                obj.incidentIdCode + " : " + obj.headline;
            });
            //console.log("relatedIncidentList " + JSON.stringify(this.relatedIncidentList));
          }
        },
        query_params,
        "FAULT_SERVICE_URL"
      );
    },
    submit() {
      this.$v.$touch();

      this.isLoading = true;
      let query_params = {};
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;

      if (this.isEdit) {
        ApiService.put(
          "/api/v1/Alarm/" + this.Alarm_id,
          this.Alarmdetails,
          (err, result) => {
            //console.log("*update*"+JSON.stringify(result))
            this.isLoading = false;
            if (err) {
              this.apiStatus = result
                ? result.data.message
                  ? result.data.message
                  : result.data
                : "Request failed";
              this.utilsCheckLogout(this.apiStatus);
            } else {
              this.apiStatus = null;
              this.cancelCreateAlarm();
            }
          },
          query_params,
          "FAULT_SERVICE_URL"
        );
      } else {
        // create
        ApiService.post(
          "/api/v1/Alarm",
          this.Alarmdetails,
          (err, result) => {
            //console.log("*create*"+JSON.stringify(err) + " " + JSON.stringify(result))
            this.isLoading = false;
            if (err) {
              this.apiStatus = result
                ? result.data.message
                  ? result.data.message
                  : result.data
                : "Request failed";
              this.utilsCheckLogout(this.apiStatus);
            } else {
              this.apiStatus = null;
              this.cancelCreateAlarm();
            }
          },
          query_params
        );
      }
    },
    cancel() {
      this.$v.$reset();

      this.cancelCreateAlarm();
    },

    cancelCreateAlarm() {
      this.$router.go(-1);
    },

    modifyParamList(paramList) {
      return paramList.map((p) => {
        var display = this.paramDisplayNames[p.parameter];
        if (display) {
          return {
            parameter: display.name,
            value: (p.value / display.divider).toFixed(1) + " " + display.units,
            absoluteLimit: (p.absoluteLimit / display.divider).toFixed(1) + " " + display.units,
            mlLimit: (p.mlLimit / display.divider).toFixed(1) + " " + display.units,
            reason: p.reason,
          };
        } else return p;
      });
    },

    fetchAnomalyFiles(dicts) {
      Object.keys(dicts).map(function (filename) {
        var batches = dicts[filename];
        if (
          batches[0].anomalyStatus ==
          this.UtilsModaConstants.FaultService.MLAnomalyStatus.ANOMALY
        ) {
          //   this.utilsGetFileFromS3(
          this.getFileFromAPI({
            bucket: "rawlogs",
            filename,
            targetOrgId: this.currOrgId,
            arg: this,
            next: function (err, content, thisArg) {
              if (!err) {
                var batch2 = {};
                var arr = filename.split("/").reverse();
                batch2["anomalyIndexFileName"] = arr[1] + "/" + arr[0];
                thisArg.anomalyBatches.push(batch2);
                //console.log("fetchAnomalyFiles got filename ", filename, JSON.stringify(batches, null, 2) )
                var lines = content.split("\n");
                content = "";
                var isStart = null;
                var isEnd = null;
                let highLightCounter = 0;

                lines.forEach(function (line, idx) {
                  isStart = batches.find(
                    (element) => idx == element["startLine"] - 1
                  );
                  isEnd = batches.find(
                    (element) => idx == element["endLine"] - 1
                  );
                  if (isStart) {
                    content += `<div class='highlight' id='highlight-${++highLightCounter}' style='background-color: #FFFF00'>`; // highlight with yellow color
                    isEnd = null;
                  }
                  content += line;
                  if (isEnd) {
                    content += "</div>";
                    isStart = isEnd = null;
                  }
                  if (idx < lines.length - 1) content += "<br>";
                });
                if (isStart) {
                  // close the last batch if it wasn't closed in the loop above
                  content += "</div>";
                }
                batch2["fileContent"] = content;

                thisArg.highLightCounter = Math.min(highLightCounter, 10);
              }
            },
          });
        }
      }, this);
    },

    scrollIntoView(n) {
      document.querySelector(`#highlight-${n}`).scrollIntoView();
    },

    getAlarmDetails() {
      let query_params = { include_unlimited: true };
      if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      query_params.shorten = "true";

      //this.isLoading=true;
      ApiService.get(
        "/svcFault/api/v1/Alarm/" + this.Alarm_id,
        (err, result) => {
          this.isLoading = false;
          if (err) {
            this.apiStatus = result
              ? result.data.message
                ? result.data.message
                : result.data
              : "Request failed";
            this.utilsCheckLogout(this.apiStatus);
          } else if (result.data.data[0]) {
            this.apiStatus = null;
            this.alarmSelected = result.data.data[0];
            this.alarmSelected.createdDateString = this.utilsFormatDateTime(this.alarmSelected.createdDate);
            //console.log("alarmFeteched " + JSON.stringify(this.alarmSelected))
            setTimeout(() => {
              // Do this in separate thread so that any exception in this party of code doesn't fart ApiService.get
              if (this.alarmSelected.results) {
                if ( this.alarmSelected.categoryType == this.UtilsModaConstants.FaultService.CategoryType.ML_ANOMALY_LOG_ANALYSIS)
                  this.fetchAnomalyFiles(this.alarmSelected.results);
                else if ( this.alarmSelected.categoryType == this.UtilsModaConstants.FaultService.CategoryType.ML_ANOMALY_TELEMETRY) {
                  var len = this.alarmSelected.results.length - 1;
                  if ( this.alarmSelected.results[len].anomalyStatus == this.UtilsModaConstants.FaultService.MLAnomalyStatus.ANOMALY)
                    this.paramList = this.alarmSelected.results[len].paramList ? this.modifyParamList( this.alarmSelected.results[len].paramList) : null;
                }
              }
                //console.log(JSON.stringify(this.alarmSelected));
            }, 0);

            this.titleName = (this.readonly ? "View - " : "Edit - ") + this.alarmSelected.alarmIdCode;
          }
        },
        query_params,
        "FAULT_SERVICE_URL"
      );
    },
    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      if (this.Alarm_id != "" && this.Alarm_id != undefined) {
        // Edit can't be done when org is changed, so g back
        this.cancelCreateAlarm();
      } else this.getAlarmDetails();
    },
  },
  mounted() {
    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    this.Alarm_id = this.$route.params.id;
    this.AlarmIdCode = this.Alarm_id.split("_")[0];
    this.readonly = this.$route.params.readonly == "1";
    if (this.Alarm_id != "" && this.Alarm_id != undefined) {
      this.isEdit = true;
      this.titleName = this.readonly ? "View" : "Edit";
      this.getAlarmDetails();
    }
  },
};
</script>


