<template>
  <div class="content-wrapper dashboard">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />

    <v-container fluid>
      <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
        {{ apiStatus }}
      </v-alert>

        <!--- Incident Details -->
        <v-banner
          elevation="11"
          rounded
          shaped
          single-line
          sticky
          color="grey"
          >
          &nbsp; &nbsp; <v-icon>mdi-lan </v-icon> Correlation Map | 
            <v-btn color="primary" fab x-small dark><v-icon>mdi-ticket-confirmation</v-icon></v-btn>
            Incident: <b>{{rcInc.incidentIdCode}}</b>, 
            Category: <b>{{rcInc.categoryType}}/{{rcInc.categorySubType}}</b>,
            Severity: <b>{{rcInc.severity}}</b>, 
            State: <b>{{rcInc.state}}</b>,
            Component: <b>{{rcInc.componentName}}</b>

        </v-banner>
      <splitpanes class="default-theme" style="height: 600px">
        <pane :min-size="60" :max-size="80">
          <splitpanes class="default-theme" horizontal>
            <pane :key="1.1" :min-size="65" :max-size="85">
              <span>
                <Network ref="network" :nodes="rcIncMap.mapData.nodes" :edges="rcIncMap.mapData.edges" :options="rcIncMap.mapOptions"
                  :events="['doubleClick', 'zoom', 'click', 'oncontext']"
                  @double-click="onDoubleClick" @zoom="onZoom" @click="onClick" @oncontext="onRightClick">
                </Network>
              </span>
            </pane>

            <!-- Similar Incident Clusters -->
            <pane :key="1.2" :min-size="15" :max-size="35">
              <span>
                <Network ref="network2" :nodes="simIncMap.mapData.nodes" :edges="simIncMap.mapData.edges" :options="simIncMap.mapOptions"
                  :events="['doubleClick', 'zoom', 'click', 'oncontext']"
                  @double-click="onDoubleClick2" @zoom="onZoom2" @click="onClick2" @oncontext="onRightClick2">
                </Network>
              </span>
            </pane>
          </splitpanes>
        </pane>

        <pane :min-size="30" :max-size="40">
          <splitpanes class="default-theme" horizontal style="height: 600px">
            <pane :key="21" :min-size="20" :max-size="40">
              <span>
                <v-expansion-panels v-model="detailsPanel" multiple>

                  <!--- Rules Matched -->
                  <v-expansion-panel :key="211">
                    <v-expansion-panel-header color="grey lighten-2">
                      <nobr>
                        <v-btn color="primary" fab x-small dark><v-icon>mdi-table-filter</v-icon></v-btn>
                        Rules matched
                      </nobr>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Rule-201: DeviceProblem-ML issues correlation
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!--- Incident History -->
                  <v-expansion-panel :key="212">
                    <v-expansion-panel-header color="grey lighten-2">
                      <nobr>
                        <v-btn color="primary" fab x-small dark><v-icon>mdi-history</v-icon></v-btn>
                        Incident History
                      </nobr>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      2023-08-20: Incident opened on Device Problem event
                      2023-08-23: Incident INC2000361 (ML Anamoly) duplicated to this Incident
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>                
              </span>
            </pane>

            <!-- Summarize -->
            <pane :key="2.2" :min-size="40" :max-size="60">
              <v-row>
                <v-col>
                  <v-card-actions class="justify-end">
                    <v-btn icon rounded outlined color="success" title="Summarize" class="float-left mt-2" @click="onSummarize()">
                      <v-icon>mdi-receipt-text-plus</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
              <!-- <v-parallax dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg">-->
              <v-parallax dark src="assets/img/moda/moda_summarization_bg3_small.png">
                <v-row align-content="start">
                  <v-list dense>
                    <v-subheader>SUMMARY</v-subheader>
                    <v-list-item v-for="(summary, i) in summaryList" :key="i">
                      <v-list-item-icon>
                        <v-icon>mdi-file-certificate</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        {{summary}}
                      </v-list-item-content>
                    </v-list-item>
                    <br>
                    <v-subheader>SUGGESTIONS</v-subheader>
                    <v-list-item v-for="(sujestion, j) in suggestionList" :key="j">
                      <v-list-item-icon>
                        <v-icon>mdi-hand-pointing-right</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        {{sujestion}}
                      </v-list-item-content>
                    </v-list-item>

                  </v-list>
                </v-row>
              </v-parallax>
            </pane>
          </splitpanes>
        </pane>
      </splitpanes>

      <v-row>
        <v-col cols=12 md=11>
          <v-expansion-panels class="mb-6" v-model="filtersPanel" multiple>
            <!-- Selection Filter -->
            <v-expansion-panel :key="51">
              <v-expansion-panel-header color="grey lighten-2" height="20">
                <nobr>
                <v-btn color="primary" fab x-small dark><v-icon>mdi-filter-multiple</v-icon></v-btn>
                  &nbsp;&nbsp;&nbsp;
                  <v-icon>mdi-office-building-marker</v-icon><b>{{ rcInc.siteName || '-' }}</b> &nbsp;&nbsp;
                  <v-icon>mdi-group</v-icon><b>{{rcInc.categoryType || '-'}}</b> &nbsp;&nbsp;
                  <v-icon>mdi-inbox-arrow-up</v-icon><b>{{rcInc.componentName || '-'}}</b> &nbsp;&nbsp;
                  <v-icon>mdi-flash-triangle</v-icon><b>{{rcInc.severity || '-'}}</b>
                  <template v-slot:actions>
                    <!-- this is working in vuetify 3 only :(
                    <v-icon 
                      color="!expanded ? 'teal' : ''"
                      icon="expanded ? 'mdi-pencil' : 'mdi-check'">
                    </v-icon>
                    -->
                    <v-icon color="secondary"> $expand </v-icon>
                  </template>
                </nobr>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row justify="space-around" no-gutters>
                  <v-col cols="2">
                    <v-text-field v-model="rcInc.siteName" label="Site" type="text"></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field v-model="rcInc.categoryType" label="Category" type="text"></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field v-model="rcInc.componentName" label="Component" type="text"></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field v-model="rcInc.severity" label="Severity" type="text"></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- TimeWin Selection -->
            <v-expansion-panel :key="52">
              <v-expansion-panel-header color="grey lighten-2">
                <nobr>
                <v-btn color="primary" fab x-small dark><v-icon>mdi-calendar-range</v-icon></v-btn>
                  &nbsp;&nbsp;Range: [{{prettify(timeWin.minTime) || ' - ' }} -> {{prettify(timeWin.maxTime) || ' - '}}], 
                  &nbsp;&nbsp;Selection: [<b>{{prettify(timeWin.startTime) || ' - ' }}</b> -> <b>{{prettify(timeWin.endTime) || ' - '}}</b>]
                  <template v-slot:actions>
                  <v-icon color="secondary"> $expand </v-icon>
                </template>
                </nobr>
              </v-expansion-panel-header>
              <!-- Black bg <v-expansion-panel-content color="black"> -->
              <v-expansion-panel-content color="#1B213B">
                <VueApexCharts type="bar" height="200" ref="bigHistogramChartRef" :options="bigHistogramChartOptions" :series="bigHistogramChartSeries"/>
                <VueApexCharts type="area" height="100" ref="smallHistogramChartRef" :options="smallHistogramChartOptions" :series="smallHistogramChartSeries"
                  @brushScrolled="brushScrolledEvent" @click="brushScrollClicked"/>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        
        <v-col cols=12 md=1>
          <v-card-actions class="justify-center">
            <v-btn icon rounded outlined color="success" title="Refresh" class="float-left mt-2" @click="onRefresh()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <v-btn rounded outlined color="success" :disabled="reportGenerated" @click="onSaveReport">Save Report</v-btn>
        <v-btn rounded outlined color="success" @click="onGoBack">Go Back</v-btn>
      </v-card-actions>
 
    </v-container>

  </div>
</template>

<script>
import auth from "@/services/auth.service";
import AdsTreeTableUtils from "@/pages/moda/AdsTreeTableUtils.vue";
import { globalAPIUrlStore } from "@/main.js";
import ApiService from "@/services/api.service";
import FaultService from "@/services/fault.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import Utils from "@/pages/moda/Utils";

import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import { Network } from "vue-vis-network";
//import { DataSet } from "vue-vis-network";

import VueApexCharts from 'vue-apexcharts'

import ModaConst from "@/services/ModaConstants.js";

export default {
  name: "InvestigationRoom",
  components: {
    Breadcrumb,
    Splitpanes,
    Pane,
    Network,
    //DataSet,
    VueApexCharts
  },
  mixins: [AdsTreeTableUtils, Utils],
  data() {

    var baseUrl = "/assets/img/moda/";

    return {
      currOrgId: null,
      isLoading: false,

      timeWin: {
        startTime: new Date(2023, 0, 1).valueOf(),    // Selected timeWin start in epochMs 
        endTime: new Date().valueOf(),                // selected timeWin end in epochMs (currentTime)

        minTime: new Date(2023, 0, 1).valueOf(),      // timeWin Start & End (in epochMs)
        maxTime: new Date().valueOf(),
      },

      histogramData: [],
      brushScrolledPrevPos: null,   // {xasix : {x: <epochMs>, y: <epochMs> }} == startTime,endTime
      brushScrolledCurrPos: null,

      bigHistogramChartSeries: [{
        name: "Incident Count",
        data: [],
      }],
      bigHistogramChartOptions: {
        chart: {
          id: 'bigHistogramChart',
          type: 'bar',
          height: 160,
          foreColor: "#ccc",    // Theme2: Ref: https://apexcharts.com/docs/chart-types/area-chart/
          toolbar: {
            autoSelected: 'pan',
            show: false
          }
        },
        colors: ["#FCCF31", "#17ead9", "#f02fc2"], //Orig: ['#546E7A'],
        grid: {
          borderColor: "#555",  // Theme3: https://apexcharts.com/javascript-chart-demos/dashboards/realtime/
          clipMarkers: false,
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        fill: {                 // Theme3
          type: "gradient",
          gradient: {
            gradientToColors: ["#F55555", "#6078ea", "#6094ea"],
            shade: "dark",
              type: "vertical",
              shadeIntensity: 0.5,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 0.8,
              stops: [0, 100]
          }
        },
        markers: {        // Theme2
          size: 5,
          colors: ["#000524"],
          strokeColor: "#00BAEC",
          strokeWidth: 3
        },
        tooltip: {        // Theme2
          theme: "dark"
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {          // Theme2
          min: 0,
          tickAmount: 4
        },
      },

      smallHistogramChartSeries: [{
        name: "Incident Count",
        data: [],
      }],
      smallHistogramChartOptions: {
        chart: {
          id: 'smallHistogramChart',
          height: 80,
          type: 'area',
          foreColor: "#ccc", 
          brush:{
            target: 'bigHistogramChart',
            enabled: true
          },
          selection: {
            enabled: true,
            fill: {               // Theme2
              color: "#fff",
              opacity: 0.4
            },
            xaxis: {
              min: new Date(2023, 0, 1).valueOf(),
              max: new Date().valueOf()
            }
          },
        },
        colors: ['#008FFB'],      // Theme2
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          }
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          tickAmount: 2
        },
        grid: {                 // Theme2
          borderColor: "#555",  // Theme3: "#40475D"
          clipMarkers: false,
          yaxis: {
            lines: {
              show: true
            }
          }
        },
      },

      detailsPanel: [0],      // model for details panel slider component state
      filtersPanel: [1],      // model for details panel slider component state

      // Will be set on init
      rcInc: {
        incidentId: "inc.2999999", 
        incidentIdCode: "INC2999999"
      },
      rcIncMap : {
        mapData: {},
        mapOptions : {
          interaction : { hover: true, navigationButtons: true, keyboard: true },
          manipulation : { enabled : false },
          //widgth: "80%",
          height: "420px",
          nodes : { font : { face: "verdana", align: "center", size: 12 }, borderWidth: 2, shadow: true },
          edges : { width: 2, shadow: true },
          layout : { improvedLayout: true },
          physics : { enabled: false },     // true, if animation is needed
        },
        currView: {
          scale: 1.0, 
          selectedNodeId: null,
          pointer: {DOM: {x:0.0, y:0.0}, canvas: {x:0.0, y:0.0}}, 
          center: {x:0.0, y:0.0},
          viewPos: {x:0.0, y:0.0}			// Need this ?
        },
        rootNode : {nodeId: null, position: null},
      },

      // Similar incidents map clusters
      simIncMap : {
        mapData: {},
        mapOptions: {
          interaction : { hover : true, navigationButtons : true, keyboard : true },
          //widgth: "80%",
          height: "180px",
          layout : { improvedLayout: true },
          physics : { enabled: false },
        },
        currView: {
          scale: 1.0, 
          selectedNodeId: null,
          pointer: {DOM: {x:0.0, y:0.0}, canvas: {x:0.0, y:0.0}}, 
          center: {x:0.0, y:0.0},
          viewPos: {x:0.0, y:0.0}			// Need this ?
        },
        rootNode : {nodeId: null, position: null},
      },

      summaryList : ["<Click on Summarize button to generate summary>"],
      suggestionList: [],

      reportGenerated : false,
      fullPage: true,
      onCancel: true,
      height: 35,
      width: 35,
      apiStatus: null,
      isShow: "display-none",
      parent_pages: [{ name: "Home" }, { name: "InvestigationRoom", active: true }],
    };
  },

  //=== Hooks (Refer to EventsAlarms.vue for usage of all hooks) ================
  created: function () {
  },

  mounted: function () {
    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    //this.currOrgName = this.$refs.breadcrumb.getLastBreadcrumbOrgName();
    if (this.$route.params.inc) {
      this.rcInc = this.$route.params.inc; 
    }

    this.get_auth_details();

    //For-selection: this.severityValues = Object.values(this.UtilsModaConstants.FaultService.Severity).filter(v => typeof(v) == 'string');
    //For-selection: this.stateValues = Object.values(this.UtilsModaConstants.Incident.State).filter(v => typeof(v) == 'string');

    if (this.rcInc != "") {
      this.reportGenerated = true;

      //CHECK: Put this rcInc as one of the data point in the timeWin to avoid error on console
      //this.histogramData.push({x: this.rcInc.createdDate, y: 1});

      // Set inital selection timeWindow
      let selectionStartTime = this.rcInc.createdDate - (30 * 86400000); // 30 days before rcInc creation time
      if (selectionStartTime < this.timeWin.minTime) {
        selectionStartTime = this.timeWin.minTime;
      }
      this.timeWin.startTime = selectionStartTime;

      let selectionEndTime = this.rcInc.modifiedDate + (2*86400000);    // 2 days after rcInc (last) modified time
      if (selectionEndTime > this.timeWin.maxTime) {
        selectionEndTime = this.timeWin.maxTime;
      }
      this.timeWin.endTime = selectionEndTime;

      this.refreshTimeWinSelection({start: this.timeWin.startTime, end: this.timeWin.endTime});

      this.refreshData();
    }
  },

  //TRACE: beforeUpdate: function() { socketService.sendMessage(this.pageName, "in hook: beforeUpdate"); },
  //TRACE: updated: function() { socketService.sendMessage(this.pageName, "in hook: updated"); },
  updated() {
  },

  // activated and deactivated are called only when KeepAlive flag used i.e. when Component doesn't get destroyed once it's created
  activated: function () {
    this.isPageActivated = true;
  },

  // activated and deactivated are called only when KeepAlive flag used i.e. when Component doesn't get destroyed once it's created
  deactivated: function () {
    this.isPageActivated = false;
  },

  destroyed: function () {
    this.isConnected = false;
  },
  //===========================

  watch: {
    currOrgsPage: {
      handler() {
        //console.log("currOrgsPage changed ", this.currOrgsPage, " num pages ", this.orgsTableNumPages);
        this.currOrgsPageChanged();
      },
      deep: false,
      immediate: false,
    },
  },

  computed: {},

  methods: {
    onScroll() {
      console.log("onScroll");
    },

    //=== Map click actions ===
    //--- doubleClick : For now, 'doubleClick' is same as single 'click'
		onDoubleClick(params) {
			//console.log("onDoubleClick: ");
			//DEBUG: 
      console.log("onDoubleClick: params = " + JSON.stringify(params));

			this.onClick(params);
		},

    //--- if the node is non-leaf, then toggle (Open or Close) the node 
		onClick(params) {
			//DEBUG: 
      console.log("onClick: params = " + JSON.stringify(params));
    },

		//--- on right-click on a node, show a context menu for cross-launch to the corresponding object page
		onRightClick(params) {
      //DEBUG: 
      console.log("onRightClick: params = " + JSON.stringify(params));
    },

    //--- note the scale (zoom level)
		onZoom() {
			let network = this.$refs.network;
			this.rcIncMap.currView.scale = network.getScale();
			//DEBUG:
      console.log("onZoom: currView = " + JSON.stringify(this.rcIncMap.currView));
			//console.log("onZoom: currView.scale = " + this.rcIncMap.currView.scale);
		},
    //=========================

    //=== network2 (Similar Incident Map Clusters Map) click actions ===
    //--- doubleClick2 : For now, 'doubleClick' is same as single 'click'
		onDoubleClick2(params) {
			//console.log("onDoubleClick2: ");
			//DEBUG: 
      console.log("onDoubleClick2: params = " + JSON.stringify(params));
			this.onClick(params);
		},

    //--- show additional info popup bubble ----------------------------- 
		onClick2(params) {
			//DEBUG: 
      console.log("onClick2: params = " + JSON.stringify(params));
    },

		//--- on right-click on a node, show a context menu for cross-launch to the corresponding object page
		onRightClick2(params) {
      //DEBUG: 
      console.log("onRightClick2: params = " + JSON.stringify(params));
    },

    //--- note the scale (zoom level)
		onZoom2() {
			let network2 = this.$refs.network2;
			this.simIncMap.currView.scale = network2.getScale();
			//DEBUG:
      console.log("onZoom2: currView = " + JSON.stringify(this.simIncMap.currView));
			//console.log("onZoom2: currView.scale = " + this.simIncMap.currView.scale);
		},
    //=========================

    prettify: function(ts) {
      return new Date(ts).toLocaleDateString("en", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },

    get_auth_details() {
      let title = this.$route.meta.title;
      this.isShow = auth.AuthService.get_usr_auth_details(title);
      this.userdetails =  auth.AuthService.get_usrdetails();
      this.loggedin_org = this.userdetails.orgInfo
    },

    brushScrolledEvent(chart, pos) {
      //@@@ console.log("brushScrolledEvent pos = ", pos.xaxis.min, pos.xaxis.max)
      this.brushScrolledCurrPos = pos; // save the last one
    },

    brushScrollFinished() {
      if ( this.brushScrolledCurrPos && 
          // prev pos doesn't exist or not equal to current pos
          ( ! this.brushScrolledPrevPos ||
            this.brushScrolledPrevPos.xaxis.min != this.brushScrolledCurrPos.xaxis.min ||
            this.brushScrolledPrevPos.xaxis.max != this.brushScrolledCurrPos.xaxis.max ) ) {
        //DEBUG: 
        console.log("brushScrolledCurrPos = ", JSON.stringify(this.brushScrolledCurrPos));

        this.timeWin.startTime = this.brushScrolledCurrPos.xaxis.min;
        this.timeWin.endTime = this.brushScrolledCurrPos.xaxis.max;
        
        this.brushScrolledPrevPos = this.brushScrolledCurrPos;
        this.brushScrolledCurrPos = null
      }
    },

    brushScrollClicked(event, chart, config) {
        setTimeout ( this.brushScrollFinished, 500 );   //wait for last event after click
    },

    refreshTimeWinChartData() {
      console.log("refreshTimeWinChartData: ");
      //DEBUG: console.log("refreshTimeWinChartData: histogramData = ", JSON.stringify(this.histogramData));
      this.$refs.smallHistogramChartRef.updateSeries([{data: this.histogramData}]);
      this.$refs.bigHistogramChartRef.updateSeries([{data: this.histogramData}]);
    },

    //--- Update the TimeWin range ----------------------------------------------------------------
    refreshTimeWinRange(tw) {
      console.log("refreshTimeWinRange: tw = [" + tw.min + " -> " + tw.max + "]");
      this.$refs.smallHistogramChartRef.updateOptions({
        xaxis : {
          min : tw.min,
          max : tw.max
        }
      });
    },

    //--- Update Time Window selection ------------------------------------------------------------
    refreshTimeWinSelection(tw) {
      console.log("refreshTimeWinSelection: tw = [" + tw.start + " -> " + tw.end + "]");
      // Set the time window selection
      this.$refs.bigHistogramChartRef.zoomX(tw.start, tw.end);

      // Refresh the big histogram accordingly
      this.$refs.bigHistogramChartRef.updateOptions({
        xaxis : {
          min : tw.start,
          max : tw.end
        }
      });

      //this.this.$refs.bigHistogramChartRef.render();
    },

    //--- calculate the data for the Time Window -------------------------------------------------
    calcTimeSelectionData(twData) {
      this.histogramData = [];    // clear any old data

      let num = twData.length;
      console.log("calcTimeSelectionData: processing " + num + " entries ...");
      for (let i=0; i<num; i++) {
        let xdate = new Date(twData[i].date).getTime();
        let count = twData[i].count;
        //DEBUG: console.log("xdate = " + xdate + ", count = " + count);
        this.histogramData.push({x: xdate, y: count});
      }

      // Update the timewindow range on the data range
      if (num > 0) {
        this.timeWin.minTime = this.histogramData[0].x;
        this.timeWin.maxTime = this.histogramData[num-1].x;

        this.refreshTimeWinRange({min: this.timeWin.minTime, max: this.timeWin.maxTime});
      }

      // Set curret selected timeWin - IS THIS NEEDED ????
      this.refreshTimeWinSelection({start: this.timeWin.startTime, end: this.timeWin.endTime});
    
      this.refreshTimeWinChartData();
    },

    //--- make REST call to backend to get performance data --------------------------------
    getInvestigationReportData() {
      let query_params = {};
      if (this.currOrgId) { 
        query_params.targetOrgId = this.currOrgId;
      }
      query_params.incidentId = this.rcInc.incidentId;
      query_params.startTime = this.timeWin.startTime;
      query_params.endTime = this.timeWin.endTime;
      //DEBUG: 
      console.log("getInvestigationReportData: query_params = " + JSON.stringify(query_params));

      this.isLoading = true;

			FaultService.getInvestigationReport(query_params).then((result) => {
				this.isLoading = false;
				
        if (result.data) {
					this.apiStatus = null;

          this.rcIncMap.mapData = result.data.data.rcIncMap.mapData;
          //this.rcIncMap.mapOptions = result.data.data.rcIncMap.mapOptions;    // Use local options

          //console.log("\n~~~> data = " + JSON.stringify(result.data.data));
          //console.log("\n~~~> rcIncMap = " + JSON.stringify(result.data.data.rcIncMap));
          //console.log("\n~~~> timeWinData = " + JSON.stringify(result.data.data.timeWinData));

          // Generate Data for brushChart time-selection-slider
          this.calcTimeSelectionData(result.data.data.timeWinData);

          //DEBUG: 
          console.log("\n### SUCCESS timeWin = " + JSON.stringify(this.timeWin));

					this.refreshNetworkView("rciNet");
				}

			}).catch((err) => {
        console.log("\n### ERROR (getEnvReport) err = " + JSON.stringify(err));

				this.isLoading = false;
				this.apiStatus = err.response.data || "Request failed";
				this.utilsCheckLogout(this.apiStatus);
			});
    },

    //--- get (ML) Incidents trend info, for the current Org -------------
    getSimilarIncidentsInvestigationReportData() {
      //this.isLoading = true;

      let query_params = {};
      if (this.currOrgId) {
        query_params.targetOrgId = this.currOrgId;
      }
      query_params.incidentId = this.rcInc.incidentId;
      query_params.startTime = this.timeWin.startTime;
      query_params.endTime = this.timeWin.endTime;

      this.isLoading = true;

			FaultService.getSimilarIncidentsInvestigationReport(query_params).then((result) => {
				this.isLoading = false;
				
        if (result.data) {
					//DEBUG: 
          console.log("\n>>> getSimilarIncidentsInvestigationReport: result.data = " + JSON.stringify(result.data));
					this.apiStatus = null;

          //DEBUG: console.log("simIncInvReport.result = " + JSON.stringify(result));

          //#####################################
          // TODO: Update similar incident cluster maps
          //#####################################

          //~~~ this.addSimIncToRcIncMap(result.data.data.rcIncRelatedIncList);

          this.addSimClustersToSimIncMap(result.data.data.simIncMapList);
          //this.simIncMap.mapOptions = result.data.data.rcIncMap.mapOptions();    // use local options

          console.log("simIncMap = " + JSON.stringify(this.simIncMap));
					this.refreshNetworkView("siNet");
				}

			}).catch((err) => {
				this.isLoading = false;
				this.apiStatus = err.response.data || "Request failed";
				this.utilsCheckLogout(this.apiStatus);
			});
    },
    
    refreshNetworkView(networkName) {
 
      if (networkName == "rciNet") {
        let network1 = this.$refs.network;

        let scale1 = 0.7;

        let offsetForRootNode = {
          x: -500 * scale1,    // (0 - this.rcIncMap.mapData.nodes[0].x) * scale1,
          y: -200 * scale1,    // (0 - this.rcIncMap.mapData.nodes[0].y) * scale1
        }

        console.log("offsetForRootNode = " + JSON.stringify(offsetForRootNode));
        network1.moveTo({
          scale: scale1,
          offset: offsetForRootNode,          
					animation: false
        });

        network1.redraw();

      } else {    // ie if networkName == "siNet"
        let network2 = this.$refs.network2;

        let scale2 = 0.5;

        let offsetForRootNode2 = {
          x: -100 * scale2,
          y: -400 * scale2,
        }

        network2.moveTo({
          scale: scale2,
          offset: offsetForRootNode2,
					animation: false
        });

        network2.redraw();
      }
    },

    addSimIncToRcIncMap(relIncList) {
      console.log("addSimIncToRcIncMap: ");

      //TODO: add the relevant Inc objects to rcMap, as disconnected nodes

    },

    addSimClustersToSimIncMap(simIncMapList) {
      let numMaps = simIncMapList.length;
      console.log("addSimClustersToSimIncMap: numMaps = " + numMaps);
      // Add similar Incident clusters to network-map-2
      
      //TODO: Merge All Maps to form a single map
      simIncMapList[0].nodes[0].x = 0;          // Set position for rootName of cluster-0
      simIncMapList[0].nodes[0].y = -200;

      let combinedMap = simIncMapList[0];

      //DEBUG: console.log("simIncMapList = " + JSON.stringify(simIncMapList));
      if (numMaps > 1) {
        // Merge the other maps
        for (let i=1; i<numMaps; i++) {
          simIncMapList[i].nodes[0].x = i*200; // Set position for rootName of cluster-0
          simIncMapList[i].nodes[0].y = -200;

          combinedMap.nodes = combinedMap.nodes.concat(simIncMapList[i].nodes);
          combinedMap.edges = combinedMap.edges.concat(simIncMapList[i].edges);
        }
      }

      //DEBUG: console.log("combinedMap = " + JSON.stringify(combinedMap));
      this.simIncMap.mapData = combinedMap;
    },

    refreshData() {
      this.getInvestigationReportData();
      this.getSimilarIncidentsInvestigationReportData();
    },

    onRefresh() {
      console.log("onRefresh: ");
      this.refreshData();
    },

    onSummarize() {
      console.log("invRoom: onSummarize: ");
      this.summaryList = [];    // Clear old list
      this.summaryList.push("App:Teams issue is reported at all the Sites of this Org");
      this.summaryList.push("There is also a temperature issue from the Router device at this Site");

      //"Suggested resolutions ==> \n" + 
      this.suggestionList = [];  // Clear old list
      this.suggestionList.push("Check and Resolve the Fan issue of the Router or");
      this.suggestionList.push("Restart the Router");
    },

    onSaveReport() {
      //DEBUG: 
      console.log("invRoom: Save Report");

      //TODO: Save all the data into InvReport mongo collection

      alert("Investigation Report is Saved");
      this.reportGenerated = (this.reportGenerated == true) ? false : true;   // Dummy
    },

    onGoBack() {
      //DEBUG: 
      console.log("invRoom: onGoBack");
      this.$router.go(-1)
    },

    setOrgCookie() {
      // This does not overwrite entire document.cookie string. It just adds/updates specified key=value only
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
      // Note: document.cookie is an accessor property with native setter and getter functions, and consequently is not a data property with a value: what you write is not the same as what you read, everything is always mediated by the JavaScript interpreter.
      document.cookie = "MODA_targetOrgId=" + this.currOrgId;
    },

    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      this.setOrgCookie();
    },

    handleVisibility() {}
  }

};
</script>
