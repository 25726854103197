<template>
	<v-list nav dense>
		<v-list-group class="menu-subgroup" v-if="menu.children" :value="false">
			<template v-slot:prependIcon>
				<v-icon small dense left class="mr-1 ml-2">{{ menu.icon }}</v-icon>
			</template>
			<template v-slot:activator>
				<v-list-item-content>
					<v-list-item-title>{{ menu.title }}</v-list-item-title>
				</v-list-item-content>
			</template>
			<template v-for="submenu in menu.children">
				<sidebar-nav-list :menu="submenu" :key="submenu.path" class="pl-3">
				</sidebar-nav-list>
			</template>
		</v-list-group>
		<v-list-item v-else router :to="menu.path">
			<v-list-item-icon class="mr-1 ml-2">
				<v-icon small dense left>{{ menu.icon }}</v-icon>
			</v-list-item-icon>
			<v-list-item-title>{{ menu.title }}</v-list-item-title>
		</v-list-item>
	</v-list>
</template>

<script>
import SidebarNavList from '@/components/templates/SidebarNavList'

export default {
	name: 'SidebarNavList',
	props: ['menu'],
	components: {
		SidebarNavList
	}
}
</script>
