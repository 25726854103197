<template>
  <div :class="showlabels ? 'full-width' : ''">
    <div class="float-left">
      <v-tooltip top v-if="getInfo(item.categoryType)">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" medium class="mr-2" color="primary">
            {{ getInfo(item.categoryType).icon }}</v-icon>
        </template>
        <span>{{ getInfo(item.categoryType).text }}</span>
      </v-tooltip>
    </div>
    <div v-if="showlabels" class="float-left">
      <span v-if="getInfo(item.categoryType)">{{ getInfo(item.categoryType).text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TypeIcon',
  props: ['item', 'UtilsModaConstants', 'showlabels'],
  methods: {
    getInfo(e) {
      let info = null;
      switch (e) {
        case this.UtilsModaConstants.FaultService.CategoryType.ML_ANOMALY_LOG_ANALYSIS:
          info = { icon: "mdi-text-box-outline", text: "Log Analysis" };
          break;
        case this.UtilsModaConstants.FaultService.CategoryType.ML_ANOMALY_TELEMETRY:
          info = { icon: "mdi-chart-box", text: "Telemetry" };
          break;
        case this.UtilsModaConstants.FaultService.CategoryType.DEVICE_AVAILABILITY:
          info = { icon: "mdi-laptop", text: "Device Availability" };
          break;
        case this.UtilsModaConstants.FaultService.CategoryType.DEVICE_PROBLEM:
          info = { icon: "mdi-laptop-off", text: "Device Problem" };
          break;
        case this.UtilsModaConstants.FaultService.CategoryType.CUSTOMER:
          info = { icon: "mdi-alert-circle", text: "Customer" };
          break;
        case this.UtilsModaConstants.FaultService.CategoryType.SYSTEM:
          info = { icon: "wrench-cog-outline", text: "System" };
          break;
        case this.UtilsModaConstants.FaultService.CategoryType.EXTERNAL_SYS:
          info = { icon: "mdi-alert-circle", text: "External" };
          break;
        case this.UtilsModaConstants.FaultService.CategoryType.OTHER:
          info = { icon: "mdi-circle-outline", text: "Other" };
          break;
        default:
          break;
      }
      return info;
    }
  }
}
</script>
