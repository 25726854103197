<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />
    <v-card :elevation="5" class="tile-box">
      <v-card-title class="page-title">
        <v-icon>mdi-clipboard-pulse</v-icon> Monitoring Templates
        <!-- <v-btn rounded outlined color="primary" absolute right :class="isShow" v-on:click="createMonTemplate()" >
          <v-icon>mdi-plus</v-icon> New
        </v-btn>  -->
      </v-card-title>
      <v-card-text class="pb-0">
        <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
          {{ apiStatus }}
        </v-alert>
        <div class="search-wrapper">
          <v-text-field v-model="filterValueSearch" label="Search table" prepend-inner-icon="mdi-magnify"
            @input="utilsFilterChanged" />
        </div>
      </v-card-text>
      <v-container fluid class="pt-0">
        <v-data-table :loading="isLoading" :headers="monTemplate_table_vuetify_columns" :items="monTemplate_table_rows" multi-sort
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 100] }" :options.sync="pagingOptions" item-key="monTemplateId">
          <!--
          <template v-slot:item.actions="{ item }">
            <v-row>
              <v-icon small @click="editMonTemplateDetails(item.monTemplateId, 1)" class="mr-2 record-view" title="View" >
                fas fa-eye
              </v-icon>
              <span :class="utilsGetDisplayClass(item)">
                <v-icon small @click="editMonTemplateDetails(item.monTemplateId, 0)" class="mr-2 record-edit" title="Edit" >
                  fas fa-pencil-alt
                </v-icon>
                <v-icon small @click="deleteMonTemplateDetails(item.monTemplateId, item.monTemplateName)" class="record-delete" title="Delete" >
                  fas fa-trash
                </v-icon>
              </span>
            </v-row>
          </template>-->
        </v-data-table>
      </v-container>
    </v-card>
    <DeleteDialog v-if="deleteDialog" :currentObjectName="currName" :isBackupBeforeDelete="false"
      @DeleteDialogCallback="deleteObjectChildEvent">
    </DeleteDialog>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import auth from "@/services/auth.service";
import Breadcrumb from "@/components/templates/Breadcrumb";
import AdsTreeTableUtils from "@/pages/moda/AdsTreeTableUtils.vue";
import Utils from "@/pages/moda/Utils.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";

export default {
  components: {
    Breadcrumb,
    DeleteDialog,
  },
  mixins: [AdsTreeTableUtils, Utils],
  data() {
    var monTemplate_table_vuetify_columns = [
      {
        text: "Name",
        value: "monTemplateName",
        sortable: true,
        filterable: true,
      },
      {
        text: "Type",
        value: "monTemplateType",
        sortable: true,
        filterable: true,
      },
      {
        text: "Vendor",
        value: "monTemplateVendor",
        sortable: true,
        filterable: true,
      },
      {
        text: "Model",
        value: "monTemplateModel",
        sortable: true,
        filterable: true,
      },
      {
        text: "OS",
        value: "monTemplateOS",
        sortable: true,
        filterable: true,
      },
      {
        text: "Techs",
        value: "monTemplateTech",
        sortable: true,
        filterable: true,
      },
      {
        text: "Description",
        value: "description",
        sortable: true,
        filterable: true,
      },
      {
        text: "Creation Time",
        value: "createdDateString",
        sortable: true,
        filterable: true,
      },
/*
      {
        text: "Actions",
        value: "actions",
      },
*/
    ];

    return {
      currOrgId: null,

      monTemplate_table_vuetify_columns,
      monTemplate_table_rows: [],

      isLoading: false,

      userdetails: {},
      isShow: "display-none",
      parent_pages: [{ name: "Home" }, {name: "Configuration"}, { name: "Profiles & Jobs" },
                                { name: "Monitoring Templates", active: true }],
      apiStatus: null,
      deleteDialog: false,
      currId: null,
      currName: null,

      pagingOptions: sessionStorage.getItem(`_pagination${this.$route.path}`) ? JSON.parse(sessionStorage.getItem(`_pagination${this.$route.path}`)) : this.utilsPagingOptions, // template doesn't work directly using utilsPagingOptions
      //totalEntriesAllPages:this.utilsTotalEntriesAllPages, // template can directly use this variable
    };
  },
  watch: {
    pagingOptions: {
      handler() {
        sessionStorage.setItem(`_pagination${this.$route.path}`, JSON.stringify(this.pagingOptions));
        this.getMonTemplateList();
      },
      deep: false,
      immediate: false,
    },
  },
  methods: {
    // table filter functionality doesn't work unless it's rendering from native fields
    // i.e. doesn't work if it's rendered using slots and dynamically created content. So expand/build fields here
    buildFilterableColumns(objs) {
      objs.forEach((obj) => {
        obj.createdDateString = this.utilsFormatDateTime(obj.createdDate);
        obj.profilesUsing = [];
        if (obj._children) this.buildFilterableColumns(obj._children);
      });
    },

    getMonTemplateList() {
      console.log(11);
      this.isLoading=true
      let query_params = {}
      // if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
      // if (this.filterValueSearch)
      //   query_params.searchString = "any:*:" + this.filterValueSearch;
      // query_params.pageNum = this.pagingOptions.page;
      // query_params.pageSize = this.pagingOptions.itemsPerPage;
      var sortCriteria = this.utilsMakeSortCriteria(this.pagingOptions, {
        subType_protoPorts: "protocol",
      });
      if (sortCriteria) query_params.sortCriteria = sortCriteria;

      //let body={/*To be filled in with advSearchCriteria */};
      //ApiService.post( "/svcInventory/api/v1/monitoringTemplate/all/page2", body, (err, result) =>
      ApiService.get( "/svcInventory/api/v1/monitoringTemplate", (err, result) =>
      {
          this.isLoading = false;
          if (err){
            this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
            this.utilsCheckLogout(this.apiStatus)
          }else {
            this.apiStatus = null;

            this.monTemplate_table_rows = result.data.data;
            this.utilsTotalEntriesAllPages = this.monTemplate_table_rows.length

            this.buildFilterableColumns(this.monTemplate_table_rows);
            //console.log("getMonTemplateList " + JSON.stringify(this.monTemplate_table_rows));
          }
        },
        query_params, "INVENTORY_SERVICE_URL"
      );
    },
    editMonTemplateDetails(id, readonly) {
      this.$router.push( { name: "MON_TEMPLATES_EDIT", params: { id: id, readonly: readonly}});
    },

    deleteMonTemplateDetails(Id, name) {
      this.deleteDialog = true;
      this.currId = Id;
      this.currName = name;
    },

    deleteObjectChildEvent(cancelFlag) {
      this.deleteDialog = false;
      if (cancelFlag == true) return;
      //   console.log("**" + isBackupDel);
      // if(confirm("Do you really want to delete - '" + name + "' ?"))
      {
        this.isLoading = true;

        let query_params = {};
        if (this.currOrgId) query_params.targetOrgId = this.currOrgId;
        query_params.deleteMode = this.UtilsModaConstants.DeleteMode.FORCE;

        ApiService.delete( "/api/v1/monTemplate/" + this.currId, (err, result) => {
            //console.log("**"+JSON.stringify(result))
            this.isLoading = false;
            if (err){
              this.apiStatus = result ? result.data.message ? result.data.message : result.data : "Request failed";
              this.utilsCheckLogout(this.apiStatus)
            } else {
              this.apiStatus = null;
              this.getMonTemplateList();
            }
          },
          query_params
        );
      }
    },
    createMonTemplate() {
      this.$router.push( { name: "MON_TEMPLATES_CREATE" } )
    },
    get_auth_details() {
      let title = this.$route.meta.title;
      this.isShow = auth.AuthService.get_usr_auth_details(title);
    },
    processBreadcrumbMessage(selectedOrgId) {
      this.currOrgId = selectedOrgId;
      this.clearPaginationCache();
      this.getMonTemplateList();
    },
    clearPaginationCache(){
      sessionStorage.removeItem(`_pagination${this.$route.path}`);
      this.pagingOptions.page = 1;
    },
  },
  mounted() {
    this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    this.get_auth_details();
    //this.utilsDataRefreshFunc = this.getMonTemplateList;
    this.getMonTemplateList();
  },
};
</script>
