<template>
  <div class="content-wrapper">
    <Breadcrumb :parent_pages="parent_pages" ref="breadcrumb" v-on:messageFromBreadcrumb="processBreadcrumbMessage" />

    <v-card :elevation="2" class="tile-box">
      <v-card-title class="page-title">
        <v-icon>mdi-filter-settings</v-icon> {{ titleName }}

        <v-btn class="published-button" :color="blue" rounded outlined right absolute>
          Admin-Status: <strong>{{ruleDetails.adminStatus}}</strong>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <!-- begin view form -->
        <v-container fluid class="pa-0">
          <v-alert v-if="apiStatus" :border="'top'" color="red" dark tile>
            {{ apiStatus }}
          </v-alert>
          <v-row v-if="isLoading">
            <v-col cols="12">
              <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="px-0">
              <v-row v-if="isLoading">
                <v-col cols="12" class="text-center pt-10 mt-10" style="height:400px">
                  <v-progress-circular :size="80" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
              <v-row v-if="!isLoading">
                <v-col>
                  <Network ref="network" :nodes="ruleDetails.ruleMap.data.nodes" :edges="ruleDetails.ruleMap.data.edges"
                    :options="ruleDetails.ruleMap.options">
                  </Network>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-container>
        <!-- end view form -->
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn rounded outlined color="success" @click="cancel">Go Back</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
//import ApiService from "@/services/api.service";
//import moment from 'moment';

import auth from '@/services/auth.service'
import Breadcrumb from '@/components/templates/Breadcrumb'
import Utils from '@/pages/moda/Utils'
import { Network } from "vue-vis-network";
import FaultService from "@/services/fault.service";

export default {
	name: "EventRuleView",
	components: {
		Breadcrumb,
		Network
	},
	mixins: [
		Utils,
	],
	data() {
		return {
			currOrgId: null,
			isLoading: false,
      titleName: "View:",
      rule_id: '',
      ruleDetails : {
        ruleName : '-toget-',
        ruleMap: {data: {nodes: [], edges: []}, options: {}}
      },
			apiStatus: null,
			isShow: 'display-none',
			parent_pages: [{ name: 'Home' }, { name: 'Rule Map', active: true }]
		};
	},

	methods: {

		get_auth_details() {
			let title = this.$route.meta.title;
			this.isShow = auth.AuthService.get_usr_auth_details(title)
		},

		getRuleMapData() {
			console.log("getRuleMapData:");

			let query_params = {};
			query_params.targetOrgId = this.currOrgId;

			this.isLoading = true;

			FaultService.getRuleMap(this.rule_id, query_params).then((result) => {
				this.isLoading = false;
				//DEBUG: console.log("getRuleMapData result.data " + JSON.stringify(result.data))
				if (result.data) {
					this.apiStatus = null;

          result.data.data.ruleMap.data.nodes[result.data.data.ruleMap.data.nodes.length-1].y -= 100
          result.data.data.ruleMap.data.edges.forEach ( (e) => {delete e.length } )
          this.ruleDetails = result.data.data;
          //this.ruleDetails.ruleMap.options.layout = { hierarchical : { direction :'LR' } }
         //console.log("rule " + JSON.stringify(this.ruleDetails.ruleMap.data.nodes))

          this.titleName = "View: " + this.ruleDetails.ruleName;
          //DEBUG: console.log("ruleName = " + this.titleName);
				}

			}).catch((err) => {
				this.isLoading = false;
				this.apiStatus = "Request failed";
				this.utilsCheckLogout(this.apiStatus);
			});
		},

		processBreadcrumbMessage(selectedOrgId) {
			this.currOrgId = selectedOrgId;
			// if editing a specific rule and org changed in breadcrumb, cancel and go back to list page
			//if ( this.rule_id ) this.cancel();
			this.getRuleMapData()
		},

		destroyNetwork() {
			this.$refs.network.destroy();
		},

    cancel() {
      this.$router.go(-1);
    },

	},

	mounted() {
		this.currOrgId = this.$refs.breadcrumb.getLastBreadcrumbOrgId();
    this.rule_id = this.$route.params.id;
		this.get_auth_details();
		this.getRuleMapData();
	}
}

</script>
